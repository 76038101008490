import React from "react";
import { useNavigate } from "react-router-dom";
import { CardBoard } from "../../GenericComponents/CardBoard";
import { useGetActivityMetaData } from "./useGetActivityMetaData";
import { cardComponentTypeMap } from "./activityCardComponentMap";
import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
import { ActivityViewFields, initialFilterData } from "../../lib/Activities/IActivity";
import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
import { useAppSelector } from "../../Redux/hooks";
import { EActivityManagerStatus } from "../../Redux/Activities/activitiesReducer";
import { AppCardboardWrap, AppInfoSection, ErrorMessage } from "../../styles/app";
import { UserAgePrompt } from "../UserAgePrompt";
import { UserGroup } from "../../lib/User/UserGroup";
import { LoadingCard } from "../../GenericComponents/CardBoard/LoadingCard";
import { SelectDropdown } from "../../GenericComponents/FormElements/Select";
import { FilterStateContext, FilterStateProvider } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { IActivityCardData } from "./IActivityCardProps";
import { ActivityDashboardKey } from "./ActivitiesKey";
import { filterActivityCards } from "./activityCardFilter";

const JumpToActivitySelect = ({ activities }: { activities: { id: string | number; label: string }[] }) => {
  const navigate = useNavigate();
  return (
    <p>
      <label htmlFor="jumpToActivitySelect">Jump to activity</label>:{" "}
      <SelectDropdown
        selectOptions={[
          { id: "", label: "Use dropdown to make activity selection" },
          ...activities.sort((a, b) => a.label.localeCompare(b.label)).map((a) => ({ id: a.id, label: a.label })),

        ]}
        id="jumpToActivitySelect"
        value={""}
        onChange={(v) => navigate(`/app/activities/${v}`)}
        style={{ width: "auto", maxWidth: "100%", background: "white", marginTop: 0 }}
      />
    </p>
  );
};

const FilteredCardsContainer = ({
  breakpoints,
  cards: cardsData,
}: {
  breakpoints: { default: number; [key: number]: number } | number;
  cards: IActivityCardData[];
}) => {
  const { filters } = React.useContext(FilterStateContext);
  const filteredCards = React.useMemo(() => cardsData.filter(filterActivityCards(filters)), [cardsData, filters]);
  const cardsCompiled = useGetCards({ cardsData: filteredCards, cardComponentTypeMap, filterHiddenCards: true });
  return <CardBoard breakpointColumns={breakpoints}>{[...cardsCompiled]}</CardBoard>;
};

export const ActivitiesDashboard = () => {
  const userRole = useAppSelector<UserGroup>((state) => state.userState?.user?.group);
  const [containerRef, breakpoints] = useGetBreakpoints(2);
  const { cards: cardsData } = useGetActivityMetaData({
    groupId: "appActivityCards",
    userRole,
  });

  const loadingActivityViews = useAppSelector((state) => state.activitiesState.status !== EActivityManagerStatus.IDLE);
  const errorLoadingActivityViews = useAppSelector((state) => state.activitiesState.error);

  return (
    <FilterStateProvider fieldsData={ActivityViewFields} initialFilterData={initialFilterData}>
      <AppInfoSection style={{ padding: "1rem", paddingBottom: "0.5rem", marginBottom: "0.5rem" }}>
        <ActivityDashboardKey />
      </AppInfoSection>
      <AppInfoSection style={{ padding: "0.5rem", paddingLeft: "1rem", marginBottom: "3rem" }}>
        <JumpToActivitySelect activities={cardsData} />
      </AppInfoSection>
      <div ref={containerRef} style={{ position: "relative" }}>
        {!loadingActivityViews && !errorLoadingActivityViews && !cardsData?.length ? (
          <ErrorMessage>Could not find any activity cards!</ErrorMessage>
        ) : (
          ""
        )}
        {loadingActivityViews && !errorLoadingActivityViews ? <LoadingCard /> : ""}
        {errorLoadingActivityViews ? <ErrorMessage>Error Loading Cards</ErrorMessage> : ""}
        <UserAgePrompt />
        <div style={{ display: "flex" }}>
          <AppCardboardWrap>
            <FilteredCardsContainer breakpoints={breakpoints} cards={cardsData} />
          </AppCardboardWrap>
        </div>
      </div>
    </FilterStateProvider>
  );
};
