import { EActivityStatus, IActivityUser, IMissingActivityData } from "../../../lib/Activities/IActivity";
import { CTALinkButtonStyle, LinkButtonStyle } from "../../../styles/links";
import { List, ListItem } from "../../../styles/page";

export interface IDependentActivitiesProps {
  previousActivities: (IActivityUser | IMissingActivityData)[];
}

export const DependentActivities = ({ previousActivities }: IDependentActivitiesProps) => {
  const completedActivities = previousActivities.filter(
    (a: IActivityUser) =>
      a.activityUserProgress?.activityStatus === EActivityStatus.COMPLETED || a.activityUserProgress?.attemptNo > 1
  );
  const incompleteActivities = previousActivities.filter(
    (a: IActivityUser) =>
      !a.activityUserProgress ||
      (a.activityUserProgress?.activityStatus !== EActivityStatus.COMPLETED &&
        (!a.activityUserProgress.attemptNo || a.activityUserProgress.attemptNo <= 1))
  );

  return (
    <>
      <div>
        {(incompleteActivities.length && (
          <>
            <h3>Incomplete</h3>
            <List>
              {incompleteActivities.map((a) => (
                <ListItem key={a.id}>
                  <CTALinkButtonStyle to={`/app/activities/${a.id}`}>{a.label}</CTALinkButtonStyle>
                </ListItem>
              ))}
            </List>
          </>
        )) ||
          ""}
        {/* {completedActivities.length && incompleteActivities.length && <p style={{ minHeight: "1rem" }} />} */}
        {(completedActivities.length && (
          <>
            <h3>Complete</h3>
            <List>
              {completedActivities.map((a) => (
                <ListItem key={a.id}>
                  <LinkButtonStyle to={`/app/activities/${a.id}`}>{a.label}</LinkButtonStyle>
                </ListItem>
              ))}
            </List>
          </>
        )) ||
          ""}
      </div>
    </>
  );
};
