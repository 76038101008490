import { Link } from "react-router-dom";
import {
  BorderedTable,
  Citation,
  CitationList,
  ConstrainedPageStyle,
  LowerRomanOl,
  P,
  PageTitle,
  PLong,
  Section,
  SectionBackground,
  SectionHeading,
  SubSectionHeading,
  TableOfContents,
  TableOfContentsWrapper,
  Ul,
} from "../styles/page";
import { BoxoutContactStyle } from "../styles/hero";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";
import { AExternal, DownloadLink } from "../components/Links";
import { CTAAnchorButtonStyle } from "../styles/links";

const RecommendationsPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>Recommendations for policy and practice</PageTitle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#the-issue">The issue</Link>
              </li>
              <li>
                <Link to="#why-it-matters">Why it matters</Link>
              </li>
              <li>
                <Link to="#key-evidence-based-recommendations">Key recommendations</Link>
              </li>
              <li>
                <Link to="#research-evidence-and-implications-for-policy-and-practice">Evidence and implications</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <BoxoutContactStyle style={{ marginBottom: "2rem" }}>
            <Section style={{ margin: "1rem" }}>
              <PLong>
                In November 2024, SAMHE published an evidence synthesis with recommendations for policy and practice to
                improve air quality in UK schools, titled 'Improving air quality in UK schools'. Read the text below or
                download '
                <DownloadLink href="/resources/SAMHE_recommendations.pdf" target="_blank">
                  Improving air quality in UK schools
                </DownloadLink>
                ' as a two-page pdf.
              </PLong>
            </Section>
          </BoxoutContactStyle>
          <Section>
            <SectionHeading id="the-issue">The issue</SectionHeading>
            <SubSectionHeading>
              Poor air quality in school buildings impacts pupils' and staff health and attention levels.
            </SubSectionHeading>
            <P>
              Poor air quality is the largest environmental risk to public health in the UK.
              <Citation href="#public-health-england-2018">1</Citation> Fine particulate matter (PM<sub>2.5</sub>) is
              responsible for a significant proportion of air pollution's health impacts.
              <Citation href="#uk-health-security-agency-2022">2</Citation> These particulate matter pollutants readily
              enter school buildings and impact indoor air quality there.
              <Citation href="#handy-et-al-2025">SA</Citation> The UK has not yet committed to meeting the 2021 WHO Air
              Quality Guidelines, designed to protect public health.
            </P>
            <P>
              UK schools' ventilation rates are low<Citation href="#wood-et-al-2024">SB</Citation> which has been linked
              to decreased pupil attention and ability to concentrate.
              <Citation href="#bako-biro-et-al-2012">3</Citation> These ventilation rates are significantly below the UK
              regulations<Citation href="#uk-government-approved-document-f-2022">4</Citation> specified for most
              workplaces (e.g. offices), despite schools being workplaces for one million adults.
              <Citation href="#department-for-education-2024">5</Citation> Low ventilation in classrooms not only leads
              to increased CO<sub>2</sub> levels but also risks the build-up of a wide range of indoor pollutants which
              have been linked to a broad range of negative health and cognitive outcomes. UK classroom ventilation is
              particularly poor in winter, doubling the risk of concentrating airborne viruses
              <Citation href="#vouriot-et-al-2021">6</Citation> and spreading disease, including to clinically
              vulnerable groups in schools. Improving classroom air quality significantly reduces illness-related
              absence during wintertime.<Citation href="#noakes-et-al-2023">7</Citation>
            </P>
            <P>
              There are currently no requirements to routinely monitor air quality in schools, nor mechanisms to enforce
              current guidelines. Current guidance for schools (BB101
              <Citation href="#department-for-education-bb101-2018">8</Citation>) is outdated as associated ventilation
              rates in classrooms are low. Some classrooms do not even meet this guidance. Updating and enforcing
              guidelines is critical to drive improvements in current school buildings and ensure investment in new
              buildings recognises the importance of indoor air.
            </P>
          </Section>

          <Section>
            <SectionHeading id="why-it-matters">Why it matters</SectionHeading>
            <P>
              Children are particularly vulnerable to air pollution because they inhale more air than adults in
              proportion to their body weight, are less able to control their exposure than adults
              <Citation href="#royal-college-of-paediatrics-and-child-health-2024">9</Citation>, and pollution can
              affect developing tissues creating lifelong health implications<Citation href="#unicef-2023">10</Citation>
              . UK children spend a quarter of their waking hours at school each year, so exposure to poor quality air
              in school buildings has a significant effect on them, impacting school attendance
              <Citation href="#noakes-et-al-2023">7</Citation> and attainment
              <Citation href="#bako-biro-et-al-2012">3</Citation>, potentially exacerbating health and educational
              inequalities.
            </P>
            <P>
              Improving air quality in schools would contribute to multiple societal and economic benefits, including:{" "}
              <b>improved public health</b>, <b>improved school attendance</b>, and <b>improved educational outcomes</b>
              .
            </P>
          </Section>

          <Section>
            <SectionHeading id="key-evidence-based-recommendations">Key evidence-based recommendations</SectionHeading>
            <Ul>
              <li>
                <b>The UK governments should commit to meeting the 2021 WHO Air Quality Guidelines</b>
              </li>
              <li>
                <b>
                  School air quality guidance should be updated to focus on providing good ventilation & good air
                  quality
                </b>
              </li>
              <li>
                <b>
                  Interventions to improve school air quality should be provided where evidence demonstrates the need
                </b>
              </li>
              <li>
                <b>
                  School leaders, staff and unions should be made aware and advised on better management of school air
                  quality
                </b>
              </li>
            </Ul>
            <P>
              SAMHE was funded by UKRI to work with schools to provide evidence and improvements in school air quality
              and link these to health outcomes and educational attainment. SAMHE analysed 38,000 schooldays of air
              quality data from 346 schools across the UK, and published the findings as:{" "}
              <sup id="handy-et-al-2025">SA </sup>
              <AExternal href="https://doi.org/10.31224/4008">Handy, et al. (2025)</AExternal> and{" "}
              <sup id="wood-et-al-2024">SB </sup>
              <AExternal href="https://doi.org/10.1016/j.dibe.2024.100520">Wood, et al. (2024).</AExternal>
            </P>
            <P>References for all other evidence mentioned in this document are:</P>
            <CitationList>
              <li id="public-health-england-2018">
                <AExternal href="https://www.gov.uk/government/publications/health-matters-air-pollution/health-matters-air-pollution">
                  Public Health England (2018)
                </AExternal>
                ;
              </li>
              <li id="uk-health-security-agency-2022">
                <AExternal href="https://www.gov.uk/government/publications/particulate-air-pollution-health-effects-of-exposure/statement-on-the-differential-toxicity-of-particulate-matter-according-to-source-or-constituents-2022">
                  UK Health Security Agency (2022)
                </AExternal>
                ;
              </li>
              <li id="bako-biro-et-al-2012">
                <AExternal href="https://doi.org/10.1016/j.buildenv.2011.08.018">Bakó-Biró, et al. (2012)</AExternal>;
              </li>
              <li id="uk-government-approved-document-f-2022">
                <AExternal href="https://www.gov.uk/government/publications/ventilation-approved-document-f">
                  UK Government, Approved Document F (2022)
                </AExternal>
                ;
              </li>
              <li id="department-for-education-2024">
                <AExternal href="https://explore-education-statistics.service.gov.uk/find-statistics/school-workforce-in-england">
                  Department for Education (2024)
                </AExternal>
                ;
              </li>
              <li id="vouriot-et-al-2021">
                <AExternal href="https://doi.org/10.1111/ina.12818">Vouriot, et al. (2021)</AExternal>;
              </li>
              <li id="noakes-et-al-2023">
                <AExternal href="https://doi.org/10.1136/archdischild-2023-rcpch.165">Noakes, et al. (2023)</AExternal>;
              </li>
              <li id="department-for-education-bb101-2018">
                <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
                  Department for Education, BB101 (2018)
                </AExternal>
                ;
              </li>
              <li id="royal-college-of-paediatrics-and-child-health-2024">
                <AExternal href="https://www.rcpch.ac.uk/resources/air-pollution-uk-position-statement">
                  Royal College of Paediatrics and Child Health (2024)
                </AExternal>
                ;
              </li>
              <li id="unicef-2023">
                <AExternal href="https://www.unicef.org/eca/media/30816/file/Breathless%20beginnings.pdf">
                  UNICEF (2023)
                </AExternal>
                .
              </li>
            </CitationList>
            <P>
              SAMHE continues to gather data and currently receives over 100,000 days of data from over 600 UK schools
              each year.
            </P>
          </Section>

          <Section>
            <SectionHeading id="research-evidence-and-implications-for-policy-and-practice">
              Research evidence and implications for policy and practice
            </SectionHeading>
            <BorderedTable>
              <thead>
                <tr>
                  <th style={{ width: "45%", backgroundColor: "#d3dafe" }}>Findings and evidence</th>
                  <th style={{ backgroundColor: "#d3dafe" }}>Recommendation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Right to clean air</b>
                    <br />
                    PM<sub>2.5</sub> levels measured in schools correlate closely with background PM<sub>2.5</sub>{" "}
                    levels measured outdoors, and the data suggests outdoor PM<sub>2.5</sub> is a major contributor to
                    air pollution in UK classrooms.
                    <Citation href="#handy-et-al-2025">SA</Citation>
                  </td>
                  <td>
                    FAO: UK government and devolved administrations; Secretary of State for Environment, Food and Rural
                    Affairs
                    <LowerRomanOl>
                      <li>
                        Enact a Clean Air Act to establish a legal right to clean air in all four nations of the UK.
                      </li>
                      <li>Develop a clear strategy to meet the 2021 WHO Air Quality Guidelines.</li>
                    </LowerRomanOl>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Policy and Guidance</b>
                    <P>
                      BB101 guidance<Citation href="#department-for-education-bb101-2018">8</Citation> requires
                      improvement as many classrooms meet the guidance whilst having ventilation rates
                      <Citation href="#wood-et-al-2024">SB</Citation> that are far lower than the minimum ventilation
                      rates specified for most workplaces.
                      <Citation href="#uk-government-approved-document-f-2022">4</Citation> Schools with pupil numbers
                      above the stated GIAS 'school capacity' exhibited significantly lower per-person ventilation
                      rates.<Citation href="#wood-et-al-2024">SB</Citation>
                    </P>
                    <P>
                      The daily mean PM<sub>2.5</sub> concentration, measured across the schools, was 4.5μg/m
                      <sup>3</sup>.<Citation href="#handy-et-al-2025">SA</Citation> For comparison, WHO annual mean
                      guidance values are currently 5μg/m<sup>3</sup>.
                    </P>
                  </td>
                  <td>
                    FAO: Department for Education (and equivalent departments in the devolved nations)
                    <LowerRomanOl start={3}>
                      <li>
                        Update BB101<Citation href="#department-for-education-bb101-2018">8</Citation>, changing the
                        focus towards achieving good classroom ventilation with consideration to pupil and staff health
                        (e.g. defined as CO<sub>2</sub> concentrations &lt; 800 ppm by SAGE during the pandemic), and
                        good air quality (e.g. ensuring classrooms PM
                        <sub>2.5</sub> levels adhere to WHO annual mean guidance values over the school year). Work with
                        school leaders and other school staff to work towards achieving the guidance.
                      </li>
                      <li>
                        Change and enforce legislation to prevent schools and classrooms exceeding their stated pupil
                        capacity, as exceedance of this contributes to poor air quality.
                      </li>
                    </LowerRomanOl>
                    FAO: School heads/leaders, CEOs and buildings managers
                    <LowerRomanOl start={5}>
                      <li>
                        Provide consistent guidance to school staff: manage good ventilation even on cold days, use air
                        quality monitors, and consider the air quality impacts of occupant behaviour and building
                        renovations.
                      </li>
                    </LowerRomanOl>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Monitoring</b>
                    <br />
                    SAMHE has demonstrated that longitudinal monitoring of school air quality can be achieved at scale,
                    and in a cost effective manner.<Citation href="#handy-et-al-2025">SA,</Citation>
                    <Citation href="#wood-et-al-2024">SB</Citation>
                  </td>
                  <td>
                    FAO: Department of Health & Social Care, Department for Education (and equivalent departments in the
                    devolved nations)
                    <LowerRomanOl start={6}>
                      <li>
                        Ensure children's health in schools is protected by establishing regular monitoring of CO
                        <sub>2</sub> (as a proxy for ventilation) and PM<sub>2.5</sub> in all occupied spaces in all UK
                        schools. Ideally this would also include opportunities for learning about ventilation and air
                        quality.
                      </li>
                    </LowerRomanOl>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Building technology</b>
                    <br />
                    Air filters can reduce the concentrations of PM<sub>2.5</sub> in classrooms by 40%-50% during
                    wintertime. Air filters significantly reduced illness-related absence during the pandemic
                    <Citation href="#noakes-et-al-2023">7</Citation>. Engineering retro-fit technologies, which provide
                    a greater control of ventilation rates, are available both at classroom and building level.
                  </td>
                  <td>
                    FAO: Department for Education and Department of Health & Social Care (and equivalent departments in
                    the devolved nations)
                    <LowerRomanOl start={7}>
                      <li>
                        Where either PM<sub>2.5</sub> monitoring indicates air quality is not good, or CO<sub>2</sub>{" "}
                        levels indicate ventilation is not good, mitigation measures should be provided for schools. In
                        the first instance, ventilation should be improved, but where this is not possible or
                        sufficient, other measures, such as air cleaners, should be provided.
                      </li>
                      <li>
                        Provide guidance for schools and Local Authorities on what retro-fit/maintenance work can
                        improve air quality (e.g. fixing windows which don't open, cleaning ventilation filters, etc.)
                      </li>
                    </LowerRomanOl>
                    FAO: School architects, builders and those who carry out school maintenance
                    <LowerRomanOl start={9}>
                      <li>
                        Ensure new school buildings, and all renovations to school buildings, are designed to achieve
                        good ventilation and air quality, and that the impact of these works on ventilation and air
                        quality are evaluated.
                      </li>
                    </LowerRomanOl>
                  </td>
                </tr>
              </tbody>
            </BorderedTable>
            <br />
            <CTAAnchorButtonStyle href="/resources/SAMHE_recommendations.pdf" target="_blank" download>
              Download this Document
            </CTAAnchorButtonStyle>
          </Section>

          <ScrollTopButton />
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default RecommendationsPage;
