import styled from "styled-components";
import { CTAButtonStyle } from "../../../styles/button";
import { BOX_DIM_PADDED } from "../UserScoreWidget/style";

export const SCORE_PANEL_HEIGHT = BOX_DIM_PADDED + 1;

export const NewAchievementPopupStyle = styled.div<{ open: boolean; closing: boolean }>`
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  animation: none;

  pointer-events: ${({ open, closing }) => (closing || !open ? "none" : "auto")};

  transition: all 1s ease-in-out;
  overflow: hidden;
  &:before {
    content: "";
    z-index: -1;
    padding-right: 0;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease;
    background: ${({ theme }) => theme.colors.appBackground};
    opacity: ${({ open, closing }) => (closing || !open ? 0 : 0.95)};
    pointer-events: none;
    padding-bottom: 0;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
`;

export const MultipleAchievementsBannerWrapper = styled.div<{ open: boolean; closing: boolean }>`
  padding: ${({ open }) => (open ? "1" : "0")}rem;
  transition: all 1s ease-in-out;

  margin-top: 6rem;
  margin-right: 1rem;
  padding-bottom: ${SCORE_PANEL_HEIGHT}rem;

  display: flex;
  flex-direction: column;
  overflow: ${({ open, closing }) => (closing || !open ? "hidden" : "auto")};

  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 80%;
    border-radius: 0 2rem 0 0;
  }
  &:after {
    content: "";
    height: 20rem;
    width: 100%;
  }
`;

const STAGGER = 0.2;
export const AchievementPopupStyleWrap = styled.div<{
  index: number;
  open: boolean;
  closing: boolean;
}>`
  margin-bottom: -1rem;
  margin-left: -1rem;

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-120vw);
      max-height: 0rem;
      margin-bottom: 0rem;
    }
    30% {
      transform: translateX(-120vw);
      margin-bottom: 1rem;
      max-height: 15rem;
    }
    100% {
      transform: translateX(0);
      margin-bottom: 1rem;
      max-height: 15rem;
    }
  }

  @keyframes slideOutToLeft {
    0% {
      transform: translateX(0);
      max-height: 15rem;
      margin-bottom: 1rem;
    }
    70% {
      transform: translateX(-120vw);
      max-height: 15rem;
      margin-bottom: 1rem;
    }
    100% {
      transform: translateX(-120vw);
      max-height: 0;
      margin-bottom: 0;
    }
  }

  animation-name: ${({ closing, open }) => (closing || !open ? "slideOutToLeft" : "slideInFromLeft")};
  animation-duration: ${({ closing, open }) => (closing || !open ? "0.8" : "0.5")}s;
  animation-timing-function: ease-in-out;
  animation-delay: ${({ index, closing }) => (closing ? 0 : index * STAGGER)}s;
  animation-fill-mode: both;

  ${({ open }) =>
    open
      ? ``
      : `
      transform: translateX(-120%);
      animation-name: slideOutToLeft;
      animation-duration: 0.2s;
      animation-delay: 0;
      `}
`;

export const AchievementPopupStyle = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  z-index: 10;

  border-radius: 0 2rem 0 0;
  padding: 1rem;
  width: fit-content;
  overflow: hidden;
  max-height: 15rem;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.7), 1px 1px 2px rgba(0, 0, 0, 0.7), 0 0 8px rgba(0, 0, 0, 0.3),
    0 0 14px rgba(0, 0, 0, 0.2);

  box-shadow: 4px 2px 15px 0px rgba(0, 0, 0, 0.2), inset 1px 1px 8px 2px rgba(0, 0, 0, 0.2),
    inset 0 0 3rem 1rem ${({ color }) => color}, inset 50rem 2rem 8rem 1rem ${({ color }) => color};
  * {
    color: white;
    font-weight: 600;
  }
  p {
    font-size: 18px;
  }
  box-shadow-opacity: 0.4;
  border: 2px solid white;
`;

export const AchievementLabelStyle = styled.h2``;

export const AchievementContentStyle = styled.div<{ color: string }>`
  // background: ${({ color }) => color};
  padding: 1rem;
  border-radius: 0.2rem;
`;

export const AchievementAcceptBtnStyle = styled(CTAButtonStyle)`
  background: white;
  margin-left: 1rem;
  padding: 2rem;
  &:hover {
    background: #ddd;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
  }
`;

export const AchievementCategoryIconStyle = styled.div<{ color: string }>`
  min-width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 1rem;

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12rem;
    box-shadow: inset 0 0 0.3rem 0.3rem ${({ color }) => color}, inset 0 0 10px 3px black;
    border: 1px solid white;
    opacity: 0.8;
  }
  img {
    width: 60%;
    max-height: 60%;
    max-width: 6rem;
  }
`;

export const CloseAllAchievementsBtnStyle = styled(CTAButtonStyle)<{ closing: boolean; open: boolean }>`
  transition: all 0.3s ease;
  opacity: ${({ closing, open }) => (closing || !open ? 0 : 1)};
  position: absolute;
  top: 0;
  right: 0;
  width: 14rem;
  height: 6rem;

  font-size: ${({ theme }) => theme.typography.fontSize4};
  background-color: ${({ theme }) => theme.colors.infoButtonBackground};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.tertiaryHover};
  }
`;

export const NewScorePopupWrap = styled.div<{ open: boolean; closing: boolean }>`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  align-items: end;
  position: absolute;
  gap: 2.5rem;
  padding-left: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${SCORE_PANEL_HEIGHT + 1}rem; // Makes sure can fit expanding elements
  z-index: 10000;
  padding-bottom: 2rem;

  scroll-padding-right: 6rem;
  scroll-padding-left: 20rem;

  * {
    display: ${({ open }) => (open ? "block" : "none")};
  }

  &:before {
    transition: all 0.7s ease;
    border-top: 1px solid white;
    content: "";
    position: fixed;
    height: ${SCORE_PANEL_HEIGHT}rem;
    max-height: ${({ open, closing }) => (closing || !open ? 0.2 : SCORE_PANEL_HEIGHT)}rem;

    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: 1px 1px 20px 4px rgb(0 0 0 / 20%);
    background: white;
    opacity: ${({ open, closing }) => (closing || !open ? 0 : 0.85)};

    @keyframes slideInFromBottom {
      0% {
        max-height: 0;
        transform: translateY(120%);
      }
      100% {
        transform: translateY(0);
        max-height: ${SCORE_PANEL_HEIGHT}rem;
      }
    }

    animation-name: slideInFromBottom;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
  }
`;
export const NewScorePopup = styled.div<{ index: number; open: boolean; closing: boolean }>`
  z-index: 9999999;
  left: 0;
  padding: 1rem;
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(120%);
    }
    30% {
      transform: translateY(120%);
    }
    80% {
      transform: translateY(-10%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideOutToBottom {
    0% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(120%);
    }
    100% {
      transform: translateY(120%);
    }
  }
  transition: opacity 1s ease, padding 0.3s ease, margin-left: 0.3s ease;
  opacity: ${({ closing, open }) => (closing || !open ? 0 : 1)};

  animation-name: ${({ closing, open }) => (!open || closing ? "slideOutToBottom" : "slideInFromBottom")};
  animation-duration: ${({ closing }) => (closing ? "0.8" : "0.5")}s;
  animation-timing-function: ease-in-out;
  animation-delay: ${({ index, closing }) => (closing ? 0 : index * STAGGER)}s;
  animation-fill-mode: both;

  margin-left: 0;

  &:focus-within {
    z-index: 99900000;

    &:first-child{
      // margin-left: 3rem;
    }
  }
`;
