import styled, { css } from "styled-components";
import { ButtonNoFormatCss, CTAButtonStyle } from "../../styles/button";
import { CollapsableSection } from "../../styles/collapsable";

export const BoardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  /* Handle react-masonry-css */
  .masonry_grid {
    display: flex;

    margin-left: -${({ theme }) => theme.shape.defaultPaddingWide}; /* gutter size offset */
    margin-right: -${({ theme }) => theme.shape.defaultPaddingWide}; /* gutter size offset */

    margin-top: ${({ theme }) => theme.shape.defaultPaddingWide};
    flex-grow: 1;

    max-width: calc(100% + ${({ theme }) => theme.shape.defaultPaddingWide});
  }
  .masonry_column {
    border-left: ${({ theme }) => theme.shape.defaultPaddingWide} solid transparent;
    background-clip: padding-box;
  }
`;

export const CardButtonsStyle = styled.div``;

export const CardBoardMenuWrapStyle = styled.div`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardBoardMenuStyle = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;

  // TODO: Should use specific button styles
  *.button,
  button {
    width: 6rem;
    border-radius: 0;
    border: none;
    margin-right: 1rem;
    box-shadow: none;
    &:active {
    }
  }
  input {
    border: none;
  }
`;
export const CardBoardMenuContentStyle = styled.div`
  width: 100%;
  height: 100%;
`;

export const CardContainerStyle = styled.div`
  min-width: 10px;
  min-height: 10px;
  position: relative;
`;

export const CardStyle = styled.div`
  border-radius: ${({ theme }) => theme?.shape?.defaultRadius || "1rem"};
  margin-bottom: 1rem; // ${({ theme }) => theme.shape.defaultPaddingWide};
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  background: ${({ theme }) => theme.background};

  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in;
  outline: 2px solid rgba(0, 0, 0, 0);

  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.tertiary};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
  }
`;

export const CardWithLogoStyle = styled.div`
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  background: ${({ theme }) => theme.background};
  margin-top: 8rem;
  position: relative;
  transition: all 0.1s ease-in;
  outline: 2px solid rgba(0, 0, 0, 0);
  min-height: 20rem;
`;

export const CardOuterStyle = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme?.shape?.defaultRadius || "1rem"};
  border: 3px solid ${({ theme }) => theme.background};
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.baseBackground};
  background-position: center;
  background-size: cover;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingWide};
  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.main};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
  }
`;

export const CardIconStyle = styled.img`
  background: ${({ theme }) => theme.colors.main};
  width: 6rem;
  border-radius: 100rem;
  z-index: 1;
  transition: box-shadow 0.2s ease-in;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0);
  &:hover {
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const CardIconBtnCss = css`
  ${ButtonNoFormatCss}
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: -8rem;
  padding: 0.5rem; // stops image clipping by circle border
  background: ${({ theme }) => theme.background};
  border-radius: 100rem 100rem 0 0;
  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
  position: relative;
  &:hover {
    &:after {
      box-shadow: 0px -7px 6px 1px rgba(0, 0, 0, 0.4);
    }
  }
  &:after {
    transition: box-shadow 0.2s ease-in;
    content: "";
    display: block;
    // background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: -0.1rem;
    right: 0;
    bottom: 1.5rem;
    top: 0;
    box-shadow: 0px -7px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 100rem 100rem 0 0;
  }
`;

export const CardIconBtn = styled.button`
  ${CardIconBtnCss}
`;

export const CardIconBtnLink = styled.span`
  ${CardIconBtnCss}
`;

export interface ICardTitleProps {
  attention?: boolean;
}

export const CardTitleBar = styled.div`
  width: 100%;
`;

export const CardTitle = styled.h1<ICardTitleProps>`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize5};
  font-weight:bold;
  width: 100%;
  button {
    width: 100%;
  }
  @keyframes mymove {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 1;
  animation: ${({ attention }) => (attention ? "mymove 0.5s infinite" : "none")};
  &:hover {
    animation: none;
  }
  * {
    display: block;
    font-weight: 800;
    text-shadow 0 0 8px rgba(0,0,0,0.4);
    font-size: ${({ theme }) => theme.typography.fontSize4};
    line-height: ${({ theme }) => theme.typography.lineHeight2};
  }
`;

export const CardInfoSectionWrapper = styled(CollapsableSection)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingWide};
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-bottom: 0;
  }
`;

export const CardInfoSection = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.mainLighter};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  margin-right: ${({ theme }) => theme.shape.defaultPadding};
  padding-right: 0; // Fixes child margin right
  padding-bottom: 0; // Fixes child margin right
  max-width: 100%;
`;

export const CardInfoSectionItem = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  margin-right: ${({ theme }) => theme.shape.defaultPadding};
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  transition: all 0.2s ease;
  flex-direction: column;
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  background: ${({ theme }) => theme.colors.main + "18"};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  flex-grow: 0;

  & > h2 {
    width: fit-content;
    line-height: 100%;
    background: ${({ theme }) => theme.background};
    border-radius: 0 0 1rem 0;
    padding: 0 ${({ theme }) => theme.shape.defaultPadding} ${({ theme }) => theme.shape.defaultPadding} 0;
    margin-bottom: 0.8rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    // max-height: 30rem;
  }
`;

export const CardInfoSubSection = styled(CardInfoSectionItem)<{ vertical?: boolean }>`
  flex-grow: 1;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 30%;
    min-width: 30%;
  }
`;

export const CardInfoSectionItemContent = styled.div`
  padding: ${({ theme }) => theme.shape.defaultPadding};
  flex-grow: 1;
`;

export const HideInfoSectionButton = styled(CTAButtonStyle)`
  z-index: 2;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  margin-top: ${({ theme }) => theme.shape.defaultPadding};
`;

export const ActivityType = styled.span`
  float: right;
  margin: 0.3rem;
  color: ${({ theme }) => theme.background};
`;

export const ActivityCardSection = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  max-width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    flex-direction: column;
  }
`;

export const ActivityCardSubSection = styled.div<{ flexBasis?: string }>`
  flex-basis: ${({ flexBasis }) => flexBasis || "auto"};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-basis: 100%;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
`;

export const DetailsSection = styled.p`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  * {
    line-height: ${(props) => props.theme.typography.lineHeight0};
  }
  b {
    width: 100%;
    // text-wrap: nowrap;
  }
`;

export const LoadingCardStyle = styled(CardStyle)`
  width: 100%;
  height: 100%;
`;

export const InvalidCardStyle = styled(CardStyle)`
  border: 1px solid red;
  width: 100%;
  height: 100%;
`;

export const PlotDescriptionSection = styled.div`
  padding: 0 ${({ theme }) => theme.shape.defaultPadding};
  p,
  ul {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
  ul {
    padding-left: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
`;
