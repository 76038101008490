import React from "react";
// import { Link } from "react-router-dom";
import { DownloadLink } from "../components/Links";
import { ConstrainedPageStyle, P, PageTitle, Ul, SectionBackground } from "../styles/page";
// import { FlexBox, FlexBoxCell, InfoBlock } from "../styles/singlePageStyles";
import { Link } from "react-router-dom";
import { GetStartDiv, QuickLinksDiv, SideDiv, TechSupportPageWrapper } from "../styles/techSupport";
import TechSupport from "../components/TechSupport/techSupport";
import { CTALinkButtonStyle } from "../styles/links";

const SupportPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>Tech support</PageTitle>
      </SectionBackground>
      <TechSupportPageWrapper>
        <div className="left">
          <div>
            <GetStartDiv>
              <img src="/images/tech-support/list.png" alt="todo list" className="todo-list" />
              <P>
                <b>Wondering where to get started?</b>
              </P>
              <P>If your school has a SAMHE monitor, work through our SAMHE checklist and tick things off as you go.</P>
              <CTALinkButtonStyle to="/getting-started">Get the checklist</CTALinkButtonStyle>
              <br />
              <CTALinkButtonStyle to="/get-involved">No monitor? See other ways of getting involved</CTALinkButtonStyle>
            </GetStartDiv>
            <QuickLinksDiv>
              <P>
                <b>Already know what you're looking for?</b>
              </P>
              <Ul>
                <li>
                  <Link to="/monitor-connection-guide">Monitor connection guide</Link>
                </li>

                <li>
                  <Link to="/resources/monitor-placement-guide">Monitor placement advice</Link>
                </li>
                <li>
                  <DownloadLink href="/resources/SAMHE_Admin_information_sheet.pdf">
                    Information sheet for admin users
                  </DownloadLink>
                </li>
              </Ul>
            </QuickLinksDiv>
            <SideDiv>
              <P>
                <b>Safety & data protection</b>
              </P>
              <Ul>
                <li>
                  This <DownloadLink href="/resources/samhe_risk_assessment.pdf">monitor risk assessment</DownloadLink>{" "}
                  covers risks relating to the SAMHE monitors and documents procedures in place to mitigate those risks.
                </li>
                <li>
                  Our{" "}
                  <DownloadLink href="/resources/SAMHE_Monitors_Standard_Operating_Procedure_Staff.pdf">
                    monitor standard operating procedures
                  </DownloadLink>{" "}
                  detail safe practice for using and moving your monitor.
                </li>
              </Ul>
            </SideDiv>
            <SideDiv>
              <P>
                <b>Need more help?</b>
              </P>
              <P>
                If you're following our instructions and you're still stuck, use our{" "}
                <Link to="/support/tech-contact">technical query form</Link> to request help.
              </P>
            </SideDiv>
          </div>
        </div>
        <div className="right">
          <TechSupport />
        </div>
      </TechSupportPageWrapper>
    </ConstrainedPageStyle>
  );
};

export default SupportPage;
