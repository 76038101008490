import { DocumentStyle } from "../../styles/documents";
import { Section, P, PageTitle } from "../../styles/page";
import { TableStyle } from "../../styles/table";
import { DownloadLink } from "../Links";

export const MonitorPlacementGuideContent = (
  <DocumentStyle>
    <PageTitle className="hideFromGlossary">Where to put your SAMHE Monitor</PageTitle>
    <Section>
      <P>
        Here are 6 simple things to bear in mind when choosing where to put your SAMHE monitor. Monitor placement is
        important because it can affect the readings you get. Please also refer to our{" "}
        <DownloadLink href="/resources/SAMHE_Monitors_Standard_Operating_Procedure_Staff.pdf">
          {" "}
          standard operating procedures
        </DownloadLink>{" "}
        for advice on the safe usage of your SAMHE monitor.
      </P>
      <TableStyle>
        <tbody>
          <tr>
            <td>
              <b>Only use it indoors</b>
            </td>
            <td>SAMHE Monitors are designed to measure indoor air quality.</td>
          </tr>
          <tr>
            <td>
              <b>Remember the wire</b>
            </td>
            <td>
              Your monitor needs to be plugged in. When choosing a spot ensure that the cable does not present a trip
              hazard.
            </td>
          </tr>
          <tr>
            <td>
              <b>Find a flat surface</b>
            </td>
            <td>
              Seek out a flat surface for your monitor to sit on and make sure that the monitor is placed away from its
              edges. Your monitor should always be placed vertically, making use of the stands (if supplied).
            </td>
          </tr>
          <tr>
            <td>
              <b>Away from windows and doors</b>
            </td>
            <td>
              Place your monitor away from windows and doors if possible. Windows and doors help ventilate your room. If
              you put your monitor close to them this could distort the readings you get, giving you an inaccurate
              picture of air quality in your classroom. Ideally the monitor should also be out of direct sunlight to
              avoid the sensors within overheating.
            </td>
          </tr>
          <tr>
            <td>
              <b>Monitor height</b>
            </td>
            <td>
              Put your SAMHE monitor somewhere you can easily see it. Ideally your monitor should be placed at head
              height. Avoid placing your monitor any higher than this and always ensure it's positioned at least 50 cm
              from the floor.
            </td>
          </tr>
          <tr>
            <td>
              <b>Distance from occupants</b>
            </td>
            <td>
              Exhaled breath contains CO<sub>2</sub>. If your monitor is somewhere where it is often breathed on
              directly this will affect the readings. Although we may encourage you to explore this in experiments we'd
              recommend that your monitor normally lives somewhere at least 50 cm away from the nearest person.
            </td>
          </tr>
        </tbody>
      </TableStyle>
    </Section>
  </DocumentStyle>
);

export const MonitorPlacementGuide = () => {
  return MonitorPlacementGuideContent;
};
