import { EStepStatus } from "../../../lib/Activities/IStep";

export interface IStepStatusIcon extends React.HTMLProps<HTMLImageElement> {
  stepStatus: EStepStatus;
}

export const StepStatusIcon = ({ stepStatus, ...additionalProps }: IStepStatusIcon) => {
  switch (stepStatus) {
    case EStepStatus.BLOCKED:
      return (
        <img
          width="2rem"
          height="2rem"
          src="/images/app/icons/Locked.png"
          alt="Locked"
          {...(additionalProps as any)}
        />
      );
    case EStepStatus.NOT_STARTED:
      return (
        <img width="2rem" height="2rem" src="/images/app/icons/Ready.png" alt="Ready" {...(additionalProps as any)} />
      );
    case EStepStatus.IN_PROGRESS:
      return (
        <img
          width="2rem"
          height="2rem"
          src="/images/app/icons/In_Progress.png"
          alt="In Progress"
          {...(additionalProps as any)}
        />
      );
    case EStepStatus.FAILED:
      return (
        <img width="2rem" height="2rem" src="/images/app/icons/Ready.png" alt="Failed" {...(additionalProps as any)} />
      );
    case EStepStatus.COMPLETED:
      return (
        <img
          width="2rem"
          height="2rem"
          src="/images/app/icons/Complete.png"
          alt="Complete"
          {...(additionalProps as any)}
        />
      );
    default:
      throw Error(`Invalid step status: ${stepStatus}`);
  }
};
