import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { clipToSensibleTimeSteps, clipToTimeRange } from "./timeAggregation";

export interface IPreProcessDataOptions {
  startClipToMinute?: number;
  timeRangeFilter?: [Date, Date];
}

export const preProcessData = (
  data: IMonitorDataItem<number>[],
  options: IPreProcessDataOptions
): IMonitorDataItem<number>[] => {
  let dataCopy = [...data];
  if (options.startClipToMinute !== undefined) {
    dataCopy = clipToSensibleTimeSteps(data, options.startClipToMinute);
  }
  if (options.timeRangeFilter !== undefined) {
    dataCopy = clipToTimeRange(dataCopy, options.timeRangeFilter[0], options.timeRangeFilter[1]);
  }
  return dataCopy;
};
