import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultThunkAction } from "../reduxStore";
import { apiActivities, ICompleteActivityResponse } from "../../Api/apiActivities";
import { IActivityUser, TActivityId, IActivityUserStarted } from "../../lib/Activities/IActivity";
import { IStepSubmission, TStepId, TStepState } from "../../lib/Activities/IStep";
import { actions as notificationActions } from "../../GenericComponents/NotificationBar/Provider";
import { EMessageType } from "../../GenericComponents/NotificationBar/lib";
import { actions as achievementsActions, reduxGetUserScore } from "../Achievements/achievementsReducer";
import { RoomId } from "../../lib/School/IRoom";
import { MonitorId } from "../../lib/Monitor/IMonitor";

export interface INotLoaded {
  loaded?: false;
  loading?: boolean;
}
export interface ILoaded {
  loaded: true;
  loading: boolean;
}

export interface IActivitiesContextState {
  userActivities: ((INotLoaded | ILoaded) & (IActivityUser | IActivityUserStarted))[];
  error?: ActivitiesError;
  status: EActivityManagerStatus;
}

export enum EActivityManagerStatus {
  LOADING_ACTIVITIES,
  LOADING_ACTIVITY,
  STARTING_ACTIVITY,
  ERROR_LOADING,
  COMPLETING_ACTIVITY,
  SUBMITTING_STEP,
  IDLE,
}
export const initialState: IActivitiesContextState = {
  userActivities: [],
  status: EActivityManagerStatus.IDLE,
};
export enum EErrorMessage {
  FAILED_TO_LOAD_ACTIVITIES = "Failed to load activities",
}

export class ActivitiesError extends Error {
  statusCode: number;
  message: EErrorMessage | string;
  constructor(message = "", statusCode = 500) {
    super(message);
    this.name = "ActivitiesError";
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, ActivitiesError.prototype);
  }
}

export const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    resetUserActivities: (state, action: PayloadAction<IActivityUser[]>) => ({
      ...state,
      // TODO: Merge with currently loaded data
      userActivities: action.payload,
    }),
    resetUserActivity: (state, action: PayloadAction<IActivityUser>) => ({
      ...state,
      userActivities: [
        { ...action.payload, open: true },
        ...state.userActivities.filter((act) => act.id !== action.payload.id),
      ],
    }),
    updateUserActivity: (state, action: PayloadAction<IActivityUser>) => ({
      ...state,
      userActivities: state.userActivities.map((act) =>
        act.id === action.payload.id ? { ...action.payload, open: true } : act
      ),
    }),
    setError: (state, action: PayloadAction<ActivitiesError>) => ({
      ...state,
      error: action.payload,
    }),
    setStatus: (state, action: PayloadAction<EActivityManagerStatus>) => ({
      ...state,
      status: action.payload,
    }),
  },
});

export const actions = activitySlice.actions;

export default activitySlice.reducer;

export const reduxStartActivity =
  (token, activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(actions.setStatus(EActivityManagerStatus.STARTING_ACTIVITY));
    await apiActivities.startActivity(activityId, token, monitorRefId, roomId);
    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
  };

export const reduxRestartActivity =
  (token, activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(actions.setStatus(EActivityManagerStatus.STARTING_ACTIVITY));
    await apiActivities.startActivity(activityId, token, monitorRefId, roomId);
    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
  };

export const reduxPostCompleteActivity =
  (token: string, activityId: TActivityId, responseData: ICompleteActivityResponse): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(achievementsActions.addAchievements({ achievements: responseData.data.achievements, openPopup: true }));
    dispatch(reduxGetUserScore(token));
  };

export const reduxCompleteActivity =
  (token, activityId: TActivityId, completeCheatCode?: string): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(actions.setStatus(EActivityManagerStatus.COMPLETING_ACTIVITY));
    const responseData = await apiActivities.completeActivity(activityId, token, completeCheatCode);
    // TODO: Get achievement updates here
    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
    if (responseData.ok !== false) dispatch(reduxPostCompleteActivity(token, activityId, responseData));
  };

export const reduxJumpActivity =
  (token, activityId: TActivityId): DefaultThunkAction =>
  async (dispatch) => {
    if (!token) throw new Error("Token missing!");
    dispatch(actions.setStatus(EActivityManagerStatus.COMPLETING_ACTIVITY));
    const responseData = await apiActivities.jumpActivity(activityId, token).catch((e) => {
      // TODO: Get activity label instead of id
      // TODO: Handle error types properly here
      dispatch(
        notificationActions.openMessage({
          message: `Unknown error: Failed to complete activity: ${activityId}. ${e.message}`,
          messageType: EMessageType.ERROR,
        })
      );
      return { ok: false } as { ok: false };
    });

    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
    if (responseData.ok !== false) dispatch(reduxPostCompleteActivity(token, activityId, responseData));
  };

export const reduxSubmitActivityStep =
  (
    token,
    activityId: TActivityId,
    stepId: TStepId,
    data: IStepSubmission<TStepState>,
    nextStep?: TStepId
  ): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(actions.setStatus(EActivityManagerStatus.SUBMITTING_STEP));
    await apiActivities.submitStep(activityId, stepId, data, nextStep, token);
    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
  };

export const reduxGoToActivityStep =
  (token, activityId: TActivityId, stepId: TStepId): DefaultThunkAction =>
  async (dispatch) => {
    dispatch(actions.setStatus(EActivityManagerStatus.SUBMITTING_STEP));
    await apiActivities.goToStep(activityId, stepId, token);
    const activityData = await apiActivities.get(activityId, token);
    dispatch(actions.updateUserActivity(activityData));
    dispatch(actions.setStatus(EActivityManagerStatus.IDLE));
  };
