import { setupWorker, rest } from "msw";

import { availableEndpoints as mockUserEndpoints } from "../Api/apiUser.mock";
import { availableEndpoints as mockActivityViewsEndpoints } from "../Api/apiActivities.mock";
import { availableEndpoints as mockAchievementViewsEndpoints } from "../Api/apiAchievements.mock";
import { availableEndpoints as mockDataViewsEndpoints } from "../Api/apiDataViews.mock";
import { availableEndpoints as mockMonitorDataEndpoints } from "../Api/apiMonitorData.mock";
import { availableEndpoints as mockContactFormEndpoints } from "../Api/apiContactForm.mock";
import { availableEndpoints as mockGetDataEndpoints } from "../Api/apiMonitorData.mock";
import { availableEndpoints as mockMonitorDeploymentEndpoints } from "../Api/apiMonitorDeployment.mock";
import { availableEndpoints as mockMonitorManagementEndpoints } from "../Api/apiMonitorManagement.mock";
import { availableEndpoints as mockMonitorRequestEndpoints } from "../Api/apiMonitorRequest.mock";
import { availableEndpoints as mockSchoolAdminEndpoints } from "../Api/apiSchoolAdmin.mock";
import { availableEndpoints as mockPingEndpoints } from "../Api/ping.mock";

import { MSW_LOGGER, SERVER_URL } from "../config";

const testEndpoints = [
  rest.get(`${SERVER_URL}/ping`, (req, res, ctx) => {
    // sessionStorage.clear();
    return res(
      ctx.status(200),
      ctx.json({
        ok: true,
      })
    );
  }),
];

export const getMockServer = (filterEndpoints: boolean) => {
  const mockServer = setupWorker(
    ...testEndpoints,
    ...[
      ...mockDataViewsEndpoints,
      ...mockMonitorDataEndpoints,
      ...mockContactFormEndpoints,
      ...mockAchievementViewsEndpoints,
      ...mockActivityViewsEndpoints,
      ...mockGetDataEndpoints,
      ...mockMonitorDeploymentEndpoints,
      ...mockMonitorManagementEndpoints,
      ...mockMonitorRequestEndpoints,
      ...mockUserEndpoints,
      ...mockSchoolAdminEndpoints,
      ...mockPingEndpoints,
    ]
      .filter((e) => !filterEndpoints || e.useMockApi)
      .map((e) => e.endpoint)
  );

  /* Log network requests that are managed by MSW */
  const requestMap = {};
  const requestSet = {};

  mockServer.events.on("request:match", (req) => {
    if (!MSW_LOGGER) return;
    const k = `${req.method}:${req.url}`;

    requestMap[req.id] = {
      method: req.method,
      url: req.url.toJSON(),
      referrerUrl: window.document.URL,
      body: req.body,
    };
    requestSet[k] = requestMap[req.id];
  });

  mockServer.events.on("response:mocked", async (res, reqId) => {
    if (!MSW_LOGGER) return;
    const req = requestMap[reqId];
    const responseBody = await res.json();
    requestMap[reqId].returnedBody = responseBody;
    const k = `${req.method}:${req.url}`;
    requestSet[k].returnedBody = responseBody;
  });
  return mockServer;
};
