import React from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { CTAButtonStyle } from "../../styles/button";
import { FormStyle, InputStyle, HorizSpacer } from "../../styles/form";
import { InputArea, InputWrap } from "../FormComponents/Input";
import { MultiCheckbox } from "../FormComponents/MultiCheckbox";
import {
  browsers,
  devices,
  ITechContactFormData,
  ITechContactFormSubmission,
} from "../../lib/formSubmissions/ITechContactFormSubmission";
import { apiSubmitTechContactForm } from "../../Api/apiTechContactForm";
import { VERSION } from "../../config";
import { getCurrentDateTime, getSelectValue } from "../FormComponents/utils";
import { LinkExternal } from "../Links";
import { ErrorMessageStyle } from "../FormComponents/ErrorMessage";
import { Captcha } from "../../GenericComponents/Captcha/Captcha";

export interface TechContactFormProps {}

const formDefaultValues: ITechContactFormData = {
  firstName: "",
  lastName: "",
  email: "",
  date: getCurrentDateTime().date,
  time: getCurrentDateTime().time,
  pageUrl: "",
  whatHappened: "",
  version: VERSION,
  device: "",
  browser: "",
  otherDetails: "",
  confirmAge: false,
  agreeToPrivacy: false,
};

const checkConsents = (data) => ["confirmAge", "agreeToPrivacy"].every((k) => data[k] === true);

const parseFormData = (data: ITechContactFormData, captcha: string): ITechContactFormSubmission => {
  const exactDate = new Date(
    new Date(data.date).getTime() +
      parseInt(data.time.split(":")[0]) * 3600000 +
      +parseInt(data.time.split(":")[1]) * 60000
  );
  const amendedData: ITechContactFormSubmission = {
    ...data,
    // TODO: would have been nicer to extract Uids here
    date: exactDate,
    device: getSelectValue(data.device, Object.values(devices)),
    browser: getSelectValue(data.browser, Object.values(browsers)),
    captcha: captcha,
  };
  return amendedData;
};

const TechContactForm: React.FC<TechContactFormProps> = () => {
  const [message, setMessage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [captcha, setCaptchaValue] = React.useState<string>(null);
  const navigate = useNavigate();

  const onFormSubmit = async (data: ITechContactFormData) => {
    setMessage("Submitting your message");
    if (!checkConsents(data)) {
      setMessage("Must agree to all required consents.");
      throw Error("Must agree to all required consents.");
    }
    if (!captcha) {
      setMessage("Please complete the captcha.");
      throw Error("Please complete the captcha.");
    }
    setLoading(true);

    const submissionData = parseFormData(data, captcha);

    return apiSubmitTechContactForm(submissionData)
      .then(() => {
        navigate("/support/tech-contact/success");
      })
      .catch((e) => {
        console.log(e);
        // NOTE: Must throw error to trigger useFormHook error handling
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formData = useForm<ITechContactFormData>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, formState } = formData;

  const { isSubmitted, isSubmitSuccessful, isSubmitting, isValidating, errors } = formState;

  React.useEffect(() => {
    if (isValidating || isSubmitting) {
      setMessage("-");
    }
  }, [isValidating, isSubmitting]);

  const containsErrors = Object.keys(errors).length > 0;

  const displayMessage = React.useMemo(
    () =>
      (isSubmitting && "Submitting Form.") ||
      (isSubmitted && containsErrors && "Form has validation errors. Check if you have filled in all required fields.") ||
      (isSubmitted && !isSubmitSuccessful && "There was an error submitting your answers. Please try again.") ||
      (isSubmitted && isSubmitSuccessful && "Submission successful. Navigating to success page.") ||
      message ||
      "",
    [isSubmitted, containsErrors, isSubmitSuccessful, message, isSubmitting]
  );

  const isErrorMessage = React.useMemo(() => {
    return containsErrors || (isSubmitted && !isSubmitSuccessful);
  }, [containsErrors, isSubmitSuccessful, isSubmitted]);

  return (
    <div className="contactForm_wrap">
      <FormProvider {...formData}>
        <FormStyle aria-label="Contact Form" name="contactForm" onSubmit={handleSubmit(onFormSubmit)}>
          <div style={{ display: "flex", flexGrow: 1, width: "100%", flexWrap: "wrap" }}>
            <InputWrap
              style={{ width: "20rem", flexGrow: 1 }}
              name="firstName"
              label="First Name"
              type="string"
              required
            />
            <HorizSpacer />
            <InputWrap
              style={{ width: "20rem", flexGrow: 1 }}
              name="lastName"
              label="Last Name"
              type="string"
              required
            />
            <InputWrap style={{ width: "20rem", flexGrow: 1 }} name="email" label="Email" type="email" required />
          </div>
          <InputWrap name="date" label="The date your problem occured" type="date" required />
          <InputWrap name="time" label="The time your problem occured" type="time" required />
          <InputWrap
            name="pageUrl"
            label="The page url where the problem occured"
            placeholder="e.g. https://samhe.org.uk/support"
            type="string"
          />
          <InputWrap
            name="version"
            label="The SAMHE version that you are using which can be found at the bottom of the page or in the information button in the app at the bottom right."
            type="string"
            required
          />
          <InputArea
            name="whatHappened"
            label="Describe what happened when the problem occured."
            type="area"
            required
          />
          <MultiCheckbox label="Select the type of computer or tablet you are using" name="device" options={Object.values(devices)} />
          <MultiCheckbox label="Select your browser" name="browser" options={Object.values(browsers)} />
          <InputArea
            name="otherDetails"
            label="Any other details"
            placeholder="Please provide any other relevant details including your monitor ID number (if known)"
            type="area"
          />
          <InputWrap name="confirmAge" label="I confirm that I am over the age of 13." type="checkbox" required />
          <InputWrap
            name="agreeToPrivacy"
            label={
              <>
                I agree to the terms set out in the{" "}
                <LinkExternal
                  to="/privacy/tech-contact"
                  aria-label="Open privacy policy in new tab"
                  title="SAMHE Tech Support Privacy Policy"
                >
                  SAMHE Tech Support Privacy Policy
                </LinkExternal>{" "}
              </>
            }
            type="checkbox"
            required
          />
          {isErrorMessage && <ErrorMessageStyle data-testid="displayErrorMessage">{displayMessage}</ErrorMessageStyle>}
          <br></br>
          {!isErrorMessage && <div data-testid="displayMessage">{displayMessage || " "}</div>}
          <Captcha onChange={(v) => setCaptchaValue(v)} />
          <InputStyle style={{ alignItems: "center" }}>
            <CTAButtonStyle style={{ width: "30%" }} type="submit" disabled={loading}>
              {loading ? "Loading" : "Submit"}
            </CTAButtonStyle>
          </InputStyle>
        </FormStyle>
      </FormProvider>
    </div>
  );
};

export default TechContactForm;
