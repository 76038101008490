import { IMonitorDataItem, IMonitorDataItemProcessed } from "../../../lib/MonitorData/IMonitorData";

export const clipToSensibleTimeSteps = (
  data: IMonitorDataItem<number>[],
  startClipToMinute: number = 0
): IMonitorDataItem<number>[] => {
  const startOfHourIndex =
    startClipToMinute === 0
      ? data.findIndex((d) => d.dateStamp.getMinutes() === 0)
      : data.findIndex((d) => d.dateStamp.getMinutes() % startClipToMinute === 0);
  return data.slice(startOfHourIndex);
};

export const clipToTimeRange = (
  data: IMonitorDataItem<number>[],
  startTime: Date,
  endTime: Date
): IMonitorDataItemProcessed<number>[] => {
  const filterStartTime = new Date(
    new Date("2000-01-01").setHours(
      startTime.getHours(),
      startTime.getMinutes(),
      startTime.getSeconds(),
      startTime.getMilliseconds()
    )
  );
  const filterEndTime = new Date(
    new Date("2000-01-01").setHours(
      endTime.getHours(),
      endTime.getMinutes(),
      endTime.getSeconds(),
      endTime.getMilliseconds()
    )
  );
  return data.map((d) => {
    const dateF = new Date(
      new Date("2000-01-01").setHours(
        d.dateStamp.getHours(),
        d.dateStamp.getMinutes(),
        d.dateStamp.getSeconds(),
        d.dateStamp.getMilliseconds()
      )
    );
    return {
      ...d,
      highlighted: dateF >= filterStartTime && dateF <= filterEndTime,
    };
  });
};
