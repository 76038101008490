import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { NotificationBarStyle } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { EMessageType, INotificationState } from "./lib";
import { actions } from "./Provider";

let timeoutMemory = null;
export interface INotificationBarProps {
  timeout?: number;
  errorTimeout?: number;
}

export const NotificationBar: React.FC<INotificationBarProps> = ({ timeout, errorTimeout }) => {
  const dispatch = useDispatch();
  const open = useSelector((state: { notificationState: INotificationState }) => state.notificationState.open);
  const message = useSelector((state: { notificationState: INotificationState }) => state.notificationState.message);
  const messageType = useSelector(
    (state: { notificationState: INotificationState }) => state.notificationState.messageType
  );
  const [hovering, setHovering] = useState(false);

  const closeNotificationBar = useCallback(() => {
    dispatch(actions.closeMessage());
  }, [dispatch]);

  useEffect(() => {
    if (open && !hovering) {
      clearTimeout(timeoutMemory);
      if (messageType === EMessageType.ERROR && errorTimeout) {
        timeoutMemory = setTimeout(() => closeNotificationBar(), errorTimeout);
      }
      if (messageType !== EMessageType.ERROR && timeout) {
        timeoutMemory = setTimeout(() => closeNotificationBar(), timeout);
      }
    }
    if (hovering) {
      clearTimeout(timeoutMemory);
    }
    return () => {
      clearTimeout(timeoutMemory);
    };
  }, [timeout, closeNotificationBar, open, hovering, errorTimeout, messageType]);

  if (!open) return <></>;
  return (
    <NotificationBarStyle
      onMouseOver={() => setHovering(true)}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
      onMouseOut={() => setHovering(false)}
      data-testid="notificationBar"
      open={open}
      messageType={messageType}
    >
      <p data-testid="notificationBar-message">{message}</p>
      <button type="button" className="closeBtn button-one" onClick={() => closeNotificationBar()}>
        X
      </button>
    </NotificationBarStyle>
  );
};

NotificationBar.propTypes = {
  timeout: PropTypes.number,
  errorTimeout: PropTypes.number,
};

NotificationBar.defaultProps = {
  timeout: 0,
  errorTimeout: 0,
};

export default NotificationBar;

