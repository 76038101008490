import { useCookie } from "react-use";
import { DocumentStyle } from "../../styles/documents";
import { Section, SectionHeading, P, Ul } from "../../styles/page";
import { Cookies } from "../../lib/Cookies/ECookies";
import { TableStyle } from "../../styles/table";
import { AExternal } from "../Links";

const lastUpdated = "17/06/2022";
const version = "1.0";

export const CookieRow = ({ uid, label, description }) => {
  const [value] = useCookie(uid);
  return (
    <tr>
      <td>{label}</td>
      <td>{description}</td>
      <td>{value}</td>
    </tr>
  );
};

export interface ICookiesTableProps {
  cookies: {
    uid: string | number;
    label: string;
    description: string;
  }[];
}

export const CookieTable = ({ cookies }) => (
  <TableStyle>
    <tr>
      <th>Name</th>
      <th>Description</th>
      <th>Value</th>
    </tr>
    {cookies.map((c) => (
      <CookieRow {...c} />
    ))}
  </TableStyle>
);

export const CookiePolicy = () => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>

        <P>
          A cookie is a small piece of information sent by the Website, stored by your web browser, and returned by your
          web browser to the Website whenever your use our Website.
        </P>

        <P>Examples of how cookies are used on the Website include:</P>
        <Ul>
          <li>
            Essential cookies - These are cookies that are required for the operation of a website. We use cookies to
            keep You logged in to Your account, and to ensure Your question attempts are associated with Your account.
            Cookies of this type include: SAMHE_ACCEPT_COOKIES (additional similar cookies may be added to this list at
            a later date).
          </li>
          <li>
            Analytical or performance cookies - These cookies collect information in an anonymous form about website
            use. They recognise and count the number of visitors to see how visitors use the website. Examples include
            Google Analytics cookies.
          </li>
        </Ul>
        <P>
          You may refuse to accept some or all cookies by activating the appropriate setting in Your browser. However,
          if You set Your browser settings to block all cookies, including essential cookies, You may affect the
          functionality of this and other websites you visit. Another option is to opt out of Google Analytics without
          affecting how you visit our site - for more information on opting out of being tracked by Google Analytics
          across all websites you use,{" "}
          <AExternal href="http://tools.google.com/dlpage/gaoptout">
            read about the Google Analytics Opt-out Browser Add-on
          </AExternal>.
        </P>
        <P>
          To find out more about cookies, including how to manage and delete them, see the{" "}
          <AExternal href="https://ico.org.uk/for-the-public/online/cookies/">
            Information Commissioner's Office information page
          </AExternal>
          .
        </P>
      </Section>
      <Section>
        <SectionHeading>Third-party cookies </SectionHeading>
        <P>
          The Website may have pages with embedded content from third parties such as social media sites, e.g. Twitter
          or YouTube. Cookies generated by those sites are subject to their own privacy policies and are outside the
          control of the Website and Imperial College London. Please check these policies before You submit any personal
          data to, or use, these websites. You may block third party cookies using Your browser without affecting the
          core functionality of our Website.
        </P>
      </Section>
      <Section>
        <SectionHeading>Cookies In Use</SectionHeading>
        <CookieTable cookies={Object.values(Cookies)} />
      </Section>
      <Section>
        <P>
          We may need to update this Privacy Notice periodically, so we recommend that you revisit this information from
          time to time. If there are any significant changes that arise, we will ensure that this is made clear.{" "}
        </P>
        <P> Last updated {lastUpdated} </P>
      </Section>
    </DocumentStyle>
  );
};
