import { rest } from "msw";
import { SERVER_URL } from "../config";
import { getMockMonitorData } from "../dummyData/monitorData";
import { DataMetricsMetaData, EDataMetricsStrId } from "../lib/DataViews/EDataMetrics";
import { EAggregationInterval } from "../lib/MonitorData/AggregationIntervals";
import { IApiMonitorDataReturn } from "./apiMonitorData";

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    /*apiGetMonitorData*/
    useMockApi: false,
    endpoint: rest.get<IApiMonitorDataReturn>(`${SERVER_URL}/monitorReadings/:monitorId`, (req, res, ctx) => {
      const { monitorId } = req.params;
      const start = req.url.searchParams.get("start");
      const end = req.url.searchParams.get("end");
      const metric = req.url.searchParams.get("metric");
      const aggregation = parseInt(req.url.searchParams.get("aggregatePeriod"));
      try {
        switch (monitorId) {
          case "ERROR":
            return res(ctx.status(500));
          case "NO_DATA":
            return res(
              ctx.status(500),
              ctx.json({
                data: [],
                startTime: start,
                endTime: end,
                metric: metric,
              })
            );

          default: {
            const data = metric
              ? getMockMonitorData(
                  new Date(start),
                  new Date(end),
                  metric as EDataMetricsStrId,
                  aggregation as EAggregationInterval
                )
              : {
                  // If metric is null return all
                  start: new Date(start),
                  end: new Date(end),
                  data: Object.values(DataMetricsMetaData)
                    .map(
                      (o) =>
                        getMockMonitorData(
                          new Date(start),
                          new Date(end),
                          o.strid as EDataMetricsStrId,
                          aggregation as EAggregationInterval
                        ).data
                    )
                    .reduce((acc, v) => [...acc, ...v], []),
                };
            return res(ctx.status(200), ctx.json(data));
          }
        }
      } catch (error) {
        console.info(error);
        console.info(`Failed to generate mock data with inputs: start: ${start}, end: ${end}, metric: ${metric}`);
        return res(ctx.status(500, "Failed to generate mock data"));
      }
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
