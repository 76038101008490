/* eslint-disable react/prop-types */
import React from "react";
import { VERSION } from "../../config";

const errorWrapStyle = {
  border: "1px solid red",
  color: "red",
  padding: "1px",
};

const generateBody = (errorLabel, error) =>
  encodeURIComponent(`
...add extra info here...
\n
\n
Error Title\n
===========\n
${errorLabel}\n
\n
Details\n
========\n

Version: ${VERSION}
Date: ${new Date(Date.now()).toString()}
Location: ${window.location.href}

Error Traceback:
================
${error}\n
\n
`);

const getMailTo = (addr, errorBody, subject) => `mailto:${addr}?subject=${subject}&body=${errorBody}`;

export interface ErrorInfoComponentProps {
  errorLabel: string;
  error: Error;
  addr?: string;
  emailSubject?: string;
  includeMailto?: boolean;
}

const ErrorInfoComponent: React.FC<ErrorInfoComponentProps> = ({
  errorLabel,
  error,
  includeMailto,
  addr,
  emailSubject,
}) => {
  return (
    <div style={errorWrapStyle}>
      <p>{errorLabel}</p>
      {includeMailto && (
        <p>
          <a
            href={getMailTo(addr, generateBody(errorLabel, error), emailSubject)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Submit Bug Report
          </a>
        </p>
      )}
    </div>
  );
};

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
  onError?: (error: Error, message: string) => void;
  message?: string;
  emailSubject?: string;
  addr?: string;
  includeMailto?: boolean;
}
export interface ErrorBoundaryState {
  error?: Error;
  errorLabel?: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorLabel: null,
    };
  }

  componentDidMount() {
    this.setState({
      error: null,
      errorLabel: null,
    });
  }

  componentDidCatch(error, info) {
    const { onError, message } = this.props;
    this.setState({
      error,
      errorLabel: message || "Render Failed!",
    });
    if (onError) onError(error, info);
  }

  render() {
    const { children, addr, emailSubject, includeMailto } = this.props;
    const { error, errorLabel } = this.state;
    return error ? (
      <ErrorInfoComponent
        errorLabel={errorLabel}
        error={error}
        includeMailto={includeMailto}
        addr={addr}
        emailSubject={emailSubject}
      />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;

export const wrapWithErrorBoundary = (Component, message, onError, addr, emailSubject) => (props) =>
  (
    <ErrorBoundary
      onError={onError || (() => null)}
      message={message || "Failed to Render"}
      addr={addr}
      emailSubject={emailSubject}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
