import { IDataViewFull } from "../../../../lib/DataViews/IDataView";
import { EDataMetrics } from "../../../../lib/DataViews/EDataMetrics";
import { EDataViewTypes } from "../../../../lib/DataViews/IDataView";
import { ISimplePlotAdditionalProps } from "./lib";
import { exampleTags } from "../../../../dummyData/tags";
import { keywordMap } from "../../../../dummyData/keywords";
import { EBaseLineStandardTypes } from "../../../DataViz/lib";

export const simpleTemperaturePlot: IDataViewFull<ISimplePlotAdditionalProps> = {
  id: "simpleTemperaturePlot",
  label: "Live Data(Temperature)",
  description: "Some info on temperature",
  longDescription: `
  The monitors also measure temperature. This is another useful factor to measure, because it can show how
  comfortable the room might feel to students and staff, and whether the heating systems, and the ventilation,
  are working well. If the ventilation is very good (with a CO₂ reading well below 800 ppm) but the room is too
  cold, it can also tell us that the ventilation can be reduced (by closing windows for example) to warm up the
  room. !CO2! example CO2`,
  type: EDataViewTypes.SIMPLE_PLOT,
  tags: [exampleTags.temperature],
  allowDataDownload: true,
  additionalProps: {
    metrics: [EDataMetrics.TEMPERATURE],
    baselineStandards: [
      { value: 57.8, label: "Hottest temperature ever recorded on Earth! 57.8⁰C", type: EBaseLineStandardTypes.LINE },
      { value: 20, label: "Average Room Temperature: 20⁰C", type: EBaseLineStandardTypes.LINE },
      { value: 0, label: "Freezing Temperature: 0⁰C", type: EBaseLineStandardTypes.LINE },
    ],
  },
};

export const simpleCO2Plot: IDataViewFull<ISimplePlotAdditionalProps> = {
  id: "simpleCO2Plot",
  label: "Live CO\u2082",
  description: "Some info on temperature",
  longDescription: `Carbon dioxide (CO\u2082) is a gas found in the air around us. The most common source of CO\u2082 indoors in schools is
  people who breathe it out. Because of this, it is a good indication of the ventilation each person might be
  getting : with higher CO\u2082 levels showing an increase in stale air and a lack of fresh air supplied to the
  room.`,
  type: EDataViewTypes.SIMPLE_PLOT,
  tags: [exampleTags.co2, exampleTags.air_quality],
  allowDataDownload: true,
  additionalProps: {
    metrics: [EDataMetrics.CO2],
    baselineStandards: [
      { value: 0, label: "Good: Up to 800ppm", type: EBaseLineStandardTypes.AREA, upper: 799 },
      { value: 799, label: "Stuffy: Up to 1500ppm", type: EBaseLineStandardTypes.AREA, upper: 1499 },
      { value: 1499, label: "High: Up to 3000ppm", type: EBaseLineStandardTypes.AREA, upper: 3000 },
      { value: 3000, label: "Very High: Above 3000ppm", type: EBaseLineStandardTypes.AREA, upper: 9000 },
    ],
  },
};

export const simplePlot: IDataViewFull<ISimplePlotAdditionalProps> = {
  id: "simplePlot",
  label: "Live Data",
  description: "Some info on the current data",
  longDescription: `This plot allows switching between multiple metrics.

  # Section Title

  Some info on about the metrics and air quality here.

  air pollution

  keywords: ${keywordMap[0].rtext[0]}

  `,
  allowDataDownload: true,
  type: EDataViewTypes.SIMPLE_PLOT,
  tags: [exampleTags.co2, exampleTags.temperature, exampleTags.air_quality],
  additionalProps: {
    metrics: [EDataMetrics.TEMPERATURE, EDataMetrics.CO2],
  },
};
