import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/Forms/ContactForm";
import { ConstrainedPageStyle, PageTitle, Section, PLong, SectionHeadingTop } from "../styles/page";
import { BoxoutContactStyle } from "../styles/hero";

export const ContactPageContent: React.FC = () => {
  return (
    <Section>
      <PageTitle id="contact">Get In Touch!</PageTitle>
      <PLong>
        If you want to stay in touch by signing up for our newsletter, please complete the form below. (If you want to
        access the teacher resource pack, have a specific query about data, collaboration or media, please visit our{" "}
        <Link to="/get-involved">Get Involved</Link> page to find out who to contact)
      </PLong>
      <PLong>
        If you are under 13 then please ask your parent, carer or your teacher to submit this form on your behalf. If
        you are over 13 then you can send us a message yourself.
      </PLong>
      <BoxoutContactStyle>
        <PLong style={{ margin: "1rem 0.5rem" }}>
          If you are experiencing technical issues with your monitor, please visit our{" "}
          <Link to="/support">Help Centre</Link> or use our{" "}
          <Link to="/support/tech-contact">Technical Queries Form</Link>.
        </PLong>
      </BoxoutContactStyle>
      <div id={"enquiries"} style={{ textAlign: "center", marginTop: "4rem" }}>
        <SectionHeadingTop>Contact Form</SectionHeadingTop>
      </div>
      <br></br>
      <ContactForm />
    </Section>
  );
};

const ContactPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <ContactPageContent />
    </ConstrainedPageStyle>
  );
};

export default ContactPage;
