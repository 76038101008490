import React from "react";
import styled from "styled-components";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";

export interface IVideoStepExtraProps {
  videoUrl: string;
}

const VideoStyle = styled.div`
  max-width: 853px;
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`;

const VideoEmbed = ({ videoUrl }) => (
  <VideoStyle>
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </VideoStyle>
);

export interface IVideoStepState {
  progress: "watched";
  url: string;
}

export const VideoStep = ({
  description,
  additionalProps: { videoUrl },
  onSubmit,
}: Partial<IStepProps<IVideoStepExtraProps, IVideoStepState>>) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ newValue: { progress: "watched", url: videoUrl } });
      }}
      style={{ minWidth: "30rem" }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>{" "}
      {/* TODO: Change state when video has ended */}
      <VideoEmbed videoUrl={videoUrl} />
      <CTAButtonStyle type="submit">I've watched it!</CTAButtonStyle>
    </form>
  );
};
