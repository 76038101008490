import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { ISchoolEstablishment } from "../lib/formSubmissions/ISchoolEstablishment";
import { ISchoolValidated, ISchoolAppDetails, ISchoolDetailsPrivateSearchResult } from "../lib/School/ISchoolDetails";
import { monitorIds } from "./monitorIds";

const dummyRooms = [
  {
    id: "1",
    label: "Room 1",
  },
  {
    id: "2",
    label: "Room 2",
  },
];

export const mockSchools: (ISchoolValidated & ISchoolAppDetails & ISchoolDetailsPrivateSearchResult)[] = [
  {
    id: 1,
    establishmentId: "1",
    uid: 1,
    dateCreated: new Date("2021-01-01"),
    label: "1",
    schoolId: "1",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName1",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.VALIDATING,
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
    roomList: dummyRooms,
  },
  {
    id: 2,
    establishmentId: "2",
    uid: 2,
    dateCreated: new Date("2021-01-01"),
    label: "2",
    schoolId: "2",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName2a",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 2a",
    lastName: "LastName 2a",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.VALIDATED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
  },
  {
    id: 22,
    establishmentId: "22",
    uid: 22,
    dateCreated: new Date("2021-01-01"),
    label: "22",
    schoolId: "22",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName22",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 22",
    lastName: "LastName 22",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.VALIDATED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
  },
  {
    id: 3,
    establishmentId: "3",
    uid: 3,
    dateCreated: new Date("2021-01-01"),
    label: "3",
    schoolId: "3",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName3",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 3",
    lastName: "LastName 3",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.LINKED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
    monitors: [monitorIds[0]],
  },
  {
    id: 4,
    establishmentId: "4",
    uid: 4,
    dateCreated: new Date("2021-01-01"),
    label: "4",
    schoolId: "4",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName4",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 4",
    lastName: "LastName 4",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.DEPLOYED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
    monitors: [monitorIds[1]],
  },
  {
    id: 5,
    establishmentId: "5",
    uid: 5,
    dateCreated: new Date("2021-01-01"),
    label: "5",
    schoolId: "5",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName5",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 5",
    lastName: "LastName 5",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.RECEIVED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "",
    secretWordTeacher: "",
    secretWordPupil: "",
    monitors: [monitorIds[1]],
  },
  {
    id: 6,
    establishmentId: "6",
    uid: 6,
    dateCreated: new Date("2021-01-01"),
    label: "6",
    schoolId: "6",
    monitorRequestId: 1,
    schoolAddress1: "School Address1",
    schoolAddress2: "School Address2",
    schoolAddress3: "School Address3",
    schoolAddress4: "School Address4",
    schoolName: "SchoolName6",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName 6",
    lastName: "LastName 6",
    email: "teacherEmail@school.com",
    schoolEmail: "genericEmail@school.com",
    schoolTelephone: "0909090909",
    status: ESchoolStatus.ACTIVATED,
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
    handle: "schoolhandle",
    secretWordTeacher: "teachersecret",
    secretWordPupil: "pupilsecret",
    monitors: [monitorIds[1]],
    roomList: [
      { id: "1", label: "Room 1" },
      { id: "2", label: "Room 2" },
    ],
  },
];

export const mockSchoolEstablishments: ISchoolEstablishment[] = [
  {
    establishmentId: "12354",
    name: "Dummy school establishment",
    address: "1 road a, area1, ZoneA, City 1",
    postcode: "LS1 9KJ",
    region: "dummy region",
  },
];
