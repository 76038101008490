export enum EFaqGroup {
  ACCESS_TO_DATA = "ACCESS_TO_DATA",
  ACCESSING_THE_WEB_APP = "ACCESSING_THE_WEB_APP",
  TIMELINES = "TIMELINES",
  INTERACTING_WITH_THE_MONITOR = "INTERACTING_WITH_THE_MONITOR",
  PARTNERSHIPS = "PARTNERSHIPS",
  PROJECT_BACKGROUND = "PROJECT_BACKGROUND",
  WHAT_DOES_THE_MONITOR_MEASURE = "WHAT_DOES_THE_MONITOR_MEASURE",
  INFO_FOR_PUPILS = "INFO_FOR_PUPILS",
  INFO_FOR_TEACHERS = "INFO_FOR_TEACHERS",
  OTHER = "OTHER",
}

export interface IFaqGroup {
  uid: EFaqGroup;
  label: string | React.ReactNode;
}

export const FaqGroups: Record<EFaqGroup, IFaqGroup> = {
  [EFaqGroup.PROJECT_BACKGROUND]: {
    uid: EFaqGroup.PROJECT_BACKGROUND,
    label: "Background",
  },
  [EFaqGroup.TIMELINES]: {
    uid: EFaqGroup.TIMELINES,
    label: "Timelines",
  },
  [EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE]: {
    uid: EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE,
    label: "What does the monitor measure",
  },
  [EFaqGroup.ACCESS_TO_DATA]: {
    uid: EFaqGroup.ACCESS_TO_DATA,
    label: "Access to data",
  },
  [EFaqGroup.ACCESSING_THE_WEB_APP]: {
    uid: EFaqGroup.ACCESSING_THE_WEB_APP,
    label: "Accessing the Web App",
  },
  [EFaqGroup.PARTNERSHIPS]: {
    uid: EFaqGroup.PARTNERSHIPS,
    label: "Partnerships",
  },
  [EFaqGroup.INTERACTING_WITH_THE_MONITOR]: {
    uid: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    label: "Interacting with the monitor",
  },
  [EFaqGroup.INFO_FOR_PUPILS]: {
    uid: EFaqGroup.INFO_FOR_PUPILS,
    label: "Info for pupils",
  },
  [EFaqGroup.INFO_FOR_TEACHERS]: {
    uid: EFaqGroup.INFO_FOR_TEACHERS,
    label: "Info for Teachers",
  },
  [EFaqGroup.OTHER]: {
    uid: EFaqGroup.OTHER,
    label: "Other",
  },
};

export interface IFaqItem {
  uid: string;
  label: string | React.ReactNode;
  content: React.ReactNode;
  group: EFaqGroup;
  audience: EFaqAudience[];
  highlighted?: boolean;
}

export enum EFaqAudience {
  STAFF = "staff",
  PUPILS = "pupils",
  PARENTS = "parents",
  GOVERNORS = "governors",
  OTHER = "other",
}
