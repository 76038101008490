import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { PopupPanelContext } from "@react_db_client/components.popup-panel-v2";
import { CloseBtn, ExpandedPanelInnerStyle } from "../../styles/popups";
import { IKeyword } from "../../lib/Keyword/IKeyword";
import styled from "styled-components";
import { ButtonNoFormat } from "../../styles/button";
import { TextFormatter } from "./TextFormatter";
import { KeywordContext } from "./KeyWordProvider";
import { removeFormatPrompts } from "./formatters";
import { Section } from "../../styles/page";

export interface IKeywordItemProps {
  text: string | React.ReactNode;
  data: IKeyword;
}

export const KeywordItemStyle = styled(ButtonNoFormat)`
  color ${({ theme }) => theme.colors.cta};
  text-decoration: underline;
  position: relative;
`;

export interface ITooltipProps {
  data: IKeyword;
  style: React.CSSProperties;
  attributes: any;
}

export const KeywordTooltipStyle = styled.div`
  max-width: 100%;
  max-height: 30rem;
  min-width: 20rem;
  line-height: ${({ theme }) => theme.typography.lineHeight};
  position: absolute;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.colors.main};
  top: 2.5rem;
  left: 100%;
  z-index: 100;
  padding: 1rem;
  box-shadow: 3px 0 3px 2px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 40rem;
  }

  .hideFromGlossary {
    display: none;
  }
`;

export const KeywordTooltip = React.forwardRef<HTMLDivElement, ITooltipProps>(
  ({ data: { label, description }, style, attributes }: ITooltipProps, ref) => {
    const contentRef = useRef(null);
    const [shouldShowElement, setShouldShowElement] = useState(true);
    const descriptionAsShown =
      typeof description === "string" ? <p>{removeFormatPrompts(description)}</p> : description;

    useEffect(() => {
      if (contentRef.current) {
        const contentElement = contentRef.current;
        // get element's height
        const height = contentElement.offsetHeight;

        // set max height and overflow
        contentElement.style.overflow = "hidden";
        contentElement.style.maxHeight = "10rem";

        // if content is too big, show the info link
        if (height < 160) {
          setShouldShowElement(false);
        }
      }
    }, []);

    return (
      <KeywordTooltipStyle
        data-testid="keywordTooltip"
        ref={ref}
        style={style}
        {...attributes}
        className="keyword-tooltip"
      >
        <h2>{label}</h2>
        <div ref={contentRef}>{descriptionAsShown}</div>
        {shouldShowElement && (
          <p style={{ marginTop: "1rem" }}>
            <b>Click the link for more info...</b>
          </p>
        )}
      </KeywordTooltipStyle>
    );
  }
);

export interface IKeywordPopupProps {
  data: IKeyword;
  keywordMap: IKeyword[];
}

export const KeywordPopupStyle = styled(ExpandedPanelInnerStyle)`
  background: ${({ theme }) => theme.background};
  min-width: 20rem;
  min-height: 20rem;

  max-width: 60rem;
  max-height: 40rem;
  margin: auto;
  overflow: auto;
  .popupTitle {
    margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  }
  img {
    // image should not exceed parent's width
    max-width: 100%;
  }

  .hideFromGlossary {
    display: none;
  }
`;

export const KeywordPopupHeadingStyle = styled.h2`
  margin: ${({ theme }) => theme.shape.defaultPaddingMid};
`;

export const KeywordPopup = ({ data: { label, description }, keywordMap }: IKeywordPopupProps) => {
  const { closePopup } = React.useContext(PopupPanelContext);
  const descriptionAsShown =
    typeof description === "string" ? (
      <Section>
        <TextFormatter text={description} keywordMap={keywordMap}></TextFormatter>
      </Section>
    ) : (
      description
    );
  return (
    <KeywordPopupStyle data-testid="keywordPopup" className="keywordPopup">
      <KeywordPopupHeadingStyle>{label}</KeywordPopupHeadingStyle>
      <CloseBtn onClick={() => closePopup("keywordPopup")}>x</CloseBtn>
      {/* <TextFormatter text={description} keywordMap={keywordMap}></TextFormatter> */}
      {descriptionAsShown}
    </KeywordPopupStyle>
  );
};

export const KeywordItem = ({ text, data }: IKeywordItemProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const { openKeyword } = React.useContext(KeywordContext);

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  if (!data) return <>INVALID KEYWORD {text}!</>;
  const { uid } = data;
  if (!uid) return <>INVALID KEYWORD {text}!</>;
  return (
    <KeywordItemStyle
      data-testid={`keyword_${uid}`}
      onMouseOver={() => setShowTooltip(true)}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
      onMouseOut={() => setShowTooltip(false)}
      onClick={() => openKeyword(data)}
      type="button"
    >
      <span ref={setReferenceElement}>{text}</span>
      {/* TODO: Replace this with more advanced tooltip system */}
      {showTooltip && (
        <KeywordTooltip ref={setPopperElement} data={data} style={styles.popper} attributes={attributes} />
      )}
    </KeywordItemStyle>
  );
};
