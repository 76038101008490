import React from "react";
import { useInterval } from "react-use";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStep, IStepProps, IStepUserData } from "../../../../lib/Activities/IStep";
import { DataMetricsMetaData, EDataMetrics, IMetricData } from "../../../../lib/DataViews/EDataMetrics";
import { MonitorId } from "../../../../lib/Monitor/IMonitor";
import { ErrorMessage, StepDescription } from "../../../../styles/app";
import { ButtonStyle, CTAButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { SimplePlot } from "../../../DataViz/SimplePlot/SimplePlot";
import { useDataLoader } from "../../../DataViewDashboard/dataLoader";
import { ETimeFormat } from "../../../../lib/DataViews/ETimeFormats";
import { getNextStep } from "../../nextStepUtils";
import { FlexBox } from "../../../../styles/singlePageStyles";
import { EDayRange, ETimeRange, IDateRangeState } from "../../../DateRangePicker/lib";
import {
  parseDateRangeState,
  refreshTimes,
  selectDayRange,
  selectTimeRange,
} from "../../../DateRangePicker/utils";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import { StepPlotContainer } from "./styles";
import { DataRangePlotOptions, EDateRangeSelectionType } from "./DataRangePlotOptions";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { useAppSelector } from "../../../../Redux/hooks";

export type TimeString = `${number}${number}:${number}${number}`;
export interface IUseDataLoaderArgs {
  monitorId: MonitorId;
  metric: EDataMetrics;
}

export interface IDataRangeSnapshotExtraProps {
  metric: EDataMetrics;
  // yRange?: [number, number]; // Not currently used
  allowSkip?: boolean;
  dateRangeSelectionType?: EDateRangeSelectionType;
}

export interface IDataRangeSnapshotState {
  dateRange?: IDateRangeState;
  eventStartTime?: TimeString;
  eventEndTime?: TimeString;
  metric: EDataMetrics;
  skipped: boolean;
}

export interface ISnapshotProps {
  metric: IMetricData;
  monitorId: MonitorId;
  acceptSelection: (dateRange: IDateRangeState, eventStartTime: TimeString | "", eventEndTime: TimeString | "") => void;
  skipSelection: () => void;
  allowSkip: boolean;
  initialDateRangeState?: IDateRangeState;
  dateRangeSelectionType: EDateRangeSelectionType;
}

export const defaultDateRangeState = (): IDateRangeState => ({
  startDate: selectDayRange(EDayRange.TODAY)[0],
  endDate: selectDayRange(EDayRange.TODAY)[1],
  startTimeLimit: selectTimeRange(ETimeRange.NO_LIMIT)[0],
  endTimeLimit: selectTimeRange(ETimeRange.NO_LIMIT)[1],
  invalid: false,
  dayRange: EDayRange.TODAY,
  timeRange: ETimeRange.NO_LIMIT,
  aggregation: EAggregationInterval.FIVE_MINUTE,
  maxAggregation: EAggregationInterval.FIVE_MINUTE,
  minAggregation: EAggregationInterval.FIVE_MINUTE,
});

const SelectSnapshot = ({
  metric,
  monitorId,
  acceptSelection,
  skipSelection,
  allowSkip,
  initialDateRangeState,
  dateRangeSelectionType,
}: ISnapshotProps) => {
  const { id: metricId, label, unit } = metric;
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const currentRoomMonitor = useAppSelector((state) => state.userState.state.monitor?.data?.room);
  const [dateRange, setDateRange] = React.useState<IDateRangeState>(initialDateRangeState || defaultDateRangeState());
  const [startYAxisAtZero, setStartYAxisAtZero] = React.useState(true);
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const [showMinAndMax, setShowMinAndMax] = React.useState(false);
  const [showAverage, setShowAverage] = React.useState(false);
  const [startTime, setStartTime] = React.useState<TimeString | "">("");
  const [endTime, setEndTime] = React.useState<TimeString | "">("");

  const { loadedData, dataLoadError, readyToView, loading } = useDataLoader({
    monitorId,
    currentMetric: metricId,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });
  const yLabel = `${label} (${unit})`;

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <>
      <p>Viewing data for monitor in {currentRoom?.label || currentRoomMonitor?.label}.</p>
      {readyToView && !dataLoadError ? (
        <StepPlotContainer style={{ width: "100%" }}>
          <SimplePlot
            timeFormat={ETimeFormat.TIME}
            data={loadedData?.data}
            yLabel={yLabel}
            allowScale
            showAverage={showAverage}
            showMin={showMinAndMax}
            showMax={showMinAndMax}
            yRange={startYAxisAtZero ? [0, undefined] : undefined}
            preProcessDataOptions={{
              timeRangeFilter: [dateRange.startTimeLimit, dateRange.endTimeLimit],
            }}
          />
        </StepPlotContainer>
      ) : (
        ""
      )}
      {dataLoadError && <ErrorMessage>Error loading data</ErrorMessage>}
      <br />
      <p>Use the options below to select the date range you would like to investigate:</p>
      <DataRangePlotOptions
        reloadData={() => setDateRange(refreshTimes(dateRange))}
        loading={loading}
        setDateRange={setDateRange}
        dateRange={dateRange}
        dateRangeSelectionType={dateRangeSelectionType}
        startYAxisAtZero={startYAxisAtZero}
        setStartYAxisAtZero={setStartYAxisAtZero}
        setLiveUpdate={setLiveUpdate}
        liveUpdate={liveUpdate}
        setShowAverage={setShowAverage}
        showAverage={showAverage}
        setShowMinAndMax={setShowMinAndMax}
        showMinAndMax={showMinAndMax}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        startTime={startTime}
        endTime={endTime}
      />
      <FlexBox horiz>
        <CTAButtonStyle onClick={() => acceptSelection(dateRange, startTime, endTime)}>Accept</CTAButtonStyle>
        {allowSkip && <ButtonStyle onClick={() => skipSelection()}>Skip</ButtonStyle>}
      </FlexBox>
    </>
  );
};

export const DataRangeSnapshotStep: React.FC<
  Partial<IStepProps<IDataRangeSnapshotExtraProps, IDataRangeSnapshotState>>
> = (props) => {
  const { description, onSubmit, additionalProps, monitorId, nextSteps, previousStepsState, activitySteps, state } =
    props;
  const { metric, allowSkip, dateRangeSelectionType = EDateRangeSelectionType.SIMPLE } = additionalProps;
  const metricData = DataMetricsMetaData[metric];
  if (!metricData) throw Error(`Missing metric data for ${metric}`);

  const acceptSelection = (
    dateRange: IDateRangeState,
    eventStartTime: TimeString | "",
    eventEndTime: TimeString | ""
  ) => {
    const nextStepId = null;
    const state = {
      dateRange: {
        ...dateRange,
        dayRange: EDayRange.CUSTOM, // why overwrite user's selection?
        timeRange: ETimeRange.CUSTOM,
      },
      eventStartTime: eventStartTime || undefined,
      eventEndTime: eventEndTime || undefined,
      metric: metric,
      skipped: false,
    };
    const nextStepRef = getNextStep(
      nextStepId as string,
      nextSteps,
      previousStepsState,
      activitySteps,
      props as IStep<IDataRangeSnapshotExtraProps>,
      { ...props, state } as IStepUserData<IDataRangeSnapshotState>
    );
    onSubmit({ newValue: state, nextStep: nextStepRef });
  };

  const skipSelection = () => {
    const nextStepId = null;
    const state = {
      dateRange: null,
      metric: metric,
      skipped: true,
    };
    const nextStepRef = getNextStep(
      nextStepId as string,
      nextSteps,
      previousStepsState,
      activitySteps,
      props as IStep<IDataRangeSnapshotExtraProps>,
      { ...props, state } as IStepUserData<IDataRangeSnapshotState>
    );

    onSubmit({ newValue: state, nextStep: nextStepRef });
  };

  if (!metric) return <>Missing Metric!</>;

  return (
    <>
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <SelectSnapshot
        metric={metricData}
        monitorId={monitorId}
        acceptSelection={acceptSelection}
        skipSelection={() => skipSelection()}
        allowSkip={allowSkip}
        initialDateRangeState={parseDateRangeState(state?.dateRange || defaultDateRangeState())}
        dateRangeSelectionType={dateRangeSelectionType}
      />
    </>
  );
};
