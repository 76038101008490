import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiAchievements } from "../../Api/apiAchievements";
import { AchievementCategories, EAchievementCategory } from "../../lib/Achievements/EAchievementCategory";
import { IAchievementUser, IUserScores, IUserScoreUpdates, TAchievementId } from "../../lib/Achievements/IAchievement";
import { DefaultThunkAction } from "../reduxStore";

export interface IAchievementPopupState {
  open: boolean;
  currentAchievement: null | IAchievementUser;
}

export interface IAchievementsContextState {
  achievements: IAchievementUser[];
  achievementPopup: IAchievementPopupState;
  userScore: IUserScores;
}

export const achievementPopupInitialState: IAchievementPopupState = {
  open: false,
  currentAchievement: null,
};

export const initialState: IAchievementsContextState = {
  achievements: [],
  achievementPopup: achievementPopupInitialState,
  userScore: {
    [EAchievementCategory.QUIZ]: {
      ...AchievementCategories[EAchievementCategory.QUIZ],
      category: EAchievementCategory.QUIZ,
      value: 0,
    },
    [EAchievementCategory.TOP_RESPONDANT]: {
      ...AchievementCategories[EAchievementCategory.TOP_RESPONDANT],
      category: EAchievementCategory.TOP_RESPONDANT,
      value: 0,
    },
    [EAchievementCategory.TEAM_STUDENT]: {
      ...AchievementCategories[EAchievementCategory.TEAM_STUDENT],
      category: EAchievementCategory.TEAM_STUDENT,
      value: 0,
    },
    [EAchievementCategory.CHIEF_INVESTIGATOR]: {
      ...AchievementCategories[EAchievementCategory.CHIEF_INVESTIGATOR],
      category: EAchievementCategory.CHIEF_INVESTIGATOR,
      value: 0,
    },
    [EAchievementCategory.CONTENT_CREATOR]: {
      ...AchievementCategories[EAchievementCategory.CONTENT_CREATOR],
      category: EAchievementCategory.CONTENT_CREATOR,
      value: 0,
    },
    [EAchievementCategory.EXPERIMENT_WHIZZ]: {
      ...AchievementCategories[EAchievementCategory.EXPERIMENT_WHIZZ],
      category: EAchievementCategory.EXPERIMENT_WHIZZ,
      value: 0,
    },
    [EAchievementCategory.DATA_ANALYST]: {
      ...AchievementCategories[EAchievementCategory.DATA_ANALYST],
      category: EAchievementCategory.DATA_ANALYST,
      value: 0,
    },
    [EAchievementCategory.HERO]: {
      ...AchievementCategories[EAchievementCategory.HERO],
      category: EAchievementCategory.HERO,
      value: 0,
    },
  },
};

export const achievementsSlice = createSlice({
  name: "achievements",
  initialState,
  reducers: {
    clearStoredAchievements: (state) => ({
      ...state,
      achievements: [],
      achievementPopup: achievementPopupInitialState,
    }),
    addAchievement: (state, action: PayloadAction<{ achievement: IAchievementUser; openPopup?: boolean }>) => ({
      ...state,
      achievements: [...state.achievements, action.payload.achievement],
      achievementPopup: {
        open: action.payload.openPopup || state.achievementPopup.open,
        currentAchievement: action.payload.openPopup
          ? action.payload.achievement
          : state.achievementPopup.currentAchievement,
      },
    }),
    addAchievements: (state, action: PayloadAction<{ achievements: IAchievementUser[]; openPopup?: boolean }>) => ({
      ...state,
      achievements: [...state.achievements, ...action.payload.achievements],
      achievementPopup: {
        open: action.payload.openPopup || state.achievementPopup.open,
        currentAchievement: action.payload.openPopup
          ? action.payload.achievements[0]
          : state.achievementPopup.currentAchievement,
      },
    }),
    removeAchievement: (state, action: PayloadAction<{ achievementId: TAchievementId; closePopup?: boolean }>) => ({
      ...state,
      achievements: state.achievements.filter((a) => a.id !== action.payload.achievementId),
      achievementPopup: {
        open: action.payload.closePopup === true ? false : state.achievementPopup.open && state.achievements.length > 1,
        currentAchievement:
          state.achievementPopup.currentAchievement?.id === action.payload.achievementId
            ? null
            : state.achievementPopup.currentAchievement,
      },
    }),
    removeAllAchievement: (state, action: PayloadAction<{ closePopup?: boolean }>) => ({
      ...state,
      achievements: [],
      achievementPopup: {
        open: action.payload.closePopup === true ? false : state.achievementPopup.open,
        currentAchievement: null,
      },
    }),
    closePopup: (state) => ({
      ...state,
      achievementPopup: {
        open: false,
        currentAchievement: state.achievementPopup.currentAchievement,
      },
    }),
    updateScore: (state, action: PayloadAction<IUserScoreUpdates>) => ({
      ...state,
      userScore: {
        ...state.userScore,
        ...Object.entries(action.payload).reduce(
          (acc, [k, v]) => ({ ...acc, [k]: { ...state.userScore[k], value: v } }),
          {}
        ),
      },
    }),
  },
});

export const actions = achievementsSlice.actions;

export default achievementsSlice.reducer;

export const reduxGetUserScore =
  (token: string): DefaultThunkAction =>
  async (dispatch) => {
    const updatedUserScore = await apiAchievements.getScore(token);
    dispatch(actions.updateScore(updatedUserScore));
  };
