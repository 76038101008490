import styled from "styled-components";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { getNextStep } from "../../nextStepUtils";

export interface ITextInputExtraProps {
  type?: "text" | "number";
  min?: number;
  max?: number;
  step?: number;
}

export interface ITextInputState {
  value?: string;
}

const TextInput = styled.input`
  display: block;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-bottom: 0.5rem;
  &[type="text"] {
    width: 100%;
  }
`;

export const TextInputStep = (props: IStepProps<ITextInputExtraProps, ITextInputState>) => {
  const {
    label,
    description,
    id: uid,
    state,
    onChange,
    onSubmit,
    additionalProps,
    nextSteps,
    previousStepsState,
    activitySteps,
  } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const nextStepId = null;
        const nextStepRef = getNextStep(nextStepId as string, nextSteps, previousStepsState, activitySteps, props, {
          ...props,
          state,
        });
        onSubmit({ newValue: state, nextStep: nextStepRef });
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <TextInput
        id={`stepInput_${uid}`}
        value={(state && state.value) || ""}
        type={additionalProps?.type || "text"}
        onChange={(e) => onChange("value", e.target.value)}
        aria-label={label}
        min={additionalProps?.min}
        max={additionalProps?.max}
        step={additionalProps?.step}
      />
      <CTAButtonStyle type="submit">Ok</CTAButtonStyle>
    </form>
  );
};
