import { IDataViewFull } from "../../../../lib/DataViews/IDataView";
import { EDataMetrics } from "../../../../lib/DataViews/EDataMetrics";
import { EDataViewTypes } from "../../../../lib/DataViews/IDataView";
import { IBubblePlotAdditionalProps } from "./lib";
import { exampleTags } from "../../../../dummyData/tags";

export const simpleCO2BubblePlot: IDataViewFull<IBubblePlotAdditionalProps> = {
  id: "simpleCO2BubblePlot",
  label: "CO2",
  description: "Example Bubble Plot",
  longDescription: `Example bubble plot...`,
  type: EDataViewTypes.BUBBLE_PLOT,
  tags: [exampleTags.CO2],
  allowDataDownload: true,
  additionalProps: {
    metrics: [EDataMetrics.CO2],
    scale: 1 / 70,
    scaleMini: 9999,
    unit: "ppm",
    visualMapProps: {
      type: "piecewise",
      itemHeight: 25,
      itemWidth: 25,
      pieces: [
        { min: 0, max: 799, label: "< 799 ppm", color: "ForestGreen", opacity: 0.8 },
        { min: 800, max: 1499, label: "800 - 1499 ppm", color: "Orange", opacity: 0.8 },
        { min: 1500, max: 3000, label: "1500 - 3000 ppm", color: "Red", opacity: 0.7 },
        { min: 3000, max: 5000, label: "> 3000 ppm", color: "RebeccaPurple", opacity: 0.7 },
      ],
    },
    // metrics: [EDataMetrics.TVOC],
    // scale: 1 / 1400,
    // scaleMini: 1 / 400,
    // visualMapProps: {
    //   type: "continuous",
    //   text: ["Ind30"],
    //   unit: " Ind30",
    //   min: 0,
    //   max: 60000,
    //   itemHeight: 100,
    //   itemWidth: 30,
    //   inRange: {
    //       color: ['#98d82a', '#2cc4d9', '#b52bd8'],
    //       opacity: [0.8, 0.6]
    //   }
    // },
    visualMapPropsMini: {
      // type: "continuous",
      // text: ["Ind30"],
      // min: 0,
      // max: 60000,
      // itemHeight: 100,
      // itemWidth: 30,
      // orient: "horizontal",
      // inRange: {
      //   color: ["#98d82a", "#2cc4d9", "#b52bd8"],
      //   opacity: [0.8, 0.6],
      // },

      min: 0,
      max: 60000,
      type: "piecewise",
      itemHeight: 25,
      itemWidth: 25,
      pieces: [
        { min: 0, max: 799, label: "< 799 ppm", color: "ForestGreen", opacity: 0.8 },
        { min: 800, max: 1499, label: "800 - 1499 ppm", color: "Orange", opacity: 0.8 },
        { min: 1500, max: 3000, label: "1500 - 3000 ppm", color: "Red", opacity: 0.7 },
        { min: 3000, max: 5000, label: "> 3000 ppm", color: "RebeccaPurple", opacity: 0.7 },
      ],
    },
  },
};
