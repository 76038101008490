import React from "react";
import useAsyncRetry from "react-use/lib/useAsyncRetry";
import { apiMonitorData } from "../../Api/apiMonitorData";
import { EDataMetrics } from "../../lib/DataViews/EDataMetrics";
import { MonitorId } from "../../lib/Monitor/IMonitor";
import { EAggregationInterval } from "../../lib/MonitorData/AggregationIntervals";
import { ETimeRange, IDateRangeState } from "../DateRangePicker/lib";
import { IMonitorData } from "../../lib/MonitorData/IMonitorData";
import { ApiContext } from "../../Redux/Api/ApiContext";

export interface IUseDataLoaderArgs {
  monitorId: MonitorId;
  currentMetric: EDataMetrics | null;
  startDate?: Date;
  endDate?: Date;
  dateRange: IDateRangeState;
  blocked?: boolean;
  aggregationInterval?: EAggregationInterval;
  onLoadedData?: (data: any) => void;
}

export interface IUseMultipleMonitorsDataLoaderArgs {
  accessToken?: string;
  onLoadedData?: (data: any) => void;
}

const filterByTimeRange = (data: IMonitorData<number>, dateRange?: IDateRangeState) => {
  if (!dateRange || dateRange.timeRange === ETimeRange.NO_LIMIT) return data;
  if (dateRange.timeRange === ETimeRange.SCHOOL_HOURS) {
    return {
      ...data,
      data: data.data.filter((d) => {
        const hour = d.dateStamp.getHours();
        return hour > 8 && hour <= 15;
      }),
    };
  }
  if (dateRange.timeRange === ETimeRange.CUSTOM) {
    const { startTimeLimit, endTimeLimit } = dateRange;
    const startTimeLimitTime = startTimeLimit.getHours() * 60 + startTimeLimit.getMinutes();
    const endTimeLimitTime = endTimeLimit.getHours() * 60 + endTimeLimit.getMinutes();

    return {
      ...data,
      data: data.data.filter((d) => {
        const dataTime = d.dateStamp.getHours() * 60 + d.dateStamp.getMinutes();
        return dataTime >= startTimeLimitTime && dataTime <= endTimeLimitTime;
      }),
    };
  }
};

export const useDataLoader = ({
  monitorId,
  currentMetric,
  blocked,
  dateRange,
  aggregationInterval,
  onLoadedData,
}: IUseDataLoaderArgs) => {
  const valueCached = React.useRef<Awaited<ReturnType<typeof apiMonitorData.getRange>>>(null);
  const { apiGetMonitorData } = React.useContext(ApiContext);
  const state = useAsyncRetry(
    async () =>
      !blocked
        ? apiGetMonitorData(monitorId, currentMetric, dateRange.startDate, dateRange.endDate, aggregationInterval).then(
            (newValue) => {
              if (!newValue) return valueCached.current;
              const valueFiltered = filterByTimeRange(newValue, dateRange);
              valueCached.current = valueFiltered;
              return valueFiltered;
            }
          )
        : valueCached.current,
    [
      monitorId,
      currentMetric,
      dateRange.startDate,
      dateRange.endDate,
      blocked,
      aggregationInterval,
      dateRange.timeRange,
    ]
  );

  const [hasLoaded, setHasLoaded] = React.useState(false);
  const loadedMetric = state?.value?.metric;

  const valuePossiblyCached = state.value || valueCached.current;
  const readyToView = hasLoaded && state?.value?.data?.length; // && loadedMetric === currentMetric && state?.value?.data?.length > 0;
  const dataLoadError =
    state.error || (hasLoaded && state.value?.data?.length === 0 && new Error("No data found for your monitor"));
  const loading = state.loading || !(loadedMetric === currentMetric && valuePossiblyCached?.data?.length > 0);

  React.useEffect(() => {
    if (state.value) {
      setHasLoaded(true);
      if (onLoadedData) {
        onLoadedData(state.value);
      }
    }
  }, [onLoadedData, state]);

  return { ...state, loading, readyToView, dataLoadError, reloadData: state.retry, loadedData: valuePossiblyCached };
};

export const useMultipleMonitorsDataLoader = ({ accessToken, onLoadedData }: IUseMultipleMonitorsDataLoaderArgs) => {
  const valueCached = React.useRef<Awaited<ReturnType<typeof apiMonitorData.getRange>>>(null);
  const state = useAsyncRetry(
    async () =>
      apiMonitorData.getAll(accessToken).then((newValue) => {
        if (newValue) valueCached.current = newValue;
        if (!newValue) return valueCached.current;
        return newValue;
      }),
    []
  );

  const [hasLoaded, setHasLoaded] = React.useState(false);

  const readyToView = hasLoaded && state?.value?.data?.length; // && loadedMetric === currentMetric && state?.value?.data?.length > 0;
  const dataLoadError =
    state.error || (hasLoaded && state.value?.data?.length === 0 && new Error("No data found for your monitor"));
  const loading = state.loading || !(state?.value?.data?.length > 0);

  React.useEffect(() => {
    if (state.value) {
      setHasLoaded(true);
      if (onLoadedData) {
        onLoadedData(state.value);
      }
    }
  }, [onLoadedData, state]);

  return { ...state, loading: loading, readyToView, dataLoadError, reloadData: state.retry, loadedData: state.value };
};
