import { rest } from "msw";
import { SERVER_URL } from "../config";
import { IContactFormSubmission } from "../lib/formSubmissions/IContactFormSubmission";
import { sleep } from "../utils/testing/sleep";


export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    useMockApi: true, // This is actually implemented but we need to disable on dev env
    endpoint: rest.post<IContactFormSubmission[]>(`${SERVER_URL}/Contact/contactUs`, async (req, res, ctx) => {
      await sleep(1000);
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          ok: true,
        })
      );
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints
  .filter((e) => e.useMockApi)
  .map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
