import { rest } from "msw";
import { SERVER_URL } from "../config";
import { mockSchools } from "../dummyData";
import { ISchoolAdminFormSubmission } from "../lib/formSubmissions/ISchoolAdminSubmission";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { parseJwt } from "../utils/testing/test-utils";
import * as database from "../dummyApi/database";
import { IUser } from "../lib/User/IUser";
import { ISchoolAppDetails, ISchoolValidated } from "../lib/School/ISchoolDetails";
import { IApiGetSchoolAdminDataReturn } from "./apiSchoolAdmin";
import { IApiResponse } from "./apiHelpers";

const Ok = (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      ok: true,
    })
  );

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    /* Get school Admin Secrets */
    useMockApi: false,
    endpoint: rest.get<null, null, { data: IApiGetSchoolAdminDataReturn["data"] }>(
      `${SERVER_URL}/Schools/Secrets`,
      (req, res, ctx) => {
        const schoolData = mockSchools.find((s) => s.status === ESchoolStatus.ACTIVATED);
        const school: IApiGetSchoolAdminDataReturn["data"] = {
          ...schoolData,
          handle: schoolData.handle,
        };
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            data: school,
          })
        );
      }
    ),
  },
  {
    /* Post school Admin secrets */
    useMockApi: false,
    endpoint: rest.post<ISchoolAdminFormSubmission>(`${SERVER_URL}/Schools/Secrets`, (req, res, ctx) => {
      if (!req?.body?.schoolHandle) {
        return res(
          ctx.status(403),
          ctx.json({
            errorMessage: "Missing request body",
          })
        );
      }
      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        console.info(e);
        console.info("Failed to parse user access token", accessToken);
        return res(
          ctx.status(401),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const { userName } = tokenData;
      const userData = database.getDocs<IUser>("SchoolUsers").find((u) => u.username === userName);
      const schoolData = database.getDocs<ISchoolAppDetails>("Schools").find((s) => s.schoolId === userData.schoolId);
      const schoolDataValidated = database
        .getDocs<ISchoolValidated>("SchoolsValidated")
        .find((s) => s.schoolId === userData.schoolId);
      if (!schoolData && !schoolDataValidated) {
        console.info("Failed to find school data", userData.schoolId);
        return res(
          ctx.status(403),
          ctx.json({
            errorMessage: "School not found",
          })
        );
      }
      if (!userData) {
        console.info("Failed to find user data", userName);

        return res(
          ctx.status(403),
          ctx.json({
            errorMessage: "User not found",
          })
        );
      }
      const schoolDataFull = {
        ...(schoolDataValidated || {}),
        ...(schoolData || {}),
      };
      // @ts-ignore
      const school: ISchoolAppDetails = {
        id: schoolDataFull.id,
        schoolId: schoolDataFull.schoolId,
        ...schoolDataFull,
        handle: req.body.schoolHandle,
      };

      if (!schoolData) database.addDoc(school.id, "Schools", school);
      else database.putDoc(school.id, "Schools", school);
      return res(
        // Respond with a 200 status code
        ctx.status(200)
        // ctx.json({
        //   data: school,
        // })
      );
    }),
  },
  {
    /* Activate school */
    useMockApi: false,
    endpoint: rest.post<{}, any, IApiResponse>(`${SERVER_URL}/Schools/Activate`, (req, res, ctx) => {
      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        console.info(e);
        console.info("Failed to parse user access token", accessToken);
        return res(
          ctx.status(401),
          ctx.json({
            ok: false,
            errorMessage: "Not authorized",
          })
        );
      }
      const { userName } = tokenData;
      const userData = database.getDocs<IUser>("SchoolUsers").find((u) => u.username === userName);
      const schoolData = database.getDocs<ISchoolAppDetails>("Schools").find((s) => s.schoolId === userData.schoolId);

      const schoolActivated: ISchoolValidated = {
        ...schoolData,
        status: ESchoolStatus.ACTIVATED,
      };
      const schoolAppDetails: ISchoolAppDetails = {
        ...schoolData,
        status: ESchoolStatus.ACTIVATED,
        id: schoolData.id,
      };
      database.putDoc(schoolActivated.id, "SchoolsValidated", schoolActivated);
      database.putDoc(schoolAppDetails.id, "Schools", schoolAppDetails);
      return Ok(req, res, ctx);
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
