import { ILabelled } from "@react_db_client/constants.client-types";

export enum EEquipmentNeeded {
  NONE = 1,
  ANY = 2,
}

/* Note this should only include the below 2 options. Full list will be stored in equipmentNeededDetailed */
export const EquipmentNeeded: Record<EEquipmentNeeded, ILabelled> = {
  [EEquipmentNeeded.NONE]: {
    uid: EEquipmentNeeded.NONE,
    label: "No equipment required",
  },
  [EEquipmentNeeded.ANY]: {
    uid: EEquipmentNeeded.ANY,
    label: "Some equipment is required for this activity",
  },
};
