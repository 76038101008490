import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetExpandedCard } from "../../GenericComponents/CardBoard/useGetCards";
import {
  ExpandedCardPageBackLink,
  ExpandedCardPageBackLinkWrapper,
  ExpandedCardPageStyle,
  AppPageStyle,
} from "../../styles/app";
import { useAppSelector } from "../../Redux/hooks";
import { useGetSingleDataViewMetaData } from "../../components/DataViewDashboard/useGetDataViewMetaData";
import { cardComponentOpenTypeMap } from "../../components/DataViewDashboard/cardComponentMap";

export const DataViewPage = () => {
  const { dataViewId } = useParams<{ dataViewId: string }>();
  const {
    state: { monitor: { id: monitorId } = { id: null } },
  } = useAppSelector((state) => state.userState);
  const { card: cardData } = useGetSingleDataViewMetaData({ id: dataViewId, monitorId: monitorId });
  const card = useGetExpandedCard({ cardData: cardData, cardComponentTypeMap: cardComponentOpenTypeMap });
  return (
    <AppPageStyle data-testid="samheAppContent">
      <ExpandedCardPageStyle
        style={{ height: "100%", position: "relative", alignItems: "end" }}
        data-testid="appPage-dataViewPage"
      >
        <ExpandedCardPageBackLinkWrapper>
          <ExpandedCardPageBackLink to="/app/data">
            <FontAwesomeIcon style={{ marginRight: "0.3rem" }} icon={faCircleLeft} />
            Back to Data Views page
          </ExpandedCardPageBackLink>
        </ExpandedCardPageBackLinkWrapper>
        {card}
      </ExpandedCardPageStyle>
    </AppPageStyle>
  );
};
