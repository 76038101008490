import { EFilterType, FilterOption } from "@react_db_client/constants.client-types";
import { TActivityId } from "../Activities/IActivity";
import { EAchievementCategory } from "./EAchievementCategory";

export type TAchievementId = string | number;

export enum EAchievementType {
  ACTIVITY_COMPLETE = 1,
  SCORE_REACHED = 2,
  OTHER = 3,
}

export enum EAchievementStatus {
  HIDDEN = 0,
  AVAILABLE = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
  SIGNED_OFF = 4,
}

export interface IAchievementType {
  id: EAchievementType;
  label: string;
}

export interface IAchievement {
  id: TAchievementId; //: int [pk]
  label: string; // string
  type: IAchievementType; // int [ref: > AchievementType.Id]
  description: string; // string
  category?: EAchievementCategory;
  logo?: string;
}

export interface IAchievementScoreType extends IAchievement {
  scoreThreshold: number;
}

export interface IAchievementCompleteActivityType extends IAchievement {
  activityId: TActivityId;
}
// TODO: Move this somewhere logical
// // record each time user gain any score
// // based on completing an activity
// table UserScore
// {
//   Id int [pk]
//   SchoolUserId  int
//   ActivityUserProgressId int [ref: > ActivityUserProgress.Id]
//   AchievementCategoryId int [ref: > AchievementCategory.Id]
//   Score int
// }

// Ref: "Achievement"."Id" < "Achievement"."Label"

export interface IAchievementUser extends IAchievement {
  status: EAchievementStatus;
  dateCreated: string;
}

// TODO: This is an issue with apiGetAchievements endpoint
export interface IAchievementUserNested {
  achievement: IAchievement;
  status: EAchievementStatus;
  dateCreated: string;
}

export interface IUserScore {
  category: EAchievementCategory;
  label: string;
  value?: number;
  description: string;
  color: string;
}

export type IUserScores = Record<EAchievementCategory, IUserScore>;
export type IUserScoreUpdates = Partial<Record<EAchievementCategory, number>>;

export const AchievementViewFields: Record<string, FilterOption> = {
  tags: new FilterOption({
    uid: "tags",
    field: "tags",
    label: "Tags",
    type: EFilterType.selectSearch,
  }),
};
