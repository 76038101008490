import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const UserDropdownBtnStyle = styled.button`
  border: none;
  border-radius: ${({ theme }) => theme.shape.defaultPaddingLarger};
  background: ${({ theme }) => theme.colors.boxoutBackground};
  color: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: ${({ theme }) => theme.colors.mainHover} solid 3px;
    outline-offset: 2px;
    border-color: ${({ theme }) => theme.colors.grey1};
  }
`;

const UserDropDownIcon = ({ onHover }) => {
  return (
    <UserDropdownBtnStyle onMouseOver={() => onHover(true)} onFocus={() => onHover(true)}>
      <FontAwesomeIcon size="xl" icon={faUser} />
    </UserDropdownBtnStyle>
  );
};

UserDropDownIcon.propTypes = {
  onHover: PropTypes.func.isRequired,
};

export default UserDropDownIcon;
