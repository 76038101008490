import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { aggregateDays } from "../dataProcessing/aggregateDays";
import { IPreProcessDataOptions, preProcessData } from "../dataProcessing/dataProcessing";
import { IVisualMapProps } from "../../DataViewDashboard/DataViewTypes/BubblePlotView/lib";

export interface IBubbleArrayPlotMiniProps {
  data: IMonitorDataItem<number>[];
  preProcessDataOptions?: IPreProcessDataOptions;
  visualMapPropsMini?: IVisualMapProps;
  scaleMini?: number;
  unit: string;
}

const MIN_SIZE = 10;
const MAX_SIZE = 80;

const optionsMiniView = (scaleMini) => (day, hours, idx) => ({
  title: {
    textBaseline: "middle",
    top: "10%",
    left: "center",
    textAlign: "left",
    text: day,
  },
  singleAxis: {
    left: "center",
    type: "category",
    boundaryGap: false,
    data: hours,
    top: "35%",
    height: "12%",
    width: "75%",
  },
  series: {
    name: day,
    singleAxisIndex: idx,
    coordinateSystem: "singleAxis",
    type: "scatter",
    data: [],
    symbolSize: function (dataItem) {
      return Math.min(MAX_SIZE, Math.max(MIN_SIZE, MIN_SIZE + dataItem[1] * scaleMini));
    },
    symbolOffset: [0, -30],
  },
});

/**
 * Bubble plot created by https://github.com/Cfaccetti
 * @returns
 */
export const BubbleArrayPlotMini = ({
  data,
  preProcessDataOptions,
  scaleMini,
  visualMapPropsMini,
  unit,
}: IBubbleArrayPlotMiniProps) => {
  const dataProcessed = preProcessData(data, preProcessDataOptions);
  const { series, title, singleAxis, hoursNoGap } = aggregateDays(optionsMiniView(scaleMini))(dataProcessed);

  if (!visualMapPropsMini) return <div>Missing data</div>;

  const chartOptions: EChartsOption = {
    grid: {
      // right: '10%'
    },
    tooltip: {
      position: "top",
      formatter: function (params) {
        if (params.value.length > 1) {
          return params.seriesName + " <br/>" + hoursNoGap[params.value[0]] + ":00 <br/>" + params.value[1] + unit;
        } else {
          return params.seriesName + " <br/>" + params.name + " : " + params.value + unit;
        }
      },
    },
    title: title,
    singleAxis: singleAxis,
    series: series,
    // defines the legend properties
    visualMap: {
      left: "center",
      bottom: "5%",
      calculable: true,
      realtime: false,
      inverse: visualMapPropsMini.type !== "continuous",
      textStyle: {
        fontSize: 12,
      },
      align: "left",
      ...visualMapPropsMini,
    },
  };
  // Key is set to date now to force update see https://github.com/CoTrace-Samhe/FrontEnd.Wrap/issues/378
  return <ReactECharts key={Date.now()} option={chartOptions} />;
};
