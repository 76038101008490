import styled from "styled-components";

export const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 0;
  margin-right: -0.5rem;
  margin-top: -1rem;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    justify-content: space-between;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-right: -2rem;
    justify-content: flex-start;
  }
`;

export const Logo = styled.div`
  height: 5rem;
  // margin-right: 0.5rem;
  margin-bottom: 2rem;
  max-width: 100%;

  &:last-child {
    margin-right: 0;

    &&&:first-child {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
  a {
    height: 100%;
    display: block;
  }
  svg {
    display: none;
  }
  &:hover,
  &:focus {
    svg {
      display: block;
    }
  }  
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 42%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-right: 2rem;
    margin-bottom: 2rem;
    img {
      object-fit: contain;
      height: 8rem;
      width: 10rem;
    }
  }
`;

export const LogosFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
`;

export const FooterLogoWrap = styled.div`
  height: 4rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  max-width: 9rem;
  &:last-child {
    margin-right: 0;
  }
  a {
    height: 100%;
    display: block;
  }
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
  }
`;
