import styled from "styled-components";
import { ButtonNoFormat } from "../../../styles/button";
import { convertHexToRGBA } from "../../../utils/colorUtils";

export const PADDING = 0.7;
const PROGRESS_BAR_THICKNESS = 1;
export const BOX_DIM = 13;
export const RING_EXPAND_RATIO = 1.25;
export const BOX_DIM_PADDED = BOX_DIM * RING_EXPAND_RATIO + PROGRESS_BAR_THICKNESS * 2;
export const PROGRESS_TRANSITION_T = 2;
export const EXPAND_RATIO = 1.5;
const BACKGROUND_COLOUR_ALPHA = 0.3;

export const UserScoreWidgetWrapStyle = styled.div<{ color: string }>`
  min-width: ${BOX_DIM}rem;
  min-height: ${BOX_DIM}rem;
  position: relative;
`;

export const UserScoreWidgetStyle = styled(ButtonNoFormat)<{ color: string }>`
  min-width: ${BOX_DIM}rem;
  min-height: ${BOX_DIM}rem;
  position: absolute;
  z-index: 500;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:focus-within,
  &:focus {
    z-index: 1000;
    .userScoreDescription {
      opacity: 1;
    }
  }
`;

export const ProgressRingWrapperStyle = styled.svg`
  width: ${BOX_DIM * RING_EXPAND_RATIO}rem;
  height: ${BOX_DIM * RING_EXPAND_RATIO}rem;
  position: absolute;
  left: -${BOX_DIM * ((RING_EXPAND_RATIO - 1) / 2)}rem;
  top: -${BOX_DIM * ((RING_EXPAND_RATIO - 1) / 2)}rem;

  transform: rotate(-91deg);
  /* Changed rotation direction */
  transform-origin: center;
`;

export const ProgressRingBackgroundStyle = styled.circle<{ color: string }>`
  fill: none;
  stroke-width: 1rem;
  stroke: ${({ color }) => convertHexToRGBA(color, BACKGROUND_COLOUR_ALPHA)};
`;

export const ProgressRingProgressStyle = styled.circle<{
  color: string;
  progressStrokeDasharray: string;
  progressStrokeDashoffset: string;
}>`
  fill: none;
  stroke-width: 1rem;
  stroke: ${({ color }) => color};

  stroke-dasharray: ${({ progressStrokeDasharray }) => progressStrokeDasharray};
  stroke-dashoffset: ${({ progressStrokeDashoffset }) => progressStrokeDashoffset};

  transform: scaleY(-1);
  transform-origin: center;
`;

export const ProgressRingDialStyle = styled.circle<{ dialStrokeDasharray: string }>`
  fill: none;
  stroke: white;
  stroke-width: 1rem;
  stroke-dasharray: ${({ dialStrokeDasharray }) => dialStrokeDasharray};
`;

export const UserScoreWidgetContentStyle = styled.div<{ color: string }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  background: ${({ color }) => convertHexToRGBA(color, BACKGROUND_COLOUR_ALPHA)};
  border-radius: 50%;
  position: relative;
  min-width: ${BOX_DIM}rem;
  min-width: 100%;
  min-height: ${BOX_DIM}rem;
  height: 100%;
  padding: ${PADDING}rem;
  z-index: 10;
  overflow: hidden;
  
  * {
    font-weight: 600;
  }
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-wrap: wrap;
  }

  & > div {
      position: relative; 
      width: 100%; 
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    }
  }
`;
export const UserScoreWidgetIconStyle = styled.div<{ color: string }>`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;

  & > .icon {
    width: 100%;
    object-fit: contain;
    max-height: 100%;
  }
`;

export const ShieldBadgeButton = styled.div``;

export const UserScoreNumberStyle = styled.div<{ color: string; value: number }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  font-size: ${({ theme }) => theme.typography.fontSize2};
  line-height: ${({ theme }) => theme.typography.fontSize4};
  font-weight: 1000;
  color: black;
`;

export const UserScoreLabelStyle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  font-size: ${({ theme }) => theme.typography.fontSize3};
  line-height: ${({ theme }) => theme.typography.fontSize3};
  font-weight: 1000;
  color: black;
  word-wrap: none;
  text-overflow: clip;
`;

export const UserScoreLevelStyle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  font-size: ${({ theme }) => theme.typography.fontSize3};
  line-height: ${({ theme }) => theme.typography.lineHeight3};
  font-weight: 1000;
  color: black;
  word-wrap: none;
  text-overflow: clip;
`;

export const UserScoreDescriptionStyle = styled.div<{ color: string }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  font-size: ${({ theme }) => theme.typography.fontSize3};
  line-height: ${({ theme }) => theme.typography.lineHeight1};
  background: ${({ color }) => color};
  border-radius: 50%;
  aspect-ratio: 1;
  width: ${BOX_DIM * RING_EXPAND_RATIO}rem;
  height: ${BOX_DIM * RING_EXPAND_RATIO}rem;
  position: absolute;
  left: -${BOX_DIM * ((RING_EXPAND_RATIO - 1) / 2)}rem;
  top: -${BOX_DIM * ((RING_EXPAND_RATIO - 1) / 2)}rem;

  font-weight: 400;
  position: absolute;
  opacity: 0; // Hidden until focused

  word-wrap: none;
  text-overflow: clip;
  z-index: 1001;

  & > p {
    color: white;
  }

  &::before {
    content: "";
    float: left;
    height: 100%;
    width: 50%;
    shape-outside: polygon(
      0 0,
      98% 0,
      50% 6%,
      23.4% 17.3%,
      6% 32.6%,
      0 50%,
      6% 65.6%,
      23.4% 82.7%,
      50% 94%,
      98% 100%,
      0 100%
    );
    shape-margin: 6%;
  }
  & > p {
    height: 100%;
    padding: 0;
    text-align: center;
    display: block;

    &::before {
      content: "";
      float: right;
      height: 100%;
      width: 50%;
      shape-outside: polygon(
        2% 0%,
        100% 0%,
        100% 100%,
        2% 100%,
        50% 94%,
        76.6% 82.7%,
        94% 65.6%,
        100% 50%,
        94% 32.6%,
        76.6% 17.3%,
        50% 6%
      );
      shape-margin: 6%;
    }
  }
`;

export const UserScoreWidgetsWrap = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 2rem;

  & > div {
    margin: 3rem;
  }
  transition: padding 0.5s ease-in-out, max-height 0.5s ease-in-out;
  overflow: auto;
  width: 100%;

  scroll-padding-right: 6rem;
  scroll-padding-left: 20rem;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    overflow: visible;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const UserScoreWidgetsOuterWrap = styled.div`
  overflow: visible;
`;
