import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
// import { useManageFilters } from "@react_db_client/components.filter-manager";
// import { CardBoard } from "../../GenericComponents/CardBoard";
// import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
// import { generateFiltersFromFields } from "../../GenericComponents/CardBoard/utils";
// import { CardBoardMenu } from "../../GenericComponents/CardBoard/CardboardMenu";
// import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
// import { AppCardboardWrap, AppInfoSection } from "../../styles/app";
// import { useGetAchievementMetaData } from "./useGetAchievementsMetaData";
// import { cardComponentTypeMap } from "./AchievementViewerCard/achievementCardComponentMap";
// import { AchievementViewFields, IUserScores } from "../../lib/Achievements/IAchievement";
import { IUserScores } from "../../lib/Achievements/IAchievement";
import { UserScoreWidget } from "./UserScoreWidget";
import { EAchievementCategory } from "../../lib/Achievements/EAchievementCategory";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { UserScoreWidgetsOuterWrap, UserScoreWidgetsWrap } from "./UserScoreWidget/style";
import { reduxGetUserScore } from "../../Redux/Achievements/achievementsReducer";

// const initialFilterData = generateFiltersFromFields(Object.values(AchievementViewFields));

export const UserScoresWrapped = () => {
  const { token } = React.useContext(AuthContext);
  const userScores = useAppSelector<IUserScores>((state) => state.achievementsState.userScore);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(reduxGetUserScore(token));
  }, [dispatch, token]);

  return (
    <UserScoreWidgetsOuterWrap data-testid="userScoreWidgetsOuterWrap">
      <UserScoreWidgetsWrap>
        <UserScoreWidget
          {...userScores[EAchievementCategory.QUIZ]}
          value={userScores[EAchievementCategory.QUIZ].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.TOP_RESPONDANT]}
          value={userScores[EAchievementCategory.TOP_RESPONDANT].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.TEAM_STUDENT]}
          value={userScores[EAchievementCategory.TEAM_STUDENT].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.CHIEF_INVESTIGATOR]}
          value={userScores[EAchievementCategory.CHIEF_INVESTIGATOR].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.CONTENT_CREATOR]}
          value={userScores[EAchievementCategory.CONTENT_CREATOR].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.EXPERIMENT_WHIZZ]}
          value={userScores[EAchievementCategory.EXPERIMENT_WHIZZ].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.DATA_ANALYST]}
          value={userScores[EAchievementCategory.DATA_ANALYST].value}
        />
        <UserScoreWidget
          {...userScores[EAchievementCategory.HERO]}
          value={userScores[EAchievementCategory.HERO].value}
        />
      </UserScoreWidgetsWrap>
    </UserScoreWidgetsOuterWrap>
  );
};

// TODO: Achievement cards hidden. Reimplement when required
// export const AchievementsDashboard: React.FC = () => {
//   const filtersManager = useManageFilters({ fieldsData: AchievementViewFields, initialFilterData });
//   const [containerRef, breakpoints] = useGetBreakpoints();
//   const cardsData = useGetAchievementMetaData({ filters: filtersManager.filters, groupId: "appAchievementsCards" });
//   const cardsCompiled = useGetCards({ cardsData, cardComponentTypeMap });

//   return (
//     <div ref={containerRef}>
//       <UserScoresWrapped />
//       <AppInfoSection>
//         <CardBoardMenu fields={AchievementViewFields} filtersManager={filtersManager} />
//         {/* <ButtonStyle onClick={() => dispatch(reduxLoadActivities,filtersManager.filters)}>Reload</ButtonStyle> */}
//       </AppInfoSection>
//       <AppCardboardWrap>
//         {cardsCompiled?.length > 0 && <CardBoard breakpointColumns={breakpoints}>{cardsCompiled}</CardBoard>}
//         {cardsCompiled?.length === 0 && <AppInfoSection>You do not have any achievements yet!</AppInfoSection>}
//       </AppCardboardWrap>
//     </div>
//   );
// };

export const AchievementsDashboard: React.FC = () => {
  return (
    <div>
      <UserScoresWrapped />
    </div>
  );
};
