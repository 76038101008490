import React from "react";
import { useLocation } from "react-router-dom";
import { FILE_SERVER_URL } from "../config";
import { ImageUrl } from "../lib/Primatives/Paths";

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const getImageUrl = (url: ImageUrl) => {
  return `${FILE_SERVER_URL? `${FILE_SERVER_URL}/` : ""}${url}`;
};
