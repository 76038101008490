import React from "react";
import TechContactForm from "../components/Forms/TechContactForm";
import { ConstrainedPageStyle, PageTitle, Section, PLong } from "../styles/page";

const TechContactPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <Section>
        <PageTitle>Website technical query contact form</PageTitle>
        <PLong>
          If you're having an issue with the SAMHE Web App or connecting your monitor to the WiFi please use this form
          to tell us about it. Please give as much detail as possible in order to guide our approach to trying to solve
          it.
        </PLong>
        <PLong>
          Some details which are relevant to the resolution of your problem, such as your internet browser version, may
          also be automatically collected once this form is submitted.
        </PLong>
        <PLong>
          If you are under 13 then please ask your parent, carer or your teacher to submit this form on your behalf. If
          you are over 13 then you can send us a message yourself.{" "}
        </PLong>
      </Section>
      <TechContactForm />
    </ConstrainedPageStyle>
  );
};

export default TechContactPage;
