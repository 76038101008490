import { FilterObjectClass } from "@react_db_client/constants.client-types";
import { IDataViewCardData } from "./IDataViewCardProps";
import { EDataViewTypes } from "../../lib/DataViews/IDataView";

export const filterDataViewCards = (filters: FilterObjectClass[]) => (card: IDataViewCardData) =>
  filters.every((filter) => {
    if(!card) return false;
    // NOTE: We have to convert the uid to string in filter setup so convert back to number here
    if (Number(filter.uid) === EDataViewTypes.SIMPLE_PLOT) return filter.value || card.type !== EDataViewTypes.SIMPLE_PLOT;
    if (Number(filter.uid) === EDataViewTypes.BUBBLE_PLOT) return filter.value || card.type !== EDataViewTypes.BUBBLE_PLOT;
    if (Number(filter.uid) === EDataViewTypes.GAUGE_CHART) return filter.value || card.type !== EDataViewTypes.GAUGE_CHART;
    if (Number(filter.uid) === EDataViewTypes.SIMPLE_SCATTER) return filter.value || card.type !== EDataViewTypes.SIMPLE_SCATTER;
    if (Number(filter.uid) === EDataViewTypes.SIMPLE_BAR) return filter.value || card.type !== EDataViewTypes.SIMPLE_BAR;
    if (Number(filter.uid) === EDataViewTypes.PIE_CHART) return filter.value || card.type !== EDataViewTypes.PIE_CHART;
    if (Number(filter.uid) === EDataViewTypes.DATA_DOWNLOAD) return filter.value || card.type !== EDataViewTypes.DATA_DOWNLOAD;

    throw Error(`Filter not setup for: ${filter.uid}`);
  });
