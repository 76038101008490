import React from "react";
import { PopupPanel, PopupPanelContext } from "@react_db_client/components.popup-panel-v2";
import { ExpandedPanelStyle } from "../../styles/popups";
import { IKeyword } from "../../lib/Keyword/IKeyword";
import { KeywordPopup } from "./KeywordItem";
import { keywordMap } from "../../dummyData/keywords";

export interface IKeywordProviderState {
  //
}

export interface IKeywordProviderContext {
  currentKeyword?: IKeyword;
  setCurrentKeyword?: (newK: IKeyword) => void;
  openKeyword?: (newK: IKeyword) => void;
  keywordMap: IKeyword[];
}

export interface IKeywordProviderProps {
  children: React.ReactNode;
  initialState?: Partial<IKeywordProviderContext>;
  keywordMap: IKeyword[];
}

export const defaultState: IKeywordProviderContext = {
  keywordMap,
};

export const KeywordContext = React.createContext(defaultState);

export const KeywordProvider = ({ initialState = defaultState, keywordMap, children }: IKeywordProviderProps) => {
  const [currentKeyword, setCurrentKeyword] = React.useState<IKeyword | null>(null);
  const { openPopup } = React.useContext(PopupPanelContext);

  const openKeyword = React.useCallback(
    (keyword: IKeyword) => {
      setCurrentKeyword(keyword);
      openPopup("keywordPopup");
    },
    [openPopup]
  );
  const state: IKeywordProviderContext = React.useMemo<IKeywordProviderContext>(
    () => ({
      ...initialState,
      currentKeyword,
      setCurrentKeyword,
      openKeyword,
      keywordMap,
    }),
    [initialState, currentKeyword, setCurrentKeyword, openKeyword, keywordMap]
  );
  return (
    <KeywordContext.Provider value={state}>
      {children}{" "}
      <PopupPanel id="keywordPopup" renderWhenClosed={false} zIndex={12000}>
        <ExpandedPanelStyle>
          <KeywordPopup data={currentKeyword} keywordMap={keywordMap} />
        </ExpandedPanelStyle>
      </PopupPanel>
    </KeywordContext.Provider>
  );
};
