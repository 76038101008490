import { exampleTags } from "../../../../dummyData/tags";
import { AchievementCategories, EAchievementCategory } from "../../../../lib/Achievements/EAchievementCategory";
import { EEquipmentNeeded } from "../../../../lib/Activities/EEquipmentNeeded";
import { EGrades } from "../../../../lib/Activities/EGrades";
import { EThemes } from "../../../../lib/Activities/EThemes";
import {
  EActivityStatus,
  EActivityTypes,
  IActivity,
  IActivityUserData,
  IActivityUserDataNotStarted,
} from "../../../../lib/Activities/IActivity";
import { EStepStatus, EStepTypes, IStep, TStepAdditionalProps } from "../../../../lib/Activities/IStep";
import { EDataMetrics } from "../../../../lib/DataViews/EDataMetrics";
import { EDataSnapshotChallengeType } from "../../StepTypes/DataSnapshotStep";

export const exampleSteps: IStep<TStepAdditionalProps>[] = [
  {
    id: 1,
    stepRef: "step1",
    label: "Please tell us which year groups use this classroom. Tick all that apply.",
    description: `# Example description
Some extra text. Can include keywords like !air cleaner!`,
    type: EStepTypes.TEXT_INPUT,
  },
  {
    id: 6,
    stepRef: "step-snapshot",
    label: "Take a snapshot of the current CO2?",
    description: `# Example description
Some extra text`,
    type: EStepTypes.DATA_SNAPSHOT,
    additionalProps: {
      metric: EDataMetrics.CO2,
      challengeType: EDataSnapshotChallengeType.SELECT,
    },
    preSteps: ["step1"],
  },
  {
    id: 2,
    stepRef: "step2",
    label: "Step 2 Answer this while watching the video below?",
    description: `# Example description
Some extra text`,
    type: EStepTypes.MULTIPLE_CHOICE,
    additionalProps: {
      multiple: true,
      options: [
        { uid: "opt1", label: "Opt 1" },
        { uid: "opt2", label: "Opt 2" },
        { uid: "opt3", label: "Opt 3" },
      ],
    },
    preSteps: ["step1"],
  },
  {
    id: 3,
    stepRef: "step3",
    label: "Step 3 Video",
    description: `# Example description
Some extra text`,
    type: EStepTypes.VIDEO,
    additionalProps: {
      videoUrl: "https://www.youtube.com/embed/tHbCCTrUKsg",
    },
    preSteps: ["step1"],
  },
  {
    id: 4,
    stepRef: "Additional Question",
    label: "Did you like the video?",
    description: `# Example description
Some extra text`,
    type: EStepTypes.TEXT_INPUT,
    optional: true,
    preSteps: ["step3"],
  },
  {
    id: 5,
    stepRef: "step6",
    label: "Did you like this activity?",
    description: `# Example description
Some extra text`,
    type: EStepTypes.MULTIPLE_CHOICE,
    additionalProps: {
      options: [
        { uid: "yeslots", label: "Yes! A lot!" },
        { uid: "yes", label: "It was ok." },
        { uid: "no", label: "No" },
      ],
    },
    preSteps: ["step2", "step3"],
  },
  {
    id: 7,
    stepRef: "step7-datarange",
    label: "Pick a range of data?",
    description: `### Example description
Some extra text`,
    type: EStepTypes.DATA_RANGE_SNAPSHOT,
    additionalProps: {
      metric: EDataMetrics.CO2,
      allowSkip: true,
    },
    preSteps: ["step2", "step3"],
    nextSteps: [],
  },
  {
    id: 8,
    stepRef: "step8-roomchoice",
    label: "Pick a room?",
    description: `Pick a room from the list.`,
    type: EStepTypes.ROOM_SELECTION,
    additionalProps: {},
    preSteps: ["step2", "step3"],
    nextSteps: [],
  },
];

export const simpleActivity: IActivity = {
  id: "simpleActivity",
  label: "Simple Activity",
  logo: "simpleActivity.jpg",
  feedbackNeeded: true,
  longDescription: `This is a long description of the activity...
  !air cleaner!

It can span multiple lines.

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**
!air cleaner!
`,
  description: `This is a **short** description of the activity...

It can span multiple lines.
`,
  imageUrl: "/images/app/activities/icons/ANALYSIS_SMALL.png",
  type: EActivityTypes.STEP_BY_STEP,
  equipmentNeeded: [EEquipmentNeeded.ANY],
  targetGrade: [EGrades.LVL1],
  bannerImageUrl: "/images/app/general/Molecules.png",
  textFilePath: "lkjlkjkl",
  noOfPeople: "3+",
  timeEstimates: {
    investigation: 5,
    video: 8,
    discussion: 14,
  },
  theme: EThemes.B,
  activitySteps: exampleSteps,
  tags: [exampleTags.co2, exampleTags.air_quality, exampleTags.data, exampleTags.dataentry, exampleTags.measurements],
  activityScores: [
    {
      category: AchievementCategories[EAchievementCategory.QUIZ],
      score: 8,
    },
    {
      category: AchievementCategories[EAchievementCategory.DATA_ANALYST],
      score: 5,
    },
    {
      category: AchievementCategories[EAchievementCategory.HERO],
      score: 2,
    },
  ],
  cheatCodes: {
    complete: "examplecheatcode",
  },
  nextActivityIds: [],
  previousActivityIds: [],
};

export const shortActivity: IActivity = {
  ...simpleActivity,
  id: "shortActivity",
  label: "Short Activity",
  logo: "shortActivity.jpg",
  activitySteps: exampleSteps.slice(0, 1),
};

export const shortActivityUserProgress: IActivityUserDataNotStarted = {
  activityId: shortActivity.id,
  activityStatus: EActivityStatus.NOT_STARTED,
};

export const simpleActivityB: IActivity = {
  id: "simpleActivityB",
  label: "Simple Activityn B",
  logo: "simpleActivity.jpg",
  description: "Activity activity B",
  longDescription: `This is a long description of the activity...`,
  imageUrl: "/images/app/activities/icons/ANALYSIS_SMALL.png",
  type: EActivityTypes.STEP_BY_STEP,
  feedbackNeeded: true,
  targetGrade: [EGrades.LVL1],
  noOfPeople: "1",
  bannerImageUrl: "/images/app/general/Molecules.png",
  equipmentNeeded: [EEquipmentNeeded.NONE],
  timeEstimates: {
    investigation: 1,
    video: 2,
    discussion: 3,
  },
  theme: EThemes.B,
  activitySteps: exampleSteps,
  tags: [exampleTags.co2],
  activityScores: [
    {
      category: AchievementCategories[EAchievementCategory.QUIZ],
      score: 100,
    },
  ],
  nextActivityIds: [],
  previousActivityIds: [],
};

export const simpleActivityUserProgress: IActivityUserData = {
  id: 991,
  activityId: simpleActivity.id,
  activityStatus: EActivityStatus.NOT_STARTED,
  dateCreated: new Date().toISOString(),
  dateModified: new Date().toISOString(),
  stepsState: [],
  activeStepId: 0,
  previousSteps: [],
  attemptNo: 3,
};

export const simpleActivityBUserProgress: IActivityUserData = {
  id: -1,
  activityId: simpleActivityB.id,
  activeStepId: exampleSteps[0].id,
  activityStatus: EActivityStatus.IN_PROGRESS,
  previousSteps: [],
  dateCreated: String(Date.now()),
  dateModified: String(Date.now()),
  attemptNo: 1,
  stepsState: [
    {
      stepId: exampleSteps[0].id,
      stepRef: exampleSteps[0].stepRef,
      dateCreated: new Date(Date.now()),
      status: EStepStatus.COMPLETED,
      state: {
        hello: "world",
      },
    },
  ],
};
