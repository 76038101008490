import { EAggregationInterval } from "../../lib/MonitorData/AggregationIntervals";
import { IDateRangeState, ETimeRange, EDayRange } from "./lib";

/* convert milliseconds */
export const years = (w) => 1000 * 60 * 60 * 24 * 365 * w;
export const weeks = (w) => 1000 * 60 * 60 * 24 * 7 * w;
export const days = (d) => 1000 * 60 * 60 * 24 * d;
export const hours = (h) => 1000 * 60 * 60 * h;
export const minutes = (m) => 1000 * 60 * m;
export const seconds = (s) => 1000 * s;
export const tryParseDate = (d) => {
  if (!d) return null;
  return new Date(d);
};

export const getTimeMinusHours = (date: Date, hours: number) => {
  const dateCopy = new Date(date);
  dateCopy.setHours(date.getHours() - hours);
  return dateCopy;
};

export const selectTimeRange = (timeRange: ETimeRange): [Date, Date] => {
  const baseTime = new Date("2000-01-01"); // We are only interested in the time
  switch (timeRange) {
    case ETimeRange.SCHOOL_HOURS: {
      const startDate = new Date(new Date(baseTime).setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 9);
      const endDate = new Date(new Date(baseTime).setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 16);
      return [startDate, endDate];
    }
    case ETimeRange.NO_LIMIT: {
      const startDate = new Date(new Date(baseTime).setHours(0, 0, 0, 0));
      const endDate = new Date(new Date(baseTime).setHours(23, 59, 59, 999));
      return [startDate, endDate];
    }
    default:
      throw new Error(`Time range type: ${timeRange} not implemented`);
  }
};

export const parseDateIn = (date: Date): string => {
  const YYYY = date.getFullYear();
  const MM = (date.getMonth() + 1).toString().padStart(2, "0");
  const DD = date.getDate().toString().padStart(2, "0");
  const HH = date.getHours().toString().padStart(2, "0");
  const mm = date.getMinutes().toString().padStart(2, "0");
  const ss = date.getSeconds().toString().padStart(2, "0");
  const dateString = `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}`;
  return dateString;
};

export const parseDateInDateOnly = (date: Date): string => {
  const YYYY = date.getFullYear();
  const MM = (date.getMonth() + 1).toString().padStart(2, "0");
  const DD = date.getDate().toString().padStart(2, "0");
  const dateString = `${YYYY}-${MM}-${DD}`;
  return dateString;
};

export const selectDayRange = (dayRange: EDayRange): [Date, Date] => {
  let startDate: Date, endDate: Date;
  switch (dayRange) {
    case EDayRange.LAST_HOUR: {
      endDate = new Date(Date.now());
      startDate = new Date(endDate.valueOf() - 1000 * 60 * 60);
      break;
    }
    case EDayRange.LAST_SIX_HOURS: {
      endDate = new Date(Date.now());
      startDate = endDate.getHours() >= 6 ? getTimeMinusHours(endDate, 6) : new Date(endDate.setHours(0));
      break;
    }
    case EDayRange.TODAY: {
      startDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0));
      endDate = new Date(new Date(Date.now()).setHours(23, 59, 59, 999));
      break;
    }
    case EDayRange.YESTERDAY: {
      startDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24);
      endDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1);
      break;
    }
    case EDayRange.THIS_WEEK: {
      let currentDayOfWeek = new Date(Date.now()).getDay();
      currentDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek; // reset sunday
      startDate = new Date(
        new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24 * (currentDayOfWeek - 1)
      );
      endDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() + 24 * 60 * 60 * 1000 - 1);
      break;
    }
    case EDayRange.LAST_SCHOOL_WEEK: {
      let currentDayOfWeek = new Date().getDay();
      currentDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek; // reset sunday
      startDate = new Date(
        new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24 * (currentDayOfWeek - 1 + 7)
      );
      endDate = new Date(
        new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24 * (currentDayOfWeek - 1 + 2) - 1
      );
      break;
    }
    case EDayRange.THIS_YEAR: {
      startDate  = new Date(new Date(Date.now()).getFullYear(), 0, 1);
      endDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf());
      break;
    }
    case EDayRange.THIS_SCHOOL_YEAR: {
      const now = new Date(Date.now());
      startDate  = new Date(now.getFullYear() - 1, 8, 1);
      endDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf());
      break;
    }
    case EDayRange.CUSTOM: {
      startDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1000 * 60 * 60 * 24);
      endDate = new Date(new Date(Date.now()).setHours(0, 0, 0, 0).valueOf() - 1);
      break;
    }
    default:
      throw new Error(`Day range type: ${dayRange} not implemented`);
  }
  return [startDate, endDate];
};

export const allowAllDayRanges = [
  EDayRange.LAST_HOUR,
  EDayRange.LAST_SIX_HOURS,
  EDayRange.TODAY,
  EDayRange.YESTERDAY,
  EDayRange.THIS_WEEK,
  EDayRange.LAST_THREE_DAYS,
  EDayRange.LAST_SCHOOL_WEEK,
  EDayRange.SCHOOL_DAYS,
  EDayRange.THIS_SCHOOL_TERM,
  EDayRange.THIS_YEAR,
  EDayRange.THIS_SCHOOL_YEAR,
  EDayRange.CUSTOM,
];

export const defaultDateRangeState = (dayRange: EDayRange = EDayRange.LAST_HOUR): IDateRangeState => ({
  startDate: selectDayRange(dayRange)[0],
  endDate: selectDayRange(dayRange)[1],
  startTimeLimit: selectTimeRange(ETimeRange.NO_LIMIT)[0],
  endTimeLimit: selectTimeRange(ETimeRange.NO_LIMIT)[1],
  invalid: false,
  dayRange: dayRange,
  timeRange: ETimeRange.NO_LIMIT,
  aggregation: EAggregationInterval.NONE,
  maxAggregation: EAggregationInterval.DAY,
  minAggregation: EAggregationInterval.NONE,
});

export const parseDateRangeState = (dateRangeState?: Partial<IDateRangeState>): IDateRangeState => {
  const defaultDateRangeStateIn = defaultDateRangeState();
  return {
    startDate: tryParseDate(dateRangeState?.startDate) || defaultDateRangeStateIn.startDate,
    endDate: tryParseDate(dateRangeState?.endDate) || defaultDateRangeStateIn.endDate,
    startTimeLimit: tryParseDate(dateRangeState?.startTimeLimit) || defaultDateRangeStateIn.startTimeLimit,
    endTimeLimit: tryParseDate(dateRangeState?.endTimeLimit) || defaultDateRangeStateIn.endTimeLimit,
    invalid: dateRangeState?.invalid || defaultDateRangeStateIn.invalid,
    dayRange: dateRangeState?.dayRange || defaultDateRangeStateIn.dayRange,
    timeRange: dateRangeState?.timeRange || defaultDateRangeStateIn.timeRange,
    aggregation: dateRangeState?.aggregation || EAggregationInterval.NONE,
    minAggregation: dateRangeState?.minAggregation || EAggregationInterval.NONE,
    maxAggregation: dateRangeState?.maxAggregation || EAggregationInterval.DAY,
  };
};

export const refreshTimes = (dateRangeState: IDateRangeState): IDateRangeState => {
  const [startDate, endDate] = selectDayRange(dateRangeState.dayRange);
  const [startTimeLimit, endTimeLimit] = selectTimeRange(dateRangeState.timeRange);
  return {
    ...dateRangeState,
    startDate,
    endDate,
    startTimeLimit,
    endTimeLimit,
  };
};

export const roundDateToAggregationInterval = (i: number): string => {
  if (i <= minutes(1)) {
    return `${Math.floor(i / seconds(1))} seconds`;
  }
  if (i <= minutes(59)) {
    return `${Math.floor(i / minutes(1))} minutes`;
  }
  if (i <= hours(24)) {
    return `${Math.floor(i / hours(1))} hours`;
  }
  if (i <= days(7)) {
    return `${Math.floor(i / days(1))} days`;
  }
  if (i < weeks(365 / 7)) {
    return `${Math.floor(i / weeks(1))} weeks`;
  }
  if (i < weeks(365 / 7)) {
    return `${Math.floor(i / weeks(1))} weeks`;
  }
  return `${Math.floor(i / years(1))} years`;
};

export const parseDateToHumanRange = (i: number): string => {
  if (i <= minutes(1)) {
    return `${Math.floor(i / seconds(1))} seconds`;
  }
  if (i <= minutes(59)) {
    return `${Math.floor(i / minutes(1))} minutes`;
  }
  if (i <= hours(24)) {
    return `${Math.floor(i / hours(1))} hours`;
  }
  if (i <= days(7)) {
    return `${Math.floor(i / days(1))} days`;
  }
  if (i < weeks(365 / 7)) {
    return `${Math.floor(i / weeks(1))} weeks`;
  }
  if (i < weeks(365 / 7)) {
    return `${Math.floor(i / weeks(1))} weeks`;
  }
  return `${Math.floor(i / years(1))} years`;
};

export const getMinAggregation = (i: number): EAggregationInterval => {
  // if (i <= hours(1)) {
  //   return EAggregationInterval.NONE;
  // }
  if (i <= hours(2)) {
    return EAggregationInterval.MINUTE;
  }
  if (i <= hours(24)) {
    return EAggregationInterval.FIVE_MINUTE;
  }
  if (i <= days(7)) {
    return EAggregationInterval.HOUR;
  }
  return EAggregationInterval.DAY;
};

export const getMaxAggregation = (i: number): EAggregationInterval => {
  if (i <= minutes(2)) {
    return EAggregationInterval.MINUTE;
  }
  if (i <= minutes(10)) {
    return EAggregationInterval.FIVE_MINUTE;
  }
  if (i <= hours(1)) {
    return EAggregationInterval.TEN_MINUTE;
  }
  if (i <= days(1)) {
    return EAggregationInterval.HOUR;
  }
  return EAggregationInterval.DAY;
};
