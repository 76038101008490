import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAsyncRetry } from "react-use";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { UserGroup } from "../lib/User/UserGroup";
import { useAppSelector } from "../Redux/hooks";

import { SignInPanel, SignInScreen, SignInPanelWrap, FullAuthPanelWrap } from "../styles/auth";
import { CTALinkButtonStyle } from "../styles/links";
import { apiSchoolAdmin } from "../Api/apiSchoolAdmin";

export const MessagePanel = ({ message, buttonText, helpText }) => {
  const { user } = useAppSelector((state) => state.userState);
  const isAdmin = user?.group === UserGroup.SCHOOLADMIN || user?.group === UserGroup.SUPERADMIN;

  if (isAdmin)
    return (
      <>
        <p style={{ marginBottom: "1rem" }}>
          <b>{message}</b>
        </p>
        <CTALinkButtonStyle to="/setup/school">{buttonText}</CTALinkButtonStyle>
        <p style={{ marginTop: "1rem" }}>{helpText}</p>
      </>
    );
  return <p>{message}. Please sign in as an admin user.</p>;
};

export const YouNeedToActivateYourSchoolPanel = () => (
  <MessagePanel
    message="Your school has not been activated!"
    buttonText="Please go to the School activation page"
    helpText=""
  />
);

export const YouNeedToAddRoomsPanel = () => (
  <MessagePanel
    message="You haven't completed the school set up process, please add some rooms."
    buttonText="Please go to the school activation page to add rooms."
    helpText="This will only take you a couple of minutes and then you'll be able to see your air quality data!"
  />
);

export const YouNeedToSetASchoolHandlePanel = () => (
  <MessagePanel
    message="You haven't completed the school set up process, please add a school handle"
    buttonText="Please go to the school activation page to add a school handle."
    helpText="This will only take you a couple of minutes and then you'll be able to see your air quality data!"
  />
);

export const RequiresActivatedSchool = ({ children }) => {
  const { token } = React.useContext(AuthContext);
  const userGroup = useAppSelector((state) => state.userState.user?.group);
  const schoolLoaded = useAppSelector((state) => state.userState.state.school?.loadStatus === "INIT");
  const schoolStatus = useAppSelector((state) => state.userState.state.school?.data?.status);
  const roomListIn = useAppSelector((state) => state.userState.state.school?.data?.roomList);
  const schoolAdminData = useAsyncRetry(
    async () =>
      userGroup === UserGroup.SCHOOLADMIN
        ? apiSchoolAdmin.get(token)
        : ({} as unknown as ReturnType<typeof apiSchoolAdmin.get>),
    [token]
  );

  const missingSchoolHandle =
    !(
      schoolAdminData?.value as {
        schoolHandle: any;
      }
    )?.schoolHandle && userGroup === UserGroup.SCHOOLADMIN;

  if (!missingSchoolHandle && roomListIn?.length && schoolStatus === ESchoolStatus.ACTIVATED) return children;
  if (!schoolLoaded || schoolAdminData.loading)
    return (
      <SignInScreen>
        <FullAuthPanelWrap>
          <div style={{ position: "relative", height: "fit-content" }}>
            <SignInPanelWrap>
              <SignInPanel data-testid="signInPanel">
                <p>Loading school data...</p>
              </SignInPanel>
            </SignInPanelWrap>
          </div>
        </FullAuthPanelWrap>
      </SignInScreen>
    );
  return (
    <SignInScreen>
      <FullAuthPanelWrap>
        <div style={{ position: "relative", height: "fit-content" }}>
          <SignInPanelWrap>
            <SignInPanel data-testid="signInPanel">
              {schoolStatus < 7 ? <YouNeedToActivateYourSchoolPanel /> : ""}
              {schoolStatus === 7 && missingSchoolHandle ? <YouNeedToSetASchoolHandlePanel /> : ""}
              {schoolStatus === 7 && !roomListIn?.length ? <YouNeedToAddRoomsPanel /> : ""}
            </SignInPanel>
          </SignInPanelWrap>
        </div>
      </FullAuthPanelWrap>
    </SignInScreen>
  );
};
