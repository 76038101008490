import React from "react";
import { UserAdminPrivacyPolicy } from "../components/Documents/UserAdminPrivacyPolicy";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";

const UserAdminPrivacyPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE School Admin User Transparency Notice</PageTitle>
      <UserAdminPrivacyPolicy />
    </ConstrainedPageStyle>
  );
};

export default UserAdminPrivacyPage;
