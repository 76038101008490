import { SectionHeading, SubSectionHeading, P, Section, FloatImageMax } from "../../styles/page";
import { BoxoutHero } from "../../styles/hero";
import { Link } from "react-router-dom";
import { AExternal } from "../Links";

export const TemperatureHumidityInfo = ({ glossaryClass = true }) => {
  return (
    <>
      <Section>
        <SectionHeading id="temperature-and-relative-humidity" className={glossaryClass ? "hideFromGlossary" : ""}>
          Temperature and Relative Humidity
        </SectionHeading>
        <br></br>
        <SubSectionHeading id="why-measure-temperature-and-relative-humidity">
          Why measure temperature and relative humidity?
        </SubSectionHeading>
        <FloatImageMax src="/images/monitor-leds/boy-in-classroom.jpg" alt="Boy in classroom" />
        <P>
          Whether we feel too warm, too cool, or comfortable is described as our 'thermal comfort'. Managing thermal
          comfort is important in providing a suitable environment for school children to learn effectively. However, it
          is not as simple as just managing the temperature of the air around us. Different environmental and personal
          factors influence thermal comfort, such as air temperature, the 'radiant' temperature of surfaces in the room,
          air velocity, humidity, clothing, physical activity, age, and other factors.
        </P>
        <P>
          We are all different and therefore sense/feel things differently. This makes it very difficult to measure the
          personal factors influencing thermal comfort objectively. The SAMHE monitors measure two key environmental
          factors: ambient air temperature and relative humidity (see our{" "}
          <Link to="/resources/key-definitions">Key definitions</Link> page).
        </P>
        <P>
          As well as contributing to thermal comfort, relative humidity is also linked to health, as it determines the
          survival of dust mites, mould, and other disease-causing organisms.
        </P>
        <P>
          Higher temperatures and humidity levels have also been linked to higher emission rates of{" "}
          <Link to="/resources/monitor-leds#what-levels-of-tvoc-are-high">VOCs</Link> from new products such as
          furniture, flooring, carpet, etc.
        </P>
      </Section>
      <Section>
        <BoxoutHero>
          <SubSectionHeading id="what-is-a-good-temperature">What is a good temperature?</SubSectionHeading>
          <FloatImageMax
            src="/images/monitor-leds/hand-of-person-turning-radiator-dial.jpg"
            alt="hand of person turning radiator dial"
          />
          <P>
            The UK Government publishes recommendations (
            <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
              BB101: Guidelines on Ventilation, thermal comfort and indoor air quality in schools
            </AExternal>
            ) on appropriate room temperature. However, these recommendations are for operative temperature which is not
            the same as the air temperature measured by the SAMHE monitors. Operative temperature is a weighted average
            of ambient air temperature and mean radiant temperature. For classrooms, the BB101 guidance suggests
            'normally' operative temperatures should be around 20°C and maximum operative temperatures of 25°C during
            the heating season. In classrooms dedicated to pupils with physical or learning difficulties, the guidelines
            suggest a higher normal operative temperature of 23°C.
          </P>
          <P>
            The air temperature, as shown on the SAMHE monitors, is only one factor contributing to the operative
            temperature. It is also influenced by the setup of the classroom. Electrical equipment, radiators, sun
            radiation and humans have high radiant temperatures and so act to increase the operative temperature above
            the air temperature. Window surfaces and poorly insulated walls have low radiant temperatures and act to
            decrease the operative temperature. The relative effects of these can vary day to day.
          </P>
          <P>
            We suggest that you use the air temperature measurements from the SAMHE monitors as an indication of one
            factor affecting thermal comfort, but rely more on whether staff and students feel too cold or too hot when
            deciding whether to change the thermal comfort in a room. That can be done by changing the heating level or
            changing the opening of windows (which will affect the room ventilation). See our{" "}
            <Link to="/resources/ventilation">ventilation guidance for schools</Link> for more information.
          </P>
        </BoxoutHero>
      </Section>
      <Section>
        <SubSectionHeading id="what-is-a-good-humidity-level">What is a good humidity level?</SubSectionHeading>
        <FloatImageMax src="/images/monitor-leds/mould.jpg" alt="mould" />
        <P>
          The{" "}
          <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
            BB101 guidance for schools
          </AExternal>{" "}
          does not recommend a specific relative humidity level and states that short-term exposure to very low or high
          humidity is not a problem.
        </P>
        <P>
          The Chartered Institution of Building Services Engineers (CIBSE) provides guidance on relative humidity levels
          in its{" "}
          <AExternal href="https://www.cibse.org/tm40">TM40 - Health and wellbeing in building services</AExternal>{" "}
          document recommending 40-60% relative humidity in domestic settings & mechanically ventilated buildings and
          40-70% elsewhere. (The majority of UK schools fall into the second category). CIBSE recommendations are based
          on World Health Organisation (WHO) guidelines. Similar to CIBSE recommendations, the UK Health and Safety
          Executive (HSE) has published{" "}
          <AExternal href="https://www.hse.gov.uk/foi/internalops/ocs/300-399/oc311_2.htm">
            Sick building syndrome: Guidance for specialist inspectors
          </AExternal>
          , recommending relative humidity levels in the range of 40 to 70% for the workplace environment.
        </P>
        <P>
          The reasoning behind these recommendations is that low relative humidity levels can cause skin and eye
          problems and indirectly increase particulate matter such as airborne dust and fibres; whereas relative
          humidity levels above 70% can result in surface condensation and mould growth. A detailed description of
          health effects of high moisture levels in the air is given in the{" "}
          <AExternal href="https://apps.who.int/iris/handle/10665/164348">
            WHO guidelines for indoor air quality: dampness and mould
          </AExternal>
          .
        </P>
      </Section>
    </>
  );
};
