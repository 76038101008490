import { EStepTypes, IStep } from "../../../lib/Activities/IStep";
import { UserGroup } from "../../../lib/User/UserGroup";
import { IMultipleChoiceExtraProps } from "../StepTypes/MultiChoiceStep/MultipleChoiceStep";

export const feedbackSteps: IStep<IMultipleChoiceExtraProps>[] = [
  {
    id: -1,
    stepRef: "enjoyActivity",
    label: "Did you enjoy this activity?",
    description: "",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "I really enjoyed this activity" },
        { uid: 2, label: "I enjoyed this activity" },
        { uid: 3, label: "I feel neutral about this activity (it was ok)" },
        { uid: 4, label: "I did not enjoy this activity" },
        { uid: 5, label: "I really did not enjoy this activity" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -2,
    stepRef: "understandActivity",
    label: "Did you understand the instructions?",
    description: "",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "I found the instructions very easy to understand" },
        { uid: 2, label: "I found the instructions easy to understand" },
        { uid: 3, label: "The instructions for this activity were ok" },
        { uid: 4, label: "The instructions for this activity were hard to understand" },
        { uid: 5, label: "The instructions for this activity were very hard to understand" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -3,
    stepRef: "understandTopic",
    label: "After doing this activity I am confident that I have a better understanding of the topic covered.",
    description: " There are no right or wrong answers we are only interested in your own thoughts and feelings.",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -4,
    stepRef: "freeTextFeedback",
    label: "General feedback on this activity",
    description: `We're committed to continuously improving our activity stock and greatly welcome your feedback.
If there's something you'd like to tell us about how this activity worked at your school, please use the box below.
Do not use this to report any technical issues, please report these using our [technical query form]("/support"),
as comments left here are only reviewed periodically.
If this activity went well and you would like to share your students' experience with others,
please use the [newsletter contribution form]("https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform").`,
    type: EStepTypes.FEEDBACK_FREETEXT,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
    userRoleLimit: [UserGroup.TEACHER, UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN],
  },
];

export const feedbackStepsQuiz: IStep<IMultipleChoiceExtraProps>[] = [
  {
    id: -1,
    stepRef: "enjoyActivity",
    label: "Did you enjoy this activity?",
    description: "",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "I really enjoyed this activity" },
        { uid: 2, label: "I enjoyed this activity" },
        { uid: 3, label: "I feel neutral about this activity (it was ok)" },
        { uid: 4, label: "I did not enjoy this activity" },
        { uid: 5, label: "I really did not enjoy this activity" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -2,
    stepRef: "understandTopic",
    label: "After doing this activity I am confident that I have a better understanding of the topic covered.",
    description: " There are no right or wrong answers we are only interested in your own thoughts and feelings.",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -3,
    stepRef: "freeTextFeedback",
    label: "General feedback on this activity",
    description: `We're committed to continuously improving our activity stock and greatly welcome your feedback.
If there's something you'd like to tell us about how this activity worked at your school, please use the box below.
Do not use this to report any technical issues, please report these using our [technical query form]("/support"),
as comments left here are only reviewed periodically.
If this activity went well and you would like to share your students' experience with others,
please use the [newsletter contribution form]("https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform").`,
    type: EStepTypes.FEEDBACK_FREETEXT,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
    userRoleLimit: [UserGroup.TEACHER, UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN],
  },
];

export const feedbackStepsDataDetective: IStep<IMultipleChoiceExtraProps>[] = [
  {
    id: -1,
    stepRef: "enjoyActivity",
    label: "Did you enjoy this activity?",
    description: "",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "I really enjoyed this activity" },
        { uid: 2, label: "I enjoyed this activity" },
        { uid: 3, label: "I feel neutral about this activity (it was ok)" },
        { uid: 4, label: "I did not enjoy this activity" },
        { uid: 5, label: "I really did not enjoy this activity" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },

  {
    id: -2,
    stepRef: "feedbackReadings",
    label: "After doing this activity I am confident that I understand the readings I saw on my SAMHE monitor",
    description: "There are no right or wrong answers, we are only interested in your own thoughts and feelings.",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -3,
    stepRef: "feedbackWhatToDo",
    label:
      "After doing this activity I am confident that I know what to do if I see unusual readings or data events again",
    description: "There are no right or wrong answers, we are only interested in your own thoughts and feelings.",
    type: EStepTypes.FEEDBACK,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
  },
  {
    id: -4,
    stepRef: "freeTextFeedback",
    label: "General feedback on this activity",
    description: `We're committed to continuously improving our activity stock and greatly welcome your feedback.
If there's something you'd like to tell us about how this activity worked at your school, please use the box below.
Do not use this to report any technical issues, please report these using our [technical query form]("/support"),
as comments left here are only reviewed periodically.
If this activity went well and you would like to share your students' experience with others,
please use the [newsletter contribution form]("https://docs.google.com/forms/d/e/1FAIpQLSdLn1k7Tw-Z1FXqyPCWfLLf64d7fx8mYZcWBXk6WsV9JDSPyg/viewform").`,
    type: EStepTypes.FEEDBACK_FREETEXT,
    additionalProps: {
      options: [
        { uid: 1, label: "Strongly agree" },
        { uid: 2, label: "Agree" },
        { uid: 3, label: "Neither agree nor disagree" },
        { uid: 4, label: "Disagree" },
        { uid: 5, label: "Strongly disagree" },
      ],
    },
    optional: true,
    preSteps: [],
    group: 99,
    userRoleLimit: [UserGroup.TEACHER, UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN],
  },
];
