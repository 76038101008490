import { IKeyword } from "../../lib/Keyword/IKeyword";

export const exampleKeyword = {
  uid: "k",
  label: "KeywordLabel",
  rtext: ["keyword example", "keyword"],
  description: "Example description for !keyword!",
};

export const exampleKeywordMap: IKeyword[] = [exampleKeyword];

export const exampleNode = {
  properties: {
    href: `_keyword_${exampleKeywordMap[0].uid}`,
  },
  children: [{ value: "keyword" }],
};

export const text = `# Example text
!keyword example! and some more text but ignore this keyword`;
