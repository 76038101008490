import React from "react";
import styled from "styled-components";
import { PageTitle, Section, ConstrainedPageStyle, SectionHeading, SubSectionHeading, P, Ul, FloatImageMax, TableOfContentsWrapper, TableOfContents, SectionBackground } from "../styles/page";
import { HeroBackground, HeroStyle, BoxoutHero } from "../styles/hero";
import { CTALinkButtonStyle } from "../styles/links";
import { Link } from "react-router-dom";

const ActivityLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin-left: -3em;

  img,
  a {
    object-fit: contain;
    width: 7rem;
    height: 7rem;
    margin-right: 1.4rem;
    margin-bottom: 1.4rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const CodesignHeroStyle = styled(HeroStyle)`
  @media (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    height: 30rem;
    min-height: 30rem;
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    height: 20rem;
    min-height: 20rem;
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    height: 15rem;
    min-height: 15rem;
  }
`;

const CodesignPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="page__wrap">
      <SectionBackground>
      <PageTitle>Co-design Process</PageTitle>
      <CodesignHeroStyle>
        <HeroBackground
          src="/images/co-design/co_design_illustration_transparent.png"
          style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
        />
      </CodesignHeroStyle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#What-is-co-design">What is co-design?</Link>
              </li>
              <li>
                <Link to="#Co-design-with-schools">
                  Co-design with schools
                </Link>
              </li>
              <li>
                <Link to="#Evolution-of-SAMHE-line-plots">Evolution of SAMHE line plots</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <Section>
            <SectionHeading id="What-is-co-design">What is co-design?</SectionHeading>
            <P>
              Co-design is where users are involved in the design process. It's about designing <b>with</b>, not just{" "}
              <b>for</b> people. In the first part of the SAMHE project we invited schools to work with us to design, refine
              and test our interactive Web App and activities to make sure they meet schools' needs and are fun and engaging
              for pupils.
            </P>
          </Section>
          <Section>
            <SectionHeading id="Co-design-with-schools">How we worked with schools to co-design the SAMHE Web App</SectionHeading>
            <P>
              The input of teachers and pupils has been critical to making SAMHE a success and we are so grateful to all the
              schools who have helped us. Thank you!
            </P>
            <SubSectionHeading>Stage 1: co-design</SubSectionHeading>
            <P>
              Throughout May - July 2022 we met with teachers and pupils from around 20 'co-design' schools to discuss how
              SAMHE will work in schools and to get their input on the design of our Web App.
            </P>
            <P>
              <b>With the help of our co-design schools we:</b>
              <Ul>
                <li style={{marginTop: '1rem'}}>Designed our Logo <br></br> <img src="/images/co-design/logo-design.png" alt="logo development" width="100%" /></li>
                <li>
                  Discussed which websites schools like using which helped us to design what our Web App will look like and
                  what it will do. Our conversations with schools helped us to decide that clear information and excellent
                  content were more important than having a particular theme (suggestions ranged from magic to robot
                  football teams).
                </li>
                <li>
                  Learnt about the different ways in which SAMHE could fit into schools which has shaped the content we plan
                  to offer - we will provide SAMHE activities suitable for use in lessons, extracurricular clubs, to explore
                  careers links, as part of extended projects or even as a 5 minute bell task.
                </li>
                <li>
                  Considered how content should be presented within the Web App and how to help users find the content that
                  matters most to them
                </li>
                <li>
                  Designed some of the activities that will be available in the Web App - we've divided these into different
                  types including Data Analysis, Environmental Actions (for example, opening windows), People Actions (for
                  example, moving around in the classroom) and Data Entry (for example, telling us where the monitor is and
                  what is going on in the room around it). Schools helped us to refine and improve these categories and had
                  really exciting ideas for additional activities they'd like to run.
                  <ActivityLogos>
                    <img src="/images/co-design/creative.png" alt="Creative activities" />
                    <img src="/images/co-design/environment_action.png" alt="Environment Action activities" />
                    <img src="/images/co-design/data_analysis.png" alt="Analysis activities" />
                    <img src="/images/co-design/data_entry.png" alt="Data Entry activities" />
                    <img src="/images/co-design/data_detective.png" alt="Data detective activities" />
                    <img src="/images/co-design/people_action.png" alt="People action activities" />
                    <img src="/images/co-design/quiz.png" alt="Quiz activities" />
                  </ActivityLogos>
                </li>
                <li>Decided to use videos and quizzes to help make the Web App interesting and sustain engagement.</li>
              </Ul>
            </P>
            <SubSectionHeading>Stage 2: co-refine and test - Pioneer Schools</SubSectionHeading>
            <P>
              From August - October, whilst our software engineers were busy constructing the Web App, we recruited 123
              schools (including many of our co-design schools) as SAMHE 'Pioneer Schools'. Between November 2022 and April
              2023 these schools helped us test and refine a range of important aspects of the project, including:
            </P>
            <P>
              <Ul>
                <li>
                  Set up processes to enable teachers to start using the monitor and Web App, such as WiFi connections and
                  account set up
                </li>
                <li>
                  The functionality and usability of the Web App, such as users being able to find their data and understand
                  how it is represented
                </li>
                <li>
                  The structure and content of the activities in the Web App, such as if activities are clear to use,
                  interesting and relevant to pupils
                </li>
              </Ul>
            </P>
          </Section>
          <Section>
            <BoxoutHero>
              <SubSectionHeading id="Evolution-of-SAMHE-line-plots">Case study: The evolution of SAMHE line plots</SubSectionHeading>
              <P>
                During the SAMHE Pioneers Phase we dedicated several sessions to refining data visualisations within the Web
                App and discussing which should be implemented next. To show the influence of Pioneer Schools' feedback take
                a look at these before and after images of our line graphs…
              </P>
              <P><b>Before Pioneer Schools' input:</b></P>
              <FloatImageMax
                src="/images/co-design/pre_pioneers_co2_live_data_cropped.png"
                alt="Screenshot before Pioneer Schools' input"
                style={{ borderRadius: '0.3rem' }}
              />
              <br></br>
              <P><b>After Pioneer Schools' input:</b></P>
              <FloatImageMax
                src="/images/co-design/Web_App_datavis13.06.23.png"
                alt="Screenshot after Pioneer Schools' input"
                style={{ borderRadius: '0.3rem' }}
              />
              <br></br>
              <P style={{ maxWidth: '100%' }}><b>New features implemented as a direct result of Pioneer Schools' feedback include:</b></P>
              <P>
                <Ul>
                  <li>
                    Toggleable options to show maximum and minimum markers and/or overlay a line representing the average
                  </li>
                  <li>
                    Toggleable option to start y at 0 or at the minimum observed data point (maximising use of plot space)
                  </li>
                  <li>Customisable date selection options to allow more focused analysis of data</li>
                  <li>Customisable intervals on the x axis</li>
                  <li>Improved graph description</li>
                </Ul>
              </P>
            </BoxoutHero>
          </Section>
          <Section>
            <P>
              The feedback from teachers and pupils has been invaluable in helping us shape the project to meet its three
              aims:
            </P>
            <P>
              <Ul>
                <li>
                  To generate the data our project scientists need in order to better understand schools' indoor air quality
                  across the UK
                </li>
                <li>
                  To improve schools' access to air quality data, empowering both pupils and staff to make informed decisions
                  about classroom ventilation
                </li>
                <li>To increase the awareness and understanding of air quality among young people in the UK</li>
              </Ul>
            </P>
          </Section>
          <Section>
            <CTALinkButtonStyle to="/timeline">View the SAMHE project timeline</CTALinkButtonStyle>
          </Section>
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default CodesignPage;
