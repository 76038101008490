import { HeaderDropdown, HeaderLabel } from "../../styles/header";

export const AppHeaderDropdown = ({
  selectOptions,
  id,
  label,
  onChange,
  value,
}: {
  id: string;
  label: string;
  selectOptions: {
    id: string | number;
    label: string;
  }[];
  onChange: (newValue: string) => void;
  value: string;
}) => (
  <>
    <HeaderLabel htmlFor={id}>{label}</HeaderLabel>
    <HeaderDropdown style={{ maxWidth: "100%" }} id={id} value={value} onChange={(e) => onChange(e.target.value)}>
      {selectOptions.map(({ id, label }) => (
        <option value={id} key={id}>
          {label}
        </option>
      ))}
    </HeaderDropdown>
  </>
);
