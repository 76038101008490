import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ConstrainedPageStyle, PageTitle, Section, P, SectionHeading, SectionSubHeading, Ul } from "../styles/page";
import { AExternalButtonStyle } from "../styles/links";
import { AExternal } from "../components/Links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const PressPackButton = styled(AExternalButtonStyle)`
  padding: 0.3rem 1rem;
  display: inline-block;

  background-color: #4c5ebc;

  &:focus {
    outline: #28367d solid 3px;
    outline-offset: -4px;
    border-color: ${({ theme }) => theme.colors.grey1};
  }
  &:hover {
    background: #28367d;
  }
  &:disabled,
  &[disabled] {
    background: ${({ theme }) => theme.colors.grey2};
  }
  &:disabled,
  &[disabled] {
    background: ${({ theme }) => theme.colors.grey2};
  }
`;

export const VisualsTable = styled.table`
  width: 100%;
  border: 1px solid;
  border-collapse: collapse;
  td {
    vertical-align: top;
    width: 33.3%;
    border: 1px solid;
    padding: 0.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const MediaPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <Section>
        <PageTitle>Media</PageTitle>
        <SectionHeading>Report SAMHE's findings on air quality in UK schools</SectionHeading>
        <P>
          The SAMHE project ends on 31 July and we have a number of findings to report on indoor air quality in UK
          schools.
        </P>
        <P>
          We anticipate that schools will continue to use their SAMHE monitors and the Web App and we will keep
          collecting data from connected monitors until at least 2030. This growing dataset will continue be used by
          researchers to further our understanding of air quality in schools and make recommendations to improve it.
        </P>
      </Section>
      <Section>
        <SectionSubHeading>
          <b>Download a press pack containing:</b>
        </SectionSubHeading>
        <Ul>
          <li>Key info</li>
          <li>Press contact</li>
          <li>Press release</li>
          <li>List of visuals</li>
          <li>SM handles & hashtags</li>
        </Ul>
        <PressPackButton href="https://docs.google.com/document/d/1rfYNLnpzLswuVbovrypU90Kg7m02ieXFGBykVFcaxWY/export?format=doc" download target="_blank">
          Download press pack (June 2024) <FontAwesomeIcon style={{ marginLeft: "0.3rem" }} icon={faDownload} />
        </PressPackButton>
      </Section>

      <Section>
        <SectionHeading>Visuals</SectionHeading>
        <P>Click link to download full size image.</P>
        <VisualsTable>
          <tr>
            <td>
              <P>
                <Link to="/images/resources/media/SAMHE_logo.png" target="_blank" download>
                  SAMHE logo
                </Link>
              </P>
              <img src="/images/resources/media/SAMHE_logo.png" alt="SAMHE logo" />
            </td>
            <td>
              <P>
                <Link to="/images/resources/media/SAMHE_monitor.jpg" target="_blank" download>
                  SAMHE monitor
                </Link>
              </P>
              <img src="/images/resources/media/SAMHE_monitor_thumbnail.jpg" alt="SAMHE monitor" />
            </td>
            <td>
              <P>
                <AExternal href="https://www.pexels.com/photo/woman-in-green-long-sleeve-shirt-teaching-kids-8363734/">
                  Teacher with open window
                </AExternal>{" "}
                (generic free photo)
              </P>
              <img
                src="/images/resources/media/teacher-open-window-background.jpg"
                alt="teacher open window background"
              />
            </td>
          </tr>
          <tr>
            <td>
              <P>
                <Link to="/images/webapp_landing_page.jpg" target="_blank" download>
                  SAMHE Web App screenshot - landing page
                </Link>
              </P>
              <img src="/images/webapp_landing_page.jpg" alt="SAMHE landing page" />
            </td>
            <td>
              <P>
                <Link to="/images/CO2_line_plot_May_2024.png" target="_blank" download>
                  SAMHE Web App screenshot - a simple line plot of CO<sub>2</sub>
                </Link>
              </P>
              <img src="/images/CO2_line_plot_May_2024.png" alt="CO2 plot" />
            </td>
            <td>
              <P>
                <AExternal href="https://unsplash.com/photos/Y8TiLvKnLeg">Pupil using computer</AExternal> (generic free
                photo)
              </P>
              <img
                src="/images/resources/media/compare-fibre.jpg"
                alt="boy working on school work with laptop and headphones"
              />
            </td>
          </tr>
        </VisualsTable>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default MediaPage;
