import { compose, AnyAction } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import reducer from "./reducers";
import initialState from "./initialState";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const getStore = (_initialState = initialState) =>
  configureStore({
    reducer,
    preloadedState: _initialState,
  });
const store = getStore();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export type DefaultThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;
