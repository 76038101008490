import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { UserGroup } from "../lib/User/UserGroup";
import { mockSchools, mockServerMonitors } from "./";
import { IUser } from "../lib/User/IUser";
import { EAgeGroup } from "../lib/User/AgeGroup";

const demoSchoolId = mockSchools.find((s) => s.status === ESchoolStatus.ACTIVATED).schoolId;
const demoMonitorId = mockServerMonitors.find((s) => s.DateActivated).MonitorReferenceId;

export interface IMockUserDetails extends IUser {
  password: string;
}

export const demoUsers: Record<UserGroup | "schooladminnew" | "studentnew", IMockUserDetails> = {
  [UserGroup.SUPERADMIN]: {
    username: "admin",
    password: "admin",
    group: UserGroup.SUPERADMIN,
    additionalRoles: [UserGroup.SUPERADMIN],
    schoolId: demoSchoolId,
    isSuper: false,
    abProfile: {
      showABProfile: true,
      allowExpandingDataCards: true,
      allowExpandingActivityCards: true,
    },
    schools: [demoSchoolId],
    monitors: [demoMonitorId],
    ageGroup: null,
  },
  [UserGroup.SCHOOLADMIN]: {
    username: "schooladmin",
    password: "schooladmin",
    group: UserGroup.SCHOOLADMIN,
    additionalRoles: [UserGroup.SCHOOLADMIN],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [demoSchoolId],
    monitors: [demoMonitorId],
    abProfile: {},
    ageGroup: null,
  },
  schooladminnew: {
    username: "schooladminnew",
    password: "schooladminnew",
    group: UserGroup.SCHOOLADMIN,
    additionalRoles: [UserGroup.SCHOOLADMIN],
    schoolId: mockSchools.find((s) => s.status === ESchoolStatus.RECEIVED).schoolId,
    isSuper: false,
    schools: [mockSchools.find((s) => s.status === ESchoolStatus.RECEIVED).schoolId],
    monitors: [demoMonitorId],
    abProfile: {},
    ageGroup: null,
  },
  [UserGroup.TEACHER]: {
    username: "teacher",
    password: "teacher",
    group: UserGroup.TEACHER,
    additionalRoles: [UserGroup.TEACHER],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [demoSchoolId],
    monitors: [demoMonitorId],
    abProfile: {},
    ageGroup: null,
  },
  [UserGroup.STUDENT]: {
    username: "student",
    password: "student",
    group: UserGroup.STUDENT,
    additionalRoles: [UserGroup.STUDENT],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [demoSchoolId],
    monitors: [demoMonitorId],
    ageGroup: EAgeGroup.Between11And12,
    abProfile: {
      showABProfile: true,
      allowExpandingDataCards: true,
      allowExpandingActivityCards: true,
    },
  },
  studentnew: {
    username: "studentnew",
    password: "studentnew",
    group: UserGroup.STUDENT,
    additionalRoles: [UserGroup.STUDENT],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [demoSchoolId],
    monitors: [demoMonitorId],
    ageGroup: null,
    abProfile: {
      showABProfile: true,
      allowExpandingDataCards: true,
      allowExpandingActivityCards: true,
    },
  },
  [UserGroup.DEPLOYMENTADMIN]: {
    username: "deployer",
    password: "deployer",
    group: UserGroup.DEPLOYMENTADMIN,
    additionalRoles: [UserGroup.DEPLOYMENTADMIN],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [],
    monitors: [],
    ageGroup: null,
    abProfile: {},
  },
  [UserGroup.GUEST]: {
    username: "",
    password: "",
    group: UserGroup.GUEST,
    additionalRoles: [UserGroup.GUEST],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [],
    monitors: [],
    ageGroup: null,
    abProfile: {},
  },
  [UserGroup.RESEARCHER]: {
    username: "researcher",
    password: "researcherP",
    group: UserGroup.RESEARCHER,
    additionalRoles: [UserGroup.RESEARCHER],
    schoolId: demoSchoolId,
    isSuper: false,
    schools: [],
    monitors: [],
    ageGroup: null,
    abProfile: {},
  },
};
