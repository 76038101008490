import { rest } from "msw";
import { SERVER_URL } from "../config";
import * as database from "../dummyApi/database";
import { EAchievementCategory } from "../lib/Achievements/EAchievementCategory";
import { IAchievementUser } from "../lib/Achievements/IAchievement";
import { IApiGetAchievementsResponse, IApiGetUserScoreResponse } from "./apiAchievements";

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    useMockApi: false,
    endpoint: rest.get<null, null, IApiGetAchievementsResponse>(
      `${SERVER_URL}/Achievement/GetUserAchievementProgresses`,
      (req, res, ctx) => {
        const achievements = database.getDocs<IAchievementUser>("AchievementsUser");
        const nestedAchievements = achievements.map((a) => ({
          achievement: a,
          status: a.status,
          dateCreated: a.dateCreated,
        }));
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            data: {
              result: nestedAchievements,
            },
          })
        );
      }
    ),
  },
  {
    useMockApi: false,
    endpoint: rest.get<null, null, IApiGetUserScoreResponse>(
      `${SERVER_URL}/UserScore/GetUserScores`,
      (req, res, ctx) => {
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            data: {
              result: [
                {
                  category: {
                    id: EAchievementCategory.QUIZ,
                    label: "Achievement Category 1",
                  },
                  score: 19,
                },
                {
                  category: {
                    id: EAchievementCategory.TEAM_STUDENT,
                    label: "Achievement Category 3",
                  },
                  score: 27,
                },
              ],
            },
          })
        );
      }
    ),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
