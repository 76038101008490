import { exampleTags } from "../../../../dummyData/tags";
import { AchievementCategories, EAchievementCategory } from "../../../../lib/Achievements/EAchievementCategory";
import { EEquipmentNeeded } from "../../../../lib/Activities/EEquipmentNeeded";
import { EGrades } from "../../../../lib/Activities/EGrades";
import { EThemes } from "../../../../lib/Activities/EThemes";
import {
  EActivityStatus,
  EActivityTypes,
  IActivity,
  IActivityUserDataNotStarted,
} from "../../../../lib/Activities/IActivity";
import { EStepTypes, IStep, TStepAdditionalProps } from "../../../../lib/Activities/IStep";
import { IQuizMultiChoiceExtraProps } from "../../StepTypes/QuizMultiChoice";

export const exampleQuizSteps: IStep<TStepAdditionalProps>[] = [
  {
    id: 1,
    stepRef: "step1",
    label: "Step 1",
    description: `# What is Air Quality?
Pick from the options below...`,
    type: EStepTypes.QUIZ_MULTI_CHOICE,
    additionalProps: {
      options: [
        { uid: 1, label: "Opt1", isCorrect: false, responseMessage: "That is not the right answer" },
        { uid: 2, label: "Opt2", isCorrect: true, responseMessage: "That's right!" },
        { uid: 3, label: "Opt3", isCorrect: false, responseMessage: "That is not the right answer either" },
      ],
    } as IQuizMultiChoiceExtraProps,
  },
];

export const quizExample: IActivity = {
  id: "quizExample",
  label: "Quiz Activity",
  imageUrl: "/images/app/activities/icons/QUIZ_SMALL.png",
  longDescription: `This is a long description of the activity...

It can span multiple lines.

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**

# And use headings etc.

**and any other markdown functionality**
`,
  description: `This is a **short** description of the activity...

It can span multiple lines.
`,
  type: EActivityTypes.QUIZ,
  equipmentNeeded: [EEquipmentNeeded.ANY],
  targetGrade: [EGrades.LVL1],
  bannerImageUrl: "/images/app/general/Molecules.png",
  textFilePath: "lkjlkjkl",
  noOfPeople: "3+",
  timeEstimates: {
    investigation: 5,
    video: 8,
    discussion: 14,
  },
  theme: EThemes.B,
  activitySteps: exampleQuizSteps,
  tags: [exampleTags.co2, exampleTags.air_quality, exampleTags.data, exampleTags.dataentry, exampleTags.measurements],
  activityScores: [
    {
      category: AchievementCategories[EAchievementCategory.QUIZ],
      score: 13,
    },
    {
      category: AchievementCategories[EAchievementCategory.DATA_ANALYST],
      score: 10,
    },
  ],
  cheatCodes: {
    complete: "examplecheatcode",
  },
  nextActivityIds: [],
  previousActivityIds: [],
};

export const quizExampleUserProgress: IActivityUserDataNotStarted = {
  activityId: quizExample.id,
  activityStatus: EActivityStatus.NOT_STARTED,
};
