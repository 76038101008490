import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";

// // Label for single axis
export const weekdaylabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

//Create label for axis ticks only from hours included in the data
export const hourlabels = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
];

export type SeriesOptionsType = (
  day: string,
  hours: number[],
  idx: number,
  dayCount?: number
) => {
  title: object;
  singleAxis: object;
  series: object;
};

/* Aggregate days for bubble plot */
export const aggregateDays = (options: SeriesOptionsType) => (rawData: IMonitorDataItem<number>[]) => {
  // Create a new property for the day of the week in code (0 to 6, where Monday is 0)
  //// and add this property to each array
  const data2: {
    dateStamp: Date;
    value: number; // TODO: Change to value
    weekday: number;
  }[] = rawData.map((obj) => {
    const currentDateStamp = new Date(obj.dateStamp);

    const dateStamp = currentDateStamp;
    const value = obj.value;
    const weekday = (currentDateStamp.getDay() + 6) % 7;
    return {
      dateStamp,
      value,
      weekday,
    };
  });

  // Create a key where every hour is an interger
  const data3 = data2.map((obj) => {
    const currentDataStamp = new Date(obj.dateStamp);
    const value = parseFloat(String(obj.value));
    const weekday = obj.weekday;
    const hour = currentDataStamp.getHours();
    return {
      value,
      weekday,
      hour,
    };
  });

  // Obtain a mean for each hour
  const groupedData: {
    [k: string]: {
      weekday: number;
      hour: number;
      meanVal: number;
    };
  } = data3.reduce(
    (groups, item) => {
      const { weekday, hour } = item;
      const key = `${weekday}-${hour}`;

      if (!groups[key]) {
        groups[key] = { weekday, hour, total: 0, count: 0, meanVal: null };
      }

      groups[key].total += item.value;
      groups[key].count++;
      groups[key].meanVal = groups[key].total / groups[key].count;

      const total = groups[key].total;
      const count = groups[key].count;
      const meanVal = parseFloat((total / count).toFixed(0));
      groups[key].meanVal = meanVal;

      return groups;
    },
    {} as {
      [k: string]: {
        weekday: number;
        hour: number;
        meanVal: number;
        total: number;
        count: number;
      };
    }
  );

  const result: {
    weekday: number;
    hour: number;
    meanVal: number;
  }[] = Object.values(groupedData);

  // Create data array with object elements in the form [weekday, hour, meanVal]

  const dateData = [];
  result.forEach((item) => {
    const weekday = item.weekday;
    const hour = item.hour;
    const meanVal = item.meanVal;

    dateData.push([weekday, hour, meanVal]);
  });

  // Create label for single axis only from days included in the data

  let dayoftheweek = dateData.map((item) => item[0]);
  dayoftheweek = Array.from(new Set(dayoftheweek));

  let days = dayoftheweek.map((item) => weekdaylabels[item]);
  days = Array.from(new Set(days));

  //Identifies hours included in the data

  const hoursIncluded = [];
  dateData.forEach((item) => {
    hoursIncluded.push(item[1]);
  });

  // Creates an array from the hours included,
  ////filling in any gaps so the spacing between hours is always 1.

  const hoursNoGap = [];
  const start = Math.min(...hoursIncluded);
  const end = Math.max(...hoursIncluded);
  for (let i = start; i <= end; i++) {
    hoursNoGap.push(i);
  }

  //Creates an array with the hour labels

  let hours = [];
  hoursNoGap.forEach((item) => hours.push(hourlabels[item]));
  hours = Array.from(new Set(hours));

  // // Data for the series in the form of [[Day, hour, CO2(ppm)]]. Day 0 is Monday and 6 is Sunday. 24h format.
  const title = [];
  const singleAxis = [];
  const series = [];

  // //creates different objects with the properties of the title, single axis and series for each day of the week
  days.forEach(function (day, idx) {
    const o = options(day, hours, idx, days.length);
    title.push({
      ...o.title,
    });
    singleAxis.push({
      ...o.singleAxis,
    });
    series.push({
      ...o.series,
    });
  });
  // // Creates the data object inside each series object
  dateData.forEach(function (dataItem) {
    series[dayoftheweek.indexOf(dataItem[0])].data.push([hoursNoGap.indexOf(dataItem[1]), dataItem[2]]);
  });

  return { series, title, singleAxis, data: dateData, hoursNoGap };
};
