import styled from "styled-components";
import { Mandatory } from "./Mandatory";

export interface ILabelProps {
  name?: string;
  label: string | React.ReactNode;
  longDescription?: string;
  required?: boolean;
  htmlFor?: string;
}

export const LabelWrapStyle = styled.div`
  text-align: left;
  label {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }
`;

export const Label = ({ name, label, longDescription, required }: ILabelProps) => {
  return (
    <LabelWrapStyle>
      <b><label htmlFor={name}>{label}</label></b>
      {required ? <Mandatory /> : ""} {longDescription}
    </LabelWrapStyle>
  );
};

export const MultiCheckBoxLabel = ({ label, longDescription, required, htmlFor }: ILabelProps) => {
  return (
    <LabelWrapStyle>
      <b><label htmlFor={htmlFor}>{label}</label></b>
      {required ? <Mandatory /> : ""} {longDescription}
    </LabelWrapStyle>
  );
};

