import React from "react";
import styled from "styled-components";
import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
import { cardComponentTypeMap as dataViewsCardComponentTypeMap } from "../DataViewDashboard/cardComponentMap";
import { useGetDataViewsMetaData } from "../DataViewDashboard/useGetDataViewMetaData";
import { useAppSelector } from "../../Redux/hooks";
import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
import Slider from "react-slick";
import { shuffleArray } from "../../utils/arrayUtils";

const EMPTY_ARRAY = [];

const StyledSlider = styled(Slider)`
  .slick-dots li button:before {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: ${({ theme }) => theme.typography.fontSize6};
    color: black;
  }

  .slick-prev,
  .slick-next {
    top: auto;
    z-index: 1000;
    bottom: -35px;
  }

  .slick-prev {
    left: ${({ theme }) => theme.shape.defaultPadding};
  }

  .slick-next {
    right: ${({ theme }) => theme.shape.defaultPadding};
  }

  .slick-slide > div {
    padding-top: ${({ theme }) => theme.shape.defaultPaddingMid};
    padding-left: ${({ theme }) => theme.shape.defaultPadding};
    padding-right: ${({ theme }) => theme.shape.defaultPadding};
  }

  div:focus {
    outline: none;
    border: none;
  }
`;

export const SidebarDataViews: React.FC = () => {
  const {
    state: { monitor: { id: monitorId } = { id: null } },
  } = useAppSelector((state) => state.userState);

  const [containerRef] = useGetBreakpoints(1.6);

  const { cards: dataCardsData, loadingViews: loadingDataViews } = useGetDataViewsMetaData({
    filters: EMPTY_ARRAY,
    monitorId,
    groupId: "appSidebarDataCards",
  });

  const dataCardsDataShuffled = React.useMemo(() => {
    const downloadCardIndices = dataCardsData.reduce((indices, item, index) => {
      if (item.label === "Download Data" || item.label === "Mega data download") {
        indices.push(index);
      }
      return indices;
    }, []);

    const downloadCards = downloadCardIndices.map((index) => dataCardsData[index]);
    const dataCardsDataFiltered = dataCardsData.filter((_, index) => !downloadCardIndices.includes(index));

    return shuffleArray(dataCardsDataFiltered).concat(downloadCards);
  }, [dataCardsData]);

  const dataCards = useGetCards({
    cardsData: dataCardsDataShuffled,
    cardComponentTypeMap: dataViewsCardComponentTypeMap,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 30000,
  };

  return (
    <>
      <div ref={containerRef}>
        {loadingDataViews && <p>Loading data views...</p>}
        <StyledSlider {...settings}>
          {dataCards.map((card, index) => (
            <div key={index}>{card}</div>
          ))}
        </StyledSlider>
      </div>
    </>
  );
};
