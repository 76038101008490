import { IDocument } from "@react_db_client/constants.client-types";
import { IMonitorRequest } from "../formSubmissions/IMonitorRequest";
import { IMonitorServerState, IMonitorState, MonitorId } from "../Monitor/IMonitor";
import { ESchoolStatus } from "./ESchoolStatus";
import { IRoomListItem, IRoomListItemServer } from "./IRoom";

export type SchoolId = string;

export interface ISchoolValidated extends IMonitorRequest {
  status: ESchoolStatus;
  monitors?: MonitorId[];
  monitorRequestId: number;
}

export interface ISchoolAppDetails extends ISchoolValidated {
  id: number;
  schoolId: string;
  status: ESchoolStatus;
  handle: string;
  secretWordTeacher: string;
  secretWordPupil: string;
  roomList?: IRoomListItem[];
}

export interface ISchoolState {
  id: SchoolId;
  schoolName: string;
  monitors: IMonitorState[];
  status: ESchoolStatus;
  roomList: IRoomListItem[];
  isPioneer?: boolean;
  disallowMoveYourMonitor?: boolean; // TODO: Add to API
}

export enum EMonitoringType {
  MOBILE = 1,
  STATIC = 2,
}

export interface ISchoolServer {
  Id: number;
  SchoolId: SchoolId;
  Monitors: IMonitorServerState[];
  SchoolStatus: ESchoolStatus;
  Rooms: IRoomListItemServer[];
  IsPioneer?: boolean;
  MonitoringType?: EMonitoringType;
}

export interface ISchoolSearchResult extends ISchoolValidated {}
export interface ISchoolDetailsPrivateSearchResult extends IDocument, ISchoolValidated {
  label: string;
}
