import React from "react";
import { ConstrainedPageStyle } from "../styles/page";
import { MonitorSetup } from "../components/MonitorSetup";

const MonitorActivationPage: React.FC = () => {
  return (
    <ConstrainedPageStyle data-testid="page-monitor-setup">
      <MonitorSetup />
    </ConstrainedPageStyle>
  );
};

export default MonitorActivationPage;
