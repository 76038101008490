import { EComparisons } from "@react_db_client/constants.client-types";
import {
  EStepTypes,
  INextStep,
  INextStepDependency,
  INextStepSimple,
  IStep,
  IStepUserData,
  TNextStepId,
  TStepAdditionalProps,
  TStepRef,
  TStepState,
} from "../../lib/Activities/IStep";
import { IMultipleChoiceState } from "./StepTypes/MultiChoiceStep";

export const runEqualityComparison = (
  { targetField, targetValue }: INextStepDependency,
  previousStepsState: IStepUserData<TStepState>[],
  activitySteps: IStep<TStepAdditionalProps>[],
  thisStep: IStep<TStepAdditionalProps>,
  thisStepState: IStepUserData<TStepState>
) => {
  const stepData: IStep<TStepAdditionalProps> =
    targetField === "$this" ? thisStep : activitySteps.find((step) => step.stepRef === targetField);
  if (!stepData) return false;
  const dependencyStepSubmission: IStepUserData<TStepState> =
    targetField === "$this"
      ? thisStepState
      : previousStepsState
          .slice()
          .reverse()
          .find((p) => p.stepRef === targetField);

  if (stepData.type === EStepTypes.MULTIPLE_CHOICE) {
    if (
      (dependencyStepSubmission as IStepUserData<IMultipleChoiceState>).state.selectionIds.find(
        (id) => id === targetValue
      )
    ) {
      return true;
    } else {
      return false;
    }
    //
  } else {
    throw new Error(`Step type not implemented: ${stepData.type}`);
  }
};

export const runDependencyComparisons =
  (
    previousStepsState: IStepUserData<TStepState>[],
    activitySteps: IStep<TStepAdditionalProps>[],
    thisStep: IStep<TStepAdditionalProps>,
    thisStepState: IStepUserData<TStepState>
  ) =>
  (d: INextStepDependency) => {
    if (d.comparison === EComparisons.EQUALS) {
      return runEqualityComparison(d, previousStepsState, activitySteps, thisStep, thisStepState);
    } else {
      throw new Error(`Comparison type not implemented: ${d.comparison}`);
    }
  };

export const getNextStep = (
  nextStepId: TStepRef | TNextStepId | null = null,
  availableNextSteps: (INextStepSimple | INextStep)[],
  previousStepsState: IStepUserData<TStepState>[],
  activitySteps: IStep<TStepAdditionalProps>[],
  thisStep: IStep<TStepAdditionalProps>,
  thisStepState: IStepUserData<TStepState>
): TStepRef => {
  if (!nextStepId && availableNextSteps) {
    return availableNextSteps
      .map((step) => {
        if (typeof step === "object") {
          const { stepRef, dependencies } = step as INextStep;
          const passes = dependencies
            ? dependencies.every(runDependencyComparisons(previousStepsState, activitySteps, thisStep, thisStepState))
            : true;
          return passes ? stepRef : null;
        }
        return step;
      })
      .find((step) => step) as TStepRef;
  }
  return nextStepId as TStepRef;
};
