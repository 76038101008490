import React, { useEffect } from "react";
import {
  EStepStatus,
  IStep,
  IStepUserData,
  TStepAdditionalProps,
  TStepRef,
  TStepState,
  TStepStatePropertyData,
  TStepStatePropertyId,
} from "../../../lib/Activities/IStep";
import {
  ActivityStepSection,
  ActivityStepWrap,
  EditCompletedBtn,
  StepIndex,
  StepStatusIconStyle,
  StepTitle,
  StepTitleBar,
  StepCollapsible,
} from "../../../styles/app";
import { StepStatusIcon } from "./StepStatusIcon";
import { ButtonStyle } from "../../../styles/button";
import ErrorBoundary from "../../../GenericComponents/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export interface IChildProps {
  onChange: (id: TStepStatePropertyId, newValue: TStepStatePropertyData) => void;
  onSubmit: () => void;
  state: { [id: string]: any };
}

export interface IStepComponentWrapProps {
  children: (child: IChildProps) => React.ReactNode;
  isActive: boolean;
  previousSteps: TStepRef[];
  optional?: boolean;
  loadedState: IStepUserData<TStepState>;
  stepData: IStep<TStepAdditionalProps>;
  index: number;
  goToStepRef: (ref: TStepRef) => void;
  lockCompleteSteps?: boolean;
  allowUnlockCompleteSteps?: boolean;
}

/**
 * Step Component Wrap
 * Handles step state and visibility
 */
export const StepComponentWrap = ({
  index,
  children,
  isActive,
  loadedState,
  optional,
  stepData,
  previousSteps,
  goToStepRef,
  lockCompleteSteps,
  allowUnlockCompleteSteps,
}: IStepComponentWrapProps) => {
  const isComplete = previousSteps?.indexOf(stepData.stepRef) !== -1;
  const [stepRenderError, setStepRenderError] = React.useState("");
  const [allowEditComplete, setAllowEditComplete] = React.useState(false);
  const handleGoBackBtn = () => {
    goToStepRef(previousSteps[previousSteps.length - 1]);
  };
  const isReady = stepData.preSteps ? stepData.preSteps.every((sid) => previousSteps?.indexOf(sid) !== -1) : true;

  const { status, state } = loadedState || {};

  const [stateEdit, setStateEdit] = React.useState<TStepState>(state);
  const { label, id } = stepData;
  const currStatus = isReady ? status || EStepStatus.NOT_STARTED : EStepStatus.BLOCKED;
  const [isCollapsed, setIsCollapsed] = React.useState(!currStatus);
  const [icon, setIcon] = React.useState(faCaretUp);

  const currentStepRef = React.useRef(null);

  useEffect(() => {
    if (isReady && status) {
      setIcon(faCaretUp);
      // only look forward
      let sibling = currentStepRef.current.nextElementSibling;
      let firstReadyElement = null;
      while (sibling) {
        firstReadyElement = sibling.querySelector('[data-isready="true"]');
        if (firstReadyElement) {
          firstReadyElement.scrollIntoView({ behavior: "smooth" });
          break;
        }
        sibling = sibling.nextElementSibling;
      }
    } else {
      setIcon(faCaretDown);
    }
  }, [isReady, status]);

  const handleTitleBarClick = () => {
    if (isReady) {
      setIsCollapsed((prev) => !prev);
      setIcon((prevIcon) => (prevIcon === faCaretUp ? faCaretDown : faCaretUp));
    } else {
      setIsCollapsed(isCollapsed);
    }
  };

  return (
    <ErrorBoundary onError={(e) => setStepRenderError(e.message)}>
      {stepRenderError && <p>Step: {id} failed to render!</p>}
      <ActivityStepWrap data-testid={`stepComponentWrap-${id}`} locked={!isReady} ref={currentStepRef}>
        <ActivityStepSection
          optional={optional}
          active={isActive}
          locked={isComplete && lockCompleteSteps && !allowEditComplete}
          data-testid="stepComponentWrapStepSection"
        >
          {isComplete && allowUnlockCompleteSteps && !allowEditComplete && (
            <EditCompletedBtn onClick={() => setAllowEditComplete(true)}>Click to change your answer</EditCompletedBtn>
          )}
          <StepTitleBar
            onClick={handleTitleBarClick}
            data-isready={isReady && !isComplete && !isActive && !isCollapsed ? "true" : "false"}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon icon={icon} style={{ marginRight: "1rem" }} color="white" size="xl" />
              <StepIndex>
                {index + 1}
                {") "}
              </StepIndex>{" "}
              <label htmlFor={`stepInput_${id}`}>
                <StepTitle className="activityStepLabel" style={{ marginRight: "0.4rem" }}>
                  {label}
                </StepTitle>
              </label>
            </div>
            <StepStatusIconStyle>
              <StepStatusIcon stepStatus={currStatus} />
            </StepStatusIconStyle>
          </StepTitleBar>
          <StepCollapsible
            style={{
              display:
                !isReady || isCollapsed || (isComplete && allowUnlockCompleteSteps && !allowEditComplete)
                  ? "none"
                  : "block",
            }}
          >
            {isReady &&
              children({
                onChange: (iid: TStepStatePropertyId, newValue: TStepStatePropertyData) => {
                  setStateEdit((prev) => ({ ...(prev || {}), [iid]: newValue }));
                },
                onSubmit: () => setAllowEditComplete(false),
                state: stateEdit,
              })}
            {isActive && previousSteps?.length > 0 && <ButtonStyle onClick={handleGoBackBtn}>Go Back</ButtonStyle>}
          </StepCollapsible>
        </ActivityStepSection>
      </ActivityStepWrap>
    </ErrorBoundary>
  );
};
