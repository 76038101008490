import { AExternalStyle, DownloadLinkStyle, LinkExternalStyle } from "../../styles/links";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faDownload } from '@fortawesome/free-solid-svg-icons'

export const AExternal = (props: React.ComponentProps<typeof AExternalStyle>) => {
  return (
    <>
      <AExternalStyle {...props} rel="noopener noreferrer" target="_blank">
        {props.children}
        <FontAwesomeIcon style={{ marginLeft: '0.3rem', fontSize: '0.7rem'}}icon={faExternalLink} />
      </AExternalStyle>
    </>
  );
};

export const DownloadLink = (props: React.ComponentProps<typeof DownloadLinkStyle>) => {
  return (
    <DownloadLinkStyle download {...props}>
      {props.children}
      <FontAwesomeIcon style={{ marginLeft: '0.3rem'}}icon={faDownload} />
    </DownloadLinkStyle>
  );
};

export const LinkExternal = (props: React.ComponentProps<typeof LinkExternalStyle>) => {
  return (
    <LinkExternalStyle {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </LinkExternalStyle>
  );
};
