import { rest } from "msw";
import { SERVER_URL } from "../config";
import { IApiResponse } from "./apiHelpers";
import { IMonitorServerState } from "../lib/Monitor/IMonitor";
import * as database from "../dummyApi/database";
import { apiActivateMonitor } from "./apiMonitorManagement";

const Invalid = (req, res, ctx) => (message) =>
  res(
    ctx.status(403),
    ctx.json({
      errorMessage: message,
    })
  );
const Ok = (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      ok: true,
    })
  );

export const validateRequest = (req, res, ctx) => {
  if (!req?.body) {
    return Invalid(req, res, ctx)("Missing request body");
  }
  return;
};

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean; mockedFn: any }[] = [
  {
    useMockApi: false,
    mockedFn: apiActivateMonitor,
    endpoint: rest.post<{ monitorId }, any, IApiResponse>(
      `${SERVER_URL}/MonitorManagement/ActivateMonitor`,
      (req, res, ctx) => {
        const { monitorId } = req.body;
        const serverMonitor = database.getDoc<IMonitorServerState>(monitorId, "Monitors", "MonitorReferenceId");
        if (!serverMonitor) return res(ctx.status(401), ctx.json({ ok: false }));
        const serverMonitorActivated = {
          ...serverMonitor,
          DateActivated: new Date().toISOString(),
        };
        database.putDoc(monitorId, "Monitors", serverMonitorActivated);
        return Ok(req, res, ctx);
      }
    ),
  },
  {
    useMockApi: false,
    mockedFn: apiActivateMonitor,
    endpoint: rest.post<{ monitorId: string; roomId: string }, any, IApiResponse>(
      `${SERVER_URL}/MonitorManagement/ChangeLocation`,
      (req, res, ctx) => {
        const { monitorId } = req.body;
        const serverMonitor = database.getDoc<IMonitorServerState>(monitorId, "Monitors", "MonitorReferenceId");
        if (!serverMonitor) return res(ctx.status(401), ctx.json({ ok: false }));
        // TODO: Handl;e actually changing db here
        // const serverMonitorMoved = {
        //   ...serverMonitor,
        //   DateMoved: new Date().toISOString(),
        //   RoomId: roomId,
        // };
        // database.putDoc(monitorId, "Monitors", serverMonitorMoved);
        return Ok(req, res, ctx);
      }
    ),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
