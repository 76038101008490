import styled from "styled-components";

export const CollapsableSection = styled.div<{
  open: boolean;
  maxHeight: number | string;
  minHeight?: number | string;
  transitionTime?: number;
}>`
  max-height: ${({ open, maxHeight, minHeight }) => (open ? maxHeight : minHeight || 0)};
  transition: max-height ${({ transitionTime }) => transitionTime || "0.5"}s
    ${({ open }) => (open ? "ease-in" : "ease-out")};
`;

export const CollapsableSectionInner = styled.div<{open?}>`
  height: 100%;
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
  max-width: 100%;
`;
