import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";
import { mockSchools } from "./schools";

export const mockMonitorRequests: IMonitorRequest[] = [
  {
    id: 91,
    establishmentId: "91",
    dateCreated: new Date("2021-01-01"),
    schoolAddress1: "School Address1",
    schoolName: "Registered SchoolName1",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "monitorRequest@email.com",
    dateValidated: undefined,
    schoolEmail: "",
    schoolTelephone: "",
    schoolAddress2: "",
    schoolAddress3: "",
    schoolAddress4: "",
    schoolId: "91",
    dateStartedValidating: undefined,
  },
  {
    id: 191,
    establishmentId: "191",
    dateCreated: new Date("2021-01-01"),
    schoolAddress1: "School Address2",
    schoolName: "Registered SchoolName2",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "monitorRequest@email.com",
    dateValidated: undefined,
    schoolEmail: "",
    schoolTelephone: "",
    schoolAddress2: "",
    schoolAddress3: "",
    schoolAddress4: "",
    schoolId: "191",
    dateStartedValidating: undefined,
  },
  {
    id: 192,
    establishmentId: "191", // duplicate establishmentId
    dateCreated: new Date("2021-01-01"),
    schoolAddress1: "School Address3",
    schoolName: "Registered SchoolName3",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "monitorRequest@email.com",
    dateValidated: undefined,
    schoolEmail: "",
    schoolTelephone: "",
    schoolAddress2: "",
    schoolAddress3: "",
    schoolAddress4: "",
    schoolId: "192",
    dateStartedValidating: undefined,
  },
  {
    id: 92,
    establishmentId: "92",
    dateCreated: new Date("2021-01-01"),
    schoolAddress1: "School Address1",
    schoolName: "Registered SchoolName2",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "monitorRequest@email.com",
    schoolEmail: "",
    schoolTelephone: "",
    schoolAddress2: "",
    schoolAddress3: "",
    schoolAddress4: "",
    schoolId: "92",
    dateValidated: undefined,
    dateStartedValidating: new Date("2020-01-01"),
  },
  {
    id: 93,
    establishmentId: "93",
    dateCreated: new Date("2021-01-01"),
    schoolAddress1: "School Address1",
    schoolName: "Registered SchoolName3",
    schoolAddressPostCode: "SchoolAddressPostCode",
    firstName: "FirstName",
    lastName: "LastName",
    email: "monitorRequest@email.com",
    schoolEmail: "",
    schoolTelephone: "",
    schoolAddress2: "",
    schoolAddress3: "",
    schoolAddress4: "",
    schoolId: "93",
    dateValidated: new Date("2020-02-02"),
    dateStartedValidating: new Date("2020-01-01"),
  },
  ...mockSchools.map((s) => ({
    id: s.id,
    establishmentId: String(s.id),
    dateCreated: s.dateCreated,
    schoolAddress1: s.schoolAddress1,
    schoolName: s.schoolName,
    schoolAddressPostCode: s.schoolAddressPostCode,
    firstName: s.firstName,
    lastName: s.lastName,
    email: s.email,
    schoolEmail: s.schoolEmail,
    schoolTelephone: s.schoolTelephone,
    schoolAddress2: s.schoolAddress2,
    schoolAddress3: s.schoolAddress3,
    schoolAddress4: s.schoolAddress4,
    schoolId: s.schoolId,
    dateValidated: s.status >= ESchoolStatus.VALIDATED && s.dateValidated,
    dateStartedValidating: s.status >= ESchoolStatus.VALIDATING && s.dateStartedValidating,
  })),
];
