import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AuthContext } from "react-oauth2-code-pkce";
import { CTAButtonStyle } from "../../styles/button";
import { FormStyle, InputStyle } from "../../styles/form";
import { InputWrap } from "../FormComponents/Input";
import { ISchoolAdminFormState } from "../../lib/formSubmissions/ISchoolAdminSubmission";
import { apiSchoolAdmin } from "../../Api/apiSchoolAdmin";
import { P, SectionHeading } from "../../styles/page";
import { ErrorMessageStyle } from "../FormComponents/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { reduxGetUserData } from "../../Redux/User/userReducer";

export const SchoolAdminForm = ({
  schoolAdminData,
  apiSubmitData = apiSchoolAdmin.post, // Overriden in activation form
  onSuccessfulSubmit = () => {},
  refreshUserDataAfterSubmit = false,
}: {
  schoolAdminData: Partial<ISchoolAdminFormState>;
  apiSubmitData?: typeof apiSchoolAdmin.post;
  onSuccessfulSubmit?: () => void;
  refreshUserDataAfterSubmit?: boolean;
}) => {
  const { token } = React.useContext(AuthContext);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);

  const dispatch = useAppDispatch();
  const [message, setMessage] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFormSubmit = async (data: ISchoolAdminFormState) => {
    setMessage("Submitting Form");
    setLoading(true);
    setError("");

    const submissionData = {
      ...data,
    };

    // TODO: Validate form
    return apiSubmitData(token)(submissionData)
      .then(() => {
        onSuccessfulSubmit();
        dispatch(reduxGetUserData(token, refreshUserDataAfterSubmit, monitorId));
      })
      .catch((e) => {
        console.log(e);
        if (String(e) === "ApiError: HandleAlreadyExists") {
          // Catches specific errors
          setError("Please try a different school handle");
        }
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formData = useForm<ISchoolAdminFormState>({
    defaultValues: {
      ...schoolAdminData,
      monitorId: String(monitorId),
    },
    reValidateMode: "onSubmit",
  });

  const { handleSubmit, formState } = formData;
  const { isSubmitted, isSubmitSuccessful, isSubmitting, isValidating, errors } = formState;

  React.useEffect(() => {
    if (isValidating || isSubmitting) {
      setMessage("-");
    }
  }, [isValidating, isSubmitting]);

  const containsErrors = Object.keys(errors).length > 0;

  const displayMessage = React.useMemo(
    () =>
      (isSubmitting && "Submitting Form.") ||
      (isSubmitted && containsErrors && "Form has validation errors. Check you have filled in all required fields.") ||
      (isSubmitted && !isSubmitSuccessful && error && `There was an error submitting your answers. ${error}`) ||
      (isSubmitted && !isSubmitSuccessful && "There was an error submitting your answers. Please try again.") ||
      (isSubmitted && isSubmitSuccessful && "Submission successful.") ||
      message ||
      " ",
    [isSubmitted, containsErrors, isSubmitSuccessful, message, isSubmitting, error]
  );

  const isErrorMessage = React.useMemo(() => {
    return containsErrors || (isSubmitted && !isSubmitSuccessful);
  }, [containsErrors, isSubmitSuccessful, isSubmitted]);

  return (
    <div className="schoolAdminForm_wrap">
      <FormProvider {...formData}>
        <FormStyle
          aria-label="School Registration Form"
          name="schoolRegistrationForm"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div style={{ display: "block", width: "100%", margin: "1rem 0" }}>
            <SectionHeading>School Handle and Secret Words</SectionHeading>
            <P>
              To associate SAMHE Web App accounts with your school's monitor data we use a 'School Handle' and 'Secret
              Word' (separate Secret Words for teachers and for pupils).{" "}
            </P>
            <P>
              As your school's admin user you have access privileges which allow you to change your school's School
              Handle and/or Secret Words at any time. This will affect the way that all users at your school access
              their accounts.
            </P>
            <P>
              <i>
                In the boxes below you can see the School Handle and Secret Words we have stored in our system for your
                school. If the boxes are blank, no entry has been recorded so you will need to fill these in. If you
                would like to change your School Handle and/or Secret Words please write over the existing entries and
                hit 'Submit' to save your changes.
              </i>
            </P>
          </div>
          <InputWrap
            name="schoolHandle"
            label="Set your School Handle"
            type="string"
            required
            longDescription="Please enter a word (or words) to identify your school. Anyone who sets up an account at your school will need to use the School Handle to log in. Please pick something that is easy to remember."
          />
          <div style={{ display: "block", width: "100%", margin: "1rem 0" }}>
            <SectionHeading>Secret Words</SectionHeading>
            <P>
              Secret Words should be easy for you to remember but not easy for people outside of your school to guess.
              For this reason we recommend that you do not include any part of your school's name. Please keep these
              Secret Words safe and only provide them to pupils and teachers within your school.
            </P>
          </div>
          <InputWrap name="secretWordTeacher" label="Teacher Secret Word" type="string" required />
          <InputWrap name="secretWordPupil" label="Pupil Secret Word" type="string" required />
          {isErrorMessage && <ErrorMessageStyle data-testid="displayErrorMessage">{displayMessage}</ErrorMessageStyle>}
          {!isErrorMessage && <div data-testid="displayMessage">{displayMessage || " "}</div>}
          <InputStyle>
            <CTAButtonStyle style={{ width: "100%" }} type="submit" disabled={loading}>
              {loading ? "Loading" : "Submit"}
            </CTAButtonStyle>
          </InputStyle>
        </FormStyle>
      </FormProvider>
    </div>
  );
};
