import React from "react";
import { ConstrainedPageStyle, PageTitle, Section, P, FloatImageWrapperWithCredit } from "../styles/page";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";

export const AirCleaningDevicesContent = (
  <>
    <PageTitle className="hideFromGlossary">Air Cleaning Devices</PageTitle>
    <Section>
      <FloatImageWrapperWithCredit desktopStyleOverride={"max-width: 20rem"}>
        <img src="/images/resources/air-cleaning-devices/mi-air-purifier-machine.png" alt="an air purifier" />
        <p className="credit">
          (<AExternal href="https://www.rawpixel.com/image/2398109">Image</AExternal> by{" "}
          <AExternal href="https://www.rawpixel.com/">rawpixel.com</AExternal>)
        </p>
      </FloatImageWrapperWithCredit>
      <div>
        <P>
          If ventilation in your school is generally poor (
          <Link to="/resources/monitor-leds">
            as indicated by CO<sub>2</sub> levels consistently above 1500 ppm
          </Link>
          ) and you cannot open the windows (or would not want to, for example if next to a busy road), then air
          cleaning devices are an option for improving air quality. These remove a portion of pollutants from the air.
          An air cleaning device will have no effect on the CO<sub>2</sub> concentration nor the humidity in the room.
        </P>
        <P id="hepa-filter">
          Standalone filters are a type of air cleaning device, but there are also others which use different
          technologies. The most common type of air cleaning device that can be found in classrooms, and in some cases
          supplied by the Department for Education, is a standalone HEPA filter unit. HEPA stands for High-Efficiency
          Particulate Air filter. Such units use a fan to draw air through a very fine filter; the filter catches most
          small particles (<Link to="/resources/key-definitions#particulate-matter">particulate matter</Link>) before
          the filtered air is returned to the classroom. These standalone filters can help to reduce the concentration
          of particulate matter including aerosols, within a room. You could even build your own low-cost air cleaning
          device,{" "}
          <AExternal href="https://drive.google.com/file/d/1WPrJBBD9uItDzv0jl0uP3OXIWR5Y7ghQ/view">
            a Corsi-Rosenthal box
          </AExternal>
          .
        </P>
        <P>
          Standalone filter units circulate the air within the room but do not introduce any fresh air from outdoors.
          For that reason, an air cleaning unit is not a substitute for good ventilation.{" "}
          <Link to="/resources/ventilation">Opening windows and other ventilation strategies</Link> should still be used
          in addition to the device. More information on air cleaning devices is given by the 'How to' guides from the
          Department for Education (DfE) on{" "}
          <AExternal href="https://dfecomms.blob.core.windows.net/guidance/ACUHowToGuide2023.pdf">
            how to use an air cleaning device
          </AExternal>
          , and a 10-step guide to{" "}
          <AExternal href="https://www.tes.com/magazine/analysis/general/covid-schools-ventilation-10-step-guide-using-air-cleaning-units">
            air-cleaning units
          </AExternal>
          . The TAPAS network has also written a{" "}
          <AExternal href="https://tapasnetwork.co.uk/air-cleaners-in-schools">position paper</AExternal> on the use of
          air cleaners in schools.
        </P>
      </div>
    </Section>
  </>
);

export const AirCleaningDevices = () => {
  return AirCleaningDevicesContent;
};

const AirCleaningDevicesPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <AirCleaningDevices />
    </ConstrainedPageStyle>
  );
};

export default AirCleaningDevicesPage;
