import React from "react";
import {
  ConstrainedPageStyle,
  PageTitle,
  Section,
  P,
  SectionHeading,
  FloatImageMax,
  Ul,
  TableOfContents,
  TableOfContentsWrapper,
  SectionBackground
} from "../styles/page";
import { AExternal } from "../components/Links";
import { Link } from "react-router-dom";
import { HeroBackground, BackgroundHeroStyle } from "../styles/hero";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";

const AirPollutionAndHealthPage: React.FC = () => {
  return (
    <>
      <ConstrainedPageStyle>
        <SectionBackground>
          <PageTitle>Air Pollution and Health</PageTitle>
          <BackgroundHeroStyle>
            <HeroBackground src="/images/resources/air-pollution-and-health/man-in-black-suit-jacket-looking-into-microscope.jpg" />
          </BackgroundHeroStyle>
        </SectionBackground>
        <TableOfContentsWrapper>
          <div>
            <TableOfContents>
              <h4>Contents:</h4>
              <Ul>
                <li>
                  <Link to="#co2-and-attention-levels">CO<sub>2</sub> and attention levels</Link>
                </li>
                <li>
                  <Link to="#co2-levels-and-airborne-infection-risk">CO<sub>2</sub> levels and airborne infection risk</Link>
                </li>
                <li>
                  <Link to="#other-air-quality-metrics-and-health">Other air quality metrics and health</Link>
                </li>
                <li>
                  <Link to="#more-information">More information</Link>
                </li>
              </Ul>
            </TableOfContents>
          </div>
          <div>
            <Section>
              <P>
                Good indoor air quality, good lighting and noise levels ensure school environments are a good place to
                work and learn. This page contains information about the links between some of the metrics measured by
                the SAMHE monitor and health.
              </P>
            </Section>
            <Section>
              <SectionHeading id="co2-and-attention-levels">
                CO<sub>2</sub> and attention levels
              </SectionHeading>
              <P>
                High carbon dioxide (CO<sub>2</sub>) levels in rooms have been linked with increased drowsiness and
                headaches, which can mean it is harder to learn. We have all had the experience of feeling sleepy in a
                room that feels "stuffy"; this could be our bodies telling us that CO<sub>2</sub> levels might be high.
              </P>
              <FloatImageMax
                src="/images/resources/air-pollution-and-health/woman-leaning-on-table.jpg"
                alt="Woman Leaning on Table"
              />
              <P>
                High CO<sub>2</sub> levels are also known to impact how well people's brains work.{" "}
                <AExternal href="https://doi.org/10.1289/ehp.1510037">One study</AExternal> on adults found that
                increasing CO<sub>2</sub> levels from around 600 ppm to 1400 ppm reduced test scores by 50%. High CO
                <sub>2</sub> levels are also linked with{" "}
                <AExternal href="https://onlinelibrary.wiley.com/doi/full/10.1111/ina.12042">
                  increased absences
                </AExternal>{" "}
                from school, so avoiding high CO<sub>2</sub> levels can help improve attendance.
              </P>
              <P>
                However, CO<sub>2</sub> itself{" "}
                <b>
                  <u>is not</u>
                </b>{" "}
                a dangerous gas. High CO<sub>2</sub> levels is an indicator of poor ventilation, which can cause issues
                with damp and mould and spreading of respiratory illnesses. For more information about ventilation,
                please take a look at our <Link to="/resources/ventilation">ventilation guidance for schools</Link>. The{" "}
                <AExternal href="https://www.worldventil8day.com/facts-resources">World Ventil8 Day</AExternal> Website
                and{" "}
                <AExternal href="https://www.worldventil8day.com/facts-resources">
                  National Engineering Policy Centre pages
                </AExternal>{" "}
                also give useful insight into the importance of ventilation. Carbon dioxide should certainly not be
                confused with Carbon monoxide (CO), since Carbon monoxide{" "}
                <b>
                  <u>is</u>
                </b>{" "}
                a dangerous gas.{" "}
              </P>
            </Section>
            <Section>
              <SectionHeading id="co2-levels-and-airborne-infection-risk">
                CO<sub>2</sub> levels and airborne infection risk
              </SectionHeading>
              <P>
                The main source of carbon dioxide (CO<sub>2</sub>) in most classrooms is the CO<sub>2</sub> coming from
                peoples' exhaled breath. In the absence of combustion or other CO<sub>2</sub> sources, CO<sub>2</sub> is
                a good measure of the proportion of air within a classroom that has already been breathed out by someone
                else: this is called 'rebreathed air'.
              </P>
              <P>
                When someone has a respiratory infection, like SARS-CoV-2 for COVID-19 or the influenza virus for the
                flu, they will exhale small virus particles that can be transported by air currents and mixed with the
                air in the room. If these particles are then breathed in by someone else, this could potentially lead to
                that person becoming infected by the virus: infection via this transmission route is called 'airborne
                infection'.
              </P>
              <FloatImageMax
                src="/images/resources/air-pollution-and-health/teacher-teaching-her-students.jpg"
                alt="Teacher Teaching Her Students"
              />
              <P>
                If{" "}
                <Link to="/resources/monitor-leds">
                  the concentration of CO<sub>2</sub> within a room is high
                </Link>
                , this indicates low ventilation per occupant, that is, the air within the room is not being renewed
                very much and the proportion of rebreathed air is larger than it would be in a better ventilated room.
              </P>
              <P>
                If someone within the room happens to be infected with a respiratory virus, this means that (all else
                being equal) there is then a larger chance that the air within the room might contain infected particles
                that could infect someone else. CO<sub>2</sub> concentration is only a partial indicator for airborne
                infection risk, it does not indicate the risk for other transmission routes by which someone might
                become infected with a respiratory virus; for example, transmission via close contact with someone who
                is carrying the virus.
              </P>
            </Section>
            <Section>
              <SectionHeading id="other-air-quality-metrics-and-health">
                Other air quality metrics and health
              </SectionHeading>
              <P>
                Outdoor air pollution is a major health issue, according to the{" "}
                <AExternal href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health">
                  World Health Organisation
                </AExternal>
                . Poor quality air indoors can also cause ill health, and is important as most people in the UK spend
                the majority of their time indoors. Health issues arising from poor air quality can be chronic
                (developing gradually after breathing in poor air over long periods, often many years), or acute
                (developing quickly due to exposure to high levels of a specific pollutant). Asthma is a chronic
                condition with acute episodes (asthma attacks) which can be triggered by exposure to high spikes in air
                pollution. Other immediate effects of exposure to pollutants can include irritated eyes, nose or throat,
                headaches and dizziness. Removing the source, taking the affected person away from the source, and/or
                ventilating the room, are usually appropriate solutions.
              </P>
              <FloatImageMax
                src="/images/resources/air-pollution-and-health/composite-image-for-air-pollution.jpg"
                alt="composite image for air pollution and health"
              />
              <P>
                Relative humidity can be another important factor. For example, if humidity is frequently high (over
                65%), it can create a breeding ground for dust mites and mould, which are common allergens, can
                exacerbate asthma, and can result in other health conditions.
              </P>
              <P>
                Volatile Organic Compounds are emitted from many different sources, both indoor and outdoor, including
                paints, cleaning products, air fresheners, spray deodorants, and cooking. There are many different VOCs,
                some with known health effects, and some with unknown health effects.
              </P>
              <P> More research is needed about the
                effects of VOCs and other indoor air pollutants on health, as there is uncertainty around what levels of
                pollutant or periods of exposure cause specific health conditions. The{" "}
                <AExternal href="https://www.rcpch.ac.uk/resources/inside-story-health-effects-indoor-air-quality-children-young-people#downloadBox">
                  Inside Story report
                </AExternal>{" "}
                reviewed the existing evidence and gives more information about the health effects of indoor air quality
                on children and young people.
              </P>
            </Section>
            <Section>
              <SectionHeading id="more-information">More information</SectionHeading>
              <P>
                For worksheets about indoor air pollution and health, aimed at primary-school children, see the{" "}
                <AExternal href="https://theinsidestory.health/worksheets/">Inside Story resources page</AExternal>.
              </P>
            </Section>
            <ScrollTopButton />
          </div>
        </TableOfContentsWrapper>
      </ConstrainedPageStyle>
    </>
  );
};

export default AirPollutionAndHealthPage;
