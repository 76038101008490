import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { faCaretDown, faCaretUp, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SchoolId } from "../../lib/School/ISchoolDetails";
import { useAppSelector } from "../../Redux/hooks";
import { IUserState } from "../../Redux/User/userReducer";
import { Section, SectionHeading, P, PageTitle } from "../../styles/page";
import { LinkExternal } from "../Links";
import { MonitorHealthCheck } from "../MonitorHealthCheck";
import { CTAButtonStyle } from "../../styles/button";
import { FaqItemContentStyle, FaqItemHeadingStyle, FaqItemStyle, FaqItemWrapStyle } from "../../styles/faq";
import { MonitorActivation } from "./MonitorActivation";
import { CTALinkButtonStyle } from "../../styles/links";

export interface IMonitorSetupProps {}

const LockableSectionHeading = styled(FaqItemHeadingStyle)<{ locked?: boolean }>`
  background: ${({ theme, locked }) => (locked ? theme.colors.grey2 : theme.colors.text)};
  border: 1px solid ${({ theme, locked }) => (locked ? theme.colors.grey2 : theme.colors.text)};

  &:focus {
    text-decoration: none;
    outline: 4px solid ${({ theme, locked }) => (locked ? theme.colors.grey2 : theme.colors.boxoutBackground)};
    outline-offset: -4px;
  }

  &:hover {
    background-color: ${({ theme, locked }) => (locked ? theme.colors.grey2 : theme.colors.boxoutBackground)};
  }
  h3 {
    color: ${({ theme, locked }) => (locked ? theme.colors.text : theme.background)};
  }
`;

export interface ISetupItemProps {
  open?: boolean;
  children?: React.ReactNode;
  label: string;
  locked?: boolean;
  complete?: boolean;
}
export const SetupItem = ({ label, open = false, children, locked, complete }: ISetupItemProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const [icon, setIcon] = React.useState(faCaretDown);

  React.useEffect(() => {
    // Auto close if closed externally
    if (!open) setIsOpen(false);
    if (open) setIsOpen(true);
  }, [open]);

  return (
    <FaqItemStyle>
      <LockableSectionHeading
        locked={locked}
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIcon(icon === faCaretDown ? faCaretUp : faCaretDown);
        }}
      >
        <h3>
          {label}
          <FontAwesomeIcon
            size="xl"
            style={{ float: "right", marginLeft: "0.5rem" }}
            icon={complete ? faCheck : icon}
          />
        </h3>
      </LockableSectionHeading>
      <FaqItemContentStyle isOpen={isOpen}>
        <div>{children}</div>
      </FaqItemContentStyle>
    </FaqItemStyle>
  );
};

export const MonitorSetup: React.FC<IMonitorSetupProps> = () => {
  const [readPlacingYourMonitor, setReadPlacingYourMonitor] = React.useState(false);
  const [readConnectingToWifiGuide, setReadConnectingToWifiGuide] = React.useState(false);
  const [monitorPassedHealthCheck, setMonitorPassedHealthCheck] = React.useState(false);
  const state = useAppSelector<IUserState>((state) => state.userState.state);
  const schoolId: SchoolId = state.school?.id as string;
  const selectedMonitor = state.monitor?.data;
  const serialNumber = selectedMonitor?.id || "";
  const hasMonitors = state.school?.data?.monitors?.length;
  const allMonitorsActivated = state.school?.data?.monitors?.every((m) => m.activated) || false;

  return (
    <>
      <PageTitle>Monitor Link page</PageTitle>
      <Section>
        <FaqItemWrapStyle>
          <SetupItem
            label="1/4. Link your monitor"
            locked={allMonitorsActivated}
            open={!allMonitorsActivated || !hasMonitors}
            complete={allMonitorsActivated}
          >
            <MonitorActivation />
          </SetupItem>
          <SetupItem
            label="2/4. Place your monitor"
            locked={!selectedMonitor?.activated || readPlacingYourMonitor}
            complete={readPlacingYourMonitor}
            open={!readPlacingYourMonitor && selectedMonitor?.activated}
          >
            {!selectedMonitor && <P>Please select your monitor from the dropdown in the header above!</P>}
            {selectedMonitor?.activated && (
              <>
                <P>
                  Please read our{" "}
                  <LinkExternal to="/resources/monitor-placement-guide">advice on monitor placement</LinkExternal> when
                  choosing where you put your monitor.
                </P>
                <CTAButtonStyle onClick={() => setReadPlacingYourMonitor(true)}>
                  I have read the monitor placement guide
                </CTAButtonStyle>
              </>
            )}
            {selectedMonitor && !selectedMonitor?.activated && <P>Please link your monitor above!</P>}
          </SetupItem>
          <SetupItem
            label="3/4. Connect your monitor to the WiFi"
            locked={!selectedMonitor?.activated || !readPlacingYourMonitor || readConnectingToWifiGuide}
            complete={monitorPassedHealthCheck || readConnectingToWifiGuide}
            open={
              !monitorPassedHealthCheck &&
              selectedMonitor?.activated &&
              readPlacingYourMonitor &&
              !readConnectingToWifiGuide
            }
          >
            {!readPlacingYourMonitor && <P>Please complete step 2 first!</P>}
            {monitorPassedHealthCheck && <P>You monitor has successfully connected!</P>}
            {selectedMonitor?.activated && readPlacingYourMonitor && !monitorPassedHealthCheck && (
              <>
                {/* TODO: Get wifi setup instructions */}
                <P>
                  If you have already linked your monitor to the WiFi and can see air quality readings on the black
                  screen please continue to the next step.
                </P>
                <P>
                  If you still need to connect your monitor to the WiFi follow the instructions in{" "}
                  <Link to="/monitor-connection-guide">our monitor connection guide</Link>. We'd recommend you open this
                  link in a new tab.
                </P>
                <P>
                  If you are still having any trouble setting up your monitor please use our{" "}
                  <LinkExternal to="/support/tech-contact">Technical Support Form</LinkExternal>.
                </P>

                <CTAButtonStyle onClick={() => setReadConnectingToWifiGuide(true)}>
                  My monitor is connected to the wifi and I can see air quality readings on the black screen
                </CTAButtonStyle>
              </>
            )}
          </SetupItem>
          <SetupItem
            label="4/4. Monitor Health Check"
            locked={!readConnectingToWifiGuide || !selectedMonitor?.activated || monitorPassedHealthCheck}
            complete={monitorPassedHealthCheck}
            open={selectedMonitor?.activated && readConnectingToWifiGuide && !monitorPassedHealthCheck}
          >
            {!readConnectingToWifiGuide && <P>Please complete step 3 first!</P>}
            {selectedMonitor?.activated && readConnectingToWifiGuide && (
              <>
                <MonitorHealthCheck
                  schoolId={schoolId}
                  serialNumber={serialNumber}
                  onHealthCheckComplete={(result: boolean) => setMonitorPassedHealthCheck(result)}
                  callOnLoad
                />
              </>
            )}
          </SetupItem>
        </FaqItemWrapStyle>
      </Section>
      {selectedMonitor?.activated && monitorPassedHealthCheck && (
        <Section title="Success!">
          <SectionHeading>Success!</SectionHeading>
          <CTALinkButtonStyle to="/app">Go to the SAMHE Web App</CTALinkButtonStyle>
          {/* <div style={{ minHeight: "2rem" }} /> */}
          {/* <h3>Add teacher and student accounts.</h3>
          <P>In future you will need to provide the School Handle ... </P> */}
          {/* <P>
            To set up a teacher account, provide the teacher with the <b>School Handle</b> and{" "}
            <b>Secret Teacher Word</b> you set above. Point the teacher to the SAMHE{" "}
            <Link to="/register/teacher">teacher registration page</Link>
          </P>
          <div style={{ minHeight: "2rem" }} />
          <h3>Add pupil accounts</h3>
          <P>
            To set up a pupil account, provide the pupil with the <b>School Handle</b> and <b>Secret Pupil Word</b> you
            set above. Point the pupil to the SAMHE <Link to="/register/pupil">pupil registration page</Link>
          </P> */}
        </Section>
      )}
    </>
  );
};
