import { createGlobalStyle } from "styled-components";
/* ==========================================================================
   #RESET
   ========================================================================== */
export const ResetStyles = createGlobalStyle`
/**
 * A very simple reset that sits on top of Normalize.css.
 */

 body,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 blockquote,
 p,
 pre,
 dl,
 dd,
 ol,
 ul,
 figure,
 hr,
 fieldset,
 legend {
   margin: 0;
   padding: 0;
 }

 /**
  * Remove trailing margins from nested lists.
  */

 li > {
   ol,
   ul {
     margin-bottom: 0;
   }
 }

 /**
  * Remove default table spacing.
  */

 table {
   border-collapse: collapse;
   border-spacing: 0;
 }

 /**
  * 1. Reset Chrome and Firefox behaviour which sets a "min-width: min-content;"
  *    on fieldsets.
  */

 fieldset {
   min-width: 0; /* [1] */
   border: 0;
 }

 /* ==========================================================================
    #SHARED
    ========================================================================== */

 /**
  * Shared declarations for certain elements.
  */

 /**
  * Always declare margins in the same direction:
  * csswizardry.com/2012/06/single-direction-margin-declarations
  */

 address,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 blockquote,
 p,
 pre,
 dl,
 ol,
 ul,
 figure,
 hr,
 table,
 fieldset {
   margin-bottom: 0; //$unit;
 }

 /**
  * Consistent indentation for lists.
  */

 dd,
 ol,
 ul {
   margin-left: 1rem;
 }

 /* ==========================================================================
    #BOX-SIZING
    ========================================================================== */

 /**
  * More sensible default box-sizing:
  * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
  */

 html {
   box-sizing: border-box;
 }

 * {
   &,
   &:before,
   &:after {
     box-sizing: inherit;
   }
 }

 /* ==========================================================================
    #iOS
    ========================================================================== */

 // TODO: Can't set global, set as required for each element / component.
 /* input {
   appearance: none;
 } */
`