import styled from "styled-components";

export const FilterMenuContentStyle = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background};
`;

export const FilterElement = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.colors.main};
  padding: ${({ theme }) => theme.shape.defaultPadding};

  select {
    width: fit-content;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
`;
