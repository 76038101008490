import { DefaultTheme } from "styled-components";

// import original module declarations
import "styled-components";

export const themeColors = {
  PANTONE534C: "#303b71",
  PANTONE534CDark: "#1e254b",
  PANTONE5395: "#a7abc2",
  PANTONEBLACK: "#000000",

  PANTONE7452C: "#98a8fd",
  PANTONE2706C: "#cbd3ea",
  PANTONE2726C: "#4c5ebc",

  PANTONE375C: "#98d82a",
  PANTONE7486: "#d3eea4",
  PANTONE369C: "#67a300",

  PANTONE2592C: "#b52bd8",
  PANTONE2521C: "#dfa4ee",
  PANTONE2602C: "#8200a3",

  PANTONE311C: "#2cc4d9",
  PANTONE304C: "#a5e6ef",
  PANTONE7459C: "#0091a5",

  PANTONE420C: "#c9c9c9",
  PANTONE663C: "#e8e8e8",
  PANTONECOOLGREY9CC: "#7f7f7f",
  PANTONEWHITE: "#ffffff",

  PANTONE2010C: "#FFAD00",
};

export const accentColors = [
  themeColors.PANTONE2592C,
  themeColors.PANTONE375C,
  themeColors.PANTONE311C,
  themeColors.PANTONE2726C,
  themeColors.PANTONE2602C,
  themeColors.PANTONE7459C,
  themeColors.PANTONE7452C,
];

// and extend them!
declare module "styled-components" {
  export interface DefaultTheme {
    background: string;
    typography: {
      fontFamily: string;
      fontFamilyButton: string;
      fontFamilyHeader: string;
      fontWeightStandard: number;
      fontWeightBold: number;
      fontSize1: string;
      fontSize2: string;
      fontSize3: string;
      fontSize4: string;
      fontSize5: string;
      fontSize6: string;
      fontSize7: string;
      lineHeight: string;
      lineHeight0: string;
      lineHeight1: string;
      lineHeight2: string;
      lineHeight3: string;
      lineHeight4: string;
      lineHeight5: string;
      lineHeight6: string;
    };
    colors: {
      baseBackground: string;
      appBackground: string;
      main: string;
      mainHover: string;
      mainLighter: string;
      secondary: string;
      secondaryLight: string;
      secondaryDark: string;
      tertiary: string;
      tertiaryHover: string;
      text: string;
      link: string;
      linkHover: string;
      cta: string;
      ctaHover: string;
      ctaText: string;
      warning: string;
      off: string;
      offHover: string;
      grey1: string;
      grey2: string;
      grey5: string;
      grey9: string;
      textBoxBackground: string;
      infoButtonForeground: string;
      infoButtonBackground: string;
      boxoutBackground: string;
      boxoutlink: string;
      foreground: string;
    };
    shape: {
      defaultRadius: string;
      defaultRadiusWide: string;
      defaultPadding: string;
      defaultPaddingSmall: string;
      defaultPaddingMid: string;
      defaultPaddingWide: string;
      defaultPaddingLarge: string;
      defaultPaddingLarger: string;
    };
    mediaBoundaries: {
      xs: string;
      small: string;
      medium: string;
      large: string;
      larger: string;
    };
  }
}

export const defaultTheme: DefaultTheme = {
  background: "rgb(255, 255, 255)",
  typography: {
    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    fontFamilyButton: '"Varela Round", Arial, sans-serif',
    fontFamilyHeader: '"Varela Round", Arial, sans-serif',
    fontWeightStandard: 400,
    fontWeightBold: 800,
    fontSize1: "0.8rem",
    fontSize2: "0.9rem",
    fontSize3: "1.0rem",
    fontSize4: "1.3rem",
    fontSize5: "1.5rem",
    fontSize6: "1.8rem",
    fontSize7: "2.5rem",
    lineHeight: "1.6rem",
    lineHeight0: "1.1rem",
    lineHeight1: "1.3rem",
    lineHeight2: "1.6rem",
    lineHeight3: "1.7rem",
    lineHeight4: "1.9rem",
    lineHeight5: "2.0rem",
    lineHeight6: "2.2rem",
  },
  colors: {
    baseBackground: themeColors.PANTONE2706C,
    appBackground: `linear-gradient(
      90deg,
      ${themeColors.PANTONE534C} 0%,
      ${themeColors.PANTONE2592C} 100%
    )`,
    main: themeColors.PANTONE2592C,
    mainHover: themeColors.PANTONE2602C,
    mainLighter: themeColors.PANTONE2521C,
    secondary: themeColors.PANTONE311C,
    secondaryLight: themeColors.PANTONE304C,
    secondaryDark: themeColors.PANTONE7459C,
    tertiary: themeColors.PANTONE534C,
    tertiaryHover: themeColors.PANTONE534CDark,
    text: themeColors.PANTONE534C,
    link: themeColors.PANTONE2592C,
    linkHover: themeColors.PANTONE2602C,
    cta: themeColors.PANTONE2592C,
    ctaHover: themeColors.PANTONE2602C,
    ctaText: themeColors.PANTONEWHITE,
    warning: "tomato",
    off: "#ddd",
    offHover: "#ccc",
    grey1: "#ddd",
    grey2: "#ccc",
    grey5: "#777",
    grey9: "#444",
    textBoxBackground: "#eee",
    infoButtonForeground: "#eee",
    infoButtonBackground: themeColors.PANTONE534C,
    boxoutBackground: themeColors.PANTONE534C,
    boxoutlink: themeColors.PANTONE304C,
    foreground: themeColors.PANTONEWHITE,
  },
  shape: {
    defaultRadius: "0.5rem 0",
    defaultRadiusWide: "2rem 0",
    defaultPadding: "0.5rem",
    defaultPaddingSmall: "0.25rem",
    defaultPaddingMid: "1rem",
    defaultPaddingWide: "1.5rem",
    defaultPaddingLarge: "2rem",
    defaultPaddingLarger: "3rem",
  },
  mediaBoundaries: {
    xs: "350px",
    small: "400px",
    medium: "600px",
    large: "1080px",
    larger: "1300px",
  },
};

export const lightTheme: DefaultTheme = {
  ...defaultTheme,
  background: themeColors.PANTONEWHITE,
};

// TODO: Dark theme is untested
export const darkTheme: DefaultTheme = {
  ...defaultTheme,
  background: "#282c34",
  colors: {
    baseBackground: "#555",
    appBackground: "#555",
    main: "#33FF9C",
    mainHover: "#30d987",
    mainLighter: "",
    secondary: "#FF5733",
    secondaryLight: "#FF5733",
    secondaryDark: "#FF5733",
    tertiary: "#FF5733",
    tertiaryHover: "#FF5733",
    text: themeColors.PANTONEWHITE,
    link: "red",
    linkHover: "red",
    cta: "#dfa4ee",
    ctaHover: "#b52bd8",
    ctaText: "red",
    warning: "tomato",
    off: "#ddd",
    offHover: "#ccc",
    grey1: "#444",
    grey2: "#666",
    grey5: "#bbb",
    grey9: "#ddd",
    textBoxBackground: "#444",
    infoButtonForeground: "#111",
    infoButtonBackground: themeColors.PANTONE7459C,
    boxoutBackground: themeColors.PANTONE534C,
    boxoutlink: themeColors.PANTONE304C,
    foreground: themeColors.PANTONEWHITE,
  },
};

export const lockedactivityTheme: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    main: themeColors.PANTONE534C,
    mainLighter: themeColors.PANTONE2706C,
    mainHover: themeColors.PANTONE534CDark,
    cta: themeColors.PANTONE534C,
    ctaHover: themeColors.PANTONE534CDark,
    ctaText: themeColors.PANTONEWHITE,
  },
};

export const unlockedactivityTheme: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    main: themeColors.PANTONE2592C,
    mainLighter: themeColors.PANTONE2706C,
    mainHover: themeColors.PANTONE2602C,
    cta: themeColors.PANTONE2592C,
    ctaHover: themeColors.PANTONE2602C,
    ctaText: themeColors.PANTONEWHITE,
  },
};

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export default themes;
