import React from "react";
import styled from "styled-components";
import { ITeamMember } from "../../lib/Team/ITeam";
import { AExternal } from "../Links";
import { ButtonNoFormat, ButtonStyle } from "../../styles/button";
import { ISocialMediaButtonProps, SocialMediaButton, SocialMediaLinksStyle } from "../SocialMediaLinks";

export const TeamIconContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -0.5rem;
  position: relative;
`;

export const NameStyle = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize3};
`;

export interface ITeamIconStyleProps {
  opening: boolean;
  open: boolean;
  closing: boolean;
}

export const LHSContent = styled.div<ITeamIconStyleProps>`
  min-width: 15rem;
  max-width: 100%;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

export const RHSContent = styled.div<ITeamIconStyleProps>`
  min-width: 15rem;
  max-height: ${({ open }) => (open ? "30rem" : "0")};
  height: 100%;
  overflow: auto;
  padding-right: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-height: ${({ open }) => (open ? "30rem" : "15rem")};
  }
`;

export const BottomLeftContent = styled.div<ITeamIconStyleProps>`
  max-height: ${({ open }) => (open ? "40rem" : "0")};
  overflow: hidden;
`;

export const LabelledText = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
`;

export const MetaInfoRow = styled.p``;

export const TeamIconStyle = styled.div<ITeamIconStyleProps>`
  position: relative;
  max-height: ${({ open }) => (open ? "auto" : "20rem")};
  min-width: "30%";
  z-index: 1;
  ${({ open, theme }) =>
    open
      ? `
        @media only screen and (min-width: ${theme.mediaBoundaries.medium}) {
          position: fixed;
          left: 0;
          right: 0;
          top: 10%;
          z-index: 20;
          max-width: 980px;
          margin-left: auto;
          margin-right: auto;
          box-shadow: 1px 1px 70px 1px black;
        }
  `
      : ``}
  margin-left: 1rem;
  margin-bottom: 1rem;
  box-sizing: content-box;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    max-height: 100%;
    flex-direction: column;
  }

  overflow: hidden;
  border-radius: ${({ theme }) => theme.shape.defaultRadiusWide};
  transition: all 0.4s ease-in-out;
  padding: 2rem;

  &:hover,
  &:focus-within {
    img: {
      border-radius: 0;
    }
    div {
    }
    &::after {
      width: ${({ open }) => (open ? "100%" : "80%")};
      height: 100%;
      transition: all 0.3s ease-in-out;
    }
    &::before: {
    }
  }
  z-index: 1;

  &::before {
    content: "";
    background: ${({ theme }) => theme.colors.textBoxBackground};
    position: absolute;
    z-index: -1;
    top: 3px;
    left: 3px;
    bottom: 3px;
    right: 3px;
    border-radius: ${({ theme }) => theme.shape.defaultRadiusWide};
    pointer-events: none;
  }
  &::after {
    content: "";
    background: ${({ theme }) => theme.colors.mainLighter};
    position: absolute;
    top: 0rem;
    left: 0rem;
    right: 0;
    z-index: -2;
    height: 15rem;
    width: 1rem;
    border-radius: ${({ theme }) => theme.shape.defaultRadiusWide};
    transition: all 0.7s ease-in-out;
    pointer-events: none;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: ${({ open }) => (open ? "auto" : "10rem")};
    max-width: ${({ open }) => (open ? "auto" : "15rem")};
    max-height: ${({ open }) => (open ? "auto" : "30rem")};
    & > div {
      flex-direction: row;
    }
  }
`;

export const TeamIconPlaceHolder = styled(TeamIconStyle)`
  display: none;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: ${({ open }) => (open ? "inherit" : "none")};
    max-height: ${({ open }) => (open ? "20rem" : "0")};
    height: ${({ open }) => (open ? "16rem" : "0")};
    width: ${({ open }) => (open ? "12rem" : "0")};
    max-width: ${({ open }) => (open ? "15rem" : "0")};
    padding: ${({ open }) => (open ? "2rem" : "0")};
    margin-left: 1rem;
    margin-bottom: 1rem;
    // background: grey;
    position: relative;
    z-index: 1;
    border: none;
    outline: none;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
    transition: all 0s ease;
  }
`;

export const LogoButton = styled(ButtonNoFormat)`
  padding: 2px;
  img {
    transition: border-radius 0.2s ease-in;
    object-fit: cover;
    width: 10rem;
    border-radius: 3rem;
    height: 10rem;
    margin-bottom: 0.5rem;

    &:hover {
      border-radius: 0;
      // outline: 1px solid red;
    }
  }
  &:focus {
    img {
      outline: 2px solid ${({ theme }) => theme.colors.cta};
    }
  }
`;

export const ToggleBtn = styled(ButtonStyle)<ITeamIconStyleProps>`
  position: absolute;
  right: 13px;
  bottom: 13px;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 2rem;
  border: 1px solid grey;
  background: white;
  transform: rotate(45deg);
  box-shadow: none;
`;

export const SocialMediaXButton = styled(SocialMediaButton)<ISocialMediaButtonProps>`
  background-image: none;
  background-color: black;
  -webkit-mask-image: ${({ icon }) => `url("${icon}")`};
  mask-image: ${({ icon }) => `url("${icon}")`};
  mask-size: cover;
`;

export interface ITeamIconProps extends ITeamMember {
  autoOpen?: boolean;
  openMe: () => void;
  closeMe: () => void;
  open: boolean;
}

export interface ITeamIconsManagerProps {
  iconsData: ITeamMember[];
}

export const TeamIconsManager = ({ iconsData }: ITeamIconsManagerProps) => {
  const [nOpen, setNOpen] = React.useState(-1);

  const handleClosePanel = (i: number) => {
    if (nOpen === i) setNOpen(-1);
  };

  return (
    <TeamIconContainer>
      {iconsData.map((t, i) => (
        <TeamIcon
          key={t.uid}
          {...t}
          openMe={() => setNOpen(i)}
          closeMe={() => handleClosePanel(i)}
          open={i === nOpen}
        />
      ))}
    </TeamIconContainer>
  );
};

export const TeamIcon: React.FC<ITeamIconProps> = ({
  uid,
  name,
  shortDesc,
  website = null,
  institution,
  photo,
  jobTitle,
  twitterHandle,
  subjects,
  studyUni,
  advice,
  autoOpen,
  openMe,
  closeMe,
  open,
}) => {
  const [opening, setOpening] = React.useState(false);
  const [closing, setClosing] = React.useState(false);
  const openingTimeout = React.useRef(null);
  const closingTimeout = React.useRef(null);

  const handleHover = () => {
    if (!autoOpen) return;
    const t = openingTimeout.current;
    if (!open && !opening) {
      setOpening(true);
      clearTimeout(openingTimeout.current);
      openingTimeout.current = setTimeout(() => {
        openMe();
        setOpening(false);
      }, 500);
    }
    return () => {
      if (t) clearTimeout(t);
    };
  };

  const handleMouseLeave = () => {
    if (!autoOpen) return;
    const t = closingTimeout.current;
    if (!open && opening) {
      if (openingTimeout.current) clearTimeout(openingTimeout.current);
      setOpening(false);
    }
    if (open && !closing) {
      setClosing(true);
      if (closingTimeout.current) clearTimeout(closingTimeout.current);
      closingTimeout.current = setTimeout(() => {
        closeMe();
        setClosing(false);
      }, 200);
    }
    return () => {
      if (t) clearTimeout(t);
    };
  };

  const handleIconClick = () => {
    if (!open) {
      openMe();
      setOpening(false);
    } else {
      closeMe();
      setClosing(true);
    }
  };

  return (
    <>
      <TeamIconPlaceHolder closing={closing} opening={opening} open={open} />
      <TeamIconStyle
        closing={closing}
        opening={opening}
        open={open}
        onMouseOver={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <div style={{ height: "100%", overflow: "revert" }}>
          <ToggleBtn closing={closing} opening={opening} open={open} onClick={handleIconClick}>
            {open ? "<" : ">"}
          </ToggleBtn>
          <LHSContent closing={closing} opening={opening} open={open}>
            <div style={{ width: "100%", display: "flex" }}>
              <LogoButton onClick={handleIconClick}>
                <img src={photo ? `/images/team/${uid}.png` : "/images/logo/samhe_logo_robot.png"} alt={name} />
              </LogoButton>
              <div style={{ paddingLeft: "1rem" }}>
                {website && (
                  <SocialMediaLinksStyle>
                    <SocialMediaButton
                      icon="/images/icons/profileLink.png"
                      href={website}
                      target="_blank"
                      aria-label="Website Link"
                    >
                      Website
                    </SocialMediaButton>
                  </SocialMediaLinksStyle>
                )}
                {twitterHandle && (
                  <SocialMediaLinksStyle>
                    <SocialMediaXButton
                      icon="/images/icons/twitter.svg"
                      href={`https://twitter.com/${twitterHandle}`}
                      target="_blank"
                      aria-label="Twitter Link"
                    >
                      Twitter
                    </SocialMediaXButton>
                  </SocialMediaLinksStyle>
                )}
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <h2>
                {website ? (
                  <AExternal href={website}>
                    <NameStyle>
                      <b>{name}</b>
                    </NameStyle>
                  </AExternal>
                ) : (
                  <NameStyle>
                    <b>{name}</b>
                  </NameStyle>
                )}
              </h2>
              <p style={{ fontSize: "0.9rem" }}>
                <b>{shortDesc || jobTitle}</b>
              </p>
            </div>
            <BottomLeftContent closing={closing} opening={opening} open={open}>
              {institution && (
                <LabelledText>
                  <br></br>
                  <p>
                    <b>Institution</b>:{" "}
                  </p>
                  <p> {institution}</p>
                </LabelledText>
              )}
              {jobTitle && (
                <LabelledText>
                  <br></br>
                  <p>
                    <b>Job Title</b>:{" "}
                  </p>{" "}
                  <p>{jobTitle}</p>
                </LabelledText>
              )}
            </BottomLeftContent>
          </LHSContent>
          <RHSContent closing={closing} opening={opening} open={open}>
            <div>
              {subjects && (
                <LabelledText>
                  <p>
                    <b>School Subjects</b>:{" "}
                  </p>
                  <p> {subjects}</p>
                  <br></br>
                </LabelledText>
              )}
              {studyUni && (
                <LabelledText>
                  <p>
                    <b>What I studied at University</b>:{" "}
                  </p>
                  <p> {studyUni}</p>
                  <br></br>
                </LabelledText>
              )}
              {advice && (
                <LabelledText>
                  <p>
                    <b>What advice would I give?</b>:{" "}
                  </p>
                  <p> {advice}</p>
                </LabelledText>
              )}
            </div>
          </RHSContent>
        </div>
      </TeamIconStyle>
    </>
  );
};
