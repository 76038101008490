import styled from "styled-components";
import { ButtonGroup, ButtonNoFormat } from "./button";

export const FaqGroupWrapStyle = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const FaqGroupStyle = styled.li`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
    width: 100%;
    display: block;
    border-bottom: 5px solid ${({ theme }) => theme.colors.main};
    margin-bottom: 2rem;
  }
`;

export const FaqItemWrapStyle = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const FaqItemStyle = styled.li`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export interface IFaqItemHeadingStyle {
  highlighted?: boolean;
}

export const FaqItemHeadingStyle = styled(ButtonNoFormat)<IFaqItemHeadingStyle>`
  background-color: ${({ highlighted, theme }) => (highlighted ? theme.colors.secondary : theme.colors.text)};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  border: 1px solid ${({ highlighted, theme }) => (highlighted ? theme.colors.secondary : theme.colors.text)};
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.5rem 0 0 0;
  display: flex;
  &:focus {
    text-decoration: none;
    outline: 4px solid ${({ highlighted, theme }) => (highlighted ? theme.colors.secondary : theme.colors.boxoutBackground)};
    outline-offset: -4px;
  }
  &:hover {
    background-color: ${({ highlighted, theme }) => (highlighted ? theme.colors.secondary : theme.colors.boxoutBackground)};
  }
  h3 {
    flex-grow: 1;
    color: ${({ highlighted, theme }) => (highlighted ? "black" : theme.background)};
  }
`;

export interface IFaqItemContentStyle {
  isOpen: boolean;
}

export const FaqItemContentStyle = styled.div<IFaqItemContentStyle>`
  width: 100%;
  transition: all 0.4s ${({ isOpen }) => (isOpen ? "ease-in" : "ease-out")};
  max-height: ${({ isOpen }) => (isOpen ? "auto" : "0")};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: 0 0 0.5rem 0;
  & > div {
    margin: 1rem;
  }
  img {
    max-width: 100%;
  }
`;

export const FaqButtonGroupStyle = styled(ButtonGroup)`
  gap: 0.7rem;
  justify-content: left;
  flex: 1;
  button {
    text-transform: capitalize;
    font-size: large;
    padding: 0.75rem 1.5rem;
    color: ${({ theme }) => theme.colors.main};
    border-color: ${({ theme }) => theme.colors.main};
    margin-bottom: 0;

    &.active {
      background: ${({ theme }) => theme.colors.cta};
      color: ${({ theme }) => theme.colors.ctaText};
      border: none;
      &:hover {
        background: ${({ theme }) => theme.colors.ctaHover};
        box-shadow: none;
      }
    }
  }
`;
