import { FilterObjectClass } from "@react_db_client/constants.client-types";
import { IActivityCardData } from "./IActivityCardProps";
import { EActivityStatus } from "../../lib/Activities/IActivity";
import { EEquipmentNeeded } from "../../lib/Activities/EEquipmentNeeded";

export const filterActivityCards = (filters: FilterObjectClass[]) => (card: IActivityCardData) =>
  filters.every((filter) => {
    if (filter.uid === "showLocked") return filter.value || !card.activityUserProgress?.isLocked;
    if (filter.uid === "showInProgress")
      return filter.value || card.activityUserProgress?.activityStatus !== EActivityStatus.IN_PROGRESS;
    if (filter.uid === "showReady")
      return filter.value || card.activityUserProgress?.activityStatus !== EActivityStatus.NOT_STARTED;
    if (filter.uid === "showCompleted")
      return filter.value || card.activityUserProgress?.activityStatus !== EActivityStatus.COMPLETED;
    if (filter.uid === "equipmentNeeded")
      return !filter.value || card.equipmentNeeded?.filter((e) => e !== EEquipmentNeeded.NONE).length > 0;
    throw Error(`Filter not setup for: ${filter.uid}`);
  });
