import React from "react";
import { Link } from "react-router-dom";
import { CardStyle } from "../../../GenericComponents/CardBoard/styles";
import { FlexBox } from "../../../styles/singlePageStyles";
import { IResourceCardInnerProps } from "./AppResourcesDownloadCard";
import { TextFormatter } from "../../TextFormatter";

export const ResourcePopupInfoCard: React.FC<IResourceCardInnerProps> = ({
  label,
  description,
  resourceUrl,
  thumbnailUrl,
}) => {
  return (
    <CardStyle>
      {resourceUrl && thumbnailUrl && (
        <FlexBox center style={{ width: "100%" }}>
          <img src={thumbnailUrl} width="100%" alt={label} />
        </FlexBox>
      )}
      <h1>{label}</h1>
      <div style={{ marginBottom: "1rem" }}>
        <TextFormatter text={description} keywordMap={[]} />
      </div>
      <Link to={resourceUrl}>View "{label}"</Link>
    </CardStyle>
  );
};
