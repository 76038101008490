import { CardComponentTypeMap } from "../../GenericComponents/CardBoard";
import { EActivityTypes } from "../../lib/Activities/IActivity";
import { StepByStepCard, StepByStepCardExpanded } from "./ActivityTypes/StepByStep";
import { DataDetectiveCard, DataDetectiveCardExpanded } from "./ActivityTypes/DataDetective";
import { DemoActivityCard } from "./ActivityTypes/DemoActivity";
import { DemoActivityCardExpanded } from "./ActivityTypes/DemoActivity/DemoActivity";
import { QuizCard, QuizCardExpanded } from "./ActivityTypes/Quiz";
import { IActivityCardProps } from "./IActivityCardProps";

export const cardComponentTypeMap: CardComponentTypeMap<EActivityTypes, IActivityCardProps> = {
  [EActivityTypes.TEST]: {
    id: EActivityTypes.TEST,
    Component: DemoActivityCard,
  },
  [EActivityTypes.STEP_BY_STEP]: {
    id: EActivityTypes.STEP_BY_STEP,
    Component: StepByStepCard,
  },
  [EActivityTypes.DATA_DETECTIVES]: {
    id: EActivityTypes.DATA_DETECTIVES,
    Component: DataDetectiveCard,
  },
  [EActivityTypes.QUIZ]: {
    id: EActivityTypes.QUIZ,
    Component: QuizCard,
  },
};

export const cardComponentOpenTypeMap: CardComponentTypeMap<EActivityTypes, IActivityCardProps> = {
  [EActivityTypes.TEST]: {
    id: EActivityTypes.TEST,
    Component: DemoActivityCardExpanded,
  },
  [EActivityTypes.STEP_BY_STEP]: {
    id: EActivityTypes.STEP_BY_STEP,
    Component: StepByStepCardExpanded,
  },
  [EActivityTypes.DATA_DETECTIVES]: {
    id: EActivityTypes.DATA_DETECTIVES,
    Component: DataDetectiveCardExpanded,
  },
  [EActivityTypes.QUIZ]: {
    id: EActivityTypes.QUIZ,
    Component: QuizCardExpanded,
  },
};
