import { IKeyword } from "../../lib/Keyword/IKeyword";

export const splitLines = (text: string): string[] => {
  return text.split("\n");
};

const createMarkdownLink = (target: string) => `[$1](_keyword_${target})`;

export const insertLinks = (text: string, keywordMap: IKeyword[]): string => {
  const out = keywordMap.reduce((t, k) => {
    return k.rtext.reduce((tt, rtext) => {
      try {
        const out = tt.replace(
          new RegExp(`!(${rtext})!(?![^\\[\\]\\(\\)]*[\\]\\)])`, "gi"),
          createMarkdownLink(String(k.uid))
        );
        return out;
      } catch (error) {
        return "INVALID";
      }
    }, t);
  }, text);
  return out;
};

export const removeImageNotation = (markdownString: string): string => {
  // remove markdown image notations
  const regex = /!\[(.*?)\]\((.*?)\)/g;
  const result = markdownString.replace(regex, "");
  return result;
};

export const removeKeywordNotation = (markdownString: string): string => {
  const regex = new RegExp(`!(.+?)!(?![^\\[\\]\\(\\)]*[\\]\\)])`, "gi");
  const result = markdownString.replace(regex, "$1");
  return result;
};

export const removeFormatPrompts = (text: string): string => {
  let result = removeKeywordNotation(text);
  result = removeImageNotation(result);
  return result;
};
