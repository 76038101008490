import { postDataFetch, IApiResponse } from "./apiHelpers";

import { SERVER_URL } from "../config";
import { IContactFormSubmission } from "../lib/formSubmissions/IContactFormSubmission";

/**
 * Submit the contact form
 *
 * @returns
 */
export const apiSubmitContactForm = async (data: IContactFormSubmission) => {
  const url = new URL(`${SERVER_URL}/Contact/contactUs`);
  return postDataFetch<IContactFormSubmission, IApiResponse>(url.toString(), data);
};
