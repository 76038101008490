import { ILabeled } from "../common";

export enum ELedColor {
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
  PURPLE = "PURPLE",
}

export interface IMonitorLed {
  upperLimit: number | "+";
  lowerLimit: number;
  color: ELedColor;
  noOfLeds: string;
  description: string;
  whatItMeans: string;
  whatYouCanDo: string;
}

export interface ILedColor extends ILabeled<ELedColor> {
  color: string;
}

export const LedColors: Record<ELedColor, ILedColor> = {
  [ELedColor.GREEN]: {
    uid: ELedColor.GREEN,
    label: "Green",
    color: "#00b300",
  },
  [ELedColor.ORANGE]: {
    uid: ELedColor.ORANGE,
    label: "Orange",
    color: "#e65c00",
  },
  [ELedColor.RED]: {
    uid: ELedColor.RED,
    label: "Red",
    color: "#e62e00",
  },
  [ELedColor.PURPLE]: {
    uid: ELedColor.PURPLE,
    label: "Red + Purple",
    color: "#5200cc",
  },
};

export const monitorLeds: IMonitorLed[] = [
  {
    upperLimit: 799,
    lowerLimit: 0,
    color: ELedColor.GREEN,
    noOfLeds: "1 - 2 lights",
    description: "Indicative of good ventilation",
    whatItMeans:
      "Ventilation is acting to help maintain a good classroom environment, including reducing the risk that airborne diseases are spread.",
    whatYouCanDo:
      "If CO₂ levels are not rising then you can consider having your windows open slightly less wide or temporarily closing them. Do so gradually and in stages.",
  },
  {
    upperLimit: 1499,
    lowerLimit: 800,
    color: ELedColor.ORANGE,
    noOfLeds: "3 - 6 lights",
    description: "Potential for increasingly stuffy/stale air",
    whatItMeans:
      "Consider the potential to improve ventilation in your classroom for better health and learning outcomes - this might include having more windows & doors open, opening them more widely, or getting any mechanical ventilation serviced.",
    whatYouCanDo:
      "Consider opening windows and/or doors - higher-level openings first and then, if necessary, also lower-level openings. Continue to monitor the CO₂ levels.",
  },
  {
    upperLimit: 3000,
    lowerLimit: 1500,
    color: ELedColor.RED,
    noOfLeds: "7 - 8 lights",
    description: "Indicative of inadequate ventilation",
    whatItMeans:
      "There are quite high levels of shared/rebreathed air in your classroom which, if maintained, might lead to poorer learning and health outcomes. Consider the potential to improve ventilation (for example, by having more air flow by opening any windows and doors, or getting any mechanical ventilation serviced).",
    whatYouCanDo:
      "Keep checking that ventilation provision (e.g. windows and doors) is fully operational/open and continue to monitor the CO₂ levels. If consistent, notify school leadership.",
  },
  {
    upperLimit: "+",
    lowerLimit: 3000,
    color: ELedColor.PURPLE,
    noOfLeds: "9 lights",
    description: "Indicative of inadequate ventilation",
    whatItMeans:
      "There are quite high levels of shared/rebreathed air in your classroom which, if maintained, might lead to poorer learning and health outcomes. Consider the potential to improve ventilation (for example, by having more air flow by opening any windows and doors, or getting any mechanical ventilation serviced).",
    whatYouCanDo:
      "Keep checking that ventilation provision (e.g. windows and doors) is fully operational/open and continue to monitor the CO₂ levels. If consistent, notify school leadership.",
  },
];
