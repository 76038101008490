import React from "react";
import styled from "styled-components";
import { ButtonStyle, CTAButtonStyle } from "../../../../styles/button";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { years } from "../../../DateRangePicker/utils";

export interface IDataDownloadOptions {
  reloadData: () => void;
  loading: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
}

export const DataDownloadOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const DataDownloadOptions: React.FC<IDataDownloadOptions> = ({
  reloadData,
  loading,
  setDateRange,
  dateRange,
}) => {
  const [showOptions, setShowOptions] = React.useState(true);
  return (
    <DataDownloadOptionsWrap>
      <CardInfoSectionWrapper open={showOptions} maxHeight="55rem">
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <FlexList left horiz>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection>
              <DateRangePicker
                onChange={(newDateRange) => setDateRange(newDateRange)}
                value={dateRange}
                useAggregation
                adaptiveAggregation
                allowedMaxDateRange={years(1)}
                allowedMinAggregation={EAggregationInterval.MINUTE}
                allowedMaxAggregation={EAggregationInterval.DAY}
              />{" "}
              <DateRangeInfo dateRange={dateRange} />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
      <ButtonStyle style={{ width: "100%", marginTop: "1rem" }} onClick={() => setShowOptions((prev) => !prev)}>
        {showOptions ? "Hide Options" : "Show Options"}
      </ButtonStyle>
    </DataDownloadOptionsWrap>
  );
};
