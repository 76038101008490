import { DefaultTheme } from "styled-components";
import { defaultTheme } from "../../styles/themes";

export enum EThemes {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
}

export interface IThemeData {
  id: EThemes;
  uid: EThemes;
  label: string;
  theme: DefaultTheme;
}

export const ThemeData: Record<EThemes, IThemeData> = {
  [EThemes.A]: {
    id: EThemes.A,
    uid: EThemes.A,
    label: "A",
    theme: defaultTheme,
  },
  [EThemes.B]: {
    id: EThemes.B,
    uid: EThemes.B,
    label: "B",
    theme: defaultTheme,
  },
  [EThemes.C]: {
    id: EThemes.C,
    uid: EThemes.C,
    label: "C",
    theme: defaultTheme,
  },
  [EThemes.D]: {
    id: EThemes.D,
    uid: EThemes.D,
    label: "D",
    theme: defaultTheme,
  },
};
