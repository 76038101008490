import { ActivityStepSection } from "../../../styles/app";
import { ButtonStyle } from "../../../styles/button";
import { CTALinkButtonStyle } from "../../../styles/links";
import { FlexBox } from "../../../styles/singlePageStyles";

export interface IActivityCompletionMessageProps {
  badgeImageUrl: string;
  restartActivity: () => void;
}

export const ActivityCompletionMessage = ({ restartActivity }: IActivityCompletionMessageProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <ActivityStepSection active style={{ width: "100%", padding: "1rem" }}>
        <p>Congratulations you have completed this activity!</p>
        <p>Choose an option below:</p>
        <FlexBox horiz style={{ width: "100%", marginTop: "0.5rem" }}>
          <CTALinkButtonStyle to="/app/activities">Close Activity</CTALinkButtonStyle>
          <div style={{ flexGrow: 1 }} />
          <ButtonStyle onClick={() => restartActivity()}>Restart Activity</ButtonStyle>
        </FlexBox>
      </ActivityStepSection>
    </div>
  );
};
