export const extractOptionsFromMultiCheckboxOutput = (output: boolean[]): number[] => {
  return output.reduce((acc, v, i) => (v ? [...acc, i] : acc), []);
};

export const extractBitwiseIndexesFromMultiCheckboxOutput = (output: boolean[]): number[] => {
  return output.reduce((acc, v, i) => (v ? [...acc, 2 ** i] : acc), []);
};

export const getSelectValue = (v, options) => {
  if (v && typeof v === "object") return v.uid || undefined;
  if (v && typeof v !== "object") return options.find((o) => o.uid === v || o.uid === parseInt(v) || o.label === v)?.uid;
  return undefined;
};

export const getCurrentDateTime = () => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return {
    date: `${date.toJSON().slice(0, 10)}`,
    time: `${date.toJSON().slice(11, 16)}`,
  };
};
