import styled from "styled-components";
import { IActivityTimeEstimates } from "../../lib/Activities/IActivity";
import { KeyItemLabel, KeySectionHeadingStyle, KeySectionStyle } from "../../styles/app";
import { FlexBox } from "../../styles/singlePageStyles";
import { themeColors } from "../../styles/themes";

export interface ITimeEstimatesProps {
  timeEstimates: IActivityTimeEstimates;
  center?: boolean;
}

export interface ITimeBarProps {
  count: number;
  center?: boolean;
  color: string;
}

export const TimeBar = styled.div<ITimeBarProps>`
  background: ${({ theme }) => theme.colors.main};
  width: ${({ count }) => count * 0.2}rem;
  height: 2rem;
  color: white;
  padding: 0.2rem;
  transition: all 0.3s;
  margin-right: ${({ theme }) => theme.shape.defaultPadding};
`;

export const TotalTimeBar = styled(TimeBar)`
  opacity: 1;
`;

export const TimeBarContainer = styled.div<Partial<ITimeBarProps>>`
  display: flex;
  margin-bottom: 0.2rem;
  flex-wrap: no-wrap;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-wrap: wrap;
    margin-bottom: 0.7rem;
  }
`;

export const TimeBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 8rem;
`;

export const TimeBarLabel = styled.b`
  width: 6.5rem;
  text-align: left;
  margin-right: 0.2rem;
  line-height: 2rem;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 100%;
    line-height: 1.2rem;
  }
`;

export const TimeBarTime = styled.div`
  width: 4rem;
  text-align: left;
  margin-left: 0.2rem;
  line-height: 2rem;
`;

/* Used on Activity Meta panel */
export const TimeEstimates = ({ timeEstimates, center }: ITimeEstimatesProps) => {
  const totalTime = (timeEstimates.investigation || 0) + (timeEstimates.video || 0) + (timeEstimates.discussion || 0);
  return (
    <TimeBarWrap>
      {(totalTime && (
        <TimeBarContainer center={center}>
          <TimeBarLabel style={{ width: "2rem" }}>
            <img
              src="/images/app/activities/icons/clockIcon.png"
              style={{ width: "2rem", height: "2rem" }}
              alt="Time estimates"
            />
          </TimeBarLabel>
          <TimeBarTime>
            <b>{totalTime} mins</b>
          </TimeBarTime>
        </TimeBarContainer>
      )) ||
        ""}
      {(timeEstimates.investigation && (
        <TimeBarContainer center={center}>
          <TimeBarLabel>Investigation</TimeBarLabel>
          <TimeBar color={themeColors.PANTONE534C} count={timeEstimates.investigation} />
          <TimeBarTime>{timeEstimates.investigation} mins</TimeBarTime>
        </TimeBarContainer>
      )) ||
        ""}
      {(timeEstimates.video && (
        <TimeBarContainer center={center}>
          <TimeBarLabel>Video</TimeBarLabel>
          <TimeBar color={themeColors.PANTONE375C} count={timeEstimates.video} />
          <TimeBarTime>{timeEstimates.video} mins</TimeBarTime>
        </TimeBarContainer>
      )) ||
        ""}
      {(timeEstimates.discussion && (
        <TimeBarContainer center={center}>
          <TimeBarLabel>Discussion</TimeBarLabel>
          <TimeBar color={themeColors.PANTONE2592C} count={timeEstimates.discussion} />
          <TimeBarTime>{timeEstimates.discussion} mins</TimeBarTime>
        </TimeBarContainer>
      )) ||
        ""}
    </TimeBarWrap>
  );
};

export const TotalTimeStyle = styled.p`
  font-size: 18px;
  line-height: 2rem;
  font-weight: 800;
  margin-left: 0.2rem;
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LongBarWrap = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
`;

const TimeBarScale = 0.35;

export const LongBar = styled.div<{ value: number; color: string }>`
  min-width: ${({ value }) => value * TimeBarScale}rem;
  width: ${({ value }) => value * TimeBarScale}rem;
  height: 2rem;
  background: ${({ color }) => color};
`;

/* Used on card cover */
export const TimeEstimatesLongBar = ({ timeEstimates }: ITimeEstimatesProps) => {
  const totalTime = (timeEstimates.investigation || 0) + (timeEstimates.video || 0) + (timeEstimates.discussion || 0);
  return (
    <LongBarWrap>
      <ValuesContainer>
        <img
          src="/images/app/activities/icons/clockIcon.png"
          style={{ width: "2rem", height: "2rem" }}
          alt="Time estimates"
        />
        {/* {(timeEstimates.investigation && (
          <LongBar value={timeEstimates.investigation} color={themeColors.PANTONE534C} />
        )) ||
          ""}
        {(timeEstimates.video && <LongBar value={timeEstimates.video} color={themeColors.PANTONE375C} />) || ""}
        {(timeEstimates.discussion && <LongBar value={timeEstimates.discussion} color={themeColors.PANTONE2592C} />) ||
          ""} */}
        <TotalTimeStyle>{totalTime}mins</TotalTimeStyle>
      </ValuesContainer>
    </LongBarWrap>
  );
};

export const TimeKeyLabel = styled.p<{ color: string }>`
  line-height: 2rem;
  margin-left: 0.5rem;
`;

export const TimeEstimateKeyItems = () => (
  <KeySectionStyle>
    <KeySectionHeadingStyle>
      {/* <img
        src="/images/app/activities/icons/clockIcon.png"
        style={{ width: "2rem", height: "2rem" }}
        alt="Time estimates"
      /> */}
      Time Estimate
    </KeySectionHeadingStyle>

    <FlexBox horiz center>
      <KeyItemLabel color={themeColors.PANTONE534C}>Investigation</KeyItemLabel>
      <LongBar value={2 / TimeBarScale} color={themeColors.PANTONE534C} />
    </FlexBox>
    <FlexBox horiz center>
      <KeyItemLabel color={themeColors.PANTONE375C}>Video</KeyItemLabel>
      <LongBar value={2 / TimeBarScale} color={themeColors.PANTONE375C} />
    </FlexBox>
    <FlexBox horiz center>
      <KeyItemLabel color={themeColors.PANTONE2592C}>Discussion</KeyItemLabel>
      <LongBar value={2 / TimeBarScale} color={themeColors.PANTONE2592C} />
    </FlexBox>
  </KeySectionStyle>
);
