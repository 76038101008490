import { ILabelled } from "@react_db_client/constants.client-types";

export enum EAggregationInterval {
  NONE = 0,
  MINUTE = 1,
  FIVE_MINUTE = 2,
  TEN_MINUTE = 4,
  HOUR = 8,
  DAY = 16,
}

export const AggregationIntervals: Record<EAggregationInterval, ILabelled<EAggregationInterval> & { ratio: number }> = {
  [EAggregationInterval.NONE]: {
    uid: EAggregationInterval.NONE,
    label: "No aggregation",
    ratio: 1,
  },
  [EAggregationInterval.MINUTE]: {
    uid: EAggregationInterval.MINUTE,
    label: "1 Minute",
    ratio: 1,
  },
  [EAggregationInterval.FIVE_MINUTE]: {
    uid: EAggregationInterval.FIVE_MINUTE,
    label: "5 minutes",
    ratio: 5,
  },
  [EAggregationInterval.TEN_MINUTE]: {
    uid: EAggregationInterval.TEN_MINUTE,
    label: "10 minutes",
    ratio: 10,
  },
  [EAggregationInterval.HOUR]: {
    uid: EAggregationInterval.HOUR,
    label: "1 hour",
    ratio: 60,
  },
  [EAggregationInterval.DAY]: {
    uid: EAggregationInterval.DAY,
    label: "1 day",
    ratio: 60 * 24,
  },
};
