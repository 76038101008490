import { ILabeled } from "../common";

export enum EHowDidYouHearAboutUs {
  VIA_STUDENT = 1,
  FOCUS_GROUP = 2,
  WORD_OF_MOUTH = 4,
  MEDIA = 8,
  SOCIAL_MEDIA = 16,
  OTHER_ORG = 32,
  OTHER = 64,
}

export const HowDidYouHearAboutUs: Record<EHowDidYouHearAboutUs, ILabeled<EHowDidYouHearAboutUs>> = {
  [EHowDidYouHearAboutUs.VIA_STUDENT]: {
    uid: EHowDidYouHearAboutUs.VIA_STUDENT,
    label: "Via a student at my school",
  },
  [EHowDidYouHearAboutUs.FOCUS_GROUP]: {
    uid: EHowDidYouHearAboutUs.FOCUS_GROUP,
    label: "We took part in SAMHE co-design focus groups",
  },
  [EHowDidYouHearAboutUs.WORD_OF_MOUTH]: {
    uid: EHowDidYouHearAboutUs.WORD_OF_MOUTH,
    label: "Word of mouth",
  },
  [EHowDidYouHearAboutUs.MEDIA]: {
    uid: EHowDidYouHearAboutUs.MEDIA,
    label: "Media (TV, Radio, Newspaper)",
  },
  [EHowDidYouHearAboutUs.SOCIAL_MEDIA]: {
    uid: EHowDidYouHearAboutUs.SOCIAL_MEDIA,
    label: "Social media (Facebook, X, BlueSky, LinkedIn, or other)",
  },
  [EHowDidYouHearAboutUs.OTHER_ORG]: {
    uid: EHowDidYouHearAboutUs.OTHER_ORG,
    label: "Newsletter/website of another organisation",
  },
  [EHowDidYouHearAboutUs.OTHER]: {
    uid: EHowDidYouHearAboutUs.OTHER,
    label: "Other",
  },
};
