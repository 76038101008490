import { rest } from "msw";
import { SERVER_URL } from "../config";
import { ESchoolFundingTypes, IMonitorRequestSubmission } from "../lib/formSubmissions/IMonitorRequestSubmission";
import { ISchoolEstablishment } from "../lib/formSubmissions/ISchoolEstablishment";
import { IApiMonitorRequestReturn, IApiMonitorRequestsReturn } from "./apiMonitorRequest";
import * as database from "../dummyApi/database";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";
import { parseJwt } from "../utils/testing/test-utils";

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    // searchMonitorRequests
    useMockApi: true,
    endpoint: rest.get<IApiMonitorRequestsReturn>(`${SERVER_URL}/MonitorRequests/Search`, (req, res, ctx) => {
      const validating = req.url.searchParams.get("validating") === "true";
      const validated = req.url.searchParams.get("validated") === "true";
      const searchPhrase = req.url.searchParams.get("searchPhrase");
      const lastEditedByUser = req.url.searchParams.get("lastEditedByUser") === "true";

      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        console.info("Failed to parse user access token", accessToken);
        return res(
          ctx.status(401),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const { userName } = tokenData;

      const filteredMonitorRequests = database
        .getDocs<IMonitorRequest>("MonitorRequests")
        .filter((m) => {
          const searchableText = [m.id, m.schoolName, m.schoolAddressPostCode].join(" ");
          if (validated)
            return m.dateValidated && (!searchPhrase || searchableText.includes(searchPhrase)) && !m.dateMarkedInvalid;
          if (validating)
            return (
              m.dateStartedValidating &&
              !m.dateValidated &&
              (!searchPhrase || searchableText.includes(searchPhrase)) &&
              !m.dateMarkedInvalid
            );
          return (
            !m.dateValidated &&
            !m.dateStartedValidating &&
            (!searchPhrase || searchableText.includes(searchPhrase)) &&
            !m.dateMarkedInvalid
          );
        })
        .filter((m) => !lastEditedByUser || m.lastEditedUser === userName);
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          data: filteredMonitorRequests,
        })
      );
    }),
  },
  {
    // getMonitorRequest
    useMockApi: true,
    endpoint: rest.get<IApiMonitorRequestReturn>(`${SERVER_URL}/MonitorRequests/:id`, (req, res, ctx) => {
      const { id } = req.params;
      const monitorRequest = database.getDoc<IMonitorRequest>(id, "MonitorRequests");
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          data: monitorRequest,
        })
      );
    }),
  },
  {
    // apiSubmitMonitorRequest
    useMockApi: true,
    endpoint: rest.post<IMonitorRequestSubmission>(`${SERVER_URL}/MonitorRequests`, (req, res, ctx) => {
      const data = req.body;
      // TODO: Add data to mock database
      if (!data.firstName)
        return res(
          // Respond with a 200 status code
          ctx.status(401)
        );

      const id = database.getId("MonitorRequests");
      const schoolId = `${id}`;
      const schoolFundingType = data.schoolFundingType as ESchoolFundingTypes;
      database.addDoc<IMonitorRequest>(id, "MonitorRequests", {
        ...data,
        schoolFundingType,
        id,
        schoolId,
        dateCreated: new Date(),
      });

      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          data,
        })
      );
    }),
  },
  {
    // apiGetSchoolFromFullList
    useMockApi: true,
    endpoint: rest.get<ISchoolEstablishment[]>(`${SERVER_URL}/SchoolEstablishment`, (req, res, ctx) => {
      const postcode = req.url.searchParams.get("postcode");
      const schools = database
        .getDocs<ISchoolEstablishment>("SchoolEstablishments")
        .filter((s) => s.postcode?.includes(postcode));
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          data: schools,
        })
      );
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
