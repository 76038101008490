import { Section, SectionHeading, P, PageTitle, FloatImage, Ul } from "../../styles/page";

export const GuidanceOnWindowTypesContent = (
  <>
    <PageTitle className="hideFromGlossary">Window types</PageTitle>
    <Section>
      <P>
        There are various types of window, which open differently. The five main types of window that can be found in
        the UK are:
      </P>
      <Ul>
        <li>Tilt and turn window</li>
        <li>Casement window</li>
        <li>Skylight</li>
        <li>Sliding window</li>
        <li>Sash window</li>
      </Ul>
      <P>See the descriptions below for more information.</P>
    </Section>
    <Section>
      <SectionHeading>Tilt and turn window</SectionHeading>
      <FloatImage
        src="/images/glossary/window-type-tilt-and-turn.jpeg"
        alt="Tilt and turn window"
        desktopStyleOverride={"max-width: 20rem"}
      />
      <P>
        A tilt and turn window opens in two ways. If you turn the handle 90°, you can swing the window like a door, as
        can be seen in the right side of the tilt and turn window schematic shown. But if you turn the handle 180°, you
        can tilt the window, creating a small opening at the top, as shown on the left side of the tilt and turn window
        schematic shown. Tilt and turn windows always open inwards.
      </P>
      <P>
        <b>Key to identify tilt and turn windows:</b> these windows can be opened in two different ways - either tilting
        or turning, usually both inwards.
      </P>
    </Section>
    <Section>
      <SectionHeading>Casement window</SectionHeading>
      <FloatImage
        src="/images/glossary/window-type-casement.jpeg"
        alt="Casement window"
        desktopStyleOverride={"max-width: 20rem"}
      />
      <P>
        Casement windows are windows attached to their frame by hinges. They can have a single or multiple panels. The
        hinges can be in different locations. If the hinges are on the side, the window opens like a door. If the hinges
        are on the top or bottom, the window swings open at the bottom or top. The example casement window shown in the
        image above has two framed panels, the top panel has its hinges at the top and the bottom panel has its hinges
        at the side.
      </P>
      <P>
        <b>Key to identify casement windows:</b> every window panel can be opened in one direction, hinges allow the
        window to swing from the side, top or bottom.
      </P>
    </Section>
    <Section>
      <SectionHeading>Skylight</SectionHeading>
      <FloatImage
        src="/images/glossary/window-type-skylight.jpeg"
        alt="Skylight window"
        desktopStyleOverride={"max-width: 20rem"}
      />
      <P>
        A skylight is a type of window set on the roof of a building. They bring light into the room. The opening
        mechanism for a skylight can vary. Some skylights cannot be opened.
      </P>
      <P>
        <b>Key to identify a skylight:</b> these windows are set within a roof.
      </P>
    </Section>
    <Section>
      <SectionHeading>Sliding window</SectionHeading>
      <FloatImage
        src="/images/glossary/window-type-sliding.jpeg"
        alt="Sliding window"
        desktopStyleOverride={"max-width: 20rem"}
      />
      <P>Sliding windows have two framed panels that slide horizontally, opening to the side like patio doors.</P>
      <P>
        <b>Key to identify sliding windows:</b> these windows slide horizontally.
      </P>
    </Section>
    <Section>
      <SectionHeading>Sash window</SectionHeading>
      <FloatImage
        src="/images/glossary/window-type-sash.jpeg"
        alt="Sash window"
        desktopStyleOverride={"max-width: 20rem"}
      />
      <P>
        A sash window is a type of window that consists of two framed panels that slide up and down. Some have one panel
        that slides up and down and another one that is fixed. Each panel can have one or more glass sheets. For
        example, in the sash window shown in the image above, both panels can be moved and each one has two glass
        sheets.
      </P>
      <P>
        <b>Key to identify sash windows:</b> at least one window panel slides up and down.
      </P>
    </Section>
  </>
);

export const GuidanceOnWindowTypes = () => {
  return GuidanceOnWindowTypesContent;
};
