import { ITeamMember } from "./ITeam";

export const samheTeamMembers: ITeamMember[] = [
  {
    uid: "henry_burridge",
    name: "Henry Burridge",
    title: "Dr",
    photo: true,
    shortDesc: "Project lead, Web App and data analysis development",
    institution: "Imperial College London",
    jobTitle: "Senior Lecturer in Fluid Mechanics",
    website: "https://www.imperial.ac.uk/people/h.burridge",
    twitterHandle: undefined,
    subjects: "Maths, Further Maths, Additonal Further Maths & Design Technology",
    studyUni: "Mechanical Engineering",
    advice:
      "Learn to love your inner geek - science and maths helps to shape the world around us; if you happen to be good at these subjects be proud and enjoy it! Lastly, never be afraid to spend time doing something that you are not sure where or what it might lead to - these can be the most enjoyable avenues to explore...",
  },
  {
    uid: "paul_linden",
    name: "Paul Linden",
    title: "Professor",
    photo: true,
    shortDesc: "Principal Investigator",
    institution: "University of Cambridge",
    jobTitle: "Professor of Mathematics",
    website: "https://www.cfse.cam.ac.uk/directory/linden",
    twitterHandle: undefined,
    subjects: "Maths, Physics, Chemistry",
    studyUni: "Mathematics",
    advice: "Follow your dream",
  },
  {
    uid: "sarah_west",
    name: "Sarah West",
    title: "Dr",
    photo: true,
    shortDesc: "Leading schools engagement",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Director and Senior Research Fellow",
    website: " https://www.sei.org/people/sarah-west/",
    twitterHandle: "@SarahWest_SEI",
    subjects: "Geography, Biology, Maths, Chemistry",
    studyUni:
      "Geography (Physical and Human) (BA), Biodiversity and Conservation (MSc) and then Environmental Education (PhD)",
    advice:
      "A 'portfolio career' is a thing - do what you love doing, take opportunities that excite you as they come up and don't worry too much about where you are heading. If you do take this advice though, practice saying No a lot - people will see your enthusiasm and ask you to do lots of things, say No to things you aren't that interested in or don't have time to do.",
  },
  {
    uid: "lucy_way",
    name: "Lucy Way",
    title: undefined,
    photo: true,
    shortDesc: "Project Coordinator",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Project Coordinator",
    website: " https://www.sei.org/people/lucy-way/",
    twitterHandle: undefined,
    subjects: "English, French, History, Economics",
    studyUni: "BA Open Degree (Law, Environmental Policy, Sustainable Development)",
    advice: undefined,
  },
  {
    uid: "victoria_beale",
    name: "Victoria Beale",
    title: undefined,
    photo: true,
    shortDesc: "Communications lead",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Communications Specialist",
    website: " https://www.sei.org/people/victoria-beale/",
    twitterHandle: "@victoriabeale2",
    subjects: "Maths, Further Maths, Biology & Chemistry",
    studyUni: "Natural Sciences and then Wildlife Management",
    advice:
      "You don't have to choose a career path and stick to it! I have worked as an ornithologist, environmental expert for churches, research network facilitator, ethics administrator... I picked up lots of transferable skills and am currently enjoying working in communications. What next? Who knows!",
  },
  {
    uid: "rhys_archer",
    name: "Rhys Archer",
    title: "Dr",
    photo: true,
    shortDesc: "Citizen Science researcher/ Resources for Schools",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Research Fellow",
    website: "https://www.york.ac.uk/crd/staff/rhys-archer/",
    twitterHandle: "@drrhysarcher",
    subjects: "Maths, Physics, Textiles, English Literature",
    studyUni: "Materials Science and Engineering",
    advice:
      "Don't ignore your side hustle, there are always ways to combine your day job and side hustle to do what suits your interests and skills",
  },
  {
    uid: "sam_bland",
    name: "Sam Bland",
    title: undefined,
    photo: true,
    shortDesc: "Web App and data analysis development",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Software Developer",
    website: "https://www.sei.org/people/sam-bland/",
    twitterHandle: "@Sbland_co_uk",
    subjects: "Maths, Physics, Fine Art",
    studyUni: "Architecture & Urban Modelling",
    advice:
      "Get stuck into open source projects as this is the easiest and most rewarding way to gain useful software experience. Also time to explore all your interests before focusing solely on coding.",
  },
  {
    uid: "mike_simmons",
    name: "Mike Simmons",
    title: undefined,
    photo: false,
    shortDesc: undefined,
    institution: undefined,
    jobTitle: "Software Developer",
    website: undefined,
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },

  {
    uid: "joshua_finneran",
    name: "Joshua Finneran",
    title: "Dr",
    photo: true,
    shortDesc: "Web App and data analysis development",
    institution: "University of Cambridge",
    jobTitle: "Research Associate",
    website: "https://www.damtp.cam.ac.uk/person/jf721",
    twitterHandle: undefined,
    subjects: "Maths, Further Maths, Physics, Chemistry",
    studyUni: "Mechanical Engineering",
    advice: undefined,
  },
  {
    uid: "samuel_wood",
    name: "Samuel Wood",
    title: "Dr",
    photo: true,
    shortDesc: "Web App and data analysis development",
    institution: "Imperial College London",
    jobTitle: "Research Associate",
    website: "https://www.imperial.ac.uk/fluid-mechanics/people/",
    twitterHandle: undefined,
    subjects: "Chemistry, biology, maths and english",
    studyUni: "Chemical engineering, and Mechanical engineering for my Phd",
    advice: undefined,
  },
  {
    uid: "carolanne_vouriot",
    name: "Carolanne Vouriot",
    title: "Dr",
    photo: true,
    shortDesc: "Web App and data analysis tool development",
    institution: "University of Sheffield",
    jobTitle: "Lecturer in Building Physics",
    website: "https://www.sheffield.ac.uk/civil/people/academic/carolanne-vouriot",
    twitterHandle: undefined,
    subjects: "Everything from Maths and Physics to Philosophy and French literature (I went to school in France!)",
    studyUni: "Aeronautical Engineering",
    advice:
      "There's many 'perfect' jobs out there for you. If something doesn't work out, it doesn't mean that you'll never be able to find a career that you enjoy. ",
  },
  {
    uid: "claudia_fernanda_castro_faccetti",
    name: "Claudia Fernanda Castro Faccetti",
    title: "Dr",
    photo: true,
    shortDesc: "Data visualisation",
    institution: "Visiting researcher at the University of Leeds",
    jobTitle: "Research Associate",
    website: "https://www.iahr.org/individual-member/user?member_no=65448",
    twitterHandle: "@ccasf13",
    subjects: "Everything (I went to a Colombian school)",
    studyUni: "Civil Engineering (BEng), and then Water, Sanitation and Health Engineering (MSc)",
    advice:
      "To not be afraid of learning new things that interest you, even when they may not seem useful at the moment. Each one of us has a unique set of skills and every one of them will be useful in one way or another!",
  },
  {
    uid: "dale_weston",
    name: "Dale Weston",
    title: "Dr",
    photo: true,
    shortDesc: "Behavioural science",
    institution: "UKHSA",
    jobTitle: "Principal Behavioural Scientist",
    website: "https://epr.hpru.nihr.ac.uk/our-team/investigators/dr-dale-weston",
    twitterHandle: undefined,
    subjects: "Psychology, Sociology, English Literature, Business Studies",
    studyUni: "Psychology and Criminology",
    advice:
      "Take time to explore all of psychology - it's such a broad and varied topic and can lead to so many different career opportunities. You don't necessarily need to be wedded to your choices in psychology at university - do what you think looks interesting and exciting and job opportunities will come!",
  },
  {
    uid: "natalie_williams",
    name: "Natalie Williams",
    title: undefined,
    photo: false,
    shortDesc: "Behavioural science",
    institution: "UK Health Security Agency (UKHSA)",
    jobTitle: "Research Fellow",
    website: undefined,
    twitterHandle: undefined,
    subjects: "English, Maths, Chemistry, Psychology, Drama studies",
    studyUni: "Psychology BSc, Mental health and wellbeing MSc and a PhD in Hearing and quality of life",
    advice: undefined,
  },
  {
    uid: "holly_carter",
    name: "Holly Carter",
    title: "Dr",
    photo: false,
    shortDesc: "Behavioural science ",
    institution: "UK Health Security Agency (UKHSA)",
    jobTitle: "Research Fellow",
    website: "http://epr.hpru.nihr.ac.uk/our-team/investigators/holly-carter",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "douglas_wang",
    name: "Douglas Wang",
    title: undefined,
    photo: true,
    shortDesc: "Software developer",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Software developer",
    website: "https://www.sei.org/people/douglas-wang/",
    twitterHandle: undefined,
    subjects: "Information Technology",
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "roderic_jones",
    name: "Roderic Jones",
    title: "Professor",
    photo: true,
    shortDesc: "Sensor network development and data analysis",
    institution: "University of Cambridge",
    jobTitle: "Professor of Atmospheric Science",
    website: " https://www.ch.cam.ac.uk/person/rlj1001",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "prashant_kumar",
    name: "Prashant Kumar",
    title: "Professor",
    photo: true,
    shortDesc: "Insights from in-school deep-dive investigations, intervention case studies and data analysis",
    institution: "University of Surrey",
    jobTitle:
      "Associate Dean (International), Professor and Chair in Air Quality and Health; Founding Director, Global Centre for Clean Air Research (GCARE)",
    website: "https://www.surrey.ac.uk/people/prashant-kumar",
    twitterHandle: "@pk_shishodia",
    subjects: "Physics, Chemistry, Maths, Science",
    studyUni: "Environmental Engineering (Air Pollution Modelling)",
    advice: undefined,
  },
  {
    uid: "lia_chatzidiakou",
    name: "Lia Chatzidiakou",
    title: "Dr",
    photo: true,
    shortDesc: "Measurements and analysis",
    institution: "University of Cambridge",
    jobTitle: "Research Associate",
    website: " https://www.ch.cam.ac.uk/person/ec571",
    twitterHandle: undefined,
    subjects: "Maths, Physics, Chemistry, Philosophy, Fine Art",
    studyUni: "Architectural Engineering (BSc, MSc) and Environmental Engineering (MSc), Indoor Air Quality (PhD)",
    advice: undefined,
  },
  {
    uid: "nidhi_rawat",
    name: "Nidhi Rawat",
    title: undefined,
    photo: true,
    shortDesc: "Deep-dive school interventions",
    institution: "University of Surrey",
    jobTitle: undefined,
    website: "www.surrey.ac.uk/gcare",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "ana_paula_mendis_emygdio",
    name: "Ana Paula Mendis Emygdio",
    title: undefined,
    photo: true,
    shortDesc: "Lab and field experimental support",
    institution: "University of Surrey",
    jobTitle: undefined,
    website: "www.surrey.ac.uk/gcare",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "mark_winterbottom",
    name: "Mark Winterbottom",
    title: "Professor",
    photo: true,
    shortDesc: "Chair of the Engagement Panel",
    institution: "Faculty of Education, University of Cambridge",
    jobTitle: "Professor of Education",
    website: " https://www.educ.cam.ac.uk/people/staff/winterbottom/",
    twitterHandle: undefined,
    subjects: "Biology, Chemistry, Maths, Further Maths",
    studyUni: "Biological Sciences",
    advice:
      "Study the subject you enjoy at University, and then study for a PGCE in that subject before you become a teacher.",
  },
  {
    uid: "hannah_edwards",
    name: "Hannah Edwards",
    title: undefined,
    photo: true,
    shortDesc: "Project administration",
    institution: "University of Cambridge",
    jobTitle: "Air Pollution Project Administrator",
    website: "https://www.damtp.cam.ac.uk/person/he259",
    twitterHandle: undefined,
    subjects: "Politics",
    studyUni: "International Development",
    advice: undefined,
  },
  {
    uid: "kat_roberts",
    name: "Kat Roberts",
    title: "Dr",
    photo: true,
    shortDesc: "Project administration",
    institution: "Imperial College London/University of Cambridge",
    jobTitle: "Research Associate",
    website: "www.tapasnetwork.co.uk",
    twitterHandle: "@KatRoberts1980",
    subjects: "Geography, Maths, Chemistry",
    studyUni: "Environmental Science",
    advice:
      "I have always been interested in the environment but didn't specifically know what are I wanted to study after school. My A-levels didn't go as well as I'd hoped but I was very lucky to be accepted onto a course at the University of Leeds which opened up many doors to me. I would say making connections wherever you can is sometimes an important step in finding a career you love and study hard to get the qualifications you need. Sign up to as many of the professional networks out there to broaden your knowledge and keep up to date with the latest news.",
  },
  {
    uid: "elizabeth_carper",
    name: "Elizabeth Carper",
    title: undefined,
    photo: true,
    shortDesc: "Facilitating Schools Engagement",
    institution: "Stockholm Environment Institute, University of York",
    jobTitle: "Project Administrator",
    website: undefined,
    twitterHandle: undefined,
    subjects: "Environmental Science and Policy, Geography, Human Geography",
    studyUni: "Environmental Science and Policy",
    advice:
      "Find something you're passionate about and keep at it. We need passionate people in this field! And always keep learning",
  },
  {
    uid: "sarkawt_hama",
    name: "Sarkawt Hama",
    title: "Dr",
    photo: true,
    shortDesc: "Deep-dive school interventions and data analysis",
    institution: "University of Surrey",
    jobTitle: "Research fellow",
    website: "https://www.surrey.ac.uk/people/sarkawt-hama",
    twitterHandle: "@sarkawt_hama",
    subjects: "Air pollution",
    studyUni: "Chemistry/Environmental chemistry",
  },
  {
    uid: "alice_handy",
    name: "Alice Handy",
    title: undefined,
    photo: true,
    shortDesc: "Web App, data analysis development and champions work",
    institution: "Imperial College London",
    jobTitle: "Research fellow",
    website: "https://profiles.imperial.ac.uk/a.handy23",
    twitterHandle: undefined,
    subjects: "Maths, Physics, Geography, Chemistry",
    studyUni: "Engineering - a mix of fluid mechanics and environmental engineering",
    advice: "Give things a go! Often the most enjoyable and interesting projects are the ones you least expect.",
  },
];
export const execBoardMembers: ITeamMember[] = [
  {
    uid: "ben_barratt",
    name: "Ben Barratt",
    title: "Dr",
    photo: true,
    shortDesc: "Chair of the Executive Board",
    institution: "Imperial College London",
    jobTitle: "Reader in Environmental Exposures and Public Health",
    website: " https://www.imperial.ac.uk/people/b.barratt",
    twitterHandle: undefined,
    subjects: "Maths, Physics, Chemistry",
    studyUni: "Physics and Meteorology (I wanted to be a weatherman, but the Met Office didn't agree!)",
    advice: undefined,
  },
  {
    uid: "christopher_pain",
    name: "Christopher Pain",
    title: "Professor",
    photo: true,
    shortDesc: "Executive Board",
    institution: "Imperial College London",
    jobTitle: "Professor of Engineering",
    website: "https://www.imperial.ac.uk/people/c.pain",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "marco-felipe_king",
    name: "Marco-Felipe King",
    title: "Dr",
    photo: true,
    shortDesc: "Executive Board",
    institution: "University of Leeds",
    jobTitle: "Research Fellow",
    website: "https://eps.leeds.ac.uk/civil-engineering/staff/514/dr-marco-felipe-king",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "mark_mon-williams",
    name: "Mark Mon-Williams",
    title: "Professor",
    photo: true,
    shortDesc: "Executive Board",
    institution: "University of Leeds; Wolfson Centre of Applied Education Research ",
    jobTitle: "Professor of Psychology",
    website: "https://caer.org.uk/author/markmonwilliams/",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
];
export const steeringCommitteeMembers: ITeamMember[] = [
  {
    uid: "cath_noakes",
    name: "Cath Noakes",
    title: "Professor",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "University of Leeds",
    jobTitle: "Professor of Environmental Engineering for Buildings",
    website: "https://eps.leeds.ac.uk/civil-engineering/staff/169/professor-catherine-noakes",
    twitterHandle: undefined,
    subjects:
      "Physics, Maths, Chemistry & Music. I never studied biology (even at GCSE) which is ironic as most of my research focuses on engineering control of infection!",
    studyUni:
      "Mathematical Engineering (which is Mechanical Engineering with a bit of extra maths). I did my PhD in computational fluid dynamics modelling industrial coating processes.",
    advice:
      "Be open to opportunities and ideas, and listen to the perspectives of different people. I went into engineering thinking that I would end up working in a mechanical engineering design role in industry. But a chance opportunity to work on a project on using UV light to disinfect microorganisms in air got me working in a totally new area and interacting with people from lots of different disciplines. I really enjoy working on problems that need input from a range of different perspectives and it makes me think about my own expertise in a different way.",
  },
  {
    uid: "heather_king",
    name: "Heather King",
    title: "Professor",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "Kings College London",
    jobTitle: "Professor in Science Education",
    website: "https://www.kcl.ac.uk/people/dr-heather-king",
    twitterHandle: undefined,
    subjects: "Biology, Chemistry, Geography",
    studyUni: "Human Sciences, and then Science Communication",
    advice: undefined,
  },
  {
    uid: "katie_daniel",
    name: "Katie Daniel",
    title: undefined,
    photo: true,
    shortDesc: "Steering Committee",
    institution: "Engineering and Physical Sciences Research Council (EPSRC)",
    jobTitle: "Deputy Director, Partnerships",
    website: "https://www.ukri.org/councils/epsrc/",
    twitterHandle: undefined,
    subjects: "Chemistry, Physics, Mathematics",
    studyUni: "Chemistry",
    advice:
      "Working at UKRI, and the constituent Councils, is perfect for anyone who loves science but wants to move away from undertaking research themselves.  You can continue to learn about really exciting research and make the case for funding new research programmes.  My only advice would be to do what you love and gain qualifications in those fields, then apply to work at UKRI!  As we cover everything from arts and humanities, through to engineering and physical sciences, as well as working with business, there are many opportunities to contribute to making the UK a great place to do science.",
  },
  {
    uid: "mariam_orme",
    name: "Mariam Orme",
    title: "Dr",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "Department for Education",
    jobTitle: "Head of Science Team",
    website: undefined,
    twitterHandle: undefined,
    subjects: "Biology, Chemistry, Maths and Physics",
    studyUni: "Natural sciences, focusing on Molecular Biology",
    advice:
      "Don’t ever worry that you have to choose a single career path and stick to it for the rest of your life: it’s never too late to change careers! And if you decide to join the Civil Service, it gives great opportunities to experience lots of different areas of work.",
  },
  {
    uid: "owen_connick",
    name: "Owen Connick",
    title: undefined,
    photo: true,
    shortDesc: "Steering Committee",
    institution: "Carrier Corporation",
    jobTitle: "Strategic Leader, Building Sustainability",
    website: undefined,
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "pia_hardelid",
    name: "Pia Hardelid",
    title: "Dr",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "University College London",
    jobTitle: "Associate Professor",
    website: " https://iris.ucl.ac.uk/iris/browse/profile?upi=PHARD97",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "sani_dimitroulopoulou",
    name: "Sani Dimitroulopoulou",
    title: "Professor",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "UK Health Security Agency (UKHSA)",
    jobTitle: "Principal Environmental Public Health Scientist – Indoor Environments",
    website: "https://www.ucl.ac.uk/bartlett/environmental-design/professor-sani-dimitroulopoulou",
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: undefined,
    advice: undefined,
  },
  {
    uid: "verity_cook",
    name: "Verity Cook",
    title: "Dr",
    photo: true,
    shortDesc: "Steering Committee",
    institution: "Department for Education",
    jobTitle: "Scientific Evidence Advisor",
    website: undefined,
    twitterHandle: undefined,
    subjects: undefined,
    studyUni: "Biological Sciences (BSc) and then Molecular Haematology (PhD)",
    advice:
      "I worked in industry in pharmaceutical development before coming to work in the government. Academia, Industry and Government have very different routes of entry, progression, and opportunities. The civil service offers great training and support for those interested in a career in the civil service.",
  },
];
