import { AnyAction, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";

import { IUser } from "../../lib/User/IUser";
import { EMonitoringType, ISchoolState, SchoolId } from "../../lib/School/ISchoolDetails";
import { EMonitorChangeSource, IMonitorCurrentState, MonitorId } from "../../lib/Monitor/IMonitor";
import { UserGroup } from "../../lib/User/UserGroup";
import { RootState } from "../reduxStore";
import { apiGetUserState, apiPutUserAgeGroup } from "../../Api/apiUser";
import { ESchoolStatus } from "../../lib/School/ESchoolStatus";
import { EAgeGroup } from "../../lib/User/AgeGroup";
import { IRoomListItem, IRoomState } from "../../lib/School/IRoom";
import { apiChangeMonitorLocation } from "../../Api/apiMonitorManagement";
import { TActivityId } from "../../lib/Activities/IActivity";

export interface ILoadedData<T, I = string | number | "INVALID"> {
  id: I;
  loadStatus: "INIT" | "LOADING" | "LOADED" | "MISSING";
  data?: T;
}

export interface IUserState {
  school: ILoadedData<ISchoolState>;
  monitor: ILoadedData<IMonitorCurrentState>;
  room: ILoadedData<IRoomState>;
}

export enum ESessionStatus {
  NEW_SESSION = "NEW_SESSION",
  LOGGED_OUT = "LOGGED_OUT",
  GETTING_TOKEN = "GETTING_TOKEN",
  RECEIVED_TOKEN = "RECEIVED_TOKEN",
  GETTING_USER_META_DATA = "GETTING_USER_META_DATA",
  LOGGING_OUT = "LOGGING_OUT",
  LOGGED_IN = "LOGGED_IN",
}

export enum EIdleState {
  ACTIVE = "ACTIVE",
  IDLE_SHORT = "IDLE_SHORT",
  IDLE_LONG = "IDLE_LONG",
}

export interface ISession {
  sessionState: ESessionStatus;
  idleState: EIdleState;
  user?: IUser;
  state?: IUserState;
  error?: string | Error;
}

export const initialState: ISession = {
  sessionState: ESessionStatus.NEW_SESSION,
  idleState: EIdleState.ACTIVE,
  user: {
    username: null,
    schoolId: null,
    group: null,
    additionalRoles: [],
    isSuper: null,
    schools: [],
    monitors: [],
    abProfile: null,
    ageGroup: null,
  },
  state: {
    school: undefined,
    monitor: undefined,
    room: undefined,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IUser>) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),
    clearUserState: (state) => ({
      ...state,
      user: initialState.user,
    }),
    setIdle: (state) => ({
      ...state,
      idleState: EIdleState.IDLE_SHORT,
    }),
    goingToLoginScreen: (state) => ({
      ...state,
      sessionState: ESessionStatus.GETTING_TOKEN,
    }),
    returnFromLoginScreen: (state) => ({
      ...state,
      sessionState: ESessionStatus.RECEIVED_TOKEN,
    }),
    gettingUserData: (state) => ({
      ...state,
      sessionState: ESessionStatus.GETTING_USER_META_DATA,
    }),
    setState: (state, action: PayloadAction<IUserState>) => ({
      ...state,
      state: action.payload,
    }),
    setSchoolState: (state, action: PayloadAction<ISchoolState>) => ({
      ...state,
      state: {
        ...state.state,
        school: {
          id: action.payload.id,
          loadStatus: "INIT",
          data: action.payload,
        },
      },
    }),
    setInitialMonitorState: (state, action: PayloadAction<IMonitorCurrentState>) => ({
      ...state,
      state: {
        ...state.state,
        monitor: {
          id: action.payload.id,
          loadStatus: "INIT",
          data: action.payload,
        },
      },
    }),
    setInitialRoomState: (state, action: PayloadAction<IRoomState>) => ({
      ...state,
      state: {
        ...state.state,
        room: {
          id: action.payload.currentRoom?.id,
          loadStatus: "INIT",
          data: action.payload,
        },
      },
    }),
    setUserData: (state, action: PayloadAction<{ user: IUser }>) => ({
      ...state,
      user: action.payload.user,
    }),
    setLoggedIn: (state) => ({
      ...state,
      sessionState: ESessionStatus.LOGGED_IN,
    }),
    loggingOut: (state) => ({
      ...state,
      sessionState: ESessionStatus.LOGGING_OUT,
      user: initialState.user,
      state: initialState.state,
    }),

    loggedOut: (state) => ({
      ...state,
      sessionState: ESessionStatus.LOGGED_OUT,
      user: initialState.user,
      state: initialState.state,
    }),

    updateUserProfile: (state, action: PayloadAction<IUser>) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
    }),
    errorMessage: (state, action: PayloadAction<string>) => ({ ...state, error: action.payload }),
    switchUserGroup: (state, action: PayloadAction<UserGroup>) => ({
      ...state,
      user: { ...state.user, group: action.payload },
    }),
    switchSchool: (state, action: PayloadAction<SchoolId>) => ({
      ...state,
      state: { ...state.state, school: { id: action.payload, loadStatus: "INIT" } },
    }),
    switchMonitor: (state, action: PayloadAction<IMonitorCurrentState>) => ({
      ...state,
      state: {
        ...state.state,
        monitor: { id: action.payload.id, loadStatus: "INIT", data: action.payload },
        room: action.payload.room
          ? {
              id: action.payload.room?.id,
              loadStatus: "INIT",
              data: {
                currentRoom: action.payload.room,
              },
            }
          : state.state.room,
      },
    }),
    switchRoom: (state, action: PayloadAction<IRoomListItem>) => ({
      ...state,
      state: {
        ...state.state,
        room: {
          id: action.payload.id,
          loadStatus: "INIT",
          data: { currentRoom: action.payload },
        },
      },
    }),
    changeMonitorRoom: (
      state,
      action: PayloadAction<{
        monitorId: MonitorId;
        room: IRoomListItem;
      }>
    ) => ({
      ...state,
      state: {
        ...state.state,
        school: {
          ...state.state.school,
          data: {
            ...state.state.school?.data,
            monitors: state.state.school?.data.monitors.map((m) => {
              if (m.id === action.payload.monitorId) {
                return {
                  ...m,
                  room: action.payload.room,
                };
              }
              return m;
            }),
          },
        },
      },
    }),
    updateMonitorActivationState: (
      state,
      action: PayloadAction<{
        monitorId: MonitorId;
        activated: boolean;
        room?: IRoomListItem;
      }>
    ) => ({
      ...state,
      state: {
        ...state.state,
        school: {
          ...state.state.school,
          data: {
            ...state.state.school?.data,
            monitors: state.state.school?.data.monitors.map((m) => {
              if (m.id === action.payload.monitorId) {
                return {
                  ...m,
                  activated: action.payload.activated,
                };
              }
              return m;
            }),
          },
        },
        monitor:
          action.payload.monitorId === state.state.monitor?.data?.id
            ? {
                ...state.state.monitor,
                data: {
                  ...state.state.monitor?.data,
                  activated: action.payload.activated,
                  room: action.payload.room,
                },
              }
            : state.state.monitor,
      },
    }),
  },
});

export const actions = userSlice.actions;

export const {
  setData,
  clearUserState,
  setIdle,
  goingToLoginScreen,
  returnFromLoginScreen,
  gettingUserData,
  setSchoolState,
  setInitialMonitorState,
  setInitialRoomState,
  setUserData,
  setLoggedIn,
  setState,
  loggingOut,
  loggedOut,
  updateUserProfile,
  errorMessage,
  switchUserGroup,
  switchSchool,
  switchMonitor,
  switchRoom,
  changeMonitorRoom,
  updateMonitorActivationState,
} = userSlice.actions;

export default userSlice.reducer;

const DEV_MODE = process.env.NODE_ENV === "development";

// ASYNC ACTIONS
export const reduxGetUserData =
  (
    accessToken: string,
    isLoggingIn: boolean = true,
    monitorSelection: MonitorId | null
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    if (isLoggingIn) {
      dispatch(gettingUserData());
    }
    const data = await apiGetUserState(accessToken).catch((e) => {
      if (DEV_MODE) console.log(e);
      if (typeof e === "string" && e.includes("Bad authorization state")) {
        dispatch(errorMessage("Your session has timed out. Please log back in."));
      } else if (typeof e === "string" && e.includes("SchoolUser not found")) {
        dispatch(errorMessage("User could not be found. Please log back in."));
      } else if (e.statusCode === 401) {
        dispatch(errorMessage("Your session has timed out. Please log back in."));
      } else if (e.statusCode === 500) {
        dispatch(errorMessage(`There was a server error during login. Please try again.`));
      } else if (e.statusCode === 404) {
        dispatch(errorMessage(`User could not be found. Please try again.`));
      } else {
        dispatch(errorMessage(`Unknown server error while attempting to login. Please try again.`));
      }
      dispatch(loggedOut());
    });
    if (!data) return;
    try {
      const currentMonitor = monitorSelection
        ? data.School.Monitors.find((m) => m.Id === monitorSelection)
        : data.School.Monitors.find((m) => m.DateActivated) || data.School.Monitors[0];
      const currentRoom = data.School.Rooms.find((r) => r.Id === currentMonitor?.Room?.Id) || data.School.Rooms[0];
      const requiresRoom = data.School?.MonitoringType === EMonitoringType.STATIC;
      const initialState: IUserState = {
        school: data.School && {
          id: data.School.SchoolId,
          loadStatus: "INIT",
          data: {
            id: data.School.SchoolId,
            schoolName: String(data.School.Id),
            monitors:
              data.School?.Monitors.map((m) => ({
                id: m.MonitorReferenceId,
                activated: m.DateActivated && (!requiresRoom || m.Room) ? true : false,
                room: m.Room ? { id: String(m.Room.Id), label: m.Room.Name } : null,
              })) || [],
            status: data.School.SchoolStatus || ESchoolStatus.ERROR,
            roomList: data.School?.Rooms?.map((r) => ({ id: String(r.Id), label: r.Name })) || [],
            isPioneer: data.School?.IsPioneer,
            disallowMoveYourMonitor: data.School?.MonitoringType === EMonitoringType.STATIC,
          },
        },
        monitor: currentMonitor
          ? {
              id: currentMonitor?.MonitorReferenceId || "No monitor connected!",
              loadStatus: "INIT",
              data: {
                id: currentMonitor.MonitorReferenceId,
                activated: currentMonitor.DateActivated ? true : false,
                room: currentMonitor.Room
                  ? { id: String(currentMonitor.Room.Id), label: currentMonitor.Room.Name }
                  : null,
              },
            }
          : {
              id: "INVALID",
              loadStatus: "MISSING",
            },
        room: currentRoom
          ? {
              id: String(currentRoom.Id),
              loadStatus: "INIT",
              data: {
                currentRoom: {
                  id: String(currentRoom.Id),
                  label: currentRoom.Name,
                },
              },
            }
          : {
              id: "INVALID",
              loadStatus: "MISSING",
            },
      };

      const user: IUser = {
        username: String(data.Id),
        group: (data.Roles && data.Roles[0]) || UserGroup.GUEST,
        additionalRoles: data.Roles || [UserGroup.GUEST],
        schoolId: data.School.SchoolId,
        isSuper: data.superUser,
        abProfile: data.ABProfile,
        ageGroup: data.AgeGroup,
        schools: [data.School.SchoolId],
        monitors: data.School.Monitors[0] ? [data.School.Monitors[0]?.Id] : [],
      };
      dispatch(setUserData({ user }));
      dispatch(setLoggedIn());
      if (!initialState.school?.data) throw Error("Failed to load school data");
      if (initialState.school?.data) dispatch(setSchoolState(initialState.school.data));
      if (initialState.monitor?.data) dispatch(setInitialMonitorState(initialState.monitor.data));
      if (initialState.room?.data) dispatch(setInitialRoomState(initialState.room.data));
    } catch (e) {
      if (DEV_MODE) console.log(e);
      dispatch(errorMessage(`Failed to login. Please try again.`));
      dispatch(loggedOut());
    }
  };

/**
 * Logout user - Links to user api then updates state
 *
 */
export const reduxLogoutUser = (logOutAuthContext?) => async (dispatch) => {
  if (logOutAuthContext) logOutAuthContext();
  dispatch(loggingOut());
  dispatch(loggedOut());
};

export const reduxSetUserAgeGroup =
  (accessToken: string, ageGroup: EAgeGroup): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    await apiPutUserAgeGroup(accessToken, ageGroup);
    dispatch(reduxGetUserData(accessToken, true, null));
  };

export const reduxSwitchRoomAsync =
  (
    accessToken: string,
    monitorId: MonitorId,
    room: IRoomListItem,
    updatedFrom: EMonitorChangeSource,
    activityId?: TActivityId
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    await apiChangeMonitorLocation(accessToken, monitorId, room, null, activityId, null, updatedFrom).then(() => {
      dispatch(switchRoom(room));
      // TODO: Handle errors
    });
  };
