import React from "react";
import { Card } from "./Card";
import { InvalidCard } from "./InvalidCard";
import { CardComponentTypeMap, ICard } from "./lib";
import { LoadingCard } from "./LoadingCard";

export interface IUseGetCardArgs<K extends string | symbol | number, T extends ICard<K>> {
  cardData: T | { loading: true } | { loading: false; error: string };
  cardComponentTypeMap: CardComponentTypeMap<K, T>;
}

export const useGetCard = <K extends string | symbol | number, T extends ICard<K>>({
  cardData,
  cardComponentTypeMap,
}: IUseGetCardArgs<K, T>): React.ReactElement => {
  const card = React.useMemo(() => {
    if ((cardData as { loading: true }).loading) {
      return <LoadingCard />;
    }
    if ((cardData as { loading: false; error: string }).error) {
      return <InvalidCard message={(cardData as { loading: false; error: string }).error} />;
    }
    const { id, type } = cardData as T;
    const { Component } = cardComponentTypeMap[type] || {};
    if (!Component)
      return <Card key={id}>{() => <InvalidCard message={`Missing component for card type: ${String(type)}`} />}</Card>;
    return <Card key={id}>{({ rootRef }) => <Component rootRef={rootRef} {...(cardData as T)} />}</Card>;
  }, [cardData, cardComponentTypeMap]);
  return card;
};

export const useGetExpandedCard = <K extends string | symbol | number, T extends ICard<K>>({
  cardData,
  cardComponentTypeMap,
}: IUseGetCardArgs<K, T>): React.ReactElement => {
  const card = React.useMemo(() => {
    if ((cardData as { loading: true }).loading) {
      return <LoadingCard />;
    }
    if ((cardData as { loading: false; error: string }).error) {
      return <InvalidCard message={(cardData as { loading: false; error: string }).error} />;
    }
    const { id, type } = cardData as T;
    const { Component } = cardComponentTypeMap[type] || {};
    if (!Component)
      return <Card key={id}>{() => <InvalidCard message={`Missing component for card type: ${String(type)}`} />}</Card>;
    return <Component {...(cardData as T)} />;
  }, [cardData, cardComponentTypeMap]);
  return card;
};

export interface IUseGetCardsArgs<K extends string | symbol | number, T extends ICard<K>> {
  cardsData: T[];
  cardComponentTypeMap: CardComponentTypeMap<K, T>;
  filterHiddenCards?: boolean;
}

export const useGetCards = <K extends string | symbol | number, T extends ICard<K>>({
  cardsData,
  cardComponentTypeMap,
  filterHiddenCards,
}: IUseGetCardsArgs<K, T>): React.ReactElement[] => {
  const cards = React.useMemo(() => {
    return cardsData
      .filter((c) => !filterHiddenCards || !c.hidden)
      .map((props) => {
        const { id, type } = props;
        const { Component } = cardComponentTypeMap[type] || {};
        if (!Component)
          return (
            <Card key={id}>{() => <InvalidCard message={`Missing component for card type: ${String(type)}`} />}</Card>
          );
        return <Card key={id}>{({ rootRef }) => <Component rootRef={rootRef} {...props} />}</Card>;
      });
  }, [cardsData, cardComponentTypeMap, filterHiddenCards]);
  return cards;
};
