import React from "react";
import { AchievementCategories, EAchievementCategory } from "../../../lib/Achievements/EAchievementCategory";
import { IAchievement, IAchievementUser, IUserScores, TAchievementId } from "../../../lib/Achievements/IAchievement";
import { actions } from "../../../Redux/Achievements/achievementsReducer";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { ButtonNoFormat } from "../../../styles/button";
import { themeColors } from "../../../styles/themes";
import { UserScoreWidget } from "../UserScoreWidget";
import {
  // AchievementAcceptBtnStyle,
  AchievementCategoryIconStyle,
  AchievementContentStyle,
  AchievementLabelStyle,
  AchievementPopupStyle,
  AchievementPopupStyleWrap,
  BtnContainer,
  CloseAllAchievementsBtnStyle,
  MultipleAchievementsBannerWrapper,
  NewAchievementPopupStyle,
  NewScorePopup,
  NewScorePopupWrap,
} from "./style";

export interface IAchievementIndividualBannerProps {
  achievement: IAchievement;
  index: number;
  open: boolean;
  closing: boolean;
  handleAcceptAchievement: (id: TAchievementId) => void;
}

export const AchievementIndividualBanner: React.FC<IAchievementIndividualBannerProps> = ({
  achievement,
  index,
  open,
  closing,
  handleAcceptAchievement,
}) => {
  const color = AchievementCategories[achievement.category]?.color || themeColors.PANTONE2602C;
  const logo = achievement.logo || AchievementCategories[achievement.category]?.logo;
  return (
    <AchievementPopupStyleWrap index={index} open={open} closing={closing} data-testid="newAchievementPanel">
      <AchievementPopupStyle color={color}>
        <AchievementContentStyle color={color}>
          <AchievementLabelStyle>{achievement.label}</AchievementLabelStyle>
          <p>{achievement.description}</p>
        </AchievementContentStyle>
        <BtnContainer>
          {logo && (
            <ButtonNoFormat aria-label="Ok" onClick={() => handleAcceptAchievement(achievement.id)}>
              <AchievementCategoryIconStyle color={color}>
                <img src={logo} width="100%" alt={achievement.label} />
              </AchievementCategoryIconStyle>
            </ButtonNoFormat>
          )}
        </BtnContainer>
        {/* <button>Enter the number of windows currenlty open to get an extra 10 points!</button> */}
      </AchievementPopupStyle>
    </AchievementPopupStyleWrap>
  );
};

export const CloseAllAchievementsBtn = ({ open, removeAllAchievements, closing }) => {
  return (
    <CloseAllAchievementsBtnStyle
      className={open ? "open" : "closed"}
      closing={closing}
      open={open}
      onClick={removeAllAchievements}
    >
      Back to activity
    </CloseAllAchievementsBtnStyle>
  );
};

const MAX_ACHIEVEMENTS = 5;

export const NewAchievementPopup = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector<boolean>((state) => state.achievementsState.achievementPopup.open);
  const userScores = useAppSelector<IUserScores>((state) => state.achievementsState.userScore);
  const achievements = useAppSelector<IAchievementUser[]>((state) => state.achievementsState.achievements);
  const [achievementsToClose, setAchievementsToClose] = React.useState<TAchievementId[]>([]);
  const [achievementsClosing, setAchievementsClosing] = React.useState<Record<string, TAchievementId>>({});
  const timeouts = React.useRef([]);
  const timeoutB = React.useRef([]);
  const [openAchievementCount, setOpenAchievementCount] = React.useState(0);
  const [isClosing, setIsClosing] = React.useState(false);

  React.useEffect(() => {
    setOpenAchievementCount(achievements.length);
  }, [achievements, achievementsClosing]);

  React.useEffect(() => {
    timeoutB.current.forEach((t) => {
      clearTimeout(t);
    });
    if (open)
      setIsClosing(openAchievementCount > 0 && openAchievementCount === Object.keys(achievementsClosing).length);
  }, [openAchievementCount, achievementsClosing, open]);

  const removeAllAchievements = () => {
    setIsClosing(true);
    const achievementsIds = achievements.map((a) => a.id);
    setAchievementsToClose(achievementsIds.reverse());
  };

  const startClosingAchievement = (achievementId) => {
    setAchievementsToClose((prev) => [...prev, achievementId]);
  };

  React.useEffect(() => {
    if (isClosing && Object.keys(achievementsClosing).length === 0) {
      const timeout = setTimeout(() => {
        dispatch(actions.closePopup());
      }, 1300);
      timeoutB.current.push(timeout);
    }
  }, [isClosing, achievementsClosing, dispatch]);

  React.useEffect(() => {
    const achievementId = achievementsToClose[0];
    if (achievementId != null) {
      const timeout = setTimeout(() => {
        setAchievementsClosing((prev) => {
          const newMap = { ...prev };
          delete newMap[achievementId];
          return newMap;
        });
        dispatch(actions.removeAchievement({ achievementId, closePopup: false }));
      }, 1300);
      timeouts.current.push(timeout);
      setAchievementsClosing((prev) => ({ ...prev, [achievementId]: achievementId }));
      setAchievementsToClose((prev) => {
        return prev.filter((a) => a !== achievementId);
      });
    }
  }, [achievementsToClose, dispatch]);

  React.useEffect(() => {
    timeouts.current.forEach((t) => {
      clearTimeout(t);
    });
    timeoutB.current.forEach((t) => {
      clearTimeout(t);
    });
  }, []);

  if (!open) return <></>;

  return (
    <NewAchievementPopupStyle open={open} closing={isClosing} data-testid="newAchievementPopup">
      <CloseAllAchievementsBtn open={open} closing={isClosing} removeAllAchievements={removeAllAchievements} />
      <MultipleAchievementsBannerWrapper open={open} closing={isClosing}>
        {achievements.slice(0, MAX_ACHIEVEMENTS).map((achievement, i) => (
          <AchievementIndividualBanner
            key={achievement.id}
            index={i}
            achievement={achievement}
            handleAcceptAchievement={startClosingAchievement}
            open={open}
            closing={achievementsClosing[achievement.id] != null}
          />
        ))}
        {/* TODO: Better no achievements text */}
        {/* {achievements.length === 0 && <p>No more achievements!</p>} */}
      </MultipleAchievementsBannerWrapper>
      {/* TODO: Filter out if 0 score? */}
      {open && (
        <NewScorePopupWrap open={open} closing={isClosing}>
          <NewScorePopup data-testid="score-QUIZ" index={0} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.QUIZ]}
              value={open && !isClosing ? userScores[EAchievementCategory.QUIZ].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-TOP_RESPONDANT" index={1} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.TOP_RESPONDANT]}
              value={open && !isClosing ? userScores[EAchievementCategory.TOP_RESPONDANT].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-TEAM_STUDENT" index={2} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.TEAM_STUDENT]}
              value={open && !isClosing ? userScores[EAchievementCategory.TEAM_STUDENT].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-CHIEF_INVESTIGATOR" index={3} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.CHIEF_INVESTIGATOR]}
              value={open && !isClosing ? userScores[EAchievementCategory.CHIEF_INVESTIGATOR].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-CONTENT_CREATOR" index={4} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.CONTENT_CREATOR]}
              value={open && !isClosing ? userScores[EAchievementCategory.CONTENT_CREATOR].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-EXPERIMENT_WHIZZ" index={5} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.EXPERIMENT_WHIZZ]}
              value={open && !isClosing ? userScores[EAchievementCategory.EXPERIMENT_WHIZZ].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-DATA_ANALYST" index={6} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.DATA_ANALYST]}
              value={open && !isClosing ? userScores[EAchievementCategory.DATA_ANALYST].value : 0}
            />
          </NewScorePopup>
          <NewScorePopup data-testid="score-HERO" index={7} open={open} closing={isClosing}>
            <UserScoreWidget
              {...userScores[EAchievementCategory.HERO]}
              value={open && !isClosing ? userScores[EAchievementCategory.HERO].value : 0}
            />
          </NewScorePopup>
        </NewScorePopupWrap>
      )}
    </NewAchievementPopupStyle>
  );
};
