import React from "react";
import { useFormContext } from "react-hook-form";
import { InputStyle } from "../../styles/form";
import { Checkbox } from "./Checkbox";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";


export interface IInputWrapProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string | React.ReactNode;
  longDescription?: string;
  hidden?: boolean;
  validate?: () => true | string;
  validateOnBlur?: boolean;
}
export interface IInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  validate?: () => true | string;
  validateOnBlur?: boolean;
}

export const Input: React.FC<IInputProps> = (props) => {
  const { name, required, type, validate, validateOnBlur, ...additionalProps } = props;
  if (!name) throw Error("Must supply name");
  const { register, trigger } = useFormContext();
  const { ref, ...registerProps } = register(name, {
    validate,
    required: required ? "Required" : null,
    onBlur: validateOnBlur ? () => trigger(name) : () => { },
  });
  const inputProps = {
    id: name,
    name,
    type,
    ...registerProps,
    ...additionalProps,
  };
  return <input {...inputProps} ref={ref} />;
};

export const InputWrap: React.FC<IInputWrapProps> = (props) => {
  const { style, label, name, required, type, hidden, longDescription, placeholder, ...additionalProps } = props;

  const inputProps: IInputProps = {
    ...additionalProps,
    name,
    required,
    type,
    placeholder,
  };

  return (
    <InputStyle hidden={hidden} style={style}>
      {type !== "checkbox" && <Label name={name} label={label} longDescription={longDescription} required={required} />}
      {type === "checkbox" && <div style={{ display: 'flex', alignItems: 'center' }}><div><Checkbox {...inputProps} /></div><div style={{ marginLeft: "0.5rem" }}><Label name={name} label={label} longDescription={longDescription} required={required} /></div></div>}
      {type === "string" && <Input {...inputProps} />}
      {type === "email" && <Input {...inputProps} />}
      {type === "date" && <Input {...inputProps} />}
      {type === "time" && <Input {...inputProps} />}
      <ErrorMessage name={name} />
    </InputStyle>
  );
};

export interface IInputAreaProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label: string;
  required?: boolean;
  hidden?: boolean;
}

export const InputArea: React.FC<IInputAreaProps> = (props) => {
  const { type, label, name, hidden, required, ...inputProps } = props;
  if (!name) throw Error("Must supply name");
  const id = name;

  if (type && type !== "area") throw Error(`Attempted to use area input for none area type: ${type}`);

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[id]?.message;
  return (
    <InputStyle hidden={hidden}>
      <Label name={name} label={label} required={required} />
      <textarea id={id} {...register(id, { required: required ? "Required" : null })} {...inputProps} />
      <div className="form__errorMessage">{errorMessage ? `Input is invalid: ${errorMessage}` : ""}</div>
    </InputStyle>
  );
};
