import React from "react";
import { AppInfoSection, AppSectionHeading, AppPageStyle } from "../../styles/app";
import { AppResourceDashboard } from "../../components/AppResources";

const ResourcesPage: React.FC = () => {
  return (
    <AppPageStyle data-testid="samheAppContent">
      <div data-testid="appPage-resourcesView">
        <AppInfoSection>
          <AppSectionHeading>SAMHE Web App - Library</AppSectionHeading>
          <p>Find some useful SAMHE resources below!</p>
        </AppInfoSection>
        <AppResourceDashboard />
      </div>
    </AppPageStyle>
  );
};

export default ResourcesPage;
