import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { apiPing, apiPingAuth } from "../../Api/ping";
import useAsyncFnReset from "../../utils/hooks/useAsync";

export enum EStatus {
  OK = "OK",
  LOADING = "LOADING",
  ERROR = "ERROR",
  TIMED_OUT = "TIMED_OUT",
}
export enum EPingStatus {
  OK = "OK",
  LOADING = "LOADING",
  ERROR = "ERROR",
  TIMED_OUT = "TIMED_OUT",
}
export enum EAuthStatus {
  OK = "OK",
  NOT_CHECKED = "NOT_CHECKED",
  LOADING = "LOADING",
  UNATHENTICATED = "UNATHENTICATED",
  TIMED_OUT = "TIMED_OUT",
  ERROR = "ERROR",
}

// TODO: We should return {ok: true} instead from server;
const expectedValue = "Hello from the SAHME API.";

export const usePingTest = ({ timeout = 2000, maxAttempts = 10 }) => {
  const [pingTest, pingTestFn, resetPingTest] = useAsyncFnReset(apiPing);
  const { token } = React.useContext(AuthContext);
  const pingTimeout = React.useRef(null);
  const authTimeout = React.useRef(null);
  const [allowPing, setAllowPing] = React.useState(true);
  const [allowAuth, setAllowAuth] = React.useState(true);
  const [authResponse, authTestFn, resetAuthTest] = useAsyncFnReset(
    async () => (token ? apiPingAuth(token) : { ok: false }),
    [token]
  );
  const [attempts, setAttempts] = React.useState(0);
  const [authAttempts, setAuthAttempts] = React.useState(0);

  const pingTimedOut = React.useMemo(() => attempts >= maxAttempts, [attempts, maxAttempts]);
  const authTimedOut = React.useMemo(() => authAttempts >= maxAttempts, [authAttempts, maxAttempts]);

  /** Ping Test */
  React.useEffect(() => {
    if (!pingTest.loading && !pingTest.value && allowPing && attempts < maxAttempts) {
      setAllowPing(false);
      setAttempts((prev) => prev + 1);
      pingTestFn();
      pingTimeout.current = setTimeout(() => {
        setAllowPing(true);
      }, timeout);
    }
    return () => {
      if (pingTimeout.current) clearTimeout(pingTimeout.current);
    };
  }, [pingTest, maxAttempts, attempts, pingTestFn, allowPing, timeout]);

  const connectionStatus = React.useMemo(
    () =>
      (attempts === 0 && EPingStatus.LOADING) ||
      ((pingTest?.value as any) === (expectedValue as any) && EPingStatus.OK) ||
      (pingTest?.loading && EPingStatus.LOADING) ||
      (pingTimedOut && EPingStatus.TIMED_OUT) ||
      EPingStatus.ERROR,
    [pingTest, pingTimedOut, attempts]
  );

  const authStatus = React.useMemo(
    () =>
      (connectionStatus !== EPingStatus.OK && EAuthStatus.NOT_CHECKED) ||
      ((authResponse?.value?.ok as any) && EAuthStatus.OK) ||
      (authResponse?.loading && EAuthStatus.LOADING) ||
      (authResponse?.error && EAuthStatus.ERROR) ||
      (authTimedOut && EAuthStatus.TIMED_OUT) ||
      EAuthStatus.UNATHENTICATED,
    [connectionStatus, authResponse, authTimedOut]
  );

  /** Auth Test */
  React.useEffect(() => {
    if (
      token &&
      connectionStatus === EPingStatus.OK &&
      authStatus !== EAuthStatus.OK &&
      !authResponse.loading &&
      allowAuth
    ) {
      if (authAttempts < maxAttempts) {
        setAllowAuth(false);
        setAuthAttempts((prev) => prev + 1);
        authTestFn();
        authTimeout.current = setTimeout(() => {
          setAllowAuth(true);
        }, timeout);
      }
    }
    return () => {
      if (authTimeout.current) clearTimeout(authTimeout.current);
    };
  }, [authTestFn, maxAttempts, authAttempts, connectionStatus, authResponse, authStatus, allowAuth, timeout, token]);

  if (pingTest.error) {
    console.warn(pingTest.error);
  }

  if (authResponse.error) {
    console.warn(authResponse.error);
  }

  const retry = () => {
    setAttempts(0);
    setAuthAttempts(0);
    resetAuthTest();
    resetPingTest();
    setAllowPing(true);
    setAllowAuth(true);
  };

  const status =
    ((connectionStatus === EPingStatus.LOADING || authStatus === EAuthStatus.LOADING) && EStatus.LOADING) ||
    (connectionStatus === EPingStatus.OK && authStatus === EAuthStatus.OK && EStatus.OK) ||
    (connectionStatus === EPingStatus.TIMED_OUT && authStatus === EAuthStatus.TIMED_OUT && EStatus.TIMED_OUT) ||
    EStatus.ERROR;

  return { connectionStatus, authStatus, status, retry };
};
