import React from "react";
import { Uid } from "@react_db_client/constants.client-types";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps, TStepRef } from "../../../../lib/Activities/IStep";
import { IMultiCheckboxOption } from "../../../../lib/Forms/IMultiCheckboxOption";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { MultiCheckboxB } from "../../../FormComponents/MultiCheckbox";
import { TextFormatter } from "../../../TextFormatter";
import { getNextStep } from "../../nextStepUtils";

export interface IMultiCheckboxStepOptions extends IMultiCheckboxOption {
  targetStep?: TStepRef;
}

export interface IMultipleChoiceExtraProps {
  options: IMultiCheckboxStepOptions[];
  multiple?: boolean;
}

export interface IMultipleChoiceState {
  selection: boolean[];
  selectionIds: Uid[];
}

export const MultipleChoiceStep = (props: IStepProps<IMultipleChoiceExtraProps, IMultipleChoiceState>) => {
  const {
    state,
    description,
    additionalProps,
    onChange,
    onSubmit,
    optional,
    nextSteps,
    previousStepsState,
    activitySteps,
  } = props;
  const { options, multiple } = additionalProps;

  const submit = (stateSubmitted: IMultipleChoiceState) => {
    if (!optional) {
      if (!stateSubmitted || !options.find((opt, i) => stateSubmitted.selection[i])) {
        return;
      }
    }
    const nextStepId = options.find((opt, i) => stateSubmitted.selection[i]).targetStep;

    const nextStepRef = getNextStep(nextStepId, nextSteps, previousStepsState, activitySteps, props, {
      ...props,
      state: stateSubmitted,
    });
    onSubmit({ newValue: stateSubmitted, nextStep: nextStepRef });
  };

  const handleChange = (v: boolean[]) => {
    const updatedState = {
      selection: v,
      selectionIds: options.filter((opt, i) => v[i]).map((opt) => opt.uid),
    };
    if (!multiple && updatedState.selection !== state?.selection) submit(updatedState);
    onChange("selection", v);
    onChange(
      "selectionIds",
      options.filter((opt, i) => v[i]).map((opt) => opt.uid)
    );
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <MultiCheckboxB
        name="selection"
        options={options}
        value={state?.selection}
        onChange={handleChange}
        multiple={multiple}
      />
      {multiple && (
        <CTAButtonStyle type="button" onClick={() => submit(state)}>
          Next
        </CTAButtonStyle>
      )}
    </form>
  );
};
