import { Link } from "react-router-dom";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { ButtonCSS, CTAButtonCSS } from "./button";

export const LinkStyle = styled(Link)`
  text-decoration-thickness: 1px;
`;

export const LinkExternalStyle = styled(Link)`
  position: relative;
  &:hover,
  &:focus {
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.colors.text};
      display: inline-block;
      margin-left: 4px;
      position: absolute;
      left: 100%;
      -webkit-mask-image: url(/new_window_icon.svg);
      mask-size: contain;
      mask-image: url(/new_window_icon.svg);
    }
    &:before {
      content: "";
      position: absolute;
      margin-left: 4px;
      background-color: ${({ theme }) => theme.background};
      width: 20px;
      height: 20px;
      left: 100%;
      border-radius: 2px;
      opacity: 0.7;
    }
  }
`;

export const AExternalStyle = styled.a`
  position: relative;
  cursor: pointer;
`;

export const LinkButtonStyle = styled(Link)`
  ${ButtonCSS}
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  text-decoration: none;
  text-align: centre;
  display: inline-block;
`;

export const CTALinkButtonStyle = styled(Link)<{ disabled?: boolean }>`
  ${CTAButtonCSS}
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  text-decoration: none;
  text-align: centre;
  display: inline-block;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-bottom: 0.7rem;
  }

  ${({ disabled }) =>
    disabled
      ? `
    background-color: ${({ theme }) => theme.colors.grey1};
    color: ${({ theme }) => theme.colors.text};
 `
      : ``}
`;

export const AExternalButtonStyle = styled(AExternalStyle)`
  ${CTAButtonCSS}
  font-weight: 400;
  text-decoration: none;
  text-align: centre;
  display: inline-block;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-bottom: 0.7rem;
  }
`;

export const DownloadLinkStyle = styled.a``;

export const DownloadCsvLink = styled(CSVLink)`
  ${ButtonCSS}
  color: white;
  display: inline-block;
  background: ${({ theme }) => theme.colors.tertiary};
  &:hover {
    background: ${({ theme }) => theme.colors.tertiaryHover};
    color: white;
  }
  border: none;
`;

export const CTAAnchorButtonStyle = styled.a`
  ${CTAButtonCSS}
`;

export const DownloadCsvLinkBig = styled(CSVLink)`
  ${CTAButtonCSS}
`;

export const LinkNoFormat = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const InternalLinkNoFormat = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;
