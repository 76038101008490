import { DataMetricsMetaData, EDataMetrics, IMetricData, MetricStrIdMap } from "../../../lib/DataViews/EDataMetrics";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";

export interface IAggregatedByTimestampDataRow {
  dateStamp: string;
  date: string;
  time: string;
}

/**
 * Combines raw data into a row per timestamp with metric headings
 */
export const aggregateTimestamps = <T>(
  data: IMonitorDataItem<T>[],
  multipleMonitors?: boolean
): {
  metricHeadings: IMetricData[];
  data: ({
    dateStamp: string;
    date: string;
    time: string;
    monitorReferenceId?: string;
  } & { [k in EDataMetrics]: number })[];
} => {
  const initialProcessed: {
    metricHeadings: EDataMetrics[];
    data: {
      [k: string]: IAggregatedByTimestampDataRow & { [k: string]: any };
    };
  } = {
    metricHeadings: [],
    data: {},
  };

  const processedData = data.reduce((acc, row) => {
    const date = new Date(row.dateStamp).toLocaleDateString("en-GB");
    const time = new Date(row.dateStamp).toLocaleTimeString();
    const key = multipleMonitors ? `${date}T${time}-${row.monitorReferenceId}` : `${date}T${time}`;

    const fieldId = MetricStrIdMap[row.field];
    if (!fieldId) {
      // Skip fields not in our Metric list
      return acc;
    }
    const metricHeadings = Array.from(new Set([...acc.metricHeadings, fieldId]));
    const newRow = acc.data[key] || {
      dateStamp: row.dateStamp,
      date,
      time,
      ...(multipleMonitors ? { monitorReferenceId: row.monitorReferenceId } : {}),
    };
    newRow[fieldId] = parseFloat((row.value as any as number).toFixed(DataMetricsMetaData[fieldId].decimalPlaces));
    return {
      metricHeadings,
      data: { ...acc.data, [key]: newRow },
    };
  }, initialProcessed);
  const dataSorted = Object.values(processedData.data).sort((a, b) => {
    if (multipleMonitors && a.monitorReferenceId !== b.monitorReferenceId) {
      return a.monitorReferenceId > b.monitorReferenceId ? 1 : -1;
    }
    return new Date(a.dateStamp) > new Date(b.dateStamp) ? 1 : -1;
  });
  const metricHeadingsData = processedData.metricHeadings.map((h) => DataMetricsMetaData[h]);
  return {
    metricHeadings: metricHeadingsData,
    data: dataSorted as (IAggregatedByTimestampDataRow & { [k in EDataMetrics]: number })[],
  };
};

export const dataToCsv = (
  metrics: IMetricData[],
  data: ({
    dateStamp: string;
    date: string;
    time: string;
    monitorReferenceId?: string;
  } & { [k in EDataMetrics]: number })[],
  includeTime: boolean = true,
  multipleMonitors?: boolean
) => {
  if (!metrics || metrics.length === 0) throw new Error("Missing metrics for csv output");
  // console.info(metrics);
  const headingRow = [
    "Date",
    ...(includeTime ? ["Time"] : []),
    ...(multipleMonitors ? ["Monitor Ref ID"] : []),
    ...metrics.map((m) => `${m.label}(${m.unit})`),
  ];
  return [
    headingRow,
    ...data.map((row) => [
      row.date,
      ...(includeTime ? [row.time] : []),
      ...(multipleMonitors ? [row.monitorReferenceId] : []),
      ...metrics.map((m) => row[m.id]),
    ]),
  ];
};
