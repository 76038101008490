import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import { NormalizeStyles } from "./normalize";
import { ResetStyles } from "./reset";

function StyleProviders({ theme, children }: { children: React.ReactNode; theme: DefaultTheme }) {
  return (
    <ThemeProvider theme={theme}>
      <ResetStyles />
      <NormalizeStyles />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}

export default StyleProviders;
