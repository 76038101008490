import { TDataAdditionalProps } from "../../lib/DataViews/IDataView";
import { IDataViewCardProps, IDataViewFullCardProps } from "./IDataViewCardProps";
import { useGetDataViewMetaData } from "./useGetDataViewMetaData";

export interface IExpandedCardLoaderProps<T extends TDataAdditionalProps> {
  children: (props: IDataViewFullCardProps) => React.ReactNode;
  childProps: IDataViewCardProps<T>;
}

export const ExpandedCardLoader = <T extends TDataAdditionalProps>({
  children,
  childProps,
}: IExpandedCardLoaderProps<T>) => {
  const { card: fullCardData, loadingView } = useGetDataViewMetaData(childProps);
  if (loadingView) return <>Card data is loading...!</>;
  return <>{children({ ...childProps, ...fullCardData })}</>;
};
