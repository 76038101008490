export enum EBaseLineStandardTypes {
  LINE = 1,
  AREA = 2,
}

export interface IBaseLineStandard {
  value: number;
  label: string;
  upper?: number;
  type: EBaseLineStandardTypes;
  color?: `#${string}`;
}
