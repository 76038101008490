import { CardComponentTypeMap } from "../../GenericComponents/CardBoard";
import { wrapWithErrorBoundary } from "../../GenericComponents/ErrorBoundary/ErrorBoundary";
import { EDataViewTypes } from "../../lib/DataViews/IDataView";
import { SimplePlotCard, SimplePlotCardExpanded } from "./DataViewTypes";
import { BubblePlotCard } from "./DataViewTypes/BubblePlotView";
import { BubblePlotCardExpanded } from "./DataViewTypes/BubblePlotView/BubblePlotView";
import { DataDownloadCard, DataDownloadCardExpanded } from "./DataViewTypes/DataDownload/DataDownload";
import { GaugeChartCard, GaugeChartCardExpanded } from "./DataViewTypes/GaugeChartView/GaugeChartView";
import { PieChartCard, PieChartCardExpanded } from "./DataViewTypes/PieChart";
import { SimpleBarCard, SimpleBarCardExpanded } from "./DataViewTypes/SimpleBar";
import { SimpleScatterCard, SimpleScatterCardExpanded } from "./DataViewTypes/SimpleScatter/SimpleScatter";
import { IDataViewCardProps } from "./IDataViewCardProps";

export const cardComponentTypeMap: CardComponentTypeMap<EDataViewTypes, IDataViewCardProps> = {
  [EDataViewTypes.SIMPLE_PLOT]: {
    id: EDataViewTypes.SIMPLE_PLOT,
    Component: wrapWithErrorBoundary(SimplePlotCard, "Failed to Render Simple Plot Card", () => {}, "", ""),
  },
  [EDataViewTypes.BUBBLE_PLOT]: {
    id: EDataViewTypes.BUBBLE_PLOT,
    Component: wrapWithErrorBoundary(BubblePlotCard, "Failed to Render Bubble Plot Card", () => {}, "", ""),
  },
  [EDataViewTypes.DATA_DOWNLOAD]: {
    id: EDataViewTypes.DATA_DOWNLOAD,
    Component: wrapWithErrorBoundary(DataDownloadCard, "Failed to Render Data Download Card", () => {}, "", ""),
  },
  [EDataViewTypes.GAUGE_CHART]: {
    id: EDataViewTypes.GAUGE_CHART,
    Component: wrapWithErrorBoundary(GaugeChartCard, "Failed to Render Gauge Chart Card", () => {}, "", ""),
  },
  [EDataViewTypes.SIMPLE_SCATTER]: {
    id: EDataViewTypes.SIMPLE_SCATTER,
    Component: wrapWithErrorBoundary(SimpleScatterCard, "Failed to Render Simple Scatter Card", () => {}, "", ""),
  },
  [EDataViewTypes.SIMPLE_BAR]: {
    id: EDataViewTypes.SIMPLE_BAR,
    Component: wrapWithErrorBoundary(SimpleBarCard, "Failed to Render Simple Bar Card", () => {}, "", ""),
  },
  [EDataViewTypes.PIE_CHART]: {
    id: EDataViewTypes.PIE_CHART,
    Component: wrapWithErrorBoundary(PieChartCard, "Failed to Render Pie Chart Card", () => {}, "", ""),
  },
};

export const cardComponentOpenTypeMap: CardComponentTypeMap<EDataViewTypes, IDataViewCardProps> = {
  [EDataViewTypes.SIMPLE_PLOT]: {
    id: EDataViewTypes.SIMPLE_PLOT,
    Component: wrapWithErrorBoundary(SimplePlotCardExpanded, "Failed to Render Simple Plot Card", () => {}, "", ""),
  },
  [EDataViewTypes.BUBBLE_PLOT]: {
    id: EDataViewTypes.BUBBLE_PLOT,
    Component: wrapWithErrorBoundary(BubblePlotCardExpanded, "Failed to Render Bubble Plot Card", () => {}, "", ""),
  },
  [EDataViewTypes.DATA_DOWNLOAD]: {
    id: EDataViewTypes.DATA_DOWNLOAD,
    Component: wrapWithErrorBoundary(DataDownloadCardExpanded, "Failed to Render Data Download Card", () => {}, "", ""),
  },
  [EDataViewTypes.GAUGE_CHART]: {
    id: EDataViewTypes.GAUGE_CHART,
    Component: wrapWithErrorBoundary(GaugeChartCardExpanded, "Failed to Render Gauge Chart Card", () => {}, "", ""),
  },
  [EDataViewTypes.SIMPLE_SCATTER]: {
    id: EDataViewTypes.SIMPLE_SCATTER,
    Component: wrapWithErrorBoundary(
      SimpleScatterCardExpanded,
      "Failed to Render Simple Scatter Card",
      () => {},
      "",
      ""
    ),
  },
  [EDataViewTypes.SIMPLE_BAR]: {
    id: EDataViewTypes.SIMPLE_BAR,
    Component: wrapWithErrorBoundary(SimpleBarCardExpanded, "Failed to Render Simple Bar Card", () => {}, "", ""),
  },
  [EDataViewTypes.PIE_CHART]: {
    id: EDataViewTypes.PIE_CHART,
    Component: wrapWithErrorBoundary(PieChartCardExpanded, "Failed to Render Pie Chart Card", () => {}, "", ""),
  },
};
