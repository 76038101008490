import { UserGroup } from "../lib/User/UserGroup";
import { useAppSelector } from "../Redux/hooks";

import { SignInPanel, SignInScreen, SignInPanelWrap, FullAuthPanelWrap } from "../styles/auth";
import { CTALinkButtonStyle } from "../styles/links";

export const YouNeedToActivateYourMonitorPanel = () => {
  const { user } = useAppSelector((state) => state.userState);
  const isAdmin = user?.group === UserGroup.SCHOOLADMIN || user?.group === UserGroup.SUPERADMIN;
  if (isAdmin)
    return (
      <>
        <p style={{ marginBottom: "1rem" }}>
          <b>Monitor linking incomplete!</b>
        </p>
        <CTALinkButtonStyle to="/setup/monitor">Please go to the Monitor Link page</CTALinkButtonStyle>{" "}
        <p style={{ marginTop: "1rem" }}> ...or choose a different monitor from the dropdown in the header.</p>
      </>
    );
  return (
    <p>
      Monitor linking incomplete! Please ask your school's admin user to log in and link this monitor using the Monitor
      Link page or choose a different monitor using the dropdown in the header
    </p>
  );
};

export const RequiresActivatedMonitor = ({ children }) => {
  const monitorIsActivated = useAppSelector((state) => state.userState.state.monitor?.data?.activated);
  const monitorHasRoom = useAppSelector((state) => state.userState.state.monitor?.data?.room);
  const requiresRoom = useAppSelector((state) => state.userState.state.school?.data?.disallowMoveYourMonitor);
  const isReady = monitorIsActivated && (!requiresRoom || monitorHasRoom);

  if (isReady) return children;

  return (
    <SignInScreen>
      <FullAuthPanelWrap>
        <div style={{ position: "relative", height: "fit-content" }}>
          <SignInPanelWrap>
            <SignInPanel data-testid="signInPanel">
              <YouNeedToActivateYourMonitorPanel />
            </SignInPanel>
          </SignInPanelWrap>
        </div>
      </FullAuthPanelWrap>
    </SignInScreen>
  );
};
