import React from "react";
import { useNavigate } from "react-router-dom";
import { CardBoard } from "../../GenericComponents/CardBoard";
import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
import { generateFiltersFromFieldsExtended } from "../../GenericComponents/CardBoard/utils";
import { LoadingPanel } from "../../GenericComponents/LoadingPanel";
import { DataViewFields } from "../../lib/DataViews/IDataView";
import { cardComponentTypeMap } from "./cardComponentMap";
import { useGetDataViewsMetaData } from "./useGetDataViewMetaData";
import { IDataViewCardData } from "./IDataViewCardProps";
import { useAppSelector } from "../../Redux/hooks";
import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
import { AppCardboardWrap, AppInfoSection, AppSectionHeading } from "../../styles/app";
import { LinkExternal } from "../Links";
import { SelectDropdown } from "../../GenericComponents/FormElements/Select";
import { FilterStateContext, FilterStateProvider } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { filterDataViewCards } from "./dataViewCardFilter";
import { DataViewDashboardKey } from "./DataViewKey";

const initialFilterData = generateFiltersFromFieldsExtended(DataViewFields);

const JumpToDataViewSelect = ({ dataviews }: { dataviews: { id: string | number; label: string }[] }) => {
  const navigate = useNavigate();
  return (
    <p>
      <label htmlFor="jumpToDataViewSelect">Jump to data view</label>:{" "}
      <SelectDropdown
        selectOptions={[
          { id: "", label: "Use dropdown to make dataview selection" },
          ...dataviews.sort((a, b) => a.label.localeCompare(b.label)).map((a) => ({ id: a.id, label: a.label })),
        ]}
        id="jumpToDataViewSelect"
        value={""}
        onChange={(v) => navigate(`/app/data/${v}`)}
        style={{ width: "auto", maxWidth: "100%", background: "white", marginTop: 0 }}
      />
    </p>
  );
};

const FilteredCardsContainer = ({
  breakpoints,
  cards: cardsData,
}: {
  breakpoints: { default: number; [key: number]: number } | number;
  cards: IDataViewCardData[];
}) => {
  const { filters } = React.useContext(FilterStateContext);
  const filteredCards = React.useMemo(() => cardsData.filter(filterDataViewCards(filters)), [cardsData, filters]);
  const updatedCardsData = React.useMemo(() => {
    const downloadCardIndices = filteredCards.reduce((indices, item, index) => {
      if (item.label === "Download Data" || item.label === "Mega data download") {
        indices.push(index);
      }
      return indices;
    }, []);

    const downloadCards = downloadCardIndices.map((index) => filteredCards.slice(index, index + 1)[0]);
    const filteredCardsData = filteredCards.filter((item, index) => !downloadCardIndices.includes(index));
    return [...filteredCardsData, ...downloadCards];
  }, [filteredCards]);
  const cardsCompiled = useGetCards({ cardsData: updatedCardsData, cardComponentTypeMap, filterHiddenCards: true });
  return <CardBoard breakpointColumns={breakpoints}>{[...cardsCompiled]}</CardBoard>;
};

export const DataDashboard = () => {
  const {
    state: { monitor: { id: monitorId } = { id: null } },
  } = useAppSelector((state) => state.userState);
  const [containerRef, breakpoints] = useGetBreakpoints(1.5);

  const {
    cards: cardsData,
    loadingViews,
    error,
  } = useGetDataViewsMetaData({ monitorId, groupId: "appDataDashboardCards" });

  return (
    <FilterStateProvider fieldsData={DataViewFields} initialFilterData={initialFilterData}>
      <div ref={containerRef}>
        <LoadingPanel open={loadingViews} message="Loading Cards" />
        {error && <p>Error: {error}</p>}
        <AppInfoSection>
          <AppSectionHeading>SAMHE Web App - Data Views</AppSectionHeading>
          <p>
            Use these data views to explore data from your SAMHE monitor in more detail. To understand what the numbers
            mean, take a look at our{" "}
            <LinkExternal to="/resources/monitor-leds">guidance on interpreting monitor readings</LinkExternal>.
          </p>
        </AppInfoSection>
        <AppInfoSection style={{ padding: "1rem", paddingBottom: "0.5rem", marginBottom: "0.5rem" }}>
          <DataViewDashboardKey />
        </AppInfoSection>

        <AppInfoSection style={{ padding: "0.5rem", paddingLeft: "1rem", marginBottom: "3rem" }}>
          <JumpToDataViewSelect dataviews={cardsData} />
        </AppInfoSection>
        <AppCardboardWrap>
          <FilteredCardsContainer breakpoints={breakpoints} cards={cardsData} />
        </AppCardboardWrap>
      </div>
    </FilterStateProvider>
  );
};
