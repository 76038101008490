import { Section, P } from "../../styles/page";
import styled from "styled-components";
import { WebAppFooterLogo, LogoWrap } from "../../styles/header";
import { TextFormatter } from "../TextFormatter";
import { keywordMap } from "../../dummyData/keywords";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faSmog, faDesktop, faWifi } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { VERSION } from "../../config";
import { Tooltip } from "react-tooltip";

const FooterStyle = styled.footer`
  background-color: ${({ theme }) => theme.colors.baseBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LinkBar = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.shape.defaultPaddingMid};
  h3 {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
    color: ${({ theme }) => theme.colors.link};
  }
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
  }
`;

const BottomFooterStyle = styled.div`
  background-color: transparent;
  padding-top: ${({ theme }) => theme.shape.defaultPadding};
  padding-bottom: ${({ theme }) => theme.shape.defaultPadding};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
`;

export const ResourceHubWrapper = styled(Section)`
  display: grid; // Using grid layout
  gap: 2rem;

  // By default (for mobile), it's a single column
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    // For tablets, make it 2 columns (2x2 grid)
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    // For laptops and larger screens, make it 4 columns (1x4 grid)
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const QuickLink = styled.div`
  border-radius: 2rem;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  position: relative;
  text-align: center;
  background-color: ${(props) => props.theme.colors.boxoutBackground};
  p,
  svg,
  button {
    color: ${({ theme }) => theme.background};
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  }
  button {
    font-size: ${({ theme }) => theme.typography.fontSize4};
    text-align: center;
    margin-bottom: 0.7rem;
  }
  svg {
    font-size: ${({ theme }) => theme.shape.defaultPaddingLarger};
    margin-top: ${({ theme }) => theme.shape.defaultPaddingMid};
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }

  .glossary-tooltip {
    opacity: 1;
    background: ${({ theme }) => theme.background};
    border: 1px solid ${({ theme }) => theme.colors.main};
    z-index: 100;
    padding: 1rem;
    box-shadow: 3px 0 3px 2px rgba(0, 0, 0, 0.2);
  }
`;

export const AppFooter = () => {
  return (
    <FooterStyle className="footer">
      <LinkBar>
        <h3>
          <b>Here are some quick links to key documents for your reference:</b>
        </h3>
        <ResourceHubWrapper>
          <QuickLink>
            <FontAwesomeIcon style={{ color: "#d3eea4" }} icon={faKey} />
            {/* Glossary link is not added yet */}
            <div>
              <h3 style={{ color: "white", marginBottom: "0.7rem", textDecoration: "underline" }}>
                <Link
                  to="/resources/glossary"
                  style={{ color: "white", fontWeight: "400" }}
                  data-tooltip-id="glossary-tooltip"
                  data-tooltip-html="<a href='/resources/glossary'><img src='/images/glossary/glossary.jpg' /></a>"
                >
                  Glossary
                </Link>
                <Tooltip id="glossary-tooltip" noArrow className="glossary-tooltip" clickable />
              </h3>
              <P>Our glossary provides definitions for key terms used throughout the SAMHE Web App.</P>
            </div>
          </QuickLink>
          <QuickLink>
            <FontAwesomeIcon style={{ color: "#a5e6ef" }} icon={faSmog} />
            <div>
              <TextFormatter text={"!About Air Pollution!"} keywordMap={keywordMap} />
              <P>Find out why air pollution is a problem and what you can do about it at your school.</P>
            </div>
          </QuickLink>
          <QuickLink>
            <FontAwesomeIcon style={{ color: "#f6aa06" }} icon={faDesktop} />
            <div>
              <TextFormatter text={"!Guidance on monitor readings!"} keywordMap={keywordMap} />
              <P>
                Use our guidance on monitor readings to put the numbers on your SAMHE monitor into context and find out
                what they mean for you.
              </P>
            </div>
          </QuickLink>
          <QuickLink>
            <FontAwesomeIcon style={{ color: "#d3dafe" }} icon={faWifi} />
            <div>
              <TextFormatter text={"!Where to put your monitor!"} keywordMap={keywordMap} />
              <P>
                Use our guidance on monitor placement to decide where to put your monitor and ensure accurate readings.
              </P>
            </div>
          </QuickLink>
        </ResourceHubWrapper>
      </LinkBar>
      <BottomFooterStyle>
        <LogoWrap style={{ width: 0 }}>
          <WebAppFooterLogo>
            <div style={{ maxWidth: "4rem" }}>
              <img src="/images/logo/samhe_webapp_footer_small.png" alt="SAMHE web app" height="100%"></img>
            </div>
          </WebAppFooterLogo>
        </LogoWrap>
        <p>App Version: {VERSION}</p>
        <div style={{ display: "block", height: "1rem", content: "" }} />
      </BottomFooterStyle>
    </FooterStyle>
  );
};
