import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { getNextStep } from "../../nextStepUtils";

export interface IInfoExtraProps {
  content: string;
}

export interface IInfoState {
  read?: boolean;
}

export const InfoStep = (props: IStepProps<IInfoExtraProps, IInfoState>) => {
  const { description, additionalProps, onSubmit, nextSteps, previousStepsState, activitySteps } = props;
  const handleSubmit = () => {
    const nextStepId = null;
    const state = { read: true };
    const nextStepRef = getNextStep(nextStepId, nextSteps, previousStepsState, activitySteps, props, {
      ...props,
      state,
    });
    onSubmit({ newValue: state, nextStep: nextStepRef });
  };
  return (
    <>
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      {additionalProps?.content && <TextFormatter text={additionalProps?.content} keywordMap={keywordMap} />}
      <p>
        <CTAButtonStyle onClick={() => handleSubmit()}>Ok</CTAButtonStyle>
      </p>
    </>
  );
};
