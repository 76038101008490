import React from "react";
import { useManageFilters } from "@react_db_client/components.filter-manager";
import { CardBoard } from "../../GenericComponents/CardBoard";
import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
import { useGetBreakpoints } from "../../GenericComponents/CardBoard/useGetBreakpoints";
import { AppCardboardWrap } from "../../styles/app";
import { useGetResourcesMetaData } from "./useGetResourcesMetaData";
import { cardComponentTypeMap } from "./resourceCardComponentMap";
import styled from "styled-components";

const fieldsData = {};
const initialFilterData = [];

const AppResourceDashboardWrap = styled.div`
  p,
  ul {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
  ul {
    padding-left: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
`;

export const AppResourceDashboard: React.FC = () => {
  const filtersManager = useManageFilters({ fieldsData, initialFilterData });
  const [containerRef, breakpoints] = useGetBreakpoints();
  const cardsData = useGetResourcesMetaData({ filters: filtersManager.filters });
  const cardsCompiled = useGetCards({ cardsData, cardComponentTypeMap });

  return (
    <AppResourceDashboardWrap ref={containerRef}>
      {/* <AppInfoSection> */}
      {/* <CardBoardMenu fields={AchievementViewFields} filtersManager={filtersManager} /> */}
      {/* <ButtonStyle onClick={() => dispatch(reduxLoadActivities,filtersManager.filters)}>Reload</ButtonStyle> */}
      {/* </AppInfoSection> */}
      <AppCardboardWrap>
        {cardsCompiled?.length > 0 && <CardBoard breakpointColumns={breakpoints}>{cardsCompiled}</CardBoard>}
      </AppCardboardWrap>
    </AppResourceDashboardWrap>
  );
};
