import { EChartsOption, XAXisComponentOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { IBucketRange, usePreprocessBarData } from "./preProcessData";
import { AggregationIntervals, EAggregationInterval } from "../../../lib/MonitorData/AggregationIntervals";

export interface ISimpleBarProps {
  data: IMonitorDataItem<number>[];
  yLabel: string;
  xLabel: string;
  aggregationInterval: EAggregationInterval;
  bucketRanges: IBucketRange[];
  compact?: boolean;
}

export const constructAxis = (xAxisName: string, fontSize: string | number, dateFormatter): XAXisComponentOption => {
  return {
    type: "time",
    axisLabel: {
      margin: 7,
      formatter: dateFormatter,
    },
    name: xAxisName,
    nameLocation: "middle",
    nameGap: 50,
    nameTextStyle: {
      fontSize,
      fontWeight: "bold",
    },
  };
};

export const SimpleBar = ({ data, yLabel, xLabel, bucketRanges, aggregationInterval, compact }: ISimpleBarProps) => {
  const dataProcessed = usePreprocessBarData(data, bucketRanges, aggregationInterval);
  const colors = bucketRanges.reduce((acc, c) => ({ ...acc, [c.label]: c.color }), {});
  const chartOptions: EChartsOption = {
    responsive: true,
    grid: {
      containLabel: true,
      left: 50,
    },
    tooltip: {
      trigger: "axis",
      position: "top",
      formatter: (params) => {
        const [name, value] = params[0].data;
        const bucketRange = bucketRanges.find((b) => b.label === name);
        return `<b>${name}</b><br> <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
          bucketRange.color
        };"></span>${value.toFixed(2).replace(".00", "")}/${(
          (data.length * AggregationIntervals[aggregationInterval]?.ratio) /
          60
        )
          .toFixed(2)
          .replace(".00", "")} ${yLabel}`;
      },
    },
    dataset: {
      dimensions: ["name", "value"],
      source: dataProcessed,
    },
    xAxis: {
      name: compact ? "" : xLabel,
      nameLocation: "middle",
      nameGap: 40,
      type: "category",
      nameTextStyle: {
        fontSize: "1rem",
      },
      axisLabel: {
        interval: 0,
        fontSize: "0.8rem",
        rotate: compact ? 45 : undefined,
      },
    },
    yAxis: {
      name: yLabel,
      nameLocation: "middle",
      nameGap: 40,
      axisLabel: {
        fontSize: "0.8rem",
        formatter: (value: number) => {
          return `${value.toFixed(0)}`;
        },
      },
      nameTextStyle: {
        fontSize: "1rem",
      },
      interval: Math.max(...dataProcessed.map(([_, v]) => v as number)) < 5 ? 1 : undefined,
    },
    series: [
      {
        data: dataProcessed,
        type: "bar",
        itemStyle: {
          color: function (param) {
            return colors[param.value[0]];
          },
        },
        encode: {
          x: 0,
          y: 1,
        },
        tooltip: {
          valueFormatter: (value: number) => `${value.toFixed()} ${yLabel}`,
        },
      },
    ],
  };
  return <ReactECharts key={Date.now()} option={chartOptions} style={{ width: "100%", height: "30rem" }} />;
};
