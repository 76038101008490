import React, { useState } from "react";
import styled from "styled-components";
import { CTAButtonStyle } from "../../styles/button";

export const ScrollTopButtonWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 1rem;
  padding: 1rem;

  & > button {
    padding: 0.3rem 1rem;
  }
`;

const ScrollTopButton = () => {
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    // element that actually scrolling
    const wrapper = document.getElementById("mainContent_wrap");
    if (!wrapper) return;
    const toggleVisible = () => {
      const scrolled = wrapper.scrollTop;
      if (scrolled > 600) {
        setVisible(true);
      } else if (scrolled <= 600) {
        setVisible(false);
      }
    };

    wrapper.addEventListener("scroll", toggleVisible, false);

    // cleanup this component
    return () => {
      wrapper.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const scrollToTop = () => {
    document.getElementById("mainContent_wrap")?.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <ScrollTopButtonWrapper className="scroll-top-button-wrapper">
      <CTAButtonStyle style={{ display: visible ? "inline" : "none" }} onClick={scrollToTop} type="button">
        BACK TO TOP
      </CTAButtonStyle>
    </ScrollTopButtonWrapper>
  );
};

export default ScrollTopButton;
