import { AExternal } from "../Links";
import { NewsDate, NewsEntryStyle, NewsEntryText, NewsEntryTitle } from "./styles";

export interface INewsEntryProps {
  title: string;
  date: string;
  text: React.ReactNode;
  link?: string;
}

export const NewsEntry: React.FC<INewsEntryProps> = ({ title, date, text, link }) => {
  return (
    <NewsEntryStyle>
      <NewsEntryTitle>
        {link ? (
          <AExternal style={{ textDecoration: "none" }} href={link}>
            {title}
          </AExternal>
        ) : (
          title
        )}
      </NewsEntryTitle>
      <NewsDate>{date}</NewsDate>
      <NewsEntryText>{text}</NewsEntryText>
    </NewsEntryStyle>
  );
};
