import { quizExample, quizExampleUserProgress } from "../components/ActivitiesDashboard/ActivityTypes/Quiz/examples";
import {
  shortActivity,
  shortActivityUserProgress,
  simpleActivity,
  simpleActivityUserProgress,
} from "../components/ActivitiesDashboard/ActivityTypes/StepByStep/examples";
import { AchievementCategories, EAchievementCategory } from "../lib/Achievements/EAchievementCategory";
import { EEquipmentNeeded } from "../lib/Activities/EEquipmentNeeded";
import { EThemes } from "../lib/Activities/EThemes";
import {
  EActivityStatus,
  EActivityTypes,
  IActivity,
  IActivityUser,
  IActivityUserStarted,
} from "../lib/Activities/IActivity";
import { DEMO_DATADETECTIVE_ACTIVITIES, DEMO_USER_DATADETECTIVE_ACTIVITIES } from "./dataDetectives";

export * from "../components/ActivitiesDashboard/ActivityTypes/StepByStep/examples";
export * from "../components/ActivitiesDashboard/ActivityTypes/Quiz/examples";

export const dummyActivity: IActivity = {
  id: "dummyActivity",
  label: "Dummy activity",
  description: "This is a test activity for the dummy activity button",
  longDescription: "This is the long description of a test activity for the dummy activity button",
  equipmentNeeded: [EEquipmentNeeded.ANY],
  type: EActivityTypes.TEST,
  activitySteps: [],
  theme: EThemes.B,
  activityScores: [
    {
      category: AchievementCategories[EAchievementCategory.QUIZ],
      score: 100,
    },
  ],
  nextActivityIds: [],
  previousActivityIds: [],
};

export const exampleLockedActivity: IActivity = {
  ...simpleActivity,
  id: "locked-activity",
  label: "Locked Activity",
  description: "This is a test activity for the locked activity functionality",
  type: EActivityTypes.STEP_BY_STEP,
  previousActivityIds: [
    simpleActivity.id,
  ]
};

export const DEMO_ACTIVITIES: Record<string | number, IActivity> = {
  exampleLockedActivity,
  simpleActivity,
  dummyActivity: dummyActivity as IActivity,
  ...DEMO_DATADETECTIVE_ACTIVITIES,
  quizExample,
  shortActivity,
};

const dummyActivityUser: IActivityUser = {
  ...dummyActivity,
  activityUserProgress: {
    id: 999,
    activityId: dummyActivity.id,
    activityStatus: EActivityStatus.NOT_STARTED,
    dateCreated: new Date().toISOString(),
    dateModified: new Date().toISOString(),
    stepsState: [],
    activeStepId: 0,
    previousSteps: [],
    attemptNo: 3,
  },
};

export const exampleLockedActivityUser: IActivityUser = {
  ...exampleLockedActivity,
  activityUserProgress: {
    activityId: exampleLockedActivity.id,
    activityStatus: EActivityStatus.NOT_STARTED,
    isLocked: true,
  },
};

export const DEMO_USER_ACTIVITIES: Record<string | number, IActivityUser | IActivityUserStarted> = {
  exampleLockedActivity: {
    ...exampleLockedActivity,
    activityUserProgress: exampleLockedActivityUser.activityUserProgress,
  },
  simpleActivity: { ...simpleActivity, activityUserProgress: simpleActivityUserProgress },
  dummyActivity: {
    ...dummyActivityUser,
    activityUserProgress: { ...dummyActivityUser.activityUserProgress },
  },
  ...DEMO_USER_DATADETECTIVE_ACTIVITIES,
  quizExample: { ...quizExample, activityUserProgress: quizExampleUserProgress },
  shortActivity: { ...shortActivity, activityUserProgress: shortActivityUserProgress },
};
