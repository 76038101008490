import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useCookie } from "react-use";
import styled from "styled-components";
import { CTAButtonStyle } from "../../styles/button";

export const CookieSnackbarStyleWrap = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
`;

export const CookieSnackbarStyle = styled.div`
  margin: auto;
  padding: 1rem;
  background: ${({ theme }) => theme.background};
  max-width: 900px;
  border: 1px solid ${({ theme }) => theme.colors.main};
  border-radius: 3rem;
  display: flex;
  justify-content: space-around;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const getPortalRoot = (rootid) => {
  let portalRoot = document.getElementById(rootid);
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", rootid);
    document.body.appendChild(portalRoot);
  }
  if (!portalRoot) throw Error("Could not create portal root");
  return portalRoot;
};

export const CookieSnackbar = () => {
  const [value, updateCookie] = useCookie("SAMHE_ACCEPT_COOKIES");
  const root = React.useMemo(() => getPortalRoot("CookieSnackbarRoot"), []);

  return ReactDOM.createPortal(
    <CookieSnackbarStyleWrap style={{ display: !value ? "inherit" : "none", zIndex: 99999 }}>
      <CookieSnackbarStyle>
        <Link to="/cookies">Cookie Policy</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <CTAButtonStyle onClick={() => updateCookie("true")}>Accept Cookies</CTAButtonStyle>
      </CookieSnackbarStyle>
    </CookieSnackbarStyleWrap>,
    root
  );
};
