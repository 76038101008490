/* This is the Room selection step. It changes the monitor and/or room selection.

There are

| shouldUpdateMonitorLocationFlag | Multi Monitor Static | Single Movable Monitor
--------------------------------------------------------------------------------
| True                            | A                    | B
| False                           | C                    | D


A - Header monitor selection is updated but we don't need to call the api
B - Header monitor selection is NOT updated and we need to call the api
C - Header monitor selection is updated but we don't need to call the api
D - Header not updated and we don't call api.

In all scenarios we save the monitor/location selection in the submitted
Step data.

*/

import React from "react";
import styled from "styled-components";
import { AuthContext } from "react-oauth2-code-pkce";
import { Link } from "react-router-dom";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { StepDescription } from "../../../../styles/app";
import { FormStyle } from "../../../../styles/form";
import { MultiCheckboxB } from "../../../FormComponents/MultiCheckbox";
import { TextFormatter } from "../../../TextFormatter";
import { IMultiCheckboxOption } from "../../../../lib/Forms/IMultiCheckboxOption";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import {
  LocalLocationSelectionDropdown,
  MonitorSelectionDropdown,
} from "../../../MonitorLocationSelection/monitorLocationSelectionDropdowns";
import { EMonitorChangeSource } from "../../../../lib/Monitor/IMonitor";
import { IRoomListItem } from "../../../../lib/School/IRoom";
import { reduxSwitchRoomAsync } from "../../../../Redux/User/userReducer";
import { DEV_MODE } from "../../../../config";

export interface IRoomSelectionStepExtraProps {
  shouldUpdateMonitorLocation: boolean;
}

export interface IRoomSelectionStepState {
  value: IMultiCheckboxOption;
  roomSelection?: IRoomListItem;
}

export const DropdownWrap = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  select {
    background: ${({ theme }) => theme.colors.main};
    color: white;
  }
  label {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const RoomSelectionStep = ({
  id,
  state,
  description,
  additionalProps: { shouldUpdateMonitorLocation },
  activityId,
  onSubmit,
}: Partial<IStepProps<IRoomSelectionStepExtraProps, IRoomSelectionStepState>>) => {
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const currentRoomMonitor = useAppSelector((state) => state.userState.state.monitor?.data?.room);
  const dissallowMoveYourMonitor = useAppSelector(
    (state) => state.userState.state.school?.data?.disallowMoveYourMonitor
  );

  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const [selectedRoom, setSelectedRoom] = React.useState<IRoomListItem | null>(
    state?.value?.uid === "confirm" ? state?.roomSelection : currentRoom || currentRoomMonitor
  );
  const fullOptions = [
    { uid: "confirm", label: "Accept selection" },
    { uid: "unknown", label: "I don't know" },
    { uid: "other", label: "The room is not in the list" },
  ];

  const [selectedOption, setSelectedOption] = React.useState(state?.value || null);

  React.useEffect(() => {
    /* If monitor id changes we reset the selected option and room to allow resubmitting */
    if (shouldUpdateMonitorLocation || dissallowMoveYourMonitor) {
      setSelectedRoom(currentRoomMonitor);
      setSelectedOption(null);
    }
  }, [monitorId, currentRoomMonitor, shouldUpdateMonitorLocation, dissallowMoveYourMonitor]);

  const handleChange = (v: boolean[]) => {
    // Note we are sent a boolean list where the selection is true
    const value = fullOptions.find((o, i) => v[i]);
    if (value?.uid === "unknown") {
      setSelectedOption(value);
      onSubmit({ newValue: { value }, monitorIdOverride: monitorId });
    } else if (value?.uid === "other") {
      setSelectedOption(value);
      onSubmit({ newValue: { value }, monitorIdOverride: monitorId });
    } else if (value?.uid === "confirm") {
      if (!selectedRoom) alert("Please select a room");
      const isMultiMonitorStaticSchool = dissallowMoveYourMonitor; // Currently these are the same
      setSelectedOption(value);
      onSubmit({
        newValue: { value: value, roomSelection: selectedRoom },
        roomIdOverride: selectedRoom?.id,
        monitorIdOverride: monitorId,
      });

      if (isMultiMonitorStaticSchool) {
        // Route A and C
        // For multi monitor schools we always change the monitor selection
        // This is handled in the monitor selection dropdown
      } else {
        if (shouldUpdateMonitorLocation) {
          // Route B
          // We update the monitor location here.
          dispatch(reduxSwitchRoomAsync(token, monitorId, selectedRoom, EMonitorChangeSource.ACTIVITY, activityId));
        } else {
          // Route D
          // Do nothing as we don't need to update the monitor location
        }
      }

      // if (shouldUpdateMonitorLocation && dissallowMoveYourMonitor) {
      //   /* Only switch the monitor selection  */
      //   dispatch(switchRoom(selectedRoom));
      // }
      // if (shouldUpdateMonitorLocation && !dissallowMoveYourMonitor) {
      //   dispatch(reduxSwitchRoomAsync(token, monitorId, selectedRoom, EMonitorChangeSource.ACTIVITY, activityId));
      // }
    }
  };

  return (
    <FormStyle
      aria-label={`multiChoiceStep-${id}`}
      name={`multiChoiceStep-${id}`}
      alignment="flex-start"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      {DEV_MODE && shouldUpdateMonitorLocation && (
        <p>
          <b>This step is requesting to change monitor location</b>
        </p>
      )}
      {!currentRoom && currentRoomMonitor ? (
        <p>
          Your monitor is currently assigned to <strong>{currentRoomMonitor.label}</strong>.
        </p>
      ) : (
        ""
      )}
      {currentRoom ? (
        <p>
          Your monitor is currently assigned to <strong>{currentRoom.label}</strong>.
        </p>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "1rem" }}>
        If you can't find your room in the dropdown menu please ask your teacher to add it to your school's room list.
        This needs to be done by your school's Admin user via the <Link to="/school/admin">School Admin page</Link>.
      </p>
      <DropdownWrap>
        <MonitorSelectionDropdown />
      </DropdownWrap>
      <br />
      <DropdownWrap data-testid="locationSelectionWrap">
        <LocalLocationSelectionDropdown
          roomSelection={selectedRoom}
          onChangeRoom={(r) => {
            setSelectedRoom(r);
            setSelectedOption(null);
          }}
        />
      </DropdownWrap>

      <MultiCheckboxB
        name="selection"
        options={fullOptions}
        value={fullOptions.map((o) => o.uid === selectedOption?.uid)}
        // value={fullOptions.map((o) => o.uid === selectedOption?.uid)}
        onChange={handleChange}
      />
    </FormStyle>
  );
};
