import React from "react";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";
import { ContactPrivacyPolicy } from "../components/Documents/ContactPrivacyPolicy";

const ContactPrivacyPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE Contact Privacy Policy</PageTitle>
      <ContactPrivacyPolicy />
    </ConstrainedPageStyle>
  );
};

export default ContactPrivacyPage;
