import { Link, useLocation } from "react-router-dom";
import {
  ConstrainedPageStyle,
  P,
  PageTitle,
  Section,
  SectionBackground,
  TableOfContents,
  TableOfContentsWrapper,
  Ul,
} from "../styles/page";
import {
  FaqGroupStyle,
  FaqGroupWrapStyle,
  FaqItemContentStyle,
  FaqItemHeadingStyle,
  FaqItemStyle,
  FaqItemWrapStyle,
} from "../styles/faq";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { BoxoutContactStyle } from "../styles/hero";
import { AExternal } from "../components/Links";

enum ECurriculumLinkGroup {
  ENGLAND = "ENGLAND",
  SCOTLAND = "SCOTLAND",
  WALES = "WALES",
  NORTHERN_IRELAND = "NORTHERN_IRELAND",
  EPQ = "EPQ",
}

interface ICurriculumLinkGroup {
  uid: ECurriculumLinkGroup;
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
  label_short?: string;
}

const CurriculumLinkGroups: Record<ECurriculumLinkGroup, ICurriculumLinkGroup> = {
  [ECurriculumLinkGroup.ENGLAND]: {
    uid: ECurriculumLinkGroup.ENGLAND,
    label: "England",
    description: (
      <>
        <P>
          Curriculum links documented for schools in England relate to the{" "}
          <AExternal href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/381344/Master_final_national_curriculum_28_Nov.pdf">
            National Curriculum in England, Framework Document (December 2014)
          </AExternal>{" "}
          and DfE's{" "}
          <AExternal href="https://assets.publishing.service.gov.uk/media/5a7f273a40f0b62305b85670/GCE_AS_and_A_level_subject_content_for_mathematics_with_appendices.pdf">
            Mathematics AS and A level Content Guide (2016)
          </AExternal>
          .
        </P>
      </>
    ),
  },
  [ECurriculumLinkGroup.SCOTLAND]: {
    uid: ECurriculumLinkGroup.SCOTLAND,
    label: "Scotland",
    description: (
      <>
        <P>
          Curriculum links documented for schools in Scotland are based on the following document unless otherwise
          stated:{" "}
          <AExternal href="https://education.gov.scot/media/wpsnskgv/all-experiencesoutcomes18.pdf">
            Curriculum for Excellence: Experiences and Outcomes
          </AExternal>
          .
        </P>
      </>
    ),
  },
  [ECurriculumLinkGroup.WALES]: {
    uid: ECurriculumLinkGroup.WALES,
    label: "Wales",
    description: (
      <>
        <P>
          Curriculum links documented for schools in Wales are based variously on the following documents:{" "}
          <AExternal href="https://hwb.gov.wales/api/storage/e2aad4fc-faae-4054-a382-242d08ee2021/programme-of-study-for-mathematics.pdf">
            Curriculum for Wales: Programme of Study for Mathematics, Key Stages 2-4 (2016)
          </AExternal>
          ,{" "}
          <AExternal href="https://hwb.gov.wales/api/storage/779c7300-574d-4a12-a518-c873557d6a7a/science-in-the-national-curriculum.pdf">
            Key Stages 2-4:Science in the National Curriculum for Wales (2008)
          </AExternal>{" "}
          and{" "}
          <AExternal href="https://hwb.gov.wales/api/storage/6fce280a-8132-4969-b821-a7313a2be719/geography-in-the-national-curriculum.pdf">
            Key Stages 2-3: Geography in the National Curriculum for Wales (2008)
          </AExternal>
          .
        </P>
      </>
    ),
  },
  [ECurriculumLinkGroup.NORTHERN_IRELAND]: {
    uid: ECurriculumLinkGroup.NORTHERN_IRELAND,
    label: "Northern Ireland",
    description: (
      <>
        <P>
          Curriculum links documented for Northern Ireland relate to the following documents / CCEA pages:{" "}
          <AExternal href="https://ccea.org.uk/downloads/docs/ccea-asset/Resource/Communication%20Assessment%20Task%3A%20Writing%20to%20Persuade%20%28Levels%203-5%29.pdf">
            Communication Levels 3,4 and 5: Writing to persuade
          </AExternal>
          ,{" "}
          <AExternal href="https://ccea.org.uk/key-stages-1-2/curriculum/world-around-us">
            The World Around Us
          </AExternal>
          ,{" "}
          <AExternal href="https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Mathematics%20%282018%29/GCE%20Mathematics%20%282018%29-specification-Standard.pdf">
            CCEA GCE Specification in Mathematics
          </AExternal>
          ,{" "}
          <AExternal href="https://ccea.org.uk/key-stage-3/curriculum/learning-life-work/local-global-citizenship">
            Local and Global Citizenship
          </AExternal>{" "}
          and{" "}
          <AExternal href="https://ccea.org.uk/key-stage-3/curriculum/environment-society">
            Environment and Society
          </AExternal>
          .
        </P>
      </>
    ),
  },
  [ECurriculumLinkGroup.EPQ]: {
    uid: ECurriculumLinkGroup.EPQ,
    label: "Extended Project Qualification (EPQ) Further Research",
    label_short: "EPQs",
  },
};

interface ICurriculumLinkItem {
  uid: string;
  label: string | React.ReactNode;
  content: React.ReactNode;
  group: ECurriculumLinkGroup;
}

interface ICurriculumLinkGroupProps {
  uid: string;
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
  entries: ICurriculumLinkItem[];
  uidToShow?: string;
}

export interface ICurriculumLinkItemProps {
  data: ICurriculumLinkItem;
  open?: boolean;
}

export const CurriculumLinkItem = ({ data: { uid, label, content }, open = false }: ICurriculumLinkItemProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const [icon, setIcon] = React.useState(faCaretDown);
  return (
    <FaqItemStyle>
      <FaqItemHeadingStyle
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIcon(icon === faCaretDown ? faCaretUp : faCaretDown);
        }}
      >
        <h3 id={uid}>
          {label}
          <FontAwesomeIcon size="xl" style={{ float: "right", marginLeft: "0.5rem" }} icon={icon} />
        </h3>
      </FaqItemHeadingStyle>
      <FaqItemContentStyle isOpen={isOpen}>
        <div>{content}</div>
      </FaqItemContentStyle>
    </FaqItemStyle>
  );
};

const CurriculumLinkGroup: React.FC<ICurriculumLinkGroupProps> = ({ label, uid, entries, uidToShow, description }) => {
  return (
    <FaqGroupStyle>
      <h2 id={uid}>{label}</h2>
      {description}
      <FaqItemWrapStyle>
        {entries.map((curriculumLinkItem) => (
          <CurriculumLinkItem
            key={curriculumLinkItem.uid}
            data={curriculumLinkItem}
            open={uidToShow ? curriculumLinkItem.uid === uidToShow : false}
          />
        ))}
      </FaqItemWrapStyle>
    </FaqGroupStyle>
  );
};

const curriculumLinkEntries: ICurriculumLinkItem[] = [
  // England
  {
    uid: "data_logging_key_stage_2_science",
    label: "Data logging, Key stage 2, Science",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          The SAMHE monitor can be considered a 'data logger' and the data available from it through the Web App can be
          used to teach pupils about making careful and systematic observations using standard units.
        </P>
        <P>
          Equally, the Web App features several activities involving data logging as part of practical experiments,
          including, for example, our{" "}
          <Link to="/app/activities/19">
            CO<sub>2</sub> before and after exercise activity
          </Link>{" "}
          which encourages pupils to record and compare measurements of carbon dioxide in the classroom before and after
          physical activity. This activity guides pupils through the process of looking for changes and patterns,
          drawing simple conclusions, identifying new questions and making predictions. Pupils can also design their own
          experiments using the data recorded by the monitor as evidence.
        </P>
        <P>
          <AExternal href="https://www.bbc.co.uk/newsround/av/65818540">
            Watch pupils taking part in this activity.
          </AExternal>
        </P>
      </div>
    ),
  },
  {
    uid: "areas_and_volume_key_stage_2_mathematics",
    label: "Areas and volume, Key stage 2, Mathematics",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          Year 5 pupils learning to calculate and compare the area of rectangles will be able to practise their skills
          in the SAMHE Web App, and contribute data to our research, through a{" "}
          <Link to="/app/activities/18">
            guided data entry exercise on describing a classroom and key features of it
          </Link>{" "}
          including size of the classroom and the total area of windows and doors. In so doing, pupils will be able to
          see the practical value of the new skill they are learning and its real life application.
        </P>
        <P>
          This activity also involves some estimation skills (estimating the height of the classroom) which is used to
          provide information about the volume of the classroom.
        </P>
      </div>
    ),
  },
  {
    uid: "earth_and_atmosphere_key_stage_3_and_4_science_geography",
    label: "Earth and Atmosphere, Key stage 3 and 4, Science/Geography",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          In conjunction with their work on the Earth and Atmosphere topic in Key Stage 3 and 4 Chemistry pupils will
          look at atmospheric composition and the potential effects of, and mitigation strategies for, increased levels
          of carbon dioxide. As part of their investigation into this pupils may find various SAMHE resources useful
          including, for example, our <Link to="/resources/air-pollution">About Air Pollution page</Link> which could be
          used to support their understanding of the links between human activity, carbon dioxide production, human
          health and the environment. At the local level, pupils will be able to see the effects of human activities
          both indoors and outdoors via their SAMHE monitor data.
        </P>
        <P>
          Similarly, in Key Stage 3 Geography pupils will be taught to understand how human and physical processes
          interact to influence and change landscapes, environments and the climate; and how human activity relies on
          effective functioning of natural systems. Pupils could consider how classroom activities affect the classroom
          environment as a small-scale case study in connection with this, and use our{" "}
          <Link to="/app/activities/24">Data Detectives activity</Link> to explore this. For example, use of materials
          that release a lot of VOCs, from hairspray to eating an orange nearby, would impact data readings. Pupils will
          learn how healthy classroom environments rely on ventilation to help them refresh the air and control the
          temperature.
        </P>
        <P>
          In addition, in Key Stage 4, pupils are tasked with understanding scientific processes by using different
          lines of scientific enquiry to help answer scientific questions about the world around them. In particular,
          using our Data Visualisations and/or Download Data feature, students can use their SAMHE data to form
          hypotheses and perform experiments on concentrations of CO<sub>2</sub>, and sources of common atmospheric
          pollutants, such as VOCs, and PM<sub>2.5</sub>, within the small-scale case study of their classroom.
        </P>
      </div>
    ),
  },
  {
    uid: "influencing_change_key_stage_3_and_4_citizenship",
    label: "Influencing change, Key stage 3 and 4, Citizenship",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          In Key Stages 3 and 4, pupils investigate the roles played by public institutions and community organisations,
          as well as learning more about the ways they, as members of a community, can influence positive change.
        </P>
        <P>
          Our <Link to="/app/activities/11">Write to Your MP</Link>,{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link>, and{" "}
          <Link to="/app/activities/32">Clean Air Zones debate</Link> activities could all support pupils exploring
          these topics by guiding them through the process of gathering data to support their views (including data
          gathered by their classroom's SAMHE monitor) and develop a persuasive argument taking into account their given
          audience ranging from fellow pupils to members of local government.
        </P>
      </div>
    ),
  },
  {
    uid: "health_disease_and_the_development_of_medicines_communicable_diseases_key_stage_4_biology",
    label: "Health, disease and the development of medicines: Communicable diseases, Key stage 4, Biology",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          Pupils learning about communicable diseases in Key Stage 4 Biology will cover pathogens, viral diseases and
          measures to prevent the spread of diseases. SAMHE is relevant to this because our monitors record the
          concentration of carbon dioxide indoors, which will rise when levels of shared/re-breathed air are high and
          can be used to indicate when ventilation is required, in turn helping to reduce the risk that airborne
          diseases are spread.
        </P>
        <P>
          <AExternal href="https://0okur.mjt.lu/nl3/Gn81G_jZgMcPgBFdBaPs_A">
            See an example of how one school designed a lesson investigating the benefits of ventilation and the
            potential for transmission of airborne infections.
          </AExternal>
        </P>
      </div>
    ),
  },
  {
    uid: "working_with_large_datasets_key_stage_5_a_level_mathematics_statistics",
    label: "Working with large datasets, Key stage 5 (A-level), Mathematics/Statistics",
    group: ECurriculumLinkGroup.ENGLAND,
    content: (
      <div>
        <P>
          All data recorded by a school's SAMHE monitor is available for export through the Web App as a csv file which
          can provide A-level Maths pupils with a useful alternative dataset to which they can apply data analysis,
          presentation and interpretation skills in Microsoft Excel or other data management software.
        </P>
        <P>
          An additional benefit of using the SAMHE data compared with the datasets provided by the exam boards is that
          the data is more relevant and meaningful to pupils as it relates to the classrooms they work in and they may
          well have directly influenced the readings.
        </P>
      </div>
    ),
  },
  // Scotland
  {
    uid: "measurement_3d_calculations_second_level_mathematics",
    label: "Measurement, 3D Calculations: Second Level, Mathematics",
    group: ECurriculumLinkGroup.SCOTLAND,
    content: (
      <div>
        <P>
          Our <Link to="/app/activities/18">Describe Your Room</Link> activity will provide pupils with real world
          examples to which they can apply their learning on how to calculate the area of 2D and 3D shapes as part of
          the 'measurement' focus under 'Number, Money, and Measure'. This activity guides pupils through the process of
          calculating the total area of windows and doors in the classroom as well as the volume of the classroom itself
          which will help make pupils' learning more tangible.
        </P>
      </div>
    ),
  },
  {
    uid: "processes_of_the_planet_third_level_sciences",
    label: "Processes of the Planet, Third Level, Sciences",
    group: ECurriculumLinkGroup.SCOTLAND,
    content: (
      <div>
        <P>
          SAMHE can support Third level pupils learning about climate change, the role of human activity in it and the
          impact of atmospheric change on living things by providing a micro-case study within the classroom to which
          they can apply their learning.
        </P>
        <P>
          Looking at classroom air quality provides interesting parallels as it too is affected by both human activity
          and natural sources and has associated health effects. The impact of activities ranging from traffic outside
          the school to <Link to="/resources/outputs#samhe-tech-report-january-2024">saharan desert dust storms</Link>,
          the use of cleaning or personal hygiene products, eating food and even just moving and breathing will be
          observable in the data recorded by the SAMHE monitor. Pupils can explore this on their own using our data
          visualisations or with support from our <Link to="/app/activities/24">Data Detectives activity</Link>. Pupils
          may also apply their findings and interpretations to a wider understanding of climate processes and change.
        </P>
      </div>
    ),
  },
  {
    uid: "people_place_and_environment_third_and_fourth_level_social_studies",
    label: "People, place and environment, Third and Fourth Level, Social Studies",
    group: ECurriculumLinkGroup.SCOTLAND,
    content: (
      <div>
        <P>
          SAMHE can be used to support pupils' learning under the 'People, place, and environment' curriculum organiser
          by providing them with data that can be used to explore the impacts of human activity within a controlled
          environment (the classroom) as well as the impacts of the environment on them.
        </P>
        <P>
          Relatedly, SAMHE data could be used to complement pupils' work on resource use, sustainability, and ways of
          managing impact of environmental issues since it can provide insight into how best to balance the competing
          demands of ventilation, thermal comfort and energy consumption.
        </P>
        <P>
          This can be linked with practical experiments including using the SAMHE monitor to experiment with different
          ventilation scenarios, such as by opening higher-level windows to provide sufficient ventilation without
          compromising a comfortable classroom temperature and increasing energy usage. In addition, pupils will be able
          to take a more active role in data collection and analysis by developing crucial critical thinking skills
          through the use of our <Link to="/app">Web App</Link> activities, including our{" "}
          <Link to="/app/activities/24">Data Detectives</Link> activity.
        </P>
      </div>
    ),
  },
  {
    uid: "people_past_events_and_societies_fourth_level_social_studies",
    label: "People, Past Events, and Societies, Fourth Level, Social Studies",
    group: ECurriculumLinkGroup.SCOTLAND,
    content: (
      <div>
        <P>
          As part of the People, Past Events, and Societies focus, pupils will be tasked with making reasoned judgements
          about how the exercise of power, including political decisions involving environmental and climate policy,
          affects the rights and responsibilities of citizens. SAMHE can be used to support pupils' development of these
          skills by providing them with a wealth of data to interpret (which they can explore independently or with the
          support of our activities such as <Link to="/app/activities/24">Data Detectives</Link>) and use to form the
          bases of informed arguments about what could be done to improve classroom air quality and who is responsible
          for it, both within and beyond the school.
        </P>
        <P>
          Our <Link to="/app/activities/11">Write to Your MP</Link>,{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link>, and{" "}
          <Link to="/app/activities/32">Clean Air Zones debate</Link> activities can be used to support this learning
          directly and will guide pupils through the process of structuring their argument/communicating their point of
          view and how to adapt it to suit their chosen audience.
        </P>
      </div>
    ),
  },
  {
    uid: "scientific_investigations_inquiry_and_analytical_thinking_second_third_and_fourth_levels_sciences",
    label: "Scientific Investigations, Inquiry and Analytical Thinking, Second, Third, & Fourth Levels, Sciences",
    group: ECurriculumLinkGroup.SCOTLAND,
    content: (
      <div>
        <P>
          Through SAMHE pupils will have lots of opportunities to carry out practical experiments and data collection
          exercises which can be used to support the development of their inquiry and investigative skills across
          Second, Third and Fourth levels. For example, by learning to interpret the data collected by their SAMHE
          monitor and depicted on the <Link to="/app">Web App</Link> in a variety of graphical formats, pupils will gain
          a greater understanding of their classroom environment, which they can link with observations about how they
          feel, to make conclusions about the conditions which are most effective for learning.
        </P>
        <P>
          This could either be through guided practical experiments such as{" "}
          <Link to="/app/activities/19">
            CO<sub>2</sub> levels before and after exercise
          </Link>
          , analysis of historical data through the <Link to="/app/activities/24">Data Detectives</Link> activity or
          pupils could use SAMHE data, which can be exported in csv format for use in external data analysis software,
          to support exploration of their own hypotheses.
        </P>
      </div>
    ),
  },

  // Wales
  {
    uid: "using_data_skills_key_stage_2_mathematics",
    label: "Using data skills, Key stage 2, Mathematics",
    group: ECurriculumLinkGroup.WALES,
    content: (
      <div>
        <P>
          SAMHE can support Key Stage 2 Maths pupils in Wales to develop their data skills by providing them with data
          they can use to practise compiling charts, graphs, and tables. Pupils will also be able to use the interactive
          data visualisation tools on the SAMHE Web App and practice extracting and interpreting information from them.
          Since this data is both real-time and relates to their classroom it should serve to bring their study of this
          topic to life!
        </P>
        <P>
          In addition, pupils can work with interactive activities such as{" "}
          <Link to="/app/activities/24">Data Detectives</Link> that will guide them through lines of enquiry.
        </P>
      </div>
    ),
  },
  {
    uid: "communication_and_enquiry_key_stage_3_science",
    label: "Communication and Enquiry, Key Stage 3, Science",
    group: ECurriculumLinkGroup.WALES,
    content: (
      <div>
        <P>
          SAMHE can be used to help Key Stage 3 pupils develop their skills of communication and enquiry by providing
          opportunities for them to interpret, analyse, and effectively communicate data, using both qualitative and
          quantitative methods. This can be done with the support of our activities, or through independent
          investigation of the monitor data.
        </P>
        <P>
          For example, pupils can use our <Link to="/app/activities/24">Data Detectives</Link> activity to investigate
          interesting patterns in their data, and narrow in on a possible answer based on available evidence which they
          are then encouraged to evaluate and discuss with the class. Our{" "}
          <Link to="/app/activities/19">
            CO<sub>2</sub> before and after exercise
          </Link>{" "}
          activity is another example of an activity which would be well suited to support this area of learning as it
          guides pupils through the process of recording conditions and making a prediction before carrying out the
          investigation, making observations and confirming whether the observed effects matched their hypotheses.
        </P>
        <P>
          Equally, the range of data visualisations in the Web App will support pupils to conduct their own pattern
          spotting enquiries which they can support by compiling their own diagrams and graphs using the raw monitor
          data which is available for download. This can then be linked with communication focused activities such as
          our <Link to="/app/activities/11">Write to Your MP</Link>,{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link>, and{" "}
          <Link to="/app/activities/32">Clean Air Zones debate</Link> activities which will give pupils the opportunity
          to learn how to effectively communicate scientific findings including considerations about presentation,
          format and audience.
        </P>
      </div>
    ),
  },
  {
    uid: "interdependence_of_organisms_key_stage_2_and_3_science",
    label: "Interdependence of organisms, Key stage 2 and 3, Science",
    group: ECurriculumLinkGroup.WALES,
    content: (
      <div>
        <P>
          In conjunction with learning how to collect and analyse data and developing lines of enquiry, pupils will be
          given opportunities to understand the interdependence of the environment and humans. They will be able to
          evaluate the data collected on their SAMHE monitor via the <Link to="/app">Web App</Link> and draw conclusions
          that will help them gain a better understanding of how human activity (e.g. their actions) affects the local
          environment, including pollution and air quality. In addition, pupils can refer to the SAMHE{" "}
          <Link to="/resources/air-pollution">About Air Pollution page</Link> or other resources, which could be used as
          part of investigation into this and will be able to develop appropriate, well-informed arguments.
        </P>
      </div>
    ),
  },
  {
    uid: "overall_knowledge_and_understanding_key_stage_4_science",
    label: "Overall knowledge and understanding, Key Stage 4, Science",
    group: ECurriculumLinkGroup.WALES,
    content: (
      <div>
        <P>
          The{" "}
          <AExternal href="https://hwb.gov.wales/api/storage/779c7300-574d-4a12-a518-c873557d6a7a/science-in-the-national-curriculum.pdf">
            Key Stage 2-4 Curriculum document for Science
          </AExternal>{" "}
          states that KS4 pupils will be given the opportunity to build upon their scientific knowledge, skills, and
          understanding and learn about the ways in which science and scientists work within society. Engaging with the{" "}
          <Link to="/app">SAMHE Web App</Link>, including activities such as{" "}
          <Link to="/app/activities/24">Data Detectives</Link>, can help to fulfil this by providing pupils with
          opportunities to examine and interpret air quality data collected in their classroom and develop their ability
          to relate their growing understanding of science to others, to making informed decisions about lifestyles, and
          to scientific developments in society. Analysis of the monitor data outside of the Web App could also be used
          to support pupils exploring the relationship between data, evidence, theories, and explanations while also
          developing their problem-solving skills in both individual and collaborative activities.
        </P>
      </div>
    ),
  },
  {
    uid: "understanding_places_environments_and_processes_key_stage_2_and_3_geography",
    label: "Understanding places, environments and processes, Key Stage 2 and 3, Geography",
    group: ECurriculumLinkGroup.WALES,
    content: (
      <div>
        <P>
          SAMHE can be used to support Key Stage 2 and 3 Geography pupils in Wales by providing them with data to
          support investigations into changes of an environment over time and the causes and effects of human and
          natural processes (in both cases at the scale of their own classrooms!).
        </P>
        <P>
          The SAMHE data and range of data visualisations will empower Key Stage 2 pupils to explore longer term or
          seasonal changes and pupils can complete guided investigations of short term changes through our{" "}
          <Link to="/app/activities/24">Data Detectives</Link> activity. Data detectives will also support Key Stage 3
          pupils to explore the effects of human activities both in the classroom and beyond it, which can be linked
          with opportunities to communicate their findings through activities such as{" "}
          <Link to="/app/activities/11">Write to Your MP</Link> or{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link>.
        </P>
      </div>
    ),
  },

  // Northern Ireland
  {
    uid: "writing_to_persuade_key_stage_3_communication",
    label: "Writing to persuade, Key stage 3, Communication",
    group: ECurriculumLinkGroup.NORTHERN_IRELAND,
    content: (
      <div>
        <P>
          Our <Link to="/app/activities/11">Write to Your MP</Link> and{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link> activities guide pupils through the process of
          researching a topic, deciding on the key message(s) that need to be conveyed from it and how to communicate
          them effectively for a given target audience including considerations about style and structure.
        </P>
        <P>
          The benefit of using SAMHE to support this area of learning is that data recorded by the SAMHE monitor is
          directly relevant to pupils since it comes from the classrooms they work in and is affected by their actions.
          Therefore, the letters or posters that pupils develop through our activities could be used to effect change
          within or beyond the school by persuading other pupils, members of staff, or parties outside of the school
          such as school governors, local councillors, or even your local MP.
        </P>
      </div>
    ),
  },
  {
    uid: "the_world_around_us_interdependence_key_stages_1_and_2_geographyhistoryscience_and_technology",
    label: "The World Around Us: Interdependence, Key Stages 1 and 2, Geography/History/Science and Technology",
    group: ECurriculumLinkGroup.NORTHERN_IRELAND,
    content: (
      <div>
        <P>
          SAMHE can be used to support learning under the 'Interdependence' focus area across Key stages 1 and 2 since
          the buildup of CO<sub>2</sub> or pollutants in the classroom can have effects on how pupils feel and pupils in
          turn can affect the classroom environment through the actions they take - see our{" "}
          <Link to="/resources/air-pollution-and-health">Air Pollution and Health page</Link> for more information. Both
          of these things can be matched with data recorded by the SAMHE monitor and explored in more detail in the
          SAMHE Web App.
        </P>
        <P>
          For example, high CO<sub>2</sub> levels can cause a room to feel stuffy which may decrease pupils'
          receptiveness to learning, while the simple action of opening a window can cause CO<sub>2</sub> levels to
          decrease and help make the classroom a more comfortable place to learn as a result. This should be balanced
          with thermal comfort e.g. how warm or cool pupils feel in the room.
        </P>
        <P>
          Pupils can also explore the effect they have on the classroom environment through our{" "}
          <Link to="/app/activities/19">
            CO<sub>2</sub> levels before and after exercise
          </Link>
          ,{" "}
          <Link to="/app/activities/9">
            CO<sub>2</sub> levels of an empty classroom
          </Link>
          , and <Link to="/app/activities/24">Data Detectives</Link> activities. Pupils may also enjoy looking for
          patterns in the monitor data which align with what is happening in the classroom using the data visualisations
          in the Web App which would support pupils to explore questions of their own.
        </P>
      </div>
    ),
  },
  {
    uid: "as_2_applied_mathematics_working_with_large_datasets_ccea_gce_mathematics",
    label: "AS 2: Applied Mathematics - Working with Large Datasets, CCEA GCE, Mathematics",
    group: ECurriculumLinkGroup.NORTHERN_IRELAND,
    content: (
      <div>
        <P>
          GCE Mathematics students can use SAMHE to practise the statistical, graphical and data analysis skills they
          learn as part of AS2 by exporting monitor data in .csv format and feeding it into an appropriate software
          package such as Excel. Since actions that pupils take within a classroom directly affect the air quality, the
          exported SAMHE data will reflect these interactions, helping bring the data to life and enabling pupils to see
          the value of statistical analyses for solving questions in real life contexts.
        </P>
        <P>
          For example, pupils can explore the effects of different sampling methods, calculate measures of central
          tendency and deviation, and have informed discussions about the differences between correlation and causation
          by comparing the data with observations about what was happening in the relevant classroom(s) over the time
          period to which the data relates. Our <Link to="/app/activities/24">Data Detectives</Link> activity can be
          used to support this kind of investigation.
        </P>
      </div>
    ),
  },
  {
    uid: "local_and_global_citizenship_key_stage_3_learning_for_life_and_work",
    label: "Local and Global Citizenship, Key Stage 3, Learning for Life and Work",
    group: ECurriculumLinkGroup.NORTHERN_IRELAND,
    content: (
      <div>
        <P>
          SAMHE can be used to support pupils learning about human rights and social responsibility by sparking
          conversations and debate around many complex topics, including: the importance of indoor air quality for a
          healthy learning environment; whether there should be a right in the UK to clean air; who is responsible for
          maintaining good air quality and what steps can be taken to improve it. Pupils could be encouraged to explore
          these lines of enquiry by thinking about what they as individuals can do to improve or maintain good air
          quality compared with what we could do as a society and how this could be supported or led by government
          action. Our <Link to="/app/activities/11">Write to Your MP</Link> and{" "}
          <Link to="/app/activities/33">Design a SAMHE poster</Link> activities would fit well with this work.
        </P>
        <P>
          An additional benefit of using SAMHE to animate this part of the curriculum is through the SAMHE monitor and
          Web App, pupils will have access to present day data which relates to their own classrooms, helping to make
          some of these bigger concepts more tangible.
        </P>
      </div>
    ),
  },
  {
    uid: "environment_and_society_key_stage_3_geography",
    label: "Environment and Society, Key stage 3, Geography",
    group: ECurriculumLinkGroup.NORTHERN_IRELAND,
    content: (
      <div>
        <P>
          Key Stage 3 Geography pupils could use SAMHE in the context of their work around developing a sense of place
          and belonging at a local level. For example, they could map their different journeys to school and discuss the
          relative impacts of these on the environment and air quality both outdoors and indoors. This could be linked
          with an investigation into whether there might be any related effects on personal health and, if so, what
          steps can be taken to minimise them.
        </P>
        <P>
          This might culminate in the development of a school action plan using the{" "}
          <AExternal href="https://www.transform-our-world.org/programmes/clean-air-for-schools">
            Clean Air for Schools Framework
          </AExternal>
          , or using our guided activity to <Link to="/app/activities/11">Write a letter to your local MP</Link>.
        </P>
        <P>
          Pupils could then compare this to how pupils at other schools travel to school and the different associated
          effects for air quality and health, perhaps starting with thinking about points of difference or similarity
          between different areas within the country before moving onto differences between countries.
        </P>
        <P>
          Used in this way SAMHE can support pupils to develop personal understanding, mutual understanding, and
          citizenship skills across objectives 1 and 2 for Environment and Society per the{" "}
          <AExternal href="https://ccea.org.uk/document/2405">
            Statutory Requirements for Geography at Key Stage 3
          </AExternal>
          .
        </P>
      </div>
    ),
  },

  // EPQs
  {
    uid: "epqs",
    label: "EPQs",
    group: ECurriculumLinkGroup.EPQ,
    content: (
      <div>
        <P>SAMHE is well suited for use in EPQs and can help pupils meet all four assessment objectives (AOs).</P>
        <P>
          Pupils can use the SAMHE Web App activities to gain an initial understanding of indoor air quality, learn new
          skills in data handling (AO3) and identify the areas they would like to study further (AO1). The Web App also
          enables pupils to download data from the SAMHE monitor for further analysis (AO2). The SAMHE website hosts
          information about air pollution, air quality monitoring and health and links out to additional resources which
          can be used to support pupils' projects (AO2).
        </P>
        <P>
          Pupils could also use our Web App quizzes to compare their knowledge and understanding at the start and end of
          their projects, as part of evaluating their learning (AO4). In addition to a written project report, pupils
          could also present their findings to school management to inform the school's air quality/ventilation
          strategy, and also share their conclusions beyond the school community through the{" "}
          <Link to="/app/activities/11">'Write to your local MP' Web App activity</Link> (AO4).
        </P>
        <P>Some examples of EPQ projects using SAMHE could be:</P>
        <Ul>
          <li>An investigation comparing classroom air quality during Winter and Spring</li>
          <li>Understanding ventilation via investigation of carbon dioxide levels in classrooms</li>
          <li>
            Comparing levels of Particulate Matter and TVOCs in Art and Design classrooms to those of other classrooms
          </li>
          <li>Investigating temperature and humidity: applying simple thermal comfort metrics/models to classrooms</li>
          <li>Methods of improving indoor air quality in classrooms</li>
        </Ul>
      </div>
    ),
  },
];

const CurriculumLinksPage: React.FC = () => {
  const { hash } = useLocation();
  const uid = hash?.replace("#", "");
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>Curriculum Links</PageTitle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              {Object.values(CurriculumLinkGroups).map((f) => (
                <li key={f.uid}>
                  <Link to={"#" + f.uid}>{f.label_short || f.label}</Link>
                </li>
              ))}
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <Section>
            <BoxoutContactStyle style={{ marginBottom: "1rem", padding: "1rem" }}>
              SAMHE has strong links across different subject areas and stages of education, making it a useful tool for
              integrating within lessons.{" "}
            </BoxoutContactStyle>
            <FaqGroupWrapStyle>
              {Object.values(CurriculumLinkGroups)
                .map((f) => ({
                  ...f,
                  entries: curriculumLinkEntries.filter((fe) => fe.group === f.uid),
                }))
                .map((f) => (
                  <CurriculumLinkGroup
                    label={f.label}
                    key={f.uid}
                    uid={f.uid}
                    entries={f.entries}
                    uidToShow={uid}
                    description={f.description}
                  />
                ))}
            </FaqGroupWrapStyle>
          </Section>
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default CurriculumLinksPage;
