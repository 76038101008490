import { rest } from "msw";
import { SERVER_URL } from "../config";
import * as database from "../dummyApi/database";
import { IAchievementCompleteActivityType } from "../lib/Achievements/IAchievement";
import {
  EActivityStatus,
  IActivity,
  IActivityApiData,
  IActivitySubmitData,
  IActivityUser,
  IActivityUserData,
  IActivityUserDataNotStarted,
  IActivityUserStarted,
} from "../lib/Activities/IActivity";
import { IStepUserData, TStepState } from "../lib/Activities/IStep";

const mockCompleteActivity = (req, res, ctx) => {
  const { id } = req.params;
  try {
    let activityUser: IActivityUserStarted = database.getLatestActivity(id) as IActivityUserStarted;
    if (!activityUser?.activityUserProgress) {
      const activity: IActivity = database.getDoc(id, "Activities");
      if (!activity) return res(ctx.status(404));
      const newId = database.getId("ActivityUserProgresses");
      activityUser = {
        ...activity,
        activityUserProgress: {
          id: newId,
          activityStatus: EActivityStatus.COMPLETED,
          activityId: id,
          dateCreated: new Date().toString(),
          dateModified: new Date().toString(),
          stepsState: [],
          activeStepId: null,
          previousSteps: [],
          attemptNo: 1,
        },
      };

      database.addDoc(newId, "ActivityUserProgresses", activityUser.activityUserProgress);
    }
    activityUser.activityUserProgress.activityStatus = EActivityStatus.COMPLETED;
    if (req.body.completeCheatCode) {
      activityUser.activityUserProgress.attemptNo = (activityUser.activityUserProgress.attemptNo || 0) + 1;
    }
    database.putDoc(activityUser.activityUserProgress.id, "ActivityUserProgresses", activityUser.activityUserProgress);

    const achievements = database
      .getDocs<IAchievementCompleteActivityType>("Achievements")
      .filter((a) => a.activityId === id);
    return res(
      // Respond with a 200 status code
      ctx.status(200),
      ctx.json({
        ok: true,
        data: { achievements },
      })
    );
  } catch (error) {
    console.info(error);
    return res(ctx.status(500));
  }
};

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: false }[] = [
  {
    /* Get activity summary */
    useMockApi: false,
    endpoint: rest.get<null, null, { data: IActivityApiData[] }>(
      `${SERVER_URL}/Activities/Summary`,
      (req, res, ctx) => {
        const activities = database.getLatestActivities();
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            data: activities.map((a) => {
              const { id } = a;
              const activityUser: IActivityUser = database.getLatestActivity(id);
              const activityUserApi: IActivityApiData = {
                activity: activityUser,
                latestActivityUserProgress: activityUser.activityUserProgress as
                  | IActivityUserData
                  | IActivityUserDataNotStarted,
                activityIsLocked: activityUser.activityUserProgress?.isLocked, // TODO: Implement mock is locked
              };
              return activityUserApi;
            }),
          })
        );
      }
    ),
  },
  {
    /* Get activity user */
    useMockApi: false,
    endpoint: rest.get<null, { id: string }, { data: IActivityApiData }>(
      `${SERVER_URL}/Activities/:id`,
      (req, res, ctx) => {
        const { id } = req.params;
        const activityUser: IActivityUser = database.getLatestActivity(id);
        const activityUserApi: IActivityApiData = {
          activity: activityUser,
          latestActivityUserProgress: activityUser.activityUserProgress as
            | IActivityUserData
            | IActivityUserDataNotStarted,
            activityIsLocked: activityUser.activityUserProgress?.isLocked, // TODO: Implement mock is locked
          };
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            data: activityUserApi,
          })
        );
      }
    ),
  },
  {
    /* submit step */
    useMockApi: false,
    endpoint: rest.post<IActivitySubmitData>(`${SERVER_URL}/Activities/:id/Steps/:stepid`, (req, res, ctx) => {
      const { id } = req.params;
      const submitData = req.body;
      const activityUser: IActivityUserStarted = database.getLatestActivity(id) as IActivityUserStarted;
      const submittedStepData: IStepUserData<TStepState> = {
        ...submitData.data,
        dateCreated: new Date(Date.now()),
        previousSteps: activityUser.activityUserProgress.previousSteps,
      };
      const stepRef = submittedStepData.stepRef;
      const updatedStepsData: IStepUserData<TStepState>[] = activityUser.activityUserProgress.stepsState.concat([
        submittedStepData,
      ]);
      activityUser.activityUserProgress.stepsState = updatedStepsData;
      activityUser.activityUserProgress.activeStepId = submitData.nextStep;
      activityUser.activityUserProgress.previousSteps = [...activityUser.activityUserProgress.previousSteps, stepRef];
      database.putDoc(
        activityUser.activityUserProgress.id,
        "ActivityUserProgresses",
        activityUser.activityUserProgress,
        "id"
      );
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          ok: true,
        })
      );
    }),
  },
  {
    /* Set active step */
    useMockApi: false,
    endpoint: rest.post(`${SERVER_URL}/Activities/:id/ActiveStep/:stepid`, (req, res, ctx) => {
      const { id, stepid } = req.params;
      const activityUser: IActivityUserStarted = database.getLatestActivity(id) as IActivityUserStarted;
      const completedStepInfo = activityUser.activityUserProgress.stepsState
        .filter((s) => s.stepId === parseInt(stepid as string))
        .sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))[0];
      const previousSteps = completedStepInfo.previousSteps;
      activityUser.activityUserProgress.activeStepId = parseInt(stepid as string);
      activityUser.activityUserProgress.previousSteps = previousSteps;
      database.putDoc(
        activityUser.activityUserProgress.id,
        "ActivityUserProgresses",
        activityUser.activityUserProgress
      );
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          ok: true,
        })
      );
    }),
  },
  {
    /* Start Activity */
    useMockApi: false,
    endpoint: rest.post<IActivitySubmitData>(`${SERVER_URL}/Activities/:id/Start`, (req, res, ctx) => {
      const { id } = req.params;
      const activityUser: IActivityUser = database.getLatestActivity(id);
      const attemptCount = activityUser.activityUserProgress?.attemptNo || 0;
      const activityUserStarted: IActivityUserData = {
        id: database.getId("ActivityUserProgresses"),
        activityId: activityUser.id,
        activityStatus: EActivityStatus.IN_PROGRESS,
        dateCreated: String(Date.now()),
        dateModified: String(Date.now()),
        stepsState: [],
        activeStepId: activityUser.activitySteps[0].id,
        previousSteps: [],
        attemptNo: attemptCount + 1,
      };

      database.addDoc(activityUserStarted.id, "ActivityUserProgresses", activityUserStarted);
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json({
          ok: true,
        })
      );
    }),
  },
  {
    useMockApi: false,
    endpoint: rest.post<IActivitySubmitData>(`${SERVER_URL}/Activities/:id/Complete`, (req, res, ctx) => {
      return mockCompleteActivity(req, res, ctx);
    }),
  },
  {
    useMockApi: false,
    endpoint: rest.post<IActivitySubmitData>(`${SERVER_URL}/Activities/:id/Jump`, (req, res, ctx) => {
      return mockCompleteActivity(req, res, ctx);
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
