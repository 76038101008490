import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { ApiError } from "../../Api/apiHelpers";
import { apiMonitorHealthCheck, IApiMonitorHealthCheckReturn } from "../../Api/ping";
import { MonitorId } from "../../lib/Monitor/IMonitor";
import { SchoolId } from "../../lib/School/ISchoolDetails";
import { CTAButtonStyle } from "../../styles/button";
import { InputStyle } from "../../styles/form";
import { P, Ul } from "../../styles/page";
import { LinkExternal } from "../Links";

export interface IMonitorHealthCheckProps {
  schoolId: SchoolId;
  serialNumber: MonitorId;
  onHealthCheckComplete: (result: boolean) => void;
  callOnLoad?: boolean;
}

export interface ResponseType {
  ok?: boolean;
  message?: string;
}

export interface IUseHealthCheckProps {
  callOnLoad?: boolean;
  onHealthCheckComplete?: (result: boolean) => void;
  serialNumber?: MonitorId;
}

export interface IUserHealthCheckReturn {
  error: Error;
  loading: boolean;
  reload: (schoolId: SchoolId, serialNumber: MonitorId) => void;
  response: IApiMonitorHealthCheckReturn;
  message: string;
  success: boolean;
}

export const useHealthCheck = ({
  callOnLoad = false,
  onHealthCheckComplete,
  serialNumber: serialNumberIn,
}: IUseHealthCheckProps = {}): IUserHealthCheckReturn => {
  const [callFlag, setCallFlag] = React.useState(() => callOnLoad);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | ApiError>(null);
  const [serialNumber, setSerialNumber] = React.useState<MonitorId>(serialNumberIn);
  const [schoolId, setSchoolId] = React.useState<SchoolId>(null);
  const [response, setResponse] = React.useState<IApiMonitorHealthCheckReturn>(null);
  const { token } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (callFlag && !loading && serialNumber) {
      setCallFlag(null);
      setLoading(true);
      setSerialNumber(null);
      setError(null);

      const call = async (serialNumber: MonitorId) => apiMonitorHealthCheck(serialNumber, token);
      call(serialNumber)
        .then((responseInner) => {
          if (!responseInner.data[0]) {
            console.info("Missing data!");
            throw new ApiError("Missing data from monitor");
          }
          setResponse(responseInner);
          if (onHealthCheckComplete && responseInner?.ok) onHealthCheckComplete(true);
        })
        .catch((e) => {
          // TODO: Handle errors better here
          setError(e);
          if (onHealthCheckComplete) onHealthCheckComplete(false);
          // setError((e as IApiResponseError).error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, callFlag, schoolId, serialNumber, onHealthCheckComplete, token]);

  const message =
    (loading && "Loading") ||
    (response?.ok &&
      `Success! Your current CO\u2082 concentration is ${response.data[response.data.length - 1].value}.`) ||
    (error && error?.message && `Failed! ${error.message}.`) ||
    (error && "Failed: Unknown error please contact SAMHE support") ||
    "Not checked";

  const success = response?.ok || false;
  const reload = (schoolId: SchoolId, serialNumberInner: MonitorId) => {
    setResponse(null);
    setCallFlag(true);
    setSerialNumber(serialNumberInner);
    setSchoolId(schoolId);
  };
  return {
    error,
    loading,
    reload,
    response,
    message,
    success,
  };
};

/**
 * Component to check the link between the app and the activated monitor
 */
export const MonitorHealthCheck: React.FC<IMonitorHealthCheckProps> = ({
  schoolId,
  serialNumber,
  onHealthCheckComplete,
  callOnLoad = false,
}) => {
  const {
    loading,
    message,
    reload: pingMonitorData,
    success,
    error,
  } = useHealthCheck({ onHealthCheckComplete, callOnLoad, serialNumber });

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          pingMonitorData(schoolId, serialNumber);
        }}
      >
        <P>
          <label htmlFor="monitorId">Checking monitor with id number</label>: <b id="monitorId">{serialNumber}</b>
        </P>
        <InputStyle>
          <CTAButtonStyle type="submit">Refresh</CTAButtonStyle>
        </InputStyle>
      </form>
      <p>
        Status: <b>{loading ? "Loading" : message}</b>
      </p>
      <div style={{ minHeight: "2rem" }} />

      <p>{success && <b>Congratulations your monitor is all setup!</b>}</p>
      <div>
        {error && (
          <>
            <p>We failed to connect to your monitor. Please see troubleshooting tips below:</p>
            <Ul>
              <li>
                Go to the <LinkExternal to="/support">SAMHE help pages</LinkExternal>
              </li>
            </Ul>
          </>
        )}
      </div>
    </div>
  );
};
