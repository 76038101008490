import { EAggregationInterval } from "../../lib/MonitorData/AggregationIntervals";

export interface IDateRangeState {
  startDate: Date;
  endDate: Date;
  startTimeLimit: Date;
  endTimeLimit: Date;
  invalid: false | string;
  dayRange: EDayRange;
  timeRange: ETimeRange;
  aggregation: EAggregationInterval;
  maxAggregation: EAggregationInterval; // Set by start and end date
  minAggregation: EAggregationInterval; // Set by start and end date
}
export interface IDateRangePickerProps {
  onChange: (state: IDateRangeState) => void;
  value: IDateRangeState;
  useAggregation?: boolean;
  allowTimeLimiting?: boolean;
  adaptiveAggregation?: boolean;
  allowedDayRanges?: EDayRange[];
  allowedMinDateRange?: number;
  allowedMaxDateRange?: number;
  allowedMaxAggregation?: EAggregationInterval;
  allowedMinAggregation?: EAggregationInterval;
}

export enum EDayRange {
  LAST_HOUR = "LAST_HOUR",
  LAST_SIX_HOURS = "LAST_SIX_HOURS",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  LAST_THREE_DAYS = "LAST_THREE_DAYS",
  LAST_SCHOOL_WEEK = "LAST_SCHOOL_WEEK",
  SCHOOL_DAYS = "SCHOOL_DAYS",
  THIS_SCHOOL_TERM = "THIS_SCHOOL_TERM",
  THIS_YEAR = "THIS_YEAR",
  THIS_SCHOOL_YEAR = "THIS_SCHOOL_YEAR",
  CUSTOM = "CUSTOM",
}
export enum ETimeRange {
  NO_LIMIT = "NO_LIMIT",
  SCHOOL_HOURS = "SCHOOL_HOURS", // 9:00am - 4:00pm
  CUSTOM = "CUSTOM",
}

interface ITimeRangeOption {
  id: ETimeRange;
  label: string;
}

export const timeRangeOptions: ITimeRangeOption[] = [
  { id: ETimeRange.NO_LIMIT, label: "No limit" },
  { id: ETimeRange.SCHOOL_HOURS, label: "School Hours (9:00am - 4:00pm)" },
  { id: ETimeRange.CUSTOM, label: "Custom" },
];
