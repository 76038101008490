import React from "react";
import { TvocsAndPmsInfo } from "../components/Documents/TvocsAndPms";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";

const TvocsAndPmsPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>TVOCs (Total Volatile Organic Compounds) and PM (Particulate Matter)</PageTitle>
      <TvocsAndPmsInfo />
    </ConstrainedPageStyle>
  );
};

export default TvocsAndPmsPage;
