import React from "react";
import { useInterval } from "react-use";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import {
  CardInfoSectionWrapper,
  CardStyle,
  CardTitle,
  CardTitleBar,
  PlotDescriptionSection,
} from "../../../../GenericComponents/CardBoard/styles";
import { DataMetricsMetaData } from "../../../../lib/DataViews/EDataMetrics";
import { SelectDropdown } from "../../../../GenericComponents/FormElements/Select";
import { Tags } from "../../../Tags/Tags";
import { ExpandedPanelContainer, ExpandedPanelHeaderStyle, ExpandedPanelInnerStyle } from "../../../../styles/popups";
import { ISimplePlotCardProps, ISimplePlotCollapsedCardProps, ISimplePlotExpandedCardProps } from "./lib";
import { useDataLoader } from "../../dataLoader";
import { DataViewCsvDownload } from "../../DataViewComponents";
import { ExpandedCardLoader } from "../../CardLoader";
import { TextFormatter } from "../../../TextFormatter/TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { SimplePlot } from "../../../DataViz/SimplePlot/SimplePlot";
import { defaultDateRangeState, refreshTimes } from "../../../DateRangePicker/utils";
import { SimplePlotOptions } from "./SimplePlotOptions";
import { Br, FlexBox } from "../../../../styles/singlePageStyles";
import { CardExpandedHr, DataViewButton, ErrorMessage } from "../../../../styles/app";
import { ButtonStyle } from "../../../../styles/button";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { GraphCaptionBox } from "../../DataViewComponents/GraphCaptionBox";
import { PatternInfoBox } from "../../DataViewComponents/PatternInfoBox";
import { GraphGuidanceBox } from "../../DataViewComponents/GraphGuidanceBox";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { IDateRangeState } from "../../../DateRangePicker/lib";
import { defaultTooltipFormatter } from "../../../DataViz/common/TooltipFormatters";

const defaultSimplePlotDateRange = (): IDateRangeState =>
  refreshTimes({
    ...defaultDateRangeState(),
    aggregation: EAggregationInterval.MINUTE,
    minAggregation: EAggregationInterval.MINUTE,
    maxAggregation: EAggregationInterval.TEN_MINUTE,
  });

export const SimplePlotCardInner = ({
  id,
  monitorId,
  label,
  additionalProps: { metrics, yRange, liveUpdate = true },
}: ISimplePlotCollapsedCardProps) => {
  if (!metrics) throw Error(`Missing metrics!`);
  const { label: metricLabel, unit, decimalPlaces } = DataMetricsMetaData[metrics[0]] || {};
  if (!metricLabel) throw Error(`Invalid Metric: ${metrics[0]}`);
  const [dateRange, setDateRange] = React.useState(defaultDateRangeState());
  const yLabel = `${metricLabel} (${unit})`;
  const { loadedData, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric: metrics[0],
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <div className="inner">
      <CardTitleBar>
        <CardTitle>
          <DataViewButton to={`/app/data/${id}`}>{String(label)}</DataViewButton>
        </CardTitle>
      </CardTitleBar>
      <p>Simple Plot of: {metricLabel} </p>
      <p>
        Now:{" "}
        <span>
          {loadedData?.data.slice().reverse()[0]?.value}
          {unit}
        </span>
      </p>
      {readyToView && !dataLoadError ? (
        <SimplePlot
          timeFormat={dateRange.aggregation}
          data={loadedData?.data}
          yLabel={yLabel}
          yRange={yRange}
          dateFormatOptions={{ compact: true }}
          tooltipOptions={{
            tooltipFormatter: defaultTooltipFormatter,
            fixedPopup: true,
            decimalPlaces,
          }}
        />
      ) : (
        ""
      )}
      {dataLoadError ? <ErrorMessage>Error loading data</ErrorMessage> : ""}
    </div>
  );
};

export const SimplePlotExpanded = ({
  id,
  label,
  monitorId,
  description,
  longDescription,
  tags,
  additionalProps: { baselineStandards, metrics, yRange, graphCaption, patternText, graphGuidance },
  onLoadedData,
  dateRange,
  setDateRange,
}: ISimplePlotExpandedCardProps) => {
  const [currentMetric, setCurrentMetric] = React.useState(metrics[0]);
  const [startYAxisAtZero, setStartYAxisAtZero] = React.useState(true);
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const [showMinAndMax, setShowMinAndMax] = React.useState(false);
  const [showAverage, setShowAverage] = React.useState(false);
  const [showLongDesc, setShowLongDesc] = React.useState(true);
  const { loadedData, loading, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
    onLoadedData: onLoadedData,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );
  const { label: metricLabel, unit, decimalPlaces } = DataMetricsMetaData[currentMetric] || {};

  if (!metricLabel) throw Error(`Invalid Metric: ${currentMetric}`);
  const metricOptions = metrics.map((m) => DataMetricsMetaData[m]);
  const yLabel = `${metricLabel} (${unit})`;

  return (
    <div className="simplePlotExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <CardExpandedHr />
      <PlotDescriptionSection>
        <TextFormatter text={String(description)} keywordMap={keywordMap} />
        {metricOptions.length > 1 && (
          <div style={{ marginRight: "1rem" }}>
            <label htmlFor={`${id}_metricSelect`}>Metric: </label>
            {metricOptions.length > 1 && (
              <SelectDropdown
                id={`${id}_metricSelect`}
                selectOptions={metricOptions}
                value={currentMetric}
                onChange={(v) => setCurrentMetric(v)}
              />
            )}
            {metricOptions.length === 1 && (metricLabel || "MISSING LABEL")}
          </div>
        )}
        {longDescription ? (
          <>
            <CardInfoSectionWrapper
              open={showLongDesc}
              maxHeight="70rem"
              style={{ width: "100%" }}
              transitionTime={0.2}
            >
              <CollapsableSectionInner open={showLongDesc}>
                <FlexBox horiz flexwrap style={{ gap: "2rem" }}>
                  <div data-testid="long-description" style={{ flex: "1 1 30rem" }}>
                    <TextFormatter text={longDescription} keywordMap={keywordMap} />
                  </div>
                  {graphCaption && <GraphCaptionBox text={graphCaption} />}
                </FlexBox>
              </CollapsableSectionInner>
            </CardInfoSectionWrapper>
            <ButtonStyle style={{ width: "100%", marginTop: "1rem" }} onClick={() => setShowLongDesc((prev) => !prev)}>
              {showLongDesc ? "Hide Description" : "Show Description"}
            </ButtonStyle>
          </>
        ) : (
          ""
        )}
      </PlotDescriptionSection>
      <Br />
      {tags ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tags tags={tags} />
        </div>
      ) : (
        ""
      )}
      {loading ? "Loading Data..." : ""}
      <Br />
      {dataLoadError ? (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadError && dataLoadError.message}</ErrorMessage>
        </FlexBox>
      ) : (
        ""
      )}
      {readyToView ? (
        <div style={{ width: "100%" }}>
          <SimplePlot
            timeFormat={
              [
                EAggregationInterval.NONE,
                EAggregationInterval.MINUTE,
                EAggregationInterval.FIVE_MINUTE,
                EAggregationInterval.TEN_MINUTE,
              ].indexOf(dateRange.aggregation) === -1
                ? dateRange.dayRange
                : dateRange.aggregation
            }
            data={loadedData?.data}
            yLabel={yLabel}
            baselineStandards={baselineStandards}
            allowScale
            showAverage={showAverage}
            showMin={showMinAndMax}
            showMax={showMinAndMax}
            yRange={startYAxisAtZero ? [0, yRange ? yRange[1] : undefined] : yRange}
            preProcessDataOptions={{
              timeRangeFilter: [dateRange.startTimeLimit, dateRange.endTimeLimit],
            }}
            tooltipOptions={{
              tooltipFormatter: defaultTooltipFormatter,
              decimalPlaces,
            }}
          />
        </div>
      ) : (
        ""
      )}
      <Br />
      <Br />
      <SimplePlotOptions
        reloadData={() => setDateRange(refreshTimes(dateRange))}
        loading={loading}
        setDateRange={setDateRange}
        dateRange={dateRange}
        startYAxisAtZero={startYAxisAtZero}
        setStartYAxisAtZero={setStartYAxisAtZero}
        setLiveUpdate={setLiveUpdate}
        liveUpdate={liveUpdate}
        setShowAverage={setShowAverage}
        showAverage={showAverage}
        setShowMinAndMax={setShowMinAndMax}
        showMinAndMax={showMinAndMax}
      />
      {graphGuidance ? <GraphGuidanceBox text={graphGuidance} /> : ""}
      {patternText ? <PatternInfoBox type={patternText} /> : ""}
    </div>
  );
};

export const SimplePlotCard = (props: ISimplePlotCardProps) => (
  <CardStyle>
    <SimplePlotCardInner {...props} />
  </CardStyle>
);

export const SimplePlotCardExpanded = (props: ISimplePlotCardProps) => {
  const { uniqueCardId } = props;

  const [loadedData, setLoadedData] = React.useState<any>(null);
  // Callback function to receive data from the child component
  const handleLoadedData = (data: any) => {
    setLoadedData(data);
  };
  const [dateRange, setDateRange] = React.useState(defaultSimplePlotDateRange());

  return (
    <>
      <ExpandedPanelContainer data-testid={`${uniqueCardId}_expanded-panel`}>
        <ExpandedPanelHeaderStyle style={{ backgroundColor: "#303b71", justifyContent: "flex-end" }}>
          {loadedData ? <DataViewCsvDownload data={loadedData} dateRange={dateRange} /> : ""}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle>
          <ExpandedCardLoader childProps={props}>
            {(propsFull: ISimplePlotExpandedCardProps) => (
              <SimplePlotExpanded
                {...propsFull}
                onLoadedData={handleLoadedData}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}
          </ExpandedCardLoader>
        </ExpandedPanelInnerStyle>
      </ExpandedPanelContainer>
    </>
  );
};
