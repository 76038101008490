import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { IKeyword } from "../../lib/Keyword/IKeyword";
import { AExternal } from "../Links";
import { insertLinks } from "./formatters";
import { KeywordItem } from "./KeywordItem";

export interface ITextFormatterProps {
  text: string;
  keywordMap: IKeyword[];
}

export interface IReactMDComponentNode {
  properties?: {
    href?: string;
  };
  children?:
    | any
    | {
        value?: string;
      }[];
}

export const AComponent =
  (keywordMap) =>
  ({ node }: { node: IReactMDComponentNode }) => {
    const href = node.properties.href;
    const text = (node.children && node.children[0]?.value) || "";
    if (href.includes("_keyword_")) {
      const uid = href.replace("_keyword_", "");
      const keyword = keywordMap.find((k) => String(k.uid) === uid);

      return <KeywordItem text={text} data={keyword} />;
    } else {
      if (href[0] === "/") return <Link to={href}>{text}</Link>;
      else return <AExternal href={href}>{text}</AExternal>;
    }
  };

export const componentMap = (keywordMap: IKeyword[]) => ({
  // We use anchor tags to store info for how to render the element
  a: AComponent(keywordMap),
});

export const TextFormatter = ({ text, keywordMap }: ITextFormatterProps) => {
  const formattedText = insertLinks(text, keywordMap);
  const componentMapIn = React.useMemo(() => componentMap(keywordMap), [keywordMap]);
  return <ReactMarkdown components={componentMapIn}>{formattedText}</ReactMarkdown>;
};
