import React from "react";
import { apiChangeMonitorLocation } from "../../Api/apiMonitorManagement";
import { apiGetActivities } from "../../Api/apiActivities";
import { apiGetMonitorData } from "../../Api/apiMonitorData";
import { apiGetDataViews } from "../../Api/apiDataViews";

export interface IApiContext {
  apiChangeMonitorLocation: typeof apiChangeMonitorLocation;
  apiGetActivities: typeof apiGetActivities;
  apiGetDataViews: typeof apiGetDataViews;
  apiGetMonitorData: typeof apiGetMonitorData;
}

export const defaultApiContext: IApiContext = {
  apiChangeMonitorLocation,
  apiGetActivities,
  apiGetMonitorData,
  apiGetDataViews,
};

export const ApiContext = React.createContext<IApiContext>(defaultApiContext);

export const ApiProvider = ({
  apiContextOverrides,
  children,
}: {
  apiContextOverrides: Partial<IApiContext>;
  children: React.ReactNode;
}) => {
  return <ApiContext.Provider value={{ ...defaultApiContext, ...apiContextOverrides }}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
