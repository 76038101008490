import React from "react";
import { ConstrainedPageStyle } from "../styles/page";
import { SchoolActivation } from "../components/SchoolActivation";

const SchoolActivationPage: React.FC = () => {
  return (
    <ConstrainedPageStyle data-testid="page-school-setup">
      <SchoolActivation />
    </ConstrainedPageStyle>
  );
};

export default SchoolActivationPage;
