import { HamburgerMenuStyle } from "../../styles/header";

export const HamburgerButton = ({ onClick }) => (
  <HamburgerMenuStyle aria-label="open hamburger menu" onClick={onClick}>
    <svg viewBox="0 0 24 24" fill="currentColor" width="40px" height="100%" data-ux="IconHamburger">
      <path
        fillRule="evenodd"
        d="M19.248 7.5H4.752A.751.751 0 0 1 4 6.75c0-.414.337-.75.752-.75h14.496a.75.75 0 1 1 0 1.5m0 5.423H4.752a.75.75 0 0 1 0-1.5h14.496a.75.75 0 1 1 0 1.5m0 5.423H4.752a.75.75 0 1 1 0-1.5h14.496a.75.75 0 1 1 0 1.5"
      ></path>
    </svg>
  </HamburgerMenuStyle>
);
