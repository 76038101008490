import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAsyncFn } from "react-use";
import { CTAButtonStyle } from "../../styles/button";
import { FormStyle, InputStyle } from "../../styles/form";
import { P, SectionHeading, Ul } from "../../styles/page";
import { ErrorMessageStyle } from "../FormComponents/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { IRoomListFormState, IRoomListFormSubmission } from "../../lib/formSubmissions/IRoomListFormSubmission";
import { apiRoomList } from "../../Api/apiRooms";
import { reduxGetUserData } from "../../Redux/User/userReducer";
import { ApiReturnType, IApiPostResponse, IApiResponse } from "../../Api/apiHelpers";

export const RoomListForm = ({ onSuccessfulSubmit = () => {} }: { onSuccessfulSubmit?: () => void }) => {
  const { token } = React.useContext(AuthContext);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const [newRoomName, setNewRoomName] = React.useState("");

  const roomListIn = useAppSelector((state) => state.userState.state.school?.data?.roomList);
  const [roomList, setRoomList] = React.useState<IRoomListFormState["roomList"]>(roomListIn || []);
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState<string>("");
  const [madeChanges, setMadeChanges] = React.useState<boolean>(false);

  const onFormSubmitComplete = React.useCallback(
    (response: ApiReturnType<IRoomListFormSubmission, IApiPostResponse<IApiResponse>>) => {
      if (response.response.ok) {
        setMadeChanges(false);
        onSuccessfulSubmit();
        dispatch(reduxGetUserData(token, false, monitorId));
      }
      return response.response;
    },
    [onSuccessfulSubmit, dispatch, token, monitorId]
  );
  const [apiRequestState, apiRequest] = useAsyncFn(
    async () => apiRoomList.update(token)({ roomList }).then(onFormSubmitComplete),
    [roomList, token, onSuccessfulSubmit]
  );
  const isSubmitting = apiRequestState.loading;
  const isSubmitted = !apiRequestState.loading && apiRequestState.value?.ok;

  const containsErrors = React.useMemo(() => {
    return error || apiRequestState.error || (apiRequestState.value && !apiRequestState.value.ok);
  }, [error, apiRequestState]);

  const addNewRoom = () => {
    setMadeChanges(true);
    const newRoomUid = roomList?.length > 0 ? Math.max(...roomList.map((r) => Number(r.id))) + 1 : 1;
    if (roomList.map((r) => r.label).includes(newRoomName.trim())) return;
    setRoomList([...roomList, { id: String(newRoomUid), label: newRoomName.trim() }]);
    setNewRoomName("");
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const submissionData = {
      roomList,
    };
    if (!submissionData.roomList || submissionData.roomList.length === 0) {
      setError("Please enter at least one room name");
    }
    apiRequest();
  };

  // Update roomList if roomListIn changed, i.e. after form submission
  React.useEffect(() => {
    setRoomList([...roomListIn]);
  }, [roomListIn]);

  const displayMessage = React.useMemo(
    () =>
      (isSubmitting && "Submitting Form.") ||
      (madeChanges && "Please save changes to room list.") ||
      (isSubmitted && error && `There was an error saving the room list. ${error}`) ||
      (isSubmitted && apiRequestState.error && `There was an error saving the room list. Please try again.`) ||
      (isSubmitted && apiRequestState.value?.ok && "Submission successful.") ||
      " ",
    [isSubmitting, isSubmitted, error, apiRequestState.error, apiRequestState.value?.ok, madeChanges]
  );

  return (
    <div className="schoolroomListForm_wrap">
      <FormStyle
        aria-label="Room List Form"
        name="roomListForm"
        onSubmit={(e) => {
          e.preventDefault();
          addNewRoom();
        }}
      >
        <div style={{ display: "block", width: "100%" }}>
          <SectionHeading>Room List</SectionHeading>
          <P>
            The purpose of this Room List is to enable other users to describe your monitor's location as you move it
            around the school. For each room you'd like to add to the list, type a room name in the box below and click
            'add room' to save your entry. As an admin user, you can add to this list at any time. Your school's room
            list must contain at least one room name.
          </P>
          <P>
            <i>
              All room names should be entered in a way that is unique (for example, easily recognisable to site staff)
              and easy for pupils and teachers to recognise too. If your school has room numbers or names that would be
              ideal e.g. 'Room 301','Badgers' classroom', or a combination 'L4.12 - Science Block Rm 12'. Please do not
              enter the names of teachers here, your entry will be used to describe the room to all subsequent users and
              to the research team.
            </i>
          </P>
        </div>
        <div style={{ width: "100%", marginTop: "1rem" }}>
          <b>Room List:</b>
          <Ul style={{ marginBottom: 0 }}>
            {roomList?.map((r) => (
              <li key={r.id}>{r.label}</li>
            ))}
          </Ul>
          <label htmlFor="addRoomInput">Enter new room name</label>
          {": "}
          <input
            style={{ marginLeft: "1rem" }}
            id="addRoomInput"
            value={newRoomName}
            onChange={(e) => setNewRoomName(e.target.value)}
          />
          <button type="button" onClick={() => addNewRoom()}>
            Add room
          </button>
        </div>
        <div style={{ marginTop: "1rem" }}>
          {containsErrors && <ErrorMessageStyle data-testid="displayErrorMessage">{displayMessage}</ErrorMessageStyle>}
          {!containsErrors && <div data-testid="displayMessage">{displayMessage || " "}</div>}
          {/* {!displayMessage && <div style={{ height: "1rem" }}></div>} */}
        </div>
      </FormStyle>
      <FormStyle onSubmit={onFormSubmit} aria-label="Room List Form Save" name="roomListFormSave">
        <InputStyle>
          <CTAButtonStyle style={{ width: "100%" }} type="submit" disabled={isSubmitting || !madeChanges}>
            {isSubmitting ? "Loading" : "Save room list"}
          </CTAButtonStyle>
        </InputStyle>
      </FormStyle>
    </div>
  );
};
