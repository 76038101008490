import React from "react";
import { CardStyle, CardTitle, CardTitleBar } from "../../../../GenericComponents/CardBoard/styles";
import { ExpandedPanelContainer, ExpandedPanelHeaderStyle, ExpandedPanelInnerStyle } from "../../../../styles/popups";
import { useDataLoader, useMultipleMonitorsDataLoader } from "../../dataLoader";
import { DataViewCsvDownload, DataViewCsvDownloadBig } from "../../DataViewComponents";
import { ExpandedCardLoader } from "../../CardLoader";
import { TextFormatter } from "../../../TextFormatter/TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { defaultDateRangeState, refreshTimes } from "../../../DateRangePicker/utils";
import { Br, FlexBox } from "../../../../styles/singlePageStyles";
import { CardExpandedHr, DataViewButton, ErrorMessage } from "../../../../styles/app";
import { IDataDownloadCardProps, IDataDownloadCollapsedCardProps, IDataDownloadExpandedCardProps } from "./lib";
import { DataDownloadOptions } from "./DataDownloadOptions";
import { IDateRangeState } from "../../../DateRangePicker/lib";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { AuthContext } from "react-oauth2-code-pkce";

export const DataDownloadCardInner = ({ id, label, additionalProps }: IDataDownloadCollapsedCardProps) => {
  const downloadAllMonitor = additionalProps?.downloadAllMonitors || false;
  const imageUrl = downloadAllMonitor
    ? "/images/app/general/mega-data-download.png"
    : "/images/app/general/data-download.png";
  return (
    <div className="inner">
      <CardTitleBar>
        <CardTitle>
          <DataViewButton to={`/app/data/${id}`}>{String(label)}</DataViewButton>
        </CardTitle>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={imageUrl} alt="Data download" style={{ maxWidth: "100%", height: "auto" }} />
        </div>
      </CardTitleBar>
    </div>
  );
};
export const DataDownloadExpanded = ({
  label,
  monitorId,
  description,
  longDescription,
  onLoadedData,
}: IDataDownloadExpandedCardProps) => {
  const defaultDataDownloadDateRange: IDateRangeState = {
    ...defaultDateRangeState(),
    aggregation: EAggregationInterval.MINUTE,
    minAggregation: EAggregationInterval.MINUTE,
    maxAggregation: EAggregationInterval.TEN_MINUTE,
  };
  const [dateRange, setDateRange] = React.useState(defaultDataDownloadDateRange);
  const { loadedData, loading, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric: null, // Return all
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
    onLoadedData,
  });

  return (
    <div className="DataDownloadExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <CardExpandedHr />
      <TextFormatter text={String(description)} keywordMap={keywordMap} />
      <Br />
      <DataDownloadOptions
        reloadData={() => setDateRange(refreshTimes(dateRange))}
        loading={loading}
        setDateRange={setDateRange}
        dateRange={dateRange}
      />
      {dataLoadError && (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadError && dataLoadError.message}</ErrorMessage>
        </FlexBox>
      )}
      {loading ? "Loading Data..." : ""}
      <Br />
      {longDescription && (
        <div data-testid="long-description">
          <TextFormatter text={longDescription} keywordMap={keywordMap} />
        </div>
      )}

      {readyToView && (
        <FlexBox horiz center>
          <DataViewCsvDownloadBig data={loadedData} style={{ width: "100%", textAlign: "center" }} />
        </FlexBox>
      )}
    </div>
  );
};

export const MultipleMonitorsDataDownloadExpanded = ({
  label,
  description,
  longDescription,
}: IDataDownloadExpandedCardProps) => {
  const { token } = React.useContext(AuthContext);
  const { loadedData, loading, dataLoadError, readyToView } = useMultipleMonitorsDataLoader({ accessToken: token });

  return (
    <div className="DataDownloadExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <TextFormatter text={String(description)} keywordMap={keywordMap} />
      <Br />
      {dataLoadError && (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadError && dataLoadError.message}</ErrorMessage>
        </FlexBox>
      )}
      {loading ? "Loading Data..." : ""}
      <Br />
      {longDescription && (
        <div data-testid="long-description">
          <TextFormatter text={longDescription} keywordMap={keywordMap} />
        </div>
      )}

      {readyToView && (
        <FlexBox horiz center>
          <DataViewCsvDownloadBig
            data={loadedData}
            style={{ width: "100%", textAlign: "center" }}
            multipleMonitors={true}
            includeTime={false}
          />
        </FlexBox>
      )}
    </div>
  );
};

export const DataDownloadCard = (props: IDataDownloadCardProps) => (
  <CardStyle>
    <DataDownloadCardInner {...props} />
  </CardStyle>
);

export const DataDownloadCardExpanded = (props: IDataDownloadCardProps) => {
  const { uniqueCardId, additionalProps } = props;

  const downloadAllMonitor = additionalProps?.downloadAllMonitors || false;

  const [loadedData, setLoadedData] = React.useState<any>(null);
  // Callback function to receive data from the child component
  const handleLoadedData = (data: any) => {
    setLoadedData(data);
  };

  return (
    <>
      <ExpandedPanelContainer data-testid={`${uniqueCardId}_expanded-panel`}>
        <ExpandedPanelHeaderStyle style={{ backgroundColor: "#303b71", justifyContent: "flex-end" }}>
          {loadedData && <DataViewCsvDownload data={loadedData} />}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle>
          <ExpandedCardLoader childProps={props}>
            {(propsFull: IDataDownloadExpandedCardProps) =>
              downloadAllMonitor ? (
                <MultipleMonitorsDataDownloadExpanded {...propsFull} onLoadedData={handleLoadedData} />
              ) : (
                <DataDownloadExpanded {...propsFull} onLoadedData={handleLoadedData} />
              )
            }
          </ExpandedCardLoader>
        </ExpandedPanelInnerStyle>
      </ExpandedPanelContainer>
    </>
  );
};
