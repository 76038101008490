import React from "react";
import { Link } from "react-router-dom";
import { ConstrainedPageStyle, Section, SectionHeading, P } from "../styles/page";

const PioneerSchoolsPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <Section>
        <SectionHeading>UPDATE April 2023</SectionHeading>
        <P>Our Pioneers phase has now finished and SAMHE has officially launched!</P>
        <P>Would your school like to join SAMHE and receive a monitor and access to the Web App?</P>
        <P>
          <Link to="/register/school">Register now</Link>
        </P>
        <P>
          If you need more information first,{" "}
          <Link to="/get-involved">read about what being a SAMHE school involves</Link>.
        </P>
        <P>
          If you would like more detail about how Pioneer Schools contributed to the design of the SAMHE Web App, head
          to our <Link to="/co-design">co-design process page</Link>.
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default PioneerSchoolsPage;
