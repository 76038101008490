import React from "react";
import styled from "styled-components";
import { TActivityId } from "../../../lib/Activities/IActivity";
import { IDataDetectivesStep } from "../../../lib/Activities/IDataDetectives";
import {
  EStepStatus,
  IStep,
  IStepSubmission,
  IStepUserData,
  TStepAdditionalProps,
  TStepId,
  TStepRef,
  TStepState,
} from "../../../lib/Activities/IStep";
import { MonitorId } from "../../../lib/Monitor/IMonitor";
import { StepComponentMap } from "./stepComponentMap";
import { StepComponentWrap } from "./StepComponentWrap";
import { UserGroup } from "../../../lib/User/UserGroup";
import { RoomId } from "../../../lib/School/IRoom";

export interface IMappedActivityStepsProps {
  activitySteps: IStep<TStepAdditionalProps>[];
  stepsState: IStepUserData<TStepState>[];
  activeStepId: TStepId;
  previousSteps: TStepRef[];
  monitorId: MonitorId;
  roomId?: RoomId;
  activityId: TActivityId;
  submitStep: (
    activityId: TActivityId,
    stepId: TStepId,
    submittedData: IStepSubmission<TStepState>,
    nextStep?: TStepId
  ) => void;
  goToStep: (stepId: TStepId) => void;
  lockCompleteSteps?: boolean;
  allowUnlockCompleteSteps?: boolean;
  attemptNo: number;
  userGroup: UserGroup;
}

export interface IMappedStepProps {
  i: number;
  step: IStep<any>;
  stepState?: IStepUserData<TStepState>;
  activeStepId: TStepId;
  previousSteps: TStepRef[];
  activitySteps: IStep<TStepAdditionalProps>[];
  stepsState: IStepUserData<TStepState>[];
  monitorId: MonitorId;
  roomId?: RoomId;
  activityId: TActivityId;
  submitStep: (
    activityId: TActivityId,
    stepId: TStepId,
    submittedData: IStepSubmission<TStepState>,
    nextStep?: TStepRef
  ) => void;
  goToStepRef: (ref: TStepRef) => void;
  lockCompleteSteps?: boolean;
  allowUnlockCompleteSteps?: boolean;
}

export const MappedStep: React.FC<IMappedStepProps> = ({
  i,
  step,
  stepState,
  stepsState,
  activeStepId,
  previousSteps,
  activitySteps,
  monitorId,
  roomId,
  activityId,
  submitStep,
  goToStepRef,
  lockCompleteSteps,
  allowUnlockCompleteSteps,
}) => {
  const StepComponent = StepComponentMap[step.type] || ((_props) => <>Invalid Component: {step.type}</>);
  return (
    <StepComponentWrap
      index={i}
      isActive={step.id === activeStepId}
      previousSteps={previousSteps}
      optional={step.optional}
      key={step.id}
      stepData={step}
      loadedState={stepState}
      goToStepRef={goToStepRef}
      lockCompleteSteps={lockCompleteSteps}
      allowUnlockCompleteSteps={allowUnlockCompleteSteps}
    >
      {({ onChange, state, onSubmit }) => (
        <StepComponent
          {...step}
          {...stepState}
          previousStepsState={stepsState}
          activitySteps={activitySteps}
          stepId={step.id}
          monitorId={monitorId}
          activityId={activityId}
          stepRef={step.stepRef}
          state={state}
          onChange={onChange}
          onSubmit={({newValue: updatedState, roomIdOverride, monitorIdOverride, nextStep}) => {
            onSubmit();
            submitStep(
              activityId,
              step.id,
              {
                stepId: step.id,
                stepRef: step.stepRef,
                state: updatedState,
                status: EStepStatus.COMPLETED,
                monitorReferenceId: monitorIdOverride === undefined ? monitorId : monitorIdOverride,
                roomId: roomIdOverride === undefined ? roomId : roomIdOverride,
              },
              nextStep
            );
          }}
        />
      )}
    </StepComponentWrap>
  );
};

export const MappedActivitySteps = ({
  activitySteps,
  stepsState,
  activeStepId,
  previousSteps,
  monitorId,
  roomId,
  activityId,
  submitStep,
  goToStep,
  lockCompleteSteps,
  allowUnlockCompleteSteps,
  attemptNo,
  userGroup,
}: IMappedActivityStepsProps) =>
  activitySteps
    .filter(
      /* Filter by user role limit */
      (step) => !step.userRoleLimit || step.userRoleLimit.length === 0 || step.userRoleLimit.indexOf(userGroup) !== -1
    )
    .map((step, i) => (
      <MappedStep
        i={i}
        key={`${step.stepRef}_${attemptNo}`} // Force refresh on attempt no change
        step={step}
        stepState={stepsState
          ?.slice()
          .reverse()
          ?.find((s) => s.stepRef === step.stepRef)}
        stepsState={stepsState}
        activeStepId={activeStepId}
        previousSteps={previousSteps}
        activitySteps={activitySteps}
        monitorId={monitorId}
        roomId={roomId}
        activityId={activityId}
        submitStep={(id, stepId, data, nextStepRef) => {
          submitStep(id, stepId, data, nextStepRef && activitySteps.find((step) => step.stepRef === nextStepRef)?.id);
        }}
        goToStepRef={(stepRef: TStepRef) => goToStep(activitySteps.find((step) => step.stepRef === stepRef).id)}
        lockCompleteSteps={lockCompleteSteps}
        allowUnlockCompleteSteps={allowUnlockCompleteSteps}
      />
    ));

const groupSteps = (
  acc: { [k: number]: IStep<TStepAdditionalProps>[] },
  v: IStep<TStepAdditionalProps>
): { [k: number]: IStep<TStepAdditionalProps>[] } => {
  if (!v.group) throw new Error(`All steps must specify group. ${v.id} is missing a group`);
  const newGroupedAcc = { ...acc, [v.group]: [...(acc[v.group] || []), v] };
  return newGroupedAcc;
};

export const StepGroupStyle = styled.div`
  border: "1px solid red";
  margin: 1rem;
  background: grey;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const MappedAndGroupedActivitySteps = ({
  activitySteps,
  stepsState,
  activeStepId,
  previousSteps,
  monitorId,
  roomId,
  activityId,
  submitStep,
  goToStep,
  attemptNo,
  userGroup,
}: IMappedActivityStepsProps) => {
  const groupedSteps = activitySteps.reduce(groupSteps, {});
  const groups = Object.keys(groupedSteps).sort();
  return groups.map((gi) => (
    <StepGroupStyle key={gi}>
      <h1>Group: {gi}</h1>
      {MappedActivitySteps({
        activitySteps: groupedSteps[gi],
        stepsState,
        activeStepId,
        previousSteps,
        monitorId,
        roomId,
        activityId,
        submitStep,
        goToStep,
        attemptNo,
        userGroup,
      })}
    </StepGroupStyle>
  ));
};

export interface IMappedFlowActivityStepsProps {
  activitySteps: IDataDetectivesStep<TStepAdditionalProps>[];
  stepsState: IStepUserData<TStepState>[];
  activeStepId: TStepId;
  previousSteps: TStepRef[];
  monitorId: MonitorId;
  roomId?: RoomId;
  activityId: TActivityId;
  lockCompleteSteps?: boolean;
  submitStep: (
    activityId: TActivityId,
    stepId: TStepId,
    submittedData: IStepSubmission<TStepState>,
    nextStep?: TStepId
  ) => void;
  goToStep: (stepId: TStepId) => void;
}

export interface IMappedpreviousStepsArgs {
  activitySteps: IDataDetectivesStep<TStepAdditionalProps>[];
  activeStepId: TStepId;
  stepsState: IStepUserData<TStepState>[];
  previousSteps: TStepRef[];
  monitorId: MonitorId;
  roomId?: RoomId;
  activityId: TActivityId;
  goToStep: (stepId: TStepId) => void;
}
export interface IMappedActiveStepsArgs {
  activitySteps: IDataDetectivesStep<TStepAdditionalProps>[];
  activeStepId: TStepId;
  previousSteps: TStepRef[];
  stepsState: IStepUserData<TStepState>[];
  monitorId: MonitorId;
  roomId?: RoomId;
  activityId: TActivityId;
  submitStep: (
    activityId: TActivityId,
    stepId: TStepId,
    submittedData: IStepSubmission<TStepState>,
    nextStep?: TStepId
  ) => void;
  goToStep: (stepId: TStepId) => void;
}

export const MappedpreviousSteps = ({
  activitySteps,
  activeStepId,
  stepsState,
  previousSteps,
  monitorId,
  roomId,
  activityId,
  goToStep,
}: IMappedpreviousStepsArgs) => {
  const previousStepsMapped = previousSteps
    .map((stepId) => activitySteps.find((step) => step.stepRef === stepId))
    .map((step) => ({ ...step, complete: true }));
  return previousStepsMapped.map((step, i) => (
    <MappedStep
      i={i}
      key={step.stepRef}
      step={step}
      stepState={stepsState?.find((s) => s.stepRef === step.stepRef)}
      stepsState={stepsState}
      activeStepId={activeStepId}
      previousSteps={previousSteps}
      activitySteps={activitySteps}
      monitorId={monitorId}
      roomId={roomId}
      activityId={activityId}
      submitStep={() => {}} // TODO: Shouldn't need to supply this
      goToStepRef={(stepRef: TStepRef) => goToStep(activitySteps.find((step) => step.stepRef === stepRef).id)}
    />
  ));
};

export const MappedActiveStep = ({
  activitySteps,
  activeStepId,
  previousSteps,
  stepsState,
  monitorId,
  roomId,
  activityId,
  submitStep,
  goToStep,
}: IMappedActiveStepsArgs) => {
  const activeStepData = activeStepId ? activitySteps.find((step) => step.id === activeStepId) : activitySteps[0];
  if (!activeStepData) throw new Error(`Could not get step data for ${activeStepId}`);
  const handleSubmitStep =
    (isLastStep?: boolean) =>
    (activityId: TActivityId, stepId: TStepId, submittedData: IStepSubmission<TStepState>, nextStepRef?: TStepRef) => {
      submitStep(
        activityId,
        stepId,
        submittedData,
        isLastStep ? null : nextStepRef && activitySteps.find((step) => step.stepRef === nextStepRef)?.id
      );
    };
  return (
    <MappedStep
      i={0}
      key={activeStepData.stepRef}
      step={activeStepData}
      // Step state disabled for active step
      // stepState={stepsState?.find((s) => s.stepRef === activeStepData.stepRef)}
      stepsState={stepsState}
      activeStepId={activeStepId}
      previousSteps={previousSteps}
      activitySteps={activitySteps}
      monitorId={monitorId}
      roomId={roomId}
      activityId={activityId}
      submitStep={handleSubmitStep(activeStepData.isLastStep)}
      goToStepRef={(stepRef: TStepRef) => goToStep(activitySteps.find((step) => step.stepRef === stepRef).id)}
    />
  );
};

export const MappedFlowActivitySteps = ({
  activitySteps,
  stepsState,
  activeStepId,
  previousSteps,
  monitorId,
  roomId,
  activityId,
  lockCompleteSteps,
  submitStep,
  goToStep,
}: IMappedFlowActivityStepsProps) => {
  const previousStepsMapped = previousSteps
    .map((stepId) => activitySteps.find((step) => step.stepRef === stepId))
    .map((step) => ({ ...step, complete: true }));
  const activeStepData = activeStepId ? activitySteps.find((step) => step.id === activeStepId) : activitySteps[0];
  if (!activeStepData) throw new Error(`Could not get step data for ${activeStepId}`);
  const handleSubmitStep =
    (isLastStep?: boolean) =>
    (activityId: TActivityId, stepId: TStepId, submittedData: IStepSubmission<TStepState>, nextStepRef?: TStepRef) => {
      submitStep(
        activityId,
        stepId,
        submittedData,
        isLastStep ? null : nextStepRef && activitySteps.find((step) => step.stepRef === nextStepRef)?.id
      );
    };

  const activeStepState: IStepUserData<any> = {
    ...activeStepData,
    stepId: activeStepData.id,
    dateCreated: new Date(),
    ...((previousSteps.find((s) => s === activeStepData.stepRef) &&
      stepsState?.find((s) => s.stepId === activeStepId)) ||
      {}),
    status: previousSteps.find((s) => s === activeStepData.stepRef) ? EStepStatus.IN_PROGRESS : EStepStatus.NOT_STARTED,
  };

  return [...previousStepsMapped, { ...activeStepData, complete: false }].map((step, i) => (
    <MappedStep
      i={i}
      key={step.stepRef}
      step={step}
      stepState={
        step.id === activeStepId
          ? activeStepState
          : stepsState
              ?.slice()
              .reverse()
              .find((s) => s.stepRef === step.stepRef)
      }
      stepsState={stepsState}
      activeStepId={activeStepId}
      previousSteps={previousSteps}
      activitySteps={activitySteps}
      monitorId={monitorId}
      roomId={roomId}
      activityId={activityId}
      submitStep={step.complete ? () => {} : handleSubmitStep(activeStepData.isLastStep)}
      goToStepRef={(stepRef: TStepRef) => goToStep(activitySteps.find((step) => step.stepRef === stepRef).id)}
      lockCompleteSteps={lockCompleteSteps}
    />
  ));
};
