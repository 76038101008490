import { ApiError, getDataFetch, IApiResponse, throwErrors } from "./apiHelpers";
import { MAX_DATA_POINTS, SERVER_URL } from "../config";
import { DataMetricsMetaData, EDataMetrics, EDataMetricsStrId } from "../lib/DataViews/EDataMetrics";
import { IMonitorData } from "../lib/MonitorData/IMonitorData";
import { MonitorId } from "../lib/Monitor/IMonitor";
import { EAggregationInterval } from "../lib/MonitorData/AggregationIntervals";

export interface IApiMonitorDataReturnElement {
  dateStamp: string;
  field: EDataMetricsStrId;
  value: string;
  monitorReferenceId?: string;
}

export interface IApiMonitorDataReturn extends IApiResponse {
  data: IApiMonitorDataReturnElement[];
  success: boolean;
}

export const apiGetMonitorData = async (
  monitorId: MonitorId,
  metric: EDataMetrics | null,
  startTime: Date = null,
  endTime: Date = null,
  aggregationInterval: EAggregationInterval = EAggregationInterval.MINUTE
): Promise<IMonitorData<number>> => {
  const startTimeQ = startTime || new Date(new Date(Date.now() - 1000 * 60 * 60));
  const endTimeQ = endTime || new Date(Date.now());
  if (endTimeQ.valueOf() - startTimeQ.valueOf() <= 0) {
    throw new Error(
      `Invalid arguments supplied to get monitor data: start: ${startTimeQ}, end: ${endTimeQ}, metric: ${metric}\n input start: ${startTime}, input end: ${endTime}`
    );
  }
  const startTimeQuery = `start=${startTimeQ.toISOString()}`;
  const endTimeQuery = `end=${endTimeQ.toISOString()}`;

  const metricData = metric && DataMetricsMetaData[metric];
  const metricQuery = metric && `metric=${metricData.strid}`;
  if (metric && !metricData) throw Error("Could not get metric data");

  const aggregationIntervalQuery = `aggregatePeriod=${aggregationInterval}`;
  const query = [startTimeQuery, endTimeQuery, metricQuery, aggregationIntervalQuery].filter((f) => f).join("&");
  let url = `${SERVER_URL}/monitorReadings/${monitorId}`;
  url = query ? encodeURI(`${url}?${query}`) : url;
  return getDataFetch<IApiMonitorDataReturn>(url)
    .then(throwErrors)
    .then(({ response }) => ({
      metric,
      data: response.json.data
        .slice(-MAX_DATA_POINTS)
        .filter((r) => !metric || r.field === metricData.strid)
        .map((r) => ({ dateStamp: new Date(r.dateStamp), field: r.field, value: parseFloat(r.value) })),
      startTime: startTimeQ,
      endTime: endTimeQ,
    }))
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }
      console.info(e);
      throw Error("Unknown Error loading Monitor Data");
    });
};

export const apiGetMonitorDataLatest = async (
  monitorId: MonitorId,
  metric: EDataMetrics
): Promise<IMonitorData<number>> => {
  const metricData = DataMetricsMetaData[metric];
  const metricQuery = `metric=${metricData.strid}`;
  const query = [metricQuery].filter((f) => f).join("&");
  if (!metricData) throw Error("Could not get metric data");
  let url = `${SERVER_URL}/monitorReadings/${monitorId}`;
  url = query ? `${url}?${query}` : url;
  return getDataFetch<IApiMonitorDataReturn>(url)
    .then(throwErrors)
    .then(({ response }) => ({
      metric,
      data: response.json.data
        .filter((r) => r.field === metricData.strid)
        .slice(-1)
        .map((r) => ({ dateStamp: new Date(r.dateStamp), field: r.field, value: parseFloat(r.value) })),
    }))
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }
      throw Error("Unknown Error loading Monitor Data");
    });
};

export const apiGetMultipleMonitorsData = async (accessToken: string): Promise<IMonitorData<number>> => {
  const headers = { Authorization: "Bearer " + accessToken };
  const url = `${SERVER_URL}/monitorReadings`;
  return getDataFetch<IApiMonitorDataReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => ({
      data: response.json.data.map((r) => ({
        dateStamp: new Date(r.dateStamp),
        field: r.field,
        value: parseFloat(r.value),
        monitorReferenceId: r.monitorReferenceId,
      })),
    }))
    .catch((e) => {
      if (e instanceof ApiError) {
        throw e;
      }
      console.info(e);
      throw Error("Unknown Error loading Monitor Data");
    });
};

export const apiMonitorData = {
  getRange: apiGetMonitorData,
  getLatest: apiGetMonitorDataLatest,
  getAll: apiGetMultipleMonitorsData,
};
