import React from "react";
import { PingStatusIcon } from "./styles";
import { EAuthStatus, EPingStatus, usePingTest } from "./usePingTest";

export const PingTest = () => {
  const { connectionStatus, authStatus, retry, status } = usePingTest({ timeout: 1000, maxAttempts: 15 });
  const message =
    (connectionStatus === EPingStatus.LOADING && "Checking connection") ||
    (connectionStatus === EPingStatus.ERROR && "Error connecting to server!") ||
    (connectionStatus === EPingStatus.TIMED_OUT && "Timed out connecting to server!") ||
    (authStatus === EAuthStatus.LOADING && "Checking Authentication") ||
    (authStatus === EAuthStatus.TIMED_OUT && "Timed out authenticating user!") ||
    (authStatus === EAuthStatus.OK && "Connection Ok") ||
    "Authentication Failed";

  return (
    <PingStatusIcon data-testid="ping-test" onClick={retry} status={status}>
      <span>{message}</span>
    </PingStatusIcon>
  );
};
