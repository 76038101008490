import { IMonitorServerState, IMonitorCurrentState } from "../lib/Monitor/IMonitor";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { monitorIds } from "./monitorIds";
import { mockSchools } from "./schools";

export const mockServerMonitors: IMonitorServerState[] = [
  {
    Id: 1,
    MonitorReferenceId: monitorIds[0],
    DateActivated: new Date(Date.now()),
    SchoolId: mockSchools.find((s) => s.status === ESchoolStatus.ACTIVATED).schoolId,
  },
  {
    Id: 2,
    MonitorReferenceId: monitorIds[1],
    DateActivated: null,
    SchoolId: mockSchools.find((s) => s.status === ESchoolStatus.RECEIVED).schoolId,
  },
];

export const mockMonitors: IMonitorCurrentState[] = [
  {
    id: monitorIds[0],
    activated: true,
    room: null,
  },
  {
    id: monitorIds[1],
    activated: false,
    room: null,
  },
];
