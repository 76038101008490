import React from "react";
import styled from "styled-components";
import { CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { DateRangeDayOnlyPicker } from "../../../DateRangePicker/DateRangeDayOnlyPicker";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { DateTimePicker } from "../../../DateRangePicker/styles";
import { allowAllDayRanges, years } from "../../../DateRangePicker/utils";

export enum EDateRangeSelectionType {
  SIMPLE = "SIMPLE",
  GUIDED = "GUIDED",
}

export interface IDataRangePlotOptions {
  reloadData: () => void;
  loading: boolean;
  setStartYAxisAtZero: React.Dispatch<React.SetStateAction<boolean>>;
  startYAxisAtZero: boolean;
  setLiveUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  liveUpdate: boolean;
  setShowMinAndMax: React.Dispatch<React.SetStateAction<boolean>>;
  showMinAndMax: boolean;
  setShowAverage: React.Dispatch<React.SetStateAction<boolean>>;
  showAverage: boolean;
  dateRangeSelectionType: EDateRangeSelectionType;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
  startTime: string;
  endTime: string;
}

export const DataRangePlotOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const DataRangePlotOptions: React.FC<IDataRangePlotOptions> = ({
  reloadData,
  loading,
  setStartYAxisAtZero,
  startYAxisAtZero,
  setLiveUpdate,
  liveUpdate,
  setShowMinAndMax,
  showMinAndMax,
  setShowAverage,
  showAverage,
  dateRangeSelectionType,
  setDateRange,
  dateRange,
  setStartTime,
  setEndTime,
  startTime,
  endTime,
}) => {
  return (
    <DataRangePlotOptionsWrap style={{ marginTop: "0.5rem" }}>
      <CardInfoSectionWrapper open maxHeight="55rem">
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap style={{ gap: "1rem" }}>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <p>Select when your data should reload</p>
              <FlexList>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle onClick={() => setLiveUpdate((prev) => !prev)} on={liveUpdate ? "true" : undefined}>
                    Live Update
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection>
              <H2>Plot View Options</H2>
              <p>Select how your data is plotted</p>
              <FlexList flexwrap>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setStartYAxisAtZero((prev) => !prev)}
                    on={startYAxisAtZero ? "true" : undefined}
                  >
                    Start Y axis at 0
                  </OnOffButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setShowMinAndMax((prev) => !prev)}
                    on={showMinAndMax ? "true" : undefined}
                  >
                    Show Min and Max
                  </OnOffButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setShowAverage((prev) => !prev)}
                    on={showAverage ? "true" : undefined}
                  >
                    Show Average
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection style={{ flex: "10 1 25rem" }}>
              {dateRangeSelectionType === EDateRangeSelectionType.SIMPLE && (
                <FlexBox style={{ gap: "0.5rem", flex: "1 1 10rem" }}>
                  <DateRangeDayOnlyPicker
                    onChange={(newDateRange) => setDateRange(newDateRange)}
                    value={dateRange}
                    useAggregation
                    adaptiveAggregation
                    label="Select the date you would like to investigate"
                    allowTimeLimiting
                    allowedDayRanges={allowAllDayRanges}
                  />
                  <FlexBox>
                    <H2>Select your time range</H2>
                    <span>
                      <label htmlFor="timeSelectStart">Select the time of the start of the event</label>
                    </span>
                    <DateTimePicker
                      type="time"
                      id="timeSelectStart"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      style={{ maxWidth: "20rem" }}
                    />
                  </FlexBox>
                  <FlexBox>
                    <span>
                      <label htmlFor="timeSelectEnd">Select the time of the end of the event</label>
                    </span>
                    <DateTimePicker
                      type="time"
                      id="timeSelectEnd"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      style={{ maxWidth: "20rem" }}
                    />
                  </FlexBox>
                </FlexBox>
              )}
              {dateRangeSelectionType === EDateRangeSelectionType.GUIDED && (
                <DateRangePicker
                  onChange={(newDateRange) => setDateRange(newDateRange)}
                  value={dateRange}
                  useAggregation
                  adaptiveAggregation
                  allowedDayRanges={allowAllDayRanges}
                  allowedMaxDateRange={years(1)} // This avoids aggregating by more than an hour
                />
              )}

              <DateRangeInfo dateRange={dateRange} />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
    </DataRangePlotOptionsWrap>
  );
};
