import { Link } from "react-router-dom";
import { FaqItemWrapStyle } from "../../styles/faq";
import { Section, SectionHeading, P, Ul, FloatImageMax } from "../../styles/page";
import { FaqItem } from "../faq";
import { IFaqItem } from "../faq/lib";
import { AExternal } from "../Links";
import { CTALinkButtonStyle } from "../../styles/links";

const entries: IFaqItem[] = [
  {
    uid: "improving-ventilation-in-naturally-ventilated-rooms",
    label: "Improving ventilation in naturally ventilated rooms",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          In warmer weather, the best thing to do is open windows. In colder weather, there are concerns about heating
          costs, energy consumption, and cold draughts. The{" "}
          <Link to="#using-co2-monitors-to-indicate-ventilation-levels">
            CO<sub>2</sub> and temperature readings
          </Link>{" "}
          on your SAMHE monitor can help to manage the balance between thermal comfort, the heating cost of energy
          consumption and air quality. You may want to look at readings before pupils arrive and over breaks, to see if
          ventilation needs to be improved. In colder months, you may wish to periodically ventilate during breaks, to
          maintain a comfortable temperature. Here are some recommendations for different natural ventilation systems:
        </P>
        <Ul>
          <li>
            <b>All windows on one side, and at similar heights.</b> In warm months, all windows might need to be opened
            wide to increase ventilation to keep indoors cool and improve indoor air quality. In cooler months, cooler
            air will tend to flow through the lower portion of the windows, or particular windows depending on the wind.
            You can try opening different windows, to different extents, to try to avoid the cold outside air directly
            hitting people. If CO<sub>2</sub> levels need decreasing then try opening windows wider, but do so gradually
            to avoid indoors becoming too cold.
          </li>
          <li>
            <b>High and low windows/openings.</b> In warm months, open low and high windows to increase airflow between
            them, cooling the air and improving indoor air quality. In cooler months, try opening high windows first to
            mix stale air with fresh air without the cold outside air directly hitting people. If CO<sub>2</sub> levels
            need decreasing further then low level windows should be opened.
          </li>
          <li>
            <b>Windows / doors on different sides of the room.</b> Opening windows/doors on opposite sides of the room
            makes air move from one side to the other, ventilating the room. In colder months, opening all windows by a
            small amount will provide better airflow and be warmer for occupants than opening a single window widely.
          </li>
          <li>
            <b>Sash windows</b> (those with panels that move vertically). In colder months, open the high-level sash
            first to keep the room a comfortable temperature, if CO<sub>2</sub> levels do not drop then the low level
            sash windows too. In summer months, open both top and bottom levels.
          </li>
        </Ul>
      </div>
    ),
  },
  {
    uid: "improving-ventilation-in-mechanically-ventilated-rooms",
    label: "Improving ventilation in mechanically ventilated rooms",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          Mechanical ventilation systems should be properly maintained by trained staff following the manufacturer's
          instructions, including regularly cleaning ducts, changing filters, etc.
        </P>
        <P>
          Some systems are controlled by temperature and/or CO<sub>2</sub> sensors and ensuring that adequate set points
          are given is important to provide good ventilation. It is important to make sure that the mechanical
          ventilation systems are bringing in an appropriate amount of outdoor air. Partial air recirculation can be
          appropriate, particularly when combined with filtration (for example HEPA filters). These filters do not
          filter out CO<sub>2</sub> so if you have a CO<sub>2</sub> monitor then the concentrations that you see might
          be higher than those typically recommended and careful interpretation might be required.
        </P>
      </div>
    ),
  },
];

export const VentilationGuidanceContent = (
  <div>
    <Section>
      <SectionHeading id="what-is-ventilation-and-why-does-it-matter">
        What is ventilation and why does it matter?
      </SectionHeading>
      <P>
        Ventilation is the process of refreshing indoor air by allowing air to flow into a space whilst letting air
        (which may be stale) out. Ventilation helps us maintain good air quality in indoor spaces (see our webpages{" "}
        <Link to="/resources/air-pollution">defining air quality</Link> and{" "}
        <Link to="/resources/key-definitions">some key indoor air quality metrics</Link>), by diluting and removing
        pollutants from the air indoors. Ventilation is therefore important for promoting good health, including
        reducing the spread of respiratory infections such as COVID-19 or the flu, and it has been linked to better
        sleep and better concentration for learning. For further information, check out our{" "}
        <Link to="/resources/air-pollution-and-health">Air Pollution and Health page</Link>.
      </P>
    </Section>
    <Section>
      <SectionHeading id="summary-of-ventilation-recommendations-for-schools">
        Summary of ventilation recommendations for schools
      </SectionHeading>
      <FloatImageMax src="/images/resources/ventilation/window.jpg" alt="window" />
      <Ul>
        <li>
          Many of the spaces within our schools, including most classrooms, are naturally ventilated by airflows through
          open windows and doors (see below)
        </li>
        <li>In naturally ventilated rooms, open windows, doors and other vents</li>
        <li>
          To maximise natural ventilation:
          <Ul>
            <li>Windows/doors may need to be opened wider at both at high- and low-levels within the room, and/or</li>
            <li>Windows/doors may need to be opened on opposing sides of the room. </li>
          </Ul>
        </li>
        <li>
          During warm weather ventilation can typically be maximised without compromising thermal comfort, and will not
          affect heating costs or energy consumption.
        </li>
        <li>
          During colder periods, smaller window openings are sometimes sufficient to achieve adequate ventilation. In
          classrooms where it is an option, open high-level windows first to maintain thermal comfort.
        </li>
        <li>
          CO<sub>2</sub> levels provide an indication of the amount of ventilation relative to the occupancy within
          teaching spaces. So CO<sub>2</sub> monitors can be a good tool to decide when more or less ventilation is
          required.
          <Ul>
            <li>
              Actions to improve ventilation should be taken when CO<sub>2</sub> levels are consistently above 1,500ppm,
              you can use <Link to="/resources/monitor-leds">the table on our guidance on monitor readings page</Link>{" "}
              to help you manage your ventilation.
            </li>
            <li>
              If CO<sub>2</sub> levels consistently remain above 1,500ppm, and your actions do not improve this, then
              please seek support from your Senior Leadership Team.
            </li>
          </Ul>
        </li>
        <li>
          Mechanical ventilation systems should be maintained to manufacturer standards by trained HVAC personnel.
        </li>
      </Ul>
    </Section>
    <Section>
      <SectionHeading id="types-of-ventilation">Types of ventilation</SectionHeading>
      <P>
        Most UK classrooms are naturally ventilated. Some are mechanically ventilated, and some are mixed-mode or
        hybrid, which means they have a mix of both natural and mechanical ventilation.
      </P>
      <P>
        <b>Natural ventilation</b> relies on natural processes, such as wind and temperature differences, to drive air
        in and around buildings through openings such as windows, doors, chimneys, wind catchers, among others. This
        works because of laws of physics. Where there is a pressure difference between two bodies of air, the air will
        move from high pressure to low pressure. Wind blowing outside a window, and temperature differences between
        indoors and outdoors, create pressure differences and so can drive ventilation. Inside our schools, warm air
        rises and escapes through the upper portions of windows and any high-level vents, as this air escapes, air from
        outside is pulled in through the lower portions of windows and vents.
      </P>
      <P>
        If you have openable windows or a door to outdoors then your classroom has at least some natural ventilation.
        Note that fire doors should not be propped open and, therefore, fire doors cannot be regarded as providing
        ventilation.
      </P>
      <FloatImageMax src="/images/resources/ventilation/classroom_open_windows.jpg" alt="classroom with open windows" />
      <P>
        <b>Mechanical ventilation</b> systems mainly use fans to move fresh air in and stale air out of spaces. The air
        moves along ducts to vents (often fitted with grills) on the ceilings, walls or floor. Grills are also found in
        extract ventilation (often used in toilets and kitchens), but this only takes stale air out of rooms, and does
        not actively manage the incoming air supply, so is not considered mechanical ventilation.
      </P>
      <P>
        It can be hard to tell if you have mechanical ventilation as usually ducts are hidden behind walls or in
        ceilings, so look for covers over vents. Check with your Senior Leadership Team or site management staff if you
        are unsure.
      </P>
      <P>
        For more information on types of ventilation, see the{" "}
        <AExternal href="https://explainers.raeng.org.uk/ventilation-matters">
          National Engineering Policy Centre pages
        </AExternal>
        .
      </P>
    </Section>
    <Section>
      <SectionHeading id="recommendations-to-improve-ventilation">
        Recommendations to improve ventilation
      </SectionHeading>
      <FaqItemWrapStyle>
        {entries.map((item) => (
          <FaqItem key={item.uid} data={item} />
        ))}
      </FaqItemWrapStyle>
    </Section>
    <Section>
      <SectionHeading id="using-co2-monitors-to-indicate-ventilation-levels">
        Using CO<sub>2</sub> monitors to indicate ventilation levels
      </SectionHeading>
      <P>
        CO<sub>2</sub> monitors can be used to indicate the per person ventilation in a room, particularly in naturally
        ventilated classrooms. They are less useful when the number of occupants are low, in very large rooms, or in the
        presence of filters.
      </P>
      <P>
        The SAMHE monitors can be used to help decide whether ventilation needs to be improved.{" "}
        <Link to="/resources/monitor-leds">Our guidance on monitor readings</Link> page gives an overview of how your
        monitor will look at different CO<sub>2</sub> concentrations and indicates any actions that could be taken.
      </P>
      <FloatImageMax
        src="/images/resources/ventilation/monitor_display_orange_lights.jpg"
        alt="SAMHE monitor display orange lights"
      />
      <P>
        Following{" "}
        <AExternal href="https://www.gov.uk/government/publications/emg-and-spi-b-application-of-co2-monitoring-as-an-approach-to-managing-ventilation-to-mitigate-sars-cov-2-transmission-27-may-2021">
          guidance from the UK's Scientific Advisory Group for Emergencies (SAGE)
        </AExternal>
        , we take 800 ppm and below to indicate good ventilation. Above this, we recommend action should be taken to
        improve ventilation. For more information, see the{" "}
        <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
          DfE Guidance for naturally ventilated classrooms in BB101
        </AExternal>
        .
      </P>
      <P>
        Regularly monitoring CO
        <sub>2</sub> should allow you to spot any changes, take action and see the effects of those actions. Please note
        that even at high concentrations (over 1,500ppm) CO<sub>2</sub> levels are not directly dangerous to our health
        and there is no need to evacuate classrooms. Carbon dioxide, CO<sub>2</sub>, is <b>not</b> a poisonous gas
        (unlike carbon monoxide, CO).
      </P>
      <P>
        In some cases, high CO<sub>2</sub> readings can be due to a monitor fault, please see our{" "}
        <Link to="/resources/monitor-placement-guide#consistently-high-readings-during-normal-operation">
          Monitor placement and accuracy guide
        </Link>
        .
      </P>
      <P>
        If CO<sub>2</sub> levels in your classroom consistently rise above 1,500ppm, and any actions you take do not
        improve ventilation, please speak to your Senior Leadership Team. In such circumstances, long-term solutions to
        improve the classroom ventilation provision may need to be identified and implemented; the{" "}
        <Link to="/resources/air-cleaning-devices#hepa-filter">addition of stand-alone HEPA filter units</Link> may be a
        viable solution in the interim.
      </P>
    </Section>

    <Section>
      <SectionHeading id="ventilation-and-the-covid-19-pandemic">Ventilation and the COVID-19 pandemic</SectionHeading>
      <P>
        Throughout the pandemic, there has been much focus on the link between reducing the spread of infection, and
        increasing ventilation. Respiratory infections such as COVID-19, or the flu, can be transmitted through the air.
        For more information see our <Link to="/resources/air-pollution-and-health">Air pollution and health page</Link>
        .
      </P>
    </Section>
    <Section>
      <CTALinkButtonStyle to="/resources">Find more info on the Resources Hub</CTALinkButtonStyle>
    </Section>
  </div>
);

export const VentilationGuidance = () => {
  return VentilationGuidanceContent;
};
