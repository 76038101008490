import { ITag } from "../lib/Tags/ITag";
import { keywordMap } from "./keywords";

export const exampleTags: { [id: string]: ITag } = {
  temperature: {
    uid: "temperature",
    label: "Temperature",
    description: "Some info on temperature",
    longDescription: `The monitors also measure temperature. This is another useful factor to measure, because
    it can show how comfortable the room might feel to students and staff, and whether the heating systems, and
    the ventilation, are working well. If the ventilation is really good (with a CO₂ reading well below 800 ppm)
    and the room is too cold, then you can consider having your windows open slightly less wide. Keep referring
    back to your SAMHE monitor as you make adjustments.`,
  },
  co2: {
    uid: "co2",
    label: "CO₂",
    description: "PLACEHOLDER",
  },
  air_quality: {
    uid: "air_quality",
    label: "Air Quality",
    description: "PLACEHOLDER",
  },
  data: {
    uid: "data",
    label: "Data",
    description: "PLACEHOLDER",
  },
  dataentry: {
    uid: "dataentry",
    label: "Data Entry",
    description: "PLACEHOLDER",
  },
  measurements: {
    uid: "measurements",
    label: "Measurements",
    description: "PLACEHOLDER",
  },
  pm25: {
    uid: "pm25",
    label: "PM₂.₅",
    description: "PLACEHOLDER",
  },
  humidity: {
    uid: "humidity",
    label: "Humidity",
    description: "PLACEHOLDER",
  },
  tvoc: {
    uid: "TVOC",
    label: "TVOC",
    description: "PLACEHOLDER",
  },
  biology: {
    uid: "biology",
    label: "Biology",
    description: "PLACEHOLDER",
  },
  chemistry: {
    uid: "chemistry",
    label: "Chemistry",
    description: "PLACEHOLDER",
  },
  maths: {
    uid: "maths",
    label: "Maths",
    description: "PLACEHOLDER",
  },
  physicaleducation: {
    uid: "physicaleducation",
    label: "Physical education",
    description: "PLACEHOLDER",
  },
  ventilation: {
    uid: "ventilation",
    label: "Ventilation",
    description: "PLACEHOLDER",
  },
  thinkingScientifically: {
    uid: "thinkingScientifically",
    label: "Thinking Scientifically",
    description: "PLACEHOLDER",
  },
  ...keywordMap.reduce((acc, v) => ({ ...acc, [v.uid]: v }), {}),
};
