import React from "react";
import { Link } from "react-router-dom";
import { AExternal } from "../../components/Links";
import {
    Section,
    SectionHeading,
    P,
    FloatImageMax,
} from "../../styles/page";
import { BoxoutHero } from "../../styles/hero";

export const AboutAirPollutioncontent = (
    <>
        <Section>
            <SectionHeading>Why should we care about air?</SectionHeading>
            <P>
                Air is what you breathe in, and is mainly made of gases (nitrogen and oxygen, just less than 78% and 21%
                each, argon 0.9%, and then <Link to="/resources/key-definitions#carbon-dioxide">carbon dioxide</Link>{" "}
                makes up around 0.04%!). You can survive about a month without food, a few days without water, but only a
                few minutes without air - that is why air is so important…
            </P>
        </Section>
        <Section>
            <SectionHeading id="what-is-air-pollution">What is air pollution?</SectionHeading>
            <P>
                Air pollution is the combination of tiny airborne particles (solid or liquid) and certain gases that are
                mixed into the air around us, making it less healthy to breathe. These air pollutants mostly come from
                human activities such as car and lorry exhausts, factory smoke, and even from the furniture in our
                buildings and paint on our walls. However, some of the particles, such as desert dust (which can travel
                from the Sahara!) and mould spores, come from natural sources.
            </P>
            <P>
                We can measure the 'particulate matter' (PM) (or particles) in the air. Some of the gases found in our air
                are produced by commonly used solids and liquids naturally evaporating, these gases are typically volatile
                organic compounds (or VOCs - almost everything you smell is a VOC!). Other gases that are common air
                pollutants include nitrogen dioxide, sulphur dioxide, ozone or carbon monoxide. The SAMHE monitors measure
                {" "}
                <Link to="/resources/key-definitions#particulate-matter">particulate matter</Link>,{" "}
                <Link to="/resources/key-definitions#total-volatile-organic-compounds">
                    total volatile organic compounds
                </Link>{" "}
                but also <Link to="/resources/key-definitions#carbon-dioxide">carbon dioxide</Link>, along with{" "}
                <Link to="/resources/key-definitions#temperature">temperature</Link> and{" "}
                <Link to="/resources/key-definitions#relative-humidity">relative humidity</Link>.
            </P>
            <P>
                We're measuring carbon dioxide (CO<sub>2</sub>) because, although it is not actually a pollutant, it is a reliable indicator of
                some aspects of indoor air quality; higher indoor concentrations of CO<sub>2</sub> indoors correspond with
                high levels of rebreathed air and a lack of fresh air supplied to the room. For more information on what
                air pollution is, visit the{" "}
                <AExternal href="https://www.cleanairhub.org.uk/clean-air-information/the-basic-information/what-is-air-pollution">
                    Clean Air Hub
                </AExternal>
                .
            </P>
        </Section>
        <Section>
            <SectionHeading id="what-is-the-relationship-between-indoor-and-outdoor-air-quality">
                What is the relationship between indoor and outdoor air quality?
            </SectionHeading>
            <FloatImageMax
                src="/images/resources/air-pollution/open-window-from-outside.jpg"
                alt="building with open window"
            />
            <P>
                When people think about air pollution, they often think about the quality of the air outdoors. However,
                indoor air quality is also very important as most of us spend more than 80% of our time indoors.
            </P>
            <P>
                The sources of indoor and outdoor air pollution can be very different. For instance, within outdoor air,{" "}
                <Link to="/resources/key-definitions#particulate-matter">particulate matter</Link> mainly comes from cars,
                factories, planes and other human activities. On the other hand, within the indoor environment, this
                pollutant might come from fireplaces, cooking, and… outdoor air! For example, if quite a few children are
                dropped off or picked up by car at school you may be able to detect this within the indoor data.
            </P>
            <P>
                The reason why outdoor air can be a source of indoor air pollution is that air moves in and out of
                windows, doors, vents, cracks, etc. However, many pollutants can become more concentrated indoors than
                outdoors, as there is more volume of air outside to mix them in.{" "}
                <Link to="/resources/ventilation">Good ventilation</Link> is a way to make sure we introduce more air into
                indoor spaces and keep those pollutant concentrations lower.
            </P>
            <P>
                Overall, indoor and outdoor air quality are strongly linked. Knowing the quality of the air in the local
                area of your school or home, as well as the sources of pollution, can give you a good indication of how
                clean the air you breathe is. It can also give you ideas of what you can do to improve it. To see
                measurements of outdoor air quality, take a look at the{" "}
                <AExternal href="https://aqicn.org/map/unitedkingdom/">World Air Quality Index Project</AExternal>.
            </P>
        </Section>
        <Section>
            <SectionHeading id="is-air-pollution-an-issue">Is air pollution an issue?</SectionHeading>
            <P>
                Air pollutants can affect people's health, as well as the health of the environment. We breathe them in,
                they enter our lungs and then can move into the bloodstream where they can reach other organs such as our
                hearts and brains. Sometimes the effects of breathing polluted air happen to your body or mind quickly,
                but very often the effects build up over many years of exposure to polluted air - that's why it is good to
                always be mindful of the air you are breathing. You can read more about this on the{" "}
                <AExternal href="https://www.cleanairhub.org.uk/clean-air-information/the-basic-information/what-is-air-pollution">
                    Clean Air Hub
                </AExternal>
                .
            </P>
            <P>
                When carbon dioxide builds up in classrooms it can also make people feel sleepy, have headaches, and find
                it harder to learn. For information on carbon dioxide levels and how it can affect learning, see the{" "}
                <AExternal href="https://coschools.org.uk/">CoSchools videos</AExternal>.
            </P>
        </Section>
        <Section>
            <SectionHeading id="what-should-i-look-out-for-in-my-indoor-air-quality-data">
                What should I look out for in my indoor air quality data?
            </SectionHeading>
            <BoxoutHero>
                <P>
                    Sharp increases in the level of any of the indoor air quality metrics we're measuring (
                    <Link to="/resources/key-definitions#carbon-dioxide">
                        CO<sub>2</sub>
                    </Link>
                    ,{" "}
                    <Link to="/resources/key-definitions#particulate-matter">
                        PM<sub>2.5</sub>
                    </Link>{" "}
                    or <Link to="/resources/key-definitions#total-volatile-organic-compounds">TVOCs</Link>), may look alarming
                    even if they are short-lived,{" "}
                    <b>but it is much more important to look at averages and the overall trend</b>.
                </P>
                <P>
                    To protect our health, many organisations set limits for concentrations of some air pollutants. For an
                    overview of the reference levels relevant to the metrics we're measuring take a look at our{" "}
                    <Link to="/resources/monitor-leds">guidance on monitor readings page</Link>. As you will see, these
                    reference levels often relate to averages e.g.{" "}
                    <AExternal href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health">
                        WHO outdoor air quality guidelines
                    </AExternal>{" "}
                    for PM<sub>2.5</sub> exposure relate to averages over periods of at least 24 hours. Therefore, when you
                    are looking at data from your SAMHE monitor, focus on where your average reading (over the relevant
                    period) breaks any target threshold. Any spikes in the data will be captured in the average but, if
                    short-lived, they will not typically influence it very much and therefore do not generally present any
                    immediate cause for concern.
                </P>
                <P>
                    By looking at data over a few days or weeks, trends may begin to emerge. Thinking about whether there are
                    regular events coinciding with the data patterns you see which could affect air quality, such as cleaning,
                    large class sizes etc may help you to understand your data and decide if any action needs to be taken.
                </P>
                <P>
                    <b>The image below</b>{" "} shows data from a SAMHE monitor, with the average for the period shown of 6.83 µg/m<sup>3</sup>.
                    There are several spikes which go over the{" "}
                    <AExternal href="https://uk-air.defra.gov.uk/assets/documents/Air_Quality_Objectives_Update.pdf">
                        20µg/m<sup>3</sup> target annual mean value (for outdoor values) set by DEFRA
                    </AExternal>. Although the measured value goes over the threshold value for a short time, the average value is much lower.
                </P>
                <FloatImageMax src="/images/resources/air-pollution/PMsecreenshot.png" alt="screenshot showing PM2.5 data" style={{ borderRadius: '0.3rem' }} />
            </BoxoutHero>
        </Section>
        <Section>
            <SectionHeading id="what-can-we-do-about-air-pollution">What can we do about air pollution?</SectionHeading>
            <P>
                Global Action Plan has produced a helpful{" "}
                <AExternal href="https://www.transform-our-world.org/programmes/clean-air-for-schools">
                    Clean Air for Schools framework
                </AExternal>
                , where you can develop an action plan for your school to tackle air pollution. It's free and easy to use.
                Some schools have created <AExternal href="http://schoolstreets.org.uk/">School Streets</AExternal>, where
                motor traffic is restricted during school drop-off and pick-up times. Our partners the University of
                Surrey have developed guidance on how to reduce pupils'{" "}
                <AExternal href="https://www.surrey.ac.uk/global-centre-clean-air-research/resources/guidance-for-schools">
                    exposure to traffic pollution
                </AExternal>
                .
            </P>
            <P>
                There are also tips about managing ventilation on the{" "}
                <AExternal href="https://coschools.org.uk">CoSchools website</AExternal>.
            </P>
        </Section>
    </>
);

export const AboutAirPollution = () => {
    return AboutAirPollutioncontent;
};
