import React from "react";
import styled from "styled-components";
import { CTAButtonStyle } from "../../../../styles/button";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { EDayRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { weeks } from "../../../DateRangePicker/utils";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";

export const BubblePlotOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export interface IBubblePlotOptions {
  reloadData: () => void;
  loading: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
}

export const BubblePlotOptions = ({ setDateRange, dateRange, reloadData, loading }) => {
  return (
    <BubblePlotOptionsWrap>
      <CardInfoSectionWrapper open maxHeight="55rem" style={{ width: "100%" }}>
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap style={{ gap: "1rem" }}>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <p>Select when your data should reload</p>
              <FlexList>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection style={{ flex: "10 1 25rem" }}>
              <DateRangePicker
                onChange={(newDateRange) => setDateRange(newDateRange)}
                value={dateRange}
                allowTimeLimiting
                allowedDayRanges={[
                  EDayRange.CUSTOM,
                  EDayRange.TODAY,
                  EDayRange.YESTERDAY,
                  EDayRange.THIS_WEEK,
                  EDayRange.LAST_SCHOOL_WEEK,
                  EDayRange.SCHOOL_DAYS,
                ]}
                allowedMaxDateRange={weeks(1)}
                allowedMinAggregation={EAggregationInterval.HOUR}
                allowedMaxAggregation={EAggregationInterval.HOUR}
              />
              <DateRangeInfo dateRange={dateRange} />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
    </BubblePlotOptionsWrap>
  );
};
