import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider } from "styled-components";
import {
  CardIconBtnLink,
  CardIconStyle,
  CardTitle,
  HideInfoSectionButton,
} from "../../../GenericComponents/CardBoard/styles";
import { EActivityStatus } from "../../../lib/Activities/IActivity";
import {
  ActivityStepsSection,
  ActivitySectionTitle,
  ActivityStartBtnWrap,
  CardStatusIconStyle,
  ActivityStepsSectionInner,
  ActivityExpandedContainer,
} from "../../../styles/app";
import { CTAButtonStyle } from "../../../styles/button";
import { ExpandedPanelInnerStyle, ExpandedPanelHeaderStyle, Hr } from "../../../styles/popups";
import { lockedactivityTheme } from "../../../styles/themes";
import { unlockedactivityTheme } from "../../../styles/themes";
import { ActivityStatusIcon } from "../CommonComponents/ActivityStatusIcon";
import { ActivityMetaDataDisplay } from "../CommonComponents/ActivityPanelMetaSection";
import { DownloadLink } from "../../Links";
import { IActivityCardProps } from "../IActivityCardProps";
import { useAppSelector } from "../../../Redux/hooks";

export interface IExpandedCardProps extends IActivityCardProps {
  activityTypeName: string;
  children: ({ scrollToTitle }: { scrollToTitle: () => void }) => React.ReactNode;
}

export const ExpandedCard = ({
  children,
  activityTypeName,
  /* activity data below */
  id,
  label,
  targetGrade,
  longDescription,
  timeEstimates,
  activitySteps,
  tags,
  completeActivity,
  imageUrl,
  startActivity,
  textFilePath,
  equipmentNeededDetailed,
  noOfPeople,
  cheatCodes,
  activityScores,
  previousActivities,
  previousActivitiesRequirement,
  activityUserProgress: { activeStepId, activityStatus, stepsState, attemptNo, isLocked },
}: IExpandedCardProps) => {
  const monitorId = useAppSelector((state) => state.userState?.state?.monitor?.id);
  const currentRoom = useAppSelector((state) => state.userState?.state?.room?.data?.currentRoom);
  const activeStepData = activeStepId ? activitySteps.find((step) => step.id === activeStepId) : activitySteps[0];

  const started =
    [EActivityStatus.IN_PROGRESS, EActivityStatus.COMPLETED, EActivityStatus.FAILED].indexOf(activityStatus) > -1;

  const [collapseMetaInfoPanel, setCollapseMetaInfoPanel] = React.useState(started);
  const cardTitleRef = React.useRef(null);
  const submitCheatCode = (cheatCodeEntry: string) => {
    if (cheatCodeEntry === cheatCodes.complete) {
      completeActivity(id, cheatCodeEntry);
    }
  };

  const scrollToTitle = () => {
    cardTitleRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={isLocked ? lockedactivityTheme : unlockedactivityTheme}>
      <ActivityExpandedContainer>
        <ExpandedPanelHeaderStyle ref={cardTitleRef}>
          {imageUrl && (
            <div style={{ display: "flex" }}>
              <CardIconBtnLink style={{ height: "7rem", width: "7rem", marginTop: "-2.5rem" }} className="cardIconBtn">
                <CardIconStyle src={imageUrl} alt={`${activityTypeName} activity: ${label}`} />
              </CardIconBtnLink>
              <CardStatusIconStyle
                style={{ borderRadius: "1.5rem 1.5rem 0 0", marginLeft: "0", marginRight: "0.4rem" }}
                className="activityStatusIcon"
              >
                <ActivityStatusIcon
                  style={{ height: "3rem", width: "3rem" }}
                  isLocked={isLocked}
                  activityStatus={activityStatus || EActivityStatus.NOT_STARTED}
                />
              </CardStatusIconStyle>
            </div>
          )}
          {textFilePath && (
            <DownloadLink style={{ margin: "0.1rem", marginRight: "1rem", alignSelf: "center" }} href={textFilePath}>
              Printable activity instructions
            </DownloadLink>
          )}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle style={{ minWidth: "100%" }} data-testid={`dataDetectives_expandedPanelInner-${id}`}>
          <CardTitle ref={cardTitleRef}>{label}</CardTitle>
          <Hr />
          <ActivityMetaDataDisplay
            targetGrade={targetGrade}
            activitySteps={activitySteps}
            longDescription={longDescription}
            timeEstimates={timeEstimates}
            textFilePath={textFilePath}
            stepsState={stepsState}
            activeStep={activeStepData}
            activityStatus={activityStatus || EActivityStatus.NOT_STARTED}
            isLocked={isLocked}
            tags={tags}
            collapseMetaInfoPanel={collapseMetaInfoPanel}
            equipmentNeededDetailed={equipmentNeededDetailed}
            noOfPeople={noOfPeople}
            activityScores={activityScores}
            cheatCodes={cheatCodes}
            attemptNo={attemptNo}
            submitCheatCode={submitCheatCode}
            previousActivities={previousActivities}
            previousActivitiesRequirement={previousActivitiesRequirement}
          />
          {started && (
            <HideInfoSectionButton
              onClick={() => {
                setCollapseMetaInfoPanel((prev) => !prev);
                scrollToTitle();
              }}
            >
              {collapseMetaInfoPanel ? "Show" : "Hide"} Info
            </HideInfoSectionButton>
          )}
          {!started && !isLocked && (
            <ActivityStartBtnWrap>
              <CTAButtonStyle
                onClick={() => {
                  startActivity(id, monitorId, currentRoom?.id);
                  setCollapseMetaInfoPanel(true);
                  scrollToTitle();
                }}
              >
                Start Activity
              </CTAButtonStyle>
            </ActivityStartBtnWrap>
          )}
          {isLocked && (
            <ActivityStartBtnWrap>
              <CTAButtonStyle>
                Activity is Locked <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={faLock} />
              </CTAButtonStyle>
            </ActivityStartBtnWrap>
          )}
          {started && (
            <ActivityStepsSection open={started} data-testid="activityStepsSection">
              <ActivityStepsSectionInner style={{ flexDirection: "column" }}>
                <ActivitySectionTitle>Activity Zone</ActivitySectionTitle>
                {children({ scrollToTitle })}
              </ActivityStepsSectionInner>
            </ActivityStepsSection>
          )}
        </ExpandedPanelInnerStyle>
      </ActivityExpandedContainer>
    </ThemeProvider>
  );
};
