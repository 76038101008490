import React from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { cardComponentOpenTypeMap } from "../../components/ActivitiesDashboard/activityCardComponentMap";
import {
  useGetSingleActivityMetaData,
  useLoadActivitiesStart,
} from "../../components/ActivitiesDashboard/useGetActivityMetaData";
import { useGetExpandedCard } from "../../GenericComponents/CardBoard/useGetCards";
import {
  ExpandedCardPageBackLink,
  ExpandedCardPageBackLinkWrapper,
  ExpandedCardPageStyle,
  AppPageStyle,
} from "../../styles/app";
import { useAppSelector } from "../../Redux/hooks";


const DEFAULT_FILTERS = {};
export const ActivityPage = () => {
  useLoadActivitiesStart({ filters: DEFAULT_FILTERS });
  const userRole = useAppSelector((state) => state.userState?.user?.group);
  const { activityId } = useParams<{ activityId: string }>();
  const activityData = useGetSingleActivityMetaData({ activityId: activityId, userRole });
  const card = useGetExpandedCard({ cardData: activityData, cardComponentTypeMap: cardComponentOpenTypeMap });
  return (
    <AppPageStyle
      data-testid="samheAppContent"
      style={{ width: "100%", maxWidth: "1200px", minWidth: "auto", margin: "auto" }}
    >
      <ExpandedCardPageStyle
        style={{ height: "100%", position: "relative", alignItems: "end" }}
        data-testid="appPage-activityView"
      >
        <ExpandedCardPageBackLinkWrapper>
          <ExpandedCardPageBackLink to="/app/activities">
            <FontAwesomeIcon style={{ marginRight: "0.3rem" }} icon={faCircleLeft} />
            Back to Activities
          </ExpandedCardPageBackLink>
        </ExpandedCardPageBackLinkWrapper>
        {card}
      </ExpandedCardPageStyle>
    </AppPageStyle>
  );
};
