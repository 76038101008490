import styled from "styled-components";
import { ButtonCSS, CTAButtonStyle } from "./button";
import { CTAAnchorButtonStyle } from "./links";

export const SignInScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  background: ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.appBackground};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    padding: 2rem;
    align-items: center;
  }

  * p {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
`;

export const FullAuthPanelWrap = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 0;

  > div {
    display: flex;
    flex-direction: column;
  }

  /* Only display on desktop */
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: 50%;
    > div {
      width: 40rem;
    }
  }
`;

export const SignInPanelWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
`;

export const SignInPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  max-width: 40rem;
  flex-grow: 1;
  background: ${({ theme }) => theme.background};
  border-radius: 2rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  * {
    text-align: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    padding: 2rem;
  }
`;

export const HelpButtonToggleButton = styled.button<{ open: boolean }>`
  ${ButtonCSS}
  ${({ open, theme }) =>
    open
      ? `

      position: relative;
      top: 0;
      right: 0;
      width: 1.5rem;
      height: 1.5 rem;
      background: none;
      border-radius: 0.2rem;
      padding: 0;
      margin-top: -0.5rem;
      margin-bottom: 1rem;

      &:hover {
        // color: ${theme.colors.main};
        // background: none;
        // border: none;
      }

  `
      : `
      width: 100%;
      // position: absolute;
      z-index: 10;
      inset: 0;
      background: none;
      margin: 0;
      outline: none;
      border: none;
      border-radius: 2rem;

      &:hover {
        color: ${theme.colors.main};
        background: none;
      }
  `}
`;

export const HelpPanel = styled.div<{ open: boolean }>`
  ${({ open }) =>
    open
      ? `
    position: absolute;
    inset: 0;
    z-index: 10;
    padding: 0.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
      padding: 2rem;
    }
  `
      : `
    position: relative;
    margin-top: 1rem;
  `}
  display: flex;
  max-width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background};
  border-radius: 2rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  * {
    text-align: center;
  }

  div,
  p {
    transition: opacity ${({ open }) => (open ? `0.5s` : `0s`)};
    opacity: ${({ open }) => (open ? `1` : `0`)};
    position: ${({ open }) => (open ? `relative` : `absolute`)};
    pointer-events: ${({ open }) => (open ? `` : `none`)};
  }
`;

export const SignInPanelTitle = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  margin-top: 0;
`;

export const SignInBoxes = styled.div`
  flex-direction: column;
  align-self: stretch;
  display: flex;
  /* Only display on desktop */
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    flex-direction: row;
    margin-left: -1rem;
    justify-content: stretch;
  }
`;

export const SignInBox = styled.div`
  border-radius: 5rem;
  background: ${({ theme }) => theme.background};
  flex-grow: 1;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  img {
    width: 4rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    margin-left: 1rem;
  }
`;

export const SignInButton = styled(CTAButtonStyle)`
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.tertiary};
  &:hover {
    background: ${({ theme }) => theme.colors.tertiaryHover};
  }
  flex-grow: 1;
`;

export const RegisterButton = styled(CTAAnchorButtonStyle)`
  padding: 0.5rem 1rem;
  flex-grow: 1;
`;

export const SignInPanelRhsPanel = styled.div`
  display: none;

  /* Only display on desktop */
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    height: 100%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
  }
`;

export const SamheBigLogo = styled.img`
  max-height: 80%;
  width: 100%;

  ::after {
    content: "";
    display: block;
    position: absolute;
    width: 5rem;
    height: 5rem;
  }

  @keyframes logoDance {
    0% {
      margin-bottom: 0;
      margin-top: 4rem;
    }
    50% {
      margin-bottom: 4rem;
      margin-top: 0rem;
    }
    100% {
      margin-bottom: 0;
      margin-top: 4rem;
    }
  }

  animation: logoDance 2.5s infinite;
`;

export const SamheBigLogoShadow = styled.div`
  background: black;
  border-radius: 10rem;
  width: 50%;
  height: 0rem;
  box-shadow: 0px 0px 20px 2px rgb(0 0 0);

  @keyframes shadowDance {
    0% {
      opacity: 1;
      width: 40%;
    }
    50% {
      opacity: 0.2;
      width: 80%;
    }
    100% {
      opacity: 1;
      width: 40%;
    }
  }

  animation: shadowDance 2.5s infinite;
`;

// TODO: Add activity icons somewhere
// export const SamheActivityIconWrapper = styled.div`
//   position: absolute;
// `;
// export const SamheActivityIcon = styled.img`
//   width: 3rem;
// `;
