import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  ConstrainedPageStyle,
  ListItem,
  PageTitle,
  Section,
  List,
  SectionHeading,
  P,
  FloatImage,
  SectionStyle,
} from "../styles/page";
import NotificationPage from "./notifications";
import { CTAAnchorButtonStyle, CTALinkButtonStyle } from "../styles/links";
import { useAppSelector } from "../Redux/hooks";
import { ESchoolStatus, SchoolStatus } from "../lib/School/ESchoolStatus";
import { UserGroup, UserGroups } from "../lib/User/UserGroup";
import { AUTH_URL_ACCOUNT_DELETE } from "../config";
import styled from "styled-components";

export const InfoWrapperDiv = styled(SectionStyle)`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: block;
  }
`;

export const PioneerBadgeDiv = styled.div`
  width: 25rem;
  margin-left: 2rem;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const UserProfilePage: React.FC = () => {
  const {
    user: { username, group, abProfile },
    state: {
      monitor: { id: monitorId } = { id: null },
      school: { id: schoolId, data: schoolData } = { id: null, data: null },
    },
  } = useAppSelector((state) => state.userState);

  const handleAccountDelete = (e) => {
    const message = `Deleting your account will delete your access to the SAMHE Web App including the records of any achievements you have earned. Are you sure you want to continue?

Clicking ok will take you to our user ID server to complete the account deletion process.`;
    if (!window.confirm(message)) {
      e.preventDefault();
    }
  };

  return (
    <ConstrainedPageStyle className="page_wrap samheAppWrapper">
      <PageTitle>
        Hi <b>{username}</b>!
      </PageTitle>

      <InfoWrapperDiv>
        <div>
          <p>
            User group: <b>{UserGroups[group]?.label || "User is not assigned to a user group"}</b>
          </p>
          <p>
            Monitor ID: <b>{monitorId}</b>
          </p>
          {group === UserGroup.SUPERADMIN ||
            (group === UserGroup.SCHOOLADMIN && (
              <>
                <p>
                  School ID: <b>{schoolId}</b>
                </p>
                <p>
                  School Status: <b>{SchoolStatus[schoolData?.status || ESchoolStatus.ERROR].label}</b>
                </p>
              </>
            ))}
        </div>

        {schoolData?.isPioneer && (
          <PioneerBadgeDiv>
            <FloatImage
              src="/images/app/general/shield_blue_pioneers.png"
              alt="badge for Pioneer Schools"
              desktopStyleOverride={"max-width: 10rem"}
              mobileStyleOverride={"max-width: 10rem"}
              direction="right"
            />
            <P>
              <b>Pioneers Badge</b>
              <br />
              This badge has been awarded in recognition of your school's contributions to the SAMHE Pioneers phase.
              Thank you for helping us to shape the Web App and make the project fun and engaging!
            </P>
          </PioneerBadgeDiv>
        )}
      </InfoWrapperDiv>
      {abProfile?.showABProfile && (
        <Section>
          <SectionHeading>A|B Profile</SectionHeading>
          <p>You have been signed up to our A|B system</p>
          <ul>
            {Object.keys(abProfile)
              .filter((k) => k !== "Id")
              .map((k) => (
                <li key={k}>
                  {k}: {JSON.stringify(abProfile[k])}
                </li>
              ))}
          </ul>
        </Section>
      )}
      <Section>
        <p>Use the links below to explore your profile.</p>
        <List className="list-reset">
          <ListItem>
            <CTALinkButtonStyle to="./achievements">Achievements</CTALinkButtonStyle>
          </ListItem>
          <ListItem>
            <CTALinkButtonStyle to="./notifications">Notifications</CTALinkButtonStyle>
          </ListItem>
        </List>
        <div data-testid="profile-subContent">
          <Routes>
            <Route path="notifications" element={<NotificationPage />} />
          </Routes>
        </div>
      </Section>
      <Section>
        <P>
          If you would like to delete your account you can do so using the button below. Please note that once your
          account is deleted there is no way of recovering it.
        </P>
        <P>
          <CTAAnchorButtonStyle href={AUTH_URL_ACCOUNT_DELETE} onClick={handleAccountDelete}>
            Delete Account
          </CTAAnchorButtonStyle>
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default UserProfilePage;
