import React from "react";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";
import { AdminParticipationInfo } from "../components/Documents/AdminParticipationInfo";

const AdminParticipationInfoPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE Admin Participation Info</PageTitle>
      <AdminParticipationInfo />
    </ConstrainedPageStyle>
  );
};

export default AdminParticipationInfoPage;
