import styled from "styled-components";

export const DocumentStyle = styled.div`
  ul {
    list-style: none;
    margin-left: 2.5rem;
  }
  li {
    position: relative;
    :after {
      content: " ";
      width: 10px;
      height: 10px;
      border: 1px solid black;
      border-radius: 2px;
      display: block;
      position: absolute;
      left: -1rem;
      top: 0.45rem;
    }
  }
  p {
    margin-top: 1rem;
  }
`;
