import React from "react";
import { useParams } from "react-router-dom";
import { MonitorSetup } from "../components/MonitorSetup";
import { UserGroup } from "../lib/User/UserGroup";
import { useAppSelector } from "../Redux/hooks";
import { ISession } from "../Redux/User/userReducer";
import { ConstrainedPageStyle } from "../styles/page";
import MonitorLinkingPage from "./monitorLinkingPage";

const QrPage: React.FC = () => {
  const { qrcode } = useParams();
  const userState = useAppSelector<ISession>((state) => state.userState);
  const {
    user: { group },
  } = userState as ISession;

  return (
    <>
      {group === UserGroup.DEPLOYMENTADMIN && <MonitorLinkingPage serialNumber={qrcode} />}
      {group === UserGroup.SCHOOLADMIN && (
        <ConstrainedPageStyle>
          <MonitorSetup />
        </ConstrainedPageStyle>
      )}
    </>
  );
};

export default QrPage;
