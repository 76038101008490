import React from "react";
import { AppSectionHeading, KeyIconStyle, KeyItemLabelButton, KeySectionStyle, KeyStyle } from "../../styles/app";
import { NoOfPeopleKeyItems } from "./CommonComponents/NoOfPeopleIcon";
import { CompleteCountKeyItems } from "./CommonComponents/CompleteCountIcon";
import { Hr } from "../../styles/page";
import { UserGroup } from "../../lib/User/UserGroup";
import { useAppSelector } from "../../Redux/hooks";
import { ActivityViewFields } from "../../lib/Activities/IActivity";
import { FilterStateContext } from "../../GenericComponents/CardBoard/Filters/FilterState";

export const ActivityKeyItem = ({ icon, label, id }: { icon: string; label: string; id: any }) => {
  const { filters, updateFilterValue } = React.useContext(FilterStateContext);

  return (
    <KeySectionStyle>
      <KeyItemLabelButton
        on={filters.find((f) => f.uid === id)?.value}
        onClick={() => updateFilterValue(id, !filters.find((f) => f.uid === id)?.value)}
      >
        <KeyIconStyle src={icon} alt={label} style={{ marginRight: "0.5rem" }} />
        <span>{label}</span>
      </KeyItemLabelButton>
    </KeySectionStyle>
  );
};

// Shown on activities page
export const ActivityKey = () => (
  <>
    <KeyStyle>
      {ActivityViewFields.map((f) => (
        <ActivityKeyItem key={f.filter.uid} icon={f.icon} label={f.filter.label} id={f.filter.uid} />
      ))}
    </KeyStyle>
    <KeyStyle>
      <NoOfPeopleKeyItems />
      <CompleteCountKeyItems />
    </KeyStyle>
  </>
);

export const ActivityDashboardKey = () => {
  const { user } = useAppSelector((state) => state.userState);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <AppSectionHeading style={{ color: "#0091a5", marginRight: "20px" }}>
        Activity <br></br>key
      </AppSectionHeading>
      <div>
        <ActivityKey />
        {[UserGroup.SCHOOLADMIN, UserGroup.TEACHER].indexOf(user.group) !== -1 && (
          <p>Please note some activities may be locked for students</p>
        )}
        <p>To remove a particular set of activities from view, click on the relevant purple button.</p>
      </div>
    </div>
  );
};

// Shown on home page
export const ActivitiesKey = () => {
  const { user } = useAppSelector((state) => state.userState);

  return (
    <>
      <AppSectionHeading style={{ color: "#b52bd8" }}>Activities</AppSectionHeading>
      <Hr style={{ backgroundColor: "#b52bd8" }} />
      <p>
        Below you will find activities to help you to explore and understand your air quality using your SAMHE monitor
      </p>
      <br></br>
      <ActivityKey />
      {[UserGroup.SCHOOLADMIN, UserGroup.TEACHER].indexOf(user.group) !== -1 && (
        <p>Please note some activities may be locked for students</p>
      )}
      <p>To remove a particular set of activities from view, click on the relevant purple button.</p>
    </>
  );
};

export const ActivityKeyCard = () => {
  return <ActivitiesKey />;
};
