import styled from "styled-components";
import { LinkNoFormat } from "../../styles/button";

export const SocialMediaLinksStyle = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export interface ISocialMediaButtonProps {
  icon: string;
}
export const SocialMediaButton = styled(LinkNoFormat)<ISocialMediaButtonProps>`
  background-image: ${({ icon }) => `url("${icon}")`};
  background-position: center;
  background-size: cover;
  cursor: pointer;
  font-size: 0;
  margin-right: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  // border-radius: 2rem;
  transition: transform 0.7s ease-in-out;

  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:focus {
    // background-color: ${({ theme }) => theme.colors.main};
    transform: rotate(360deg);
  }
`;

export const SocialMediaLinks = () => (
  <SocialMediaLinksStyle>
    <SocialMediaButton
      icon="/images/icons/bluesky-brands-solid.svg"
      href="https://bsky.app/profile/samhe.bsky.social"
      target="_blank"
      aria-label="BlueSky Link"
    >
      Twitter
    </SocialMediaButton>
    <SocialMediaButton
      icon="/images/icons/linkedin.svg"
      href="https://www.linkedin.com/groups/12898847/"
      target="_blank"
      aria-label="LinkedIn Link"
    >
      LinkedIn
    </SocialMediaButton>
    <SocialMediaButton
      icon="/images/icons/youtube.svg"
      href="https://www.youtube.com/@SAMHEProject"
      target="_blank"
      aria-label="YouTube Link"
    >
      YouTube
    </SocialMediaButton>
  </SocialMediaLinksStyle>
);
