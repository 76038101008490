import { rest } from "msw";
import { SERVER_URL } from "../config";
import { DEMO_DATA_VIEWS } from "../components/DataViewDashboard/exampleDataViews";
import { IDataView, IDataViewFull, TDataAdditionalProps } from "../lib/DataViews/IDataView";

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    useMockApi: false,
    endpoint: rest.get<IDataView[]>(`${SERVER_URL}/DataViews/Summary`, (req, res, ctx) => {
      // const filters = req.url.searchParams;
      // console.info(filters.toString());
      // TODO: Work out how to parse the json query parameter

      // const tagFilter = filters.get("tags");
      // console.info(tagFilter);
      // console.info(tagFilter.type);
      return res(
        ctx.status(200),
        ctx.json({
          data: Object.values(DEMO_DATA_VIEWS),
        })
      );
    }),
  },
  {
    useMockApi: false,
    endpoint: rest.get<IDataViewFull<TDataAdditionalProps>>(`${SERVER_URL}/DataViews/:id`, (req, res, ctx) => {
      const { id } = req.params;
      return res(
        ctx.status(200),
        ctx.json({
          data: DEMO_DATA_VIEWS[id as string],
        })
      );
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints
  .filter((e) => e.useMockApi)
  .map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
