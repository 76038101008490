import { EDayRange } from "../../components/DateRangePicker/lib";
import { EAggregationInterval } from "../MonitorData/AggregationIntervals";

export enum ETimeFormat {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  DAY = "DAY",
  TIME = "TIME",
  TIME_HOURS = "TIME_HOURS",
}

export interface IDateFormatOptions {
  compact?: boolean;
}

const dateFormatterTime =
  ({ compact }: IDateFormatOptions) =>
  (value: number) => {
    const date = new Date(value);
    const tS = date.getSeconds().toString().padStart(2, "0");
    const tM = date.getMinutes().toString().padStart(2, "0");
    const isPm = date.getHours() > 12;
    const isPmLabel = date.getHours() >= 12 ? "pm" : "am";
    const tH = compact && isPm ? (date.getHours() - 12).toString() : date.getHours().toString().padStart(2, "0");
    const d = dayOfWeekLabels[date.getDay()];
    const dd = date.getDate();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const t = compact ? `${tH}:${tM}\n${isPmLabel}` : `${tH}:${tM}:${tS}\n`;
    const v = compact ? `  ${t}  ` : `${t}${d}\n${dd}/${mm}`;
    return v;
  };
const dateFormatterTimeHours =
  ({ compact }: IDateFormatOptions) =>
  (value: number) => {
    const date = new Date(value);
    const roundHourUp = date.getMinutes() > 30;
    const hour = date.getHours() + (roundHourUp ? 1 : 0);
    const isPm = hour > 12;
    const isPmLabel = hour >= 12 ? "pm" : "am";
    const tH = isPm ? (hour - 12).toString() : hour.toString();
    const d = dayOfWeekLabels[date.getDay()];
    const dd = date.getDate();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const t = compact ? `${tH}\n${isPmLabel}\n` : `${tH}${isPmLabel}\n`;
    const v = compact ? `${t}${d}\n${dd}/${mm}` : `${t}${d}\n${dd}/${mm}`;
    return v;
  };

const dateFormatterTimeDays =
  ({ compact }: IDateFormatOptions) =>
  (value: number) => {
    const date = new Date(value);
    const d = dayOfWeekLabels[date.getDay()];
    const dd = date.getDate();
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const v = compact ? `${d}\n${dd}/${mm}` : `${d}\n${dd}/${mm}`;
    return v;
  };

const dateFormatterTimeMonths =
  ({ compact }: IDateFormatOptions) =>
  (value: number) => {
    const date = new Date(value);
    const monthString = date.toLocaleString("default", { month: "long" });
    const monthStringShort = date.toLocaleString("default", { month: "short" });
    const v = compact ? monthStringShort : monthString;
    return v;
  };
export type TDateFormatter = (d: number) => string;

export const dateFormatters: Record<
  EAggregationInterval & ETimeFormat & EDayRange,
  (options?: IDateFormatOptions) => TDateFormatter
> = {
  [EAggregationInterval.NONE]: dateFormatterTime,
  [EAggregationInterval.MINUTE]: dateFormatterTime,
  [EAggregationInterval.FIVE_MINUTE]: dateFormatterTime,
  [EAggregationInterval.TEN_MINUTE]: dateFormatterTime,
  [EAggregationInterval.HOUR]: dateFormatterTimeHours, //() => (d: number) => `${new Date(d).getHours()}`,
  [EAggregationInterval.DAY]: () => (d: number) => `${new Date(d).getDate()}/${new Date(d).getMonth()}`,

  [ETimeFormat.MINUTES]: () => (d: number) => `${new Date(d).getMinutes()}`,
  [ETimeFormat.HOURS]: () => (d: number) => `${new Date(d).getHours()}`,
  [ETimeFormat.DAYS]: () => (d: number) => `${new Date(d).getDay()}`,
  [ETimeFormat.DAY]: () => (d: number) => `${new Date(d).toLocaleDateString()}`,
  [ETimeFormat.TIME]: dateFormatterTime,
  [ETimeFormat.TIME_HOURS]: dateFormatterTimeHours,

  [EDayRange.LAST_HOUR]: dateFormatterTimeHours,
  [EDayRange.LAST_SIX_HOURS]: dateFormatterTimeHours,
  [EDayRange.TODAY]: dateFormatterTimeHours,
  [EDayRange.YESTERDAY]: dateFormatterTimeHours,
  [EDayRange.THIS_WEEK]: dateFormatterTimeDays,
  [EDayRange.LAST_THREE_DAYS]: dateFormatterTimeDays,
  [EDayRange.LAST_SCHOOL_WEEK]: dateFormatterTimeDays,
  [EDayRange.SCHOOL_DAYS]: dateFormatterTimeDays,
  [EDayRange.THIS_SCHOOL_TERM]: dateFormatterTimeDays,
  [EDayRange.THIS_YEAR]: dateFormatterTimeMonths,
  [EDayRange.THIS_SCHOOL_YEAR]: dateFormatterTimeMonths,
  [EDayRange.CUSTOM]: dateFormatterTime,
};

export const dateLabels: Record<ETimeFormat, string> = {
  [ETimeFormat.MINUTES]: "Minutes",
  [ETimeFormat.HOURS]: "Hours",
  [ETimeFormat.DAYS]: "Days",
  [ETimeFormat.DAY]: "Day",
  [ETimeFormat.TIME]: "Time",
  [ETimeFormat.TIME_HOURS]: "Time",
};

export enum EAxisTimePreprocesses {
  MINUTE_1 = 1,
  MINUTE_5 = 2,
  MINUTE_15 = 3,
  MINUTE_30 = 4,
  HOUR_1 = 5,
  DAY_1 = 6,
  DAY_5 = 7,
  MONTH_1 = 8,
}

export const NO_OP = () => "NOT IMPLEMENTED";

export const axisTimeFormatters: Record<EAxisTimePreprocesses, typeof NO_OP | ((d: string) => string)> = {
  [EAxisTimePreprocesses.MINUTE_1]: function (value) {
    const date = new Date(value);
    return date.getMinutes() % 1 === 0 ? [date.getHours(), date.getMinutes()].join("-") : "";
  },
  [EAxisTimePreprocesses.MINUTE_5]: NO_OP,
  [EAxisTimePreprocesses.MINUTE_15]: NO_OP,
  [EAxisTimePreprocesses.MINUTE_30]: NO_OP,
  [EAxisTimePreprocesses.HOUR_1]: NO_OP,
  [EAxisTimePreprocesses.DAY_1]: NO_OP,
  [EAxisTimePreprocesses.DAY_5]: NO_OP,
  [EAxisTimePreprocesses.MONTH_1]: NO_OP,
};

export const dayOfWeekLabels = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};
