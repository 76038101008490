export const Cookies = {
  SAMHE_ACCEPT_COOKIES: {
    uid: "SAMHE_ACCEPT_COOKIES",
    label: "SAMHE_ACCEPT_COOKIES",
    description:
      "When you accept our cookies policy we use cookies to remember your choice so we don't need to ask again.",
  },
} as const;

export type ECookies = keyof typeof Cookies;
