export enum EGrades {
  ANY = 0,
  LVL1 = 1,
  LVL2 = 2,
}

export interface IGradeData {
  id: EGrades;
  label: string;
  ageRangeMax: number;
  ageRangeMin: number;
}

export const GradeData: Record<EGrades, IGradeData> = {
  [EGrades.ANY]: {
    id: EGrades.ANY,
    label: "Any",
    ageRangeMax: 4,
    ageRangeMin: 1
  },
  [EGrades.LVL1]: {
    id: EGrades.LVL1,
    label: "LVL1",
    ageRangeMax: 4,
    ageRangeMin: 1
  },
  [EGrades.LVL2]: {
    id: EGrades.LVL2,
    label: "LVL2",
    ageRangeMax: 8,
    ageRangeMin: 3
  },
};

