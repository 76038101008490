import { Link } from "react-router-dom";
import { P, Ul } from "../../styles/page";
import { AExternal, DownloadLink } from "../Links";
import { INewsEntryProps } from "./entry";

export const entries: INewsEntryProps[] = [
  {
    title: "SAMHE's recommendations for policy and practice",
    date: "20 November 2024",
    text: (
      <>
        <P>
          Our new publication <Link to="/recommendations">Improving air quality in UK schools</Link> brings together
          SAMHE's findings and other evidence on air quality in UK schools and makes recommendations for policy and
          practice. This publication will be shared with policymakers; local authorities; school leaders and building
          managers; teaching unions; and school architects, builders and those who carry out school maintenance.
        </P>
        <P>
          On 25 November we will have the opportunity to share these recommendations with MPs, Members of the House of
          Lords and key stakeholders from across the research and innovation system at a reception organised by UK
          Research and Innovation (UKRI). We have been invited to the reception to showcase the SAMHE project and its
          impact on improving outcomes for children. In the words of UKRI{" "}
          <i>
            "Your work has been selected as a standout example of how UKRI-funded research connects discovery to
            prosperity for a better tomorrow."
          </i>
          .
        </P>
      </>
    ),
  },
  {
    title: "Findings from a full year of SAMHE data",
    date: "16 September 2024",
    text: (
      <>
        <P>
          Our latest technical report examines air quality in SAMHE schools throughout the 2023-24 academic year. Once
          again, the analysis suggests that keeping classrooms warm during colder weather periods may, for some schools,
          provide a significant barrier to providing adequate ventilation, with some monitors recording daily mean CO
          <sub>2</sub> values above the{" "}
          <AExternal href="https://www.gov.uk/government/publications/building-bulletin-101-ventilation-for-school-buildings">
            maximum government guideline of 1500 ppm
          </AExternal>{" "}
          during cold periods.
        </P>
        <P>
          Particulate matter levels fluctuated over the course of the academic year but generally remained below the{" "}
          <AExternal href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health">
            maximum daily mean value of 15 μg/m3 recommended by the World Health Organisation
          </AExternal>
          .
        </P>
        <P>
          We've also published a paper on assessing classroom ventilation rates, using data from SAMHE monitors, which
          shows that ventilation rates in occupied classrooms are generally low.
        </P>
        <P>
          You can access these and all the SAMHE project reports and research papers, on our{" "}
          <Link to="/resources/outputs">Outputs page</Link>, along with short, simple summaries which aim to make the
          findings accessible to teachers, pupils (with teacher support for younger pupils), parents and others. We will
          continue to add new outputs to the page as they are published in the months and years to come.
        </P>
      </>
    ),
  },
  {
    title: "Launching our Teacher Resource Pack!",
    date: "05 September 2024",
    text: (
      <>
        <P>
          Our free Teacher Resource Pack is now available! It enables schools without a SAMHE monitor to access parallel
          versions of many of the educational activities in the SAMHE Web App, including quizzes, data analysis, videos,
          and creative activities.
        </P>
        <P>
          The pack contains 20 activities that can be used to teach pupils from age 5 - 18 about air quality and support
          their development of a range of different curriculum based skills. Teacher guidance is embedded throughout and
          we have included example data and graphs for pupils to work with. Many of the activities complement curriculum
          teaching and they can also be used for extracurricular activities, such as science clubs.
        </P>
        <P>
          SAMHE is now in a new phase and no longer offering air quality monitors to schools, so we're delighted to be
          able to offer this stand-alone pack to enable all schools to benefit from the resources we've developed.
        </P>
        <P>
          Download the Teacher Resource Pack from our <Link to="/get-involved">Get Involved</Link> page.
        </P>
      </>
    ),
  },
  {
    title: "Watch recordings of the SAMHE webinars",
    date: "03 September 2024",
    text: (
      <>
        <P>
          In July we held two webinars to share our findings on air quality in UK schools. One was for pupils and staff
          at SAMHE schools and the other was a public webinar open to all. As part of these we received and answered
          some really interesting questions. Watch the recordings:
        </P>
        <Ul>
          <li>
            <AExternal href="https://www.youtube.com/watch?v=EgenIlp6w3g">SAMHE schools' webinar</AExternal>
          </li>
          <li>
            <AExternal href="https://www.youtube.com/watch?v=5Qv3J-Thgdo">Public SAMHE webinar</AExternal>
          </li>
        </Ul>
        <P>
          We've also produced a{" "}
          <AExternal href="https://www.youtube.com/watch?v=vraKJUbT_xY">
            short video introducing pupils to two key scientific papers on SAMHE's methods
          </AExternal>
          . This is designed to support the SAMHE Methods papers activity which can be found both in the SAMHE Web App
          and our teacher resource pack. In the video, Professor Sarah West talks through two key SAMHE papers, why we
          wrote them and what we learned through the process of co-design.
        </P>
        <P>
          You can find summaries of all reports and research papers about the SAMHE project that have been published by
          the SAMHE team to date, with links to the full versions for those interested in reading about our work in more
          detail, on our <Link to="/resources/outputs">Outputs page</Link>.
        </P>
        <P>
          We anticipate publishing more findings over the coming months and years.{" "}
          <Link to="/faq#contact">Sign up to receive the SAMHE newsletter</Link> to stay in touch with our findings and
          further project developments.
        </P>
        <P>
          Although our initial funding came to an end on 31 July 2024, there are still plenty of ways for schools and
          others to be involved in SAMHE. Read more on our new look <Link to="/get-involved">Get Involved</Link> page.
        </P>
      </>
    ),
  },
  {
    title: "SAMHE project findings",
    date: "05 July 2024",
    text: (
      <>
        <P>
          The latest additions to our <Link to="/resources/outputs">Outputs page</Link> are two technical reports on air
          quality in SAMHE schools: one on the 2023 Autumn term and one on the 2024 Spring term. In particular, we
          investigated how changes in outside temperature influenced the different aspects of air quality inside. The
          analyses suggest that keeping classrooms warm during colder weather periods may, for some schools, provide a
          significant barrier to providing adequate ventilation.
        </P>
        <P>
          You can access all the SAMHE project reports and research papers on our{" "}
          <Link to="/resources/outputs">Outputs page</Link>, along with short, simple summaries which aim to make the
          findings accessible to teachers, older pupils, parents and others. We will continue to add new outputs to the
          page as they are published in the months and years to come.
        </P>
        <P>
          You can also read about the impact of fireworks events, and a Saharan dust event, on classroom air, and
          discover how we worked out classroom ventilation rates.
        </P>
      </>
    ),
  },
  {
    title: "Keep your monitor connected over the holidays",
    date: "25 June 2024",
    text: (
      <>
        <P>
          It's really important for our researchers to know what air quality is like when classrooms are not occupied,
          so please keep your monitor switched on and connected to the internet at all times, including outside of
          school hours and over school holidays, if possible. This will also allow pupils to investigate how air quality
          changes with occupancy level!
        </P>
        <P>
          You don't need to worry about the cost or environmental impact as the monitor uses very little electricity (c.
          1.5 kWh energy per year) and costs almost nothing (under £1 per year) to run.
        </P>
        <P>
          If all the electrics in the room are usually switched off overnight (e.g. through a 'kill switch') you can
          plug the monitor into a power bank (like the sort you use for a phone) and plug that into the mains. The power
          bank charges during the day and then powers the monitor overnight.
        </P>
      </>
    ),
  },
  {
    title: "Hear about the project findings firsthand!",
    date: "18 June 2024",
    text: (
      <>
        <P>
          You are warmly invited to meet us online to hear about the project's findings on air quality in UK schools.
          We're running two webinars on Zoom in July - one for staff and pupils at SAMHE schools and one for other
          interested groups including local authorities and education unions. We will present some of the findings so
          far and try to answer your questions! We will record both webinars so, if you are unable to attend, you can
          watch them afterwards at a time that suits you.
        </P>
        <P>
          <b>SAMHE schools</b> - webinar on Wednesday 3 July 3.30-4.10pm - you will have received an email last week
          inviting you to register.
        </P>
        <P>
          <b>Everyone else</b> - webinar on Wednesday 17 July 12-1pm.{" "}
          <AExternal href="https://york-ac-uk.zoom.us/webinar/register/WN_t8IfMTXORtyLQfQsdtpexA">
            Register for the public SAMHE webinar
          </AExternal>
          .
        </P>
      </>
    ),
  },
  {
    title: "New Activities!",
    date: "11 June 2024",
    text: (
      <>
        <P>We're excited to have launched a range of new activities in the SAMHE Web App in the last few months!</P>
        <P>
          The quality of the air outdoors can affect air indoors, so a broader understanding of air quality can help
          pupils understand the actions to take to improve indoor air quality.{" "}
          <Link to="/app/activities/31">Learning about outdoor air pollution</Link> is a quiz activity that helps pupils
          learn about outdoor air pollution. They can then use their new knowledge to formulate arguments for a{" "}
          <Link to="/app/activities/32">Clean Air Zones debate</Link>!
        </P>
        <P>
          In <Link to="/app/activities/26">Sources of air pollution indoors</Link>, pupils study a graphic showing
          potential sources of air pollution in a classroom and things that can affect temperature and humidity. Then
          they take a quiz to test their understanding.
        </P>
        <P>
          <Link to="/app/activities/33">Design a SAMHE poster</Link> is a creative activity which guides pupils in
          designing, creating and sharing a poster to illustrate a key message about air quality or something they have
          learnt through SAMHE.
        </P>
        <P>
          <Link to="/app/activities/34">How do you use SAMHE?</Link> is an activity just for school staff, to tell us
          how you are using SAMHE in your school or your classroom. This is really helpful for us to understand how
          effective the project has been.
        </P>
        <P>
          Completing any of these activities gains you points towards SAMHE badges! See your progress on the new look{" "}
          <Link to="/app/achievements">Achievements</Link> page.
        </P>
      </>
    ),
  },
  {
    title: "Registrations closed but Teacher Resource Pack coming soon",
    date: "1 June 2024",
    text: (
      <>
        <P>
          SAMHE is transitioning. We're still working with UK schools to better understand classroom air quality but are
          no longer recruiting new schools to host monitors.
        </P>
        <P>
          Don't worry if you missed the deadline because we will soon release a Teacher Resource Pack to enable schools
          without a monitor to access SAMHE activities! Sign up to the SAMHE newsletter via our{" "}
          <Link to="/contact">contact form</Link> to be the first to hear when this is available, and for other news.
        </P>
      </>
    ),
  },
  {
    title: "Empower pupils through personal Web App accounts",
    date: "15 May 2024",
    text: (
      <>
        <P>
          Did you know that pupils can have individual accounts for the SAMHE Web App? This enables them to explore the
          monitor data in more detail and complete activities themselves, earning points towards in-app achievement
          badges! They can also contribute directly to the SAMHE research by inputting contextual data. By creating
          individual pupil accounts, you can also set SAMHE activities as homework.
        </P>
        <P>
          For instructions on setting up pupil accounts, watch our latest '
          <AExternal href="https://youtu.be/iu1jYjgkjSA">video: How to set up pupil accounts</AExternal>' (2 min) or use
          the step-by-step instructions in the FAQ '
          <Link to="/support#how_can_i_set_up_pupil_accounts">How can I set up pupil accounts?</Link>' on our tech
          support page.
        </P>
        <P>
          (Please note, depending on your school's policy, you may need to communicate with parents/guardians before you
          set up pupil accounts. Our '
          <DownloadLink href="/resources/SAMHE_Parent_information_pack.pdf">parent information pack</DownloadLink>'
          contains templates to support this. Please use these in combination with your usual processes and adapt the
          templates as required.)
        </P>
        <P>Pupils need to earn 10 points to be a 'SAMHE Hero' - who will be first??</P>
      </>
    ),
  },
  {
    title: "Last chance to sign up!",
    date: "1 May 2024",
    text: (
      <>
        <P>
          If your school would like to take part in SAMHE, <Link to="/register/school">register</Link> by 31 May! Our
          current research funding ends this summer and the support that we can offer SAMHE schools will reduce from 31
          July. In order to get monitors out to schools and support them to get set up, we are closing registrations at
          the end of this month.
        </P>
        <P>
          It's definitely still worth joining - you'll be able to keep using your monitor and the Web App for many years
          to come! We hope to receive additional funding in the future to extend and expand SAMHE, but even if we don't,
          we will maintain the Web App until at least 2030.
        </P>
        <P>
          Please encourage other schools to sign up while there is still time! Download the{" "}
          <DownloadLink href="https://docs.google.com/document/d/18J_OJZS6JdyRevvEwOklYJHcjpZEobXR/export?format=doc">
            latest SAMHE promotional materials
          </DownloadLink>{" "}
          for content you can use in newsletters, emails, social media posts etc.
        </P>
      </>
    ),
  },
  {
    title: "SAMHE activities for British Science Week",
    date: "1 March 2024",
    text: (
      <>
        <P>
          Are you getting excited about British Science Week, 8-17 March? We are! This year's theme is <b>Time</b> so,
          if you use the Web App, why not try our data analysis activity{" "}
          <Link to="/app/activities/8">Temperature changes throughout the day (15 mins)</Link>.
        </P>
        <P>
          Alternatively, have fun with some star jumps or dance moves as part of{" "}
          <Link to="/app/activities/19">CO₂ levels before and after exercise</Link> (as seen on{" "}
          <AExternal href="https://www.youtube.com/watch?v=HL_pBp73q38">BBC Newsround</AExternal>!) (15 mins) before
          break, open the windows whilst the kids are outside, then use{" "}
          <Link to="/app/activities/9">CO₂ levels of an empty classroom</Link> (25 mins) to see how CO₂ levels changed
          over the time period and witness the effect of ventilation and reducing occupancy.
        </P>
        <P>
          You could also produce a poster on the theme of 'Time', based on your SAMHE activities, and submit it to{" "}
          <AExternal href="https://www.britishscienceweek.org/plan-your-activities/poster-competition/">
            British Science Week 2024 poster competition
          </AExternal>
          .
        </P>
        <P>
          This 30th anniversary year there is a particular focus on <b>Smashing Stereotypes</b> - celebrating the
          diverse people and careers in science and engineering. If you are a teacher or pupil using the SAMHE monitor,
          that includes you! You are making an important contribution to scientific research into air quality in
          schools. Thank you!
        </P>
      </>
    ),
  },
  {
    title: "SAMHE Outputs page",
    date: "19 January 2024",
    text: (
      <>
        <P>
          You can now read short, simple summaries of SAMHE project reports and research papers on our new{" "}
          <Link to="/resources/outputs">Outputs page</Link>. These are suitable for teachers, older pupils, parents and
          others. There are links to the full texts for anyone who would like more detail.
        </P>
        <P>
          So far, the page provides summaries of our first two research papers - one on the overall SAMHE project
          methods and one specifically on the co-design process - and also a technical report on the raised levels of
          particulate matter recorded by SAMHE monitors during the Saharan dust storm in September 2023.
        </P>
        <P>
          As a citizen science project we are committed to sharing all our outputs in an accessible way with those who
          are contributing to the research, including teachers and pupils at SAMHE schools. After all, we couldn't do
          this work without you!
        </P>
      </>
    ),
  },
  {
    title: "We have a winner!",
    date: "18 December 2023",
    text: (
      <>
        <P>
          We ran our first <Link to="/connection-competition">connection competition</Link> last month and are delighted
          to announce that the winner is Stanwell School in Penarth, Wales! They will soon be receiving their prize - a
          £50 National Book Token along with printed SAMHE stickers and posters. 247 schools had their monitors
          connected at midnight on 30 November, and were hence eligible for entry into the competition.
        </P>
        <P>
          <b>Could you be our next winner?</b>
          <br />
          This month's winner will be drawn at the end of the month, so make sure your monitor is connected whilst you
          are busy enjoying your New Year's Eve celebrations!
        </P>
        <P>
          If you'd prefer not to be entered into our competitions you can opt out at any time by emailing{" "}
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. See full{" "}
          <DownloadLink href="/resources/SAMHE_Connection_Competition_Prize_Draw_Terms_and_Conditions.pdf">
            terms and conditions
          </DownloadLink>
          .
        </P>
      </>
    ),
  },
  {
    title: "Connect your SAMHE monitor to win prizes!",
    date: "30 November 2023",
    text: (
      <>
        <P>
          To show our appreciation for schools submitting data to the SAMHE project, we have started a monthly
          'connection competition'. If your school's SAMHE monitor is connected to the internet at the time of the draw
          (23:59 on the last calendar day of each month) you will be automatically entered into the competition and
          eligible to win a £50 National Book Token along with printed SAMHE stickers and posters!
        </P>
        <P>
          Read more about the <Link to="/connection-competition">SAMHE connection competition</Link>.
        </P>
      </>
    ),
  },
  {
    title: "Hear teachers explain why they love SAMHE!",
    date: "30 November 2023",
    text: (
      <>
        <P>
          We asked four teachers how they are using SAMHE and what benefits they have seen for their pupils, themselves
          and their schools. See what they said in our new{" "}
          <AExternal href="https://youtu.be/8fhvbjLUMtg">Teacher Testimonials video</AExternal>. We are extremely
          grateful to Andy Brittain, Alex Burns, Gemma Hortop, Steven West and their schools for wholeheartedly
          embracing the SAMHE project and being willing to share their stories and enthusiasm!
        </P>
      </>
    ),
  },
  {
    title: "Find out more about the SAMHE team with our new careers resource!",
    date: "22 November 2023",
    text: (
      <>
        <P>
          Want to find out more about the team behind SAMHE? Take a look at our{" "}
          <DownloadLink href="/resources/ADITL_SAMHE_Publication_v6.pdf">
            'Day in the life of the SAMHE team'
          </DownloadLink>{" "}
          careers resource.
        </P>
        <P>
          This booklet can be used with pupils to explore different careers in STEM, and the different types of roles it
          takes to run a project like SAMHE. If you have access to the Web App, we will be launching a careers activity
          based on the resource soon!
        </P>
      </>
    ),
  },
  {
    title: "CoSchools website - additional resources for SAMHE schools",
    date: "21 November 2023",
    text: (
      <>
        <P>
          The <AExternal href="https://coschools.org.uk/">CoSchools website</AExternal> has been overhauled and now
          includes even more information and resources about air quality and schools. It's a great additional resource
          for SAMHE schools, for example providing information about air cleaners and filters in schools. Schools may
          particularly value the range of bitesize videos on topics including ventilation, CO<sub>2</sub> levels and
          virus transmission, CO<sub>2</sub> and effective learning, choosing and using HEPA filters, and much more.
        </P>
      </>
    ),
  },
  {
    title: "Take a tour of the SAMHE Web App!",
    date: "26 October 2023",
    text: (
      <>
        <P>
          Want to see for yourself what the newly revamped SAMHE Web App offers?{" "}
          <AExternal href="https://www.youtube.com/watch?v=Z2WA4hx8mRQ&t=2s">Take a 2 minute tour!</AExternal>
        </P>
        <P>
          This short video introduces the Web App, highlighting the ways pupils and teachers can view and interact with
          the data from the SAMHE air quality monitor and the range of curriculum-linked activities available. We also
          touch on the achievement badges pupils love to work towards, and embedded glossary links to help them
          understand key terms and concepts.
        </P>
        <P>
          (Note, we have more improvements in the pipeline, so if you <Link to="/register/school">sign up now</Link> it
          will look even better when you access it!)
        </P>
      </>
    ),
  },
  {
    title: "SAMHE featured in important UK report on childhood inequality",
    date: "12th September 2023",
    text: (
      <>
        <P>
          The SAMHE project has been included as a case study in a major new report which highlights how a failure to
          address childhood inequality is creating a “conveyor belt of disadvantage”.{" "}
          <DownloadLink href="https://www.healthequitynorth.co.uk/app/uploads/APPG-REPORT-SEPT-23.pdf">
            The Child of the North: Addressing Education and Health Inequity
          </DownloadLink>{" "}
          report highlights how children born into the poorest fifth of families in the UK are almost 13 times more
          likely to experience poor health and educational outcomes by the age of 17.
        </P>
        <P>
          Sarah West, who leads our engagement with schools, says "Citizen science projects, such as SAMHE, can empower
          schools to better understand their environment and take low cost actions to improve it, thereby reducing
          inequality". Read more in a{" "}
          <AExternal href="https://www.york.ac.uk/news-and-events/news/2023/research/child-of-north-report/">
            press release from University of York
          </AExternal>
          .
        </P>
      </>
    ),
  },
  {
    title: "International Day of Clean Air for blue skies",
    date: "7th September 2023",
    text: (
      <>
        <P>
          Today is the 4th{" "}
          <AExternal href="https://www.cleanairblueskies.org/">International Day of Clean Air for blue skies</AExternal>
          ! This UN designated day aims to build a global community of action that encourages countries to work together
          to tackle air pollution. This year's theme is <i>Together for Clean Air</i> and we are encouraging more
          schools to work together with scientists through the SAMHE project, to provide evidence for better national
          policies and practice on air quality in schools. If you haven't already, do{" "}
          <Link to="/register/school">register for free as a SAMHE school</Link>.
        </P>
        <P>
          Claudia Castro Faccetti will be addressing the{" "}
          <AExternal href="https://ukieg431081511.wordpress.com/events/">
            UK Indoor Environments Group (UKIEG) Annual Conference
          </AExternal>{" "}
          today, introducing them to the SAMHE project by explaining how schools helped us{" "}
          <Link to="/co-design">co-design</Link> the SAMHE data visualisations.
        </P>
        <P>
          <b>Monitor languishing in its box?</b>
          <br />
          If you've received your SAMHE monitor but not yet set it up, please don't feel bad! We know that school staff
          have a host of conflicting demands on their time. The good news is that (most of the time) set up is actually
          quite quick and easy! And as soon as you're set up you can begin using your monitor readings and carry out
          experiments in the Web App with your pupils.
        </P>
        <P>
          We have a step-by-step <Link to="/getting-started">guide to Getting Started</Link> and, if it's advice on
          connecting your monitor to your school's WiFi that you need, head to our{" "}
          <Link to="/monitor-connection-guide">monitor connection page</Link>, which features a new{" "}
          <AExternal href="https://youtu.be/YxWVRKNJXRI?si=QlS6u5gCP4BA-XZ6">walkthrough video</AExternal>. Additional
          guidance is available on the <Link to="/support">Tech support page</Link>, including troubleshooting tips and
          frequently asked questions.
        </P>
        <P>
          Later this week, we'll also be releasing a series of dates for drop-in support sessions online where our team
          will be able to troubleshoot any issues you, or your IT support team, have come across. If you're not yet
          connected, you'll receive details by email soon. Meanwhile, don't hesitate to{" "}
          <Link to="/support/tech-contact">contact us</Link>. We're here to help!
        </P>
      </>
    ),
  },
  {
    title: "New school year, new SAMHE activity!",
    date: "4th September 2023",
    text: (
      <>
        <P>
          Best wishes to all UK school staff and pupils. We hope you are enjoying a positive start to the new academic
          year!
        </P>
        <P>
          Today we're excited to launch the <b>Data Detectives</b> activity on the SAMHE Web App! This activity supports
          pupils to investigate data patterns they find interesting, including formulating a research question and
          finding an answer backed by evidence. Data Detectives supports STEM and Geography learning and the trial
          version was a hit with SAMHE Pioneer Schools!
        </P>
        <P>
          The activity takes about 25 minutes and can be carried out as often as you wish, looking at a different data
          pattern or event each time. It teaches many aspects of working scientifically, such as observation, recording,
          developing a line of enquiry, data analysis, cause and effect and presenting and communicating results. In
          conjunction with SAMHE's <Link to="/resources">information resources</Link>, pupils could also apply their
          findings and interpretations to a wider understanding of climate processes and change.
        </P>
        <P>
          If you haven't used the SAMHE Web App in a while, you will also notice that it has been beautifully restyled
          to make it easier to use and navigate and generally more attractive!
        </P>
      </>
    ),
  },
  {
    title: "SAMHE featured on BBC Newsround!",
    date: "9th June 2023",
    text: (
      <>
        <P>
          We are very excited that the{" "}
          <AExternal href="https://www.bbc.co.uk/newsround/av/65818540">
            SAMHE project was featured on Newsround!
          </AExternal>{" "}
          BBC's Ricky Boleto and project lead Henry Burridge visited a SAMHE school to see the project in action and
          meet pupils investigating their classroom's air quality using the monitor and Web App.
        </P>
        <P>
          Henry and Ricky joined pupils in an experiment to see the effect of exercise on the CO₂ level in the
          classroom. During some strenuous jumping up and down, the CO₂ readout climbed until the indicator lights
          changed from green to orange. In line with{" "}
          <AExternal href="https://samhe.org.uk/resources/monitor-leds#co2">SAMHE guidance</AExternal>, the group then
          took action to improve air quality in the room by opening windows to increase ventilation. Looking at the CO₂
          plot in the Web App afterwards, pupils were able to identify when they had begun the period of exercise and
          when windows had been opened.
        </P>
        <P>This experiment is just one of many activities pupils and teachers can access through the SAMHE Web App.</P>
      </>
    ),
  },
  {
    title: "Celebrate Clean Air Day, 15 June",
    date: "7th June 2023",
    text: (
      <>
        <P>
          It's nearly{" "}
          <AExternal href="https://www.actionforcleanair.org.uk/campaigns/clean-air-day">Clean Air Day</AExternal>, the
          UK's largest air pollution campaign. Why not celebrate by{" "}
          <Link to="/register/school">registering your school with SAMHE</Link>?
        </P>
        <P>
          If you are already signed up, you could use our{" "}
          <DownloadLink href="/resources/samhe_assemblies_and_notes_for_schools.zip">
            SAMHE introductory assembly
          </DownloadLink>{" "}
          materials on 15 June or some other time that week to let the whole school community know what you are doing.
          You could also share the news with parents, your wider community and other schools in your area through your
          newsletter and/or social media accounts. Feel free to use and adapt our{" "}
          <AExternal href="https://docs.google.com/document/d/18J_OJZS6JdyRevvEwOklYJHcjpZEobXR/export?format=doc">
            SAMHE promotional materials
          </AExternal>
          .
        </P>
        <P>
          Global Action Plan has developed a range of resources to help everyone celebrate Clean Air Day 2023. See their{" "}
          <AExternal href="https://www.actionforcleanair.org.uk/campaigns/clean-air-day/school-resources">
            resources for schools
          </AExternal>
          , including specific Clean Air Day packs for Scottish schools, delivered in partnership with Environmental
          Protection Scotland. These are a great complement to the <Link to="/resources">SAMHE resources</Link> and your
          ongoing SAMHE activities.
        </P>
        <P>
          For those able to get to Manchester, there is also an opportunity to hear Rhys Archer speak about SAMHE at the
          UK Institute of Air Quality Management (IAQM){" "}
          <AExternal href="https://www.sei.org/events/iaqm-childhood-exposure-air-pollution/">
            forum on childhood exposure to air pollution
          </AExternal>{" "}
          2-4pm on Thursday 15 June at Manchester Metropolitan University. Also speaking at the event will be Professor
          Paul Linden (SAMHE co-lead) speaking about his wider work on school air quality through the{" "}
          <AExternal href="https://tapasnetwork.co.uk/">TAPAS network</AExternal> and Dr Abigail Whitehouse from Queen
          Mary University of London. Registration is open to anyone interested in air quality management.
        </P>
      </>
    ),
  },
  {
    title: "1/3 of SAMHE monitors allocated to schools",
    date: "29th May 2023",
    text: (
      <>
        <P>
          We are thrilled to have recruited over 700 SAMHE schools! We want to get SAMHE monitors into 1500-2000
          schools, so we're already over a third of the way there!
        </P>
        <P>
          This is a great start just 5 weeks post-launch, but we recognise that recruiting the rest of the schools we
          need is still a big challenge. Please encourage schools you have contacts for to sign up. If every SAMHE
          school recruited just one more, that would make a huge difference! Do use the promotional materials on our{" "}
          <Link to="/resources/media">media page</Link> if helpful.
        </P>
        <P>
          Recruiting a large number of schools is important as we need to generate sufficient data for analysis to
          clearly understand schools' air quality across the UK.
        </P>
      </>
    ),
  },
  {
    title: "Join SAMHE now!",
    date: "24th April 2023",
    text: (
      <>
        <P>
          We're delighted to officially launch SAMHE this week and invite UK schools to{" "}
          <Link to="/register/school">register now</Link>! The first 100 schools to register for SAMHE will receive a
          special <Link to="/launch-offer">launch pack</Link> along with their monitor, so don't delay!
        </P>
      </>
    ),
  },
  {
    title: "SAMHE launch week",
    date: "", // Excluded as causes confusion with dates below
    text: (
      <>
        <P>We're delighted to announce the official launch of SAMHE!</P>
        <P>
          <b>SAMHE launch week is 24-28 April.</b>
        </P>
        <P>
          From Monday 24 April we invite schools across the UK to register as SAMHE schools to receive a high spec air
          quality monitor and access to the SAMHE Web App. SAMHE will be available to 1500+ schools and we hope to
          recruit a wide diversity of schools across England, Scotland, Wales and Northern Ireland, representative of
          the whole UK school community.
        </P>
        <P>
          In the coming weeks you will see some changes to this website as we prepare for more traffic from people
          looking to register their school, find out more about the project or learn about indoor air quality.
        </P>
      </>
    ),
  },
  {
    title: "Beta testing nearly complete",
    date: "24th February 2023",
    text: (
      <>
        <P>
          Today is the International Day of Education and we want to celebrate our wonderful SAMHE Pioneer Schools! Over
          the last few months, they have been testing and reviewing important aspects of SAMHE to ensure that it meets
          schools' needs before it is shared more widely.
        </P>
        <P>
          With their feedback we have revised our instructions for schools logging in and connecting their SAMHE
          monitors and improved the functionality of the data views on the Web App. They have also tried out our first
          Web App Activities and helped us refine our in-app 'Achievements' structure and the rewards strategy for how
          badges and certificates are gained. In our next release, Pioneer schools will be invited to start testing our
          new 'Data Detectives' activity, which will help students investigate 'spikes' in their data (big but
          short-lived changes in the readings) and explore what could be causing them.
        </P>
        <P>
          That will bring our beta testing process with schools to an end. Our designers and software developers will
          continue to implement the feedback, create new activities and tidy up the Web App and this website ready for
          the SAMHE launch. We hope to share a date for that with you soon!
        </P>
      </>
    ),
  },
  {
    title: "Beta testing is underway!",
    date: "21st November 2022",
    text: (
      <>
        <P>
          Over 100 Pioneer Schools have already received SAMHE air quality monitors and access to the beta version of
          the SAMHE Web App. They are now starting to test its functionality and try out the initial activities. Over
          the next couple of months we will continue to release new activities to pioneer schools and ask them for
          feedback. This will inform our ongoing development of the Web App, with the aim that it will be fully
          functional, wonderfully engaging and brimming with exciting activities in time for the official launch of
          SAMHE early in 2023!
        </P>
        <br />
        <P>
          Unfortunately we cannot accept any more schools as pioneer schools at this stage, but if your school would
          like to receive a monitor as part of our next roll out, do <Link to="/register/school">register now</Link> to
          join our waitlist. You'll receive a monitor and access to the Web App after the official launch.
        </P>
      </>
    ),
  },
  {
    title: "An Interview with Dr Henry Burridge for #WorldVentil8Day",
    date: "November 2022",
    text: (
      <>
        Henry is a Senior Lecturer at Imperial College London and leads the SAMHE project. He explains how he got
        involved in research on air quality, why we should be concerned about indoor air pollution, what the newly
        inaugurated World 'Ventil8 Day' wants to achieve, and how SAMHE fits in. Read the interview on the{" "}
        <AExternal href="https://www.ukcleanair.org/2022/11/08/air-flow-expert-gives-his-insight-into-why-indoor-air-quality-is-just-as-important-as-outdoor/">
          Clean Air Programme website
        </AExternal>
        .
      </>
    ),
  },
  {
    title: "Are you ready for #WorldVentil8Day?",
    date: "November 2022",
    text: (
      <>
        World Ventil8 Day, on 8th November, aims to raise awareness of the importance of ventilation as a crucial part
        of enabling health and wellbeing of people. Learn more{" "}
        <Link to="/resources/air-pollution">about air pollution</Link> here on the SAMHE website and{" "}
        <AExternal href="https://www.worldventil8day.com/">visit the WorldVentil8Day website</AExternal> to find out how
        important ventilation is and discover news and events to help you{" "}
        <AExternal href="https://twitter.com/hashtag/CelebrateVentil8?src=hashtag_click">#CelebrateVentil8</AExternal>.
      </>
    ),
  },
  {
    title: "'SAMHE pioneer schools' are go!",
    date: "October 2022",
    text: (
      <>
        Our <Link to="/register/school">school registration form</Link> is now live! Sign your school up to receive a
        SAMHE air quality monitor this autumn and help test the beta version of the SAMHE Web App.
      </>
    ),
  },
  {
    title: "The process of co-design has begun!",
    date: "May 2022",
    text: (
      <>
        Teachers from our first co-design schools have met with members of the SAMHE team to start shaping the design
        and functionality of the web app. We have also started getting feedback on possible logo ideas from young
        people. See our <Link to="/co-design">co-design page</Link> to find out about co-design process.
      </>
    ),
  },
  {
    title: "SAMHE secures funding from the EPSRC",
    date: "April 2022",
    text: (
      <>
        We are delighted to have secured funding from the EPSRC (Engineering and Physical Sciences Research Council) to
        carry out the SAMHE project. Initial funding to get started was provided by the Department for Education, which
        continues to be involved.
      </>
    ),
  },
];
