import React from "react";
import { HeaderLink, HeaderItem } from "../../styles/header";

export interface IHeaderDeploymentProps {
  setMenuOpen: (v: boolean) => void;
}

export const HeaderDeployment: React.FC<IHeaderDeploymentProps> = ({ setMenuOpen }) => {
  return (
    <>
      <HeaderItem>
        <HeaderLink to="/deployment/link-monitor" className="header-button" onClick={() => setMenuOpen(false)}>
          Link Monitor
        </HeaderLink>
      </HeaderItem>
      <HeaderItem>
        <HeaderLink to="/deployment/school-validation" className="header-button" onClick={() => setMenuOpen(false)}>
          Validate Schools
        </HeaderLink>
      </HeaderItem>
    </>
  );
};
