import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
      background: ${({ theme }) => theme.colors.baseBackground};
      color: ${({ theme }) => theme.colors.text};
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: Helvetica, serif, Helvetica, Arial, sans-serif;
    position: absolute;
    top:0;
    bottom: 0;
  }

  .App {

    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    flex-direction: column;


  }

  button {
    cursor: pointer;
  }


  :focus {
    outline: ${({ theme }) => theme.colors.mainHover} solid 2px;
    outline-offset: -1px;
    border-color: ${({ theme }) => theme.colors.mainHover};
  }
  ::-moz-focus-inner {
    outline: 1px ${({ theme }) => theme.colors.grey2} dashed;
  }

  button, a {
    :focus {
      outline: ${({ theme }) => theme.colors.mainHover} solid 2px;
      outline-offset: 2px;
      border-color: ${({ theme }) => theme.colors.mainHover};
    }
  }


  /* BASE STYLES */
  // TODO: Need to fix some old references to scss variables
  html {
    font-size: (${({ theme }) => theme.typography.fontSize3} / 16px) * 1em;
    overflow-y: hidden;
    min-height: 100%;
  }

  body {
    font-family:${({ theme }) => theme.typography.fontFamily};
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin: auto;

    transition: padding 0.4s ease-in;
  }

  :focus {
    outline: ${({ theme }) => theme.colors.mainHover} solid 2px;
    outline-offset: -1px;
    border-color: ${({ theme }) => theme.colors.mainHover};
  }
  ::-moz-focus-inner {
    outline: 1px ${({ theme }) => theme.colors.grey2} dashed;
  }

  // /* Typography */
  p,
div {
  font-weight: ${({ theme }) => theme.typography.fontWeightStandard};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSize3};
}

label {
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSize3};
  color: ${({ theme }) => theme.colors.text};
}

div,
p,
h1,
h2,
h3,
h4,
h5,
button,
textarea,
a {
  font-weight: ${({ theme }) => theme.typography.fontWeightStandard};
  line-height: 135%;
  color: ${({ theme }) => theme.colors.text};
}
b {
  font-weight: bold;
}

h1 {
  font-size: ${({ theme }) => theme.typography.fontSize6};
}
h2 {
  font-size: ${({ theme }) => theme.typography.fontSize5};
}
h3 {
  font-size: ${({ theme }) => theme.typography.fontSize4};
}
h4 {
  font-size: ${({ theme }) => theme.typography.fontSize3};
}
h5 {
  font-size: ${({ theme }) => theme.typography.fontSize2};
}

a {
  color: ${({ theme }) => theme.colors.link};
  text-decoration: underline;
  text-decoration-thickness: 1px;
  font-weight: bold;
  border: none;
  outline: none;
  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
  }
}

// helper class
.text-left {
  text-align: left!important;
}
.text-center {
  text-align: center!important;
}
.text-right {
  text-align: right!important;
}

// Font overwrite
.App .samheAppWrapper,
#keywordPopup{
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  button,
  textarea,
  a,
  input,
  label,
  ul li {
    font-family:${({ theme }) => theme.typography.fontFamilyButton};
  }
}
`;
