export enum EDataMetrics {
  TEMPERATURE = 1,
  CO2 = 2,
  PM25 = 4,
  TVOC = 8,
  HUMIDITY = 16,
}

export enum EDataMetricsStrId {
  TEMPERATURE = "atmp",
  CO2 = "rco2",
  PM25 = "pm02",
  TVOC = "tvoc",
  HUMIDITY = "rhum",
}

export interface IMetricData {
  id: EDataMetrics;
  strid: EDataMetricsStrId;
  label: string;
  unit: string;
  defaultYRange: [number, number];
  decimalPlaces: number;
}

export const DataMetricsMetaData: Record<EDataMetrics, IMetricData> = {
  [EDataMetrics.TEMPERATURE]: {
    id: EDataMetrics.TEMPERATURE,
    strid: EDataMetricsStrId.TEMPERATURE,
    label: "Temperature",
    unit: "°C",
    defaultYRange: [0, 40],
    decimalPlaces: 2,
  },
  [EDataMetrics.CO2]: {
    id: EDataMetrics.CO2,
    strid: EDataMetricsStrId.CO2,
    label: "CO₂",
    unit: "ppm",
    defaultYRange: [0, 3000],
    decimalPlaces: 0,
  },
  [EDataMetrics.PM25]: {
    id: EDataMetrics.PM25,
    strid: EDataMetricsStrId.PM25,
    label: "PM\u2082.\u2085",
    unit: "µg/m\u00B3",
    defaultYRange: [0, 40],
    decimalPlaces: 0,
  },
  [EDataMetrics.TVOC]: {
    id: EDataMetrics.TVOC,
    strid: EDataMetricsStrId.TVOC,
    label: "TVOC",
    unit: "Ind 30",
    defaultYRange: [0, 2000],
    decimalPlaces: 0,
  },
  [EDataMetrics.HUMIDITY]: {
    id: EDataMetrics.HUMIDITY,
    strid: EDataMetricsStrId.HUMIDITY,
    label: "Humidity",
    unit: "%",
    defaultYRange: [0, 100],
    decimalPlaces: 2,
  },
};

export const MetricStrIdMap: Record<EDataMetricsStrId, EDataMetrics> = {
  [EDataMetricsStrId.TEMPERATURE]: EDataMetrics.TEMPERATURE,
  [EDataMetricsStrId.CO2]: EDataMetrics.CO2,
  [EDataMetricsStrId.PM25]: EDataMetrics.PM25,
  [EDataMetricsStrId.TVOC]: EDataMetrics.TVOC,
  [EDataMetricsStrId.HUMIDITY]: EDataMetrics.HUMIDITY,
};
