import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { OnOffButtonStyle } from "../../styles/button";

export const CheckMark = styled.span`
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.background};
  border: 2px solid ${({ theme }) => theme.colors.main};
  &::after {
    content: "Y";
    position: absolute;
    display: none;
  }
`;

// export const CheckBoxContainer = styled(ButtonNoFormat)`
export const CheckBoxContainer = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  min-height: ${({ theme }) => theme.typography.lineHeight};
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
    justify-content: start;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    &:checked {
      display: block;
    }
  }

  :after: {
    content: "";
    position: absolute;
    display: none;
  }
  .checkmarkLabel {
    cursor: pointer;
    padding: ${({ theme }) => theme.shape.defaultPaddingSmall} ${({ theme }) => theme.shape.defaultPaddingLarge};
    background-color: ${({ theme }) => theme.colors.grey1};
    border-radius: ${({ theme }) => theme.shape.defaultRadius};
    flex-grow: 1;
    text-align: center;
  }
  &:hover {
    .checkmarkLabel {
      background-color: ${({ theme }) => theme.colors.grey2};
    }
  }

  .checkmark {
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.cta};
    color: ${({ theme }) => theme.colors.ctaText};
  }
  input:focus ~ .checkmark {
    outline: 2px solid ${({ theme }) => theme.colors.cta};
    outline-offset: 2px;
  }

  input:checked ~ .checkmarkLabel {
    background-color: ${({ theme }) => theme.colors.cta};
    color: ${({ theme }) => theme.colors.ctaText};
  }
  input:focus ~ .checkmarkLabel {
    outline: 2px solid ${({ theme }) => theme.colors.cta};
    outline-offset: 2px;
  }
`;

export const Checkbox = (props) => {
  const { register } = useFormContext();

  const { name, value, defaultChecked, required } = props;
  const id = name;
  const { onChange, ref } = register(id, { required: required ? "Required" : null });
  const [checked, setChecked] = React.useState(defaultChecked || false);

  React.useEffect(() => {
    if (onChange) {
      if (checked !== value) onChange({ target: { value: checked, name } });
    }
  }, [checked, onChange, value, name]);

  return (
    <CheckBoxContainer onClick={() => setChecked(!checked)}>
      <input
        id={id}
        ref={ref}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      />
      <CheckMark className="checkmark" />
    </CheckBoxContainer>
  );
};

export const RadioButtonContainerStyle = styled.label`
  display: flex;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
  input[type="radio"] {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1px solid grey;
    margin-right: 0.5rem;
    &:checked {
      background-color: ${({ theme }) => theme.colors.cta};
      &:after {
        content: "";
        background-color: ${({ theme }) => theme.colors.cta};
      }
    }
  }
`;

export const RadioButton = (props) => {
  const { register } = useFormContext();

  const { label, name, required } = props;
  const id = name;
  const { ...otherRegisterProps } = register(id, { required: required ? "Required" : null });
  return (
    <RadioButtonContainerStyle>
      <label id={`${name}_${props.fieldId}`} className="radioButtonLabel">
        <input
          type="radio"
          aria-labelledby={`${name}_${props.fieldId}`}
          value={props.fieldId}
          id={props.fieldId}
          {...otherRegisterProps}
        />
        {label}
      </label>
    </RadioButtonContainerStyle>
  );
};

export const CheckboxText = (props) => {
  const { register } = useFormContext();

  const { label, name, value, defaultChecked, required } = props;
  const id = name;
  const { onChange, ref } = register(id, { required: required ? "Required" : null });
  const [checked, setChecked] = React.useState(defaultChecked || false);

  React.useEffect(() => {
    if (onChange) {
      if (checked !== value) onChange({ target: { value: checked, name } });
    }
  }, [checked, onChange, value, name]);

  // TODO: For accessability we should use a checkbox instead of a button
  return (
    <OnOffButtonStyle
      ref={ref}
      on={checked ? "true" : undefined}
      onClick={() => setChecked((prev) => !prev)}
      type="button"
    >
      {label}
    </OnOffButtonStyle>
  );
};

export interface ICheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  setChecked: (v: boolean) => void;
  label?: string;
}

export const CheckboxTextB = React.forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ id, name, value, checked, setChecked, label, ...additionalProps }, ref) => {
    return (
      <CheckBoxContainer onClick={() => {}}>
        <input
          id={id}
          ref={ref}
          type="checkbox"
          name={name}
          value={value || ""}
          checked={checked || false}
          aria-label={label}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          {...additionalProps}
        />
        {label ? (
          <label className="checkmarkLabel" htmlFor={id}>
            {label}
          </label>
        ) : (
          <CheckMark className="checkmark" />
        )}
      </CheckBoxContainer>
    );
  }
);
