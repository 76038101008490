import React from "react";
import { AppInfoSection, AppSectionHeading, AppPageRestrictedStyle } from "../../styles/app";
import { TeacherExtraAppInfo } from "../../components/Documents/AppTeacherExtraInfo";

const TeacherNotesPage: React.FC = () => {
  return (
    <AppPageRestrictedStyle data-testid="samheAppContent">
      <div data-testid="appPage-resourcesView">
        <AppInfoSection>
          <AppSectionHeading>SAMHE Web App - Teacher Notes</AppSectionHeading>
          <p>As a new 'admin' or 'teacher' user on the SAMHE Web App here are some resources you may find helpful:</p>
        </AppInfoSection>
        <AppInfoSection>
          <TeacherExtraAppInfo />
        </AppInfoSection>
      </div>
    </AppPageRestrictedStyle>
  );
};

export default TeacherNotesPage;
