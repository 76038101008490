import { themeColors } from "../../styles/themes";

export enum EAchievementCategory {
  QUIZ = 1,
  TOP_RESPONDANT = 2,
  TEAM_STUDENT = 3,
  CHIEF_INVESTIGATOR = 4,
  CONTENT_CREATOR = 5,
  EXPERIMENT_WHIZZ = 6,
  DATA_ANALYST = 7,
  HERO = 8,
}

export const AchievementLevelLogos: Record<number, string> = {
  0: "/images/app/shields/BLUE.png",
  1: "/images/app/shields/GREEN.png",
  2: "/images/app/shields/GREY.png",
  3: "/images/app/shields/ORANGE.png",
  4: "/images/app/shields/TURQUOISE.png",
};

export interface IAchievementCategory {
  id: EAchievementCategory;
  label: string;
  color: string;
  description: string;
  logo: string;
}

export const AchievementCategories: Record<EAchievementCategory, IAchievementCategory> = {
  [EAchievementCategory.QUIZ]: {
    id: EAchievementCategory.QUIZ,
    label: "Quiz Pro",
    logo: "/images/app/achievements/Quiz_Genius.png",
    description: "You aced our quiz challenges!",
    color: themeColors.PANTONE2726C,
  },
  [EAchievementCategory.TOP_RESPONDANT]: {
    id: EAchievementCategory.TOP_RESPONDANT,
    label: "Data Gatherer",
    logo: "/images/app/achievements/Evidence_Collector.png",
    description:
      "The data you provide is improving the evidence base for our research. The more you can tell us about your classroom conditions the better we can understand air quality. Thank you.",
    color: themeColors.PANTONE534CDark,
  },
  [EAchievementCategory.TEAM_STUDENT]: {
    id: EAchievementCategory.TEAM_STUDENT,
    label: "Team Player",
    logo: "/images/app/achievements/Team_Player.png",
    description:
      "Research projects always rely on teams to deliver their aims. You're working well with others to explore key concepts. Nice one.",
    color: themeColors.PANTONE369C,
  },
  [EAchievementCategory.CHIEF_INVESTIGATOR]: {
    id: EAchievementCategory.CHIEF_INVESTIGATOR,
    label: "Chief Investigator",
    logo: "/images/app/achievements/Chief_Investigator.png",
    description:
      "By working through our questions you can identify with increasing accuracy potential causes of unusual data trends.",
    color: themeColors.PANTONE2521C,
  },
  [EAchievementCategory.CONTENT_CREATOR]: {
    id: EAchievementCategory.CONTENT_CREATOR,
    label: "Content Creator",
    logo: "/images/app/achievements/Content_Creator.png",
    description:
      "Science isn't just about designing and running experiments, research teams need members with varied skill sets. Creative skills are highly valued and help to convey key concepts.",
    color: themeColors.PANTONE7459C,
  },
  [EAchievementCategory.EXPERIMENT_WHIZZ]: {
    id: EAchievementCategory.EXPERIMENT_WHIZZ,
    label: "Experiment Whizz",
    logo: "/images/app/achievements/Experiment_Whizz.png",
    description:
      "You're conducting experiments and evaluating the effects of changes you make on your environment. What have you learnt? What will you test next?",
    color: themeColors.PANTONE2010C,
  },
  [EAchievementCategory.DATA_ANALYST]: {
    id: EAchievementCategory.DATA_ANALYST,
    label: "Data Analyst",
    logo: "/images/app/achievements/Data_Analyst.png",
    description: "Spotting trends and patterns in data helps you to make sense of it and answer scientific questions",
    color: themeColors.PANTONE311C,
  },
  [EAchievementCategory.HERO]: {
    id: EAchievementCategory.HERO,
    label: "SAMHE Hero",
    logo: "/images/app/achievements/SAMHE_Hero.png",
    description: "You're a great all rounder",
    color: themeColors.PANTONE2602C,
  },
};

export interface IActivityScore {
  category: {
    id: EAchievementCategory;
    label: string;
  };
  score: number;
}
