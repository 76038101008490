import { P, Section } from "../../styles/page";
import { SimplePlot } from "../DataViz/SimplePlot/SimplePlot";
import { IMonitorDataItem } from "../../lib/MonitorData/IMonitorData";
import { EDataMetricsStrId } from "../../lib/DataViews/EDataMetrics";
import { ETimeFormat } from "../../lib/DataViews/ETimeFormats";
import { simpleTooltipFormatter } from "../DataViz/common/TooltipFormatters";

const dataWithIncreasingTrend: IMonitorDataItem<number>[] = [
  10, 11, 12, 13, 14, 15, 16, 17, 18, 18.5, 18.3, 18.8, 19,
].map((v, i) => ({
  dateStamp: new Date(new Date("2020-01-01T08:00:00").valueOf() + i * 1000 * 60 * 15),
  value: v,
  field: EDataMetricsStrId.TEMPERATURE,
}));

const dataWithSmallSpikes: IMonitorDataItem<number>[] = [10, 14, 11, 12, 15, 10, 11, 17, 18, 18.5, 18.3, 16.8, 17].map(
  (v, i) => ({
    dateStamp: new Date(new Date("2020-01-01T08:00:00").valueOf() + i * 1000 * 60 * 15),
    value: v,
    field: EDataMetricsStrId.TEMPERATURE,
  })
);

const dataWithSharpDecrease: IMonitorDataItem<number>[] = [10, 14, 13, 14, 3, 6, 17, 17, 18, 18.5, 18.3, 18.8, 19].map(
  (v, i) => ({
    dateStamp: new Date(new Date("2020-01-01T08:00:00").valueOf() + i * 1000 * 60 * 15),
    value: v,
    field: EDataMetricsStrId.TEMPERATURE,
  })
);

const dataWithSharpIncrease: IMonitorDataItem<number>[] = [
  12, 14, 11, 12, 10, 20, 19, 21, 20, 19.8, 20.3, 20.4, 21,
].map((v, i) => ({
  dateStamp: new Date(new Date("2020-01-01T08:00:00").valueOf() + i * 1000 * 60 * 15),
  value: v,
  field: EDataMetricsStrId.TEMPERATURE,
}));

export const DataEventGuidance = (
  <>
    <Section>
      <P>
        Throughout the day, your monitor readings will vary leading to a variety of different patterns on the
        corresponding graphs. For the data detectives activity, we would like you to only consider changes which are
        large and rapid. You can use the four graphs below as a guide to help identify if the changes you see in your
        graphs are considered as data events or not.
      </P>
      <P>
        <b>Graph 1: A slow increase in the data trend</b>
      </P>
      <SimplePlot
        data={dataWithIncreasingTrend}
        yLabel="Temperature (°C)"
        timeFormat={ETimeFormat.TIME}
        tooltipOptions={{
          tooltipFormatter: simpleTooltipFormatter,
        }}
        dateFormatOptions={{ compact: true }}
      />
      <P>
        Here, the data may be seen to trend upwards from a low value, before becoming steady at a new, higher value.
        This change takes place slowly, over the course of about two hours, so <b>would not</b> classify as a data event
        for the purpose of the data detectives activity.
      </P>
      <P>
        <b>Graph 2: Small increases/decreases in the data values</b>
      </P>
      <SimplePlot
        data={dataWithSmallSpikes}
        yLabel="Temperature (°C)"
        timeFormat={ETimeFormat.TIME}
        tooltipOptions={{
          tooltipFormatter: simpleTooltipFormatter,
        }}
        dateFormatOptions={{ compact: true }}
      />
      <P>
        In this graph, the data values go up and down throughout the morning (although the average reading does not
        change very much). However, these changes are quite small, so <b>would not</b> be defined as a data event for
        the purpose of the data detectives activity.
      </P>
      <P>
        <b>Graph 3: A sharp decrease in the data trend for a short amount of time</b>
      </P>
      <SimplePlot
        data={dataWithSharpDecrease}
        yLabel="Temperature (°C)"
        timeFormat={ETimeFormat.TIME}
        tooltipOptions={{
          tooltipFormatter: simpleTooltipFormatter,
        }}
        dateFormatOptions={{ compact: true }}
      />
      <P>
        This graph is similar to the first graph. However, as the data trends upwards, there is a sharp and sudden
        decrease in the data that lasts for only a few minutes. The short duration and size of the change means this
        would be considered as a data event for the data detectives activity.
      </P>
      <P>
        <b>Graph 4: A large, sudden increase in the data trend</b>
      </P>
      <SimplePlot
        data={dataWithSharpIncrease}
        yLabel="Temperature (°C)"
        timeFormat={ETimeFormat.TIME}
        tooltipOptions={{
          tooltipFormatter: simpleTooltipFormatter,
        }}
        dateFormatOptions={{ compact: true }}
      />
      <P>
        This graph is similar to the second graph. However, in this case there is a large change in the measurement
        which happens suddenly. This would be considered as a data event for the data detectives activity.
      </P>
    </Section>
  </>
);
