import { postDataFetch, IApiResponse } from "./apiHelpers";
import { IRoomListItem, RoomId } from "../lib/School/IRoom";
import { SERVER_URL } from "../config";
import { EMonitorChangeSource, MonitorId } from "../lib/Monitor/IMonitor";
import { TActivityId } from "../lib/Activities/IActivity";

/**
 * Submit the register sensor form
 *
 * @returns
 */
export const apiActivateMonitor = (accessToken: string) => async (monitorId: MonitorId, roomId?: RoomId) => {
  const url = `${SERVER_URL}/MonitorManagement/ActivateMonitor`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{ monitorId: MonitorId; roomId: RoomId }, IApiResponse>(url, { monitorId, roomId }, headers);
};

export const apiMonitorManagement = {
  activate: apiActivateMonitor,
};

export const apiChangeMonitorLocation = async (
  accessToken: string,
  monitorId: MonitorId,
  newRoom: IRoomListItem,
  fromRoom?: IRoomListItem,
  activityId?: TActivityId,
  dateOverride?: Date,
  updatedFrom? : EMonitorChangeSource
) => {
  if (!accessToken) throw Error("Missing access token");
  const url = `${SERVER_URL}/MonitorManagement/ChangeLocation`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}, IApiResponse>(
    url,
    {
      MonitorReferenceId: monitorId,
      FromRoomId: fromRoom?.id,
      ToRoomId: newRoom.id,
      ActivityId: activityId,
      DateMoved: dateOverride || new Date(Date.now()),
      Source: updatedFrom,
    },
    headers
  );
};
