import { postDataFetch, IApiResponse } from "./apiHelpers";

import { SERVER_URL } from "../config";
import { ITechContactFormSubmission } from "../lib/formSubmissions/ITechContactFormSubmission";

/**
 * Submit the contact form
 *
 * @returns
 */
export const apiSubmitTechContactForm = async (data: ITechContactFormSubmission) => {
  const url = `${SERVER_URL}/Contact/contactTechSupport`;
  return postDataFetch<ITechContactFormSubmission, IApiResponse>(url, data);
};
