import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ESessionStatus } from "../../Redux/User/userReducer";
import { useAppSelector } from "../../Redux/hooks";
import UserDropDownIcon from "./userDropDownIcon";
import "./_userDropDown.scss";
import styled from "styled-components";
import { ButtonStyle } from "../../styles/button";
import { UserGroup } from "../../lib/User/UserGroup";
import { AUTH_URL_LOGOUT } from "../../config";
import { InternalLinkNoFormat, LinkNoFormat } from "../../styles/links";

export const LoginBtn = ({ onClick }) => (
  <div className="loginBtn" aria-label="Show user dropdown" data-testid="loginBtn">
    <ButtonStyle type="button" className="button-one" onClick={onClick}>
      Log In
    </ButtonStyle>
  </div>
);

LoginBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const UserDropdownLink = styled(InternalLinkNoFormat)`
  background: white;
  color: ${({ theme }) => theme.colors.text};
  padding: 0 1rem;
  display: block;
  width: 100%;
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.grey1};
  }
`;

export const UserDropdownExternalLink = styled(LinkNoFormat)`
  background: white;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  padding: 0 1rem;
  display: block;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.grey1};
  }
`;

// Shown when logged in
export const UserDropDownMenu = ({ menuOpen }) => {
  const {
    user: { group },
  } = useAppSelector((state) => state.userState);

  return (
    <div className="userDropDownMenu_wrap">
      <div
        // Set menu class hidden if menuOpen is false
        className={`userDropDownMenu ${menuOpen ? "" : "hidden"}`}
      >
        <ul data-testid="userDropDownMenuList">
          <li>
            <UserDropdownLink to="/user/profile">
              <b>Profile</b>
            </UserDropdownLink>
          </li>
          <li>
            <UserDropdownLink to="/">
              <b>Go to SAMHE Website</b>
            </UserDropdownLink>
          </li>
          <li>
            <UserDropdownLink to="/app">
              <b>Go to SAMHE Web App</b>
            </UserDropdownLink>
          </li>
          {group === UserGroup.SUPERADMIN && (
            <li>
              <UserDropdownLink to="/admin">
                <b>Admin Panel</b>
              </UserDropdownLink>
            </li>
          )}
          {(group === UserGroup.SCHOOLADMIN || group === UserGroup.SUPERADMIN) && (
            <li>
              <UserDropdownLink to="/school/admin">
                <b>School Admin</b>
              </UserDropdownLink>
            </li>
          )}
          <li>
            <UserDropdownExternalLink className="logoutBtn" href={AUTH_URL_LOGOUT}>
              <b>Log Out</b>
            </UserDropdownExternalLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

UserDropDownMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
};

export type UserDropDownBtnProps = {
  onLoginCallback?: () => void;
};

export const UserDropDownBtn: React.FC<UserDropDownBtnProps> = () => {
  const navigate = useNavigate();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false); // useAutoHidePanel(menuRef, true, false, 400);

  const userState = useAppSelector((state) => state.userState);
  const { sessionState } = userState;
  const content =
    (sessionState === ESessionStatus.LOGGED_IN && (
      <>
        <UserDropDownIcon onHover={(e) => (e ? setMenuOpen(e) : "")} />
        <UserDropDownMenu menuOpen={menuOpen} />
      </>
    )) ||
    (sessionState === ESessionStatus.GETTING_TOKEN && "(...)") ||
    (sessionState === ESessionStatus.RECEIVED_TOKEN && "(...)") ||
    (sessionState === ESessionStatus.GETTING_USER_META_DATA && "(...)") ||
    (sessionState === ESessionStatus.LOGGING_OUT && "(...)") ||
    ((sessionState === ESessionStatus.NEW_SESSION || sessionState === ESessionStatus.LOGGED_OUT) && (
      <LoginBtn onClick={() => navigate("/app")} />
    )) ||
    sessionState;

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  }
  React.useEffect(() => {
    // Bind the event listener
    if (menuOpen) {
      document.addEventListener("keydown", handleClickOutside);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div className="userDropDownBtn_wrap" data-testid="userDropdown" ref={menuRef}>
      {content}
    </div>
  );
};

export default UserDropDownBtn;
