import React from "react";
import Faq from "../components/faq/faq";
import { ConstrainedPageStyle, PageTitle, Section } from "../styles/page";
import { ContactPageContent } from "./contact";

const FaqPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <Section>
        <PageTitle>SAMHE FAQ</PageTitle>
        <Faq />
      </Section>
      <ContactPageContent />
    </ConstrainedPageStyle>
  );
};

export default FaqPage;
