import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { ConstrainedPageStyle, PageTitle, Section, P, Ul, SectionBackground } from "../styles/page";
import { CTALinkButtonStyle } from "../styles/links";
import { DownloadLink } from "../components/Links";
import { BoxoutHero } from "../styles/hero";
import { themeColors } from "../styles/themes";

export const ResourceHubWrapper = styled(Section)`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }

  h2 {
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  }

  ul {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
`;

export const ResourceHubDiv = styled.div`
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  padding: 1rem;
  position: relative;

  & > img {
    width: 50%;
    height: auto;
  }

  & > ${CTALinkButtonStyle} {
    margin-top: 2rem;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    padding: 0.3rem 1rem;
    display: inline-block;
  }
`;

export const AirPollutionDiv = styled(ResourceHubDiv)`
  text-align: center;
  background-color: #d3dafe;

  & > ${CTALinkButtonStyle} {
    background-color: #4c5ebc;

    &:focus {
      outline: #28367d solid 3px;
      outline-offset: -4px;
      border-color: ${({ theme }) => theme.colors.grey1};
    }
    &:hover {
      background: #28367d;
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

export const MonitorDiv = styled(ResourceHubDiv)`
  text-align: center;
  background-color: #d4a4ee;
`;

export const GuidanceDiv = styled(ResourceHubDiv)`
  text-align: center;
  background-color: #a5e6ef;

  & > ${CTALinkButtonStyle} {
    background-color: #0091a5;

    &:focus {
      outline: #014852 solid 3px;
      outline-offset: -4px;
      border-color: ${({ theme }) => theme.colors.grey1};
    }
    &:hover {
      background: #014852;
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

const DownloadBoxoutHero = styled(BoxoutHero)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;

  ${P} {
    margin: 0;
  }

  ${CTALinkButtonStyle} {
    background-color: ${({ theme }) => theme.colors.baseBackground};
    color: black;

    &:focus {
      outline: #28367d solid 3px;
      outline-offset: -4px;
      border-color: ${({ theme }) => theme.colors.grey1};
    }
    &:hover {
      background: ${themeColors.PANTONE2726C};
      color: white;
    }
    &:disabled,
    &[disabled] {
      background: ${({ theme }) => theme.colors.grey2};
    }
  }
`;

const ResourcesPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>Resources Hub</PageTitle>
      </SectionBackground>
      <DownloadBoxoutHero>
        <P>Teacher Resource Pack - for schools without a SAMHE monitor</P>
        <CTALinkButtonStyle to="/get-involved">Download the pack from our Get Involved page</CTALinkButtonStyle>
      </DownloadBoxoutHero>
      <ResourceHubWrapper>
        <AirPollutionDiv>
          <img src="/images/resources/molecules_small.png" alt="Molecules" />
          <P>What is air pollution? Why does air quality matter and what can we do about it?</P>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/resources/air-pollution">Learn about air pollution</CTALinkButtonStyle>
        </AirPollutionDiv>

        <MonitorDiv>
          <img src="/images/resources/monitor_small.png" alt="SAMHE monitor" />
          <P>Put your SAMHE monitor readings into context and find out what they mean for you.</P>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/resources/monitor-leds">Understand your monitor readings</CTALinkButtonStyle>
        </MonitorDiv>
        <GuidanceDiv>
          <img src="/images/resources/open_window_small.png" alt="Open window" />
          <P>Develop your understanding of classroom ventilation options and get tailored practical guidance.</P>
          <br></br>
          <br></br>
          <CTALinkButtonStyle to="/resources/ventilation">Get practical guidance</CTALinkButtonStyle>
        </GuidanceDiv>
      </ResourceHubWrapper>

      <ResourceHubWrapper>
        <ResourceHubDiv>
          <h2 className="text-center">General/background</h2>
          <Ul>
            <li>
              <Link to="/resources/key-definitions">Key definitions</Link>
            </li>
            <li>
              <Link to="/resources/air-pollution-and-health">Air pollution and health</Link>
            </li>
            <li>
              <Link to="/resources/air-cleaning-devices">Air cleaning devices</Link>
            </li>
            <li>
              <Link to="/resources/glossary">Glossary of Web App terms</Link>
            </li>
          </Ul>
        </ResourceHubDiv>

        <ResourceHubDiv>
          <h2 className="text-center">Teaching</h2>
          <Ul>
            <li>
              <DownloadLink href="/resources/samhe_assemblies_and_notes_for_schools.zip">
                Introductory Assembly slides & notes
              </DownloadLink>
            </li>
            <li>
              <DownloadLink href="/resources/ADITL_SAMHE_Publication_v6.pdf">
                Day in the life of the SAMHE Team - Careers Resource
              </DownloadLink>
            </li>
            <li>
              <Link to="/resources/curriculum-links">Curriculum Links</Link>
            </li>
            <li>
              <a href="https://youtu.be/EgenIlp6w3g" target="_blank" rel="noreferrer">
                Schools' webinar on findings
              </a>
            </li>
          </Ul>
        </ResourceHubDiv>

        <ResourceHubDiv>
          <h2 className="text-center">Videos</h2>
          <Ul>
            <li>
              <a href="https://youtu.be/uXigPfxikbw" target="_blank" rel="noreferrer">
                Assembly (short)
              </a>
            </li>
            <li>
              <a href="https://youtu.be/sAEnt6KQ3xU" target="_blank" rel="noreferrer">
                Assembly (full)
              </a>
            </li>
            <li>
              <a href="https://youtu.be/LCh-EiSnczw" target="_blank" rel="noreferrer">
                Web App tour
              </a>
            </li>
            <li>
              <a href="https://youtu.be/8fhvbjLUMtg" target="_blank" rel="noreferrer">
                Teacher testimonials
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/playlist?list=PLy5kP5wcN-NXnklGwf6TLID-JYtwx9uZh"
                target="_blank"
                rel="noreferrer"
              >
                Air quality videos
              </a>
            </li>
            <li>
              For more videos, visit{" "}
              <a href="https://www.youtube.com/channel/UCEp7Uce52tS5wd8Asvgt2MA" target="_blank" rel="noreferrer">
                our Youtube channel
              </a>
              .
            </li>
          </Ul>
        </ResourceHubDiv>

        <ResourceHubDiv>
          <h2 className="text-center">Other</h2>
          <Ul>
            <li>
              <Link to="/resources/media">Media pack</Link>
            </li>
            <li>
              <Link to="/faq">FAQs</Link>
            </li>
            <li>
            <DownloadLink href="/resources/A4_SAMHE_LED_poster.pdf">Monitor LEDs poster</DownloadLink>
            </li>
            <li>
              <DownloadLink href="/resources/samhe_monitor_sign.zip">SAMHE monitor sign</DownloadLink>
            </li>
            <li>
              <DownloadLink href="/resources/we_are_part_of_samhe.zip">"We're part of SAMHE" poster</DownloadLink>
            </li>
          </Ul>
        </ResourceHubDiv>
      </ResourceHubWrapper>
    </ConstrainedPageStyle>
  );
};

export default ResourcesPage;
