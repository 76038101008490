import { Link } from "react-router-dom";
import { DocumentStyle } from "../../styles/documents";
import { SectionHeading, P, Section } from "../../styles/page";
import { DownloadLink } from "../Links";

export const lastUpdated = "22/08/2024";
export const version = "2.1";

export const AdminParticipationInfo = () => {
  return (
    <DocumentStyle>
      <Section>
        <SectionHeading style={{ margin: 0 }}>
          SAMHE (Schools' Air quality Monitoring for Health and Education)
        </SectionHeading>
        <P>Version: {version}</P>
        <P> Last updated {lastUpdated} </P>
        <P>
          You can also download this document here:
          <DownloadLink href="/resources/SAMHE_Admin_information_sheet.pdf">
            SAMHE Admin User Information Sheet (.pdf){" "}
          </DownloadLink>
        </P>
      </Section>
      <Section>
        <SectionHeading>Admin User Welcome and Information Sheet</SectionHeading>
        <P>
          This document contains information about the project, the implications of creating an admin account for your
          school, and how we are going to use data entered via the Web App.
        </P>
      </Section>
      <Section>
        <SectionHeading>Who is involved with SAMHE</SectionHeading>
        <P>
          The project is jointly led by Imperial College London (Dr Henry Burridge from the Civil and Environmental
          Engineering Department), and the University of Cambridge (Professor Paul Linden), in collaboration with the
          Stockholm Environment Institute (SEI) at the University of York, the University of Surrey, the University of
          Leeds and the UK Health Security Agency (UK HSA).
        </P>
      </Section>
      <Section>
        <SectionHeading>Participating in SAMHE as an admin user</SectionHeading>
        <P>
          When you completed the <Link to="/register/school">registration form</Link> you were asked to provide a
          generic email address. This is the email address that will be used for your school's Admin account.
        </P>
        <P>
          Before you set up your school's Admin account, we'd like to remind you why the research is being done and what
          participating in SAMHE will involve for your school.
        </P>
        <P>
          <b>Please note:</b>
        </P>
        <P>
          As part of the Admin account creation process you will also be asked to set up a 'school handle' and two
          different 'Secret Words'- one for pupils and one for teachers. All subsequent users will need to enter your
          chosen school handle and the appropriate Secret Word to associate their account with your school.
        </P>
        <P>
          We therefore recommend that you make a note of your school's 'school handle' and 'Secret Words'. Please be
          aware that these are means of securing accounts at your school and they should not be shared beyond teachers
          or pupils at your school. Your pupils can create their own SAMHE Pupil accounts. This will not identify the
          pupils to either you or the research team. We have created an opt-out letter for parents and guardians which
          you can download and send as appropriate.
        </P>
        <P>
          <b>Please take time to read the following information carefully.</b>
        </P>
        <P>
          If there is anything that is not clear or if you would like more information, please{" "}
          <Link to="/contact">contact us</Link>.
        </P>
        <P>Thank you for reading this.</P>
      </Section>
      <Section>
        <SectionHeading>What is the purpose of the study?</SectionHeading>
        <P>
          The purpose of this project is to establish an air quality monitoring network in schools across the UK and
          through the network record measurements of carbon dioxide and selected air pollutants to evaluate classroom
          ventilation levels. The monitors we provide schools are for schools to keep. They measure carbon dioxide,
          particulate matter, total volatile organic compounds, temperature and relative humidity. Our Web App will
          allow teachers and pupils to see how these measurements change over time.
        </P>
        <P>
          Through interactions with the Web App both teachers and students will gain an enhanced understanding of air
          quality science. Teaching materials, including videos, information sheets, and links to other resources about
          air quality will be provided. The Web App will prompt pupils and teachers to complete activities relating to
          the monitor. These can be done as part of lessons (e.g. geography, science, IT, maths), or in extra-curricular
          clubs such as science or eco clubs. We hope that monitors provided as part of the project will enable your
          school to monitor air quality in teaching environments and make informed decisions to control ventilation and
          improve air quality.
        </P>
      </Section>
      <Section>
        <SectionHeading>Why have I been asked to complete this process?</SectionHeading>
        <P>
          You are being asked to complete this Admin account creation process on behalf of your school, as you or
          someone at your school has requested a SAMHE air quality monitor. All schools in the UK, except those
          exclusively for early years pupils, are eligible to take part.
        </P>
      </Section>
      <Section>
        <SectionHeading>Does my school have to take part?</SectionHeading>
        <P>
          We appreciate that schools are very busy and it might not be easy to find the time to keep on top of SAMHE
          activities, so we'll be doing everything we can to make sure that it is easy for you to do. You can decide to
          stop taking part in SAMHE activities at any time and there will be no repercussions.
        </P>
        <P>
          As part of the account creation process, you will be asked to confirm on behalf of your school that your
          school agrees for the data entered using the Admin account to be used for the purposes outlined herein.
        </P>
      </Section>
      <Section>
        <SectionHeading>What will setting up an account involve?</SectionHeading>
        <P>
          As outlined above, to create Admin account for your school you will need to create a 'school handle' and two
          'secret words' (one for students and one for teachers). You will also need to create a password to secure your
          account.
        </P>
        <P>
          We require that you associate your school's Admin account with a generic email address which does not identify
          anyone at your school and should not relate to a specific member of staff. This is because we need your school
          to be able to maintain access to your school's account even if staff members are on leave, or if they stop
          working at your school. By default, we use the generic email address provided via the registration form, if
          you need to change it for any reason, please <Link to="/contact">contact us</Link>.
        </P>
      </Section>
      <Section>
        <SectionHeading>What are the responsibilities of an Admin user?</SectionHeading>
        <P>
          As an Admin user you, and any other members of staff who help you to monitor this account, will be{" "}
          <b>responsible</b> for managing this account, including sharing Secret Words which other users will need to
          create their accounts, and maintaining your school's Room List.
        </P>
      </Section>
      <Section>
        <SectionHeading>What if something goes wrong?</SectionHeading>
        <P>
          The SAMHE project is gifting your school a monitor. By accepting this gift, your school is accepting
          responsibility for the monitor, its safe usage within your school, and any liability that arises as a result.
          For advice on the safe usage of the air quality monitor, including standard operating procedures and a risk
          assessment, please refer to the documentation accompanying the delivery of your monitor. You can also access
          this information on our <Link to="/safety-and-data-protection">Safety and Data protection page</Link>.
        </P>
        <P>
          If you are harmed by taking part in this research project, there are no special compensation arrangements. If
          you are harmed due to someone's negligence, then you may have grounds for a legal action. Regardless of this,
          if you wish to complain, or have any concerns about any aspect of the way you have been treated during the
          course of this study then you should immediately inform the research team at{" "}
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a> If you are still not satisfied with the response,
          you may contact the Imperial College Research Governance Integrity Team (
          <a href="mailto:rgitcoordinator@imperial.ac.uk">rgitcoordinator@imperial.ac.uk</a>) .
        </P>
      </Section>
      <Section>
        <SectionHeading>Who will be able to see our data?</SectionHeading>
        <P>
          The data from your monitor will only be accessible to your school, researchers involved with the SAMHE
          project, and other researchers as authorised by the project team. No other schools will be able to see the
          data from your monitor. For the purposes of comparison, we plan to show average results from schools within
          the local authority.
        </P>
      </Section>
      <Section>
        <SectionHeading>What will happen to the results of the research study?</SectionHeading>
        <P>
          Results from this study will be analysed and summarised in academic papers which will be made publicly
          available. Once these have been published, they will be made available on the SAMHE website. Data supporting
          the Web App will be stored in a data repository and be made available to other researchers. No personal data
          will be included in data repositories. Unless we receive written permission from your school, your school will
          not be identified in any project outputs.
        </P>
      </Section>
      <Section>
        <SectionHeading>Who is organising and funding the research?</SectionHeading>
        <P>
          Initial funding for this project was granted by the Department for Education, which continues to be involved.
          Funding for the main project is provided by the Engineering and Physical Sciences Research Council (EPSRC).
        </P>
      </Section>
      <Section>
        <SectionHeading>Who has reviewed the study?</SectionHeading>
        <P>
          This study was given favourable opinion by the Science, Engineering and Technology Research Ethics Committee
          (SETREC) and approval by Washington Ochieng, Head of Department of Civil and Environmental Engineering and the
          Research Governance and Integrity Team (RGIT).
        </P>
      </Section>
      <Section>
        <SectionHeading>Contact for Further Information</SectionHeading>
        <P>
          If you would like any further information about this project please contact the SAMHE team using{" "}
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>.
        </P>
      </Section>
    </DocumentStyle>
  );
};
