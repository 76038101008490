import React from "react";
import { usePopper } from "react-popper";
import { PopupPanel, PopupPanelContext } from "@react_db_client/components.popup-panel-v2";
import { ITag } from "../../lib/Tags/ITag";
import { TagButtonStyle, TagListItemStyle } from "./style";
import { TagPopup } from "./TagPopup";
import { TagTooltip } from "./TagTooltip";
import { ExpandedPanelStyle } from "../../styles/popups";

export interface ITagProps {
  data: ITag;
}

export const Tag = ({ data, data: { uid, label = "MISSING LABEL" } }: ITagProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {});
  const { openPopup } = React.useContext(PopupPanelContext);
  const popupId = `tagPopup_${uid}`;
  const popupIdTooltip = `tagPopup_${uid}_tooltip`;

  const handleHover = () => {
    openPopup(popupIdTooltip);
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <TagListItemStyle>
        <TagButtonStyle
          /** TODO: Temporarily disable tag interations. Need review! */ 
          // onMouseOver={handleHover}
          // onFocus={handleHover}
          // onBlur={handleBlur}
          // onMouseOut={handleBlur}
          // onClick={() => openPopup(popupId)}
          ref={setReferenceElement}
        >
          <span>{label}</span>
        </TagButtonStyle>
        {showTooltip && (
          <TagTooltip
            ref={setPopperElement}
            data={data}
            style={styles.popper}
            attributes={{
              ...attributes,
              onMouseOver: handleHover,
              onFocus: handleHover,
              onBlur: handleBlur,
              onMouseOut: handleBlur,
              onClick: () => openPopup(popupId),
            }}
          />
        )}
      </TagListItemStyle>
      {/* Popups and tooltips.... */}
      <PopupPanel id={popupId}>
        <ExpandedPanelStyle>
          <TagPopup data={data} id={popupId} />
        </ExpandedPanelStyle>
      </PopupPanel>
    </>
  );
};
