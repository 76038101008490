import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PopupProvider } from "@react_db_client/components.popup-panel-v2";
import { lightTheme } from "./styles/themes";
import store from "./Redux/reduxStore";
import StyleProviders from "./styles/StyleProviders";
import ErrorBoundary from "./GenericComponents/ErrorBoundary";
import { HELP_CONTACT } from "./config";
import { KeywordProvider } from "./components/TextFormatter/KeyWordProvider";
import { keywordMap } from "./dummyData/keywords";
import { NotificationBar } from "./GenericComponents/NotificationBar";
import { NewAchievementPopup } from "./components/Achievements/NewAchievementPopup";
import "./styles/css/style.scss";
import { useSyncMonitorLocationSelection } from "./components/MonitorLocationSelection/monitorLocationSelectionDropdowns";

const NestedHooks = () => {
  useSyncMonitorLocationSelection();
  return <></>;
};
function Providers({ children }) {
  return (
    <BrowserRouter>
      <StyleProviders theme={lightTheme}>
        <PopupProvider>
          <Provider store={store}>
            <NotificationBar />
            <NewAchievementPopup />
            <KeywordProvider keywordMap={keywordMap}>
              <ErrorBoundary addr={HELP_CONTACT} emailSubject="SAMHE App Error Report">
                <NestedHooks />
                {children}
              </ErrorBoundary>
            </KeywordProvider>
          </Provider>
        </PopupProvider>
      </StyleProviders>
    </BrowserRouter>
  );
}

export default Providers;
