import React from "react";
import { ConstrainedPageStyle, PageTitle, Section, SectionHeading } from "../styles/page";

const NewsletterSignupPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Sign up for the SAMHE newsletter</PageTitle>

      <Section>
        <SectionHeading>Coming Soon! </SectionHeading>
        <p>
          In the mean time please get in touch via <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>{" "}
        </p>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default NewsletterSignupPage;
