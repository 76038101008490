import { EAchievementCategory } from "../lib/Achievements/EAchievementCategory";
import {
  EAchievementStatus,
  EAchievementType,
  IAchievementCompleteActivityType,
  IAchievementScoreType,
  IAchievementUser,
  TAchievementId,
} from "../lib/Achievements/IAchievement";
import { dummyActivity } from "./activities";

export const demoActivityCompleteAchievement: IAchievementCompleteActivityType = {
  id: "demoActivityCompleteAchievement",
  label: `You completed activity ${dummyActivity.label}`,
  activityId: dummyActivity.id,
  type: { id: EAchievementType.ACTIVITY_COMPLETE, label: "Activity Complete" },
  description: `Congratulations you won an achievement for completing the ${dummyActivity.label} activity!`,
  category: EAchievementCategory.CHIEF_INVESTIGATOR,
  logo: "/images/app/achievements/SAMHE_Hero.png",
};

export const DEMO_ACHIEVEMENTS_ALL: Record<TAchievementId, IAchievementCompleteActivityType | IAchievementScoreType> = {
  demoActivityCompleteAchievement,
};

export const DEMO_ACHIEVEMENTS_USER: Record<TAchievementId, IAchievementUser> = {
  demoActivityCompleteAchievement: {
    ...demoActivityCompleteAchievement,
    status: EAchievementStatus.COMPLETE,
    dateCreated: new Date().toJSON(),
  },
};
