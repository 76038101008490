import { postDataFetch, IApiResponse, getDataFetch, throwErrors } from "./apiHelpers";

import { SERVER_URL } from "../config";
import { ISchoolValidated, SchoolId } from "../lib/School/ISchoolDetails";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";

export interface IApiDeploymentGeneral {
  monitorRequestId?: any;
  monitorId?: any;
  schoolId?: any;
  monitorSerialNumber?: any;
}

export interface IApiInitiateValidationArgs extends IApiDeploymentGeneral {
  monitorRequestIds: number[];
}

export const apiInitiateValidation = (accessToken: string) => async (data: IApiInitiateValidationArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/InitiateValidation`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiInitiateValidationArgs, IApiResponse>(url, data, headers);
};

export interface IApiMarkRequestsValidArgs {
  monitorRequestIds: number[];
}

export interface IApiMarkRequestsValidResponse extends IApiResponse {
  response: {
    responseData: {
      errors: boolean;
      rejectedRequestMonitorSubmissions: IMonitorRequest[];
    };
  };
}

export const apiMarkRequestsValid = (accessToken: string) => async (data: IApiMarkRequestsValidArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/MarkRequestsValid`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiMarkRequestsValidArgs, IApiMarkRequestsValidResponse>(url, data, headers).then((res) => {
    if (
      res.response.json.response?.responseData?.errors &&
      res.response.json.response?.responseData?.rejectedRequestMonitorSubmissions?.length > 0
    ) {
      const errorMessage = res.response.json.response.responseData.rejectedRequestMonitorSubmissions
        .map((submission) =>
          [
            `schoolName: ${submission.schoolName}`,
            `establishmentId: ${submission.establishmentId}`,
            `schoolId: ${submission.schoolId}`,
          ].join("\t")
        )
        .join("\n");
      throw new Error(`Some changes failed:\n ${errorMessage}`);
    }
    return res;
  });
};

export interface IApiMarkRequestsInvalidArgs {
  invalidMonitorRequests: {
    monitorRequestId: number;
    invalidReason: string;
  }[];
}

export const apiMarkRequestsInvalid = (accessToken: string) => async (data: IApiMarkRequestsInvalidArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/MarkRequestsInvalid`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiMarkRequestsInvalidArgs, IApiResponse>(url, data, headers);
};
export interface IApiLinkMonitorArgs {
  monitorId: string;
  schoolId: number;
}

export const apiLinkMonitor = (accessToken: string) => async (data: IApiLinkMonitorArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/LinkMonitor`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiLinkMonitorArgs, IApiResponse>(url, data, headers);
};

export interface IApiDeployMonitorArgs {
  // monitorSerialNumber: string; //TODO: Remove requirement on server
  schoolIds: number[];
}

export const apiDeployMonitor = (accessToken: string) => async (data: IApiDeployMonitorArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/DeployMonitor`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiDeployMonitorArgs, IApiResponse>(url, data, headers);
};

export interface IApiMarkReceivedArgs {
  monitorSerialNumber: string;
  schoolId: SchoolId;
}

export interface IApiMarkWithdrawnArgs {
  schoolIds: number[];
}

export const apiMarkWithdrawn = (accessToken: string) => async (data: IApiMarkWithdrawnArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/MarkWithdrawn`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiMarkWithdrawnArgs, IApiResponse>(url, data, headers);
};

export interface IApiUpdateDeploymentStatusArgs {
  schoolId: SchoolId;
  newStatus: ESchoolStatus;
  statusReason: string;
}

export const apiMarkReceived = (accessToken: string) => async (data: IApiMarkReceivedArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/UpdateDeploymentStatus`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiUpdateDeploymentStatusArgs, IApiResponse>(
    url,
    {
      ...data,
      newStatus: ESchoolStatus.RECEIVED,
      statusReason: "",
    },
    headers
  );
};

export const apiUpdateDeploymentStatus = (accessToken: string) => async (data: IApiUpdateDeploymentStatusArgs) => {
  const url = `${SERVER_URL}/MonitorDeployment/UpdateDeploymentStatus`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IApiUpdateDeploymentStatusArgs, IApiResponse>(url, data, headers);
};

export interface IApiGetDeploymentStatsReturn extends IApiResponse {
  totalRequestCount;
  totalValidatedRequestCount;
  totalSchoolsWithMonitorLinkedCount;
  totalSchoolsStatusActivated;
  totalSchoolsStatusDeployed;
  totalSchools;
  totalSchoolsStatusDuplicate;
  totalSchoolsStatusWithdrawn;
}

export const apiGetDeploymentStats = (accessToken: string) => async (): Promise<IApiGetDeploymentStatsReturn> => {
  const url = `${SERVER_URL}/MonitorDeployment/Stats`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiGetDeploymentStatsReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => response.json);
};

export interface apiSchoolDetailsReturn extends IApiResponse {
  school;
  data: ISchoolValidated;
}

export const getSchoolDetails = (accessToken: string) => async (id: number) => {
  const url = `${SERVER_URL}/MonitorDeployment/Schools/${id}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiSchoolsReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => response.json);
};

export interface IApiSchoolsReturn {
  id: number;
  schoolId: string;
  monitorRequest: IMonitorRequest;
  monitorReferenceIds: string[];
  potentialDuplicateMonitorRequests: number[];
  status: ESchoolStatus;
}

export const searchSchoolDetails =
  (accessToken: string) =>
  async (status: ESchoolStatus, searchPhrase: string, lastEditedByUser: boolean): Promise<ISchoolValidated[]> => {
    const statusQuery = status ? `schoolStatus=${status}&` : "";
    const query = `${statusQuery}searchPhrase=${searchPhrase || ""}&lastEditedByUser=${
      lastEditedByUser ? "true" : "false"
    }`;
    const url = `${SERVER_URL}/MonitorDeployment/Search?${query}`;
    const headers = { Authorization: "Bearer " + accessToken };
    return getDataFetch<IApiSchoolsReturn[]>(url, "json", headers)
      .then(throwErrors)
      .then(({ response }) =>
        response.json.map((d) => ({
          ...d.monitorRequest,
          status: status || d.status,
          monitorRequestId: d.monitorRequest?.id,
          schoolId: d.schoolId,
          monitors: d.monitorReferenceIds,
          id: d.id,
        }))
      );
  };

export const apiMonitorDeployment = {
  initiateValidation: apiInitiateValidation,
  markRequestsValid: apiMarkRequestsValid,
  markRequestsInvalid: apiMarkRequestsInvalid,
  linkMonitor: apiLinkMonitor,
  deployMonitor: apiDeployMonitor,
  updateDeploymentStatus: apiUpdateDeploymentStatus,
  markReceived: apiMarkReceived,
  getDeploymentStats: apiGetDeploymentStats,
  getSchool: getSchoolDetails,
  searchSchools: searchSchoolDetails,
  markWithdrawn: apiMarkWithdrawn,
};
