import React from "react";
import styled from "styled-components";
import { AppInfoSection, AppSectionHeading, AppPageStyle, CardExpandedHr } from "../../styles/app";
import { AchievementsDashboard } from "../../components/Achievements/AchievementsDashboard";

const AchievementsPageWrapper = styled.div`
  background: white;
  border-radius: 2rem;
`;

const AchievementsPage: React.FC = () => {
  return (
    <AppPageStyle data-testid="samheAppContent" style={{ paddingBottom: "3rem", minHeight: "100%" }}>
      <AchievementsPageWrapper data-testid="appPage-achievementsPage">
        <AppInfoSection style={{ border: "none", boxShadow: "none" }}>
          <AppSectionHeading>Your SAMHE Achievements</AppSectionHeading>
          <CardExpandedHr />
          <p>Below you will find your scores, badges and certificates.</p>
        </AppInfoSection>
        <AchievementsDashboard />
      </AchievementsPageWrapper>
    </AppPageStyle>
  );
};

export default AchievementsPage;
