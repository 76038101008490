import { Link } from "react-router-dom";
import { EFaqAudience, EFaqGroup, IFaqItem } from "./lib";
import { P, Su, Ul, Ol } from "../../styles/page";
import { AExternal, DownloadLink } from "../Links";

const faqEntries: IFaqItem[] = [
  /**
   * PROJECT_BACKGROUND
   */
  {
    uid: "how_can_i_get_involved_with_samhe",
    label: "How can I get involved with SAMHE?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PUPILS,
      EFaqAudience.PARENTS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    highlighted: true,
    content: (
      <div>
        <P>Thanks so much for your interest in SAMHE! We are delighted to hear that you'd like to get involved.</P>
        <P>
          Registration to receive a SAMHE monitor closed on 31 May 2024 but schools can still benefit from the SAMHE
          teaching resources. See our <Link to="/get-involved">get involved page</Link> for more information.
        </P>
        <P>
          If your school already has a SAMHE monitor, please note that the Web App will remain fully operational until
          at least January 2030.
        </P>
        <P>
          The support that we can offer SAMHE schools will reduce after 31st July 2024, when our current research
          funding ends. But don't worry - almost nothing else will change! We hope to receive additional funding in the
          future to extend and expand SAMHE, but even if we don't, you will be able to keep using your monitor and the
          Web App for many years to come.
        </P>
        <P>
          If your school has a SAMHE monitor, and it is not connected yet, please see our{" "}
          <Link to="/getting-started">getting started</Link> page for help.
        </P>
      </div>
    ),
  },
  {
    uid: "what_hypotheses_scientific_questions_is_samhe_addressing",
    label: "What hypotheses (scientific questions) is SAMHE addressing?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          That's a great question! Our starting point is that because we know indoor air quality can affect our health,
          and children & staff spend a lot of time at school, we should try to learn more about the air quality in our
          UK schools.
        </P>
        <P>
          Our primary hypothesis is that involving pupils and staff in monitoring their air quality might be a
          sustainable way to gather school air quality data at national scale - if that works, we have many more
          detailed hypotheses to test with the data (for example, that the age of a building and the usage of a
          classroom might have an important effect on the air quality within, or that most classrooms can be adequately
          ventilated if staff are trained to manage their classroom ventilation).
        </P>
        <P>
          A secondary hypothesis is that our methods will, at the very least, raise awareness of air quality issues in
          some of the next generation - that cannot be a bad thing, and perhaps it will increase the likelihood of
          satisfying our primary hypothesis!
        </P>
      </div>
    ),
  },
  {
    uid: "do_you_have_any_further_information_about_the_research_questions_samhe_will_address",
    label: "Do you have any further information about the research questions SAMHE will address?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.PARENTS, EFaqAudience.GOVERNORS, EFaqAudience.OTHER],
    content: (
      <div>
        <P>SAMHE's core aims are to:</P>
        <Ol>
          <li>improve understanding of air quality in schools</li>
          <li>
            empower teachers and pupils to make informed decisions about management of their classroom environment,
            including ventilation
          </li>
          <li>support the UK's next generation to think differently about air quality</li>
        </Ol>
        <P>The specific research questions SAMHE will address include:</P>
        <Ul>
          <li>
            What CO<sub>2</sub> levels, as an indication of ventilation, are typical in schools?
          </li>
          <li>How does indoor air quality in schools vary with ventilation?</li>
          <li>Does indoor air quality vary widely within a given school?</li>
          <li>Does indoor air quality vary with the location of a school?</li>
          <li>Can taking part in SAMHE improve people's understanding of air quality?</li>
          <li>Can taking part in SAMHE help schools improve their indoor air quality</li>
        </Ul>
      </div>
    ),
  },
  {
    uid: "what_is_expected_of_a_samhe_school",
    label: "What is expected of a SAMHE school?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS, EFaqAudience.GOVERNORS, EFaqAudience.OTHER],
    content: (
      <div>
        <P>
          SAMHE has been designed to be used flexibly. You're free to use SAMHE however best suits you - you might use
          SAMHE during your lessons, with extra-curricular groups, or as part of extended project work. We know that in
          some schools our monitors will only be used infrequently and that's fine. Providing the monitors are plugged
          in and connected to the WiFi they still give us really useful data.
        </P>
        <P>
          For ideas about how SAMHE might work in your school take a look at our{" "}
          <AExternal href="https://www.youtube.com/watch?v=8fhvbjLUMtg&feature=youtu.be">
            teacher testimonials video
          </AExternal>
          .
        </P>
        <P>
          <b>
            Please note that although the research team's initial funding came to an end in July 2024, the SAMHE Web App
            will remain operational until at least January 2030 so you can continue using SAMHE in your teaching for
            years to come.
          </b>
        </P>
      </div>
    ),
  },
  {
    uid: "how_do_we_put_the_numbers_we_see_on_our_monitor_into_context_what_is_a_big_number_high_reading",
    label: "How do we put the numbers we see on our monitor into context? What is a big number / high reading?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.PUPILS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          For an explanation of what the numbers on your monitor mean take a look at our{" "}
          <Link to="/resources/monitor-leds">guidance on monitor readings</Link>.This page has information on reference
          levels for each of the metrics we measure which will help put the readings into context. For more detail about
          what monitor readings mean in practice, and what you can do if these values concern you, take a look at our{" "}
          <Link to="/resources">Resources</Link>. In particular, you might like to start by reading our{" "}
          <Link to="/resources/air-pollution">About Air Pollution</Link> and{" "}
          <Link to="/resources/ventilation">ventilation guidance</Link> pages.
        </P>
      </div>
    ),
  },
  {
    uid: "where_can_i_learn_more_about_the_samhe_air_quality_monitors",
    label: "Where can I learn more about the SAMHE air quality monitors? ",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          SAMHE is using{" "}
          <AExternal href="https://www.airgradient.com/airgradient-one/">AirGradient One monitors</AExternal> with
          custom firmware developed specially for our project. The monitors measure levels of carbon dioxide,
          particulate matter, total volatile organic compounds, temperature and relative humidity, all of which is
          displayed on the monitor screen in realtime. The concentration of CO<sub>2</sub> detected determines the
          combination of monitor LEDs displayed.
        </P>
        <P>
          All information gathered by the monitors is transmitted to the SAMHE Web App via WiFi connection where it is
          made available to schools to explore in more detail.
        </P>
        <P>
          For more information about the AirGradient One monitor, including full technical specifications, visit the{" "}
          <AExternal href="https://www.airgradient.com/">AirGradient website</AExternal>.
        </P>
      </div>
    ),
  },
  {
    uid: "is_there_a_samhe_presentation_i_can_use_to_introduce_the_project_to_my_school",
    label: "Is there a SAMHE presentation I can use to introduce the project to my school?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.STAFF, EFaqAudience.PARENTS, EFaqAudience.PUPILS, EFaqAudience.GOVERNORS],
    content: (
      <div>
        <P>
          Yes! Based on the recommendations of the teachers we worked with during our 'Pioneers phase' we have developed
          a 15 minute presentation to assist you in introducing the project to interested parties at your school (and
          beyond)! Our template introductory presentation is linked from the <Link to="/resources">Resources</Link>{" "}
          page.
        </P>
      </div>
    ),
  },
  {
    uid: "im_moving_schoolchanging_roles_what_do_i_do_with_my_monitor",
    label: "I'm moving school/changing roles, what do I do with my monitor?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.STAFF],
    content: (
      <div>
        <P>
          <b>If you are leaving your current school</b>, please note that your SAMHE monitor was gifted to your school,
          so it cannot be taken with you. Although as of 1st June 2024 we are not sending out any more monitors, you can
          still get involved with SAMHE at your new school by taking advantage of our Teacher Resource Pack. See our{" "}
          <Link to="/get-involved">get involved</Link> page for more information.
        </P>
        <P>
          <b>Whether you're leaving school or just changing roles</b>, if you are handing over responsibility for the
          school's SAMHE monitor to someone else, please use the following brief checklist:
        </P>
        <div style={{ backgroundColor: "#e8e8e8", padding: "1rem" }}>
          <P>
            <b>Handover checklist:</b>
          </P>
          <P>If you're passing on SAMHE to someone else please:</P>
          <Ul>
            <li>
              <b>Contact us</b> - that way we can remove your contact details from our database. Ideally we'd also add
              details for your replacement (if you know who they are).
            </li>
            <li>
              <b>Give the new person the credentials for the school admin account</b>
            </li>
            <li>
              <b>Encourage them to:</b>
              <Ul>
                <li>
                  Read the <Link to="/get-involved">Get Involved</Link> page for some context on the SAMHE offering and
                  what taking part in SAMHE means{" "}
                </li>
                <li>
                  Read the <Link to="/getting-started">Getting Started page</Link> (in particular step 5) for some ideas
                  about which activities they might like to do first
                </li>
                <li>
                  Look through our{" "}
                  <DownloadLink href="/resources/samhe_assemblies_and_notes_for_schools.zip">
                    SAMHE introductory assembly
                  </DownloadLink>
                </li>
                <li>
                  Consider <Link to="/contact/#enquiries">signing up to our newsletter</Link> (and maybe even{" "}
                  <Link to="/news">look through some back copies</Link>, so that they can see some examples of how
                  schools are using SAMHE)
                </li>
              </Ul>
            </li>
          </Ul>
        </div>
      </div>
    ),
  },
  {
    uid: "ive_just_been_handed_a_monitor_what_do_i_do_with_it",
    label: "I've just been handed a monitor, what do I do with it?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.STAFF],
    content: (
      <div>
        <P>
          <b>If you are wondering where to start with SAMHE, we'd recommend you follow these steps:</b>
        </P>
        <Ul>
          <li>
            <b>
              If possible, confer with the member of staff previously leading on the SAMHE project at your school to
              establish where they got up to with the set up process
            </b>{" "}
            - you may find it helpful to refer to the <Link to="/getting-started">Getting Started page</Link> when you
            do so
          </li>
          <li>
            <b>Confirm the credentials for accessing the school's admin account</b> - these were sent to both the member
            of staff who initially registered your school on SAMHE and a non-personal inbox at your school (typically
            reception/admin team)
          </li>
          <li>
            <b>For context, read through some key pages on our website</b> - we'd recommend the{" "}
            <Link to="/how-samhe-works">How SAMHE works</Link> to start with
          </li>
          <li>
            <b>Create yourself a teacher account and begin browsing the Web App</b> - refer to our FAQ on '
            <Link to="/support#how_can_i_set_up_staff_accounts">How can I set up staff accounts?</Link>' for
            instructions on how to do this. Step 5 of the <Link to="/getting-started">Getting Started page</Link>{" "}
            contains some ideas about what you might like to do first.
          </li>
          <li>
            <b>Over to you!</b> SAMHE is designed to be used flexibly and can be used in a variety of different contexts
            - see our FAQ on{" "}
            <Link to="/faq?audience=staff#what_is_expected_of_a_samhe_school">What is expected of a SAMHE school</Link>{" "}
            for more info.
          </li>
        </Ul>
      </div>
    ),
  },
  {
    uid: "how_do_co2_levels_affect_cognition_and_concentration",
    label: <>How do CO₂ levels affect cognition and concentration?</>,
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.PUPILS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          For a summary of what we know about the links between CO<sub>2</sub> levels, cognition and concentration head
          to our <Link to="/resources/air-pollution-and-health">Air Pollution and Health page</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "how_does_outdoor_air_pollution_affect_indoor_air_quality",
    label: "How does outdoor air pollution affect indoor air quality?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          For information about how outdoor air pollution can affect indoor air quality see our{" "}
          <Link to="/resources/air-pollution">About Air Pollution page</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "how_are_air_quality_and_covid_19_transmission_linked",
    label: "How are air quality and COVID-19 transmission linked?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          For information on the relationship between air quality and transmission of illnesses such as COVID-19 please
          see our <Link to="/resources/air-pollution-and-health">Air Pollution and Health page</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "what_do_the_samhe_team_think_of_corsi_rosenthal_boxes",
    label: "What do the SAMHE team think of Corsi-Rosenthal boxes?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          Corsi-Rosenthal boxes are a low-cost but effective air filter which you could build for your school.
          Instructions have been provided by{" "}
          <AExternal href="https://drive.google.com/drive/folders/1yN0ZV_1tGMnnIgq7Aqka8yG8gbAlAPUt">
            Clean Air Classrooms
          </AExternal>
          . If you wanted to, you could see if there are any differences in monitor readings before and after
          installation.
        </P>
      </div>
    ),
  },
  {
    uid: "what_if_something_goes_wrong",
    label: "What if something goes wrong?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.PARENTS],
    content: (
      <div>
        <P>
          If your child is harmed by taking part in this research project, there are no special compensation
          arrangements. If your child is harmed due to someone's negligence, then you may have grounds for a legal
          action. Regardless of this, if you wish to complain, or have any concerns about any aspect of the way you have
          been treated during the course of this study then you should immediately inform Dr Henry Burridge and the
          project team using <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. If you are still not satisfied
          with the response, you may contact the Imperial College Research Governance Integrity Team (
          <a href="mailto:rgitcoordinator@imperial.ac.uk">rgitcoordinator@imperial.ac.uk</a>).
        </P>
      </div>
    ),
  },
  {
    uid: "what_will_happen_to_the_results_of_the_research_study",
    label: "What will happen to the results of the research study?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.PARENTS],
    content: (
      <div>
        <P>
          Results from this study are being analysed and summarised in publicly available academic papers and technical
          reports. Those already published are available on our <Link to="/resources/outputs">Outputs page</Link>, along
          with a short, simple summary. Data supporting the Web App will be stored in a data repository and be made
          available to other researchers. No personal data will be included in data repositories. Your child will not be
          identified in any project outputs and it will not be possible for anyone to work out that they took part in
          the study. The only exception to this is if you specifically provide consent for their name to be used in our
          newsletter in conjunction with an experiment or activities they have taken part in. To provide consent for the
          use of their name in this way you will be asked to complete a form designed to gather consent for this
          specific purpose.
        </P>
      </div>
    ),
  },
  {
    uid: "where_does_samhes_funding_come_from",
    label: "Where does SAMHE's funding come from?",
    group: EFaqGroup.PROJECT_BACKGROUND,
    audience: [EFaqAudience.PARENTS],
    content: (
      <div>
        <P>
          Funding for the SAMHE project was provided by the Department for Education and Engineering and Physical
          Sciences Research Council (EPSRC). SAMHE grew out of the{" "}
          <AExternal href="https://co-trace.uk/">CO-TRACE project</AExternal> (2021-22) which assessed the risk of
          airborne COVID-19 transmission in schools and evaluated the effectiveness of mitigation measures. Funding to
          continue the SAMHE initiative has been provided by Hertfordshire County Council and through the Royal
          Society's Partnership Grants scheme.
        </P>
      </div>
    ),
  },

  /**
   * What does the monitor measure
   */
  {
    uid: "what_do_the_monitors_measure",
    label: "What do the monitors measure?",
    group: EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          The SAMHE monitors measure particulate matter (PM<Su>2.5</Su>), total volatile organic compounds (TVOCs) and
          carbon dioxide (CO₂), along with temperature and relative humidity. For more information about what we're
          measuring, why we're measuring it and how to make sense of your monitor's readings why not check out our{" "}
          <Link to="/resources/monitor-leds">guidance on interpreting monitor readings</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "is_the_monitor_data_real_time",
    label: "Is the monitor data real time?",
    group: EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          Data on the SAMHE monitor screen is in real time and is updated every minute. There is a slight delay of up to
          2 minutes between what you see on the monitor and what you will see in the Web App.
        </P>
      </div>
    ),
  },
  {
    uid: "would_we_be_able_to_see_the_impacts_of_pollen_and_possible_differences_resulting_from_differing_proximity_to_trees",
    label:
      "Would we be able to see the impacts of pollen and possible differences resulting from differing proximity to trees?",
    group: EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          It is not impossible but it is unlikely. Trees do give off lots of particular matter (PMs); for example, tree
          pollen is a PM but the particles tend to be relatively big. Most types of pollen would be expected to show up
          more in measurement of PM<sub>10</sub> (we said 'relatively big' because PM<sub>10</sub> is particles up to 10
          microns in size, that is 1/100<sup>th</sup> of the millimetre on your ruler!). The SAMHE sensors measure PM
          <sub>2.5</sub>.
        </P>
      </div>
    ),
  },
  {
    uid: "will_the_monitor_show_different_readings_in_relation_to_different_kinds_of_classroom_activities_for_example_would_the_monitor_show_different_levels_of_pollutants_when_pupils_are_doing_written_work_compared_with_when_they_are_carrying_out_a_chemistry_experiment",
    label:
      "Will the monitor show different readings in relation to different kinds of classroom activities? For example, would the monitor show different levels of pollutants when pupils are doing written work compared with when they are carrying out a chemistry experiment?",
    group: EFaqGroup.WHAT_DOES_THE_MONITOR_MEASURE,
    audience: [
      EFaqAudience.STAFF,
      EFaqAudience.PARENTS,
      EFaqAudience.PUPILS,
      EFaqAudience.GOVERNORS,
      EFaqAudience.OTHER,
    ],
    content: (
      <div>
        <P>
          This is certainly something you could explore. We would expect that CO₂ levels and PM levels would go up as
          pupils move around more (more CO₂ due to heavier breathing, and more PM as dust from surfaces gets back into
          the air).
        </P>
      </div>
    ),
  },

  /**
   * Access to data
   */
  {
    uid: "who_sees_the_data_from_our_monitor",
    label: "Who sees the data from our monitor?",
    group: EFaqGroup.ACCESS_TO_DATA,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          Your school can see the data from your monitor, and so can researchers on the SAMHE project team. Other
          researchers will also be able to access your monitor's data subject to authorisation by the project team.
        </P>
      </div>
    ),
  },
  {
    uid: "will_other_schools_be_able_to_see_data_from_our_monitor",
    label: "Will other schools be able to see data from our monitor?",
    group: EFaqGroup.ACCESS_TO_DATA,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          Other schools cannot see your data. We sometimes share average data from groups of schools and provide it to
          you as an average, but you'll never be able to identify which school the data came from.
        </P>
      </div>
    ),
  },

  /**
   * Accessing the Web App
   */
  {
    uid: "can_i_use_my_phone_to_access_the_monitor_readings",
    label: "Can I use my phone to access the monitor readings?",
    group: EFaqGroup.ACCESSING_THE_WEB_APP,
    audience: [EFaqAudience.STAFF, EFaqAudience.PARENTS, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          Yes, you can access monitor readings via the Web App using any web-enabled device, as long as you have your
          username and password. However, it has been designed to look best on either a tablet or computer. No matter
          what kind of device you are using you can find the SAMHE Web App by heading to{" "}
          <Link to="/app">https://samhe.org.uk/app</Link>. The SAMHE app is a 'Web App' as opposed to a 'native' app
          which means it operates within a browser, rather than being something you need to download from an app store
        </P>
      </div>
    ),
  },
  {
    uid: "can_students_explore_their_schools_data_without_supervision",
    label: "Can students explore their school's data without supervision?",
    group: EFaqGroup.ACCESSING_THE_WEB_APP,
    audience: [EFaqAudience.STAFF, EFaqAudience.PARENTS, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>Yes, as long as they have their username and password.</P>
      </div>
    ),
  },
  {
    uid: "can_students_access_samhe_at_home",
    label: "Can students access SAMHE at home?",
    group: EFaqGroup.ACCESSING_THE_WEB_APP,
    audience: [EFaqAudience.STAFF, EFaqAudience.PARENTS, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>Yes, if they wish to and they have their username and password</P>
      </div>
    ),
  },
  {
    uid: "will_the_web_app_be_available_in_different_languages",
    label: "Will the Web App be available in different languages?",
    group: EFaqGroup.ACCESSING_THE_WEB_APP,
    audience: [EFaqAudience.STAFF, EFaqAudience.PARENTS, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>Unfortunately not, but if someone gives us more funding in the future, it is something we'd consider.</P>
      </div>
    ),
  },

  /**
   * Partnerships
   */
  {
    uid: "could_our_organisation_partner_with_samhe",
    label: "Could our organisation partner with SAMHE?",
    group: EFaqGroup.PARTNERSHIPS,
    audience: [EFaqAudience.OTHER],
    content: (
      <div>
        <P>
          Thank you so much for your interest in partnering with SAMHE! We are actively seeking additional funding to
          support SAMHE in the future. If you have suggestions of who we should approach for funding, or want to work in
          collaboration to do this, <Link to="/contact">please let us know</Link>!
        </P>
      </div>
    ),
  },
  {
    uid: "im_doing_related_research_could_we_integrate_our_datasets",
    label: "I'm doing related research - could we integrate our datasets?",
    group: EFaqGroup.PARTNERSHIPS,
    audience: [EFaqAudience.OTHER],
    content: (
      <div>
        <P>
          If you are a researcher who is doing related research and want to collaborate, please get in touch with the{" "}
          <Link to="/about/team">PI Henry Burridge</Link>.
        </P>
      </div>
    ),
  },

  /**
   * Interacting with the monitor
   */
  {
    uid: "where_should_we_put_our_samhe_monitor",
    label: "Where should we put our SAMHE Monitor?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          For advice on where to put your SAMHE monitor please refer to our guidance on{" "}
          <Link to="/resources/monitor-placement-guide">monitor placement</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "how_often_can_we_move_the_monitor_every_few_days",
    label: "How often can we move the monitor? Every few days?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          We encourage you to move your monitor(s) around to collect data about the different rooms in your school and
          we would recommend that you leave the monitor in each place for a minimum of a week, to allow it to collect
          data about the conditions of the room that it is in. When you move your monitor it would be really useful to
          the project team if you can tell us using the Web App when you have moved it and where you moved it to.
        </P>
      </div>
    ),
  },
  {
    uid: "we_re_a_samhe_school_how_often_should_we_input_data",
    label: "We're a SAMHE school. How often should we input data?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          This is up to you and your school's timetable and other commitments. The SAMHE Web App is designed to be
          flexible and it is open to you to decide how you'd like to use it, you may have a regular group (e.g. a
          science club) who can do activities every week or so, or you might have one event a term where you interact
          with it.
        </P>
        <P>
          Measurements from SAMHE monitors, once they have been plugged in and connected to the internet, will be
          transmitted automatically to our secure database which supports the SAMHE Web App. This means that even just
          plugging in your monitor gives us useful information, so if you're too busy to interact with it for parts of
          the year that's not a problem at all.
        </P>
      </div>
    ),
  },
  {
    uid: "my_childs_school_has_a_samhe_monitor_can_i_see_the_air_quality_data_from_it",
    label: "My child's school has a SAMHE monitor - can I see the air quality data from it?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.PARENTS],
    content: (
      <div>
        <P>
          If you'd like to view data from your child's school's SAMHE monitor please get in touch with a relevant
          teacher at their school.
        </P>
      </div>
    ),
  },
  {
    uid: "do_we_need_to_keep_our_monitor_switched_on_overnight_how_about_outside_of_term_time",
    label: "Do we need to keep our monitor switched on overnight? How about outside of term time?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS],
    content: (
      <div>
        <P>
          If you can leave your monitor switched on overnight, and during other unoccupied hours such as over weekends
          and during school holidays, that will provide us (and you) with really useful data about baseline air quality
          conditions.
        </P>
        <P>
          If you are concerned about energy use, please be aware that SAMHE monitors require around 1.5 kWh energy per
          year.
        </P>
      </div>
    ),
  },
  {
    uid: "how_much_electricity_does_the_monitor_use",
    label: "How much electricity does the monitor use?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          SAMHE monitors require around 15 kWh energy per year - that's about £3.65 per year, using the UK price cap
          from Jun/24.
        </P>
      </div>
    ),
  },
  {
    uid: "should_the_monitor_ever_be_switched_off",
    label: "Should the monitor ever be switched off?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS, EFaqAudience.PUPILS, EFaqAudience.PARENTS],
    content: (
      <div>
        <P>
          Ideally not! It's really important for our researchers to know what air quality is like when classrooms are
          not occupied (and your pupils might find it interesting too!). So{" "}
          <b>
            please leave your SAMHE monitors switched on at all times, including outside of school hours and over school
            holidays.
          </b>
        </P>
      </div>
    ),
  },
  {
    uid: "do_i_need_to_come_in_over_the_school_holidays_to_keep_an_eye_on_monitor_readings",
    label: "Do I need to come in over the school holidays to keep an eye on monitor readings?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF],
    content: (
      <div>
        <P>
          Providing the monitor is plugged in and connected to the WiFi it will automatically transmit readings to our
          databases. This data will be made available to you within the Web App. There is no need to supervise this
          process.
        </P>
        <P>
          Using the date selection features provided on data views within the Web App you can review historical data, so
          if you are interested in seeing how monitor readings differ during unoccupied periods such as weekends or
          school holidays, this information is accessible at any time.
        </P>
      </div>
    ),
  },
  {
    uid: "when_moving_the_monitor_between_classrooms_does_it_need_to_stay_at_the_same_height_in_each",
    label: "When moving the monitor between classrooms, does it need to stay at the same height in each?",
    group: EFaqGroup.INTERACTING_WITH_THE_MONITOR,
    audience: [EFaqAudience.STAFF],
    content: (
      <div>
        <P>
          Please refer to our <Link to="/resources/monitor-placement-guide">guidance on monitor placement</Link> for
          advice on where to put your monitor when moving it between classrooms.
        </P>
      </div>
    ),
  },

  /**
   * Info for pupils
   */
  {
    uid: "does_samhe_count_towards_ucas_points",
    label: "Does SAMHE count towards UCAS points?",
    group: EFaqGroup.INFO_FOR_PUPILS,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>Taking part in SAMHE does not count towards UCAS points.</P>
        <P>
          However, if you are applying to university, for an apprenticeship, or for a job, you can use your
          participation in SAMHE to enhance your application.
        </P>
      </div>
    ),
  },
  {
    uid: "will_samhe_help_me_with_my_ucas_application",
    label: "Will SAMHE help me with my UCAS application?",
    group: EFaqGroup.INFO_FOR_PUPILS,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          Yes - SAMHE is an opportunity to participate as a citizen scientist. This means that you can write in your
          personal statement about the skills you have used in SAMHE to contribute to real life science research on air
          quality, such as: problem solving, communication, discussion, investigation, and data analysis.
        </P>
      </div>
    ),
  },
  {
    uid: "i_feel_anxious_or_worried_about_the_readings_on_the_samhe_monitor_what_should_i_do",
    label: "I feel anxious or worried about the readings on the SAMHE monitor, what should I do?",
    group: EFaqGroup.INFO_FOR_PUPILS,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          The SAMHE monitor measures carbon dioxide (CO<sub>2</sub>), particulate matter, temperature, humidity, and
          total volatile organic compounds (TVOCs). You can ask your teachers about these if you are interested in
          learning more. If you are worried about your monitor readings, you might want to complete the{" "}
          <Link to="/app/activities/4">data detectives activity</Link> to understand more about where these readings
          might be coming from, and have a conversation with your teacher about these.
        </P>
        <P>
          If you are experiencing eco-anxiety, that is anxiety about effects of climate change,{" "}
          <AExternal href="https://holdthis.space/">Hold This Space</AExternal> is a great tool for support and
          understanding, and they also have a{" "}
          <AExternal href="https://holdthis.space/resources-and-further-reading/">list of resources</AExternal> that you
          may find useful.
        </P>
      </div>
    ),
  },
  {
    uid: "can_i_use_samhe_as_part_of_my_extended_project_qualification_epq",
    label: "Can I use SAMHE as part of my Extended Project Qualification (EPQ)?",
    group: EFaqGroup.INFO_FOR_PUPILS,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          Yes you can! SAMHE is a brilliant tool to use in your EPQ and helps you meet all four assessment objectives
          (AOs).
        </P>
        <P>
          You can use the SAMHE Web App activities to gain an initial understanding of indoor air quality, learn new
          skills in data handling (AO3) and identify the areas you would like to study further (AO1). The Web App also
          enables you to download data from the SAMHE monitor for further analysis (AO2). The SAMHE website hosts
          information about air pollution, air quality monitoring and health and links out to additional resources to
          support your project (AO2).
        </P>
        <P>
          You could use the Web App quizzes to check your knowledge and understanding at the start and end of your
          project, as part of evaluating your learning (AO4). In addition to your written project report, you could ask
          to present your findings to school management, to inform the school's air quality/ventilation strategy, and
          also share your conclusions beyond the school community through the 'Write to your local MP' Web App activity
          (AO4).
        </P>
        <P>Some examples of EPQ projects could be:</P>
        <Ul>
          <li>An investigation comparing classroom air quality during Winter and Spring</li>
          <li>Understanding ventilation via investigation of carbon dioxide levels in classrooms</li>
          <li>
            Comparing levels of Particulate Matter and TVOCs in Art and Design classrooms to those of other classrooms
          </li>
          <li>Investigating temperature and humidity: applying simple thermal comfort metrics/models to classrooms</li>
          <li>Methods of improving indoor air quality in classrooms </li>
        </Ul>
        <P>
          If you do use SAMHE as part of your EPQ, please mention SAMHE in your acknowledgements, and ask your teacher
          to let us know!
        </P>
      </div>
    ),
  },
  {
    uid: "help_im_struggling_with_something_thats_nothing_to_do_with_samhe_and_i_need_support",
    label: "Help, I'm struggling with something that's nothing to do with SAMHE and I need support",
    group: EFaqGroup.INFO_FOR_PUPILS,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          If you are struggling with your mental health, or are in a difficult situation, it is important to speak to a
          teacher, parent, carer, or other trusted adult for support.
        </P>
        <P>
          If you do not feel that there is a trusted adult to speak to, please consider contacting one of the services
          below for advice and support:
        </P>
        <Ul>
          <li>
            To talk to someone visit <AExternal href="https://www.childline.org.uk/">Childline</AExternal> or call 0800
            1111
          </li>
          <li>
            For 24/7 support with mental health over text messaging, visit{" "}
            <AExternal href="https://giveusashout.org/">SHOUT</AExternal> or text 'shout' to 85258
          </li>
          <li>
            For suicide prevention and support, visit <AExternal href="https://www.papyrus-uk.org/">Papyrus</AExternal>{" "}
            or ring the hopeline on 0800 068 41 41
          </li>
          <li>
            For drugs advice and support visit <AExternal href="https://www.talktofrank.com/">FRANK</AExternal> or call
            0300 1236600
          </li>
        </Ul>
        <P>
          In case of an emergency or suicidal feelings, call Samaritans immediately on 116 123 or the National Suicide
          Prevention Helpline UK on 0800 689 5652 (open 24/7)
        </P>
      </div>
    ),
  },

  /**
   * Info for Teachers
   */
  {
    uid: "i_know_a_pupil_who_is_feeling_anxious_or_worried_about_the_readings_on_the_samhe_monitor_how_can_i_help_them",
    label:
      "I know a pupil who is  feeling anxious or worried about the readings on the SAMHE monitor, how can I help them?",
    group: EFaqGroup.INFO_FOR_TEACHERS,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS, EFaqAudience.PARENTS, EFaqAudience.OTHER],
    content: (
      <div>
        <P>
          The SAMHE monitor measures carbon dioxide (CO<sub>2</sub>), particulate matter, temperature, humidity, and
          total volatile organic compounds (TVOCs). You can read more about these in our{" "}
          <Link to="/resources/monitor-leds">guidance on monitor readings</Link>. If there are particular monitor
          readings which are causing them concern, you may wish to encourage your students to complete the{" "}
          <Link to="/app/activities/4">data detectives activity</Link> to find out more about them.
        </P>
        <P>
          If your students are experiencing eco-anxiety, that is anxiety about effects of climate change,{" "}
          <AExternal href="https://holdthis.space/">Hold This Space</AExternal> is a great tool for support and
          understanding, and they also have a{" "}
          <AExternal href="https://holdthis.space/resources-and-further-reading/">list of resources</AExternal> that you
          and your students may find useful.
        </P>
      </div>
    ),
  },
  {
    uid: "a_parent_has_requested_data_from_the_samhe_monitor_what_should_i_say",
    label: "A parent has requested data from the SAMHE monitor, what should I say?",
    group: EFaqGroup.INFO_FOR_TEACHERS,
    audience: [EFaqAudience.STAFF],
    content: (
      <div>
        <P>
          It is up to you. You have permission to share your data with whoever you like (or not, if you prefer). You can
          either let them know that the data are not publicly available and not provide it to them, or provide it to
          them. If you choose this second option, you could ask them which data they are interested in seeing and over
          what time period, navigate in the Web App to that graph, and then download the data as a csv file. If you do
          this, you may want to provide some guidance about onward sharing of that data (to e.g. other parents or other
          organisations).
        </P>
      </div>
    ),
  },

  /**
   * Other
   */
  {
    uid: "is_there_a_way_of_asking_the_project_team_questions_about_my_schools_data",
    label: "Is there a way of asking the project team questions about my school's data?",
    group: EFaqGroup.OTHER,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          One of the key aims of SAMHE is to allow you to improve your understanding of air quality, so we have designed
          the Web App to enable you to investigate and understand your data, at least as well as we could - you know
          your school better than anyone else! For example within the Web App, the{" "}
          <Link to="/app/activities/4">Data Detectives activity</Link> will help you find patterns in your data and
          identify possible causes. Our <Link to="/resources">Resources Page</Link> is also a great starting point if
          you've got questions about how to interpret your monitor's data.
        </P>
      </div>
    ),
  },
  {
    uid: "if_someone_was_to_scream_a_lot_near_the_monitor_what_would_happen",
    label: "If someone was to scream a lot near the monitor what would happen?",
    group: EFaqGroup.OTHER,
    audience: [EFaqAudience.STAFF, EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          You'd probably see an increase in CO₂ levels and perhaps also an increase in relative humidity as little bits
          of spit come out of your mouth!
        </P>
      </div>
    ),
  },
  {
    uid: "can_my_samhe_monitor_help_me_tackle_rising_energy_costs",
    label: "Can my SAMHE monitor help me tackle rising energy costs?",
    group: EFaqGroup.OTHER,
    audience: [EFaqAudience.STAFF, EFaqAudience.GOVERNORS],
    content: (
      <div>
        <P>
          Your SAMHE monitor measures temperature and CO₂, and these two pieces of information together might be able to
          help you decide when windows need to be opened to ventilate, and when they can be kept shut. During the
          COVID-19 pandemic, many schools were encouraged to keep their windows open to ensure there was plenty of
          ventilation. In winter this can be problematic because then lots of energy is wasted trying to keep the
          classroom air warm enough. Keeping an eye on your CO₂ readings and opening windows when your monitor turns
          orange (800ppm) should allow you to have adequate ventilation but not require windows to be open constantly.
        </P>
      </div>
    ),
  },
  {
    uid: "im_breathing_out_co2_am_i_contributing_to_climate_change",
    label: "I'm breathing out CO₂, am I contributing to climate change?",
    group: EFaqGroup.OTHER,
    audience: [EFaqAudience.PUPILS],
    content: (
      <div>
        <P>
          The short answer is no. Read on for the longer answer! Every time you breathe out, you release carbon dioxide.
          But this carbon dioxide comes from food that was produced by photosynthesis, as everything we eat is
          ultimately based on plants. Sometimes this is obvious, for example, if you eat a carrot or some rice, but this
          is also true for things such as meat, for example, cows are fed on grass or soya. When we eat food, our body
          converts this into energy, water and carbon dioxide. So the plants we eat (or the animals we eat have eaten)
          have absorbed carbon dioxide from the atmosphere and our bodies then convert this back into carbon dioxide, so
          there is no extra CO₂ being added.
        </P>
        <P>
          By contrast, when fossil fuels like coal or gas are burned, this releases CO₂ into the atmosphere which has
          been locked up for millions of years, contributing to rising CO₂ levels and climate change.
        </P>
      </div>
    ),
  },
];

export default faqEntries;
