import React from "react";
import { Link } from "react-router-dom";
import MonitorDeploymentForm from "../components/Forms/MonitorDeploymentForm";
import { ConstrainedPageStyle, P, PageTitle, Section } from "../styles/page";

const MonitorLinkingPage: React.FC<{ serialNumber: string }> = ({ serialNumber }) => {
  return (
    <ConstrainedPageStyle data-testid="MonitorLinkingPage">
      <PageTitle>Link monitor to school</PageTitle>
      <Section>
        <P>This page is for deployment users to link a monitor to a validated school.</P>
        <P>
          Schools must first be validated on the <Link to="/deployment/school-validation">School Validation Page</Link>
        </P>
        <P>
          Once the monitor is linked to the school you should return to the{" "}
          <Link to="/deployment/school-validation">School Validation Page</Link> to mark it as "deployed" once it has
          been sent in the post
        </P>
      </Section>
      <Section>
        <MonitorDeploymentForm serialNumber={serialNumber} />
      </Section>
    </ConstrainedPageStyle>
  );
};

export default MonitorLinkingPage;
