import styled from "styled-components";
import { Section } from "./page";
import { CTALinkButtonStyle } from "./links";

export const TechSupportPageWrapper = styled(Section)`
  display: flex;
  gap: 2rem;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: block;
  }

  div.left {
    display: block;
    width: 17rem;
    @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      padding: 1rem;
      width: 100%;
    }
  }
  div.right {
    flex: 1;
  }
`;

export const SideDiv = styled.div`
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.main};

  ul {
    margin-left: 1rem;
    padding-left: 1rem;
  }
`;

export const GetStartDiv = styled(SideDiv)`
  text-align: center;
  border-color: #4c5ebc;
  img.todo-list {
    width: 50%;
  }

  & > ${CTALinkButtonStyle} {
    padding: 0.5rem 1.5rem;
    background-color: #4c5ebc;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

export const QuickLinksDiv = styled(SideDiv)`
  background-color: #0097a7;
  border: none;
  ul {
    margin-left: 1rem;
  }
  &,
  p,
  a {
    color: white;
  }
`;
