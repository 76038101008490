import { postDataFetch, IApiResponse, getDataFetch, throwErrors, EReturnTypes } from "./apiHelpers";
import { SERVER_URL } from "../config";
import { ISchoolAdminFormSubmission } from "../lib/formSubmissions/ISchoolAdminSubmission";

export interface IApiGetSchoolAdminDataReturn extends IApiResponse {
  data: {
    handle: string;
    secretWordTeacher: string;
    secretWordPupil: string;
  };
}

export const postSchoolAdminForm = (accessToken: string) => async (data: ISchoolAdminFormSubmission) => {
  const url = `${SERVER_URL}/Schools/Secrets`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<ISchoolAdminFormSubmission, IApiResponse>(url, data, headers, { returnType: EReturnTypes.HTML });
};

export const apiActivateSchool = (accessToken: string) => async () => {
  // TODO: Setup this endpoint on the api
  const url = `${SERVER_URL}/Schools/Activate`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}, IApiResponse>(url, {}, headers, { returnType: EReturnTypes.HTML });
};

export const apiGetSchoolAdminData = async (accessToken: string) => {
  const url = `${SERVER_URL}/Schools/Secrets`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiGetSchoolAdminDataReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => {
      // NOTE: Quick fix for incorrect handle provided by API
      return {
        ...response.json.data,
        handle: undefined,
        schoolHandle: response.json.data.handle,
      };
    });
};

export const apiSchoolAdmin = {
  get: apiGetSchoolAdminData,
  post: postSchoolAdminForm,
  activate: apiActivateSchool,
};
