import React from "react";
import styled from "styled-components";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";

export const GaugePlotOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export interface IGaugeChartOptions {
  reloadData: () => void;
  loading: boolean;
  setLiveUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  liveUpdate: boolean;
}

export const GaugeChartOptions: React.FC<IGaugeChartOptions> = ({ reloadData, loading, setLiveUpdate, liveUpdate }) => {
  return (
    <GaugePlotOptionsWrap>
      <CardInfoSectionWrapper open maxHeight="55rem" style={{ width: "100%" }}>
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap>
            <ExpandedCardSection>
              <H2>Data Refreshing</H2>
              <p>Select when your data should reload</p>
              <FlexList left horiz>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle onClick={() => setLiveUpdate((prev) => !prev)} on={liveUpdate ? "true" : undefined}>
                    Live Update
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
    </GaugePlotOptionsWrap>
  );
};
