export enum EMessageType {
  INFO,
  WARN,
  ERROR,
}

export interface INotificationState {
  message?: string;
  open: boolean;
  messageType?: EMessageType;
}
