/* istanbul ignore file */
import { getDataFetch, IApiResponse, throwErrors } from "./apiHelpers";

import { SERVER_URL } from "../config";
import { apiGetUserState } from "./apiUser";
import { MonitorId } from "../lib/Monitor/IMonitor";
import { IApiMonitorDataReturnElement } from "./apiMonitorData";

export interface IPingResponse {
  ok?: boolean;
}

/**
 * Ping the server to test connection
 *
 * @returns
 */
export const apiPing = async (): Promise<IPingResponse> => {
  const url = `${SERVER_URL}/query/ping`;
  return getDataFetch<IPingResponse>(url, "html")
    .then(throwErrors)
    .then(({ response }) => response.json);
};
/**
 * Ping the server to test logged in
 *
 * @returns
 */
export const apiPingAuth = async (accessToken: string): Promise<IPingResponse> => {
  return apiGetUserState(accessToken)
    .then(({ Id }) => (Id ? { ok: true } : { ok: false }))
    .catch((e) => {
      console.info("Failed to authenticate!");
      throw e;
    });
};

export interface IApiMonitorHealthCheckReturn extends IApiResponse {
  success: boolean;
  data: IApiMonitorDataReturnElement[];
  error?: string;
  isActivated?: boolean;
  isLinkedToSchool?: boolean;
}

export const apiMonitorHealthCheck = async (monitorId: MonitorId, accessToken: string) => {
  const url = `${SERVER_URL}/MonitorHealthCheck/${monitorId}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiMonitorHealthCheckReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => ({
      ...response.json,
      ok: response.json.success,
    }));
};
