import React from "react";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";
import { TechContactPrivacyPolicy } from "../components/Documents/TechContactPrivacyPolicy";

const TechContactPrivacyPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE Tech Support Privacy Policy</PageTitle>
      <TechContactPrivacyPolicy />
    </ConstrainedPageStyle>
  );
};

export default TechContactPrivacyPage;
