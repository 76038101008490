import React from "react";
import { VentilationGuidance } from "../components/Documents/VentilationGuidance";
import { ConstrainedPageStyle, PageTitle, TableOfContents, TableOfContentsWrapper, Ul, SectionBackground } from "../styles/page";
import { HeroBackground, BackgroundHeroStyle } from "../styles/hero";
import { Link } from "react-router-dom";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";

const VentilationGuidancePage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <SectionBackground>
        <PageTitle>Ventilation Guidance</PageTitle>
        <BackgroundHeroStyle>
          <HeroBackground src="/images/resources/ventilation/classroom-with-windows.jpg" />
        </BackgroundHeroStyle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#what-is-ventilation-and-why-does-it-matter">What is ventilation?</Link>
              </li>
              <li>
                <Link to="#summary-of-ventilation-recommendations-for-schools">
                  Ventilation recommendations summary
                </Link>
              </li>
              <li>
                <Link to="#types-of-ventilation">Types of ventilation</Link>
              </li>
              <li>
                <Link to="#recommendations-to-improve-ventilation">Recommendations to improve ventilation</Link>
              </li>
              <li>
                <Link to="#using-co2-monitors-to-indicate-ventilation-levels">
                  CO<sub>2</sub> monitors and ventilation levels
                </Link>
              </li>
              <li>
                <Link to="#ventilation-and-the-covid-19-pandemic">Ventilation and the COVID-19 pandemic</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <VentilationGuidance />
          <ScrollTopButton />
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default VentilationGuidancePage;
