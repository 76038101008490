import { EDataMetricsStrId } from "../../../lib/DataViews/EDataMetrics";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { ScatterSeriesOption } from "echarts";

export type Hour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export const processDataForScatterPlot = (
  dataX: IMonitorDataItem<number>[],
  dataY: IMonitorDataItem<number>[],
  xField: EDataMetricsStrId,
  yField: EDataMetricsStrId
): [ScatterSeriesOption[], string[]] => {
  const mergedData: {
    hour: Hour;
    hourRange: string;
    xVal: number;
    yVal: number;
  }[] = Object.values(
    [...dataX, ...dataY].reduce((acc, obj) => {
      const { dateStamp, field, value } = obj;
      const hour = new Date(dateStamp).getHours();
      const hourRange =
        hour >= 7 && hour < 18 ? `${hour}:00-${hour}:59` : hour === 18 ? `${hour}:00-${hour + 1}:00` : "Overnight";
      const key = dateStamp.toISOString();
      const existingData = acc[key] || {};
      const newData = {
        ...existingData,
        hour,
        hourRange,
        xVal: field === xField ? value : existingData.xVal,
        yVal: field === yField ? value : existingData.yVal,
      };
      acc[key] = newData;
      return acc;
    }, {})
  );

  const plotableData = mergedData
    .filter((item) => item.xVal !== undefined && item.yVal !== undefined)
    .map((item) => [item.hourRange, item.xVal?.toFixed(2), item.yVal?.toFixed(2)] as [string, string, string]);

  const hours: string[] = [...Array.from(new Set(plotableData.map((item) => item[0])))];
  const hourrangelabels = [
    "7:00-7:59",
    "8:00-8:59",
    "9:00-9:59",
    "10:00-10:59",
    "11:00-11:59",
    "12:00-12:59",
    "13:00-13:59",
    "14:00-14:59",
    "15:00-15:59",
    "16:00-16:59",
    "17:00-17:59",
    "18:00-19:00",
    "Overnight",
  ];
  // Sort hour ranges by hour labels
  hours.sort((a, b) => {
    const indexA = hourrangelabels.indexOf(a);
    const indexB = hourrangelabels.indexOf(b);

    return indexA - indexB;
  });

  const groupedArrays = plotableData.reduce((groups, array) => {
    const groupKey = array[0];
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(array);
    array.shift();
    return groups;
  }, []);

  //Create different series with their respective names and data for each hour range
  const series: ScatterSeriesOption[] = hours.map((item) => ({
    name: item,
    type: "scatter",
    symbolSize: 10,
    itemStyle: item === "Overnight" ? { borderColor: "grey", background: null } : {},
    data: groupedArrays[item],
  }));
  return [series, hours];
};

const colorMap = {
  // Colours generated with https://colordesigner.io/gradient-generator
  "7:00-7:59": "#98d82a",
  "8:00-8:59": "#b4c300",
  "9:00-9:59": "#cbad00",
  "10:00-10:59": "#dd9400",
  "11:00-11:59": "#ea7800",
  "12:00-12:59": "#f25a17",
  "13:00-13:59": "#f33534",
  "14:00-14:59": "#ee004d",
  "15:00-15:59": "#e10064",
  "16:00-16:59": "#cb007c",
  "17:00-17:59": "#ac0091",
  "18:00-19:00": "#8200a3",
  Overnight: "#ddd",
};
export const getColors = (hours: string[]): string[] => hours.map((hour) => colorMap[hour]);

export const usePreprocessScatterData = (
  dataX: IMonitorDataItem<number>[],
  dataY: IMonitorDataItem<number>[],
  xField: EDataMetricsStrId,
  yField: EDataMetricsStrId
): [ScatterSeriesOption[], string[]] => processDataForScatterPlot(dataX, dataY, xField, yField);
