import React from "react";
import styled from "styled-components";
import { ActivitiesDashboard } from "../../components/ActivitiesDashboard";
import { AppPageStyle } from "../../styles/app";

const ActivitiesBanner = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  padding-top: ${({ theme }) => theme.shape.defaultPaddingWide};
  padding-left: ${({ theme }) => theme.shape.defaultPaddingLarge};
  padding-right: ${({ theme }) => theme.shape.defaultPaddingLarge};
  flex-direction: row;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
  }
  background: ${({ theme }) => theme.colors.appBackground};
`;

const ActivitiesBannerLHS = styled.div`
  flex-grow: 0.3;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 40%;
  margin-right: 1rem;
  margin-bottom: 0;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 100%;
  }
`;

const ActivitiesBannerHeading1 = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 3.37rem;
  font-weight: bold;
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 2rem;
  }
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;

const ActivitiesBannerParagraph = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize3};
  color: ${({ theme }) => theme.background};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }
  margin: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin-top: ${({ theme }) => theme.shape.defaultPaddingSmall};
`;

const ActivitiesBannerRHS = styled.div`
  flex-grow: 1;
  max-height: 12rem;
  max-width: 60%;
  img {
    float: right;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-height: 25rem;
    max-width: 100%;
    img {
      width: 100%;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivitiesPage: React.FC = () => {
  return (
    <FlexWrapper>
      <ActivitiesBanner>
        <ActivitiesBannerLHS>
          <ActivitiesBannerHeading1>Activities</ActivitiesBannerHeading1>
          <p style={{ minHeight: "1rem" }} />
          <ActivitiesBannerParagraph>
            Below you will find activities to help you explore and understand your air quality using your SAMHE monitor.
          </ActivitiesBannerParagraph>
        </ActivitiesBannerLHS>
        <ActivitiesBannerRHS>
          <img
            src="/images/app/activities/SAMHE_robot.png"
            alt="SAMHE Monitor on a desk with some stationary equipment"
            height="100%"
          />
        </ActivitiesBannerRHS>
      </ActivitiesBanner>
      <AppPageStyle data-testid="samheAppContent">
        <div data-testid="appPage-activityViews">
          <ActivitiesDashboard />
        </div>
      </AppPageStyle>
    </FlexWrapper>
  );
};

export default ActivitiesPage;
