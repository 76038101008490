import React from "react";
import {
  ConstrainedPageStyle,
  PageTitle,
  Section,
  P,
  SectionHeading,
  SubSectionHeading,
  FloatImageWrapperWithCredit,
} from "../styles/page";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";

const ConnectionCompetitionPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Connection Competition</PageTitle>
      <Section>
        <SectionHeading>Connect your SAMHE monitor to win a prize!</SectionHeading>
        <P>
          To show our appreciation for SAMHE schools with monitors connected to the internet, we've been running a
          monthly 'connection competition'. That has now come to an end but we're running a special one-off connection
          competition with a different prize in May!
        </P>
        <SubSectionHeading>What you could win</SubSectionHeading>
        <div>
          <P>
            Two winning schools will get the opportunity for staff and pupils to meet members of the SAMHE team and ask
            them questions in an online session! This could be during a lesson or after school club session, in a
            student council meeting, or whatever they choose. We will contact the winning schools in early June to
            arrange a date and time that works for them.
          </P>
          <FloatImageWrapperWithCredit desktopStyleOverride={"max-width: 100%"}>
            <img src="/images/student-raising-hand-in-classroom.jpg" alt="student raising hand in classroom" />
            <p className="credit">
              (Image by <AExternal href="https://www.freepik.com/">Freepik</AExternal>)
            </p>
          </FloatImageWrapperWithCredit>
        </div>
        <SubSectionHeading>How to enter</SubSectionHeading>
        <P>
          All schools with connected SAMHE monitors at 23.59 on 31 May 2024 will be automatically entered into our
          connection competition prize draw.
        </P>
        <P>
          Schools which registered before 2024, have connected monitors at the time of the draw and had not connected
          their monitor prior to May 2024 will be entered into a second, parallel, prize draw as part of the connection
          competition.
        </P>
        <SubSectionHeading>How to opt out</SubSectionHeading>
        <P>
          If you'd prefer not to be entered into this competition you can opt out at any time by emailing{" "}
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. See{" "}
          <AExternal href="https://drive.google.com/file/d/1HxABKvkcJ6FC0Ec949W_mRO9ON_fL8pa/view">
            full terms and conditions
          </AExternal>
          .
        </P>

        <SubSectionHeading>Get connected now!</SubSectionHeading>
        <P>
          If you have a SAMHE monitor but it is not connected, follow our{" "}
          <Link to="/getting-started">instructions on getting started</Link>. If you need any assistance or are not sure
          if your monitor is connected, please don't hesitate to <a href="mailto:hello@samhe.org.uk">get in touch</a> or
          join us at an upcoming support session. We are happy to help!
        </P>
        <P>
          If your school doesn't have a SAMHE monitor, <Link to="/register/school">register for free</Link> now to
          receive one! (Final date for registration is 31st May).
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default ConnectionCompetitionPage;
