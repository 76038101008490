import styled from "styled-components";
import { FlexBox } from "../../../styles/singlePageStyles";
import { H2 } from "../../../styles/app";
import { TextFormatter } from "../../TextFormatter";
import { keywordMap } from "../../../dummyData/keywords";

const GraphGuidanceBoxStyle = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  
`;

export interface IGraphGuidanceProps {
  text: string;
}

export const GraphGuidanceBox = ({ text }: IGraphGuidanceProps) => {
  return (
    <GraphGuidanceBoxStyle>
      <H2>What this means for you:</H2>
      <FlexBox>
        <TextFormatter text={text} keywordMap={keywordMap} />
      </FlexBox>
    </GraphGuidanceBoxStyle>
  );
};
