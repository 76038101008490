import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMessageType, INotificationState } from "./lib";

export const initialState: INotificationState = {
  message: "",
  open: false,
  messageType: EMessageType.INFO,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    openMessage: (state, action: PayloadAction<{ message: string; messageType: EMessageType }>) => ({
      open: true,
      ...action.payload,
    }),
    closeMessage: () => ({
      open: false,
      message: "",
      messageType: null,
    }),
  },
});

export const actions = notificationSlice.actions;

export default notificationSlice.reducer;
