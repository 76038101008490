import { FilterObjectClass } from "@react_db_client/constants.client-types";
import React from "react";
import { apiDataViews } from "../../Api/apiDataViews";
import { DataViewId, IDataView, IDataViewFull, TDataAdditionalProps } from "../../lib/DataViews/IDataView";
import { MonitorId } from "../../lib/Monitor/IMonitor";
import { IDataViewCardData, IDataViewFullCardData } from "./IDataViewCardProps";
import { ApiContext } from "../../Redux/Api/ApiContext";

export interface IUseGetDataViewsMetaDataProps {
  monitorId: MonitorId;
  filters?: FilterObjectClass[];
  groupId: string;
}

export interface IUseGetDataViewsMetaDataReturn {
  cards: IDataViewCardData[];
  dataViews: IDataView[];
  loadingViews: boolean;
  error: null | string;
  reload: () => void;
}

export const useGetDataViewsMetaData = ({
  filters,
  monitorId,
  groupId,
}: IUseGetDataViewsMetaDataProps): IUseGetDataViewsMetaDataReturn => {
  const { apiGetDataViews } = React.useContext(ApiContext);
  const [dataViews, setDataViews] = React.useState<IDataView[]>(null);
  const [error, setError] = React.useState(null);
  const [loadingViews, setLoadingViews] = React.useState(false);
  const [reloadFlag, setReloadFlag] = React.useState(true);

  React.useEffect(() => {
    if (filters) setReloadFlag(true);
  }, [filters]);

  React.useEffect(() => {
    if (reloadFlag) {
      setReloadFlag(false);
      setError(null);
      setLoadingViews(true);
      apiGetDataViews(filters)
        .then((d) => {
          setDataViews(d);
        })
        .catch(() => {
          setError("Failed to load Data Views");
        })
        .finally(() => {
          setLoadingViews(false);
        });
    }
  }, [reloadFlag, filters, apiGetDataViews]);

  const cards: IDataViewCardData<TDataAdditionalProps>[] = React.useMemo(
    () => dataViews?.map((d) => ({ ...d, title: d.label, monitorId, uniqueCardId: `${groupId}_${d.id}` })) || [],
    [dataViews, monitorId, groupId]
  );

  return {
    cards,
    dataViews,
    loadingViews,
    error,
    reload: () => setReloadFlag(true),
  };
};

export interface IUseGetDataViewMetaDataProps<T extends TDataAdditionalProps> extends IDataViewCardData<T> {
  id: DataViewId;
}

export interface IUseGetDataViewMetaDataReturn<T extends TDataAdditionalProps> {
  card: IDataViewFullCardData<T>;
  dataView: IDataView<T>;
  loadingView: boolean;
  error: null | string;
  reload: () => void;
}

export const useGetDataViewMetaData = <T extends TDataAdditionalProps>({
  id,
  ...additionalProps
}: IUseGetDataViewMetaDataProps<T>): IUseGetDataViewMetaDataReturn<T> => {
  const [dataView, setDataView] = React.useState<IDataViewFull<T>>(null);
  const [error, setError] = React.useState(null);
  const [loadingView, setLoadingView] = React.useState(false);
  const firstLoad = React.useRef(true);
  const [reloadFlag, setReloadFlag] = React.useState(true);

  React.useEffect(() => {
    if (reloadFlag) {
      setReloadFlag(false);
      setError(null);
      setLoadingView(true);
      firstLoad.current = false;
      apiDataViews
        .get(id)
        .then((d: IDataViewFull<T>) => {
          if (d) setDataView(d);
        })
        .catch(() => {
          setError("Failed to load Data Views");
        })
        .finally(() => {
          setLoadingView(false);
        });
    }
  }, [reloadFlag, id]);

  const card: IDataViewFullCardData<T> = React.useMemo(
    () => (dataView ? { ...additionalProps, ...dataView, title: dataView.label } : null),
    [dataView, additionalProps]
  );

  return {
    card,
    dataView,
    loadingView: firstLoad.current || loadingView,
    error,
    reload: () => setReloadFlag(true),
  };
};

export interface IUseGetSingleDataViewsMetaDataProps {
  id: DataViewId;
  monitorId: MonitorId;
}

export interface IUseGetSingleDataViewMetaDataReturn {
  card: IDataViewCardData | { loading: true } | { loading: false; error: string };
  dataView: IDataView;
  loadingView: boolean;
  error: null | string;
  reload: () => void;
}

export const useGetSingleDataViewMetaData = ({
  id,
  monitorId,
}: IUseGetSingleDataViewsMetaDataProps): IUseGetSingleDataViewMetaDataReturn => {
  const [dataView, setDataView] = React.useState<IDataViewFull>(null);
  const [error, setError] = React.useState<null | string>(null);
  const [loadingView, setLoadingView] = React.useState(false);
  const [reloadFlag, setReloadFlag] = React.useState(true);

  React.useEffect(() => {
    // Reload if id changes
    setReloadFlag(true);
  }, [id]);

  React.useEffect(() => {
    if (reloadFlag) {
      setReloadFlag(false);
      setError(null);
      setLoadingView(true);
      apiDataViews
        .get(id)
        .then((d: IDataViewFull) => {
          if (d) setDataView(d);
          if (!d) setError("Data View not found");
        })
        .catch(() => {
          setError("Failed to load Data View");
        })
        .finally(() => {
          setLoadingView(false);
        });
    }
  }, [reloadFlag, id]);

  const card: IDataViewFullCardData | { loading: true } | { loading: false; error: string } = React.useMemo(
    () =>
      dataView
        ? {
            ...dataView,
            title: dataView.label,
            monitorId,
            uniqueCardId: dataView.id,
            loading: loadingView,
            error: error,
          }
        : { loading: loadingView, error: error },
    [dataView, monitorId, loadingView, error]
  );

  return {
    card,
    dataView,
    loadingView,
    error,
    reload: () => setReloadFlag(true),
  };
};
