import React from "react";
import styled from "styled-components";
import { ConstrainedPageStyle, P, PageTitle, Section, SectionBackground } from "../styles/page";
import { keywordMap } from "../dummyData/keywords";
import { TextFormatter } from "../components/TextFormatter";
import { BoxoutContactStyle } from "../styles/hero";
import { IKeyword, KeywordId } from "../lib/Keyword/IKeyword";
import { getNodeText } from "../utils/textUtils";

export const KeywordsListTable = styled.table`
  width: 100%;
  & > tr > td:first-child {
    width: 200px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  td {
    border: 1px solid black;
    padding: 1rem;

    section {
      margin: 0;
      padding: 0;
    }

    p {
      max-width: 100%;

      img {
        max-width: 100%;
      }
    }
  }

  //NOTE: margin bottom is set to 0 for <p> (NOTE: not <P>) in the reset.tsx, ReactMarkdown using <p> not <P>
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const filterBySearchVal = (searchVal: string) => (item: IKeyword) => {
  const labelAsString = typeof item.label === "string" ? item.label : getNodeText(item.label);
  if (labelAsString.toLowerCase().includes(searchVal.toLowerCase())) return true;
  if (getNodeText(item.description).toLowerCase().includes(searchVal.toLowerCase())) return true;
  return false;
};

const KeywordsListPage: React.FC = () => {
  const [searchVal, setSearchVal] = React.useState("");

  // hide some entries from the list
  const keysToHide: KeywordId[] = [
    "guidance-on-window-types",
    "pm_and_tvoc_guidance",
    "vocSources",
    "pm_sources",
    "guidance_on_monitor_readings",
    "guidanceOnInterpretingMonitorReadings",
    "guidanceOnMonitorPlacement",
    "co2_reference_text",
    "ventilationGuidance",
    "temperature_and_relative_humidity",
    "guidance_on_air_cleaning_devices",
    "about_air_pollution",
  ];
  const filteredKeywordMap = keywordMap
    .filter((item) => !keysToHide.includes(item.uid))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <ConstrainedPageStyle>
        <SectionBackground>
          <PageTitle>Glossary</PageTitle>
        </SectionBackground>
        <Section>
          <P>
            Below is a list of key terms that are used throughout the SAMHE project. Please use the search bar to find a
            specific entry.
          </P>
          <BoxoutContactStyle style={{ marginBottom: "2rem", padding: "2rem" }}>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
              <label style={{ minWidth: "12rem", marginBottom: "0.2rem" }} htmlFor="searchInput">
                <b>Search glossary:</b>
              </label>
              <input
                style={{ flexGrow: 1 }}
                id="searchInput"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </div>
          </BoxoutContactStyle>

          <KeywordsListTable>
            {filteredKeywordMap.filter(filterBySearchVal(searchVal)).map((keyword) => (
              <tr>
                <td>{keyword.label}</td>
                <td>
                  {typeof keyword.description === "string" ? (
                    <TextFormatter text={keyword.description} keywordMap={keywordMap} />
                  ) : (
                    keyword.description
                  )}
                </td>
              </tr>
            ))}
          </KeywordsListTable>
        </Section>
      </ConstrainedPageStyle>
    </>
  );
};

export default KeywordsListPage;
