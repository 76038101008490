import styled from "styled-components";
import { BETA_VERSION, DEV_MODE } from "../../config";
import { useAppSelector } from "../../Redux/hooks";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

export const DebugBarStyle = styled.div`
  border-radius: 2rem;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: ${({ theme }) => theme.shape.defaultPadding};
  z-index: 1000;
  background: white;
  padding 1rem;

  p {
    display: none;
  }

  &:hover, &:focus {
    color: white;
    opacity: 1;
    border: 1px solid black;
    p {
      display: block;
    }
  }
  `;

export const DebugBar = () => {
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const currentRoomMonitor = useAppSelector((state) => state.userState.state.monitor?.data?.room);
  const dissallowMoveYourMonitor = useAppSelector(
    (state) => state.userState.state.school?.data?.disallowMoveYourMonitor
  );
  const hasMultipleMonitors = useAppSelector((state) => state.userState.state.school?.data?.monitors?.length > 1);

  // State to hold width and height
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  // Effect to handle resize event with debounce
  useEffect(() => {
    // Debounced handler
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100); // 100ms debounce delay

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.cancel(); // Cancel the debounced function on cleanup
    };
  }, []);

  if (!BETA_VERSION && !DEV_MODE) return <></>;

  return (
    <DebugBarStyle>
      <h3>Debug Info:</h3>
      <p>Room: {currentRoom?.label}</p>
      <p>Monitor: {monitorId}</p>
      <p>Current Room Monitor: {currentRoomMonitor?.label}</p>
      <p>Static School: {dissallowMoveYourMonitor ? "true" : "false"}</p>
      <p>Has Multiple Monitors: {hasMultipleMonitors ? "true" : "false"}</p>
      <p>
        Current Browser Resolution: {width}px x {height}px
      </p>
    </DebugBarStyle>
  );
};
