import React from "react";
import { Link } from "react-router-dom";
import { useAsyncRetry } from "react-use";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAppSelector } from "../../Redux/hooks";
import { ISession } from "../../Redux/User/userReducer";
import { Section, SectionHeading, P, PageTitle } from "../../styles/page";
import { SchoolAdminForm } from "../Forms/SchoolAdminForm";
import { apiSchoolAdmin } from "../../Api/apiSchoolAdmin";
import { CTALinkButtonStyle } from "../../styles/links";
import { ESchoolStatus } from "../../lib/School/ESchoolStatus";
import { ISchoolAdminFormSubmission } from "../../lib/formSubmissions/ISchoolAdminSubmission";
import { RoomListForm } from "../Forms/RoomListForm";

export interface ISchoolActivationProps {}

const apiActivateAndSubmit = (token: string) => async (data: ISchoolAdminFormSubmission) => {
  await apiSchoolAdmin.activate(token)();
  return apiSchoolAdmin.post(token)(data);
};

export const SchoolActivation: React.FC<ISchoolActivationProps> = () => {
  const { token } = React.useContext(AuthContext);
  const userState = useAppSelector<ISession>((state) => state.userState);
  const schoolAdminData = useAsyncRetry(async () => apiSchoolAdmin.get(token), [token]);
  const { state } = userState;
  const monitors = state.school?.data?.monitors || [];
  const roomList = state.school.data?.roomList || [];
  const fullSchoolAdminData = { ...(schoolAdminData?.value || {}), roomList };
  const serialNumber = state.monitor?.id || "";

  const schoolIsActivated =
    state.school?.data?.status === ESchoolStatus.ACTIVATED &&
    fullSchoolAdminData?.schoolHandle &&
    fullSchoolAdminData?.secretWordPupil &&
    fullSchoolAdminData?.secretWordTeacher;
  const activatedMonitorCount = monitors.filter((m) => m.activated).length;
  const schoolHasRooms = roomList.length > 0;

  const onAdminFormComplete = () => {
    schoolAdminData.retry();
  }

  return (
    <>
      <PageTitle>School activation page</PageTitle>
      <Section style={{ margin: 0, padding: 0 }}>
        {!serialNumber && (
          <p>
            It looks like your school does not have a monitor assigned to it. Please contact SAMHE support at{" "}
            <a href="mailto:techsupport@samhe.org.uk">techsupport@samhe.org.uk</a>
          </p>
        )}
        {serialNumber && (!schoolIsActivated || !schoolHasRooms) && (
          <>
            <P>Please complete the form below to activate your school.</P>
            {!schoolAdminData.loading && (
              <>
                <RoomListForm />
                <SchoolAdminForm
                  schoolAdminData={fullSchoolAdminData}
                  apiSubmitData={apiActivateAndSubmit}
                  refreshUserDataAfterSubmit
                  onSuccessfulSubmit={onAdminFormComplete}
                />
              </>
            )}
            {schoolAdminData.loading && <P>Loading...</P>}
          </>
        )}
        {schoolIsActivated && schoolHasRooms && <P>Your school has been successfully activated!</P>}
      </Section>
      {(schoolIsActivated && schoolHasRooms && activatedMonitorCount) === 0 ? (
        <Section style={{ margin: 0, padding: 0 }}>
          <P style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <b>You haven't linked your monitor to the Web App yet.</b>
          </P>
          <CTALinkButtonStyle to="/setup/monitor">Please go to the Monitor Link page</CTALinkButtonStyle>
        </Section>
      ) : (
        ""
      )}
      {activatedMonitorCount && schoolIsActivated && schoolHasRooms ? (
        <Section title="Next steps">
          <SectionHeading>Next steps...</SectionHeading>
          <p>You are all setup and ready to use the SAMHE App.</p>
          <div style={{ minHeight: "2rem" }} />
          <h3>Go to the SAMHE Web App</h3>
          <Link to="/app">SAMHE Web App</Link>
        </Section>
      ) : (
        ""
      )}
    </>
  );
};
