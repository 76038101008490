import React from "react";
import styled from "styled-components";
import { ButtonStyle, CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { EDayRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { weeks } from "../../../DateRangePicker/utils";

export interface IPieChartOptions {
  reloadData: () => void;
  loading: boolean;
  setLiveUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  liveUpdate: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
}

export const PieChartOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const PieChartOptions: React.FC<IPieChartOptions> = ({
  reloadData,
  loading,
  setLiveUpdate,
  liveUpdate,
  setDateRange,
  dateRange,
}) => {
  const [showOptions, setShowOptions] = React.useState(true);
  return (
    <PieChartOptionsWrap>
      <CardInfoSectionWrapper open={showOptions} maxHeight="55rem">
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <p>Select when your data should reload</p>
              <FlexList left horiz>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle onClick={() => setLiveUpdate((prev) => !prev)} on={liveUpdate ? "true" : undefined}>
                    Live Update
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection>
              <DateRangePicker
                onChange={(newDateRange) => setDateRange(newDateRange)}
                value={dateRange}
                allowedDayRanges={[
                  EDayRange.TODAY,
                  EDayRange.YESTERDAY,
                  EDayRange.THIS_WEEK,
                  EDayRange.LAST_SCHOOL_WEEK,
                  EDayRange.SCHOOL_DAYS,
                  EDayRange.CUSTOM,
                ]}
                allowedMaxDateRange={weeks(1)} // This avoids aggregating by more than an hour
              />
              <DateRangeInfo dateRange={dateRange} showTimeRange />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
      <ButtonStyle style={{ width: "100%" }} onClick={() => setShowOptions((prev) => !prev)}>
        {showOptions ? "Hide Options" : "Show Options"}
      </ButtonStyle>
    </PieChartOptionsWrap>
  );
};
