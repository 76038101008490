import React from "react";
import { ConstrainedPageStyle, PageTitle, Section, P, SectionHeading } from "../styles/page";
import { CTALinkButtonStyle } from "../styles/links";
import styled from "styled-components";

export const LaunchOfferPageWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: block;
  }
`;

const LaunchOfferPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Exclusive Sticker & Poster Pack</PageTitle>
      <LaunchOfferPageWrapper>
        <div style={{ flex: 1 }}>
          <Section>
            <SectionHeading>Offer expired</SectionHeading>
            <P>We're sorry - we have now sent out all of our limited edition sticker and poster packs.</P>
            <P>
              However, do still register for SAMHE to receive your air quality monitor and access to the interactive
              SAMHE Web App!
            </P>
            <P>
              <CTALinkButtonStyle to="/register/school">Register now</CTALinkButtonStyle>
            </P>
          </Section>
        </div>
        <div style={{ flex: 1 }}>
          <img src="/images/launch-pack.png" alt="Launch pack" style={{ width: "100%" }} />
        </div>
      </LaunchOfferPageWrapper>
    </ConstrainedPageStyle>
  );
};

export default LaunchOfferPage;
