import { exampleTags } from "../../dummyData/tags";
import { ITag, TagId } from "../../lib/Tags/ITag";
import { TagsListStyle } from "./style";
import { Tag } from "./Tag";

export interface ITagsProps {
  tags: (ITag | TagId)[];
}

export const Tags = ({ tags }: ITagsProps) => {
  return (
    <TagsListStyle left horiz flexwrap>
      {/* TODO: Remove this use of example tags once server dataview api updated */}
      {tags
        .map((t) => [t, typeof t === "string" ? exampleTags[t] : (t as ITag)] as [typeof t, ITag])
        .map(([t, tag]) =>
          tag ? (
            <Tag key={tag.uid || (t as unknown as string)} data={tag} />
          ) : (
            <span key={String(t)}>MISSING TAG {String(t)}</span>
          )
        )}
    </TagsListStyle>
  );
};
