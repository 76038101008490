import styled from "styled-components";
import { ILabelled, Uid } from "@react_db_client/constants.client-types";
import { IMultiCheckboxOption } from "../../lib/Forms/IMultiCheckboxOption";
import { InputStyle } from "../../styles/form";
import { CheckboxText, CheckboxTextB, RadioButton } from "./Checkbox";
import { MultiCheckBoxLabel } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export interface IMultiCheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  options: IMultiCheckboxOption[];
}

export const MultiCheckboxListStyle = styled.ul<{ direction?: "column" | "row" }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: wrap;
  justify-content: ${({ direction }) => (direction === "column" ? "stretch" : "flex-start")};
  margin: 0;
  margin-top: ${({ theme }) => theme.shape.defaultPaddingSmall};
  margin-bottom: -${({ theme }) => theme.shape.defaultPadding};
  margin-right: -${({ theme }) => theme.shape.defaultPadding};

  max-width: ${({ direction }) => (direction === "column" ? "max-content" : "auto")};
`;

export const MultiCheckboxListItem = styled.li<{ radio?: boolean }>`
  display: flex;
  margin: 0;
  margin-right: ${({ theme }) => theme.shape.defaultPadding};
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  flex-grow: ${({ radio }) => (radio ? "0" : "1")};

  button {
    flex-grow: 1;
    width: fit-content;
  }
`;

export const MultiCheckboxList = (props: IMultiCheckboxProps) => {
  const { type, options, name, multiple, required, ...inputProps } = props;

  if (type && type !== "multicheckbox")
    throw Error(`Attempted to use multicheckbox input for none multicheckbox type: ${type}`);

  const mappedOptions = options.map(({ uid, label: labelOpt }, i) => (
    <MultiCheckboxListItem radio={!multiple} key={uid}>
      {multiple ? (
        <CheckboxText name={`${name}[${i}]`} type="checkbox" {...inputProps} label={labelOpt} required={required}/>
      ) : (
        <RadioButton name={name} fieldId={uid} type="checkbox" {...inputProps} label={labelOpt} required={required}/>
      )}
    </MultiCheckboxListItem>
  ));
  return <MultiCheckboxListStyle aria-labelledby={name} direction={multiple ? "row" : "column"}>{mappedOptions}</MultiCheckboxListStyle>;
};

export const MultiCheckbox = (props: IMultiCheckboxProps) => {
  const { label, name, required, ...otherProps } = props;
  return (
    <InputStyle data-testid={`inputWrap_${name}`}>
      {label && <MultiCheckBoxLabel label={label} htmlFor={name} required={required}></MultiCheckBoxLabel>}
      <MultiCheckboxList {...otherProps} name={name} required={required}/>
      <ErrorMessage name={name} />
    </InputStyle>
  );
};

export interface IMultiCheckboxBProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "value" | "onChange"
  > {
  label?: string;
  options: IMultiCheckboxOption[];
  value: boolean[];
  multiple?: boolean;
  onChange: (newValue: boolean[], value: Uid) => void;
  listStyleOverride?: React.CSSProperties;
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  direction?: "column" | "row";
}

export const selectionToBooleanList = <T extends Uid>(value: T, options: ILabelled<T>[]) => {
  return options.map((o) => value === o.uid);
};

export const MultiCheckboxB = ({
  label,
  options,
  name,
  value,
  multiple,
  direction,
  onChange,
  listStyleOverride = {},
  inputProps = {},
}: IMultiCheckboxBProps) => {
  const handleChecked = (i: number, v: boolean) => {
    const newValue = !Array.isArray(value) || !multiple ? options.map(() => false) : [...value];
    newValue[i] = v;
    onChange(newValue, options[i].uid);
  };

  const mappedOptions = options.map(({ uid, label: labelOpt }, i) => (
    <MultiCheckboxListItem key={uid}>
      <CheckboxTextB
        name={`${name}[${i}]`}
        type="checkbox"
        setChecked={(v) => handleChecked(i, v)}
        checked={value && value[i]}
        data-testid={`${name}[${i}]_[${value && value[i] ? "checked" : "clear"}]`}
        label={labelOpt}
        {...inputProps}
      />
    </MultiCheckboxListItem>
  ));

  return (
    <InputStyle>
      {label && <MultiCheckBoxLabel htmlFor={name} label={label}></MultiCheckBoxLabel>}
      <MultiCheckboxListStyle data-testid={`multiCheckboxList-${name}`} style={listStyleOverride} direction={direction}>
        {mappedOptions}
      </MultiCheckboxListStyle>
    </InputStyle>
  );
};
