import { TDateFormatter } from "../../../lib/DataViews/ETimeFormats";

export const defaultTooltipFormatter =
  (yLabel: string, dateFormatter: TDateFormatter, decimalPlaces: number) => (params) =>
    `At ${dateFormatter(parseInt(params[0].axisValue))} your ${yLabel} was ${(params[0].value as number[])[1].toFixed(
      decimalPlaces
    )}
`;

export const simpleTooltipFormatter =
  (yLabel: string, dateFormatter: TDateFormatter, decimalPlaces: number) => (params) =>
    `At ${dateFormatter(parseInt(params[0].axisValue))} the ${yLabel} was ${(params[0].value as number[])[1].toFixed(
      decimalPlaces
    )}
`;
