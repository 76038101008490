import styled from "styled-components";
import { FlexBox } from "../../../styles/singlePageStyles";

const GraphCaptionBoxStyle = styled.div`
  min-width: 18rem;
  border-radius: 1rem;
  padding: 1rem;
  flex: 1 1 10rem;

  background-color: #303b71;
  div {
    color: white;
  }
`;

export interface IGraphCaptionProps {
  text: string;
}

export const GraphCaptionBox = ({ text }: IGraphCaptionProps) => {
  return (
    <GraphCaptionBoxStyle>
      <FlexBox>{text}</FlexBox>
    </GraphCaptionBoxStyle>
  );
};
