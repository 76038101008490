import React from "react";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { IPreProcessDataOptions, preProcessData } from "../dataProcessing/dataProcessing";
import styled from "styled-components";
import { IGaugeVisualMapProps } from "./lib";
import { useTheme } from "styled-components";
import { useWindowSize } from "@uidotdev/usehooks";

// Gauge visual map props is how we define the main style differences for different variables.
// Check JSON file (sent to Sam) for each metric props.

export interface IGaugeChartsProps {
  data: IMonitorDataItem<number>[];
  preProcessDataOptions?: IPreProcessDataOptions;
  gaugeVisualMapProps?: IGaugeVisualMapProps;
}

export const LegendWrapGauge = styled.div`
  min-width: 10rem;
  width: 100%;
  position: relative;
`;

export const PlotWrapGauge = styled.div`
  flex-grow: 1;
  width: 100%;
  .reactECharts-container {
    height: 16rem;
    @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      height: 24rem;
      min-height: 24rem;
    }
  }
`;

export const WrapGauge = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const GaugeChart = ({ data, preProcessDataOptions, gaugeVisualMapProps }: IGaugeChartsProps) => {
  const dataProcessed = preProcessData(data, preProcessDataOptions);

  const theme = useTheme();
  const size = useWindowSize();
  const isMobileView = React.useMemo(
    () => size.width < parseInt(theme.mediaBoundaries.medium.replace("px", "")),
    [size.width, theme.mediaBoundaries.medium]
  );
  const isTabletView = React.useMemo(
    () => !isMobileView && size.width < parseInt(theme.mediaBoundaries.large.replace("px", "")),
    [isMobileView, size.width, theme.mediaBoundaries.large]
  );
  if (!dataProcessed || dataProcessed.length === 0) return <p>Missing data!</p>;

  const chartOptions: EChartsOption = {
    responsive: true,
    title: {
      text: gaugeVisualMapProps.titleText,
      textStyle: {
        fontSize: "1.2rem",
      },
      left: "center",
      right: "center",
      top: "85%",
    },
    series: [
      {
        type: "gauge",
        radius: "98%",
        center: ["50%", "50%"],
        min: gaugeVisualMapProps.min,
        max: gaugeVisualMapProps.max,
        progress: gaugeVisualMapProps.progress,
        axisLine: {
          lineStyle: {
            width: 25,
            color: gaugeVisualMapProps.lineColor,
          },
        },
        pointer: {
          itemStyle: {
            color: gaugeVisualMapProps.detailColor,
          },
        },
        axisTick: {
          show: gaugeVisualMapProps.showTick,
          distance: -25,
          length: 10,
          lineStyle: {
            color: "#fff",
            width: 2,
          },
        },
        axisLabel: {
          color: "auto",
          distance: 30,
          fontSize: "0.65rem",
        },
        detail: {
          valueAnimation: true,
          formatter: gaugeVisualMapProps.detailFormatter,
          color: gaugeVisualMapProps.detailColor,
          fontSize: "1.2rem",
          offsetCenter: ["0", "30%"],
        },
        data: [
          {
            value: Math.round(data[data.length - 1].value * 10) / 10,
          },
        ],
      },
    ],
  };

  const VisualMap: EChartsOption["visualMap"] = {
    show: gaugeVisualMapProps.show,
    type: "piecewise",
    // left: '75%',
    top: "middle",
    min: gaugeVisualMapProps.min,
    max: gaugeVisualMapProps.max,
    calculable: true,
    realtime: false,
    selectedMode: false,
    textStyle: {
      fontSize: 14,
    },
    itemHeight: 25,
    itemWidth: 25,
    align: "left",
    inverse: false,
    pieces: gaugeVisualMapProps.pieces,
  };

  const VisualMapTablet: EChartsOption["visualMap"] = {
    ...VisualMap,
    top: "middle",
    orient: "horizontal",
    left: "center",
    inverse: false,
  };

  const VisualMapMobile: EChartsOption["visualMap"] = {
    ...VisualMap,
    top: "middle",
    orient: "vertical",
    inverse: false, // Required as order is flipped in vertical view
    left: "center",
  };

  const chartOptionsLegend: EChartsOption = {
    responsive: true,
    grid: {
      left: "100%",
    },
    visualMap: isMobileView ? VisualMapMobile : isTabletView ? VisualMapTablet : VisualMapTablet,
  };

  return (
    <WrapGauge>
      <PlotWrapGauge>
        <ReactECharts
          className="reactEcharts-container"
          key={Date.now()}
          option={chartOptions}
          style={{ width: "100%" }}
        />
      </PlotWrapGauge>
      <LegendWrapGauge>
        <ReactECharts
          key={Date.now()}
          option={chartOptionsLegend}
          style={{ height: isTabletView ? "6rem" : isMobileView ? "10rem" : "6rem" }}
        />
      </LegendWrapGauge>
    </WrapGauge>
  );
};

export const GaugeChartInner = ({ data, preProcessDataOptions, gaugeVisualMapProps }: IGaugeChartsProps) => {
  const dataProcessed = preProcessData(data, preProcessDataOptions);
  if (!dataProcessed || dataProcessed.length === 0) return <p>Missing data!</p>;

  const chartOptions: EChartsOption = {
    responsive: true,
    visualMap: {
      show: gaugeVisualMapProps.show,
      bottom: "5%",
      type: "piecewise",
      left: "center",
      orient: "vertical",
      min: gaugeVisualMapProps.min,
      max: gaugeVisualMapProps.max,
      minOpen: true,
      maxOpen: true,
      calculable: true,
      realtime: false,
      selectedMode: false,
      textStyle: {
        fontSize: 10,
      },
      itemHeight: 12,
      itemWidth: 12,
      align: "left",
      inverse: true, // Required as vertical orientation reverses piece order
      pieces: gaugeVisualMapProps.pieces,
    },
    series: [
      {
        type: "gauge",
        radius: "70%",
        center: ["50%", "40%"],
        min: gaugeVisualMapProps.min,
        max: gaugeVisualMapProps.max,
        progress: gaugeVisualMapProps.progress,
        axisLine: {
          lineStyle: {
            width: 20,
            color: gaugeVisualMapProps.lineColor,
          },
        },
        pointer: {
          itemStyle: {
            color: gaugeVisualMapProps.detailColor,
          },
        },
        axisTick: {
          show: gaugeVisualMapProps.showTick,
          distance: -20,
          length: 5,
          lineStyle: {
            color: "#fff",
            width: 2,
          },
        },
        axisLabel: {
          show: false,
          color: "auto",
          distance: 25,
          fontSize: "0.7rem",
        },
        detail: {
          valueAnimation: true,
          formatter: gaugeVisualMapProps.detailFormatter,
          color: gaugeVisualMapProps.detailColor,
          fontSize: "1rem",
        },
        data: [
          {
            value: Math.round(data[data.length - 1].value * 10) / 10,
          },
        ],
      },
    ],
  };

  return <ReactECharts key={Date.now()} option={chartOptions} style={{ width: "100%" }} />;
};
