import styled from "styled-components";
import { AchievementCategories, IActivityScore } from "../../lib/Achievements/EAchievementCategory";

export interface IScoreContributionsProps {
  activityScores: IActivityScore[];
  center?: boolean;
}

export interface IScoreBarProps {
  count: number;
  center?: boolean;
  color: string;
}

export const ScoreBar = styled.div<IScoreBarProps>`
  background: ${({ theme }) => theme.colors.main};
  height: 2rem;
  color: white;
  padding: 0.2rem;
  transition: all 0.3s;
  margin-right: ${({ theme }) => theme.shape.defaultPadding};

  /* Mobile */
  width: ${({ count }) => count * 1}rem;

  /* Tablet */
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    width: ${({ count }) => count * 0.4}rem;
  }

  /* Full Screen */
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: ${({ count }) => count * 0.4}rem;
  }
`;

export const TotalScoreBar = styled(ScoreBar)`
  opacity: 0.7;
`;

export const ScoreBarContainer = styled.div<Partial<IScoreBarProps>>`
  display: flex;
  margin-bottom: 0.2rem;
  flex-wrap: no-wrap;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-wrap: wrap;
    margin-bottom: 0.7rem;
  }
`;

export const ScoreBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 8rem;
`;

export const ScoreBarLabel = styled.b`
  width: 7rem;
  text-align: left;
  margin-right: 0.2rem;
  line-height: 2rem;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 100%;
    line-height: 1.2rem;
  }
`;

export const ScoreBarScore = styled.div`
  width: 4rem;
  text-align: left;
  margin-left: 0.2rem;
  line-height: 2rem;
  text-wrap: nowrap;
`;

/* Used on Activity Meta panel */
export const ScoreContributions = ({ activityScores, center }: IScoreContributionsProps) => {
  // const totalScore = Object.values(scoreContributions).reduce((acc, v) => acc + v);
  return (
    <ScoreBarWrap>
      {activityScores
        .filter((s) => s.score > 0)
        .map((categoryScore) => (
          <ScoreBarContainer key={categoryScore.category.id} center={center}>
            <ScoreBarLabel>{AchievementCategories[categoryScore.category.id].label}</ScoreBarLabel>
            <ScoreBar color={AchievementCategories[categoryScore.category.id].color} count={categoryScore.score} />
            <ScoreBarScore>{categoryScore.score} points</ScoreBarScore>
          </ScoreBarContainer>
        ))}

      {/* {(totalScore && (
        <ScoreBarContainer center={center}>
          <ScoreBarLabel>Total</ScoreBarLabel>
          <TotalScoreBar color="grey" count={totalScore} />
          <ScoreBarScore>{totalScore} mins</ScoreBarScore>
        </ScoreBarContainer>
      )) ||
        ""} */}
    </ScoreBarWrap>
  );
};

// export const TotalScoreStyle = styled.p`
//   font-size: 18px;
//   line-height: 2rem;
//   font-weight: 800;
//   margin-left: 1rem;
// `;

// export const ValuesContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;

// export const LongBarWrap = styled.div`
//   display: flex;
//   width: 100%;
// `;

// const ScoreBarScale = 0.35;

// export const LongBar = styled.div<{ value: number; color: string }>`
//   min-width: ${({ value }) => value * ScoreBarScale}rem;
//   width: ${({ value }) => value * ScoreBarScale}rem;
//   height: 2rem;
//   background: ${({ color }) => color};
// `;

// /* Used on card cover */
// export const ScoreEstimatesLongBar = ({ timeEstimates }: IScoreEstimatesProps) => {
//   const totalScore = (timeEstimates.investigation || 0) + (timeEstimates.video || 0) + (timeEstimates.discussion || 0);
//   return (
//     <LongBarWrap>
//       <ValuesContainer>
//         <img
//           src="/images/app/activities/icons/clockIcon.png"
//           style={{ width: "2rem", height: "2rem" }}
//           alt="Score estimates"
//         />
//         {(timeEstimates.investigation && (
//           <LongBar value={timeEstimates.investigation} color={themeColors.PANTONE534C} />
//         )) ||
//           ""}
//         {(timeEstimates.video && <LongBar value={timeEstimates.video} color={themeColors.PANTONE375C} />) || ""}
//         {(timeEstimates.discussion && <LongBar value={timeEstimates.discussion} color={themeColors.PANTONE2592C} />) ||
//           ""}
//         <TotalScoreStyle>{totalScore}mins</TotalScoreStyle>
//       </ValuesContainer>
//     </LongBarWrap>
//   );
// };

// export const ScoreKeyLabel = styled.p<{ color: string }>`
//   line-height: 2rem;
//   margin-left: 0.5rem;
// `;

// export const ScoreEstimateKeyItems = () => (
//   <KeySectionStyle>
//     <KeySectionHeadingStyle>
//       {/* <img
//         src="/images/app/activities/icons/clockIcon.png"
//         style={{ width: "2rem", height: "2rem" }}
//         alt="Score estimates"
//       /> */}
//       Score Estimate
//     </KeySectionHeadingStyle>

//     <FlexBox horiz>
//       <LongBar value={2 / ScoreBarScale} color={themeColors.PANTONE534C} />
//       <ScoreKeyLabel color={themeColors.PANTONE534C}>Investigation</ScoreKeyLabel>
//     </FlexBox>
//     <FlexBox horiz>
//       <LongBar value={2 / ScoreBarScale} color={themeColors.PANTONE375C} />
//       <ScoreKeyLabel color={themeColors.PANTONE375C}>Video</ScoreKeyLabel>
//     </FlexBox>
//     <FlexBox horiz>
//       <LongBar value={2 / ScoreBarScale} color={themeColors.PANTONE2592C} />
//       <ScoreKeyLabel color={themeColors.PANTONE2592C}>Discussion</ScoreKeyLabel>
//     </FlexBox>
//   </KeySectionStyle>
// );
