import React from "react";
import { ThemeProvider } from "styled-components";
import { IActivityCardProps } from "../../IActivityCardProps";
import { lightTheme } from "../../../../styles/themes";
import { CardStyle, CardTitle } from "../../../../GenericComponents/CardBoard/styles";
import { ActivityStatusIcon } from "../../CommonComponents/ActivityStatusIcon";
import { EActivityStatus } from "../../../../lib/Activities/IActivity";
import { CTALinkButtonStyle } from "../../../../styles/links";
import { ExpandedCard } from "../../CommonComponents/ExpandedCard";

export interface IDemoActivityCardProps extends IActivityCardProps {}

export interface IDemoActivityCardInnerProps extends IDemoActivityCardProps {
  loaded?: boolean;
}

export const DemoActivityCardExpanded = (props: IDemoActivityCardInnerProps) => {
  const { id } = props;
  return (
    <ExpandedCard id={id} activityTypeName="Data detective" {...props}>
      {() => <div>Steps would go here</div>}
    </ExpandedCard>
  );
};

export const DemoActivityCard = ({
  id,
  description,
  title,
  activityUserProgress: { activityStatus } = { activityStatus: EActivityStatus.NOT_STARTED, activityId: null },
}: IDemoActivityCardInnerProps) => (
  <ThemeProvider theme={lightTheme}>
    <CardStyle>
      <CardTitle>
        <CTALinkButtonStyle to={`/app/activities/${id}`}>{title}</CTALinkButtonStyle>
        <ActivityStatusIcon activityStatus={activityStatus || EActivityStatus.NOT_STARTED} />
      </CardTitle>
      <p></p>
      <p>{description}</p>
      <p></p>
    </CardStyle>
  </ThemeProvider>
);
