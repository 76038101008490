import { ILabeled } from "../common";

export enum UserGroup {
  SUPERADMIN = "SuperAdmin",
  DEPLOYMENTADMIN = "DeploymentAdmin",
  SCHOOLADMIN = "SchoolAdmin",
  RESEARCHER = "Researcher", // TODO: Not implemented in Server
  STUDENT = "Student",
  TEACHER = "Teacher",
  GUEST = "Guest",
}

export const UserGroups: Record<UserGroup, ILabeled<UserGroup>> = {
  [UserGroup.SUPERADMIN]: {
    uid: UserGroup.SUPERADMIN,
    label: "Admin",
  },
  [UserGroup.RESEARCHER]: {
    uid: UserGroup.RESEARCHER,
    label: "Researcher",
  },
  [UserGroup.SCHOOLADMIN]: {
    uid: UserGroup.SCHOOLADMIN,
    label: "School Admin",
  },
  [UserGroup.DEPLOYMENTADMIN]: {
    uid: UserGroup.DEPLOYMENTADMIN,
    label: "Admin Deployment",
  },
  [UserGroup.STUDENT]: {
    uid: UserGroup.STUDENT,
    label: "Student",
  },
  [UserGroup.TEACHER]: {
    uid: UserGroup.TEACHER,
    label: "Teacher",
  },
  [UserGroup.GUEST]: {
    uid: UserGroup.GUEST,
    label: "Guest",
  },
};

export const userGroupsList = Object.values(UserGroup);
