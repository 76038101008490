import React from "react";
import styled from "styled-components";
import { CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { years } from "../../../DateRangePicker/utils";

export interface ISimplePlotOptions {
  reloadData: () => void;
  loading: boolean;
  setStartYAxisAtZero: React.Dispatch<React.SetStateAction<boolean>>;
  startYAxisAtZero: boolean;
  setLiveUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  liveUpdate: boolean;
  setShowMinAndMax: React.Dispatch<React.SetStateAction<boolean>>;
  showMinAndMax: boolean;
  setShowAverage: React.Dispatch<React.SetStateAction<boolean>>;
  showAverage: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
}

export const SimplePlotOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const SimplePlotOptions: React.FC<ISimplePlotOptions> = ({
  reloadData,
  loading,
  setStartYAxisAtZero,
  startYAxisAtZero,
  setLiveUpdate,
  liveUpdate,
  setShowMinAndMax,
  showMinAndMax,
  setShowAverage,
  showAverage,
  setDateRange,
  dateRange,
}) => {
  return (
    <SimplePlotOptionsWrap>
      <CardInfoSectionWrapper open maxHeight="55rem" style={{ width: "100%" }}>
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap style={{ gap: "1rem" }}>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <p>Select when your data should reload</p>
              <FlexList>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle onClick={() => setLiveUpdate((prev) => !prev)} on={liveUpdate ? "true" : undefined}>
                    Live Update
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection>
              <H2>Plot View Options</H2>
              <p>Select how your data is plotted</p>
              <FlexList>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setStartYAxisAtZero((prev) => !prev)}
                    on={startYAxisAtZero ? "true" : undefined}
                  >
                    Start Y axis at 0
                  </OnOffButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setShowMinAndMax((prev) => !prev)}
                    on={showMinAndMax ? "true" : undefined}
                  >
                    Show Min and Max
                  </OnOffButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setShowAverage((prev) => !prev)}
                    on={showAverage ? "true" : undefined}
                  >
                    Show Average
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection style={{ flex: "10 1 25rem" }}>
              <DateRangePicker
                onChange={(newDateRange) => setDateRange(newDateRange)}
                value={dateRange}
                useAggregation
                allowTimeLimiting
                adaptiveAggregation
                allowedMaxDateRange={years(1)} // This avoids aggregating by more than an hour
              />
              <DateRangeInfo dateRange={dateRange} showTimeRange />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
    </SimplePlotOptionsWrap>
  );
};
