import React from "react";
import ErrorBoundary from "../ErrorBoundary";
import { ICard } from "./lib";
import { CardContainerStyle } from "./styles";

export interface ICardChildProps<TypeKey> extends Partial<ICard<TypeKey>> {
  rootRef: React.RefObject<HTMLElement>;
}

export interface ICardProps<TypeKey> extends Partial<ICard<TypeKey>> {
  children: React.ReactNode | ((childProps: ICardChildProps<TypeKey>) => React.ReactNode);
  focused?: boolean;
}

export const Card = <TypeKey,>(props: ICardProps<TypeKey | string>) => {
  const { children } = props;
  const rootRef = React.useRef(null);

  return (
    <ErrorBoundary>
      <CardContainerStyle ref={rootRef} role="card">
        {typeof children === "function" ? children({ rootRef }) : children}
      </CardContainerStyle>
    </ErrorBoundary>
  );
};
