import React from "react";
import {
  PageTitle,
  ConstrainedPageStyle,
  Section,
  Ul,
  TableOfContentsWrapper,
  TableOfContents,
  SectionBackground,
} from "../styles/page";
import { MonitorLEDs } from "../components/Documents/MonitorLEDs";
import { TvocsAndPmsInfo } from "../components/Documents/TvocsAndPms";
import { TemperatureHumidityInfo } from "../components/Documents/TemperatureHumidity";
import { Link } from "react-router-dom";
import { CTALinkButtonStyle } from "../styles/links";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";

export const MonitorLEDCombinationContent = (
  <div>
    <MonitorLEDs />
    <TvocsAndPmsInfo />
    <TemperatureHumidityInfo glossaryClass={false}/>
    <Section>
      <CTALinkButtonStyle to="/resources">Find more info on the Resources Hub</CTALinkButtonStyle>
    </Section>
  </div>
);

const MonitorLEDCombinations: React.FC = () => {
  return MonitorLEDCombinationContent;
};

const MonitorLEDPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <SectionBackground>
        <PageTitle id="samhe-guidance-on-monitor-readings">Interpreting the SAMHE Monitor Readings</PageTitle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#what-you-can-see-on-the-monitor">Monitor lights & numbers</Link>
              </li>
              <li>
                <Link to="#co2">
                  CO<sub>2</sub>
                </Link>
              </li>
              <li>
                <Link to="#why-so-many-details-for-co2-compared-to-tvocs-and-pm">TVOCs and PM</Link>
              </li>
              <li>
                <Link to="#why-measure-temperature-and-relative-humidity">Temperature & humidity</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <MonitorLEDCombinations />
        <ScrollTopButton />
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default MonitorLEDPage;
