import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MonitorAccuracyGuide } from "../components/Documents/MonitorAccuracyGuide";
import { MonitorPlacementGuide } from "../components/Documents/MonitorPlacementGuide";
import { ConstrainedPageStyle, Section } from "../styles/page";

const MonitorPlacementGuidePage: React.FC = () => {
  const { hash } = useLocation();
  const uid = hash?.replace("#", "");

  return (
    <ConstrainedPageStyle className="page__wrap">
      <MonitorPlacementGuide />
      <MonitorAccuracyGuide uidToShow={uid} />
      <Section>
        <Link to="/resources">Find more information on the Resources Hub.</Link>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default MonitorPlacementGuidePage;
