import React from "react";

import { ConstrainedPageStyle, PageTitle, Section } from "../styles/page";

const SuccessMessage = () => (
  <div>
    <p>Thank you for completing our contact form, we will respond as soon as possible.</p>
    <p>Please keep an eye out for an email from the SAMHE team if a response is required.</p>
    <p>
      If we need to confirm any of the details entered in this form we will contact you using the email address you have
      provided.
    </p>
  </div>
);

const TechSupportContactSuccessPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Successfully submitted support request!</PageTitle>
      <Section>
        <SuccessMessage />
      </Section>
    </ConstrainedPageStyle>
  );
};

export default TechSupportContactSuccessPage;
