import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonCSS, ButtonStyle } from "./button";

export const ExpandedPanelStyle = styled.div`
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  && {
    pointer-events: none;
  }
  // background: rgba(255, 255, 255, 0.9);
  & > * {
    pointer-events: initial;
  }
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    background: ${({ theme }) => theme.colors.appBackground};
    opacity: 0.9;
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  }
`;

export const ExpandedPanelInnerStyle = styled.div`
  left: 0;
  right: 0;
  bottom: ${({ theme }) => theme.shape.defaultPaddingWide};
  margin: 0;
  top: ${({ theme }) => theme.shape.defaultPaddingSmall};
  background: ${({ theme }) => theme.background};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  max-width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in;
  max-height: 100%;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-height: 95%;
    top: ${({ theme }) => theme.shape.defaultPadding};
    padding: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
`;

export const ExpandedPanelHeaderStyle = styled.div`
  height: 4rem;
  background: ${({ theme }) => theme.colors.main};
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow: visible;
  a {
    color: ${({ theme }) => theme.colors.ctaText};
    margin: ${({ theme }) => theme.shape.defaultPaddingMid};
    font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
    &:hover {
      color: ${({ theme }) => theme.colors.ctaText};
    }
  }
`;

export const ExpandedPanelContainer = styled.div`
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const CloseBtn = styled(ButtonStyle)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const CloseBtnLink = styled(Link)`
  ${ButtonCSS}
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const Hr = styled.hr`
  background: ${({ theme }) => theme.colors.main};
  width: 4rem;
  height: 4px;
  border: none;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
`;
