import styled from "styled-components";
import { EMessageType } from "./lib";

export interface INotificationBarStyleProps {
  messageType: EMessageType;
  open: boolean;
}

export const NotificationBarStyle = styled.div<INotificationBarStyleProps>`
  position: fixed;
  left: 4rem;
  right: 4rem;
  bottom: 0;
  min-height: 2rem;
  z-index: 200;
  text-align: center;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: opacity 0s ease;

  padding: 2px;
  border-radius: 2px;
  margin: 2px;
  * {
    color: white;
  }
  ${({ open }) =>
    open
      ? ``
      : `
        display: none;
        transition: opacity 0.4s ease;
        opacity: 0;
        pointer-events: none;
  `}
  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    max-height: 2rem;
    // background: white;
    border: none;
    // color: grey;
    color: white;
    background: none;
  }
  ${({ messageType }) => {
    switch (messageType) {
      case EMessageType.INFO:
        return `background: rgba(95, 172, 50, 0.8);`;
      case EMessageType.WARN:
        return `background: rgba(237, 133, 22, 0.8);`;
      case EMessageType.ERROR:
        return `background: rgba(253, 41, 41, 0.8);`;
      default:
        break;
    }
  }}
`;
