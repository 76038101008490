import styled from "styled-components";
import { CTALinkButtonStyle } from "./links";

export const HeroStyle = styled.div`
  height: 100%;
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    height: 40rem;
    justify-content: stretch;
    align-items: stretch;
  }

  @media (max-width: 480px) {
    height: 45rem;
  }
`;

export const BoxoutContactStyle = styled.div`
  background-color: #e8e8e8;
  min-height: auto;
  height: auto;
  border: 0.2rem solid #e8e8e8;
  border-radius: 1rem 0;
  overflow: auto;
  padding: ${({ theme }) => theme.shape.defaultPadding};
`;

export const BoxoutHeroStyle = styled(HeroStyle)`
  background-color: #303b71;
  min-height: auto;
  height: auto;
  h2,
  p,
  ul {
    color: white;
  }
`;

export interface IHeroBackgroundProps {
  src: string;
}

export const HeroBackground = styled.div<IHeroBackgroundProps>`
  background-image: url("${({ src }) => src}");
  background-position: center;
  background-size: cover;
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
`;

export const HeroHomeBackground = styled.div<IHeroBackgroundProps>`
  background-image: url("${({ src }) => src}");
  background-position: center;
  background-size: cover;
  border-radius: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
`;

export const HeroInnerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 2rem;
  right: 2rem;
  top: 2rem;
  bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    border: none;
    display: flex;
    flex-direction: column;
    padding: none;
    padding-left: 3rem;
    padding-right: 3rem;
    justify-content: space-between;
  }
`;

export const HeroButtonWrap = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 100%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    width: 60%;
  }
`;

export const HeroButtonLink = styled(CTALinkButtonStyle)`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: 1.5rem;
  }

  &:last-child() {
    margin-right: none;
  }

  border: none;
  cursor: pointer;
  padding: 0.6rem;
  font-size: ${({ theme }) => theme.typography.fontSize7};
  line-height: 2.5rem;
  text-decoration: none;
`;

export const HomeRegisterButtonLink = styled(HeroButtonLink)`
  display: inline;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.tertiaryHover};
  font-size: ${({ theme }) => theme.typography.fontSize7};
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    font-size: ${({ theme }) => theme.typography.fontSize6};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    font-size: ${({ theme }) => theme.typography.fontSize5};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryDark};
    color: ${({ theme }) => theme.colors.tertiaryHover};
  }
`;

export const HeroMainHeading = styled.h1`
  color: #30c987;
  font-size: 5rem;
  line-height: 5rem;
  margin-bottom: 0.8rem;
  max-height: 5rem;

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    font-size: 10rem;
    line-height: 10rem;
  }
`;

export const HeroSubHeading = styled.h2`
  color: white;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    font-size: 3rem;
    line-height: 3rem;
  }
`;

export const HeroLogo = styled.div`
  // color: ${({ theme }) => theme.colors.mainHover};
  color: #30c987;
  font-size: 5rem;
  line-height: 5rem;
  margin-bottom: 0.8rem;
  max-height: 10rem;

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    font-size: 10rem;
    line-height: 10rem;
  }

  img {
    height: 8rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4rem 0;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      height: 10rem;
    }
    @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      width: 95%;
      height: 100%;
    }
  }
`;

export const BoxoutHero = styled.div`
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 0.5rem;
  padding: 2rem;
  min-height: auto;
  height: auto;
  h2,
  h3,
  p,
  ul {
    color: ${({ theme }) => theme.background};
  }
  a {
    color: ${(props) => props.theme.colors.boxoutlink};
  }
`;

export const BoxoutHeroMain = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 0.5rem;
  padding: 2.5rem;
  min-height: auto;
  height: auto;
  h2,
  h3,
  p,
  a,
  ul {
    color: ${({ theme }) => theme.background};
  }
`;

export const BoxoutQuickBrown = styled.div`
  display: inline-block;
  background-color: #c9c9c9;
  border-radius: 0.5rem;
  padding: 2.5rem;
  h2,
  h3,
  a,
  ul {
    color: ${(props) => props.theme.colors.ctaHover};
  }
`;

export const BackgroundHeroStyle = styled(HeroStyle)`
  @media (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    height: 30rem;
    min-height: 30rem;
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    height: 20rem;
    min-height: 20rem;
  }

  @media (max-width: ${({ theme }) => theme.mediaBoundaries.small}) {
    height: 15rem;
    min-height: 15rem;
  }
`;
