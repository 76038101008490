import React from "react";
import { ThemeProvider } from "styled-components";
import {
  CardIconBtnLink,
  CardIconStyle,
  CardTitle,
  ActivityType,
} from "../../../../GenericComponents/CardBoard/styles";
import { EActivityStatus } from "../../../../lib/Activities/IActivity";
import { useAppSelector } from "../../../../Redux/hooks";
import {
  ActivityStepsSection,
  ActivitySectionTitle,
  ActivityStepSection,
  CardStatusIconsWrapStyle,
  CardStatusIconStyle,
  ActivityStepsSectionInner,
  CardWithLogoStyle,
  CardOuterStyle,
  CardBtnTitleStyle,
} from "../../../../styles/app";
import { ButtonStyle, CTAButtonStyle } from "../../../../styles/button";
import { lockedactivityTheme } from "../../../../styles/themes";
import { unlockedactivityTheme } from "../../../../styles/themes";
import { TimeEstimatesLongBar } from "../../ActivityTimeEstimates";
import { IActivityCardProps } from "../../IActivityCardProps";
import { ActivityCompletionMessage } from "../../CommonComponents/ActivityCompletionMessage";
import { ActivityStatusIcon } from "../../CommonComponents/ActivityStatusIcon";
import { feedbackStepsDataDetective } from "../../CommonComponents/feedbackSteps";
import { FlexBox } from "../../../../styles/singlePageStyles";
import { TextFormatter } from "../../../TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { MappedActivitySteps, MappedFlowActivitySteps } from "../../CommonComponents/mapActivitySteps";
import { IDataDetectivesStep } from "../../../../lib/Activities/IDataDetectives";
import { TStepId } from "../../../../lib/Activities/IStep";
import { NoOfPeopleIcon } from "../../CommonComponents/NoOfPeopleIcon";
import { EquipmentNeededIcons } from "../../CommonComponents/EquipmentNeededIcons";
import { EEquipmentNeeded } from "../../../../lib/Activities/EEquipmentNeeded";
import { CompleteCountIcon } from "../../CommonComponents/CompleteCountIcon";
import { Link } from "react-router-dom";
import { ExpandedCard } from "../../CommonComponents/ExpandedCard";

export interface IDataDetectivesCardsProps extends IActivityCardProps {
  activitySteps: IDataDetectivesStep<any>[];
}

export interface IDataDetectivesCardsInnerProps extends IDataDetectivesCardsProps {
  loaded?: boolean;
}

export const DataDetectiveCard = ({
  id,
  description,
  title,
  imageUrl,
  timeEstimates,
  equipmentNeeded,
  noOfPeople,
  activityUserProgress: { activityStatus, attemptNo, isLocked } = {
    activityStatus: undefined,
    previousSteps: undefined,
    activityId: undefined,
  },
}: IDataDetectivesCardsInnerProps) => {
  return (
    <ThemeProvider theme={isLocked ? lockedactivityTheme : unlockedactivityTheme}>
      <Link to={`/app/activities/${id}`} style={{ textDecoration: "none" }}>
        <CardOuterStyle lockedactivity={isLocked ? "true" : undefined}>
          <ActivityType>Data Detectives</ActivityType>
          <CardWithLogoStyle
            data-testid="cardStyle"
            className="cardInnerWrap"
            lockedactivity={isLocked ? "true" : undefined}
          >
            {imageUrl && (
              <FlexBox left horiz>
                <CardIconBtnLink className="cardIconBtn">
                  <CardIconStyle src={imageUrl} alt={`Step by step activity: ${title}`} />
                </CardIconBtnLink>
              </FlexBox>
            )}
            <CardTitle data-testid="DataDetective-CardTitle">
              <CardBtnTitleStyle>{title}</CardBtnTitleStyle>
            </CardTitle>
            <CardStatusIconsWrapStyle>
              <CardStatusIconStyle className="activityStatusIcon">
                <ActivityStatusIcon
                  isLocked={isLocked}
                  activityStatus={activityStatus || EActivityStatus.NOT_STARTED}
                />
              </CardStatusIconStyle>
              <CardStatusIconStyle className="activityStatusIcon">
                <NoOfPeopleIcon count={noOfPeople} />
              </CardStatusIconStyle>
              {(equipmentNeeded && equipmentNeeded.length > 0 && equipmentNeeded[0] !== EEquipmentNeeded.NONE && (
                <CardStatusIconStyle className="activityStatusIcon">
                  <EquipmentNeededIcons equipmentNeeded={equipmentNeeded} />
                </CardStatusIconStyle>
              )) ||
                ""}
              {((activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1 > 0) && (
                <CardStatusIconStyle className="activityStatusIcon">
                  <CompleteCountIcon count={activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1} />
                </CardStatusIconStyle>
              )) ||
                ""}
            </CardStatusIconsWrapStyle>
            <TextFormatter text={description} keywordMap={keywordMap} />
            {timeEstimates && <TimeEstimatesLongBar timeEstimates={timeEstimates} />}
          </CardWithLogoStyle>
        </CardOuterStyle>
      </Link>
    </ThemeProvider>
  );
};

export const DataDetectiveCardExpanded = (props: IDataDetectivesCardsInnerProps) => {
  const {
    id,
    activitySteps,
    submitStep,
    goToStep,
    completeActivity,
    imageUrl,
    startActivity,
    feedbackNeeded,
    activityUserProgress: { activeStepId, activityStatus, stepsState, previousSteps, attemptNo },
  } = props;
  const userGroup = useAppSelector((state) => state.userState?.user.group);
  const monitorId = useAppSelector((state) => state.userState?.state?.monitor?.id);
  const currentRoom = useAppSelector((state) => state.userState?.state?.room?.data?.currentRoom);

  const handleGoToStep = React.useCallback(
    (stepId: TStepId) => {
      goToStep(id, stepId);
    },
    [goToStep, id]
  );
  const completedAllSteps = activityStatus >= EActivityStatus.IN_PROGRESS && activeStepId === null;
  const complete = activityStatus === EActivityStatus.COMPLETED;
  const started =
    [EActivityStatus.IN_PROGRESS, EActivityStatus.COMPLETED, EActivityStatus.FAILED].indexOf(activityStatus) > -1;

  const mappedActiveActivitySteps = React.useMemo(
    () =>
      started && !completedAllSteps
        ? MappedFlowActivitySteps({
            activitySteps,
            stepsState,
            activeStepId,
            previousSteps,
            monitorId,
            roomId: currentRoom?.id,
            activityId: id,
            submitStep,
            goToStep: handleGoToStep,
            lockCompleteSteps: true,
          })
        : [],
    [
      started,
      activitySteps,
      stepsState,
      activeStepId,
      previousSteps,
      monitorId,
      currentRoom?.id,
      id,
      submitStep,
      completedAllSteps,
      handleGoToStep,
    ]
  );

  const mappedFeedbackSteps = React.useMemo(
    () =>
      MappedActivitySteps({
        activitySteps: feedbackStepsDataDetective,
        stepsState,
        activeStepId,
        previousSteps,
        monitorId,
        roomId: currentRoom?.id,
        activityId: id,
        attemptNo,
        lockCompleteSteps: true,
        allowUnlockCompleteSteps: true,
        submitStep,
        goToStep: handleGoToStep,
        userGroup,
      }),
    [
      stepsState,
      activeStepId,
      previousSteps,
      monitorId,
      currentRoom?.id,
      id,
      attemptNo,
      submitStep,
      handleGoToStep,
      userGroup,
    ]
  );

  return (
    <ExpandedCard id={id} activityTypeName="Data detective" {...props}>
      {({ scrollToTitle }) =>
        started && (
          <ActivityStepsSection open={started} data-testid="activityStepsSection">
            <ActivityStepsSectionInner style={{ flexDirection: "column" }}>
              <ActivitySectionTitle>Activity Zone</ActivitySectionTitle>
              {((started && !activitySteps) || activitySteps.length === 0) && "MISSING STEPS"}
              <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                {mappedActiveActivitySteps}
              </div>
              {!complete && completedAllSteps && (
                <>
                  {feedbackNeeded && mappedFeedbackSteps}
                  <ActivityStepSection active style={{ padding: "1rem" }}>
                    <p>Congratulations you have completed the data detective activity!</p>
                    <p>Click the button below to see your new achievements.</p>
                    <FlexBox horiz>
                      <CTAButtonStyle onClick={() => completeActivity(id)}>Complete Activity</CTAButtonStyle>
                      <ButtonStyle onClick={() => goToStep(id, activitySteps[0].id)}>Go back to the start</ButtonStyle>
                    </FlexBox>
                  </ActivityStepSection>
                </>
              )}
              {complete && (
                <ActivityCompletionMessage
                  badgeImageUrl={imageUrl}
                  restartActivity={() => {
                    scrollToTitle();
                    startActivity(id, monitorId, currentRoom?.id);
                  }}
                />
              )}
            </ActivityStepsSectionInner>
          </ActivityStepsSection>
        )
      }
    </ExpandedCard>
  );
};
