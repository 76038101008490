/**
 * Deployment steps
 *
 * Linking Monitor===
 * Get monitor Status
 * If monitor Status is Unlinked show link monitor form
 * Link monitor form allows searching for all schools with VALIDATED status
 * Once school is selected we can link the school to a monitor
 * This will set the school status as LINKED and monitor status as LINKED
 *
 * If monitor Status is LINKED load school details
 * Show deploy monitor form with input for tracking number and free text box
 * Completing this form will mark monitor status as in transit
 *
 * Accessing School Status ===
 * If no monitor details are entered we can search for all schools that are REGISTERED or VALIDATING
 * Show button with Set school as VALIDATING or VALIDATED
 *
 */

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EFilterType, FilterOption } from "@react_db_client/constants.client-types";
import { AuthContext } from "react-oauth2-code-pkce";
import { IHeading } from "@react_db_client/components.search-and-select-v2";
import { IApiLinkMonitorArgs, apiMonitorDeployment } from "../../Api/apiMonitorDeployment";
import { IMonitorDeploymentForm } from "../../lib/formSubmissions/IRegisterMonitorFormSubmission";
import { useAppSelector } from "../../Redux/hooks";
import { CTAButtonStyle } from "../../styles/button";
import { FormStyle, SearchAndSelectStyles } from "../../styles/form";
import { InputWrap } from "../FormComponents/Input";
import { SelectAdvanced } from "../FormComponents/SelectAdvanced";
import { ESchoolStatus } from "../../lib/School/ESchoolStatus";
import { ISchoolDetailsPrivateSearchResult } from "../../lib/School/ISchoolDetails";
import { ErrorMessage } from "../../styles/app";

export interface MonitorDeploymentFormProps {
  serialNumber: string;
}

export const schoolInputValidation = {
  isSchool: (v) => (typeof v === "object" && v.id ? true : "Must select school from list."),
};

export const schoolFilters: { [key: string]: FilterOption } = {};

export interface ISchoolHeading extends IHeading {
  uid: keyof ISchoolDetailsPrivateSearchResult;
}

export const schoolHeadings: ISchoolHeading[] = [
  {
    uid: "schoolId",
    label: "School Id",
    type: EFilterType.text,
  },
  {
    uid: "schoolName",
    label: "School Name",
    type: EFilterType.text,
  },
];

export const schoolPreviewHeadings: ISchoolHeading[] = [
  {
    uid: "schoolId",
    label: "School Id",
    type: EFilterType.text,
  },
  {
    uid: "schoolName",
    label: "School Name",
    type: EFilterType.text,
  },
];

const MonitorDeploymentForm: React.FC<MonitorDeploymentFormProps> = ({ serialNumber }) => {
  const [message, setMessage] = React.useState<string>("");
  const [saveError, setSaveError] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [reloadKey, setReloadKey] = React.useState(0);
  const [selectedSchoolDetails, setSelectedSchoolDetails] = React.useState<ISchoolDetailsPrivateSearchResult>(null);
  const {
    user: { username },
  } = useAppSelector((state) => state.userState);
  const { token } = React.useContext(AuthContext);

  const onFormSubmit = async (data: IMonitorDeploymentForm) => {
    setMessage(`Submitting ${data.monitorSerialNumber}`);
    setSaveError("");
    setLoading(true);
    const submissionData: IApiLinkMonitorArgs = {
      monitorId: data.monitorSerialNumber,
      schoolId: data.school.id,
      // notes: data.notes,
    };
    return apiMonitorDeployment
      .linkMonitor(token)(submissionData)
      .then(() => {
        setMessage(
          `Monitor with id number: ${data.monitorSerialNumber} has been successfully registered to school: ${data.school.schoolName}`
        );
        setReloadKey((prev) => prev + 1);
      })
      .catch((e) => {
        console.log(e);
        setSaveError(`Failed to register Monitor-${e.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formData = useForm<IMonitorDeploymentForm>({
    reValidateMode: "onSubmit",
    defaultValues: {
      monitorSerialNumber: serialNumber,
      school: null,
      submittedBy: username,
      notes: "",
    },
  });

  const { handleSubmit, watch } = formData;

  const selectedSchoolId = watch("school", null);

  React.useEffect(() => {
    // Load Selected School data
    setError("");
    setMessage("Getting school data");
    typeof selectedSchoolId === "object" &&
      selectedSchoolId?.id &&
      apiMonitorDeployment
        .getSchool(token)(selectedSchoolId?.id)
        .then((schoolData) => {
          const loadedSchoolDetails: ISchoolDetailsPrivateSearchResult = {
            ...schoolData,
            ...schoolData.monitorRequest,
            monitorRequestId: schoolData.monitorRequest.id,
            status: ESchoolStatus.VALIDATED,
            uid: schoolData.schoolId,
            label: schoolData.monitorRequest.schoolName,
          };
          setSelectedSchoolDetails(loadedSchoolDetails);
        })
        .catch((e) => {
          console.info(e);
          setError("Error getting school data!");
        });
    setMessage("");
  }, [selectedSchoolId, token]);

  const getSchools = React.useCallback(
    async (filters, sortBy?: string, searchValue?: string) => {
      setError("");
      setMessage("Getting school data");
      const schools = await apiMonitorDeployment
        .searchSchools(token)(ESchoolStatus.VALIDATED, searchValue, false)
        .then((schoolsData) => schoolsData.map((d) => ({ ...d, uid: d.id })))
        .catch((e) => {
          console.info(e);
          setError("Error getting schools data!");
        });
      setMessage("");
      return schools as ISchoolDetailsPrivateSearchResult[];
    },
    [token]
  );

  return (
    <div title="Monitor Deployment Form" className="MonitorDeploymentForm_wrap">
      <FormProvider {...formData}>
        <FormStyle
          aria-label="Monitor Deployment Form"
          name="monitorDeploymentForm"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <p>* required</p>
          <InputWrap name="monitorSerialNumber" label="Monitor Serial Number" type="string" required />
          <p>Select from validated schools below.</p>
          <button onClick={() => setReloadKey((prev) => prev + 1)}>Reload</button>
          <SearchAndSelectStyles data-testid="schoolSearchContainer">
            <SelectAdvanced<ISchoolDetailsPrivateSearchResult>
              id="school"
              name="school"
              label="School"
              type="select"
              validateOnChange
              searchFunction={getSchools}
              required
              validate={schoolInputValidation}
              availableFilters={schoolFilters}
              headings={schoolHeadings}
              previewHeadings={schoolPreviewHeadings}
              showSearchField
              initialSearchValue=""
              allowFilters={false}
              reloadKey={reloadKey}
              key={reloadKey}
            />
          </SearchAndSelectStyles>
          <InputWrap name="submittedBy" label="Submitted By" type="string" readOnly required />
          {/* <InputArea name="notes" label="Notes" type="area" /> */}
          <CTAButtonStyle type="submit" disabled={loading}>
            {loading ? "Loading" : "Submit"}
          </CTAButtonStyle>
          <div>{message || "-"}</div>
          <div>{error || "-"}</div>
          <ErrorMessage>{saveError || "-"}</ErrorMessage>
          <div title="School Details" data-testid="monitorDeploymentForm_schoolDetails">
            <h2>School Details</h2>
            <div>First Name: {selectedSchoolDetails?.firstName}</div>
            <div>Last Name: {selectedSchoolDetails?.lastName}</div>
            <div>School Name: {selectedSchoolDetails?.schoolName}</div>
            <div>School Address Line 1: {selectedSchoolDetails?.schoolAddress1}</div>
            <div>School Address Line 2: {selectedSchoolDetails?.schoolAddress2}</div>
            <div>School Address Line 3: {selectedSchoolDetails?.schoolAddress3}</div>
            <div>School Address Line 4: {selectedSchoolDetails?.schoolAddress4}</div>
            <div>School Post Code: {selectedSchoolDetails?.schoolAddressPostCode}</div>
            <p>If alternate delivery address selected it will be shown below:</p>
            <div>Delivery School Address Line 1: {selectedSchoolDetails?.altSchoolAddress1}</div>
            <div>Delivery School Address Line 2: {selectedSchoolDetails?.altSchoolAddress2}</div>
            <div>Delivery School Address Line 3: {selectedSchoolDetails?.altSchoolAddress3}</div>
            <div>Delivery School Address Line 4: {selectedSchoolDetails?.altSchoolAddress4}</div>
            <div>Delivery School Post Code: {selectedSchoolDetails?.altSchoolAddressPostCode}</div>
          </div>
        </FormStyle>
      </FormProvider>
    </div>
  );
};

export default MonitorDeploymentForm;
