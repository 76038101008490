import React from "react";
import { Link } from "react-router-dom";
import { P, Section, SectionHeading, SubSectionHeading, FloatImageMax } from "../../styles/page";
import { BoxoutHero } from "../../styles/hero";
import { AExternal } from "../Links";

export const TvocsAndPmsInfoContent = (
  <>
    <Section>
      <SectionHeading id="tvocs-and-pm" className="hideFromGlossary">
        TVOCs (Total Volatile Organic Compounds) and PM (Particulate Matter)
      </SectionHeading>
      <br className="hideFromGlossary" />
      <br className="hideFromGlossary" />
      <SubSectionHeading id="why-so-many-details-for-co2-compared-to-tvocs-and-pm">
        Why so many details for CO<sub>2</sub> compared to TVOCs and PM?
      </SubSectionHeading>
      <FloatImageMax src="/images/monitor-leds/boy_reading.jpg" alt="Young boy by open window" />
      <P>
        The reason we provide more detail on how to interpret carbon dioxide (CO<sub>2</sub>) measurements is not
        because it's our favourite indoor air quality metric, it's because the CO<sub>2</sub> data is easier to
        interpret. In most classrooms, people are the main source of CO<sub>2</sub> and the CO<sub>2</sub> level can be
        reduced by increasing ventilation. This means CO<sub>2</sub> is a good indicator of the relative ventilation
        level in your classroom (no wonder we talk about it so much!). Read more about how you can use the CO
        <sub>2</sub> data to inform decisions about ventilation in our{" "}
        <Link to="/resources/ventilation">ventilation guidance for schools</Link>.
      </P>
      <P>Your SAMHE monitor also measure two other aspects of your classroom air quality - VOCs and PM. </P>
    </Section>
    <Section>
      <BoxoutHero>
        <SubSectionHeading id="health-issues-linked-to-vocs-and-pm">
          Health issues linked to VOCs and PM
        </SubSectionHeading>
        <FloatImageMax src="/images/monitor-leds/cough.jpg" alt="Cough" />
        <P>
          Volatile organic compounds (VOCs, the 'T' on your monitor's screen stands for 'total' in recognition of the
          fact that the monitors are designed to detect all VOCs) and particulate matter (
          <Link to="/resources/monitor-leds#what-levels-of-pm25-are-high">
            PM, or in our case specifically PM<sub>2.5</sub>
          </Link>
          ) are important too. Exposure to PM and certain VOCs can be linked to negative health effects (ranging from
          sore eyes, an irritating cough, to more serious outcomes). However the linkages are statistical, which means
          that it is hard to infer the effects on any individuals from any particular event. In part this is because
          measurements of VOCs and PM by their very nature encompass a wide variety of things, and not all of these are
          equally harmful, or even inherently harmful.
        </P>
        <P>
          To accurately assess any risk within your classrooms, you would need to identify every single compound in the
          air, and its level everywhere in your classroom at all times you are there. The SAMHE monitors (indeed, even
          the latest and greatest scientific equipment!) cannot do that.
        </P>
      </BoxoutHero>
    </Section>
    <Section>
      <SubSectionHeading id="what-are-vocs-and-pm-and-where-do-they-come-from">
        What are VOCs and PM and where do they come from?
      </SubSectionHeading>
      <FloatImageMax src="/images/monitor-leds/whiteboard-pens-eraser.jpg" alt="whiteboard pens eraser" />
      <P>
        VOCs are gases that have evaporated from certain liquids or surfaces. There are a variety of VOCs and many are
        human-made chemicals that are used and produced in the manufacture of paints, pharmaceuticals, and refrigerants.
        Some VOCs are harmful, such as chemicals given off by some cleaning products, paints and new furniture. Some are
        relatively harmless, for example trees give off VOCs and so does orange juice.
      </P>
      <FloatImageMax src="/images/monitor-leds/chalk-dust-coloured.jpg" alt="chalk dust coloured" />
      <P>
        It is a similar story for particulate matter (PM). PM<sub>2.5</sub> means all particles (including liquid and
        solid particles) which are smaller than about 2.5 microns and floating in the air. 2.5 microns is very small - a
        single hair from your head is more than 30 times as wide as that - so you can only see them with a microscope!
        Some PM is naturally produced e.g. grains of pollen from plants, dust (which includes soil and dead skin cells
        suspended in the air), mould spores and salt from sea spray. Other PM is produced by human activities such as
        driving (from tyre and brake wear and exhaust emissions), construction, agricultural processes and both
        industrial and domestic fuel burning. In schools, chalk dust can be a source of PM. Sweeping or vacuuming can
        re-release PM into the air.
      </P>
      <P>
        It is for these reasons that we need to be more cautious when giving advice about VOCs and PMs. What we do know
        is that your body does not need to breathe in VOCs or PMs, so it seems sensible to avoid high levels of them in
        general. Concentrations of many VOCs are consistently higher indoors (up to ten times higher) than outdoors so
        ventilation can help dilute them. PM levels are also higher indoors in many situations (though maybe not next to
        a very busy road). Remember our obsession with using CO<sub>2</sub> readings to help make decisions about
        ventilation.
      </P>
    </Section>
    <Section>
      <SubSectionHeading id="what-levels-of-tvoc-are-high">What levels of TVOC are 'high'?</SubSectionHeading>
      <FloatImageMax src="/images/monitor-leds/Spray_bottle.jpg" alt="disinfectant spray" />
      <P>
        The UK Health and Safety Executive (HSE) publishes{" "}
        <AExternal href="https://www.hse.gov.uk/coshh/basics/exposurelimits.htm">Workplace Exposure Limits</AExternal> -
        upper limits for an acceptable level of exposure - for a lot of chemicals, but have never published limits for
        TVOCs. Neither does the UK Health Security Agency (UKHSA) but it does publish{" "}
        <AExternal href="https://www.gov.uk/government/publications/air-quality-uk-guidelines-for-volatile-organic-compounds-in-indoor-spaces">
          guidelines for volatile organic compounds in indoor spaces
        </AExternal>{" "}
        However, that only refers to very specific types of VOCs that your SAMHE monitor is not designed to measure
        individually. The{" "}
        <AExternal href="https://www.gov.uk/government/publications/ventilation-approved-document-f">
          Approved Document F
        </AExternal>{" "}
        (which applies to England with equivalent documents published for other parts of the UK) details building
        regulations 'for the ventilation requirements to maintain indoor air quality' and provides a limit for TVOCs of
        300 µg/m<sup>3</sup> (for exposures of 8 hours) - this is based on levels recommended by the World Health
        Organisation - but this comes with the caveat that TVOC: "should not be used as a direct indicator of health".
        Guidance and standards in other countries suggest different values. As such, we cannot suggest any guideline
        values when you are looking at TVOC data from your SAMHE monitor.
      </P>
      <P>
        So what good are the TVOC measurements? You will see your SAMHE monitor does not report TVOCs as concentration
        but as levels within a relative index 'Ind30', the '30' refers to the model of TVOC sensor used within the SAMHE
        monitors. So don't worry too much about the absolute values, we suggest that, for each space you investigate,
        you can look at periods of time when the TVOC measurements are relatively low or high and consider what might
        have caused that. Look especially for short 'spikes' in the TVOC data and have a think about what might be
        happening within the space at, or just before, these times; try using the{" "}
        <Link to="/app/activities/24">SAMHE Be a detective activity</Link> to help you. These spikes might occur when
        classroom cleaning happens, when people use products like deodorant, or when certain foods are eaten - anything
        that creates a strong smell might lead to a spike.
      </P>
    </Section>
    <Section>
      <SubSectionHeading id="what-levels-of-pm25-are-high">
        What levels of PM<sub>2.5</sub> are 'high'?
      </SubSectionHeading>
      <FloatImageMax src="/images/monitor-leds/vaccum-cleaner-carpet.jpg" alt="vacuum cleaner carpet" />
      <P>
        The UK Health and Safety Executive (HSE) does not include Particulate Matter when publishing{" "}
        <AExternal href="https://www.hse.gov.uk/coshh/basics/exposurelimits.htm">Workplace Exposure Limits</AExternal>,
        and the UK Health Security Agency (UKHSA) does not publish limit values either. This is partly because most of
        the research linking PM levels to health outcomes has been carried out based on measurements made outdoors (in
        'ambient' air). Outdoor PM levels, and the sources of PM outdoors, can be quite different to those indoors. For
        outdoor air, the UK's Department for the Environment and Rural Affairs (DEFRA) publishes 'Air Quality
        Objectives' which for PM<sub>2.5</sub> specifies an annual mean value of 20 ug/m<sup>3</sup> (10 ug/m
        <sup>3</sup> for Scotland) and the World Health Organisation (WHO){" "}
        <AExternal href="https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health">
          Air quality guideline values
        </AExternal>{" "}
        specify a value of 15 ug/m<sup>3</sup> (for a daily average, and 5 ug/m<sup>3</sup> for the annual average).
        From this you can see that there are already mixed messages in relation to PM<sub>2.5</sub> levels for outdoor
        air, whereas our focus is helping you to monitor the indoor air within your school. No guideline values for
        particulate matter have been published based on measurements of PM<sub>2.5</sub> exposures indoors.
      </P>
      <P>So what good are the PM measurements?</P>
      <P>
        When we plot your data for PM<sub>2.5</sub> we have included (horizontal) grey lines at 5, 10, 15, and 20 ug/m
        <sup>3</sup> corresponding to the maximum average values (per day or year) recommended by WHO, and those
        specified by different UK agencies. These values differ quite a lot but if the average levels inside your school
        remain high relative to these guideline values, or you'd just like to try to understand the readings from within
        your school a little better, then try using <Link to="/app/activities/24">SAMHE Be a detective activity</Link>{" "}
        to help you investigate.
      </P>
    </Section>
  </>
);

export const TvocsAndPmsInfo = () => {
  return TvocsAndPmsInfoContent;
};
