import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAsync } from "react-use";
import { Link } from "react-router-dom";
import { apiSchoolAdmin } from "../Api/apiSchoolAdmin";
import { SchoolAdminForm } from "../components/Forms/SchoolAdminForm";
import { ConstrainedPageStyle, PageTitle, Section, P, Ul, SectionHeading } from "../styles/page";
import { RoomListForm } from "../components/Forms/RoomListForm";

const SchoolAdminPage: React.FC = () => {
  const { token } = React.useContext(AuthContext);
  const schoolAdminData = useAsync(async () => apiSchoolAdmin.get(token), [token]);

  return (
    <ConstrainedPageStyle className="page_wrap samheAppWrapper">
      <PageTitle>Your SAMHE School Admin Page!</PageTitle>
      <Section>
        <P>Welcome to your SAMHE Admin page. Using this page you can:</P>
        <Ul>
          <li>Add to your school's Room List</li>
          <li>Remind yourself of your school's School Handle and Secret Words</li>
          <li>Update your school's School handle and/or Secret Words</li>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>School Details Form</SectionHeading>
        <RoomListForm />
        {schoolAdminData.value && <SchoolAdminForm schoolAdminData={{ ...schoolAdminData.value }} />}
        {!schoolAdminData.value ? (
          <p>
            Error loading school details. Please refresh your page or go to <Link to="/support">SAMHE Support</Link>
          </p>
        ) : (
          ""
        )}
      </Section>
      <Section>
        <P>
          Thank you for keeping these records up to date. Your school's Room List enables us to collect vital
          information about which room within your school the air quality data recorded by the SAMHE monitors comes
          from. The entries you provide will be used by all SAMHE users within your school to connect any data that they
          input, or examine, to a particular room within the school.
        </P>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default SchoolAdminPage;
