
import Routes from "./routes";
import "./styles/css/style.scss";
import useGoogleAnalytics from "./utils/hooks/useGoogleAnalytics"

function App() {
  useGoogleAnalytics();

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
