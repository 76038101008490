import styled from "styled-components";

export interface ISelectDropdownProps<IdType extends string | number>
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "id"|"onChange"> {
  id: string;
  selectOptions: {
    id: IdType;
    label: string;
  }[];
  onChange: (newValue: IdType) => void;
  value: IdType;
}

const SelectStyle = styled.select`
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
`;

export const SelectDropdown = <IdType extends string | number>({
  selectOptions,
  onChange,
  value,
  ...additionalProps
}: ISelectDropdownProps<IdType>) => (
  <SelectStyle value={value} onChange={(e) => onChange(e.target.value as IdType)} {...additionalProps}>
    {selectOptions?.map(({ id, label }) => (
      <option value={id} key={id}>
        {label}
      </option>
    ))}
  </SelectStyle>
);
