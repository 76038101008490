import React from "react";
import { DataDashboard } from "../../components/DataViewDashboard";
import { AppPageStyle } from "../../styles/app";

const DataViewsPage: React.FC = () => {
  return (
    <AppPageStyle data-testid="samheAppContent">
      <div data-testid="appPage-dataViews">
        <DataDashboard />
      </div>
    </AppPageStyle>
  );
};

export default DataViewsPage;
