// shuffle array
export const shuffleArray = <T,>(array: T[]): T[] => {
  // Loop through the array from the last element to the first
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const randomIndex = Math.floor(Math.random() * (i + 1));

    // Swap elements at randomIndex and i to shuffle the array
    [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
  }

  // Return the shuffled array
  return array;
};
