import { Link } from "react-router-dom";
import { BorderedTable, Ol, P, Ul } from "../../styles/page";
import { ETechSupportGroup, ITechSupportItem } from "./lib";
import { AExternal, DownloadLink } from "../Links";

const techSupportEntries: ITechSupportItem[] = [
  /**
   * General
   */
  {
    uid: "what_do_the_monitor_numbers_mean",
    label: "What do the monitor numbers mean?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          For help making sense of the measurements coming from your monitor take a look at our{" "}
          <Link to="/resources/monitor-leds">guidance on interpreting monitor readings</Link>.
        </P>
        <P>
          You may also like to refer to our <Link to="/resources/key-definitions">'key definitions' page</Link> which
          defines terms used across the SAMHE Web App and website.
        </P>
      </div>
    ),
  },
  {
    uid: "what_do_the_monitor_lights_mean",
    label: "What do the monitor lights mean?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          Our{" "}
          <DownloadLink href="/resources/SAMHE_Monitors_LED_guide_normal_operation.pdf">
            guide to SAMHE monitor LEDs
          </DownloadLink>{" "}
          will help you make sense of the different light combinations <b>during normal operation</b>.
        </P>
        <P>
          (NOTE - the lights work differently during setup, so use the{" "}
          <DownloadLink href="/resources/samhe_monitor_troubleshooting_guide.pdf">
            guide to SAMHE Monitor LED's during setup
          </DownloadLink>{" "}
          (.pdf) at that stage).
        </P>
      </div>
    ),
  },
  {
    uid: "how_can_i_check_if_the_readings_are_accurate",
    label: "How can I check if the readings are accurate?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          If you have reason to believe that your monitor readings are inaccurate (e.g. because they change very little
          in response to changes in the classroom environment) please take a look at our{" "}
          <Link to="/resources/monitor-placement-guide">monitor placement and accuracy page</Link>.
        </P>
        <P>
          This page outlines important considerations regarding where you should put your monitor to ensure accurate
          readings, and describes calibration checks.
        </P>
        <P>
          If you have carried out these processes and you are still convinced that your monitor readings are inaccurate
          please report your concerns giving as much detail as possible using our{" "}
          <Link to="/support/tech-contact">technical query form</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "will_my_monitor_work_without_wifi",
    label: "Will my monitor work without WiFi?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          You will need to connect to the WiFi to set up your monitor. If, while plugged in, your monitor loses WiFi
          connection it will only show current readings on the LED screen. Our monitors have no offline storage so
          please note that these readings cannot be communicated to the Web App upon reconnection.
        </P>
      </div>
    ),
  },
  {
    uid: "should_the_monitor_ever_be_switched_off",
    label: "Should the monitor ever be switched off?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          Ideally not! It's really important for our researchers to know what air quality is like when classrooms are
          not occupied (and your pupils might find it interesting too!). So{" "}
          <b>
            please leave your SAMHE monitors switched on at all times, including outside of school hours and over school
            holidays.
          </b>
        </P>
      </div>
    ),
  },
  {
    uid: "im_moving_the_monitor_do_you_have_any_advice_on_where_to_put_it",
    label: "I'm moving the monitor, do you have any advice on where to put it?",
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          Absolutely. Please refer to the guidance on our{" "}
          <Link to="/resources/monitor-placement-guide">monitor placement and accuracy page</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "if_the_monitor_lights_go_purple_is_the_co2_level_dangerous",
    label: (
      <>
        If the monitor lights go purple is the CO<sub>2</sub> level dangerous?
      </>
    ),
    group: ETechSupportGroup.GENERAL,
    content: (
      <div>
        <P>
          During normal operation your SAMHE monitor lights turn purple when CO<sub>2</sub> levels in excess of 3,000
          parts per million (ppm) are detected.
        </P>
        <P>
          It is important to note that even at this level, CO<sub>2</sub> is not dangerous to our health, it is very
          different to poisonous gases, such as carbon monoxide. However, lower CO<sub>2</sub> levels have been shown to
          help with our concentration and improving the ventilation could also help with students' learning.
        </P>
        <P>
          If your monitor CO<sub>2</sub> readings are consistently over 3,000 ppm this could indicate that the classroom
          ventilation needs improving. Please seek support from your Senior Leadership Team. You may find it useful to
          discuss our <Link to="/resources/ventilation">ventilation guidance</Link> with them.
        </P>
      </div>
    ),
  },

  /**
   * Connection support
   */
  {
    uid: "my_school_uses_ethernet_cables_for_internet_connection_how_can_i_set_up_my_monitor",
    label: "My school uses ethernet cables for internet connection, how can I set up my monitor?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          If your computer does not have a WiFi adapter or you have difficulty accessing your computer's WiFi manager
          (sometimes this will have been disabled by the school), we recommend that you use another WiFi enabled device
          such as your phone or a tablet to carry out the monitor connection process.
        </P>
        <P>
          SAMHE monitors rely on WiFi connectivity to transmit the indoor air quality measurements they detect - if your
          school's WiFi signal is patchy or poor you may have difficulty accessing your readings.
        </P>
      </div>
    ),
  },
  {
    uid: "i_cant_see_my_wifi_network_in_the_options_listed_whats_going_wrong",
    label: "I can't see my WiFi network in the options listed. What's going wrong?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          SAMHE monitors are only able to connect to 2.4 GHz WiFi networks. The monitor cannot detect or connect to 5
          Ghz networks.
        </P>
        <P>
          Lots of routers are 'dual band routers' meaning that they support both 2.4 GHz and 5 GHz channels. If you have
          a dual band router, the different bands may have different names or you may need to manually specify which
          band you want the monitor to connect to.
        </P>
      </div>
    ),
  },
  {
    uid: "i_need_to_whitelist_my_monitor_where_can_i_find_my_monitors_mac_address",
    label: "I need to whitelist my monitor. Where can I find my monitor's MAC address?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          If you require your monitor's MAC address you can find it by transforming your monitor serial number into
          pairs of digits. Your monitor serial number is 12 digits long and may contain numbers and letters. E.g. The
          mac address for monitor number 12a345b67c8d would be 12:a3:45:b6:7c:8d.
        </P>
        <P>
          The monitor serial number is located on the reverse of the monitor where it says S/N. See image below for
          reference.
        </P>
        <img src="/images/tech-support/monitor_qr_label.png" alt="monitor QR label" />
      </div>
    ),
  },
  {
    uid: "my_school_uses_a_proxy_serverfirewallbroadband_ssl_certificates_what_are_the_sites_i_need_to_add_to_the_schools_safelist",
    label:
      "My school uses a proxy server/firewall/broadband SSL certificates, what are the sites I need to add to the school's safelist?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          The URLs in use by the monitor are https://hw.airgradient.com and https://hw-int.airgradient.com. No special
          ports are in use besides https (port 443).
        </P>
        <P>
          Please note these URLs are API endpoints with which the monitor communicates rather than links designed for
          you to view. The monitor uses self signed certificates for communication and, as a result, some browsers may
          show errors e.g. connection not private or certificate invalid. This is not unusual and can safely be ignored.
        </P>
      </div>
    ),
  },
  {
    uid: "ive_tried_to_connect_my_monitor_and_i_got_a_row_of_red_lights_what_could_be_going_wrong",
    label: "I've tried to connect my monitor and I got a row of red lights, what could be going wrong?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          A row of red lights means there may be a problem with the WiFi network name or password entered, or another
          local issue which is preventing your monitor accessing your WiFi. If you've got a full row of red lights here
          are some ideas about things you can check:
        </P>
        <Ul>
          <li>Is the WiFi network name entered correctly?</li>
          <li>
            Is the WiFi password entered correctly?
            <br />
            <br />
            <i>
              If you have entered both WiFi network name and password correctly, it's worth bearing in mind that some
              schools we have worked with operate a separate, parallel, WiFi network for IoT devices (such as
              interactive whiteboards, for example) which have different security credentials to the one you might use
              as a member of staff when connecting to the internet. If in order to access your school's internet you
              have to enter personal log in details this may well be the case. These separate networks for IoT devices
              may not be detectable when scanning for nearby networks and the network name may need to be entered
              manually. Typically your IT technician will control access.
            </i>
            <br />
            <br />
          </li>
          <li>
            Is your WiFi network working as expected?
            <br />
            <br />
            <i>
              To confirm whether your WiFi network is working normally, look at the WiFi settings on another internet
              enabled device such as a smartphone or laptop. Are they able to connect to the same WiFi network? Can you
              use the internet using your other device?
            </i>
            <br />
            <br />
          </li>
        </Ul>
        <P>
          If you have checked each of these things and you are still not having any luck please consult your school's IT
          technician or get in touch with us using our <Link to="/support/tech-contact">technical query form</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "ive_tried_to_connect_my_monitor_and_i_got_a_row_of_orange_lights_what_could_be_going_wrong",
    label: "I've tried to connect my monitor and I got a row of orange lights, what could be going wrong?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          A row of orange lights means that your monitor is able to connect to your school's WiFi but the server is not
          reachable.
        </P>
        <P>
          If you've got a full row of orange lights we advise that you contact your school's IT technician as this
          outcome covers a range of scenarios which they will be best placed to diagnose. For example:
        </P>
        <Ul>
          <li>
            At some schools it is standard practice to 'whitelist' devices. Whitelisting is a means of giving your
            system details about your monitor and adding it to a list of 'trusted' devices which are allowed access to
            your network. You will need to talk to your school's IT technician about the process for doing this. If you
            require your monitor's mac address take a look at our{" "}
            <Link to="#i_need_to_whitelist_my_monitor_where_can_i_find_my_monitors_mac_address">
              guidance on where to find it
            </Link>
            .
          </li>
          <li>
            Sometimes schools need to communicate details about trusted devices to their internet service provider. In
            these instances you may need to know your monitor's IP address, which you can find through specific programs
            using your monitor's ID number.
          </li>
        </Ul>
        <P>
          If your IT technician is unable to diagnose the problem please encourage them to get in touch with us using
          our <Link to="/support/tech-contact">technical query form</Link>.
        </P>
      </div>
    ),
  },
  {
    uid: "ive_tried_to_connect_my_monitor_and_i_got_a_row_of_purple_lights_what_could_be_going_wrong",
    label: "I've tried to connect my monitor and I got a row of purple lights, what could be going wrong?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          A row of purple lights means that the server is reachable but the monitor has not been configured correctly.
          In this instance you should contact the SAMHE team using our{" "}
          <Link to="/support/tech-contact">technical query form</Link> and tell us your monitor ID number (which can be
          found on the back of the monitor where it says S/N - your monitor ID number is 12 digits long and may include
          numbers and letters).
        </P>
      </div>
    ),
  },
  {
    uid: "my_monitor_cannot_connect_to_my_normal_network_how_can_i_test_whether_it_is_working_ok",
    label: "My monitor cannot connect to my normal network, how can I test whether it is working ok?",
    group: ETechSupportGroup.CONNECTION_SUPPORT,
    content: (
      <div>
        <P>
          If you cannot connect to your WiFi network it may be helpful to try connecting to a mobile hotspot to
          eliminate any issues with the monitor, but be aware that if you remain connected via mobile data this may
          incur a cost.
        </P>
        <P>
          If you're unable to connect your monitor to your mobile hotspot this could suggest that the issue is the
          monitor. In this case, please <Link to="/support/tech-contact">contact us</Link>.
        </P>
      </div>
    ),
  },

  /**
   * Logging in
   */
  {
    uid: "where_do_i_find_my_login_details",
    label: "Where do I find my login details?",
    group: ETechSupportGroup.LOGGING_IN,
    content: (
      <div>
        <P>
          Different SAMHE accounts require different login details. Please refer to the relevant portion of the table
          below to confirm which details are required for each account type.
        </P>
        <BorderedTable>
          <tr>
            <th></th>
            <th>Information required to register an account</th>
            <th>Information required to log in</th>
          </tr>

          <tr>
            <th>Admin user</th>
            <td>
              <P>
                <b>School ID</b>
                <br />
                An 8 digit code containing letters and numbers. All participating schools are sent a school ID via email
                from the SAMHE team. Can't find it? <Link to="/support/tech-contact">Contact us</Link>.
              </P>
              <P>
                <b>Generic email address</b>
                <br />
                When completing the <Link to="/register/school">registration form</Link> all schools are asked to
                provide a generic (non-personal) email with which their school's account can be associated.
              </P>
            </td>
            <td>
              <P>
                <b>Email address</b>
                <br />
                This is the generic (non-personal) email provided via the{" "}
                <Link to="/register/school">registration form</Link>.
              </P>
              <P>
                <b>Password</b>
                <br />
                Admin passwords are set by the admin user when the account is created. Forgotten passwords can be reset
                via an email link.
              </P>
            </td>
          </tr>

          <tr>
            <th>Teacher account</th>
            <td>
              <P>
                <b>School Handle</b>
                <br />
                School Handles are set by the school's admin user during set up and{" "}
                <Link to="/school/admin">can be changed by the admin at any time</Link>.
              </P>
              <P>
                <b>Teacher 'Secret Word'</b>
                <br />
                Teacher 'Secret Words' are set by the school's admin user during set up and{" "}
                <Link to="/school/admin">can be changed by the admin at any time</Link>.
              </P>
            </td>
            <td>
              <P>
                <b>Email address</b>
                <br />
                Teacher's email address.
              </P>

              <P>
                <b>Password</b>
                <br />
                Teacher account passwords are set by the user when the account is created. Forgotten passwords can be
                reset via an email link.
              </P>
            </td>
          </tr>

          <tr>
            <th>Student account</th>
            <td>
              <P>
                <b>School Handle</b>
                <br />
                School Handles are set by the school's admin user during set up and{" "}
                <Link to="/school/admin">can be changed by the admin at any time</Link>.
              </P>
              <P>
                <b>Student 'Secret Word'</b>
                <br />
                Student 'Secret Words' are set by the school's admin user during set up and{" "}
                <Link to="/school/admin">can be changed by the admin at any time</Link>.
              </P>
            </td>
            <td>
              <P>
                <b>Username</b>
                <br />
                Student usernames are set by the user when the account is created.
              </P>
              <P>
                <i>
                  NOTE. If a student forgets their username there is no way for this to be recovered. A new account must
                  be created.
                </i>
              </P>
              <P>
                <b>Student 'Secret Word'</b>
                <br />
                Student 'Secret Words' are set by the school's admin user during set up and{" "}
                <Link to="/school/admin">can be changed by the admin at any time</Link>.
              </P>
            </td>
          </tr>
        </BorderedTable>
      </div>
    ),
  },

  {
    uid: "how_can_i_set_up_staff_accounts",
    label: "How can I set up staff accounts?",
    group: ETechSupportGroup.LOGGING_IN,
    content: (
      <div>
        <P>
          Any staff member in a SAMHE school can have an individual 'teacher' account for the Web App. This gives you
          full access to Web App activities and monitor data and could be helpful for planning lessons. You can also
          access additional resources in the Web App library, not available to pupils, such as certificates and posters.
        </P>
        <P>To set up a teacher account:</P>
        <Ol>
          <li>
            Ask your school's Admin user for the school handle and teacher secret word. (They can get a reminder of what
            they are, or reset them, using the <Link to="/school/admin">School Admin page</Link>.)
          </li>
          <li>
            Go to the <Link to="/app">Web App landing page</Link> and click 'Register a new user'.
          </li>
          <li>
            On the registration page, select 'teacher' and read through the participant information sheet (linked at the
            bottom of the page). If you're happy to proceed with creating an account, enter the school handle, teacher
            secret word and your email address, and choose a password.
          </li>
          <li>Check at least the top two boxes (these are compulsory) and submit.</li>
        </Ol>
        <P>Now you're ready to log in! Next you need to:</P>
        <Ol start={5}>
          <li>Toggle to 'Login' in the header and ensure 'Teacher/Admin' is selected.</li>
          <li>Enter your email and the password you just created!</li>
        </Ol>
        <P>Please note: teacher accounts can access all of the same resources as Admin users.</P>
      </div>
    ),
  },

  {
    uid: "how_can_i_set_up_pupil_accounts",
    label: "How can I set up pupil accounts?",
    group: ETechSupportGroup.LOGGING_IN,
    content: (
      <div>
        <P>
          All pupils at SAMHE schools can have individual accounts for the SAMHE Web App.This enables them to explore
          the monitor data themselves, do activities individually and track their achievements. It also allows you to
          set SAMHE activities as homework.
        </P>
        <P>
          <b>Important notes about pupil accounts</b>
        </P>
        <Ul>
          <li>
            <b>
              When pupils first log in to their pupil account, they are guided to complete the five 'Understanding your
              SAMHE monitor' quizzes
            </b>{" "}
            before moving on to other activities. This is to ensure they have a basic understanding of what the air
            quality readings mean, and to mitigate against any misconceptions and concerns they might have about air
            quality in their classroom. The other activities are 'locked' until these quizzes are completed, however
            teachers can override this using completion codes if they feel there is a more appropriate place for their
            pupils to start.
          </li>
          <li>
            <b>Individual accounts may not be appropriate for all pupils.</b> Teachers should make their own assessment
            of whether to give pupils their own accounts.
          </li>
          <li>
            Depending on your school's policy, you may need to communicate with parents/guardians <b>before</b> you set
            up pupil accounts. Our '
            <DownloadLink href="/resources/SAMHE_Parent_information_pack.pdf">parent information pack</DownloadLink>'
            contains templates to support this. Please use these in combination with your usual processes and adapt the
            templates as required.
          </li>
        </Ul>
        <P>To set up a pupil account, you need to:</P>
        <Ol>
          <li>
            Ask your school's Admin user for the school handle and pupil secret word. (They can get a reminder of what
            they are, or reset them, using the <Link to="/school/admin">School Admin page</Link>.)
          </li>
          <li>Give these to the pupil.</li>
        </Ol>
        <P>Then the pupil needs to:</P>
        <Ol>
          <li>
            Go to the <Link to="/app">Web App landing page</Link> and click 'Register a new user'.
          </li>
          <li>
            On the registration page, check 'student' is selected and watch the embedded information video for their age
            group. (Transcripts are available if preferred).
          </li>
          <li>
            If they are happy to proceed, pupils should enter the school handle and pupil secret word and create a
            username to secure their account. Then tick the boxes and submit.
          </li>
        </Ol>
        <P>
          <b>IMPORTANT NOTE about choosing a username</b>: It should not include pupils' name or any part of it, or
          anything else that could identify them. A good combination is often a colour and an animal.
        </P>
        <P>Now pupils are ready to log in! Next they need to:</P>
        <Ol start={4}>
          <li>Toggle to 'Login' in the header and ensure 'Student' login is selected.</li>
          <li>Enter the school handle and the username they just created!</li>
        </Ol>
        <P>
          These instructions are also available in video format:{" "}
          <AExternal href="https://youtu.be/iu1jYjgkjSA">How to set up pupil accounts</AExternal>.
        </P>
      </div>
    ),
  },

  /**
   * Help centre material - 'other'
   */
  {
    uid: "my_monitor_is_displaying_a_lot_of_red_lights_but_there_are_readings_on_the_led_screen_is_it_connected",
    label: "My monitor is displaying a lot of red lights but there are readings on the LED screen, is it connected?",
    group: ETechSupportGroup.OTHER,
    content: (
      <div>
        <P>
          There are two occasions when you may see red lights on your monitor. The first is if, during monitor
          connection, WiFi connection has failed. If this is true your monitor screen will read 'WiFi not connected.'
        </P>
        <P>
          However, red lights can also appear if you have worked through the connection process, successfully connected
          to the WiFi, and CO<sub>2</sub> levels are high. In this situation you will be able to see readings on your
          monitor screen. High readings of CO<sub>2</sub> are expected during connection or at other times when you are
          breathing close to the monitor. To confirm successful connection, you can{" "}
          <Link to="/app">login to the Web App</Link> and review your live data.
        </P>
        <div style={{ display: "flex", gap: "2rem" }}>
          <div style={{ flex: "1" }}>
            <img src="/images/tech-support/red_light_scenario_1.png" alt="Red light scenario 1" />
            <P>
              In scenario 1 'Unsuccessful connection', monitor displays a full row of red lights. Monitor screen reads
              'WiFi not connected'.
            </P>
          </div>
          <div style={{ flex: "1" }}>
            <img src="/images/tech-support/red_light_scenario_2.png" alt="Red light scenario 2" />
            <P>
              In scenario 2: 'Successful connection', monitor displays between 6 and 8 red lights. This indicates CO
              <sub>2</sub> levels between 1,500 - 3,000 parts per million (ppm). Monitor screen will display readings
              for each of the metrics measured (CO<sub>2</sub>, PM<sub>2.5</sub>, TVOCs, temperature and relative
              humidity)
            </P>
          </div>
        </div>
      </div>
    ),
  },
  {
    uid: "do_you_conduct_pat_tests_on_the_monitors_prior_to_sending_them",
    label: "Do you conduct PAT tests on the monitors prior to sending them?",
    group: ETechSupportGroup.OTHER,
    content: (
      <div>
        <P>
          No. Your school will have it's own PAT testing policy and you should make the device available for PAT testing
          in line with this policy as required.
        </P>
        <P>
          Should your IT department or senior management team require documentation on the monitor for their records,
          please refer them to the information leaflet in the monitor box. This document confirms conformity with the CE
          regulatory standard.{" "}
        </P>
        <P>
          For advice on the safe usage of the monitor please refer to our{" "}
          <DownloadLink href="/resources/SAMHE_Monitors_Standard_Operating_Procedure_Staff.pdf">
            monitor standard operating procedures
          </DownloadLink>
          .
        </P>
      </div>
    ),
  },
  {
    uid: "how_much_electricity_does_the_monitor_use",
    label: "How much electricity does the monitor use?",
    group: ETechSupportGroup.OTHER,
    content: (
      <div>
        <P>
          SAMHE monitors require around 15 kWh energy per year - that's about £3.65 per year, using the UK price cap
          from Jun/24.
        </P>
      </div>
    ),
  },

  {
    uid: "my_rooms_electrics_are_turned_off_overnight_using_a_kill_switch_how_can_i_keep_the_samhe_monitor_connected",
    label:
      "My room's electrics are turned off overnight using a 'kill switch'. How can I keep the SAMHE monitor connected?",
    group: ETechSupportGroup.OTHER,
    content: (
      <div>
        <P>
          We know that science labs in particular often use a 'kill switch' to turn off all electrical items overnight,
          for safety reasons. If you are allowed, one solution is to plug the monitor into a simple power bank (like you
          do with a phone) and plug the power bank into the mains. That way the power bank charges up during the daytime
          and then powers the monitor overnight.
        </P>
      </div>
    ),
  },
];

export default techSupportEntries;
