import React from "react";
import { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom";
import {
  CardIconBtnLink,
  CardIconStyle,
  CardTitle,
  ActivityType,
} from "../../../../GenericComponents/CardBoard/styles";
import { EActivityStatus, IActivityTimeEstimates } from "../../../../lib/Activities/IActivity";
import { EStepStatus, TStepRef } from "../../../../lib/Activities/IStep";
import { useAppSelector } from "../../../../Redux/hooks";
import {
  ActivityStepsSection,
  ActivitySectionTitle,
  ActivityStepSection,
  CardStatusIconsWrapStyle,
  CardStatusIconStyle,
  ActivityStepsSectionInner,
  CardWithLogoStyle,
  CardOuterStyle,
  CardBtnTitleStyle,
} from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { lockedactivityTheme } from "../../../../styles/themes";
import { unlockedactivityTheme } from "../../../../styles/themes";
import { TimeEstimatesLongBar } from "../../ActivityTimeEstimates";
import { IActivityCardProps } from "../../IActivityCardProps";
import { ActivityCompletionMessage } from "../../CommonComponents/ActivityCompletionMessage";
import { ActivityStatusIcon } from "../../CommonComponents/ActivityStatusIcon";
import { feedbackStepsQuiz } from "../../CommonComponents/feedbackSteps";
import { FlexBox } from "../../../../styles/singlePageStyles";
import { TextFormatter } from "../../../TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { MappedActivitySteps } from "../../CommonComponents/mapActivitySteps";
import { NoOfPeopleIcon } from "../../CommonComponents/NoOfPeopleIcon";
import { EEquipmentNeeded } from "../../../../lib/Activities/EEquipmentNeeded";
import { EquipmentNeededIcons } from "../../CommonComponents/EquipmentNeededIcons";
import { CompleteCountIcon } from "../../CommonComponents/CompleteCountIcon";
import { ExpandedCard } from "../../CommonComponents/ExpandedCard";

export interface IQuizCardsProps extends IActivityCardProps {
  loaded: boolean;
  timeEstimates: IActivityTimeEstimates; // Time estimates are required for step by step activities
  noOfPeople: string;
  equipmentNeeded: EEquipmentNeeded[];
}

export const QuizCard = ({
  id,
  description,
  title,
  imageUrl,
  timeEstimates,
  equipmentNeeded,
  noOfPeople,
  //tags,
  activityUserProgress: { activityStatus, attemptNo, isLocked } = { activityStatus: null, activityId: null },
}: IQuizCardsProps) => {
  return (
    <ThemeProvider theme={isLocked ? lockedactivityTheme : unlockedactivityTheme}>
      <Link to={`/app/activities/${id}`} style={{ textDecoration: "none" }}>
        <CardOuterStyle lockedactivity={isLocked ? "true" : undefined}>
          <ActivityType>Quiz</ActivityType>
          <CardWithLogoStyle
            data-testid="cardStyle"
            className="cardInnerWrap"
            lockedactivity={isLocked ? "true" : undefined}
          >
            {imageUrl && (
              <FlexBox left horiz>
                <CardIconBtnLink className="cardIconBtn">
                  <CardIconStyle src={imageUrl} alt={`Step by step activity: ${title}`} />
                </CardIconBtnLink>
              </FlexBox>
            )}
            <CardTitle data-testid="Quiz-CardTitle">
              <CardBtnTitleStyle>{title}</CardBtnTitleStyle>{" "}
            </CardTitle>
            <CardStatusIconsWrapStyle>
              <CardStatusIconStyle className="activityStatusIcon">
                <ActivityStatusIcon
                  isLocked={isLocked}
                  activityStatus={activityStatus || EActivityStatus.NOT_STARTED}
                />
              </CardStatusIconStyle>
              <CardStatusIconStyle className="activityStatusIcon">
                <NoOfPeopleIcon count={noOfPeople} />
              </CardStatusIconStyle>
              {(equipmentNeeded && equipmentNeeded.length > 0 && equipmentNeeded[0] !== EEquipmentNeeded.NONE && (
                <CardStatusIconStyle className="activityStatusIcon">
                  <EquipmentNeededIcons equipmentNeeded={equipmentNeeded} />
                </CardStatusIconStyle>
              )) ||
                ""}
              {((activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1 > 0) && (
                <CardStatusIconStyle className="activityStatusIcon">
                  <CompleteCountIcon count={activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1} />
                </CardStatusIconStyle>
              )) ||
                ""}
            </CardStatusIconsWrapStyle>
            <TextFormatter text={description} keywordMap={keywordMap} />
            {timeEstimates && <TimeEstimatesLongBar timeEstimates={timeEstimates} />}
          </CardWithLogoStyle>
        </CardOuterStyle>
      </Link>
    </ThemeProvider>
  );
};

export const QuizCardExpanded = (props: IQuizCardsProps) => {
  const {
    id,
    activitySteps,
    submitStep,
    completeActivity,
    imageUrl,
    startActivity,
    feedbackNeeded,
    activityUserProgress: { activeStepId, activityStatus, stepsState, attemptNo },
  } = props;
  const {
    user: { group: userGroup },
    state: {
      monitor: { id: monitorId } = { id: null },
      room: { data: { currentRoom } = { currentRoom: null } },
    },
  } = useAppSelector((state) => state.userState);

  const completedSteps: TStepRef[] = React.useMemo(
    () => stepsState?.filter((s) => s.status === EStepStatus.COMPLETED).map((s) => s.stepRef) || [],
    [stepsState]
  );
  const remainingRequiredSteps = activitySteps.filter(
    (step) =>
      !step.optional &&
      completedSteps?.indexOf(step.stepRef) === -1 &&
      // Hide steps that are not available to user
      (!step.userRoleLimit || step.userRoleLimit.length === 0 || step.userRoleLimit.indexOf(userGroup) !== -1)
  );
  const complete = activityStatus === EActivityStatus.COMPLETED;
  const started =
    [EActivityStatus.IN_PROGRESS, EActivityStatus.COMPLETED, EActivityStatus.FAILED].indexOf(activityStatus) > -1;

  const mappedActivitySteps = React.useMemo(
    () =>
      started && activitySteps
        ? MappedActivitySteps({
            activitySteps,
            stepsState,
            activeStepId,
            previousSteps: completedSteps,
            monitorId,
            roomId: currentRoom?.id,
            activityId: id,
            submitStep,
            attemptNo,
            lockCompleteSteps: true,
            allowUnlockCompleteSteps: true,
            goToStep: () => {
              throw new Error("Go to step no implemented in Quiz activity type");
            }, // TODO: Implement go to step
            userGroup,
          })
        : [],
    [
      started,
      activitySteps,
      stepsState,
      activeStepId,
      completedSteps,
      monitorId,
      currentRoom?.id,
      id,
      submitStep,
      attemptNo,
      userGroup,
    ]
  );

  const mappedFeedbackSteps = React.useMemo(
    () =>
      MappedActivitySteps({
        activitySteps: feedbackStepsQuiz,
        stepsState,
        activeStepId,
        previousSteps: completedSteps,
        monitorId,
        roomId: currentRoom?.id,
        activityId: id,
        attemptNo,
        lockCompleteSteps: true,
        allowUnlockCompleteSteps: true,
        submitStep,
        goToStep: () => {
          throw new Error("Go to step no implemented in Quiz activity type");
        }, // TODO: Implement go to step
        userGroup,
      }),
    [stepsState, activeStepId, completedSteps, monitorId, currentRoom?.id, id, attemptNo, submitStep, userGroup]
  );

  return (
    <ExpandedCard id={id} activityTypeName="Quiz" {...props}>
      {({ scrollToTitle }) =>
        started && (
          <ActivityStepsSection open={started} data-testid="activityStepsSection">
            <ActivityStepsSectionInner>
              <ActivitySectionTitle>Activity Zone</ActivitySectionTitle>
              {((started && !activitySteps) || activitySteps.length === 0) && "MISSING STEPS"}

              {mappedActivitySteps}
              {!complete && remainingRequiredSteps.length === 0 && (
                <>
                  {feedbackNeeded && mappedFeedbackSteps}
                  <ActivityStepSection style={{ padding: "1rem" }} active>
                    <p>Congratulations you have completed all the required steps!</p>
                    <p>Click the button below to see your new achievements.</p>
                    <CTAButtonStyle onClick={() => completeActivity(id)}>Complete Activity</CTAButtonStyle>
                  </ActivityStepSection>
                </>
              )}

              {complete && (
                <ActivityCompletionMessage
                  badgeImageUrl={imageUrl}
                  restartActivity={() => {
                    scrollToTitle();
                    startActivity(id, monitorId, currentRoom?.id);
                  }}
                />
              )}
            </ActivityStepsSectionInner>
          </ActivityStepsSection>
        )
      }
    </ExpandedCard>
  );
};
