export enum ETechSupportGroup {
  GENERAL = "General",
  CONNECTION_SUPPORT = "CONNECTION_SUPPORT",
  LOGGING_IN = "LOGGING_IN",
  OTHER = "OTHER",
}

export interface ITechSupportGroup {
  uid: ETechSupportGroup;
  label: string | React.ReactNode;
}

export const TechSupportGroups: Record<ETechSupportGroup, ITechSupportGroup> = {
  [ETechSupportGroup.GENERAL]: {
    uid: ETechSupportGroup.GENERAL,
    label: "General",
  },
  [ETechSupportGroup.CONNECTION_SUPPORT]: {
    uid: ETechSupportGroup.CONNECTION_SUPPORT,
    label: "Connection support",
  },
  [ETechSupportGroup.LOGGING_IN]: {
    uid: ETechSupportGroup.LOGGING_IN,
    label: "Creating Web App accounts and logging in",
  },
  [ETechSupportGroup.OTHER]: {
    uid: ETechSupportGroup.OTHER,
    label: "Other",
  },
};

export interface ITechSupportItem {
  uid: string;
  label: string | React.ReactNode;
  content: React.ReactNode;
  group: ETechSupportGroup;
}
