import React from "react";
import styled from "styled-components";
import { CardStyle } from "../../../GenericComponents/CardBoard/styles";
import { LinkNoFormat } from "../../../styles/button";
import { FlexBox } from "../../../styles/singlePageStyles";
import { IResourceCardProps } from "./IAppResourceCardProps";

export interface IResourceCardInnerProps extends IResourceCardProps {}

export const ResourceDownloadLinkStyle = styled(LinkNoFormat)`
  background: ${({ theme }) => theme.colors.cta};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  color: ${({ theme }) => theme.colors.ctaText};
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.ctaText};
    background: ${({ theme }) => theme.colors.ctaHover};
  }
`;

export const ResourceDownloadLink = (props) => (
  <ResourceDownloadLinkStyle download {...props}>
    {props.children}{" "}
    <span role="img" aria-label="Download Button">
      📥
    </span>
  </ResourceDownloadLinkStyle>
);

export const ResourceDownloadThumbnailLink = (props) => (
  <LinkNoFormat download {...props}>
    {props.children}{" "}
  </LinkNoFormat>
);

export const ResourceDownloadCard: React.FC<IResourceCardInnerProps> = ({
  label,
  description,
  resourceUrl,
  thumbnailUrl,
}) => {
  return (
    <CardStyle>
      {thumbnailUrl && (
        <ResourceDownloadThumbnailLink href={resourceUrl}>
          <FlexBox center style={{ width: "100%" }}>
            <img src={thumbnailUrl} width="100%" alt={`Resource download: ${label}`} />
          </FlexBox>
        </ResourceDownloadThumbnailLink>
      )}

      <h1>{label}</h1>
      <p>{description}</p>
      <p style={{ marginTop: "1rem" }}>
        <ResourceDownloadLink href={resourceUrl}>Download</ResourceDownloadLink>
      </p>
    </CardStyle>
  );
};
