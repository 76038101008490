import React from "react";
import { SearchAndSelect, TSearchAndSelectSearchFunction } from "@react_db_client/components.search-and-select-v2";
import { EFilterType } from "@react_db_client/constants.client-types";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAsync } from "react-use";
import { ISchoolDetailsPrivateSearchResult, ISchoolValidated } from "../../lib/School/ISchoolDetails";
import { Keys } from "react-hook-form/dist/types/path/common";
import { IMonitorRequest, IMonitorRequestResultItem } from "../../lib/formSubmissions/IMonitorRequest";
import { searchMonitorRequests } from "../../Api/apiMonitorRequest";
import { ESchoolStatus, SchoolStatus } from "../../lib/School/ESchoolStatus";
import useAsyncFnReset from "../../utils/hooks/useAsync";
import { SearchAndSelectStyles } from "../../styles/form";
import { ButtonGroup, ButtonStyle, CTAButtonStyle, DisabledButton, OnOffButtonStyle } from "../../styles/button";
import { DownloadCsvLink } from "../../styles/links";
import {
  apiMonitorDeployment,
  IApiDeployMonitorArgs,
  IApiInitiateValidationArgs,
  IApiMarkReceivedArgs,
  IApiMarkRequestsInvalidArgs,
  IApiMarkRequestsValidArgs,
  IApiUpdateDeploymentStatusArgs,
  IApiMarkWithdrawnArgs,
} from "../../Api/apiMonitorDeployment";
import { ErrorMessage } from "../../styles/app";
import { SchoolFundingTypes } from "../../lib/formSubmissions/IMonitorRequestSubmission";

const schoolHeadings: { uid: Keys<ISchoolValidated>; label: string; type: EFilterType }[] = [
  {
    uid: "schoolId",
    label: "School ID",
    type: EFilterType.text,
  },
  {
    uid: "establishmentId",
    label: "School Establishment ID",
    type: EFilterType.text,
  },
  {
    uid: "schoolName",
    label: "School Name",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddressPostCode",
    label: "School Post Code",
    type: EFilterType.text,
  },
  {
    uid: "dateCreated",
    label: "Date Request Submitted",
    type: EFilterType.date,
  },
  {
    uid: "schoolFundingType",
    label: "School Funding Type",
    type: EFilterType.text,
  },
];

const previewHeadings: { uid: Keys<ISchoolValidated>; label: string; type: EFilterType }[] = [
  {
    uid: "firstName",
    label: "firstName",
    type: EFilterType.text,
  },
  {
    uid: "lastName",
    label: "lastName",
    type: EFilterType.text,
  },
  {
    uid: "lastName",
    label: "lastName",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddress1",
    label: "schoolAddress1",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddress2",
    label: "schoolAddress2",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddress3",
    label: "schoolAddress3",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddress4",
    label: "schoolAddress4",
    type: EFilterType.text,
  },
  {
    uid: "schoolAddressPostCode",
    label: "schoolAddressPostCode",
    type: EFilterType.text,
  },
  {
    uid: "altSchoolAddress1",
    label: "altSchoolAddress1",
    type: EFilterType.text,
  },
  {
    uid: "altSchoolAddress2",
    label: "altSchoolAddress2",
    type: EFilterType.text,
  },
  {
    uid: "altSchoolAddress3",
    label: "altSchoolAddress3",
    type: EFilterType.text,
  },
  {
    uid: "altSchoolAddress4",
    label: "altSchoolAddress4",
    type: EFilterType.text,
  },
  {
    uid: "altSchoolAddressPostCode",
    label: "altSchoolAddressPostCode",
    type: EFilterType.text,
  },
];

const csvOutputHeadings: string[] = [
  "id",
  "firstName",
  "lastName",
  "schoolName",
  "schoolAddress1",
  "schoolAddress2",
  "schoolAddress3",
  "schoolAddress4",
  "schoolAddressPostCode",
  "altSchoolAddress1",
  "altSchoolAddress2",
  "altSchoolAddress3",
  "altSchoolAddress4",
  "altSchoolAddressPostCode",
  "schoolTelephone",
  "email",
  "schoolEmail",
  "schoolId",
  "monitorId",
  "schoolFundingType",
  "dateStartedValidating",
  "dateValidated",
  "dateCreated",
  "establishmentId",
  "status",
];

const generateCsvData = (
  schools: ISchoolDetailsPrivateSearchResult[] | IMonitorRequestResultItem[],
  schoolStatus: ESchoolStatus | "all" | "allRequests" | "duplicates"
): string[][] => [
  csvOutputHeadings,
  ...schools.map(
    (
      school:
        | ISchoolDetailsPrivateSearchResult
        | (IMonitorRequestResultItem & Partial<ISchoolDetailsPrivateSearchResult>)
    ) => [
      String(school.id),
      String(school.firstName),
      String(school.lastName),
      String(school.schoolName),
      String(school.schoolAddress1),
      String(school?.schoolAddress2) || "MISSING",
      String(school?.schoolAddress3) || "MISSING",
      String(school?.schoolAddress4) || "MISSING",
      String(school.schoolAddressPostCode),
      String(school.altSchoolAddress1),
      String(school?.altSchoolAddress2) || "MISSING",
      String(school?.altSchoolAddress3) || "MISSING",
      String(school?.altSchoolAddress4) || "MISSING",
      String(school.altSchoolAddressPostCode),
      String(school?.schoolTelephone) || "MISSING",
      String(school?.email) || "MISSING",
      String(school?.schoolEmail) || "MISSING",
      String(school?.schoolId) || "MISSING",
      String(school?.monitors?.join(";")) || "MISSING",
      String(school?.schoolFundingType && SchoolFundingTypes[school.schoolFundingType]?.label) || "MISSING",
      String(school?.dateStartedValidating && new Date(school?.dateStartedValidating).toISOString()) || "MISSING",
      String(school?.dateValidated && new Date(school?.dateValidated).toISOString()) || "MISSING",
      String(school?.dateCreated && new Date(school?.dateCreated).toISOString()) || "MISSING", //TODO: Check matches api
      String(school?.establishmentId) || "MISSING", //TODO: Check matches api
      String(SchoolStatus[school?.status || schoolStatus]?.label) || "MISSING", // TODO: get status when "all" | "allRequests" | "duplicates" selected.
    ]
  ),
];
export const lockedFromStatus = [ESchoolStatus.VALIDATED, ESchoolStatus.RECEIVED, ESchoolStatus.ACTIVATED];

const mapMonitorRequestToResultItem = (monitorRequest: IMonitorRequest): IMonitorRequestResultItem => {
  return {
    ...monitorRequest,
    uid: monitorRequest.id,
    label: `${monitorRequest.id}`,
    type: EFilterType.text,
  };
};

const mapSchoolToResultItem = (schoolDetails: ISchoolValidated): ISchoolDetailsPrivateSearchResult => {
  return {
    ...schoolDetails,
    uid: schoolDetails.id,
    label: `${schoolDetails.id}`,
    // type: EFilterType.text,
  };
};

const searchMonitorRegistrations =
  (validating: boolean, validated: boolean, isInvalid: boolean) =>
  (token: string, lastEditedByUser: boolean) =>
  async (filters, sortBy: string, searchValue) => {
    return searchMonitorRequests(validating, validated, isInvalid, searchValue, lastEditedByUser, token)
      .then((monitorRequests) => monitorRequests.map(mapMonitorRequestToResultItem))
      .catch((e) => {
        console.info(e);
        throw e;
      });
  };

const getAllMonitorReqeusts = () => (token: string) => async () => {
  return searchMonitorRequests(false, false, false, null, false, token, true)
    .then((monitorRequests) => monitorRequests.map(mapMonitorRequestToResultItem))
    .catch((e) => {
      console.info(e);
      throw e;
    });
};

const getAllDuplicateMonitorRequests = () => (token: string) => async () => {
  return searchMonitorRequests(false, false, false, null, false, token, false, true)
    .then((monitorRequests) => monitorRequests.map(mapMonitorRequestToResultItem))
    .catch((e) => {
      console.info(e);
      throw e;
    });
};

const searchSchools =
  (status?: ESchoolStatus) =>
  (token: string, lastEditedByUser: boolean): TSearchAndSelectSearchFunction<ISchoolDetailsPrivateSearchResult> =>
  async (filters, sortBy?: string, searchValue?: string) => {
    return apiMonitorDeployment
      .searchSchools(token)(status || null, searchValue, lastEditedByUser)
      .then((schools) => schools.map(mapSchoolToResultItem))
      .catch((e) => {
        console.info(e);
        throw e;
      });
  };

const searchFunctions: Record<
  ESchoolStatus | "all" | "allRequests" | "duplicates",
  (
    token: string,
    lastEditedByUser: boolean
  ) => TSearchAndSelectSearchFunction<IMonitorRequestResultItem | ISchoolDetailsPrivateSearchResult>
> = {
  all: searchSchools(), // All not implemented
  allRequests: getAllMonitorReqeusts(),
  duplicates: getAllDuplicateMonitorRequests(),
  [ESchoolStatus.REGISTERED]: searchMonitorRegistrations(false, false, false),
  [ESchoolStatus.VALIDATING]: searchMonitorRegistrations(true, false, false),
  [ESchoolStatus.VALIDATED]: searchSchools(ESchoolStatus.VALIDATED),
  [ESchoolStatus.LINKED]: searchSchools(ESchoolStatus.LINKED),
  [ESchoolStatus.DEPLOYED]: searchSchools(ESchoolStatus.DEPLOYED),
  [ESchoolStatus.RECEIVED]: searchSchools(ESchoolStatus.RECEIVED),
  [ESchoolStatus.ACTIVATED]: searchSchools(ESchoolStatus.ACTIVATED),
  [ESchoolStatus.INVALID]: searchMonitorRegistrations(true, false, true),
  [ESchoolStatus.ERROR]: searchSchools(ESchoolStatus.ERROR),
  [ESchoolStatus.WITHDRAWN]: searchSchools(ESchoolStatus.WITHDRAWN),
};

/* Modifying multiple entries to status implemented as below */
export const checkAllowMultiple: Record<ESchoolStatus | "all" | "allRequests" | "duplicates", boolean> = {
  all: false,
  allRequests: false,
  duplicates: false,
  [ESchoolStatus.REGISTERED]: false,
  [ESchoolStatus.VALIDATING]: true,
  [ESchoolStatus.VALIDATED]: true,
  [ESchoolStatus.LINKED]: false,
  [ESchoolStatus.DEPLOYED]: true,
  [ESchoolStatus.RECEIVED]: false,
  [ESchoolStatus.ACTIVATED]: false,
  [ESchoolStatus.INVALID]: true,
  [ESchoolStatus.ERROR]: false,
  [ESchoolStatus.WITHDRAWN]: true,
};

export interface IChangeStatusButtonProps {
  currentStatus: ESchoolStatus | "all" | "allRequests" | "duplicates";
  onClick: (
    newStatus:
      | ESchoolStatus.VALIDATING
      | ESchoolStatus.VALIDATED
      | ESchoolStatus.DEPLOYED
      | ESchoolStatus.RECEIVED
      | ESchoolStatus.WITHDRAWN
  ) => void;
}

export const NaButton = () => <DisabledButton>NA</DisabledButton>;

export const ChangeStatusButton = ({ currentStatus, onClick }: IChangeStatusButtonProps) => {
  switch (currentStatus) {
    case ESchoolStatus.REGISTERED.valueOf(): {
      return (
        <CTAButtonStyle onClick={() => onClick(ESchoolStatus.VALIDATING)}>
          {SchoolStatus[ESchoolStatus.VALIDATING].toLabel}
        </CTAButtonStyle>
      );
    }
    case ESchoolStatus.VALIDATING.valueOf(): {
      return (
        <CTAButtonStyle onClick={() => onClick(ESchoolStatus.VALIDATED)}>
          {SchoolStatus[ESchoolStatus.VALIDATED].toLabel}
        </CTAButtonStyle>
      );
    }
    case ESchoolStatus.VALIDATED.valueOf(): {
      return <NaButton />;
    }
    case ESchoolStatus.LINKED.valueOf(): {
      return (
        <CTAButtonStyle onClick={() => onClick(ESchoolStatus.DEPLOYED)}>
          {SchoolStatus[ESchoolStatus.DEPLOYED].toLabel}
        </CTAButtonStyle>
      );
    }
    case ESchoolStatus.DEPLOYED.valueOf(): {
      return (
        <CTAButtonStyle onClick={() => onClick(ESchoolStatus.RECEIVED)}>
          {SchoolStatus[ESchoolStatus.RECEIVED].toLabel}
        </CTAButtonStyle>
      );
    }
    case ESchoolStatus.WITHDRAWN.valueOf():
    case ESchoolStatus.RECEIVED.valueOf():
    case ESchoolStatus.ACTIVATED.valueOf():
    case ESchoolStatus.INVALID.valueOf():
    case ESchoolStatus.ERROR.valueOf():
    case "allRequests":
    case "duplicates":
    case "all": {
      return <NaButton />;
    }

    default:
      // return <NaButton />;
      throw Error(`Invalid Status: ${currentStatus}`);
  }
};

export type IAsyncUpdateSchoolStatus =
  | {
      status: ESchoolStatus.VALIDATING;
      data: IApiInitiateValidationArgs;
    }
  | {
      status: ESchoolStatus.VALIDATED;
      data: IApiMarkRequestsValidArgs;
    }
  | {
      status: ESchoolStatus.INVALID;
      data: IApiMarkRequestsInvalidArgs;
    }
  | {
      status: ESchoolStatus.DEPLOYED;
      data: IApiDeployMonitorArgs;
    }
  | {
      status: ESchoolStatus.RECEIVED;
      data: IApiMarkReceivedArgs;
    }
  | {
      status: ESchoolStatus.WITHDRAWN;
      data: IApiMarkWithdrawnArgs;
    };

export type IAsyncUpdateSchoolStatusError = {
  status: ESchoolStatus.ERROR;
  data: IApiUpdateDeploymentStatusArgs;
};

const asyncUpdateSchoolStatus =
  (token: string) =>
  async ({ status, data }: IAsyncUpdateSchoolStatus | IAsyncUpdateSchoolStatusError) => {
    if (status === ESchoolStatus.VALIDATING) {
      return apiMonitorDeployment.initiateValidation(token)(data);
    }
    if (status === ESchoolStatus.VALIDATED) {
      return apiMonitorDeployment.markRequestsValid(token)(data);
    }
    if (status === ESchoolStatus.DEPLOYED) {
      return apiMonitorDeployment.deployMonitor(token)(data);
    }
    if (status === ESchoolStatus.RECEIVED) {
      return apiMonitorDeployment.markReceived(token)(data);
    }
    if (status === ESchoolStatus.INVALID) {
      return apiMonitorDeployment.markRequestsInvalid(token)(data);
    }
    if (status === ESchoolStatus.ERROR) {
      return apiMonitorDeployment.updateDeploymentStatus(token)(data);
    }
    if (status === ESchoolStatus.WITHDRAWN) {
      return apiMonitorDeployment.markWithdrawn(token)(data);
    }
  };

const availableFilters = {};

const SchoolValidationForm: React.FC = () => {
  const [schoolStatus, setSchoolStatus] = React.useState<ESchoolStatus | "all" | "allRequests" | "duplicates">(
    ESchoolStatus.REGISTERED
  );
  const [lastEditedByUserToggle, setLastEditedByUserToggle] = React.useState(false);
  const [selection, setSelection] = React.useState<ISchoolDetailsPrivateSearchResult[] | IMonitorRequestResultItem[]>(
    []
  );
  const [reloadKey, setReloadKey] = React.useState(0);
  const [error, setError] = React.useState<Error>();
  const { token } = React.useContext(AuthContext);
  const [updateSchoolStatusAsync, updateSchoolStatusAsyncCall, updateSchoolStatusAsyncReset] = useAsyncFnReset(
    asyncUpdateSchoolStatus(token)
  );

  const schoolStatusStats = useAsync(apiMonitorDeployment.getDeploymentStats(token), [token]);

  const updateStatusOfSelection = (args: IAsyncUpdateSchoolStatus | IAsyncUpdateSchoolStatusError) => {
    setError(null);
    const { status: newSchoolStatus } = args;
    updateSchoolStatusAsyncReset();
    if (!selection || selection.length === 0) {
      setError(new Error("Select some schools first!"));
      return;
    }

    if (newSchoolStatus !== ESchoolStatus.WITHDRAWN) {
      if (schoolStatus === "all") {
        setError(new Error(`Cannot manually edit from all schools! Please contact SAMHE support.`));
        return;
      }
      if (lockedFromStatus.indexOf(Number(schoolStatus)) !== -1) {
        setError(
          new Error(
            `Cannot manually edit schools with status ${SchoolStatus[schoolStatus].label}! Please contact SAMHE support.`
          )
        );
        return;
      }
      if (schoolStatus === newSchoolStatus) {
        setError(new Error(`Status is already set to ${SchoolStatus[schoolStatus].label}`));
        return;
      }
      if (selection.length > 1 && !checkAllowMultiple[newSchoolStatus]) {
        setError(new Error(`Cannot modify multiple entries to ${SchoolStatus[newSchoolStatus].label}`));
        return;
      }
    }

    updateSchoolStatusAsyncCall(args as IAsyncUpdateSchoolStatus);
    setReloadKey((prev) => prev + 1);
    setSelection([]);
  };

  const handleSelect = React.useCallback(
    (selected) => (Array.isArray(selected) ? setSelection(selected) : setSelection([selected])),
    []
  );

  const csvData = React.useMemo(
    () => (selection ? generateCsvData(selection, schoolStatus) : []),
    [selection, schoolStatus]
  );

  const handleStatusViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "all") setSchoolStatus("all");
    else if (e.target.value === "allRequests") setSchoolStatus("allRequests");
    else if (e.target.value === "duplicates") setSchoolStatus("duplicates");
    else setSchoolStatus(parseInt(e.target.value) as ESchoolStatus);
    setSelection([]);
  };

  const searchFunction = React.useMemo(
    () => searchFunctions[schoolStatus](token, lastEditedByUserToggle),
    [schoolStatus, token, lastEditedByUserToggle]
  );

  return (
    <>
      <h2>Search Schools</h2>
      <p>Search schools below by changing the Current Status dropdown.</p>
      <label htmlFor="currentStatusSelect">Current Status</label>
      <span> : </span>
      <select id="currentStatusSelect" value={schoolStatus} onChange={handleStatusViewChange}>
        <option value={ESchoolStatus.REGISTERED}>{SchoolStatus[ESchoolStatus.REGISTERED].label}</option>
        <option value={ESchoolStatus.VALIDATING}>{SchoolStatus[ESchoolStatus.VALIDATING].label}</option>
        <option value={ESchoolStatus.VALIDATED}>{SchoolStatus[ESchoolStatus.VALIDATED].label}</option>
        <option value={ESchoolStatus.LINKED}>{SchoolStatus[ESchoolStatus.LINKED].label}</option>
        <option value={ESchoolStatus.DEPLOYED}>{SchoolStatus[ESchoolStatus.DEPLOYED].label}</option>
        <option value={ESchoolStatus.RECEIVED}>{SchoolStatus[ESchoolStatus.RECEIVED].label}</option>
        <option value={ESchoolStatus.ACTIVATED}>{SchoolStatus[ESchoolStatus.ACTIVATED].label}</option>
        <option value={ESchoolStatus.INVALID}>{SchoolStatus[ESchoolStatus.INVALID].label}</option>
        <option value={ESchoolStatus.ERROR}>{SchoolStatus[ESchoolStatus.ERROR].label}</option>
        <option value={ESchoolStatus.WITHDRAWN}>{SchoolStatus[ESchoolStatus.WITHDRAWN].label}</option>
        <option value="all">All Schools</option>
        <option value="allRequests">All Requests</option>
        <option value="duplicates">All Duplicates</option>
      </select>
      <OnOffButtonStyle
        on={lastEditedByUserToggle ? "true" : undefined}
        onClick={() => setLastEditedByUserToggle((prev) => !prev)}
      >
        Last edited by you
      </OnOffButtonStyle>
      <button onClick={() => setReloadKey((prev) => prev + 1)}>Reload</button>
      <div title="School Validation Form">
        <SearchAndSelectStyles data-testid="schoolSearchContainer">
          <SearchAndSelect<ISchoolDetailsPrivateSearchResult | IMonitorRequestResultItem>
            id="schoolSearch"
            searchFunction={searchFunction}
            availableFilters={availableFilters}
            autoUpdate
            headings={schoolHeadings}
            previewHeadings={previewHeadings}
            handleSelect={handleSelect}
            liveUpdate
            autoWidth
            allowMultiple
            allowSelectionPreview
            showSearchField
            initialSearchValue=""
            allowFilters={false}
            debounceTimeout={300}
            reloadKey={reloadKey}
            key={reloadKey}
          />
        </SearchAndSelectStyles>
      </div>
      <div style={{ display: Array.isArray(selection) && selection.length > 0 ? "flex" : "none" }}>
        {Array.isArray(selection) && selection.length > 0 && (
          <ButtonGroup>
            <ChangeStatusButton
              currentStatus={schoolStatus}
              onClick={(newStatus) =>
                updateStatusOfSelection(
                  newStatus === ESchoolStatus.DEPLOYED
                    ? ({
                        status: newStatus,
                        data: {
                          schoolIds: (selection as IMonitorRequestResultItem[]).map((s) => s.id),
                        },
                      } as IAsyncUpdateSchoolStatus)
                    : [ESchoolStatus.VALIDATING, ESchoolStatus.VALIDATED].indexOf(newStatus) !== -1
                    ? ({
                        status: newStatus,
                        data: {
                          monitorRequestId: (selection as IMonitorRequestResultItem[])[0].id,
                          monitorRequestIds: (selection as IMonitorRequestResultItem[]).map((s) => s.id),
                        },
                      } as IAsyncUpdateSchoolStatus)
                    : ({
                        status: newStatus,
                        data: {
                          schoolId: (selection as IMonitorRequestResultItem[])[0].id,
                        },
                      } as IAsyncUpdateSchoolStatus)
                )
              }
            />
            {schoolStatus === ESchoolStatus.VALIDATING && (
              <>
                <ButtonStyle
                  onClick={() =>
                    updateStatusOfSelection({
                      status: ESchoolStatus.INVALID,
                      data: {
                        invalidMonitorRequests: [
                          {
                            monitorRequestId: (selection as IMonitorRequestResultItem[])[0].id,
                            invalidReason: "Invalid",
                          },
                        ],
                      },
                    })
                  }
                >
                  Mark selection as Invalid
                </ButtonStyle>
                <ButtonStyle
                  onClick={() =>
                    updateStatusOfSelection({
                      status: ESchoolStatus.INVALID,
                      data: {
                        invalidMonitorRequests: [
                          {
                            monitorRequestId: (selection as IMonitorRequestResultItem[])[0].id,
                            invalidReason: "duplicate",
                          },
                        ],
                      },
                    })
                  }
                >
                  Mark selection as Duplicate
                </ButtonStyle>
              </>
            )}
            {schoolStatus !== ESchoolStatus.VALIDATING && schoolStatus !== ESchoolStatus.WITHDRAWN && (
              <ButtonStyle
                onClick={() =>
                  updateStatusOfSelection({
                    status: ESchoolStatus.WITHDRAWN,
                    data: {
                      schoolIds: (selection as IMonitorRequestResultItem[]).map((s) => s.id),
                    },
                  })
                }
              >
                Mark Selection Withdrawn
              </ButtonStyle>
            )}
            {/* TODO: Implement below on server */}
            {/* <ButtonStyle
              onClick={() =>
                updateStatusOfSelection({
                  status: ESchoolStatus.ERROR,
                  data: {
                    newStatus: ESchoolStatus.ERROR,
                    schoolId: (selection as ISchoolDetailsPrivateSearchResult[])[0].schoolId,
                    statusReason: "Failed",
                  },
                })
              }
            >
              Mark selection as Failed
            </ButtonStyle> */}

            <DownloadCsvLink
              role="button"
              data-testdata={JSON.stringify(csvData)}
              data={csvData}
              filename="downloaded_school_data"
            >
              Download Selected as a CSV file
            </DownloadCsvLink>
          </ButtonGroup>
        )}
      </div>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {updateSchoolStatusAsync.error && <ErrorMessage>Failed to update school status!</ErrorMessage>}
      {updateSchoolStatusAsync.error &&
        updateSchoolStatusAsync.error.message.split("\n").map((m) => <ErrorMessage>{m}</ErrorMessage>)}
      {updateSchoolStatusAsync.loading && "Updating school status"}
      {updateSchoolStatusAsync?.value?.response?.ok && "Successfully changed status"}

      {/* <ButtonStyle></ButtonStyle> */}
      <h2>Stats</h2>
      {!schoolStatusStats.value && schoolStatusStats.loading && <p>Loading stats</p>}
      {!schoolStatusStats.value && !schoolStatusStats.loading && <p>Error loading stats</p>}
      {schoolStatusStats.value && (
        <div data-testid="deployment-stats">
          <p>
            <b>Total monitor requests(including duplicates): </b>
            <b>
              <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalRequestCount}</span>
            </b>
          </p>

          <p>
            Total (all time) monitor requests ( ALL requests including duplicates. team tests and eligible requests).
            Will be used to assess volume of requests
          </p>
          <hr />

          <p>
            <b>Total validated monitor requests: </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalValidatedRequestCount}</span>
          </p>
          <p>
            Total validated requests = the number of schools (no matter at what status they are) which have an entry in
            the 'datevalidated' field. Allows us to assess how many schools are participating in SAMHE
          </p>
          <hr />

          <p>
            <b>Total schools linked to a monitor (including schools with any status): </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchoolsWithMonitorLinkedCount}</span>
          </p>
          <p>
            Total schools with status 'monitor linked'. Used to see what proportion of participating schools have been
            assigned a monitor by the the deployment team. N.B. all schools with which a monitor is linked (regardless
            of status) will be here.
          </p>
          <hr />

          <p>
            <b>
              Total schools with status deployed (This is the total number of monitors dispatched but not activated):
            </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchoolsStatusDeployed}</span>
          </p>
          <p>
            Total schools for which monitor has been dispatched. Count of all the schools to which a monitor has been
            'deployed'
          </p>
          <hr />

          <p>
            <b>Total dispatched monitors activated by schools: </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchoolsStatusActivated}</span>
          </p>
          <p>
            Total number of schools at 'activated' status. Allows us to assess the proportion of schools which have
            connected
          </p>
          <hr />

          <p>
            <b>Total schools: </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchools}</span>
          </p>
          <p>Total number of schools at any status</p>
          <hr />

          <p>
            <b>Total duplicates: </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchoolsStatusDuplicate}</span>
          </p>
          <p>Total number of monitor requests marked as duplicate</p>
          <hr />

          <p>
            <b>Total withdrawn: </b>
            <span style={{ color: "tomato" }}>{schoolStatusStats.value?.totalSchoolsStatusWithdrawn}</span>
          </p>
          <p>Total number of schools withdrawn</p>
        </div>
      )}
    </>
  );
};

export default SchoolValidationForm;
