import { Link } from "react-router-dom";
import { DocumentStyle } from "../../styles/documents";
import { Section, SectionHeading, P, Ul } from "../../styles/page";
import { AExternal } from "../Links";

const lastUpdated = "09/10/2023";
const version = "1.1";

export const PrivacyPolicy = () => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>
        <P>
          SAMHE (Schools Air quality Monitoring for Health and Education) is a research project led by Imperial College
          London in partnership with the University of Cambridge, the Stockholm Environment Institute at the University
          of York, the University of Surrey, the University of Leeds and the United Kingdom Health Security Agency
        </P>
        <P>
          Imperial College London is the Data Controller for this website. This means that we are responsible for
          deciding how we hold and use personal information about you. We are required under data protection legislation
          (including the GDPR and Privacy Electronic Communications Regulations) to notify you of the information
          contained in this Privacy Notice.
        </P>
        <P>This privacy policy will:</P>
        <Ul>
          <li>help you to understand how we look after your personal data when you visit our Website;</li>
          <li>tell you about your privacy rights;</li>
          <li>tell you how data protection legislation protects you</li>
        </Ul>
        <P>
          This is a general web privacy policy for the SAMHE Website and SAMHE Web App. Activities requiring specific
          infor mation from you, such as filling out one of our forms, are governed by their own privacy notices. Those
          privacy notices will supersede or supplement this privacy notice.
        </P>
      </Section>
      <Section>
        <SectionHeading>Terminology</SectionHeading>
        <P>This section of our privacy policy tells you what we mean when we use the following words:</P>
        <Ul>
          <li>'We' and 'Us' both mean Imperial College London</li>

          <li>
            'Website' means the SAMHE website as well as the SAMHE Web App ( www.samhe.org.uk ) both of which have been
            developed by Imperial College London and the Stockholm Environment Institute at the University of York, in
            collaboration with the University of Cambridge, the University of Surrey, the University of Leeds, and the
            United Kingdom Health Security Agency (UK HSA)
          </li>
          <li>
            'You' and 'Your' is the person reading this (a user who is connected to our Website or Web App through their
            web browser)
          </li>
          <li>
            Cookie means a small text file which is sent by a website to your web browser, and returned by your web
            browser to the website whenever you visit that website. Like almost all other websites our Website uses
            cookies. This is to improve your experience when using our Website.
          </li>
          <li>
            IP Address is a numerical identifier assigned by the computer network to allow the website to communicate
            with your computer.
          </li>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>What data do we collect?</SectionHeading>
        <P>When you visit our Website we collect information from you. This includes:</P>
        <Ul>
          <li>
            Information about how you use our website and which of the pages you view (this information is available to
            us through our use of cookies){" "}
          </li>
          <li>
            Information about the computer (or other device) that you use to access our Website including your IP
            Address, operating system and browser type.{" "}
          </li>
        </Ul>
        <P>
          When you visit our website you are asked whether you consent to our use of cookies. If you do not agree to
          cookies you will still be able to navigate around the website. However, your experience of our Website may be
          negatively affected since the cookies we use are designed to improve your browsing experience. For example, we
          use a cookie to store session data from your previous log-in.
        </P>
        <P>
          For more information on cookies, including advice on how to control them using your web browser please refer
          to{" "}
          <AExternal href="https://ico.org.uk/your-data-matters/online/cookies/#:~:text=A%20cookie%20is%20a%20small,people%20looking%20at%20a%20website.">
            this guidance from the Information Commissioner's Office
          </AExternal>
          .
        </P>
        <P>
          Where we require specific data from you in addition to this, such as if you are filling out one of our forms,
          you may be asked to enter personal data such as:
        </P>
        <Ul>
          <li>Your name</li>
          <li>Your email address</li>
        </Ul>
        <P>
          These parts of the website carry their own privacy notices or provide additional information relating to their
          activities, which will supersede or supplement this privacy notice.{" "}
        </P>
        <P>We will keep all data we collect safe and secure.</P>
      </Section>
      <Section>
        <SectionHeading>How will my data be used? </SectionHeading>
        <P>
          We will only use your personal data when the law allows us to. Most commonly, we will use your personal data
          in the following circumstances:{" "}
        </P>
        <Ul>
          <li>Where we need to perform the contract we are about to enter into or have entered into with you. </li>
          <li>When you request us to do something for you or because of you </li>
          <li>To manage the SAMHE Research Project and SAMHE Connection Competition Prize Draw.</li>
          <li>
            Where it is necessary for our legitimate interests (or those of a third party) and your interests and
            fundamental rights do not override those interests.{" "}
          </li>
          <li>Where we need to comply with a legal or regulatory obligation. </li>
          <li>
            To understand the behaviour of visitors to the site. This would include:
            <Ul>
              <li>The number of visitors to different areas </li>
              <li>The types of information being searched for via the sites search engine </li>
              <li>To identify any anomalies in site usage </li>
              <li>To evaluate the popularity of news articles </li>
            </Ul>
          </li>
        </Ul>
        <P>
          The data we collect from you using cookies will be used to help us improve the functionality, security and
          accessibility of our website.{" "}
        </P>
        <P>We may also publish summary statistics using the data we hold; this will not identify any individual. </P>
      </Section>
      <Section>
        <SectionHeading>How long will you keep this data for? </SectionHeading>
        <P>
          We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
          including for the purposes of satisfying any legal, accounting, or reporting requirements.{" "}
        </P>
        <P>
          To determine the appropriate retention period for personal data, we consider the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data and whether we can achieve those purposes
          through other means, and the applicable legal requirements.{" "}
        </P>
        <P>
          Where we require personal data in relation to a specific form the retention period for this data will be
          clearly stated in the accompanying privacy notice.{" "}
        </P>
        <P>
          To find out about the different types of cookies we set and the retention periods please refer to our use of{" "}
          <Link to="/cookies">cookies page</Link>.
        </P>
      </Section>
      <Section>
        <SectionHeading>Legal basis for processing data </SectionHeading>
        <P>
          Under the General Data Protection Regulation (GDPR), we have to identify a legal basis for processing personal
          data. We use several legal bases when processing your personal data. These include:{" "}
        </P>
        <Ul>
          <li>
            (Article 6 (1) (b) of the GDPR) Performance of a contract: We may need to use your personal information in
            order to perform any agreement you have with us. For example, we may process your personal information in
            order to create or maintain your account.{" "}
          </li>
          <li>
            (Article 6 (1) (a) of the GDPR) Consent: We may ask for your consent to process and share your personal
            information. For example, we will ask for your consent when you are given the option to sign up to our
            newsletter.{" "}
          </li>
          <li>
            (Article 6 (1) (f) of the GDPR) Legitimate interest: We may use your personal information in our legitimate
            interest. For example, we rely on legitimate interest to analyse and improve the website plus to manage the
            SAMHE Connection Competition Prize Draw.
          </li>
          <li>
            (Article 6 (1) (e) of the GDPR) Public interest: We are a Public Authority as defined in the Freedom of
            Information Act. As such, we may receive requests which require us to process your personal data in
            fulfillment of our duties as a university. For example, a member of the public may ask us to calculate the
            total number of active users on the website in any given year.{" "}
          </li>
        </Ul>
        <P>For the processing of cookies we use your consent. </P>
      </Section>
      <Section>
        <SectionHeading>International Transfers </SectionHeading>
        <P>
          There may be a requirement to transfer information to countries outside the European Economic Area (for
          example, to a research partner). Where this information contains your personal data, Imperial College London
          will ensure that it is transferred in accordance with data protection legislation. If the data is transferred
          to a country which is not subject to a European Commission (EC) adequacy decision in respect of its data
          protection standards, Imperial College London will enter into a data sharing agreement with the recipient
          organisation that incorporates EC approved standard contractual clauses that safeguard how your personal data
          is processed.{" "}
        </P>
      </Section>
      <Section>
        <SectionHeading>Who do we share your data with? </SectionHeading>
        <P>
          For the purposes referred to in this privacy notice and relying on the bases for processing as set out above,
          we will share your personal data with certain third parties including:{" "}
        </P>
        <Ul>
          <li>
            Other College employees, agents, contractors and service providers (for example, suppliers of printing and
            mailing services, email communication services or web services, or suppliers who help us carry out any of
            the activities described above). Our third party service providers are required to enter into data
            processing agreements with us. We only permit them to process your personal data for specified purposes and
            in accordance with our policies.{" "}
          </li>
          <li>
            With the relevant agencies if free text boxes in any of our forms are used to disclose a child protection
            concern.{" "}
          </li>
          <li>
            The following Research Collaborators / Partners in the study:
            <Ul>
              <li>
                The Stockholm Environment Institute at the University of York - because they are leading the
                communications portion of the project{" "}
              </li>
              <li>Third party contractors of the research team </li>
              <li>
                The University of Cambridge, the Stockholm Environment Institute at the University of York, the
                University of Surrey, the University of Leeds, and the United Kingdom Health Security Agency (UK HSA)
                will have access to summary statistics which make use of the data we hold so us to help us make
                decisions about how to improve the website. This data will not identify any individuals.{" "}
              </li>
            </Ul>
          </li>
        </Ul>
      </Section>

      <Section>
        <SectionHeading>What rights do you have in relation to your data? </SectionHeading>
        <P>
          Under the General Data Protection Regulation (GDPR), you have a right of access to your data, a right to
          rectification, erasure (in certain circumstances), restriction, objection or portability (in certain
          circumstances). You also have a right to withdraw consent (where this applies).
        </P>
        <P>
          If you wish to exercise these rights or to find out more about how we use your information, please email
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>.
        </P>
      </Section>
      <Section>
        <SectionHeading>Links to other websites</SectionHeading>
        <P>Our Website contains links to other websites which we think might be interesting to you.</P>
        <P>
          This privacy policy only applies to the SAMHE Website and Web App and does not cover other websites that we
          link to. These websites will have their own terms and conditions and privacy policies. If you follow links
          from our Website to another one you should read the privacy policy on that website to find out what it does
          with your information.
        </P>
      </Section>
      <Section>
        <SectionHeading>Complaint</SectionHeading>
        <P>
          If you are unhappy with the way in which we have handled your personal data, we ask you to contact us in the
          first instance using <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>, so that we can try to put
          things right. If you are dissatisfied with our response you can contact Imperial College London's Data
          Protection Officer via email at dpo@imperial.ac.uk, via telephone on 020 7594 3502 and/or via post at Imperial
          College London, Data Protection Officer, Faculty Building Level 4, London SW7 2AZ.
        </P>
        <P>
          If you are still not happy with the response you receive or believe we are processing your personal data in a
          way that is not lawful you can complain to the Information Commissioner's Office (ICO). The ICO does recommend
          that you seek to resolve matters with the data controller (us) first before involving the regulator.
        </P>
        <P>You can contact the Information Commissioner's Office by:</P>
        <Ul>
          <li>Using their online complaints form </li>
          <li>
            By post to Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF{" "}
          </li>
          <li>By phone on 0303 123 1113 </li>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Last updated </SectionHeading>
        <P>
          We may need to update this Privacy Notice periodically, so we recommend that you revisit this information from
          time to time. If there are any significant changes that arise, we will ensure that this is made clear.{" "}
        </P>
        <P> Last updated {lastUpdated} </P>
      </Section>
    </DocumentStyle>
  );
};
