import React from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useGetCards } from "../../GenericComponents/CardBoard/useGetCards";
import { ErrorMessage } from "../../styles/app";
import { cardComponentTypeMap as activityCardComponentTypeMap } from "../ActivitiesDashboard/activityCardComponentMap";
import { useGetActivityMetaData } from "../ActivitiesDashboard/useGetActivityMetaData";
import { CTALinkButtonStyle } from "../../styles/links";
import { ActivityKeyCard } from "../ActivitiesDashboard/ActivitiesKey";
import { useAppSelector } from "../../Redux/hooks";
import { EActivityManagerStatus } from "../../Redux/Activities/activitiesReducer";
import { LoadingCard } from "../../GenericComponents/CardBoard/LoadingCard";
import { FilterStateContext } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { filterActivityCards } from "../ActivitiesDashboard/activityCardFilter";

const EMPTY_ARRAY = [];

const StyledSlider = styled(Slider)`
  .slick-dots li button:before {
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: ${({ theme }) => theme.typography.fontSize6};
    color: black;
  }

  .slick-prev,
  .slick-next {
    top: auto;
    z-index: 1000;
    bottom: -35px;
  }

  .slick-prev {
    left: ${({ theme }) => theme.shape.defaultPadding};
  }

  .slick-next {
    right: ${({ theme }) => theme.shape.defaultPadding};
  }

  .slick-slide > div {
    padding-top: ${({ theme }) => theme.shape.defaultPaddingMid};
    padding-left: ${({ theme }) => theme.shape.defaultPadding};
    padding-right: ${({ theme }) => theme.shape.defaultPadding};
  }

  div:focus {
    outline: none;
    border: none;
  }
`;

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 810,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ActivityViewHomePage = () => {
  const { filters } = React.useContext(FilterStateContext);
  const userRole = useAppSelector((state) => state.userState?.user?.group);
  const loadingActivityViews = useAppSelector((state) => state.activitiesState.status !== EActivityManagerStatus.IDLE);
  const errorLoadingActivityViews = useAppSelector((state) => state.activitiesState.error);

  const { cards: activityCardsData } = useGetActivityMetaData({
    filters: EMPTY_ARRAY,
    groupId: "appHomeActivityCards",
    userRole,
  });
  const filteredCards = React.useMemo(
    () => activityCardsData.filter(filterActivityCards(filters)),
    [activityCardsData, filters]
  );

  const activityCards = useGetCards({
    cardsData: filteredCards,
    cardComponentTypeMap: activityCardComponentTypeMap,
    filterHiddenCards: true,
  });
  return (
    <>
      <ActivityKeyCard key="KeyCard" />
      {activityCards?.length > 0 && (
        <StyledSlider {...sliderSettings}>
          {activityCards.map((card, index) => (
            <div key={index}>{card}</div>
          ))}
        </StyledSlider>
      )}
      {!loadingActivityViews && !errorLoadingActivityViews && !activityCards?.length ? (
        <ErrorMessage>Could not find any activity cards!</ErrorMessage>
      ) : (
        ""
      )}
      {loadingActivityViews && !errorLoadingActivityViews ? <LoadingCard /> : ""}
      {errorLoadingActivityViews ? <ErrorMessage>Error Loading Cards</ErrorMessage> : ""}
      <CTALinkButtonStyle style={{ marginTop: "4rem", backgroundColor: "#b52bd8" }} to="/app/activities">
        View all activities
      </CTALinkButtonStyle>
    </>
  );
};
