import React from "react";
import { Provider } from "react-redux";
import { MemoryRouter } from "react-router-dom";
import ReactJson from "react-json-view";
import { PopupProvider } from "@react_db_client/components.popup-panel-v2";
import * as database from "../../dummyApi/database";
import AuthenticationRequired from "../../auth/AuthProvider";
import { NewAchievementPopup } from "../../components/Achievements/NewAchievementPopup";
import { KeywordProvider } from "../../components/TextFormatter/KeyWordProvider";
import { HELP_CONTACT } from "../../config";
import { keywordMap } from "../../dummyData/keywords";
import ErrorBoundary from "../../GenericComponents/ErrorBoundary";
import { NotificationBar } from "../../GenericComponents/NotificationBar";
import { getStore } from "../../Redux/reduxStore";
import initialState from "../../Redux/initialState";
import StyleProviders from "../../styles/StyleProviders";
import { lightTheme } from "../../styles/themes";
import { setLoggedInState } from "../testing/test-utils";
import { initialStateLoggedIn } from "../../Redux/initialState.mock";
import { useAppSelector } from "../../Redux/hooks";
import { demoUsers } from "../../dummyData/users";
import { IUser } from "../../lib/User/IUser";
import { UserGroup } from "../../lib/User/UserGroup";
import ApiProvider, { IApiContext } from "../../Redux/Api/ApiContext";
import { AppPageStyle } from "../../styles/app";

const setDim = (diff: number) => (prev: "auto" | "100%" | string) => {
  if (prev === "auto" || prev === "100%") return `${5}rem`;
  const prevDim = parseInt(prev.replace("rem", "")) + diff;
  return `${prevDim}rem`;
};

export interface CompositionWrapProps {
  children: React.ReactNode;
  width?: number;
  height?: number;
  horizontal?: boolean;
}

export function CompositionWrap({ children, width = null, height = null, horizontal = false }) {
  const [heightActive, setHeightActive] = React.useState(height == null ? "100%" : height);
  const [widthActive, setWidthActive] = React.useState(width == null ? "100%" : width);
  const [allowOverflow, setAllowOverflow] = React.useState(false);
  const [maximized, setMaximized] = React.useState(true);

  const styleOuter: React.CSSProperties = {
    outline: "1px solid red",
    margin: 0,
    padding: "1rem",
    height: heightActive,
    width: widthActive,
    overflow: allowOverflow ? "visible" : "hidden",
  };
  const styleInner: React.CSSProperties = {
    outline: "1px solid green",
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    overflow: allowOverflow ? "visible" : "hidden",
    display: horizontal ? "flex" : "block",
    position: "relative",
  };

  const btnStyle = (isOn) => ({
    background: isOn ? "green" : "red",
  });

  if (maximized)
    return (
      <>
        <button
          onClick={() => setMaximized(false)}
          style={{ position: "absolute", right: 0, top: 0, opacity: 0.3, zIndex: 100 }}
        >
          Minimize
        </button>
        {children}
      </>
    );

  return (
    <div>
      <button onClick={database.initAll}>Clear Storage</button>
      <button onClick={() => setHeightActive(setDim(5))}>+5 Height</button>
      <button onClick={() => setWidthActive(setDim(5))}>+5 Width</button>
      <button onClick={() => setHeightActive(setDim(-5))}>-5 Height</button>
      <button onClick={() => setWidthActive(setDim(-5))}>-5 Width</button>
      <button style={btnStyle(allowOverflow)} onClick={() => setAllowOverflow((prev) => !prev)}>
        Overflow
      </button>

      <div style={{ overflow: "auto", maxHeight: "80vh" }}>
        <div style={styleOuter}>
          <div style={styleInner}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export const ReduxStoreInfo = () => {
  const state = useAppSelector((state) => state);
  return (
    <div style={{ width: "40rem", height: "30rem", overflow: "auto" }}>
      <ReactJson src={state || {}} />
    </div>
  );
};

const defaultStore = getStore(initialState);

export interface IStorybookProvidersProps {
  children: React.ReactNode;
  user?: IUser;
  store?;
  startUrl?: string;
  allowLoggedOut?: boolean;
  apiContextOverrides?: Partial<IApiContext>;
}

export const StoryProviders = ({
  children,
  store = null,
  user: userIn = demoUsers[UserGroup.STUDENT],
  startUrl = "/",
  allowLoggedOut = false,
  apiContextOverrides = {},
}: IStorybookProvidersProps) => {
  const [storeInit, setStoreInit] = React.useState(defaultStore);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userId, setUserId] = React.useState(userIn?.username);
  const [user, setUser] = React.useState(userIn);

  const changeUser = (newUser: string) => {
    if (newUser === "") {
      setLoggedIn(false);
    } else {
      setUser(Object.values(demoUsers).find((f) => f.username === newUser));
    }
    setUserId(newUser);
  };

  React.useEffect(() => {
    if (user) {
      localStorage.clear();
      setLoggedInState(user);
      setStoreInit(store || getStore(initialStateLoggedIn(user) as any));
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      localStorage.clear();
      if (store) setStoreInit(store);
    }
    return () => {
      localStorage.clear();
    };
  }, [user, store]);

  if (user) {
    localStorage.clear();
    setLoggedInState(user);
  } else {
    localStorage.clear();
  }
  if (!loggedIn && !allowLoggedOut) return <></>;

  return (
    <MemoryRouter initialEntries={[startUrl]}>
      <ApiProvider apiContextOverrides={apiContextOverrides}>
        <StyleProviders theme={lightTheme}>
          <PopupProvider>
            <KeywordProvider keywordMap={keywordMap}>
              <Provider store={storeInit}>
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  <NotificationBar />
                  <NewAchievementPopup />
                  <AuthenticationRequired>
                    <div style={{ position: "absolute", top: 0, left: 0, zIndex: 100, opacity: 0.5 }}>
                      <select value={userId} onChange={(e) => changeUser(e.target.value as UserGroup)}>
                        {[userIn, ...Object.values(demoUsers).filter((u) => u?.username !== userIn?.username)].map(
                          (u, i) => (
                            <option value={u?.username} key={u?.username || i}>
                              {u?.username}
                            </option>
                          )
                        )}
                        <option value={""}>Logged out</option>
                      </select>
                    </div>
                    <ErrorBoundary addr={HELP_CONTACT} emailSubject="SAMHE App Error Report">
                      <CompositionWrap>
                        <div
                          style={{
                            height: "100%",
                            position: "absolute",
                            top: "2rem",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            overflow: "auto",
                            borderTop: "1px solid black",
                          }}
                          data-testid="contentWrap"
                        >
                          {children}
                        </div>
                      </CompositionWrap>
                    </ErrorBoundary>
                  </AuthenticationRequired>
                  {/* <UserInfo />
                <ReduxStoreInfo /> */}
                </div>
              </Provider>
            </KeywordProvider>
          </PopupProvider>
        </StyleProviders>
      </ApiProvider>
    </MemoryRouter>
  );
};

export const AppPageStorybookWrap = (props: IStorybookProvidersProps) => (
  <StoryProviders {...props}>
    <AppPageStyle
      data-testid="samheAppContent"
      style={{ width: "100%", maxWidth: "1200px", minWidth: "auto", margin: "auto" }}
    >
      {props.children}
    </AppPageStyle>
  </StoryProviders>
);
