import { EStepTypes, IStep, IStepProps, TStepAdditionalProps, TStepState } from "../../../lib/Activities/IStep";
import { DataRangeSnapshotStep } from "../StepTypes/DataRangeSnapshotStep";
import { IDataRangeSnapshotExtraProps } from "../StepTypes/DataRangeSnapshotStep/DataRangeSnapshotStep";
import { DataSnapshotStep, IDataSnapshotExtraProps } from "../StepTypes/DataSnapshotStep";
import {
  FeedbackFreetextStep,
  IFeedbackFreetextExtraProps,
  IFeedbackFreetextState,
} from "../StepTypes/FeedbackFreetextStep/FeedbackFreetextStep";
import { FeedbackStep, IFeedbackStepExtraProps } from "../StepTypes/FeedbackStep/FeedbackStep";
import { IInfoExtraProps, IInfoState, InfoStep } from "../StepTypes/InfoStep/InfoStep";
import { IMultipleChoiceExtraProps, IMultipleChoiceState, MultipleChoiceStep } from "../StepTypes/MultiChoiceStep";
import { IQuizMultiChoiceExtraProps, IQuizMultiChoiceState, QuizMultiChoiceStep } from "../StepTypes/QuizMultiChoice";
import {
  IRoomSelectionStepExtraProps,
  IRoomSelectionStepState,
  RoomSelectionStep,
} from "../StepTypes/RoomSelection/RoomSelectionStep";
import { ITextInputExtraProps, ITextInputState, TextInputStep } from "../StepTypes/TextInputStep/TextInputStep";
import { IVideoStepExtraProps, VideoStep } from "../StepTypes/VideoStep/VideoStep";

export const StepComponentMap: Record<EStepTypes, React.FC<IStepProps<TStepAdditionalProps, TStepState>>> = {
  [EStepTypes.INFO]: (props) => <InfoStep {...(props as IStepProps<IInfoExtraProps, IInfoState>)} />,
  [EStepTypes.TEXT_INPUT]: (props) => (
    <TextInputStep {...(props as IStepProps<ITextInputExtraProps, ITextInputState>)} />
  ),
  [EStepTypes.VIDEO]: (props) => <VideoStep {...(props as IStep<IVideoStepExtraProps>)} />,
  [EStepTypes.MULTIPLE_CHOICE]: (props) => (
    <MultipleChoiceStep {...(props as IStepProps<IMultipleChoiceExtraProps, IMultipleChoiceState>)} />
  ),
  [EStepTypes.DATA_SNAPSHOT]: (props) => <DataSnapshotStep {...(props as IStep<IDataSnapshotExtraProps>)} />,
  [EStepTypes.TIMED]: (props) => <InfoStep {...(props as IStepProps<IInfoExtraProps, IInfoState>)} />,
  [EStepTypes.FEEDBACK]: (props) => <FeedbackStep {...(props as IStep<IFeedbackStepExtraProps>)} />,
  [EStepTypes.DATA_RANGE_SNAPSHOT]: (props) => (
    <DataRangeSnapshotStep {...(props as IStep<IDataRangeSnapshotExtraProps>)} />
  ),
  [EStepTypes.QUIZ_MULTI_CHOICE]: (props) => (
    <QuizMultiChoiceStep {...(props as IStepProps<IQuizMultiChoiceExtraProps, IQuizMultiChoiceState>)} />
  ),
  [EStepTypes.ROOM_SELECTION]: (props) => (
    <RoomSelectionStep {...(props as IStepProps<IRoomSelectionStepExtraProps, IRoomSelectionStepState>)} />
  ),
  [EStepTypes.FEEDBACK_FREETEXT]: (props) => (
    <FeedbackFreetextStep {...(props as IStepProps<IFeedbackFreetextExtraProps, IFeedbackFreetextState>)} />
  ),
};
