import styled from "styled-components";

export const FlexBoxCellInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1rem;

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    padding: 2rem 4rem;
  }
`;

export const InfoBlock = styled(FlexBoxCellInner)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;

  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.main};

  h1,
  h2 {
    font-size: ${({ theme }) => theme.typography.fontSize5};
  }

  h3 {
    font-size: ${({ theme }) => theme.typography.fontSize4};
  }

  img {
    max-width: 80%;
    max-height: 3rem;
  }
`;

export interface IFlexBoxProps {
  flip?: boolean;
  center?: boolean;
  left?: boolean;
  horiz?: boolean;
  flexwrap?: boolean;
}

export const FlexBox = styled.div<IFlexBoxProps>`
  display: flex;
  flex-wrap: ${({ flexwrap }) => (flexwrap ? "wrap" : "noWrap")};
  flex-direction: ${({ horiz }) => (horiz ? "row" : "column")};

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    justify-content: space-between;
    align-items: stretch;

    ${({ center }) => (center ? `justify-content:center; align-items:center;` : "")}
    ${({ left }) =>
      left
        ? `
          justify-content:flex-start;
          align-items:center;
            `
        : ""}


    flex-direction: ${({ flip, horiz }) =>
      horiz ? (flip ? "row-reverse" : "row") : flip ? "column-reverse" : "column"};
  }
`;

export const FlexList = styled.ul<IFlexBoxProps>`
  display: flex;
  list-style: none;
  margin: 0;
  flex-wrap: ${({ flexwrap }) => (flexwrap ? "wrap" : "noWrap")};
  flex-direction: ${({ horiz }) => (horiz ? "row" : "column")};
  margin-right: -${({ theme }) => theme.shape.defaultPaddingSmall};
  margin-bottom: -${({ theme }) => theme.shape.defaultPaddingSmall};

  li {
    margin-right: ${({ theme }) => theme.shape.defaultPaddingSmall};
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingSmall};
  }

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    justify-content: space-between;
    align-items: stretch;

    ${({ center }) =>
      center
        ? `
        justify-content:center;
        align-items:center;
          `
        : ""}
    ${({ left }) =>
      left
        ? `
          justify-content:flex-start;
          align-items:center;
            `
        : ""}


    flex-direction: ${({ flip, horiz }) =>
      horiz ? (flip ? "row-reverse" : "row") : flip ? "column-reverse" : "column"};
    flex-wrap: wrap;
  }
`;

export const FlexBoxCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: 50%;
    justify-content: center;
  }
`;

export const Br = styled.div`
  height: ${({ theme }) => theme.shape.defaultPadding};
  width: 100%;
`;
