import { CardComponentTypeMap } from "../../GenericComponents/CardBoard";
import { EResourceType } from "../../lib/AppResources/IAppResource";
import { ResourceDownloadCard } from "./AppResourcesCard/AppResourcesDownloadCard";
import { ResourcePlainTextCard } from "./AppResourcesCard/AppResourcesPlainTextCard";
import { ResourcePopupInfoCard } from "./AppResourcesCard/AppResourcesPopupInfoCard";
import { IResourceCardProps } from "./AppResourcesCard/IAppResourceCardProps";

export const cardComponentTypeMap: CardComponentTypeMap<EResourceType, IResourceCardProps> = {
  [EResourceType.DOWNLOAD]: {
    id: EResourceType.DOWNLOAD,
    Component: ResourceDownloadCard,
  },
  [EResourceType.CERTIFICATE]: {
    id: EResourceType.CERTIFICATE,
    Component: ResourceDownloadCard,
  },
  [EResourceType.POPUP_INFO]: {
    id: EResourceType.POPUP_INFO,
    Component: ResourcePopupInfoCard,
  },
  [EResourceType.PLAIN_TEXT]: {
    id: EResourceType.PLAIN_TEXT,
    Component: ResourcePlainTextCard,
  },
};
