import { EChartsOption, ScatterSeriesOption, XAXisComponentOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { getColors, usePreprocessScatterData } from "./preProcessData";
import { DataMetricsMetaData, EDataMetricsStrId, MetricStrIdMap } from "../../../lib/DataViews/EDataMetrics";
import styled from "styled-components";

export interface ISimpleScatterProps {
  dataX: IMonitorDataItem<number>[];
  dataY: IMonitorDataItem<number>[];
  yLabel: string;
  xLabel: string;
  metricX: EDataMetricsStrId;
  metricY: EDataMetricsStrId;
  fontSize?: string | number;
  showLegend?: boolean;
  equalAspectRatio?: boolean;
  startAxisAtZero?: boolean;
  compact?: boolean;
}

export const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;

  .reactECharts-container {
    height: 100%;
    aspect-ratio: 1/1;
  }
`;

export const constructAxis = (xAxisName: string, fontSize: string | number, dateFormatter): XAXisComponentOption => {
  return {
    type: "time",
    axisLabel: {
      margin: 7,
      formatter: dateFormatter,
    },
    name: xAxisName,
    nameLocation: "middle",
    nameGap: 50,
    nameTextStyle: {
      fontSize,
      fontWeight: "bold",
    },
  };
};

export const SimpleScatter = ({
  dataX,
  dataY,
  xLabel,
  yLabel,
  metricX,
  metricY,
  fontSize = "1rem",
  showLegend,
  equalAspectRatio,
  startAxisAtZero,
  compact,
}: ISimpleScatterProps) => {
  const [series, hours] = usePreprocessScatterData(dataX, dataY, metricX, metricY);
  const colors = getColors(hours);
  const colorByHour = hours.length > 1;
  const metricXData = DataMetricsMetaData[MetricStrIdMap[metricX]];
  const metricYData = DataMetricsMetaData[MetricStrIdMap[metricY]];
  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: "item",
      position: "top",
      formatter: compact
        ? undefined
        : (params) => {
            const [xVal, yVal] = params.value;
            const bottomLine = `<b>${params.marker}${showLegend ? params.seriesName : ""}</b>: ${xVal}`;
            return `${yVal} ${metricYData.unit}<br>${bottomLine} ${metricXData.unit}`;
          },
    },
    grid: {
      containLabel: true,
      right: "20%",
    },
    xAxis: {
      name: xLabel,
      type: "value",
      scale: true,
      nameLocation: "middle",
      nameGap: 27,
      nameTextStyle: {
        fontSize,
        fontWeight: "bold",
      },
      axisLabel: {
        fontSize,
      },
      interval: compact ? 99999 : undefined,
      splitLine: {
        show: false,
      },
      boundaryGap: ["0%", "10%"],
      min: startAxisAtZero ? 0 : undefined,
    },
    yAxis: {
      type: "value",
      scale: true,
      axisLabel: {
        fontSize,
      },
      interval: compact ? 99999 : undefined,
      name: yLabel,
      nameLocation: "middle",
      nameGap: 45 + (Math.max(...dataY.map((d) => d.value)).toString().length + 1) * 1.7, // Hack to stop text overlapping y values
      nameTextStyle: {
        fontSize,
        fontWeight: "bold",
      },
      boundaryGap: ["0%", "10%"],
      min: startAxisAtZero ? 0 : undefined,
    },
    itemStyle: {
      borderColor: "grey",
    },
    title:
      showLegend && colorByHour
        ? {
            text: "Time",
            right: 30,
            top: 0,
          }
        : undefined,
    legend:
      showLegend && colorByHour
        ? {
            data: hours,
            orient: "vertical",
            right: 0,
            top: 30,
            itemStyle: {
              borderColor: "grey",
              borderType: "solid",
              borderWidth: 1,
            },
          }
        : undefined,
    color: colorByHour ? colors : colors[0],
    series: series as ScatterSeriesOption[],
  };

  return (
    <ContainerStyle data-testid="reactECharts-containerOuter">
      <ReactECharts
        className="reactECharts-container"
        key={Date.now()}
        option={chartOptions}
        style={{ width: "100%", height: "100%", maxHeight: "38rem", maxWidth: equalAspectRatio ? "38rem" : "100%" }}
      />
    </ContainerStyle>
  );
};
