import React from "react";
import { switchUserGroup } from "../../Redux/User/userReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { AppHeaderDropdown } from "../Header/appHeaderDropdown";
import { UserGroups } from "../../lib/User/UserGroup";

export const UserGroupSelectionDropdown = () => {
  const {
    user: { group, additionalRoles },
  } = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();
  // TODO: Selecting a room should send this data to the api

  if (additionalRoles?.length > 1)
    return (
      <AppHeaderDropdown
        id="role-select"
        label="Role"
        selectOptions={additionalRoles.map((r) => ({
          id: r,
          label: UserGroups[r]?.label || r,
        }))}
        value={group}
        onChange={(newGroupSelection) => dispatch(switchUserGroup(UserGroups[newGroupSelection]?.uid))}
      />
    );

  return <></>;
};
