import { IDataViewFull, TDataAdditionalProps } from "../../lib/DataViews/IDataView";
import { simpleCO2BubblePlot } from "./DataViewTypes/BubblePlotView/examples";
import { exampleDataDownloadCard } from "./DataViewTypes/DataDownload/examples";
import { simpleCO2Plot, simplePlot, simpleTemperaturePlot } from "./DataViewTypes/SimplePlot/examples";
import {
  simpleCO2ScatterPlot,
  simpleScatterPlot,
  simpleTemperatureScatterPlot,
} from "./DataViewTypes/SimpleScatter/examples";
import { simpleCO2BarPlot, simpleBarPlot, simpleTemperatureBarPlot } from "./DataViewTypes/SimpleBar/examples";

export const DEMO_DATA_VIEWS: Record<string | number, IDataViewFull<TDataAdditionalProps>> = {
  simplePlot,
  simpleTemperaturePlot,
  simpleCO2Plot,
  simpleCO2BubblePlot,
  exampleDataDownloadCard,
  simpleCO2ScatterPlot,
  simpleTemperatureScatterPlot,
  simpleScatterPlot,
  simpleCO2BarPlot,
  simpleBarPlot,
  simpleTemperatureBarPlot,
};
