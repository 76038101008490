import { AggregationIntervals, EAggregationInterval } from "../../lib/MonitorData/AggregationIntervals";
import { ErrorMessage } from "../../styles/app";
import { IDateRangeState } from "./lib";

export const DateRangeInfo = ({
  dateRange,
  showTimeRange, // Only used for debugging
  showAggregation, // Only used for debugging
  debug,
}: {
  dateRange: IDateRangeState;
  showTimeRange?: boolean;
  showAggregation?: boolean;
  debug?: boolean;
}) => (
  <div style={{ marginTop: "1rem" }}>
    <div data-testid="dataRangeSnapshot-currentDateSelection">
      {dateRange.invalid ? (
        <ErrorMessage data-testid="dateRangePicker_invalidMessage">
          <p>There is a problem with the date selection:</p>
          <p>{dateRange.invalid}</p>
        </ErrorMessage>
      ) : (
        <p>
          Showing all available data from: {dateRange.startDate.toLocaleTimeString()}{" "}
          {dateRange.startDate.toLocaleDateString()} to {dateRange.endDate.toLocaleTimeString()}{" "}
          {dateRange.endDate.toLocaleDateString()}
        </p>
      )}
    </div>
    {showTimeRange && (
      <p data-testid="dataRangeSnapshot-currentTimeSelection">
        Data is limited each day between these times: {dateRange.startTimeLimit.toLocaleTimeString()} -{" "}
        {dateRange.endTimeLimit.toLocaleTimeString()}
      </p>
    )}
    {showAggregation && dateRange.aggregation > EAggregationInterval.NONE && (
      <p data-testid="dataRangeSnapshot-currentAggregationSelection">
        Data is aggregated to {AggregationIntervals[dateRange.aggregation].label}
      </p>
    )}
    {showAggregation && dateRange.aggregation === EAggregationInterval.NONE && (
      <p data-testid="dataRangeSnapshot-currentAggregationSelection">Not aggregating data</p>
    )}
    {debug && <p style={{ background: "lightgrey" }}>{JSON.stringify(dateRange).split(",").join("\n\n")}</p>}
  </div>
);
