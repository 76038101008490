import React from "react";
import styled from "styled-components";
import { CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";
import { DateRangePicker } from "../../../DateRangePicker/DateRangePicker";
import { ExpandedCardSection, H2 } from "../../../../styles/app";
import { FlexBox, FlexList } from "../../../../styles/singlePageStyles";
import { DateRangeInfo } from "../../../DateRangePicker/DateRangeInfo";
import { CardInfoSectionWrapper } from "../../../../GenericComponents/CardBoard/styles";
import { EDayRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { SelectDropdown } from "../../../../GenericComponents/FormElements/Select";
import { EDataMetrics } from "../../../../lib/DataViews/EDataMetrics";
import { weeks } from "../../../DateRangePicker/utils";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";

export interface ISimpleScatterOptions {
  id: string | number;
  reloadData: () => void;
  loading: boolean;
  setLiveUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  liveUpdate: boolean;
  setDateRange: React.Dispatch<React.SetStateAction<IDateRangeState>>;
  dateRange: IDateRangeState;
  metricOptions: { id: EDataMetrics; label: string }[];
  currentXMetric: EDataMetrics;
  setCurrentXMetric: React.Dispatch<React.SetStateAction<EDataMetrics>>;
  currentYMetric: EDataMetrics;
  setCurrentYMetric: React.Dispatch<React.SetStateAction<EDataMetrics>>;
  startAxisAtZero: boolean;
  setStartAxisAtZero: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SimpleScatterOptionsWrap = styled.div`
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const SimpleScatterOptions: React.FC<ISimpleScatterOptions> = ({
  id,
  reloadData,
  loading,
  setLiveUpdate,
  liveUpdate,
  setDateRange,
  dateRange,
  metricOptions,
  currentXMetric,
  setCurrentXMetric,
  currentYMetric,
  setCurrentYMetric,
  startAxisAtZero,
  setStartAxisAtZero,
}) => {
  return (
    <SimpleScatterOptionsWrap>
      <CardInfoSectionWrapper open maxHeight="55rem" style={{ width: "100%" }}>
        <CollapsableSectionInner>
          <FlexBox horiz flexwrap style={{ gap: "1rem" }}>
            <ExpandedCardSection>
              <H2>Data Refreshing </H2>
              <p>Select when your data should reload</p>
              <FlexList>
                <li>
                  <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading}>
                    {(loading && "Loading data") || "Refresh data"}
                  </CTAButtonStyle>
                </li>
                <li>
                  <OnOffButtonStyle onClick={() => setLiveUpdate((prev) => !prev)} on={liveUpdate ? "true" : undefined}>
                    Live Update
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            <ExpandedCardSection>
              <H2>Plot View Options</H2>
              <p>Select how your data is plotted</p>
              <FlexList left horiz flexwrap>
                <li>
                  <OnOffButtonStyle
                    onClick={() => setStartAxisAtZero((prev) => !prev)}
                    on={startAxisAtZero ? "true" : undefined}
                  >
                    Start axis at 0
                  </OnOffButtonStyle>
                </li>
              </FlexList>
            </ExpandedCardSection>
            {metricOptions.length > 1 && (
              <ExpandedCardSection style={{ marginRight: "1rem", marginTop: "1rem", marginBottom: "1rem" }}>
                <H2>Metrics</H2>
                <p>Pick the metrics you want to view on each axis</p>
                <FlexBox>
                  <div>
                    <label htmlFor={`${id}_metricSelect_x`}>Metric X axis: </label>
                    {metricOptions.length > 1 && (
                      <SelectDropdown
                        id={`${id}_metricSelect_x`}
                        selectOptions={metricOptions}
                        value={currentXMetric}
                        onChange={(v) => setCurrentXMetric(v)}
                      />
                    )}
                  </div>
                  <div>
                    <label htmlFor={`${id}_metricSelect_y`} style={{ marginTop: "1rem" }}>
                      Metric y axis:{" "}
                    </label>
                    {metricOptions.length > 1 && (
                      <SelectDropdown
                        id={`${id}_metricSelect_y`}
                        selectOptions={metricOptions}
                        value={currentYMetric}
                        onChange={(v) => setCurrentYMetric(v)}
                      />
                    )}
                  </div>
                </FlexBox>
              </ExpandedCardSection>
            )}
            <ExpandedCardSection style={{ flex: "10 1 25rem" }}>
              <DateRangePicker
                onChange={(newDateRange) => setDateRange(newDateRange)}
                value={dateRange}
                useAggregation
                allowTimeLimiting={false}
                allowedDayRanges={[EDayRange.TODAY, EDayRange.YESTERDAY, EDayRange.CUSTOM]}
                allowedMaxDateRange={weeks(1)}
                allowedMinDateRange={EAggregationInterval.FIVE_MINUTE}
                allowedMaxAggregation={EAggregationInterval.HOUR}
              />
              <DateRangeInfo dateRange={dateRange} showAggregation showTimeRange />
            </ExpandedCardSection>
          </FlexBox>
        </CollapsableSectionInner>
      </CardInfoSectionWrapper>
    </SimpleScatterOptionsWrap>
  );
};
