import styled from "styled-components";
import { CTAButtonStyle } from "../../styles/button";
import { ExpandedPanelInnerStyle } from "../../styles/popups";
import { FlexList } from "../../styles/singlePageStyles";

export const TagsListStyle = styled(FlexList)``;

export const TagListItemStyle = styled.li`
  margin-right: 0.2rem;
  position: relative;
  margin-bottom: 0.2rem;
`;

export const TagButtonStyle = styled(CTAButtonStyle)`
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  background: ${({ theme }) => theme.colors.cta};
  color: ${({ theme }) => theme.colors.ctaText};
  padding: 0.5rem;
  line-height: 1rem;
  cursor: default;
`;

export const TagTooltipStyle = styled.div`
  max-width: 100%;
  max-height: 30rem;
  min-width: 20rem;
  line-height: ${({ theme }) => theme.typography.lineHeight};
  position: absolute;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.colors.main};
  bottom: 1rem;
  left: 100%;
  z-index: 100;
  padding: 1rem;
  box-shadow: 3px 0 3px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-width: 40rem;
  }
`;

export const TagPopupStyle = styled(ExpandedPanelInnerStyle)`
  background: ${({ theme }) => theme.background};
  min-width: 20rem;
  min-height: 20rem;

  max-width: 60rem;
  max-height: 40rem;
  margin: auto;
  .popupTitle {
    margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  }
`;
