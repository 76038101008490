import { Link } from "react-router-dom";
import { DocumentStyle } from "../../styles/documents";
import { PageTitle, SectionHeading, PLong } from "../../styles/page";
import { DownloadLink, LinkExternal } from "../Links";

export const lastUpdated = "05/04/2023";
export const version = "3.1";

export const ParticipationInfo = () => {
  return (
    <DocumentStyle>
      <PageTitle style={{ margin: 0 }}>SAMHE Participant Information Sheet</PageTitle>
      <PLong>Version: {version}</PLong>
      <PLong> Last updated {lastUpdated} </PLong>
      <SectionHeading>Schools' Air Quality Monitoring for Health and Education (SAMHE)</SectionHeading>
      <PLong>
        This project is jointly led by Imperial College London (Dr Henry Burridge from the Civil and Environmental
        Engineering Department) and the University of Cambridge (Professor Paul Linden) in collaboration with the
        Stockholm Environment Institute (SEI) at the University of York, the University of Surrey, the University of
        Leeds and the UK Health Security Agency (UK HSA).
      </PLong>
      <PLong>
        We appreciate your interest in participating in SAMHE. Before you sign up your school by completing this
        registration form, we'd like to explain a bit more about why the research is being done and what participating
        in SAMHE will involve.
      </PLong>
      <PLong>
        <b>Please take time to read the following information carefully and discuss it with others if you wish.</b>
      </PLong>
      <PLong>
        This registration form is designed to capture the information we require in order to send you a monitor at your
        school. The contact details you provide in this form will also be used to share with you relevant materials
        including instructions on how to set up the monitor. You also have the option of signing up to our project
        newsletter. The rest of your response will be used to allow us to track and evaluate participation of schools in
        the project in relation to our intention to develop an air quality monitor network which is representative of
        the UK's schools. Information at the school level will also be used by the project team to make decisions about
        which schools should receive monitors in the event that we are oversubscribed. We may share names of schools
        with organisations who help us promote SAMHE, in order to assess the efficacy of our promotion mechanisms.
      </PLong>
      <PLong>
        Once your school is enrolled on the project, we will gift you a monitor and provide you with free access to our
        specially designed Web App to accommodate interactions with your monitor data. The data gathered will be a
        registered asset of Imperial College London.
      </PLong>
      <PLong>
        If there is anything that is not clear or if you would like more information, please{" "}
        <Link to="/contact">contact us</Link>.
      </PLong>
      <PLong>
        Participating in this research is entirely voluntary and you are free to withdraw at any time. You can stop this
        registration form at any time before submitting by closing the browser. If you want to withdraw your personal
        data after submitting this form you can do so by contacting the project team using{" "}
        <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. You will not be required to provide a reason if you
        wish to withdraw and there will be no repercussions if you decide to do so. If you withdraw, we will keep the
        information you provided in relation to your school, so that we can continue project work.
      </PLong>
      <PLong>Thank you for reading this.</PLong>
      <PLong>
        You will be able to{" "}
        <DownloadLink href="/resources/SAMHE_Registration_Form_Participant_Information_Sheet.pdf">
          download a copy
        </DownloadLink>{" "}
        of this information sheet for your records.
      </PLong>
      <SectionHeading>What is the purpose of the study?</SectionHeading>
      <PLong>
        The purpose of this project is to establish an air quality monitoring network in schools across the UK and
        through the network record measurements of carbon dioxide and selected air pollutants to evaluate classroom
        ventilation levels.
      </PLong>
      <PLong>
        The monitors we provide schools are for schools to keep. They measure carbon dioxide, particulate matter,
        volatile organic compounds, temperature and humidity. Our Web App will allow teachers and pupils to see how
        these measurements change over time and space.
      </PLong>
      <PLong>
        Through their interactions with the SAMHE Web App both teachers and pupils will gain an enhanced understanding
        of air quality science. We hope that monitors provided as part of the project will enable you, as a staff member
        at a school, to monitor air quality in teaching environments and make informed decisions to control ventilation
        and improve air quality. The Web App will allow you to see the effects of any changes you make on air quality.
      </PLong>
      <SectionHeading>What will happen if my school takes part?</SectionHeading>
      <PLong>
        Participating schools will receive an air quality monitor, instructions for setting it up and support via the
        Web App.
      </PLong>
      <SectionHeading>What if something goes wrong?</SectionHeading>
      <PLong>
        <b>
          By accepting the gift of a SAMHE monitor, your school is accepting responsibility for the monitor, its safe
          usage within your school, and any liability that arises as a result. For advice on the safe usage of the air
          quality monitor, including a risk assessment covering the usage of the air quality monitor, please see the
          documentation on our <Link to="/safety-and-data-protection">Safety and Data protection page</Link>.
        </b>
      </PLong>
      <PLong>
        If you are harmed by taking part in this research project, there are no special compensation arrangements. If
        you are harmed due to someone's negligence, then you may have grounds for a legal action. Regardless of this, if
        you wish to complain, or have any concerns about any aspect of the way you have been treated during the course
        of this study then you should immediately inform the project team using{" "}
        <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. If you are still not satisfied with the response,
        you may contact the Imperial College Research Governance Integrity Team (rgitcoordinator@imperial.ac.uk).
      </PLong>
      <SectionHeading>What will happen to the results of the research study?</SectionHeading>
      <PLong>
        Results from this study will be analysed and summarised in academic papers which will be made publicly
        available. Once these have been published, they will be made available on the SAMHE website
        (https://samhe.org.uk). Data supporting the Web App will be stored in a data repository and be made available to
        other researchers. No personal data will be included in data repositories. You will not be identified in any
        project outputs and it will not be possible for anyone to work out that you took part in the study.
      </PLong>
      <SectionHeading>Who is organising and funding the research?</SectionHeading>
      <PLong>
        Initial funding for this project was granted by the Department for Education, which continues to be involved.
        Funding for the main project is provided by the Engineering and Physical Sciences Research Council (EPSRC).{" "}
      </PLong>
      <SectionHeading>Who has reviewed the study?</SectionHeading>
      <PLong>
        This study was given approval by Washington Ochieng, Head of Department (Civil and Environmental Engineering)
        and Research Governance and Integrity Team (RGIT).
      </PLong>
      <SectionHeading>Contact for Further Information</SectionHeading>
      <PLong>
        For more information about how we use your information you can view our{" "}
        <LinkExternal to="/privacy/registration" aria-label="Open privacy policy in new tab">
          Privacy Policy
        </LinkExternal>{" "}
        . If you would like any further information about this project please contact the project team using
        hello@samhe.org.uk. Thanks for taking part!
      </PLong>
    </DocumentStyle>
  );
};