import styled from "styled-components";
import { KeyIconStyle, KeyItemLabel, KeySectionStyle } from "../../../styles/app";
import { FlexBox } from "../../../styles/singlePageStyles";

export interface INoOfPeopleIconProps {
  count: string;
}

export const CountStyle = styled.p`
  margin-top: 0.1rem;
  text-align: center;
`;

export const NoOfPeopleIconImgStyle = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const NoOfPeopleIcon: React.FC<INoOfPeopleIconProps> = ({ count }) => {
  if (!count) throw new Error("Did not provide no of people ");
  if (count === "1")
    return (
      <>
        <NoOfPeopleIconImgStyle src="/images/app/icons/Number_Of_People_Solo.png" alt="One person required" />
        {/* <CountStyle style={{ marginTop: "0.1rem" }}>1</CountStyle> */}
      </>
    );
  if (count === "2")
    return (
      <>
        <NoOfPeopleIconImgStyle src="/images/app/icons/Number_Of_People_Duo.png" alt="Two people required" />
        {/* <CountStyle style={{ marginTop: "0.1rem" }}>2</CountStyle> */}
      </>
    );
  return (
    <>
      <NoOfPeopleIconImgStyle src="/images/app/icons/Number_Of_People_Group.png" alt="Multiple people required" />
      {/* <CountStyle style={{ marginTop: "0.1rem" }}>{count}</CountStyle> */}
    </>
  );
};

export const NoOfPeopleKeyItems = () => (
  <KeySectionStyle>
    <FlexBox horiz center>
      <KeyIconStyle
        width="2rem"
        height="2rem"
        src="/images/app/icons/Number_Of_People_Solo.png"
        alt="One person required"
      />
      <KeyItemLabel>1 person required</KeyItemLabel>
    </FlexBox>
    <FlexBox horiz center>
      <KeyIconStyle
        width="2rem"
        height="2rem"
        src="/images/app/icons/Number_Of_People_Duo.png"
        alt="Two people required"
      />
      <KeyItemLabel>2 people required</KeyItemLabel>
    </FlexBox>
    <FlexBox horiz center>
      <KeyIconStyle
        width="2rem"
        height="2rem"
        src="/images/app/icons/Number_Of_People_Group.png"
        alt="Multiple people required"
      />
      <KeyItemLabel>3 people required</KeyItemLabel>
    </FlexBox>
  </KeySectionStyle>
);
