import React from "react";
import { useMeasure } from "react-use";
import { useDebounce } from "../../utils/hooks/useDebounce";

export type Breakpoints = { default: number; [key: number]: number } | number;
export type TContainerRef = any;

export const useGetBreakpoints = (targetScale: number = 1): [any, Breakpoints] => {
  const [containerRef, { width }] = useMeasure();
  const [containerWidth, setContainerWidth] = React.useState(null);
  const [scaleFactor, setScaleFactor] = React.useState(1);

  React.useEffect(() => {
    if (containerWidth) {
      const newScaleFactor = window.innerWidth / containerWidth;
      if (Math.abs(newScaleFactor - scaleFactor) > 0.2) {
        setScaleFactor(newScaleFactor);
      }
    }
  }, [containerWidth, scaleFactor]);

  const breakpoints = {
    default: 6,
    [targetScale * scaleFactor * 1400]: 5,
    [targetScale * scaleFactor * 1100]: 4,
    [targetScale * scaleFactor * 910]: 3,
    [targetScale * scaleFactor * 700]: 2,
    [targetScale * scaleFactor * 450]: 1,
  };
  const refresh = useDebounce({
    timeout: 100,
    fn: (w) => setContainerWidth(w),
    allow: true,
  });

  React.useEffect(() => {
    refresh(width);
  }, [width, refresh]);

  return [containerRef, breakpoints];
};
