import styled from "styled-components";

export const TableStyle = styled.table`
  border-collapse: collapse;
  td,
  th {
    border: 1px solid #ddd;
    padding: 1rem;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.grey1};
    // color: white;
    border-bottom: 3px solid white;
  }
`;
