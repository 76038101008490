import { H2 } from "../../styles/app";
import { FlexBox } from "../../styles/singlePageStyles";
import { IDateRangePickerProps } from "./lib";
import { DateTimePicker } from "./styles";
import { days, getMaxAggregation, getMinAggregation, parseDateInDateOnly } from "./utils";

export const DateRangeDayOnlyPicker: React.FC<IDateRangePickerProps & { label?: string }> = ({
  value,
  onChange,
  useAggregation = true,
  label = "Start Date",
}) => {
  const changeDateRangeCustom = (newStartDate: string) => {
    const startDateNew = newStartDate ? new Date(newStartDate + "T00:00:00") : new Date();
    const endDateNew = new Date(startDateNew.valueOf() + days(1) - 1);
    if (
      newStartDate &&
      startDateNew instanceof Date &&
      !isNaN(startDateNew.valueOf()) &&
      endDateNew instanceof Date &&
      !isNaN(endDateNew.valueOf())
    ) {
      let aggregationNew = value.aggregation;
      let minAggregationNew = value.minAggregation;
      let maxAggregationNew = value.maxAggregation;

      if (useAggregation) {
        minAggregationNew = getMinAggregation(endDateNew.valueOf() - startDateNew.valueOf());
        maxAggregationNew = getMaxAggregation(endDateNew.valueOf() - startDateNew.valueOf());
        aggregationNew = aggregationNew >= minAggregationNew ? aggregationNew : minAggregationNew;
        aggregationNew = aggregationNew <= maxAggregationNew ? aggregationNew : maxAggregationNew;
      }
      onChange({
        ...value,
        aggregation: aggregationNew,
        minAggregation: minAggregationNew,
        maxAggregation: maxAggregationNew,
        startDate: startDateNew,
        endDate: endDateNew,
        invalid: false,
      });
    } else {
      onChange({
        ...value,
        startDate: startDateNew,
        endDate: endDateNew,
        invalid: false || "Invalid Date",
      });
    }
  };

  return (
    <FlexBox>
      <H2>Select your date range</H2>
      <span>
        <label htmlFor="dateRangePicker_startDateSelect">{label}</label>:
      </span>
      <DateTimePicker
        id="dateRangePicker_startDateSelect"
        type="date"
        value={parseDateInDateOnly(value.startDate || new Date())}
        onChange={(e) => changeDateRangeCustom(e.target.value)}
        style={{ maxWidth: "20rem" }}
      />
    </FlexBox>
  );
};
