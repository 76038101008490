import React from "react";
import { EActivityStatus } from "../../../lib/Activities/IActivity";
export interface IActivityStatusIcon extends React.HTMLProps<HTMLImageElement> {
  activityStatus: EActivityStatus;
  isLocked?: boolean;
}

export const ActivityStatusIcon = ({ activityStatus, isLocked, ...additionalProps }: IActivityStatusIcon) => {
  if (isLocked)
    return (
      <img width="2rem" height="2rem" src="/images/app/icons/Locked.png" alt="Locked" {...(additionalProps as any)} />
    );
  switch (activityStatus) {
    case EActivityStatus.NOT_STARTED:
      return (
        <img width="2rem" height="2rem" src="/images/app/icons/Ready.png" alt="Ready" {...(additionalProps as any)} />
      );
    case EActivityStatus.IN_PROGRESS:
      return (
        <img
          width="2rem"
          height="2rem"
          src="/images/app/icons/In_Progress.png"
          alt="In Progress"
          {...(additionalProps as any)}
        />
      );
    case EActivityStatus.FAILED:
      return (
        <img width="2rem" height="2rem" src="/images/app/icons/Ready.png" alt="Failed" {...(additionalProps as any)} />
      );
    case EActivityStatus.COMPLETED:
      return (
        <img
          width="2rem"
          height="2rem"
          src="/images/app/icons/Complete.png"
          alt="Complete"
          {...(additionalProps as any)}
        />
      );
    default:
      throw Error(`Invalid step status: ${activityStatus}`);
  }
};
