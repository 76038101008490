import React from "react";
import { AuthProvider, TAuthConfig } from "react-oauth2-code-pkce";
import { AUTH_REDIRECT_URI, AUTH_SCOPE, AUTH_TOKEN_URL, AUTH_URL, CLIENT_KEY } from "../config";
import { SessionManager } from "../GenericComponents/SessionManager";
import { useAppDispatch } from "../Redux/hooks";
import { returnFromLoginScreen } from "../Redux/User/userReducer";

const authConfig = (state): TAuthConfig => ({
  autoLogin: false,
  clientId: CLIENT_KEY,
  authorizationEndpoint: AUTH_URL,
  tokenEndpoint: AUTH_TOKEN_URL,
  scope: AUTH_SCOPE,
  decodeToken: false,
  redirectUri: AUTH_REDIRECT_URI || window.location.origin,
  extraAuthParameters: {
    response_mode: "query",
    state,
  },
});

function AuthenticationRequired({ children }) {
  const dispatch = useAppDispatch();
  const authConfigCurrent = {
    ...authConfig(window.location.pathname),
    postLogin: () => dispatch(returnFromLoginScreen()),
  };
  return (
    <AuthProvider authConfig={authConfigCurrent}>
      <SessionManager alwaysShowChildren>{children}</SessionManager>
    </AuthProvider>
  );
}

export default AuthenticationRequired;
