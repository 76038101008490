import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch, DefaultThunkAction } from "./reduxStore";

type Wrap<T> = { [K in keyof T]-?: [T[K]] };
type Unwrap<T> = { [K in keyof T]: Extract<T[K], [any]>[0] };

type RestParameters<F extends (...args: any) => any> = Wrap<Parameters<F>> extends [any, ...infer InitPs]
  ? Unwrap<InitPs>
  : never;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppAuthDispatch = () => {
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const dispatchFn = <FnType extends (token, ...args) => DefaultThunkAction>(
    fn: FnType,
    ...args: RestParameters<FnType>
  ) => {
    const fullArgs = [token, ...args];
    dispatch(fn.call(null, ...fullArgs));
  };
  return dispatchFn;
};
