import { SearchAndSelectDropdown } from "@react_db_client/components.search-and-select-dropdown";
import styled from "styled-components";

export interface IFormProps {
  horizontal?: boolean;
  alignment?: string;
}

export const FormStyle = styled.form<IFormProps>`
  display: flex;
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  align-items: ${(props) => (props.alignment ? props.alignment : "center")};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    width: 90%;
  }
`;

export interface IInputStyleProps {
  hidden?: boolean;
}

export const InputStyle = styled.div<IInputStyleProps>`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // TODO: Move this padding to input wrapper
  padding: ${({ hidden }) => (hidden ? "0" : "0 0 1.5rem 0")};
  max-height: ${({ hidden }) => (hidden ? "0rem" : "50rem")};
  width: 100%;

  overflow: ${({ hidden }) => (hidden ? "hidden" : "visible")};

  transition: max-height 1s ease-in-out, padding 1s ease-in-out;

  & > label {
    font-size: ${({ theme }) => theme.typography.fontSize2};
    margin-bottom: 0.3rem;
  }

  & > input {
    margin-top: ${({ theme }) => theme.shape.defaultPaddingSmall};
    border: 1px solid ${({ theme }) => theme.background};
    border-radius: 5px;
    background-color: #ededed;
    padding: 0.75rem 1.5rem;
    min-width: 5rem;
    min-height: ${({ theme }) => theme.typography.lineHeight};
  }

  & > textarea {
    margin-top: ${({ theme }) => theme.shape.defaultPaddingSmall};
    border: 1px solid ${({ theme }) => theme.background};
    border-radius: 5px;
    background-color: #ededed;
    padding: ${({ theme }) => theme.shape.defaultPadding};
    padding-bottom: 3rem;
    flex-grow: 1;
    min-width: 5rem;
    min-height: ${({ theme }) => theme.typography.lineHeight};
    font-size: ${({ theme }) => theme.typography.fontSize3};
  }

  & > input::placeholder,
  & > textarea::placeholder {
    color: ${({ theme }) => theme.colors.grey5};
  }

  // TODO: We should tidy up styling of the sas_dropdown component
  .sas_drop_wrap {
    flex-grow: 1;
    width: auto;
    .dropdownBtn {
      padding: 0.1rem;
      font-size: 1rem;
      line-height: 1rem;
      &:hover,
      &:focus {
        background: ${({ theme }) => theme.colors.ctaHover};
        color: ${({ theme }) => theme.colors.ctaText};
      }
    }
    .selectDropdown_list {
      li {
        height: ${({ theme }) => theme.typography.lineHeight} !important;
        button {
          height: ${({ theme }) => theme.typography.lineHeight} !important;
          box-sizing: border-box;
          background: none !important;
          cursor: pointer;
          &:hover {
            background: ${({ theme }) => theme.colors.cta} !important;
            color: ${({ theme }) => theme.colors.ctaText} !important;
            // outline: 1px solid ${({ theme }) => theme.colors.ctaText} !important;
          }
          &:focus {
            background: ${({ theme }) => theme.colors.cta} !important;
            color: ${({ theme }) => theme.colors.ctaText} !important;
            // outline: 1px solid ${({ theme }) => theme.colors.ctaText} !important;
          }
        }
      }
    }
  }
`;

export const FormSectionStyle = styled.section`
  width: 100%;
`;

export const StyledSASDropdown = styled(SearchAndSelectDropdown)`
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  input {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
  }
  .selectDropdown_menu {
    max-height: 30rem;
  }
  li {
    height: 3rem;
    button {
      height: 3rem;
      padding: 0.5rem;
      background: ${({ theme }) => theme.background};
      &:hover {
        background: ${({ theme }) => theme.colors.grey1};
      }
    }
  }
`;

export const HorizSpacer = styled.div`
  width: 0rem;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 1rem;
  }
`;

/** Search and Select component Styles
 *
 * These are mostly for fixing issues with the library
 */
export const SearchAndSelectStyles = styled.div`
  width: 100%;
  .searchAndSelect {
    border: 1px solid grey;
    padding: 1rem;
    width: 100%;
    .sas_manageSelectionSection {
      display: flex;
    }
    margin-bottom: 1rem;
    margin-top: 1rem;

    .sas_resultsSection {
      min-height: 4rem;
    }
  }
`;
