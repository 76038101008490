import userState from "./User/userReducer";
import activitiesState from "./Activities/activitiesReducer";
import achievementsState from "./Achievements/achievementsReducer";
import notificationState from "../GenericComponents/NotificationBar/Provider";

const reducers = {
  userState,
  activitiesState,
  achievementsState,
  notificationState,
};

export default reducers;
