import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  CardIconBtnCss,
  CardOuterStyle as CardboardCardOuterStyle,
  CardWithLogoStyle as CardboardCardWithLogoStyle,
} from "../GenericComponents/CardBoard/styles";
import { ButtonNoFormat, CTAButtonStyle, OnOffButtonStyle } from "./button";
import { auto } from "@popperjs/core";
import { CTALinkButtonStyle } from "./links";

export const AppWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AppDashboardWrapStyle = styled.div`
  flex-grow: 1;
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  gap: 2rem;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.shape.defaultPaddingMid};
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    gap: 0rem;
    flex-direction: column;
    padding: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
`;

export const AppDashboardStyle = styled.div`
  max-width: 69%;
  min-width: 69%;
  max-height: inherit;
  min-height: inherit;
  height: 100%;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.shape.defaultPadding};
  padding-right: ${({ theme }) => theme.shape.defaultPadding};

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.larger}) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const AppWrapperInnerStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.baseBackground};
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-top: -${({ theme }) => theme.shape.defaultPadding};
`;

export const AppPageStyle = styled.div`
  max-width: 100%;
  min-width: 100%;
  max-height: inherit;
  min-height: 100vh;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.shape.defaultPadding};
  padding-right: ${({ theme }) => theme.shape.defaultPadding};
  padding-top: 3rem; // To fit back to activities button
  margin-top: 0.5rem;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding-left: ${({ theme }) => theme.shape.defaultPaddingWide};
    padding-right: ${({ theme }) => theme.shape.defaultPaddingWide};
  }
`;

export const AppPageRestrictedStyle = styled(AppPageStyle)`
  min-width: 50%;
  max-width: 98%; //showing the boundaries of the box with the shadow instead of taking up the entire width of the screen
  margin: auto;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    max-width: 1100px;
  }
`;

export const AppInfoSection = styled.div`
  padding: ${({ theme }) => theme.shape.defaultPaddingLarge};
  background: ${({ theme }) => theme.background};
  border-radius: 1.5rem;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1), 0 5px 3px 0px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding: ${({ theme }) => theme.shape.defaultPadding};
  }
`;

export const AppCardboardWrap = styled.div`
  margin-top: -${({ theme }) => theme.shape.defaultPaddingWide};
  width: 100%;
`;

export const AppSectionHeading = styled.h2`
  margin: 0;
  margin-bottom: 0.5rem;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize5};
  font-weight: bold;
`;

export const AppSectionBreadcrumbs = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
`;

export const AppSectionBreadcrumbItem = styled.li`
  margin-right: 1rem;
`;

export const ActivityStepsSection = styled.div<{ open: boolean }>`
  position: relative;
  min-height: ${({ open }) => (open ? "none" : "3rem")};
  max-height: ${({ open }) => (open ? auto : "3rem")};
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
  transition: max-height 1s ease, min-height 1s ease, box-shadow 0.3s ease;
  margin: 0 auto;
  width: 100%;
  margin-top: 1rem;
`;

export const ActivityStepsSectionInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  width: 100%;
  flex-direction: row;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    align-items: stretch;
  }
`;

export const ActivitySectionTitle = styled.div`
  opacity: 0.001; // This is mostly there for legacy tests
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: ${({ theme }) => theme.shape.defaultPaddingWide};
  right: ${({ theme }) => theme.shape.defaultPaddingWide};
  z-index: 10;
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  padding: ${({ theme }) => theme.shape.defaultPadding};
`;

export const ActivityStepWrap = styled.div<{ locked: boolean }>`
  position: relative;
  flex-grow: 1;
  display: flex;
  max-width: 100%;
  width: 100%;
  max-height: ${({ locked }) => (locked ? "30rem" : "200rem")};
  transition: max-width 0.7s ease, max-height 0.7s ease;
  margin-top: -${({ theme }) => theme.shape.defaultPadding};

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    max-height: ${({ locked }) => (locked ? "40rem" : "200rem")};
    margin-top: 0;
  }
`;

export const ActivityStepSection = styled.div<{
  active?: boolean;
  optional?: boolean;
  locked?: boolean;
}>`
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingLarge};
  position: relative;
  flex-grow: 1;
  max-width: 100%;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.main};
  border-left: ${({ theme, active }) => (active ? `4px solid ${theme.colors.main}` : "none")};
  border-radius: 1.5rem;
  overflow: hidden;
  pointer-events: ${({ locked }) => (locked ? "None" : "auto")};
  opacity: ${({ locked }) => (locked ? "0.7" : "1")};

  .activityStepLabel {
    ${({ optional }) => (optional ? "" : "font-weight: 800;")}
  }
  transition: all 0.3s;

  &:after {
    content: "";
    width: ${({ active }) => (active ? "4px" : "2px")};
    position: absolute;
    background: ${({ theme }) => theme.colors.main};
    left: 0;
    top: 0;
    transition: bottom 0.4s ease-in;
    bottom: ${({ active }) => (active ? "100%" : "0")};
  }

  &:hover,
  &:focus-within {
    box-shadow: 2px 2px 10px 2px rgb(0, 0, 0);

    &:after {
      bottom: 0;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    width: 100%;
  }

  p,
  ul {
    margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  }

  ul {
    padding-left: ${({ theme }) => theme.shape.defaultPaddingMid};
  }
`;

export const EditCompletedBtn = styled(ButtonNoFormat)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: none;
  opacity: 0;
  min-width: 100%;
  pointer-events: auto;
  transition: opacity 0.2s ease-in;
  font-size: ${({ theme }) => theme.typography.fontSize4};
  font-weight: 800;
  text-align: center;
  color: ${({ theme }) => theme.colors.ctaText};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.ctaText};
    opacity: 1;
    background: ${({ theme }) => theme.colors.main};
  }
`;

export const ActivityStartBtnWrap = styled.div`
  background: ${({ theme }) => theme.background};
  margin-top: -${({ theme }) => theme.shape.defaultPadding};
  z-index: 5;
  margin-left: 1rem;
  border-radius: 20rem;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
  button {
    height: 100%;
    width: 100%;
    border-radius: 20rem;
    font-size: 20px;
    font-weight: 800;
    line-height: 2rem;
  }
`;

export const StepIndex = styled.div`
  text-align: center;
  color: white;
  font-weight: 800;
  font-size: ${({ theme }) => theme.typography.fontSize3};
  line-height: ${({ theme }) => theme.typography.fontSize3};
`;

export const StepTitleBar = styled.div`
  background: ${({ theme }) => theme.colors.main};
  width: 100%;
  padding: ${({ theme }) => theme.shape.defaultPaddingMid};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StepTitle = styled.h3`
  font-weight: 800;
  font-size: ${({ theme }) => theme.typography.fontSize4};
  line-height: ${({ theme }) => theme.typography.fontSize3};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  color: white;
  margin-left: 0.2rem;
`;

export const StepStatusIconStyle = styled.div`
  line-height: ${({ theme }) => theme.typography.fontSize3};
  background: white;
  border-radius: 3rem;
  padding: 0.3rem;
  img {
    width: 2rem;
    height: 2rem;
  }
`;

export const StepDescription = styled.div`
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};

  img {
    max-width: 100%;
  }
`;

export const LockedStepStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 1rem;
  display: flex;

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 10rem;
    max-height: 10rem;
  }
`;

export const KeyStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-right: -${({ theme }) => theme.shape.defaultPaddingWide};
  flex-wrap: wrap;
`;

export const KeySectionStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingMid};
  flex-wrap: wrap;
`;

export const KeySectionHeadingStyle = styled.h2`
  line-height: 2rem;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
  text-align: right;
  font-weight: 800;
  font-size: ${({ theme }) => theme.typography.fontSize3};
  padding: 0 ${({ theme }) => theme.shape.defaultPadding};
  background: ${({ theme }) => theme.colors.grey1};
`;

export const KeyIconStyle = styled.img`
  width: 2rem;
  height: 2rem;
  background: white;
  padding: 0.2rem;
  border-radius: 2.5rem;
  margin-left: -0.3rem;
`;

export const KeyItemLabelButton = styled(OnOffButtonStyle)`
  line-height: 1.5rem;
  margin-right: 0.5rem;
  flex-grow: 1;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  padding-right: 0.8rem;
`;
export const KeyItemLabel = styled.p`
  line-height: 2rem;
  margin-right: 0.5rem;
  flex-grow: 1;
`;

export const CardStatusIconsWrapStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 100%;
  left: 0.4rem;
  right: ${({ theme }) => theme.shape.defaultPaddingWide};
  align-items: flex-end;
`;
export const CardStatusIconStyle = styled.div`
  background: ${({ theme }) => theme.background};
  max-height: 6rem;
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0 -8px 8px 0px rgba(0, 0, 0, 0.1);
  margin-left: 0.2rem;
  img {
    width: 2rem;
    height: 2rem;
  }
  z-index: 0;
  * {
    font-size: ${({ theme }) => theme.typography.fontSize4};
  }

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) and (max-width: ${({ theme }) =>
      theme.mediaBoundaries.large}) {
    padding: 0.2rem;
  }
`;

export const ExpandedCardSection = styled.div`
  flex-grow: 1;
  margin-bottom: 0.5rem;
`;

export const ExpandedCardSectionCollapsable = styled.div<{ expanded?: boolean; maxHeight: number | string }>`
  transition: max-height 1s ease;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight : "0")};
  overflow: hidden;
`;

export const CardWithLogoStyle = styled(CardboardCardWithLogoStyle)<{ lockedactivity?: boolean | string }>`
  position: relative;

  ${({ lockedactivity, theme }) =>
    lockedactivity &&
    `
    &::before {
      content: "";
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image:
        url("/images/app/icons/Locked.png"),
        radial-gradient(circle, white, white);
      background-position: center; // Ensure both backgrounds are centered
      background-size: contain, cover; // The image should fit and the white circle should cover the area
      background-repeat: no-repeat;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      border: 3px solid ${theme.colors.mainHover};
      z-index: 1;
    }
  `}
`;

export const CardOuterStyle = styled(CardboardCardOuterStyle)<{ lockedactivity?: boolean | string }>`
  background: ${({ theme }) => theme.colors.main};
  ${({ lockedactivity }) =>
    lockedactivity &&
    `
    opacity: 0.65;
  `}
`;

export const ErrorMessage = styled.div`
  &,
  * {
    color: white;
    background: tomato;
    padding: 0.2rem 0.6rem;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    font-weight: 800;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: ${({ theme }) => theme.shape.defaultRadius};
  }
`;

/* Headings for app only */
export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
`;

export const H2 = styled.h2`
  line-height: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize4};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const H3 = styled.h3`
  line-height: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize3};
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;

export const ExpandedCardPageStyle = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.shape.defaultPaddingWide};
`;

export const ExpandedCardPageBackLinkWrapper = styled.div`
  text-align: right;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 5;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.baseBackground};
  width: fit-content;
  margin-bottom: 0.5rem;
`;

export const ExpandedCardPageBackLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  color: ${({ theme }) => theme.colors.text};
`;

export const CardIconBtnLinkDisabled = styled.div`
  ${CardIconBtnCss}
  cursor: not-allowed;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  img: {
    opacity: 0.8;
  }
`;

export const CardBtnTitleLinkStyle = styled(CTALinkButtonStyle)`
  background: ${({ theme }) => theme.colors.main};
  &:hover {
    background: ${({ theme }) => theme.colors.mainHover};
  }
`;

export const CardBtnTitleStyle = styled(CTAButtonStyle)`
  background: ${({ theme }) => theme.colors.main};
  &:hover {
    background: ${({ theme }) => theme.colors.mainHover};
  }
`;

export const SideBarDashboardStyle = styled.div`
  max-width: 30%;
  min-width: 30%;
  max-height: inherit;
  min-height: inherit;
  height: 100%;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.shape.defaultPadding};
  padding-right: ${({ theme }) => theme.shape.defaultPadding};

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.larger}) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const StepCollapsible = styled.div`
  padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  width: 100%;
`;

export const DataViewButton = styled(CardBtnTitleLinkStyle)`
  text-align: center;
  background: ${({ theme }) => theme.colors.tertiary};
  &:hover {
    background: ${({ theme }) => theme.colors.tertiaryHover};
  }
`;

export const ActivityExpandedContainer = styled.div`
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s;
  border-radius: 0 0 2rem 2rem;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const CardExpandedHr = styled.hr`
  backgroundcolor: ${({ theme }) => theme.colors.tertiary};
`;
