import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_ID } from "../../config";

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (GOOGLE_ANALYTICS_ID) ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  }, []);

  useEffect(() => {
    if (GOOGLE_ANALYTICS_ID) {
      const path = location.pathname + location.search;
      ReactGA.send({ hitType: "pageview", page: path });
    }
  }, [location]);
};

export default useGoogleAnalytics;
