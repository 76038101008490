import React from "react";
import styled from "styled-components";
import { PLong, Section } from "../../styles/page";
import { TableStyle } from "../../styles/table";
import { CTAButtonStyle } from "../../styles/button";
import { useGetActivityMetaData } from "../ActivitiesDashboard/useGetActivityMetaData";
import { useAppSelector } from "../../Redux/hooks";
import { UserGroup } from "../../lib/User/UserGroup";

interface IClickToRevealButtonProps {
  code: string;
}

export const ClickToRevealButton: React.FC<IClickToRevealButtonProps> = ({ code }) => {
  const [showCode, setShowCode] = React.useState(false);

  // Function to handle button click
  const handleButtonClick = () => {
    // Hide the button by setting setShowCode to true
    setShowCode(true);
  };

  return <>{showCode ? code : <CTAButtonStyle onClick={handleButtonClick}>Click to reveal</CTAButtonStyle>}</>;
};

const CompleteCodeTableStyle = styled(TableStyle)`
  width: 100%;
`;

export const CompleteCodeContent = () => {
  const userRole = useAppSelector<UserGroup>((state) => state.userState?.user?.group);
  const { cards: cardsData } = useGetActivityMetaData({
    groupId: "appActivityCards",
    userRole,
  });

  const codes = cardsData
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((c) => ({ q: c.title, code: c.cheatCodes?.complete, id: c.id }));
  return (
    <Section>
      <PLong>
        We advise that pupils complete our activities in a specific order to ensure that they have a sufficient
        understanding key concepts which may be new to them, before exploring their data in more detail through other
        activities. If you'd like to bypass this your pupils can input the relevant completion code(s). If users are
        completing activities as a class or in small groups codes can also be used to share credit between everyone
        involved, so that each user gains points towards Web App badges.
      </PLong>
      <CompleteCodeTableStyle>
        <tr>
          <th>Web App Activity Name</th>
          <th>Completion code</th>
        </tr>
        {codes.map((c) => (
          <tr key={c.code}>
            <td>{c.q}</td>
            <td>
              <ClickToRevealButton code={c.code} />
            </td>
          </tr>
        ))}
      </CompleteCodeTableStyle>
    </Section>
  );
};
