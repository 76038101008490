import { EChartsOption, PieSeriesOption, XAXisComponentOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { IBucketRange, usePreprocessPieData, usePreprocessPieDataDaily } from "./preProcessData";

export interface IPieChartProps {
  data: IMonitorDataItem<number>[];
  bucketRanges?: IBucketRange[];
}

export const constructAxis = (xAxisName: string, fontSize: string | number, dateFormatter): XAXisComponentOption => {
  return {
    type: "time",
    axisLabel: {
      margin: 7,
      formatter: dateFormatter,
    },
    name: xAxisName,
    nameLocation: "middle",
    nameGap: 50,
    nameTextStyle: {
      fontSize,
      fontWeight: "bold",
    },
  };
};

export const PieChart = ({ data, bucketRanges }: IPieChartProps) => {
  const dataProcessed = usePreprocessPieData(data, bucketRanges);
  const bucketRangesWithOutOfRange = [...bucketRanges, { label: "Out of range!", color: "black" }];

  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: "item",
      position: "top",
      valueFormatter: (v) => `${v}%`,
    },
    dataset: {
      dimensions: ["name", "value"],
      source: dataProcessed,
    },
    grid: {
      containLabel: true,
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        data: dataProcessed.map(([name, value], i) => ({
          name: name,
          value: value,
          itemStyle: {
            color: bucketRangesWithOutOfRange[i].color,
          },
        })),

        label: null,
        labelLine: {
          show: false,
        },
        type: "pie",
        radius: "50%",
      },
    ],
  };
  return <ReactECharts key={Date.now()} option={chartOptions} style={{ width: "100%" }} />;
};

export const PieChartDaily = ({ data, bucketRanges }: IPieChartProps) => {
  const dataProcessed = usePreprocessPieDataDaily(data, bucketRanges);
  const bucketRangesWithOutOfRange = [...bucketRanges, { label: "Out of range!", color: "black" }];
  const series: PieSeriesOption[] = dataProcessed.map((d, i) => ({
    data: d.value.map(([name, value], i) => ({
      name: name,
      value: value,
      itemStyle: {
        color: bucketRangesWithOutOfRange[i].color,
      },
    })) as any,

    label: {
      show: false,
    },
    labelLine: {
      show: false,
    },
    width: `${100 / dataProcessed.length}%`,
    type: "pie",
    radius: `60%`,
    top: 0,
    bottom: 0,
    left: `${(100 / dataProcessed.length) * i}%`,
  }));
  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: "item",
      position: "top",
      valueFormatter: (v) => `${v}%`,
    },
    title: dataProcessed.map((d, i) => ({
      text: d.dayLabel,
      left: `${(100 / dataProcessed.length) * (i + 0.5)}%`,
      bottom: 0,
      textAlign: "center",
    })),
    legend: {
      top: "5%",
      left: "center",
    },
    series,
  };
  return <ReactECharts key={Date.now()} option={chartOptions} style={{ width: "100%" }} />;
};
