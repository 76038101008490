import { Link } from "react-router-dom";
import { Section, P, PageTitle, Ul } from "../../styles/page";
import { FaqItemWrapStyle } from "../../styles/faq";
import { FaqItem } from "../faq";
import { IFaqItem } from "../faq/lib";

const entries: IFaqItem[] = [
  {
    uid: "how-accurate-is-my-monitor",
    label: "How accurate is my monitor?",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          The accuracy of a measuring device is the smallest change in the measurement that the device can confidently
          measure. For example, if a temperature sensor is accurate to within ±0.3°C, if the true temperature is 20°C,
          the sensor will display a reading between 19.7°C and 20.3°C. All measuring devices have a limit on how
          accurate they are. The SAMHE monitors have the following accuracy (according to their manufacturer):
        </P>
        <Ul>
          <li>Temperature: ±0.3°C </li>
          <li>Humidity: ± 2%</li>
          <li>TVOC: ±15%</li>
          <li>M: ±10%</li>
          <li>
            CO<sub>2</sub>: ±40 ppm{" "}
          </li>
        </Ul>
      </div>
    ),
  },

  {
    uid: "comparing-to-other-monitors",
    label: "Comparing to other monitors",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          Two monitors placed next to each other should show similar values but there might be a difference up to 100
          ppm.
        </P>
      </div>
    ),
  },

  {
    uid: "high-readings-when-moving-your-monitor",
    label: "High readings when moving your monitor?",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          Whether you've just unboxed and connected your monitor, or you're moving it at some point thereafter, it's
          likely that people are breathing on or near it. This can affect the readings because exhaled breath contains
          CO<sub>2</sub> which may lead your CO<sub>2</sub> levels to appear high at first.
        </P>
      </div>
    ),
  },

  {
    uid: "consistently-high-readings-during-normal-operation",
    label: "Consistently high readings during normal operation?",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          If your monitor consistently shows high CO<sub>2</sub> values despite actions you've taken to improve
          ventilation perform the following check to ensure that it is working as expected.
        </P>
        <P>
          <b>
            First thing in the morning check your SAMHE monitor CO<sub>2</sub> reading.
          </b>{" "}
          If it is not below 500 ppm, find a suitable sheltered spot close to an open window and leave it plugged in
          there undisturbed for 15 minutes.
        </P>
        <P>
          If after 15 minutes the readings are still above 500 ppm, please report this using our{" "}
          <Link to="/support/tech-contact">technical queries form</Link> and provide your monitor number.
        </P>
        <h3>Why does this check work?</h3>
        <P>
          First thing in the morning, providing the room has been unoccupied overnight, indoor CO<sub>2</sub> levels
          should be at 500 ppm or below. This is because with no-one breathing in the room (and therefore exhaling CO
          <sub>2</sub>) until you arrive, we'd expect the CO<sub>2</sub> levels in your classroom to drop towards the
          background level of CO<sub>2</sub> in the atmosphere - see our{" "}
          <Link to="/resources/key-definitions">key definitions page</Link> for more information on this.
        </P>
      </div>
    ),
  },

  {
    uid: "will-my-monitor-readings-change-over-time",
    label: "Will my monitor readings change over time?",
    group: null,
    audience: [],
    content: (
      <div>
        <P>
          Sensor drift is the tendency for monitors to gradually become less accurate over time. This can be caused by
          physical changes that affect the sensor. For example, airborne pollutants such as dust and chemicals may build
          up on the sensor and affect readings over time, and so may mechanical damage, such as if the sensor is
          dropped. Prolonged exposure to extreme conditions, such as very high temperatures or humidity, may also result
          in sensor drift.
        </P>
        <P>
          Calibration is the process of adjusting a sensor to give a more accurate reading. Usually, this is done by
          comparing the sensor reading to a known value (possibly from another sensor which is known to be accurate).
          The sensor reading can then be adjusted to account for the difference between the known value and the
          inaccurate measured value. Calibration is a continuous process. If you think a monitor is providing inaccurate
          readings, try looking at your past data in the SAMHE Web App to see if your measurements have changed.
        </P>
      </div>
    ),
  },
];

export interface MonitorAccuracyGuideProps {
  uidToShow?: string;
}

export const MonitorAccuracyGuide: React.FC<MonitorAccuracyGuideProps> = ({ uidToShow }) => {
  return (
    <>
      <PageTitle id="samheMonitorAccuracy">SAMHE Monitor Accuracy</PageTitle>

      <Section>
        <FaqItemWrapStyle>
          {entries.map((item) => (
            <FaqItem key={item.uid} data={item} open={uidToShow ? item.uid === uidToShow : false} />
          ))}
        </FaqItemWrapStyle>
      </Section>
    </>
  );
};
