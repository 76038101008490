import { rest } from "msw";
import { SERVER_URL } from "../config";
import { IApiMonitorHealthCheckReturn, IPingResponse } from "./ping";
import { mockMonitorData, mockSchools, mockServerMonitors } from "../dummyData";
import { EDataMetricsStrId } from "../lib/DataViews/EDataMetrics";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    useMockApi: false,
    endpoint: rest.get<IPingResponse>(`${SERVER_URL}/query/ping`, (req, res, ctx) => {
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.text("Hello from the SAHME API.")
      );
    }),
  },
  {
    useMockApi: true,
    endpoint: rest.get<IApiMonitorHealthCheckReturn>("/MonitorHealthCheck/:monitorId", (req, res, ctx) => {
      const { monitorId } = req.params;
      const monitorData = mockServerMonitors.find((m) => m.MonitorReferenceId === monitorId);
      if (!monitorData) return res(ctx.status(401), ctx.json({ ok: false, error: "Monitor not found!" }));
      const schoolData = mockSchools.find((s) => String(s.id) === String(monitorData.SchoolId));

      if (!schoolData) return res(ctx.status(401), ctx.json({ ok: false, error: "School not found!" }));
      if (schoolData.status < ESchoolStatus.DEPLOYED)
        return res(ctx.status(401), ctx.json({ ok: false, error: "School has not had a monitor deployed yet!" }));
      if (schoolData.status === ESchoolStatus.INVALID || schoolData.status === ESchoolStatus.ERROR)
        return res(ctx.status(401), ctx.json({ ok: false, error: "There is a problem with this school!" }));
      if (schoolData.status !== ESchoolStatus.ACTIVATED)
        return res(ctx.status(401), ctx.json({ ok: false, error: "School has not been activated!" }));
      const currentCO2 = mockMonitorData.data
        .slice()
        .reverse()
        .find((d) => d.field === EDataMetricsStrId.CO2);
      return res(
        ctx.status(200),
        ctx.json({ success: true, data: [currentCO2], isActivated: true, isLinkedToSchool: true })
      );
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints
  .filter((e) => e.useMockApi)
  .map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
