import { DataMetricsMetaData, EDataMetrics, EDataMetricsStrId, MetricStrIdMap } from "../lib/DataViews/EDataMetrics";
import { AggregationIntervals, EAggregationInterval } from "../lib/MonitorData/AggregationIntervals";
import { IMonitorData, IMonitorDataItem } from "../lib/MonitorData/IMonitorData";
import { sleep } from "../utils/testing/test-utils";

export const EXAMPLE_CURRENT_TEMP = 88.0;
export const EXAMPLE_CURRENT_CO2 = 900.3;

export const MAX_TEMP = 32;
export const MAX_CO2 = 3400;

export const exampleMonitorData: IMonitorDataItem<any>[] = [
  ...Array.from(Array(200))
    .map((_, i) => ({
      dateStamp: new Date(Date.now() - (i + 1) * 60 * 1000),
      field: EDataMetricsStrId.CO2,
      value: parseFloat(400 + (Math.random() * MAX_CO2).toFixed(2)),
    }))
    .reverse(),
  ...Array.from(Array(200))
    .map((_, i) => ({
      dateStamp: new Date(Date.now() - (i + 1) * 60 * 1000),
      field: EDataMetricsStrId.TEMPERATURE,
      value: parseFloat(1 + (Math.random() * MAX_TEMP + (i / 10) * 2).toFixed(2)),
    }))
    .reverse(),
  {
    dateStamp: new Date(Date.now().valueOf()),
    field: EDataMetricsStrId.CO2,
    value: EXAMPLE_CURRENT_CO2,
  },
  {
    dateStamp: new Date(Date.now().valueOf()),
    field: EDataMetricsStrId.TEMPERATURE,
    value: EXAMPLE_CURRENT_TEMP,
  },
  {
    dateStamp: new Date(Date.now().valueOf()),
    field: EDataMetricsStrId.PM25,
    value: 0, // test 0 value
  },
];

export const mockMonitorData: IMonitorData<number> = {
  data: exampleMonitorData,
  startTime: new Date(Date.now()),
  endTime: new Date(Date.now() + 60000),
  metric: EDataMetrics.TEMPERATURE,
};

export const getMockMonitorData = (
  start: Date,
  end: Date,
  metric: EDataMetricsStrId,
  aggregation: EAggregationInterval = EAggregationInterval.MINUTE,
  includeSampleEndPoints: boolean = true,
  maxVal: number = MAX_CO2,
  minVal: number = 0
): IMonitorData<number> => {
  const aggregationRatio = AggregationIntervals[aggregation].ratio;
  const count = Math.round((Math.abs(end.valueOf() - start.valueOf()) / (1000 * 60) - 1) / aggregationRatio);
  const data = [
    ...(count >= 1
      ? Array.from(Array(count))
          .map((_, i) => ({
            dateStamp: new Date(end.valueOf() - i * 1000 * 60 * aggregationRatio),
            field: metric,
            value: parseFloat((minVal + Math.random() * maxVal).toFixed(2)),
            // value: 0, // parseFloat((1 + Math.random() * MAX_CO2).toFixed(2)),
          }))
          .reverse()
      : []),
    ...(includeSampleEndPoints
      ? [
          {
            dateStamp: end,
            field: EDataMetricsStrId.CO2,
            value: EXAMPLE_CURRENT_CO2,
          },
          {
            dateStamp: end,
            field: EDataMetricsStrId.TEMPERATURE,
            value: EXAMPLE_CURRENT_TEMP,
          },
        ].filter((d) => d.field === metric)
      : []),
  ];
  return {
    data,
    startTime: start,
    endTime: end,
    metric: MetricStrIdMap[metric],
  };
};

export const aggregateMonitorData = (aggregation: EAggregationInterval) => (data: IMonitorDataItem<number>[]) => {
  const aggregatedData = data.reduce((groups, item) => {
    const { dateStamp, value } = item;
    const d = new Date(
      Math.floor(dateStamp.valueOf() / AggregationIntervals[aggregation].ratio) *
        AggregationIntervals[aggregation].ratio
    );
    const k = d.toString();
    if (!groups[k]) {
      groups[k] = { dateStamp: d, value: 0, count: 0 };
    }
    groups[k].value += value;
    groups[k].count++;
    return groups;
  }, {} as { [k: string]: { dateStamp: Date; value: number; count: number } });
  return Object.values(aggregatedData).map((d) => ({ ...d, value: d.value / d.count }));
};

export const getExampleDataFull = (
  m: EDataMetrics,
  startTime: Date,
  endTime: Date,
  aggregation: EAggregationInterval
): IMonitorData<number> => {
  sleep(1000);
  return {
    metric: m,
    data: getMockMonitorData(startTime, endTime, DataMetricsMetaData[m].strid, aggregation, false).data,
  };
};
