import React from "react";
import styled from "styled-components";
import { useCookie } from "react-use";
import { AuthContext } from "react-oauth2-code-pkce";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { LocationSelectionDropdown, MonitorSelectionDropdown } from "./monitorLocationSelectionDropdowns";
import { CTAButtonStyle } from "../../styles/button";
import { switchRoom } from "../../Redux/User/userReducer";
import { ROOM_SELECTION_COOKIE_TIMEOUT_HOURS } from "../../config";
import { EMonitorChangeSource } from "../../lib/Monitor/IMonitor";
import { IRoomListItem } from "../../lib/School/IRoom";
import { ApiContext } from "../../Redux/Api/ApiContext";

// Panel style will sit on top of the rest of the app
const RoomSelectionPromptWrap = styled.div`
  flex-direction: column;
  z-index: 1001;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
    background: ${({ theme }) => theme.colors.appBackground};
  }

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  }
`;

const RoomSelectionPromptInner = styled.div`
  margin-top: 10%;
  padding: 1rem;
  border-radius: 1rem;
  background: white;
  select,
  select option {
    color: ${({ theme }) => theme.colors.tertiary};
    background: white;
    &:focus,
    &:hover {
      background: ${({ theme }) => theme.colors.tertiary};
      color: white;
    }
  }
  select {
    border: 1px solid ${({ theme }) => theme.colors.tertiary};
    border-radius: 0 0.5rem 0.5rem 0;
  }
  label {
    background: ${({ theme }) => theme.colors.tertiary};
    color: white;
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;

const DropdownWrap = styled.div`
  padding: 0;
  display: flex;
  margin: 0;
  text-align: center;
  width: 100%;
  max-width: 50rem;
  border-radius: 0.5rem 0;

  margin-bottom: 2rem;
  height: 3rem;
  align-content: center;
  justify-items: center;
  align-items: center;

  border: none;
  height: 2.3rem;
  margin-bottom: 0;
  width: auto;

  &:last-child {
    margin-right: 0;
  }
`;

const TextContainer = styled.div`
  background: white;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

const useSwitchRoom = () => {
  const { disallowMoveYourMonitor, monitors } = useAppSelector(
    (state) => state.userState.state.school.data || ({} as any)
  );
  const { apiChangeMonitorLocation } = React.useContext(ApiContext);
  const monitorId = useAppSelector((state) => state.userState.state.monitor.id);
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const [hasSelectedRoom, updateHasSelectedRoom] = useCookie("SAMHE_ROOM_SELECTION");
  const [storedMonitorSelection, updateStoredMonitorSelection] = useCookie("SAMHE_MONITOR_SELECTION");
  const [selectingRoom, setSelectingRoom] = React.useState(false);
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();

  const [requestedSwitchRoom, setRequestedSwitchRoom] = React.useState<IRoomListItem | false>(false);
  const [errorSwitchRoom, setErrorSwitchRoom] = React.useState(false);

  React.useEffect(() => {
    if (requestedSwitchRoom && !selectingRoom) {
      setSelectingRoom(true);
      setErrorSwitchRoom(false);
      setRequestedSwitchRoom(false);
      apiChangeMonitorLocation(token, monitorId, requestedSwitchRoom, null, null, null, EMonitorChangeSource.LOGIN)
        .then(() => {
          dispatch(switchRoom(requestedSwitchRoom));
          const cookieTimeout = new Date(Date.now() + 1000 * 60 * 60 * ROOM_SELECTION_COOKIE_TIMEOUT_HOURS);
          updateHasSelectedRoom("true", { expires: cookieTimeout });
          updateStoredMonitorSelection(String(monitorId), { expires: cookieTimeout });
        })
        .catch(() => {
          setErrorSwitchRoom(true);
        })
        .finally(() => {
          setSelectingRoom(false);
        });
    }
  }, [
    requestedSwitchRoom,
    monitorId,
    token,
    dispatch,
    updateHasSelectedRoom,
    updateStoredMonitorSelection,
    apiChangeMonitorLocation,
    selectingRoom,
  ]);

  const mustSelectRoomBeforeContinuing = !disallowMoveYourMonitor && !(currentRoom && hasSelectedRoom);
  const mustSelectAMonitor = monitors?.length > 0 && !storedMonitorSelection;

  const requestSwitchRoom = (room: IRoomListItem) => {
    setRequestedSwitchRoom(room);
  };

  return {
    requestedSwitchRoom,
    mustSelectRoomBeforeContinuing,
    mustSelectAMonitor,
    currentRoom,
    errorSwitchRoom,
    requestSwitchRoom,
    selectingRoom,
  };
};

export const RoomSelectionPrompt = () => {
  const {
    mustSelectRoomBeforeContinuing,
    mustSelectAMonitor,
    currentRoom,
    errorSwitchRoom,
    requestSwitchRoom,
    selectingRoom,
  } = useSwitchRoom();

  const {
    state: {
      school: {
        data: { monitors, disallowMoveYourMonitor, roomList },
      },
    },
  } = useAppSelector((state) => state.userState);

  const singleMobile = !disallowMoveYourMonitor && roomList;
  const multipleStatic = monitors && monitors.length > 1;

  if (!mustSelectAMonitor && !mustSelectRoomBeforeContinuing) return <></>; // User has already completed the form!

  return (
    <RoomSelectionPromptWrap data-testid="RoomSelectionPrompt">
      <RoomSelectionPromptInner>
        {selectingRoom ? <p>Switching room...</p> : null}
        {!selectingRoom ? (
          <>
            <TextContainer>
              <p>
                <b>Hi there!</b>
              </p>
              {multipleStatic && singleMobile && (
                <p>
                  The configuration for your school might have not been set up correctly. Please contact{" "}
                  <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>.
                </p>
              )}
              {multipleStatic && !singleMobile && <p>Please select the room/monitor you'd like to investigate.</p>}
              {singleMobile && !multipleStatic && <p>Please confirm which room you think the monitor is in.</p>}
            </TextContainer>
            <DropdownWrap>
              <MonitorSelectionDropdown />
            </DropdownWrap>
            <br />
            <DropdownWrap>
              <LocationSelectionDropdown updatedFrom={EMonitorChangeSource.LOGIN} />
            </DropdownWrap>
            <CTAButtonStyle
              style={{
                width: "100%",
                marginTop: "2rem",
              }}
              onClick={() => {
                requestSwitchRoom(currentRoom);
              }}
            >
              Ok
            </CTAButtonStyle>
            {errorSwitchRoom && (
              <TextContainer>
                <p>
                  <b>There was an error saving your room selection.</b>
                </p>
                <p>Please try again or contact support.</p>
              </TextContainer>
            )}
          </>
        ) : null}
      </RoomSelectionPromptInner>
    </RoomSelectionPromptWrap>
  );
};
