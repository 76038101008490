import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { ISession, changeMonitorRoom, updateMonitorActivationState } from "../../Redux/User/userReducer";
import { P, List } from "../../styles/page";
import { apiMonitorManagement } from "../../Api/apiMonitorManagement";
import { CTAButtonStyle } from "../../styles/button";
import { MonitorId } from "../../lib/Monitor/IMonitor";
import { IRoomListItem, RoomId } from "../../lib/School/IRoom";

interface IMonitorActivationQueueItem {
  monitorId: MonitorId;
  room?: IRoomListItem;
  status: "activationRequested" | "pending" | "success" | "error";
}

export const useActivateMonitor = () => {
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();

  const [monitorActivationQueue, setMonitorActivationQueue] = React.useState<IMonitorActivationQueueItem[]>([]);

  const handleActivateMonitor = (monitorId: MonitorId, room?: IRoomListItem) => {
    setMonitorActivationQueue((prev) => [
      ...prev,
      {
        monitorId,
        room,
        status: "activationRequested",
      },
    ]);
  };

  React.useEffect(() => {
    const queuedItems = monitorActivationQueue.filter((item) => item.status === "activationRequested");
    if (queuedItems.length === 0) return;
    queuedItems.forEach((item) => {
      const { monitorId, room } = item;
      setMonitorActivationQueue((prev) => {
        return prev.map((i) => {
          if (i.monitorId === monitorId) {
            i.status = "pending";
          }
          return i;
        });
      });
      apiMonitorManagement
        .activate(token)(monitorId, room?.id)
        .then(() => {
          setMonitorActivationQueue((prev) => {
            return prev.map((i) => {
              if (i.monitorId === monitorId) {
                i.status = "success";
              }
              return i;
            });
          });
          dispatch(updateMonitorActivationState({ monitorId, activated: true, room }));
        })
        .catch(() => {
          setMonitorActivationQueue((prev) => {
            return prev.map((i) => {
              if (i.monitorId === monitorId) {
                i.status = "error";
              }
              return i;
            });
          });
        });
    });
  }, [monitorActivationQueue, dispatch, token]);

  return {
    monitorActivationQueue,
    handleActivateMonitor,
  };
};

const MonitorRoomSelection = ({ monitorId }: { monitorId: MonitorId }) => {
  const userState = useAppSelector<ISession>((state) => state.userState);
  const dispatch = useAppDispatch();
  const { state } = userState;
  const roomList = React.useMemo(() => state.school?.data?.roomList || [], [state.school?.data?.roomList]);
  const assignedRooms = React.useMemo(
    () => state.school?.data?.monitors?.filter((m) => m.room).map((m) => m.room?.id) || [],
    [state.school?.data?.monitors]
  );
  const unassignedRooms = React.useMemo(
    () => roomList.filter((r) => !assignedRooms.includes(r.id)),
    [roomList, assignedRooms]
  );
  const monitor = state.school?.data?.monitors?.find((m) => m.id === monitorId);
  const value = monitor?.room?.id || "";

  const handleSelect = (value: RoomId) => {
    dispatch(changeMonitorRoom({ monitorId, room: roomList.find((r) => r.id === value) }));
  };

  return (
    <select value={value} onChange={(e) => handleSelect(e.target.value as RoomId)}>
      <option value="">{monitor?.room ? "Clear selection" : "Select a room"}</option>
      {monitor?.room && (
        <option value={monitor.room.id} disabled>
          {monitor.room.label}
        </option>
      )}
      {unassignedRooms.map((room) => (
        <option key={room.id} value={room.id}>
          {room.label}
        </option>
      ))}
    </select>
  );
};

export const MonitorActivation = () => {
  const { monitorActivationQueue, handleActivateMonitor } = useActivateMonitor();
  const roomList = useAppSelector((state) => state.userState.state.school?.data?.roomList);
  const userState = useAppSelector<ISession>((state) => state.userState);
  const requiresRoom = useAppSelector((state) => state.userState.state.school?.data?.disallowMoveYourMonitor);
  const { state } = userState;
  const monitors = state.school?.data?.monitors || [];
  const disallowMoveYourMonitor = state.school?.data?.disallowMoveYourMonitor || false;
  const allMonitorsActivated = state.school?.data?.monitors?.every((m) => m.activated) || false;

  const hasRooms = roomList && roomList.length > 0;
  return (
    <>
      {!hasRooms && <p>It looks like your school does not have any rooms. Please contact SAMHE support at </p>}
      {hasRooms && monitors.length === 0 && (
        <p>
          It looks like your school does not have a monitor assigned to it. Please contact SAMHE support at{" "}
          <a href="mailto:techsupport@samhe.org.uk">techsupport@samhe.org.uk</a>
        </p>
      )}
      {hasRooms && monitors.length > 0 && (
        <>
          <P>You have the following monitors associated with your school.</P>
          {disallowMoveYourMonitor && (
            <>
              <P>
                If you have not yet linked your monitor with a room, please do so using the dropdowns below and press
                link to confirm your selection.
              </P>
              <P>
                You will need to add a room for each monitor before you can link it. If you don't see a room in the
                dropdown, you can add it on the <Link to="/school/admin">School Admin Page</Link>.
              </P>
            </>
          )}
          {!disallowMoveYourMonitor && <P>Please click link next to each monitor you have received.</P>}
          <List>
            {monitors.map((monitor) => {
              const monitorInActivationQueue = monitorActivationQueue.find((m) => m.monitorId === monitor.id);
              return (
                <li key={monitor.id} style={{ display: "flex", borderBottom: "1px solid grey" }}>
                  <span style={{ width: "30%" }}>
                    <b>{monitor.id}</b>
                  </span>
                  {monitor.activated && (!requiresRoom || monitor.room) ? (
                    <>
                      {monitor.room?.label ? <span style={{ width: "30%" }}>Room: {monitor.room.label}</span> : ""}
                      <span style={{ marginLeft: "1rem" }}>Monitor Linked</span>
                    </>
                  ) : (
                    <>
                      {disallowMoveYourMonitor && <MonitorRoomSelection monitorId={monitor.id} />}
                      <CTAButtonStyle
                        disabled={
                          (disallowMoveYourMonitor && !monitor.room?.id) ||
                          (monitorInActivationQueue?.status && monitorInActivationQueue?.status !== "error")
                        }
                        onClick={() => handleActivateMonitor(monitor.id, monitor.room)}
                      >
                        Link Monitor
                      </CTAButtonStyle>
                      {monitorInActivationQueue?.status === "error" && (
                        <P>There was an error linking your monitor! Please try again.</P>
                      )}
                    </>
                  )}
                </li>
              );
            })}
          </List>
        </>
      )}
      {monitors.length > 0 && allMonitorsActivated && (
        <P style={{ marginTop: "1rem" }}>You have linked all of your monitors!</P>
      )}
    </>
  );
};
