import React from "react";
import Masonry from "react-masonry-css";
import { BoardStyle } from "./styles";

export interface ICardBoardProps {
  children: React.ReactNode;
  breakpointColumns?: { default: number;[key: number]: number } | number;
}

const defaultBreakpoints: {} = {
  default: 4,
  1600: 3,
  1150: 2,
  760: 1
};

// TODO: Implement https://www.npmjs.com/package/react-infinite-scroll-hook

export const CardBoard: React.FC<ICardBoardProps> = ({ children }) => {
  return (
    <BoardStyle role="cardboard">
      <Masonry breakpointCols={defaultBreakpoints} className="masonry_grid" columnClassName="masonry_column">
        {children}
      </Masonry>
    </BoardStyle>
  );
};
