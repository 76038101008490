import styled from "styled-components";

export const NewsStyle = styled.div`
  margin: 0;
  flex-grow: 1;
  position: relative;
  padding-top: 0.3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const NewsTickerStyle = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  overflow: auto;
  flex: 1;
`;

export const NewsEntryStyle = styled.li`
  flex-grow: 1;
  background: ${({ theme }) => theme.background};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  &:hover {
    background: rgb(240, 240, 240);
  }
  &:not(:last-child) {
    position: relative;
    &::after {
      content: "";
      border-bottom: 1px solid ${({ theme }) => theme.colors.main};
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.2;
    }
  }
`;

export const NewsEntryTitle = styled.h3`
  font-weight: 800;
`;
export const NewsDate = styled.h3`
  font-style: italic;
`;

export const NewsEntryText = styled.div`
  margin-top: 1rem;
  p {
    &:not(:last-child) {
      margin-bottom: 1.3rem;
    }
  }
`;

export const TwitterFeedWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: auto;
  border-left: 1px solid ${({ theme }) => theme.colors.main};
  border-right: 1px solid ${({ theme }) => theme.colors.main};
  padding: 0;

  flex-grow: 1;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    border: 0;
  }
`;

export const TwitterFeedMobileWrap = styled.div`
  flex-grow: 1;
  padding: 1rem;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: none;
  }
`;

export const TwitterFeedDesktopWrap = styled.div`
  flex-grow: 1;
  overflow: hidden;
  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: none;
  }
`;

// export const NewslettersList = styled.ul`
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   border: 1px solid ${({ theme }) => theme.colors.main};
//   border-radius: ${({ theme }) => theme.shape.defaultRadius};
//   width: auto;
//   padding: 1rem;
//   flex-grow: 1;
// `;
// export const NewslettersListItem = styled.li`
//   display: flex;
//   flex-direction: row;
// `;
export const NewsPageWrap = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    height: 30rem;
    flex-direction: row;
  }

  > div {
    @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      max-height: 100vh;

      padding-bottom: 1rem;
      :not(:last-child) {
        border-bottom: 2px solid ${({ theme }) => theme.colors.main};
      }
    }
  }

  > div,
  > ul {
    width: 100%;
    margin: 0;
    margin-right: 0;
    &:nth-child(1) {
      margin-bottom: 2rem;
    }
    &:nth-child(2) {
      margin-bottom: 2rem;
    }
    &:nth-child(3) {
    }
    @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      width: 30%;
      &:nth-child(1) {
        margin-bottom: 0;
        margin-right: ${({ theme }) => `${theme.shape.defaultPadding}`};
      }
      &:nth-child(2) {
        margin-bottom: 0;
        margin-right: ${({ theme }) => `${theme.shape.defaultPadding}`};
      }
      &:nth-child(3) {
      }
    }
  }
`;

export const NewsletterHeading = styled.h3`
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-weight: bold;
  text-align: center;
  a {
    text-decoration: none;
  }
`;
