import React from "react";
import { P, ConstrainedPageStyle, PageTitle, SectionHeading, Section, Ul, FloatImage } from "../styles/page";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

export const MonitorConnectionGuideDiv = styled.div`
  & > div:first-child {
    position: sticky;
    top: 0;
    border: 0;
    background-color: #8200a3;
    & > div {
      padding: 1rem 0.5rem;
      color: white;
    }
  }
  & > div {
    display: flex;

    & > div {
      padding: 1.5rem 0.5rem;
    }

    & > div:first-child {
      flex: 2;
      border-right: 1px solid black;
    }

    & > div:nth-child(2) {
      flex: 3;
      padding-left: 0.5rem;
    }
  }
`;

export const PlayerWrapper = styled.div`
  float: right;
  width: 30rem;
  max-width: 50%;
  margin-bottom: none;

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    float: none;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

const MonitorConnectionGuidePage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="page__wrap">
      <PageTitle>SAMHE Monitor connection guide</PageTitle>
      <Section>
        <PlayerWrapper>
          <ReactPlayer url="https://youtu.be/YxWVRKNJXRI" controls={true} width="100%" />
        </PlayerWrapper>
        <SectionHeading>Before you get started</SectionHeading>
        <P>
          Before you start setting up your air quality monitor you will need to know:
          <Ul>
            <li>The name of your school's WiFi network</li>
            <li>The password for your school's WiFi network</li>
          </Ul>
        </P>
        <P>
          You may also find it helpful to have your school's IT support on hand in case you run into any difficulties
          with these instructions. We have worked with schools to develop them, but you may encounter local issues we
          haven't come across yet.
        </P>
      </Section>

      <Section>
        <SectionHeading>Setting up your monitor</SectionHeading>
        <P>
          The steps below will take you through the process of connecting your monitor to the WiFi so that it can send
          data to the SAMHE Web App, which involves using an internet enabled device, such as a computer or phone, to
          provide the monitor with information on which WiFi network you want it to connect to. Once the monitor has
          this information it will use it to try to connect to the WiFi.
        </P>
        <MonitorConnectionGuideDiv>
          <div>
            <div>
              <big>
                <b>What you'll see:</b>
              </big>
            </div>
            <div>
              <big>
                <b>What you need to do:</b>
              </big>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step1.png"
                alt="Setup Step 1"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>XXXs to connect to WiFi hotspot: "airgradient-xxxxxxxxxxxx"</i>
              </small>
            </div>
            <div>
              <P>
                1. Plug your monitor into the wall socket so it turns on (you should see a single blue LED light up).
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step2ALTERNATIVE2.png"
                alt="Setup Step 2"
                height="auto"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div>
              <P>
                2. On any internet enabled device (e.g. computer or phone), go to your WiFi settings and find a hotspot
                called "airgradient-xxxxx". Click on this and enter the password "cleanair".
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step3A.png"
                alt="Setup Step 3-1"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div>
              <P>
                3. After entering the password you should get a screen popping up on your computer or phone saying
                "airgradient-xxxxx" WiFi Manager. (If it doesn't you can type "192.168.4.1" into your browser and it
                will take you there). Select 'Scan for networks' to configure WiFi setup.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitroSetUpGuide-Step3B.png"
                alt="Setup Step 3-2"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>XXXs to connect to WiFi hotspot: "airgradient-xxxxxxxxxxxx</i>"
              </small>
            </div>
            <div>
              <P>At this stage your monitor should have a full row of blue lights</P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetpUpGuide_Step4_WiFiNetworkScan.png"
                alt="Setup Step 4"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div>
              <P>
                4. Next you'll be taken to a screen on your computer or phone where you need to enter the details for
                your school's WiFi Network. If your school's WiFi network name appears in this list please select it,
                and enter the WiFi network password in the password box. Please note you only get one attempt to enter
                your password correctly so it's worth double checking your entry before submitting.
              </P>
              <P>
                If your school's WiFi network name does not appear in this list there are several possible explanations.
              </P>
              <P>
                The most likely explanation is that your school's WiFi network is hidden. To confirm whether this is the
                case you can try searching for nearby WiFi networks on a different internet enabled device. If your
                school's WiFi network still does not appear when you do this it is likely that it is hidden. To proceed
                you will need to type the network name in the SSID box. Then put the password for your network into the
                password box.
              </P>
              <P>
                If your school's WiFi network appears when you search on other devices, the problem could be your WiFi
                network frequency. Head to <Link to="/support">samhe.org.uk/support</Link> for more guidance on this.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step5A.png"
                alt="Setup Step 5-1"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>Trying to connect to WiFi ...</i>
              </small>
            </div>
            <div>
              <P>
                5. Having entered your password click "Save", and your monitor should display a series of white lights
                which move across the front of the monitor. A full row of white lights indicates that connection to the
                WiFi has been successful.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step5B.png"
                alt="Setup Step 5-2"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>WiFi connection successful</i>
              </small>
            </div>
            <div>
              <P>
                Note: At this stage a message may appear on the screen of the device you're using to complete set up
                which reads: "Credentials saved. Trying to connect to network. If it fails reconnect to AP to try
                again". Don't be put off by this, this is normal! Your monitor is working through set up.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step5C.png"
                alt="Setup Step 5-3"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>Connecting to Server ...</i>
              </small>
            </div>
            <div>
              <P>
                Now your monitor will attempt to connect to the server which is indicated by running green lights across
                the front of your monitor. A full row of green lights indicates that the connection to the server has
                been successful - your monitor is now set up!
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step5D.png"
                alt="Setup Step 5-4"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>Server connection successful</i>
              </small>
            </div>
            <div>
              <P>
                <b>
                  Please note the full row of green lights doesn't hang around for long, so you might miss this. Once
                  operational the lights quickly change to indicate CO₂ level. Often when you're handling the monitor
                  during set up you'll be breathing on or near it a lot, meaning you may get lots of red lights
                  indicating a high CO₂ reading. This is normal. Provided you can see numbers on the black screen you
                  can tell you've been successful. It may take a while for these numbers to settle down.
                </b>
              </P>
              <P>
                Once you've connected your monitor you can use our{" "}
                <Link to="/resources/monitor-leds">guidance on monitor readings</Link> to help you make sense of the
                monitor LED combinations.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuide-Step5REDLIGHTS.png"
                alt="Setup Error 1"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>WiFi not connected</i>
              </small>
            </div>
            <div>
              <P>
                If your running green lights are followed by a full row of red lights, this means there may be a problem
                with the WiFi network name or password entered or another local issue which is preventing your monitor
                accessing your WiFi.
              </P>
            </div>
          </div>

          <div>
            <div>
              <FloatImage
                src="/images/monitor-connection-guide/SAMHEMonitorSetUpGuideStep5ORANGELIGHTS.png"
                alt="Setup Error 2"
                height="auto"
              />
              <small>
                Monitor screen reads: <i>Server not reachable</i>
              </small>
            </div>
            <div>
              <P>
                A full row of orange lights at this stage indicates that your monitor is able to connect to your
                school's WiFi but the server is not reachable, this might be because your school has a firewall which is
                blocking access or because the monitor needs to be whitelisted.
              </P>

              <P>
                In either scenario, if you have IT support at your school, please ask for assistance. If you are still
                having issues, please check out our guidance online at <Link to="/support">samhe.org.uk/support</Link>{" "}
                or contact us via our <Link to="/support/tech-contact">tech queries form</Link> (accessible on the same
                page).
              </P>
            </div>
          </div>
        </MonitorConnectionGuideDiv>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default MonitorConnectionGuidePage;
