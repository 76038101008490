import React from "react";
import { Link } from "react-router-dom";
import SchoolValidationForm from "../components/Forms/SchoolValidationForm";
import { ConstrainedPageStyle, GreenText, Ol, P, PageTitle, Section, SectionHeading, Ul } from "../styles/page";

const SchoolValidationPage: React.FC = () => {
  return (
    <ConstrainedPageStyle data-testid="SchoolValidationPage">
      <PageTitle>School Validation Tools</PageTitle>
      <Section>
        <P>
          <b>All school data should be treated as per the SAMHE privacy policy!</b>
        </P>
        <P>
          <b>Ensure all downloaded data is removed (including from recycling bin) once finished with.</b>
        </P>
      </Section>
      <Section>
        <SectionHeading>Modify School Statuses</SectionHeading>
        <P>Use this page to change the current status of schools.</P>
        <P>
          Schools should go through the following status (Only those in green and underlined can be changed on this
          form):
        </P>
        <Ol>
          <li>
            <b>
              <GreenText>Registered {"->"} Validating </GreenText>
            </b>
            . When marking a monitor request as being checked.
          </li>
          <li>
            <b>
              <GreenText>Validating {"->"} Validated </GreenText>
            </b>
            . When the monitor request has been validated and it is ready to be linked to a monitor.
          </li>
          <li>
            <b>Validated {"->"} Linked to monitor</b>. This is achieved on the <Link to="/qr">monitor qr page</Link> or
            by scanning a monitor qr code.
          </li>
          <li>
            <b>
              <GreenText>Linked to Monitor {"->"} Monitor has been sent to school</GreenText>
            </b>
            . The monitor has been dispatched to a school and the tracking number is stored somewhere.
          </li>
          <li>
            <b>
              <GreenText>Monitor has been sent to school {"->"} School has received monitor</GreenText>
            </b>
            . Should be done when we receive confirmation a school has received their monitor.
          </li>
          <li>
            <b>School has received monitor {"->"} School has activated their monitor</b>. Completed by the school when
            they complete the activation form.
          </li>
        </Ol>
        <P>Additionally schools can have the following status':</P>
        <Ul>
          <li>School is invalid (E.g. it is a duplicate request)</li>
          <li>There was an error processing this school</li>
        </Ul>
        <P>To make other changes to school status please contact SAMHE technical support.</P>
      </Section>
      <Section>
        <h2>Headings info</h2>
        <P>
          School Id - This is generated when a school completes the registration form. This is the id they will use to
          register a new admin user and should be used for communications with schools.
        </P>
        <P>School Establishment ID below refers to the ID/URN from source DB (GIAS/EANI/gov.scot)</P>
        <P>School funding types(Is your school fee paying?):</P>
        <ul>
          <li>0: Unknown</li>
          <li>1: Yes</li>
          <li>2: No</li>
        </ul>
      </Section>
      <Section>
        <SchoolValidationForm />
      </Section>
    </ConstrainedPageStyle>
  );
};

export default SchoolValidationPage;
