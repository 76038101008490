import React from "react";
import { Link } from "react-router-dom";
import { keywordMap } from "../../../dummyData/keywords";
import {
  CardInfoSectionItem,
  CardInfoSectionItemContent,
  CardInfoSectionWrapper,
  ActivityCardSection,
  ActivityCardSubSection,
  InfoSection,
  CardInfoSubSection,
  DetailsSection,
} from "../../../GenericComponents/CardBoard/styles";
import { IActivityScore } from "../../../lib/Achievements/EAchievementCategory";
import { EGrades } from "../../../lib/Activities/EGrades";
import {
  ActivityStatus,
  EActivityStatus,
  IActivityCheatCodes,
  IActivityTimeEstimates,
  IActivityUser,
  IMissingActivityData,
} from "../../../lib/Activities/IActivity";
import { IStep, EStepStatus, IStepUserData, TStepState } from "../../../lib/Activities/IStep";
import { ITag } from "../../../lib/Tags/ITag";
import { UserGroup } from "../../../lib/User/UserGroup";
import { useAppSelector } from "../../../Redux/hooks";
import { CollapsableSectionInner } from "../../../styles/collapsable";
import { Tags } from "../../Tags";
import { TextFormatter } from "../../TextFormatter";
import { ScoreContributions } from "../ActivityScoreContributions";
import { TimeEstimates } from "../ActivityTimeEstimates";
import { AchievementCategories } from "../../../lib/Achievements/EAchievementCategory";
import { H3 } from "../../../styles/app";
import { ListItem, List, P } from "../../../styles/page";
import { CTALinkButtonStyle } from "../../../styles/links";
import { DependentActivities } from "./DependentActivities";
import { ClickToRevealButton } from "../../AppResources/CompletionCodeContent";

export interface IActivityMetaDataDisplayProps {
  targetGrade?: EGrades[];
  activitySteps: IStep<any>[];
  longDescription: string;
  timeEstimates?: IActivityTimeEstimates;
  textFilePath?: string;
  stepsState: IStepUserData<TStepState>[];
  activeStep?: IStep<any>;
  activityStatus: EActivityStatus;
  isLocked?: boolean;
  tags?: ITag[];
  collapseMetaInfoPanel?: boolean;
  equipmentNeededDetailed?: string[];
  noOfPeople?: string;
  activityScores?: IActivityScore[];
  attemptNo: number;
  cheatCodes?: IActivityCheatCodes;
  submitCheatCode: (c: string) => void;
  // nextActivities?: IActivityUser[];
  previousActivities?: (IActivityUser | IMissingActivityData)[];
  previousActivitiesRequirement?: (IActivityUser | IMissingActivityData)[];
}

export const InfoSectionItem = ({
  children,
  ...additionalProps
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => (
  <CardInfoSectionItem {...additionalProps} className="activityPanelMetaSection-cardInfoSectionItem">
    <CardInfoSectionItemContent>{children}</CardInfoSectionItemContent>
  </CardInfoSectionItem>
);

export const InfoSubSectionItem = ({
  children,
  ...additionalProps
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => (
  <CardInfoSubSection {...additionalProps} className="activityPanelMetaSection-cardInfoSectionItem">
    <CardInfoSectionItemContent>{children}</CardInfoSectionItemContent>
  </CardInfoSubSection>
);

export const ActivityMetaDataDisplay = ({
  activitySteps,
  longDescription,
  tags,
  timeEstimates,
  stepsState,
  activeStep,
  activityStatus,
  isLocked,
  collapseMetaInfoPanel,
  equipmentNeededDetailed,
  noOfPeople,
  activityScores,
  attemptNo = 0,
  cheatCodes,
  previousActivities,
  previousActivitiesRequirement,
  submitCheatCode,
}: IActivityMetaDataDisplayProps) => {
  const { user } = useAppSelector((state) => state.userState);
  const completedStepsState = stepsState?.filter((s) => s.status === EStepStatus.COMPLETED).map((s) => s.stepRef) || [];
  const completedSteps =
    activitySteps?.filter((s) => completedStepsState.indexOf(s.stepRef) !== -1).map((s) => s.stepRef) || [];
  const [cheatCodeInput, setCheatCodeInput] = React.useState("");
  const coreActivityTheme =
    activityScores &&
    activityScores.length > 0 &&
    AchievementCategories[activityScores.slice().sort((a, b) => b.score - a.score)[0]?.category.id];
  return (
    <CardInfoSectionWrapper
      open={!collapseMetaInfoPanel}
      className="activityPanelMetaSection-cardInfoSectionWrapper"
      maxHeight="300vh"
    >
      <CollapsableSectionInner open={!collapseMetaInfoPanel}>
        <ActivityCardSection className="activityPanelMetaSection-cardInfoSection">
          <ActivityCardSubSection flexBasis="40%">
            <InfoSectionItem style={{ background: "none" }}>
              <TextFormatter text={longDescription} keywordMap={keywordMap} />
            </InfoSectionItem>
          </ActivityCardSubSection>
          <ActivityCardSubSection flexBasis="60%">
            {tags && tags.length > 0 && (
              <InfoSectionItem style={{ background: "none", flexGrow: 0 }}>
                <Tags tags={tags} />
              </InfoSectionItem>
            )}
            {previousActivities && previousActivities.length > 0 && [UserGroup.STUDENT].indexOf(user.group) !== -1 && (
              <InfoSectionItem>
                <H3>Finish the activities below to unlock this activity</H3>
                <DependentActivities previousActivities={previousActivities} />
              </InfoSectionItem>
            )}
            {/* Non student dependent activity info */}
            {previousActivitiesRequirement &&
              previousActivitiesRequirement.filter((a) => !a.missingData).length > 0 &&
              [UserGroup.STUDENT].indexOf(user.group) === -1 && (
                <InfoSectionItem>
                  <H3>Pupils may need to finish the activities below to unlock this activity</H3>
                  <P>
                    Before they can engage with this activity, your pupils will be guided to complete the activities
                    listed below to ensure that they have sufficient understanding of the concepts needed. Your pupils
                    can bypass these 'prerequisite activities' by inputting the relevant{" "}
                    <Link to="/app/library">completion code(s)</Link>.
                  </P>
                  <List>
                    {previousActivitiesRequirement
                      .filter((a) => !a.missingData)
                      .map((a) => (
                        <ListItem key={a.id}>
                          <CTALinkButtonStyle to={`/app/activities/${a.id}`}>{a.label}</CTALinkButtonStyle>
                        </ListItem>
                      ))}
                  </List>
                  {/* TODO: Is this needed? */}
                  {/* <DependentActivities previousActivities={previousActivities} /> */}
                </InfoSectionItem>
              )}
            <InfoSection>
              <InfoSubSectionItem style={{ flex: 1 }}>
                <H3>Details</H3>
                <br></br>
                <DetailsSection>
                  <b>Tasks complete</b>
                  {completedSteps.length || 0}/{activitySteps?.length || "UNKNOWN"}
                </DetailsSection>
                <DetailsSection>
                  <b>Status</b> {isLocked ? "Locked" : ActivityStatus[activityStatus].label}
                </DetailsSection>
                <DetailsSection>
                  <b>Activity Theme</b> <span>{coreActivityTheme?.label || "Missing activity theme!"}</span>
                </DetailsSection>
                <DetailsSection>
                  <b>No of people required</b> <span>{noOfPeople}</span>
                </DetailsSection>
                {equipmentNeededDetailed && equipmentNeededDetailed.length > 0 && (
                  <DetailsSection>
                    <b>Equipment Needed</b> {equipmentNeededDetailed.join(", ")}
                  </DetailsSection>
                )}
                {activeStep && (
                  <DetailsSection>
                    <b>Active Step</b> {activeStep.label}
                  </DetailsSection>
                )}
                {((activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1 > 0) && (
                  <DetailsSection>
                    <b>Complete Count</b> {activityStatus === EActivityStatus.COMPLETED ? attemptNo : attemptNo - 1}
                  </DetailsSection>
                )) ||
                  ""}
              </InfoSubSectionItem>
              <InfoSubSectionItem>
                <H3>Estimated Time</H3>
                <br></br>
                {timeEstimates && <TimeEstimates timeEstimates={timeEstimates} />}
              </InfoSubSectionItem>
              <InfoSubSectionItem>
                <H3>Score Contributions</H3>
                <br></br>
                {activityScores && <ScoreContributions activityScores={activityScores} />}
              </InfoSubSectionItem>
            </InfoSection>
            {cheatCodes && [UserGroup.TEACHER, UserGroup.SCHOOLADMIN, UserGroup.SUPERADMIN].indexOf(user.group) !== -1 && (
              <InfoSectionItem>
                <p>Provide the code below to a pupil so they can mark this activity as complete.</p>
                <b>
                  <ClickToRevealButton code={cheatCodes.complete} />
                </b>
              </InfoSectionItem>
            )}
            {cheatCodes && [UserGroup.STUDENT].indexOf(user.group) !== -1 && (
              <InfoSectionItem>
                {/* NOTE: Set width to limit words per line */}
                <p style={{ marginBottom: "0.5rem" }}>
                  If you have completed this activity with a group ask your teacher for a completion code to get your
                  achievement points.
                </p>
                <label htmlFor="activityCheatCodeInput">
                  <b>
                    <u>Completion code:</u>
                  </b>{" "}
                </label>
                <input
                  id="activityCheatCodeInput"
                  type="text"
                  value={cheatCodeInput}
                  onChange={(e) => setCheatCodeInput(e.target.value)}
                />
                {cheatCodeInput === cheatCodes.complete && (
                  <button onClick={() => submitCheatCode(cheatCodeInput)}>Complete Activity</button>
                )}
              </InfoSectionItem>
            )}
          </ActivityCardSubSection>
        </ActivityCardSection>
      </CollapsableSectionInner>
    </CardInfoSectionWrapper>
  );
};
