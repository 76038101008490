import React from "react";
import { ConstrainedPageStyle, PageTitle, Section, P, FloatImage, Ul, Ol } from "../styles/page";
import { BoxoutHeroMain } from "../styles/hero";
import { FaqItemWrapStyle } from "../styles/faq";
import { IFaqItem } from "../components/faq/lib";
import { FaqItem } from "../components/faq";
import { DownloadLink } from "../components/Links";
import { Link } from "react-router-dom";

const entries: IFaqItem[] = [
  {
    uid: "unbox_samhe_monitor",
    label: "1. Unbox SAMHE monitor",
    group: null,
    audience: [],
    content: (
      <>
        <P>First off, please check the contents of the monitor box. It should contain:</P>
        <Ul>
          <li>SAMHE monitor and charging cable</li>
          <li>Two plastic legs for the monitor to stand on</li>
          <li>The 'AirGradient ONE Information Sheet for SAMHE' - this is information provided by the manufacturer </li>
        </Ul>
        <P>You may also want to read these two documents (which you have already received by email):</P>
        <Ul>
          <li>
            A{" "}
            <DownloadLink href="/resources/SAMHE_Monitors_Standard_Operating_Procedure_Staff.pdf">
              Standard Operating Procedures
            </DownloadLink>{" "}
            document which details best practice and safe use procedures
          </li>
          <li>
            A <DownloadLink href="/resources/samhe_risk_assessment.pdf">risk assessment</DownloadLink> relating to the
            use of SAMHE monitors in schools
          </li>
        </Ul>
        <P>
          <i>
            In your delivery, you should also have received a copy of our 'cover letter' directing you to this page. The
            cover letter also contains a reminder of what taking part in SAMHE involves, who can see your school's data
            and where to find answers to any questions you may have. You can{" "}
            <DownloadLink href="/resources/SAMHE_Monitor_cover_letter_v2.pdf">
              download another copy of the cover letter here
            </DownloadLink>{" "}
            if helpful.
          </i>
        </P>
      </>
    ),
  },
  {
    uid: "set_up_school_admin_account_on_the_samhe_web_app",
    label: "2. Set up School Admin account on the SAMHE Web App",
    group: null,
    audience: [],
    content: (
      <>
        <P>To create your Web App account, follow the steps below.</P>
        <Ol>
          <li>
            Find your <b>school ID</b>. This was provided in the email we sent with subject line 'Important: Your SAMHE
            Monitor is on it's way'
          </li>
          <li>
            Go to the <Link to="/">SAMHE Website</Link> and click 'Web App'
          </li>
          <li>
            On the <Link to="/app">Web App landing page</Link> select 'Register a New User'
          </li>
          <li>
            Select 'Admin' from the tabs along the top and complete the account registration process. To do this you
            will need:
            <Ul>
              <li>
                Your <b>school ID</b>, which was sent to you via email
              </li>
              <li>
                The <b>generic/office email address</b> you provided via the registration form (confirmation of this
                email address can also be found in the email referenced above)
              </li>
            </Ul>
          </li>
        </Ol>
        <P>
          <b>
            Please make sure you've read the <Link to="/documents/admin-participation-info">admin user sheet</Link>{" "}
            before registering.
          </b>
        </P>
        <P>
          <i>
            Note: All schools have to create an Admin account with which all subsequent accounts at your school will be
            associated.This account has to use the generic/office email address you provided via our registration form,
            so it remains accessible independently of any individual teacher. If you're an interested teacher you will
            be able to set up an individual account at a later stage (see step 4 below). Admin users also have special
            responsibilities, including sharing Secret Words which other users will need to create their accounts.
          </i>
        </P>
      </>
    ),
  },
  {
    uid: "login_to_your_school_admin_account_and_complete_the_set_up_process",
    label: "3. Login to your School Admin account and complete the Set Up process",
    group: null,
    audience: [],
    content: (
      <>
        <P>
          Immediately after registering you will be asked to login using your new School Admin account. The first time
          you login you'll be taken directly to the set up pages which will walk you through:
        </P>
        <Ul>
          <li>
            Activating your school (which involves creating your school's room list and Setting up a 'School handle' and
            'Secret Words' which other users at your school can use to connect their account with your school's monitor)
          </li>
          <li>Linking your monitor with your Web App account</li>
          <li>
            <Link to="/resources/monitor-placement-guide">Choosing where to put your monitor</Link>
          </li>
          <li>
            <Link to="/monitor-connection-guide">Connecting your monitor to the WiFi</Link>
          </li>
        </Ul>
        <P>
          If you are having any difficulties connecting your monitor, take a look at our{" "}
          <Link to="/support">tech support</Link> page for some information about fixes for common issues and if you
          can't find an answer to your question, please get in touch using our{" "}
          <Link to="/support/tech-contact">technical queries form</Link> - we're happy to help!
        </P>
      </>
    ),
  },
  {
    uid: "invite_other_users_at_your_school_to_create_their_own_accounts",
    label: "4. Invite other users at your school to create their own accounts!",
    group: null,
    audience: [],
    content: (
      <>
        <P>
          Having completed the Set Up process your monitor will be drawing live data from your monitor into the Web App
          which will be available for you to explore!
        </P>
        <P>
          You're ready to invite other members of staff or pupils at your school to create their own accounts, and begin
          using SAMHE however you like! Please follow whatever your usual processes are for telling parents / guardians
          about activities, we have{" "}
          <DownloadLink href="/resources/SAMHE_Parent_information_pack.pdf">provided templates</DownloadLink> to support
          you in communicating with them, and there are{" "}
          <Link to="/faq?audience=parents">parent FAQs covering common questions</Link>.
        </P>
        <P>
          For other school staff to set up individual teacher accounts, you'll need to share the school handle and
          teacher 'Secret Word' with them. (You'll create these when you set up the Admin account)
        </P>
        <P>
          For pupils to create their own accounts, you'll need to share the school handle and pupil 'Secret Word' with
          them.
        </P>
        <P>
          For step-by-step instructions on creating accounts for other users, see these FAQs: '
          <Link to="/support#how_can_i_set_up_staff_accounts">How can I set up teacher accounts?</Link>' and '
          <Link to="/support#how_can_i_set_up_pupil_accounts">How can I set up pupil accounts?</Link>'.
        </P>
      </>
    ),
  },
  {
    uid: "get_stuck_in",
    label: "5. Get stuck in",
    group: null,
    audience: [],
    content: (
      <>
        <P>
          Now you're set up on our Web App it's over to you to use it however works for you. If you're looking for some
          inspiration, here are some ideas about what you might like to do next:
        </P>
        <Ul>
          <li>
            <b>Try our tutorial</b> - Our <Link to="/app/activities/23">Web App Tutorial</Link> will show you key
            features and functionality of the Web App.
          </li>
          <li>
            <b>Get to grips with the basics</b> - some of the terms we use in our Web App may be unfamiliar. The
            following pages will help:
            <Ul>
              <li>
                Look at our <Link to="/resources/key-definitions">key definitions</Link> and{" "}
                <Link to="/resources/air-pollution">about air pollution</Link> pages which explain key terms and
                concepts.
              </li>
              <li>
                Use our <Link to="/resources/monitor-leds">guidance on monitor readings</Link> to put your data into
                context.
              </li>
              <li>
                For an overview of how ventilation works and a list of practical things you can do to improve classroom
                air quality, read our <Link to="/resources/ventilation">ventilation guidance</Link>
              </li>
            </Ul>
          </li>
          <li>
            <b>Introduce others in your school to SAMHE</b> - we've drafted two versions of an{" "}
            <DownloadLink href="/resources/samhe_assemblies_and_notes_for_schools.zip">
              introductory assembly
            </DownloadLink>{" "}
            you could use at your school.
          </li>
          <li>
            <b>Familiarise yourself with your data</b> - try the different features on our data views to begin to get an
            idea of what 'normal' looks like for your school. Are there any patterns? All data can be downloaded as a
            .csv file
          </li>
          <li>
            <b>Try out some activities </b>
            <Ul>
              <li>
                For pupil users, all activities will be locked until they have completed our introductory quizzes (1 per
                air quality metric) which are designed to help them understand key terms before they start analysing the
                data. If you would prefer to bypass this you can provide them with a code (codes for each activity are
                available to teacher and admin users when the activity front card is expanded; pupil users enter a code
                in the equivalent location).
              </li>
              <li>
                We think our '<Link to="/app/activities/18">Describe Your Room</Link>' activity is a great starter
                activity, but have a look and see for yourself!
              </li>
            </Ul>
          </li>
        </Ul>
      </>
    ),
  },
];

const GettingStartedPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Getting Started</PageTitle>
      <BoxoutHeroMain style={{ padding: "1rem" }}>
        <Section>
          <FloatImage
            src="/logo192.png"
            alt="SAMHE logo"
            direction="right"
            desktopStyleOverride={"max-width: 10rem;margin-left: 6rem;"}
          />
          <h2>
            If you have received a SAMHE monitor, this checklist will run you through the things you need to do to get
            started. If you like, you can{" "}
            <DownloadLink href="/resources/SAMHE_Getting_Started_Checklist_v2.1.pdf">
              download and print a copy.
            </DownloadLink>
          </h2>
          <br />
          <h2>
            If your school does not have a SAMHE monitor, this process does not apply to you. We have developed a{" "}
            <Link to="/get-involved">teacher resource pack</Link> to enable you to benefit from SAMHE resources without
            having a monitor.
          </h2>
        </Section>
      </BoxoutHeroMain>
      <br></br>
      <Section>
        <FaqItemWrapStyle>
          {entries.map((item) => (
            <FaqItem key={item.uid} data={item} />
          ))}
        </FaqItemWrapStyle>
      </Section>
    </ConstrainedPageStyle>
  );
};

export default GettingStartedPage;
