import React from "react";

export const getNodeText = (node) => {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
};

export const countWordsInReactElement = (element: React.ReactNode): number => {
  let wordCount = 0;

  const countWords = (childElements: React.ReactNode) => {
    React.Children.forEach(childElements, (child) => {
      if (React.isValidElement(child)) {
        const childProps = child.props as { children?: React.ReactNode };
        if (typeof childProps.children === "string") {
          const text = childProps.children.trim();
          const words = text.split(/\s+/);
          wordCount += words.length;
        } else if (childProps.children) {
          countWords(React.Children.toArray(childProps.children));
        }
      }
    });
  };

  countWords(React.Children.toArray(element));

  return wordCount;
};
