import styled from "styled-components";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { getNextStep } from "../../nextStepUtils";

export interface IFeedbackFreetextExtraProps {}

export interface IFeedbackFreetextState {
  value?: string;
}

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
`;

export const FeedbackFreetextStep = (props: IStepProps<IFeedbackFreetextExtraProps, IFeedbackFreetextState>) => {
  const {
    label,
    description,
    id: uid,
    state,
    onChange,
    onSubmit,
    nextSteps,
    previousStepsState,
    activitySteps,
  } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const nextStepId = null;
        const nextStepRef = getNextStep(nextStepId as string, nextSteps, previousStepsState, activitySteps, props, {
          ...props,
          state,
        });
        onSubmit({ newValue: state, nextStep: nextStepRef });
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <TextArea
        id={`stepInput_${uid}`}
        value={(state && state.value) || ""}
        onChange={(e) => onChange("value", e.target.value)}
        aria-label={label}
      />
      <CTAButtonStyle type="submit">Ok</CTAButtonStyle>
    </form>
  );
};
