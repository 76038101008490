import { EAggregationInterval } from "../../../lib/MonitorData/AggregationIntervals";
import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";

export type Hour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export interface IBucketRange {
  min: number;
  max: number;
  label: string;
  color: string;
}

export const processDataForBarPlot = (
  data: IMonitorDataItem<number>[],
  bucketRanges: IBucketRange[],
  aggregation: EAggregationInterval
) => {
  const bucketGroups = bucketRanges.reduce((acc, bucketRange) => {
    return {
      ...acc,
      [bucketRange.label]: {
        count: 0,
        bucketLabel: bucketRange.label,
      },
    };
  }, {});

  const groupedByRangeData: { [key: string]: { count: number } } = data.reduce((groups, item) => {
    const { value } = item;
    const bucketRange = bucketRanges.find((bucketRange) => {
      const { min, max } = bucketRange;
      return value >= min && value <= max;
    }) || {
      label: "Out of range!",
      color: "black",
    };
    if(bucketRange.label === "Out of range!") console.log("Out of range!", value);
    const key = bucketRange.label;
    const groupsOut = { ...groups };
    const oldGroupData = groups[key] || { count: 0 };
    groupsOut[key] = {
      ...oldGroupData,
      count: oldGroupData.count + 1,
      bucketLabel: bucketRange.label,
    };
    return groupsOut;
  }, bucketGroups);

  const aggregationMultiplier =
    (aggregation === EAggregationInterval.NONE && 1 / 60) ||
    (aggregation === EAggregationInterval.MINUTE && 1 / 60) ||
    (aggregation === EAggregationInterval.FIVE_MINUTE && 1 / 12) ||
    (aggregation === EAggregationInterval.TEN_MINUTE && 1 / 6) ||
    (aggregation === EAggregationInterval.HOUR && 1) ||
    "INVALID AGGREGATION!";
  if (aggregationMultiplier === "INVALID AGGREGATION!") throw Error(`Invalid aggregation interval provided (${aggregation})! `);

  return Object.entries(groupedByRangeData).map(([key, value]) => [key, value.count * aggregationMultiplier]);
};

export const usePreprocessBarData = (
  data: IMonitorDataItem<number>[],
  bucketRanges: IBucketRange[],
  aggregation: EAggregationInterval
) => processDataForBarPlot(data, bucketRanges, aggregation);
