import styled from "styled-components";
import { ButtonNoFormat } from "../../styles/button";
import { EStatus } from "./usePingTest";

export interface IPingStatusIconProps {
  status: EStatus;
}

export const PingStatusIcon = styled(ButtonNoFormat)<IPingStatusIconProps>`
  max-width: 1rem;
  max-height: 1rem;
  border-radius: 2rem;
  opacity: 0.3;

  position: absolute;
  bottom: 0;
  right: 0;
  margin: ${({theme}) => theme.shape.defaultPadding};
  padding 0 0;
  overflow: hidden;
  color: rgba(0,0,0,0);

  transition: all 0.2s ease-in;

  text-decoration: none;

  &:hover {
    color: white;
    opacity: 1;
    text-decoration: none;
    max-width: 20rem;
    max-height: 2rem;
    padding 0 1rem;
  }

  &:focus {
    text-decoration: none;
  }

  ${({ status }) =>
    status === EStatus.OK
      ? `
        background: green;
        &:hover {
          background: green;
        }
  `
      : ``}


      ${({ status }) =>
        status === EStatus.LOADING
          ? `
        background: orange;
        &:hover {
          background: orange;
        }
    `
          : ``}


  ${({ status }) =>
    status === EStatus.ERROR || status === EStatus.TIMED_OUT
      ? `
    background: tomato;
    &:hover {
      background: tomato;
    }
`
      : ``}
`;
