import { getDataFetch, postDataFetch, throwErrors } from "./apiHelpers";
import { SERVER_URL } from "../config";
import { IUserIdentity } from "../lib/User/IUser";
import { EAgeGroup } from "../lib/User/AgeGroup";

export const apiGetUserState = async (accessToken: string) => {
  if (!accessToken) throw Error("Missing access token");
  const url = `${SERVER_URL}/identity/GetUser`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IUserIdentity>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => response.json);
};

export const apiPutUserAgeGroup = async (accessToken: string, ageGroup: EAgeGroup) => {
  if (!accessToken) throw Error("Missing access token");
  const url = `${SERVER_URL}/user/UpdateAgeGroup?ageGroup=${ageGroup}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}>(url, {}, headers)
    .then(throwErrors)
    .then(({ response }) => response.json);
};
