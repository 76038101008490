import React from "react";
import { Link } from "react-router-dom";
import { CTAButtonStyle } from "../../styles/button";
import { DocumentStyle } from "../../styles/documents";
import { Section, SectionHeading, P, Ul } from "../../styles/page";
import { AExternal } from "../Links";

export const lastUpdated = "06/04/2023";
export const version = "3.1";

export const AdultContactPrivacyPolicy = () => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>
        <P> Last updated {lastUpdated} </P>
      </Section>
      <Section>
        <SectionHeading>Privacy Notice - for adults</SectionHeading>
        <P>
          This Privacy Notice tells you what to expect when you complete the SAMHE technical queries contact form. SAMHE
          (Schools Air quality Monitoring for Health and Education) is a research project jointly led by Imperial
          College London and the University of Cambridge in collaboration with the Stockholm Environment Institute (SEI)
          at the University of York, the University of Surrey, the University of Leeds and the United Kingdom Health
          Security Agency.
        </P>
      </Section>
      <Section>
        <SectionHeading>How will your data be used?</SectionHeading>
        <P>
          Imperial College London is the sponsor for this study and will act as the data controller for this study. This
          means that we are responsible for looking after your information and using it appropriately. Imperial College
          London will keep the personal data you enter in this form: Until we have resolved your technical issue.
        </P>
        <P>To process this form we will need to use information from you.</P>
        <P>This information will include your:</P>
        <Ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Details about your technical issue</li>
        </Ul>
        <P>People will use this information to respond to the query you submit using the form.</P>
        <P>We will keep all your information safe and secure.</P>
      </Section>
      <Section>
        <SectionHeading>Legal Basis</SectionHeading>
        <P>
          As a university we use personal information to conduct research that will either be in the public interest, or
          the 'legitimate interests' of Imperial College whose interests are to conduct world leading research and
          innovation aiming to deliver transformative impact for society.
        </P>
        <P>
          Our legal basis for using your information under the General Data Protection Regulation (GDPR) and the Data
          Protection Act 2018, is as follows:
        </P>
        <P>
          Imperial College London - "performance of a task carried out in the public interest" or 'legitimate interests'
          held by the controller;
        </P>
        <P>
          We will conduct scientific research in compliance with the law and the recommendations and guidance published
          by the UK Information Commissioners Office (ICO). This will follow similar rules to health and social care
          research but may not meet the definition of the College's public interest task or requirement. In such cases
          we will be holding and using your data for what are called our "legitimate interests" where, as previously
          stated, the College aims to conduct world leading research and innovation aiming to deliver transformative
          impact for society. Scientific research should serve the public interest, which means that we have to
          demonstrate that our research has societal impact serving the interests of society as a whole.
        </P>
      </Section>
      <Section>
        <SectionHeading>International Transfers</SectionHeading>
        <P>
          There may be a requirement to transfer information to countries outside the European Economic Area (for
          example, to a research partner). Where this information contains your personal data, Imperial College London
          will ensure that it is transferred in accordance with data protection legislation. If the data is transferred
          to a country which is not subject to a European Commission (EC) adequacy decision in respect of its data
          protection standards, Imperial College London will enter into a data sharing agreement with the recipient
          organisation that incorporates EC approved standard contractual clauses that safeguard how your personal data
          is processed.
        </P>
      </Section>
      <Section>
        <SectionHeading>Sharing your information with others</SectionHeading>
        <P>
          For the purposes referred to in this privacy notice and relying on the bases for processing as set out above,
          we will share your personal data with certain third parties.{" "}
        </P>
        <Ul>
          <li>
            Other College employees, agents, contractors and service providers (for example, suppliers of printing and
            mailing services, email communication services or web services, or suppliers who help us carry out any of
            the activities described above). Our third party service providers are required to enter into data
            processing agreements with us. We only permit them to process your personal data for specified purposes and
            in accordance with our policies.{" "}
          </li>
          <li>With the relevant agencies if this form is used to disclose a child protection concern.</li>
          <li>The following Research Collaborators / Partners in the study:</li>
          <Ul>
            <li>
              The Stockholm Environment Institute at the University of York - because they are leading the
              communications portion of the project{" "}
            </li>
            <li>
              The University of Cambridge - because they will be assisting the University of York in project
              communications{" "}
            </li>
            <li>Third party contractors of the research team </li>
          </Ul>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Potential use of study data for future research</SectionHeading>
        <P>
          The primary purpose of this contact form is to provide a means of you contacting the SAMHE team regarding any
          technical queries in relation to the SAMHE monitors, Web App or website. Therefore, ordinarily the information
          you provide will be relevant to the resolution of your query only and used for that purpose alone. In the
          event that information you submit via this form is relevant for research, either as part of the current study,
          or in preparation for subsequent studies, we may, with your consent provide this information to researchers
          running other research studies in this organisation and in other organisations. These organisations may be
          universities or organisations involved in research in this country or abroad. Your information will only be
          used by organisations and researchers to conduct research in accordance with all relevant legislation
          including the GDPR (UK GDPR and EU GDPR 2016/679), the Data Protection Act 2018.
        </P>
        <P>
          This information will not identify you and will not be combined with other information in a way that could
          identify you, used against you or used to make decisions about you.
        </P>
      </Section>
      <Section>
        <SectionHeading>Commercialisation</SectionHeading>
        <P>
          In the event that it is relevant to research, information submitted via this form may also be provided to
          organisations not named in this participant information sheet, e.g. commercial organisations or non-commercial
          organisations for the purposes of undertaking the current study, future research studies or commercial
          purposes such as development by a company of a new test, product, service or treatment. We will ensure your
          name and any identifying details will NOT be given to these third parties, instead you will be identified by a
          unique study number with any analysis having the potential to generate personal data'.
        </P>
        <P>
          Aggregated (combined) or anonymised data sets (all identifying information is removed) may also be created
          using your data (in a way which does not identify you individually) and be used for such research or
          commercial purposes where the purposes align to relevant legislation (including the GDPR) and wider aims of
          the study. Your data will not be shared with a commercial organisation for marketing purposes.
        </P>
      </Section>
      <Section>
        <SectionHeading>What rights do you have in relation to your data?</SectionHeading>
        <P>
          Under the General Data Protection Regulation (GDPR), you have a right of access to your data, a right to
          rectification, erasure (in certain circumstances), restriction, objection or portability (in certain
          circumstances). You also have a right to withdraw consent (where this applies).
        </P>
      </Section>
      <Section>
        <SectionHeading>What are your choices about how your information is used?</SectionHeading>
        <P>
          You can stop this form at any time before submitting by quitting the browser window. After submitting the form
          you will not be able to withdraw your data.
        </P>
      </Section>
      <Section>
        <SectionHeading>Where can you find out more about how your information is used?</SectionHeading>
        <P>
          You can find out more about how we use your information by sending an email to{" "}
          <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>
        </P>
      </Section>
      <Section>
        <SectionHeading>Complaint</SectionHeading>
        <P>
          If you wish to raise a complaint on how we have handled your personal data, please contact Imperial College
          London's Data Protection Officer via email at <a href="mailto:dpo@imperial.ac.uk">dpo@imperial.ac.uk</a>, via
          telephone on 020 7594 3502 and/or via post at Imperial College London, Data Protection Officer, Faculty
          Building Level 4, London SW7 2AZ.
        </P>
        <P>
          If you are not satisfied with our response or believe we are processing your personal data in a way that is
          not lawful you can complain to the Information Commissioner's Office (ICO). The ICO does recommend that you
          seek to resolve matters with the data controller (us) first before involving the regulator.
        </P>
      </Section>
    </DocumentStyle>
  );
};

export const ChildrenContactPrivacyPolicy = ({ setShowShortVersion }) => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>
        <P> Last updated {lastUpdated} </P>
      </Section>
      <Section>
        <SectionHeading>Privacy Notice - for pupils</SectionHeading>
        <SectionHeading>Who we are</SectionHeading>
        <P>
          We are researchers running a project called SAMHE which stands for <b>S</b>chools' <b>A</b>ir quality <b>M</b>
          onitoring for <b>H</b>ealth and <b>E</b>ducation. The aim of SAMHE is to help you understand more about air
          quality and to explore with you, using our Web App, how different things you do might affect it.
        </P>
      </Section>
      <Section>
        <SectionHeading>What data do we have about you?</SectionHeading>
        <P>
          If you complete this form we will have your name and email address which are classed as personal data.
          Personal data means information that could be used to identify you and it is protected by law.
        </P>
        <P>We will keep all your information safe and secure.</P>
      </Section>
      <Section>
        <SectionHeading>Where do we get your data?</SectionHeading>
        <P>We get it from you when you fill in this form. We won't ever ask other people for your personal data.</P>
      </Section>
      <Section>
        <SectionHeading>How will your data be used?</SectionHeading>
        <P>
          We ask for your personal data so that we can contact you to help solve the problem you report using this form.
        </P>
      </Section>
      <Section>
        <SectionHeading>How long will we keep your data for?</SectionHeading>
        <P>We will keep your email address until we have sorted out your technical problem.</P>
      </Section>
      <Section>
        <SectionHeading>Your rights</SectionHeading>
        <P>
          You have rights in relation to your personal data. These are the same rights as an adult has and they help you
          to keep it safe. For more information on what these are you can look at{" "}
          <AExternal href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
            this guide from the Information Commissioner's Office
          </AExternal>{" "}
          or refer to our{" "}
          <Link to="/privacy/tech-contact" onClick={() => setShowShortVersion(false)}>
            general privacy notice{" "}
          </Link>
          (which is a bit longer and more detailed than this one).
        </P>
      </Section>
      <Section>
        <SectionHeading>Who to contact if you are concerned about how your data is used.</SectionHeading>
        <P>
          If you want to know we use your personal data, or if you have any questions you'd like to ask us then please
          contact us. If you are under 13 your parent or carer would need to do this. If you are over 13 you can send us
          a message yourself. You can contact us at <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>.
        </P>
      </Section>
    </DocumentStyle>
  );
};

export const TechContactPrivacyPolicy = () => {
  const [showShortVersion, setShowShortVersion] = React.useState(false);
  return (
    <>
      <CTAButtonStyle onClick={() => setShowShortVersion((prev) => !prev)}>
        {showShortVersion ? "Show full Privacy Notice" : "Show version for pupils"}
      </CTAButtonStyle>
      {showShortVersion && <ChildrenContactPrivacyPolicy setShowShortVersion={setShowShortVersion} />}
      {!showShortVersion && <AdultContactPrivacyPolicy />}
    </>
  );
};
