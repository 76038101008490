import React from "react";
import { AchievementCategories } from "../../../lib/Achievements/EAchievementCategory";
import { IUserScore } from "../../../lib/Achievements/IAchievement";
import {
  BOX_DIM,
  ProgressRingBackgroundStyle,
  ProgressRingDialStyle,
  ProgressRingProgressStyle,
  ProgressRingWrapperStyle,
  RING_EXPAND_RATIO,
  UserScoreDescriptionStyle,
  UserScoreLabelStyle,
  UserScoreLevelStyle,
  UserScoreNumberStyle,
  UserScoreWidgetContentStyle,
  UserScoreWidgetIconStyle,
  UserScoreWidgetStyle,
  UserScoreWidgetWrapStyle,
} from "./style";

export interface IUserScoreWidgetProps extends IUserScore {}
const SCORE_PER_LEVEL = 10;

const ProgressRing = ({ color, progress }) => {
  const ringRadius = (BOX_DIM * RING_EXPAND_RATIO) / 2 - 0.5;
  const cx = (BOX_DIM * RING_EXPAND_RATIO) / 2;
  const cy = (BOX_DIM * RING_EXPAND_RATIO) / 2;

  const circumference = 2 * Math.PI * ringRadius;
  const offset = circumference - (progress / SCORE_PER_LEVEL) * circumference;

  const progressStrokeDasharray = `${circumference * 16} ${circumference * 16}`;
  const progressStrokeDashoffset = `${offset * 16}`;

  const dialWidth = 0.4;
  const dialBlankWidth = (2 * Math.PI * ringRadius) / 10 - dialWidth;

  const dialStrokeDasharray = `${dialWidth * 16} ${dialBlankWidth * 16}`;

  return (
    <ProgressRingWrapperStyle>
      <ProgressRingBackgroundStyle r={ringRadius * 16} cx={cx * 16} cy={cy * 16} color={color} />
      <ProgressRingProgressStyle
        r={ringRadius * 16}
        cx={cx * 16}
        cy={cy * 16}
        color={color}
        progressStrokeDasharray={progressStrokeDasharray}
        progressStrokeDashoffset={progressStrokeDashoffset}
      />
      <ProgressRingDialStyle r={ringRadius * 16} cx={cx * 16} cy={cy * 16} dialStrokeDasharray={dialStrokeDasharray} />
    </ProgressRingWrapperStyle>
  );
};

export const UserScoreWidget: React.FC<IUserScoreWidgetProps> = ({
  label,
  value = 0,
  color,
  category,
  description,
}) => {
  const buttonRef = React.useRef(null);
  const handleClick = () => {
    buttonRef.current.focus();
  };

  const handleBlur = () => {
    buttonRef.current.blur();
  };

  const progress = Math.floor(value % SCORE_PER_LEVEL);
  const level = Math.floor(value / SCORE_PER_LEVEL) + 1;

  const scoreString =
    SCORE_PER_LEVEL - progress > 1
      ? SCORE_PER_LEVEL - progress + " points to go"
      : SCORE_PER_LEVEL - progress + " point to go";

  return (
    <UserScoreWidgetWrapStyle color={color} data-testid="userScoreWidgetWrap">
      <UserScoreWidgetStyle
        color={color}
        data-testid="userScoreWidget"
        ref={buttonRef}
        onClick={handleClick}
        onBlur={handleBlur}
      >
        <ProgressRing color={color} progress={progress} />
        <UserScoreWidgetContentStyle className="userScoreWidgetContent" color={color}>
          <div style={{ paddingTop: "1rem" }}>
            <UserScoreLabelStyle className="userScoreLabel">{label}</UserScoreLabelStyle>
            <UserScoreWidgetIconStyle color={color}>
              <img className="icon" src={AchievementCategories[category].logo} width="100%" alt={label} />
            </UserScoreWidgetIconStyle>
            <UserScoreNumberStyle value={progress} color={color}>
              {scoreString}
            </UserScoreNumberStyle>
            <UserScoreLevelStyle>Level {level}</UserScoreLevelStyle>
          </div>
        </UserScoreWidgetContentStyle>
        <UserScoreDescriptionStyle className="userScoreDescription" color={color}>
          <p>{description}</p>
        </UserScoreDescriptionStyle>
      </UserScoreWidgetStyle>
    </UserScoreWidgetWrapStyle>
  );
};
