import { Link } from "react-router-dom";
import styled from "styled-components";
import { BETA_VERSION, VERSION } from "../../config";
import { SocialMediaLinks } from "../SocialMediaLinks";

const FooterStyle = styled.footer`
  background-color: ${(props) => props.theme.colors.main};
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.colors.main};
`;

const CopyrightStyle = styled.footer`
  background-color: ${({ theme }) => theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const LinkBar = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.main};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.main};
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
  }
`;

const QLink = styled.span`
  display: flex;
  a {
    color: ${(props) => props.theme.background};
    text-decoration: none;
    font-size: 0.9rem;
    text-align: center;

    &:hover {
        color: ${({ theme }) => theme.background}
    }
  }

  & {
    margin-right: 0.5rem;
    margin-left:0.5rem;
  }
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    flex-direction: row;
    width: auto;
  }
`;

export const SamheLogo = styled.img`
  max-height: 5rem;
  margin-left: 0;
  margin-top: 1rem;
  @media (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    margin-left: 1rem;
    margin-top: 0;
  }
`;

export const Footer = () => {
  return (
    <FooterStyle className="footer">
      <Link style={{ textAlign: "center", fontSize: "0.9rem", color: "white" }} to="/contact/#enquiries">Stay informed by signing up for the SAMHE newsletter</Link>
      <LinkBar>
        <QLink>
          <Link to="/faq">FAQs</Link>
        </QLink>
        <QLink>
          <Link to="/resources/media">Media</Link>
        </QLink>
        <QLink>
          <Link to="/privacy">Privacy Policy</Link>
        </QLink>
        <QLink>
          <Link to="/cookies">Cookie Policy</Link>
        </QLink>
        <SocialMediaLinks />
      </LinkBar>
      <CopyrightStyle>
        <p style={{ textAlign: "center", fontSize: "0.9rem" }}>Copyright © 2022 SAMHE Project - All Rights Reserved.</p>
        <p style={{ textAlign: "center", fontSize: "0.9rem" }}>SAMHE version: <b>{VERSION}</b>{BETA_VERSION && "BETA"}.</p>
      </CopyrightStyle>
    </FooterStyle>
  );
};
