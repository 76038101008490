import { getDataFetch, postDataFetch, IApiResponse, throwErrors } from "./apiHelpers";
import { SERVER_URL } from "../config";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";
import { IMonitorRequestSubmission } from "../lib/formSubmissions/IMonitorRequestSubmission";
import { ISchoolEstablishment } from "../lib/formSubmissions/ISchoolEstablishment";

export interface IApiMonitorRequestReturn extends IApiResponse {
  data: IMonitorRequest;
}

export interface IApiMonitorRequestsReturn extends IApiResponse {
  data: IMonitorRequest[];
}

export const getMonitorRequest = (accessToken: string) => async (id: string) => {
  const url = `${SERVER_URL}/MonitorRequests/${id}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiMonitorRequestReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => response.json.data);
};

// TODO this whole thing needs refactoring now i've messed it up :) MS
export const searchMonitorRequests = async (
  validating: boolean,
  validated: boolean,
  isInvalid: boolean,
  searchPhrase: string,
  lastEditedByUser: boolean,
  accessToken: string,
  all: boolean = false, // TODO perhaps a different function to save passing in the above params
  duplicates: boolean = false
) => {
  const query = all
    ? ""
    : duplicates
    ? `duplicates=${duplicates ? "true" : false}`
    : `validated=${validated ? "true" : "false"}&validating=${validating ? "true" : "false"}&isInvalid=${
        isInvalid ? "true" : "false"
      }&searchPhrase=${searchPhrase || ""}&lastEditedByUser=${lastEditedByUser ? "true" : "false"}`;
  const url = `${SERVER_URL}/MonitorRequests/search?${query}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiMonitorRequestsReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => response.json.data);
};

export const apiSubmitMonitorRequest = async (data: IMonitorRequestSubmission) => {
  const url = `${SERVER_URL}/MonitorRequests`;
  return postDataFetch<IMonitorRequestSubmission, IApiResponse>(url, data);
};

export const apiGetSchoolFromFullList = async (postcode: string) => {
  const url = `${SERVER_URL}/SchoolEstablishment?postcode=${postcode}`;
  return getDataFetch<{ data: ISchoolEstablishment[] }>(url)
    .then(throwErrors)
    .then(({ response }) => response.json.data);
};

export const apiMonitorRequest = {
  get: getMonitorRequest,
  search: searchMonitorRequests,
  post: apiSubmitMonitorRequest,
  apiGetSchoolFromFullList,
};
