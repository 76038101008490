import React from "react";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";
import { EDayRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { defaultDateRangeState } from "../../../DateRangePicker/utils";
import {
  IGaugeChartCardExpandedProps,
  IGaugeChartCollapsedCardProps,
  IGaugeChartsProps,
} from "../../../DataViz/GaugeChart/lib";
import { DataMetricsMetaData } from "../../../../lib/DataViews/EDataMetrics";
import { refreshTimes } from "../../../DateRangePicker/utils";
import { useDataLoader } from "../../dataLoader";
import { useInterval } from "react-use";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import {
  CardInfoSectionWrapper,
  CardStyle,
  CardTitle,
  CardTitleBar,
  PlotDescriptionSection,
} from "../../../../GenericComponents/CardBoard/styles";
import { GaugeChart, GaugeChartInner } from "../../../DataViz/GaugeChart/GaugeChart";
import { CardExpandedHr, DataViewButton, ErrorMessage } from "../../../../styles/app";
import { TextFormatter } from "../../../TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { GaugeChartOptions } from "./GaugeChartOptions";
import { Br, FlexBox } from "../../../../styles/singlePageStyles";
import { Tags } from "../../../Tags";
import { DataViewCsvDownload } from "../../DataViewComponents";
import { ExpandedPanelContainer, ExpandedPanelHeaderStyle, ExpandedPanelInnerStyle } from "../../../../styles/popups";
import { ExpandedCardLoader } from "../../CardLoader";
import { ButtonStyle } from "../../../../styles/button";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { GraphCaptionBox } from "../../DataViewComponents/GraphCaptionBox";
import { GraphGuidanceBox } from "../../DataViewComponents/GraphGuidanceBox";

const defaultGaugeChartDateRange: IDateRangeState = refreshTimes({
  ...defaultDateRangeState(),
  aggregation: EAggregationInterval.NONE,
  dayRange: EDayRange.LAST_HOUR,
});

export const GaugeChartInnerCard = ({
  id,
  monitorId,
  label,
  additionalProps: { metrics, gaugeVisualMapProps },
  liveUpdate,
}: IGaugeChartCollapsedCardProps) => {
  const { label: metricLabel } = (metrics && DataMetricsMetaData[metrics[0]]) || {};
  if (!metricLabel) throw Error(`Invalid Metric: ${metrics[0]}`);
  const [dateRange, setDateRange] = React.useState(() => refreshTimes(defaultGaugeChartDateRange));

  const loadedData = useDataLoader({
    monitorId,
    currentMetric: metrics[0],
    dateRange,
    blocked: dateRange.invalid && true,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <div className="inner">
      <CardTitleBar>
        <CardTitle>
          <DataViewButton to={`/app/data/${id}`}>{String(label)}</DataViewButton>
        </CardTitle>
      </CardTitleBar>
      {loadedData?.value?.data && (
        <GaugeChartInner
          data={loadedData?.value?.data}
          preProcessDataOptions={{}}
          gaugeVisualMapProps={gaugeVisualMapProps}
        />
      )}
      {loadedData.error && <ErrorMessage>{loadedData.error.message}</ErrorMessage>}
    </div>
  );
};

export const GaugeChartExpanded = ({
  label,
  monitorId,
  description,
  longDescription,
  tags,
  additionalProps: { metrics, gaugeVisualMapProps, graphCaption, graphGuidance },
  onLoadedData,
  dateRange,
  setDateRange,
}: IGaugeChartCardExpandedProps) => {
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const { loadedData, loading, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric: metrics[0],
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: EAggregationInterval.MINUTE,
    onLoadedData: onLoadedData,
  });
  const [showLongDesc, setShowLongDesc] = React.useState(true);

  return (
    <div className="simplePlotExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <CardExpandedHr />
      <PlotDescriptionSection>
        <TextFormatter text={String(description)} keywordMap={keywordMap}></TextFormatter>
        {longDescription && (
          <>
            <CardInfoSectionWrapper
              open={showLongDesc}
              maxHeight="70rem"
              style={{ width: "100%" }}
              transitionTime={0.2}
            >
              <CollapsableSectionInner open={showLongDesc}>
                <FlexBox horiz flexwrap style={{ gap: "2rem" }}>
                  <div data-testid="long-description" style={{ flex: "1 1 30rem" }}>
                    <TextFormatter text={longDescription} keywordMap={keywordMap} />
                  </div>
                  {graphCaption && <GraphCaptionBox text={graphCaption} />}
                </FlexBox>
              </CollapsableSectionInner>
            </CardInfoSectionWrapper>
            <ButtonStyle style={{ width: "100%", marginTop: "1rem" }} onClick={() => setShowLongDesc((prev) => !prev)}>
              {showLongDesc ? "Hide Description" : "Show Description"}
            </ButtonStyle>
          </>
        )}
      </PlotDescriptionSection>
      <Br />
      {tags && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tags tags={tags} />
        </div>
      )}
      <Br />

      {dataLoadError && (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadError && dataLoadError.message}</ErrorMessage>
        </FlexBox>
      )}
      {readyToView && (
        <div style={{ width: "100%" }}>
          <GaugeChart
            data={loadedData?.data}
            preProcessDataOptions={{ timeRangeFilter: [dateRange.startTimeLimit, dateRange.endTimeLimit] }}
            gaugeVisualMapProps={gaugeVisualMapProps}
          />
        </div>
      )}
      {loading ? "Loading Data..." : ""}
      <Br />
      <Br />
      <GaugeChartOptions
        reloadData={() => setDateRange(refreshTimes(defaultGaugeChartDateRange))}
        loading={loading}
        setLiveUpdate={setLiveUpdate}
        liveUpdate={liveUpdate}
      />
      {graphGuidance && <GraphGuidanceBox text={graphGuidance} />}
    </div>
  );
};

export const GaugeChartCard = (props: IGaugeChartsProps) => (
  <CardStyle>
    <GaugeChartInnerCard {...props} />
  </CardStyle>
);

export const GaugeChartCardExpanded = (props: IGaugeChartsProps) => {
  const { uniqueCardId } = props;
  const [loadedData, setLoadedData] = React.useState<any>(null);
  // Callback function to receive data from the child component
  const handleLoadedData = (data: any) => {
    setLoadedData(data);
  };
  const [dateRange, setDateRange] = React.useState(() => refreshTimes(defaultGaugeChartDateRange));

  return (
    <>
      <ExpandedPanelContainer data-testid={`${uniqueCardId}_expanded-panel`}>
        <ExpandedPanelHeaderStyle style={{ backgroundColor: "#303b71", justifyContent: "flex-end" }}>
          {loadedData && <DataViewCsvDownload data={loadedData} />}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle>
          <ExpandedCardLoader childProps={props}>
            {(propsFull: IGaugeChartCardExpandedProps) => (
              <GaugeChartExpanded
                {...propsFull}
                onLoadedData={handleLoadedData}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}
          </ExpandedCardLoader>
        </ExpandedPanelInnerStyle>
      </ExpandedPanelContainer>
    </>
  );
};
