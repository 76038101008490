import React from "react";
import { Link } from "react-router-dom";
import SchoolRegistrationForm from "../components/Forms/SchoolRegistrationForm";
import { ConstrainedPageStyle, PageTitle, PLong, Section, Ul } from "../styles/page";

export const SchoolRegistrationClosePage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle style={{ marginLeft: "1rem" }}>Registration closed</PageTitle>
      <Section>
        <PLong>
          Registration to receive a SAMHE monitor closed on 31 May 2024 but schools can still benefit from the SAMHE
          teaching resources. See our <Link to="/get-involved">get involved</Link> page for more information.
        </PLong>
        <PLong>
          If your school already has a SAMHE monitor, please note that the Web App will remain fully operational until
          at least January 2030.
        </PLong>
      </Section>
    </ConstrainedPageStyle>
  );
};

const Info = () => (
  <Section>
    <PLong>
      Decided you want to take part in SAMHE? Great! Please complete the form below to request a SAMHE air quality
      monitor for your school. The monitor measures carbon dioxide, particulate matter, total volatile organic compounds
      (TVOCs), temperature and relative humidity. It is web-enabled and will send this data to the SAMHE Web App which
      you and your pupils can use to investigate how air quality changes over time and space. The App also contains
      supporting materials, including curriculum linked resources. To communicate with the Web App your monitor will
      need to be connected to your school's WiFi so before submitting this request you may find it helpful to discuss
      whether IoT (Internet of Things) devices can be connected at your school and, if so, how.
    </PLong>
    <PLong>
      <b>From 1 June 2024, we are only accepting new registrations from schools which are either: </b>
    </PLong>
    <Ul>
      <li>
        Located in the Yorkshire and Humber, East Midlands or North-East regions, and registering as part of the SAMHE
        extension project we're delivering in partnership with STEM Learning.
      </li>
      <li>
        Located in Falkirk, Stirling or Clackmannanshire and are being supported to engage with the project by the
        Forth-ERA team.
      </li>
    </Ul>
    <PLong>
      If your request is accepted, you'll receive your monitor and access to the Web App within a few weeks.
    </PLong>
    <PLong>
      Please note - by accepting the gift of a SAMHE monitor your school is accepting responsibility for the monitor,
      its safe usage within your school, and any liability that arises as a result. For advice on the safe usage of the
      air quality monitor, including a risk assessment please see the documentation on our{" "}
      <Link to="/safety-and-data-protection">Safety and Data Protection</Link> page.
    </PLong>
    <PLong>If you need more information before signing up, please contact:</PLong>
    <Ul>
      <li>
        For STEM learning project schools <a href="mailto:stem-ambassadors@stem.org.uk">stem-ambassadors@stem.org.uk</a>
      </li>
      <li>
        For Forth-ERA schools <a href="mailto:forth-era@stir.ac.uk">forth-era@stir.ac.uk</a>
      </li>
    </Ul>
  </Section>
);

const SchoolRegistrationPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle style={{ marginLeft: "1rem" }}>Request Your Air Quality Monitor!</PageTitle>
      <Info />
      <SchoolRegistrationForm />
    </ConstrainedPageStyle>
  );
};

export default SchoolRegistrationPage;
