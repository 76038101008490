import { IDataViewFull } from "../../../../lib/DataViews/IDataView";
import { EDataViewTypes } from "../../../../lib/DataViews/IDataView";
import { exampleTags } from "../../../../dummyData/tags";
import { IDataDownloadAdditionalProps } from "./lib";

export const exampleDataDownloadCard: IDataViewFull<IDataDownloadAdditionalProps> = {
  id: "exampleDataDownloadCard",
  label: "Download your data",
  description: "Select some data to download",
  longDescription: `
  This view allows you to select some data to download.
  `,
  type: EDataViewTypes.DATA_DOWNLOAD,
  tags: [exampleTags.temperature],
  allowDataDownload: true,
  additionalProps: {},
};
