import React, { useState } from "react";
import styled from "styled-components";
import {
  P,
  ConstrainedPageStyle,
  PageTitle,
  SectionHeading,
  Section,
  TableOfContentsWrapper,
  TableOfContents,
  Ul,
  CenteredTextContainer,
  FlexFlipWrap,
  SnapRightImg,
  FloatImageMax,
  SectionBackground,
  SubSectionHeading,
} from "../styles/page";
import { HeroBackground, BackgroundHeroStyle, BoxoutHeroMain } from "../styles/hero";
import { Logo, Logos } from "../styles/logos";
import { AExternal } from "../components/Links";
import { Link } from "react-router-dom";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";

export const BackgroundIllustration = styled.div`
  text-align: center;
  margin-top: 1rem;
  img {
    max-width: 100%;
  }
`;

export const BackgroundXLargeText = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.fontSize7};
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;
export const BackgroundXMidText = styled.p`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
`;

export const BackgroundLargeText = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.5rem 0;
`;

export const WorkingsTable = styled.div`
  margin-top: 1rem;
  display: flex;
  div {
    padding: 1rem;
    text-align: center;
    vertical-align: top;
    font-size: medium;
    border: 1px solid ${({ theme }) => theme.background};
    flex: 1;
    p {
      font-size: small;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    div:not(:first-child) {
      border-left: 0;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    display: block;
    div:not(:first-child) {
      border-top: 0;
    }
  }
`;

const AboutPage: React.FC = () => {
  const [showWorkings, setShowWorkings] = useState(false);

  return (
    <ConstrainedPageStyle className="page__wrap">
      <SectionBackground>
        <PageTitle>About SAMHE</PageTitle>
        <BackgroundHeroStyle>
          <HeroBackground src="/images/about/KS2_4k.jpg" />
        </BackgroundHeroStyle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#background">Background</Link>
              </li>
              <li>
                <Link to="#our-mission">Mission</Link>
              </li>
              <li>
                <Link to="#samhe-schools">SAMHE Schools</Link>
              </li>
              <li>
                <Link to="#the-national-data">National data</Link>
              </li>
              <li>
                <Link to="#co-designed-and-tested-by-schools">Co-design</Link>
              </li>
              <li>
                <Link to="#funding">Funding</Link>
              </li>
              <li>
                <Link to="#the-team">Team & partners</Link>
              </li>
              <li>
                <Link to="#samhe_extension_projects_and_partners">Related projects</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <Section>
            <FlexFlipWrap style={{ marginBottom: "1.5rem" }}>
              <CenteredTextContainer>
                <div>
                  <P>
                    The SAMHE project started in 2022 and was designed WITH and FOR schools. It involved researchers
                    from 6 institutions across the UK and was supported by DfE and EPSRC until the end of July 2024.
                    Over 1000 schools received a SAMHE air quality monitor and access to the SAMHE Web App where they
                    can view and investigate their school air quality data and try curriculum-linked activities and
                    experiments. The SAMHE initiative is continuing to support research around air quality in schools
                    beyond July 2024.
                  </P>
                  <P>
                    Poor air quality impacts pupils' health and concentration, affecting attendance and attainment.
                    Schools which have a SAMHE monitor can interact with real world data about the indoor environment
                    and see how they can take action to improve air quality in their classrooms. Schools which didn't
                    receive a monitor as part of the initial project can still benefit from SAMHE via our{" "}
                    <Link to="/get-involved">Teacher Resource Pack</Link>. This contains versions of the SAMHE
                    educational activities which don't require a monitor or access to the Web App.
                  </P>
                  <P>
                    A range of analyses of data from the SAMHE monitors has been published. Summaries of these (and
                    links to the full papers and reports) are available on our{" "}
                    <Link to="/resources/outputs">Outputs page</Link>.
                  </P>
                </div>
              </CenteredTextContainer>
              <CenteredTextContainer>
                <SnapRightImg
                  style={{ borderRadius: "0.1rem" }}
                  src="/images/logo/samhe_logo_strap.png"
                  alt="Samhe logo"
                />
              </CenteredTextContainer>
            </FlexFlipWrap>
            <SectionHeading id="background">Background</SectionHeading>
            <P>
              The quality of our air is important. After all, around 10,000 litres of air passes through each person's
              body every day. However, air often contains pollutants. Young people spend lots of time at school so SAMHE
              has supported schools to see what air quality is like indoors and enable both students and teachers to
              make informed decisions about ventilation. Read more{" "}
              <Link to="/resources/air-pollution">about air pollution</Link>.
            </P>
            <BoxoutHeroMain>
              <BackgroundIllustration>
                <BackgroundXMidText>
                  We estimate that each child breathes around <BackgroundXLargeText>7.2 million</BackgroundXLargeText>
                  litres of air at school<br></br>That's about...
                </BackgroundXMidText>
                <br></br>
                <img src="/images/about/bathtub_with_text.png" alt="90000 bathfuls of air!" />
                <br></br>
                <br></br>
                <P>
                  How did we get there?{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      setShowWorkings(true);
                    }}
                  >
                    See our workings.
                  </Link>
                </P>
              </BackgroundIllustration>

              {showWorkings && (
                <div>
                  <WorkingsTable>
                    <div>
                      <P>About</P>
                      <BackgroundLargeText>10,000 litres</BackgroundLargeText>
                      <P>
                        <b>of air passes through your body every day.</b>
                      </P>
                    </div>

                    <div>
                      <P>
                        The Department for Education requires that <b>schools meet for</b>
                      </P>
                      <BackgroundLargeText>190 days</BackgroundLargeText>
                      <P>
                        <b>each academic year.</b>
                      </P>
                    </div>

                    <div>
                      <P>
                        The Department for Education expects school weeks to be 32.5 hours giving an{" "}
                        <b>average school day of</b>
                      </P>
                      <BackgroundLargeText>6.5 hours.</BackgroundLargeText>
                      <P>(32.5/5)</P>
                    </div>

                    <div>
                      <P>With pupils at school for 6.5 hours a day we can say each year they breathe in</P>
                      <BackgroundLargeText>514,583 litres</BackgroundLargeText>
                      <P>
                        <b>of 'school air' (air breathed while at school) per year.</b>
                      </P>
                      <P>(6.5/24)x10,000x190</P>
                    </div>

                    <div>
                      <P>Assuming a school career of 14 years (age 4-18) </P>
                      <P>we arrive at our figure of </P>
                      <BackgroundLargeText>7,200,000 litres</BackgroundLargeText>
                      <P>
                        <b> of 'school air' breathed during an average child's school career.</b>
                      </P>
                      <P>(514,583x14)</P>
                    </div>

                    <div>
                      <P>Assuming a bath holds 80 litres of air, we get our figure of</P>
                      <BackgroundLargeText>90,000 bathfuls</BackgroundLargeText>
                      <P>
                        <b>of 'school air' breathed during an average child's school career.</b>
                      </P>
                      <P>(7,200,000/80)</P>
                    </div>
                  </WorkingsTable>
                  <br></br>
                  <P>
                    <i>
                      Note: these figures are based on average breathing rates and school hours and include time spent
                      outside during the school day, e.g at break times (see{" "}
                      <AExternal href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1063520/Minimum_expectation_for_length_of_school_week_-__information_note.pdf">
                        Minimum expectation on length of the school week: information note )
                      </AExternal>{" "}
                      To generate equivalent 'bathfuls' of air we referred to{" "}
                      <AExternal href="https://www.energysavingtrust.org.uk/sites/default/files/reports/AtHomewithWater%287%29.pdf">
                        'At Home with Water' (2013),
                      </AExternal>{" "}
                      a report prepared by the Energy Saving Trust.
                    </i>
                  </P>
                  <P>
                    <Link
                      to="#"
                      onClick={() => {
                        setShowWorkings(false);
                      }}
                    >
                      Close this workings box
                    </Link>
                  </P>
                </div>
              )}
            </BoxoutHeroMain>
          </Section>
          <Section>
            <FlexFlipWrap>
              <CenteredTextContainer>
                <P>
                  <SectionHeading id="our-mission">Our Mission</SectionHeading>
                  SAMHE stands for <b>Schools' Air quality Monitoring for Health and Education</b> and brings together
                  scientists, pupils and teachers. SAMHE has established a network of air quality monitors in schools
                  across the UK, generating an unparalleled dataset which is helping researchers better understand
                  schools' indoor air quality.
                </P>
              </CenteredTextContainer>
              <CenteredTextContainer>
                <SnapRightImg
                  style={{ borderRadius: "4rem 0" }}
                  src="/images/SAMHE_landscape_monitor.jpg"
                  alt="Samhe Monitor on table"
                />
              </CenteredTextContainer>
            </FlexFlipWrap>
          </Section>
          <Section>
            <SectionHeading id="samhe-schools">SAMHE Schools</SectionHeading>
            <P>
              Between 2022 and 2024, over 1,300 schools received a free high spec air quality monitor that measures
              carbon dioxide (CO<sub>2</sub>), volatile organic compounds (VOCs) particulate matter (PM), temperature
              and relative humidity. Teachers and pupils can access their data through a specially designed interactive
              Web App, seeing how air quality changes over the course of hours, days or weeks and months.
              <P></P>
              The Web App also offers a range of related activities and experiments, creating opportunities for pupils
              to be scientists and do hands-on experiments with their monitor. Read more about{" "}
              <Link to="/how-samhe-works">what being a SAMHE school involves.</Link> Schools can continue to use the Web
              App until at least the end of 2030.
            </P>
            <FloatImageMax
              src="/images/webapp_landing_page.jpg"
              alt="SAMHE Web App screenshot"
              style={{ borderRadius: "0.3rem", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)" }}
            />
          </Section>
          <Section>
            <SectionHeading id="the-national-data">The National Data</SectionHeading>
            <P>
              As well as being available to teachers and pupils, the data from each monitor is recorded in a national
              database. Over 1,300 schools have received monitors. These cover all four UK nations; the full range of
              pupil age groups; a wide variety of school building types; urban, suburban and rural areas; and areas
              across the spectrum from most to least deprived. As a result, we are confident that the SAMHE data is
              fairly representative of schools across the UK. This data has been analysed by our project scientists to
              understand schools' air quality across the UK.
            </P>
            <P>
              Our overall aim is to understand and improve long-term air quality for all schools. If you are a
              researcher interested in using the data, please contact Dr Henry Burridge at Imperial College London. If
              you are a teacher interested in exploring school air quality data with your pupils, download our free
              <Link to="/get-involved">Teacher Resource Pack</Link>, which includes datasets that you can analyse
              yourself.
            </P>
            <P>
              To hear more about SAMHE findings, future projects as part of the SAMHE initiative, and related research,
              use the contact form to <Link to="/faq#contact">sign up for our newsletter</Link>.
            </P>
          </Section>
          <Section>
            <SectionHeading id="co-designed-and-tested-by-schools">Co-designed and Tested by Schools</SectionHeading>
            <P>
              Schools helped us design, refine and test this project to make sure that it meets schools' needs and is
              fun and engaging for pupils. Read more about how we <Link to="/co-design">co-designed SAMHE</Link> and
              view the <Link to="/timeline">SAMHE project timeline</Link>.
            </P>
          </Section>
          <Section>
            <FlexFlipWrap>
              <CenteredTextContainer>
                <div>
                  <SectionHeading id="funding">Funding</SectionHeading>
                  <P>
                    Funding for the SAMHE project was provided by the{" "}
                    <AExternal href="https://www.gov.uk/government/organisations/department-for-education">
                      Department for Education
                    </AExternal>{" "}
                    and{" "}
                    <AExternal href="https://www.ukri.org/councils/epsrc/">
                      Engineering and Physical Sciences Research Council (EPSRC)
                    </AExternal>
                    . SAMHE grew out of the <AExternal href="https://co-trace.uk/">CO-TRACE project</AExternal>{" "}
                    (2021-22) which assessed the risk of airborne COVID-19 transmission in schools and evaluated the
                    effectiveness of mitigation measures.
                  </P>
                  <P>
                    Funding to continue the SAMHE initiative has been provided by Hertfordshire County Council and
                    through the Royal Society's Partnership Grants scheme.
                  </P>
                </div>
              </CenteredTextContainer>
              <CenteredTextContainer>
                <Logos>
                  <Logo>
                    <AExternal
                      href="https://www.gov.uk/government/organisations/department-for-education"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/images/partners/Department_for_Education.svg.png" alt="Department for Education" />
                    </AExternal>
                  </Logo>
                  <Logo>
                    <AExternal href="https://www.ukri.org/councils/epsrc/">
                      <img src="/images/partners/EPSRC.png" alt="Engineering and Physical Sciences Research Council" />
                    </AExternal>
                  </Logo>

                  <Logo>
                    <AExternal href="https://www.hertfordshire.gov.uk/microsites/clean-air/what-were-doing-across-hertfordshire.aspx#S5_faqcontent">
                      <img src="/images/partners/hcc_logo.png" alt="Hertfordshire County Council" />
                    </AExternal>
                  </Logo>
                  <Logo>
                    <AExternal>
                      <img
                        src="/images/partners/royal_society_partnership_grants_scheme.jpg"
                        alt="Royal Society partnership grants scheme"
                      />
                    </AExternal>
                  </Logo>
                </Logos>
              </CenteredTextContainer>
            </FlexFlipWrap>
          </Section>
          <Section>
            <SectionHeading id="the-team">The Team</SectionHeading>
            <P>
              The SAMHE project was a collaboration between five UK universities (
              <AExternal href="https://www.cam.ac.uk/">University of Cambridge</AExternal>,{" "}
              <AExternal href="https://www.imperial.ac.uk/">Imperial College London</AExternal>,{" "}
              <AExternal href="https://www.york.ac.uk/">University of York</AExternal> (through the{" "}
              <AExternal href="http://sei.org">Stockholm Environment Institute</AExternal>'s York centre),{" "}
              <AExternal href="https://www.surrey.ac.uk/">University of Surrey</AExternal>,{" "}
              <AExternal href="https://www.leeds.ac.uk/">University of Leeds</AExternal>) and the{" "}
              <AExternal href="https://www.gov.uk/government/organisations/uk-health-security-agency">
                UK Health Security Agency (UKHSA)
              </AExternal>
              .
            </P>
            <br></br>
            <Logos>
              <Logo>
                <AExternal href="https://www.cam.ac.uk/">
                  <img src="/images/partners/university_of_cambridge.png" alt="University of Cambridge" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.imperial.ac.uk/">
                  <img src="/images/partners/imperial_college_london.png" alt="Imperial College London" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.york.ac.uk/">
                  <img src="/images/partners/UoY.png" alt="University of York" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.sei.org/">
                  <img src="/images/partners/sei.png" alt="Stockholm Environment Institute" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.surrey.ac.uk/global-centre-clean-air-research">
                  <img src="/images/partners/GCARE.png" alt="Global Centre for Clean Air Research" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.leeds.ac.uk/">
                  <img src="/images/partners/UoL.png" alt="University of Leeds" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.gov.uk/government/organisations/uk-health-security-agency">
                  <img
                    style={{ width: "100%" }}
                    src="/images/partners/UK_Health_Security_Agency_Logo.svg.png"
                    alt="UK Health Security Agency"
                  />
                </AExternal>
              </Logo>
            </Logos>
            <br></br>
            <br></br>
            <P>
              See more about who has been involved in SAMHE on our <Link to="/about/team">Team Page</Link>
            </P>
          </Section>
          <Section>
            <SectionHeading>Suppliers/Contractors</SectionHeading>
            <P>
              <AExternal href="https://www.airgradient.com/">AirGradient</AExternal> supplied the SAMHE air quality
              monitors.
            </P>
            <P>HDSDev supplied web development consultancy for the creation of the front-end application.</P>
            <br />
            <Logos>
              <Logo>
                <AExternal href="https://www.airgradient.com/">
                  <img src="/images/partners/airgradient.png" alt="Air Gradient" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal>
                  <img src="/images/partners/hdsdev.jpg" alt="hdsdev" />
                </AExternal>
              </Logo>
            </Logos>
          </Section>
          <Section>
            <SectionHeading id="samhe_extension_projects_and_partners">
              SAMHE extension projects and partners
            </SectionHeading>
            <P>
              The following organisations are collaborating with the SAMHE team on extensions to the SAMHE project
              working with specific groups of schools.
            </P>
            <P>
              <AExternal href="https://www.hertfordshire.gov.uk/microsites/clean-air/what-were-doing-across-hertfordshire.aspx#S5_faqcontent">
                Hertfordshire County Council
              </AExternal>{" "}
              is collaborating with SAMHE on a project to evaluate the effectiveness of installing air purifiers in
              classrooms.
            </P>
            <br />
            <Logos>
              <Logo>
                <AExternal href="https://www.hertfordshire.gov.uk/microsites/clean-air/what-were-doing-across-hertfordshire.aspx#S5_faqcontent">
                  <img src="/images/partners/hcc_logo.png" alt="Hertfordshire County Council" />
                </AExternal>
              </Logo>
              <Logo>
                <AExternal href="https://www.hertfordshire.gov.uk/microsites/clean-air/clean-air.aspx">
                  <img src="/images/partners/cleanair_logo.png" alt="Let's Clear the Air" />
                </AExternal>
              </Logo>
            </Logos>
          </Section>
          <Section>
            <SectionHeading id="projects_building_on_samhe">Projects building on SAMHE</SectionHeading>
            <SubSectionHeading>CHEPA</SubSectionHeading>
            <P>
              Members of the SAMHE team from Imperial College London and the Stockholm Environment Institute at the
              University of York have been awarded funding for the CHEPA (Classroom HEPA) project. This work is funded
              by a{" "}
              <AExternal href="https://www.ukri.org/what-we-do/supporting-collaboration/supporting-collaboration-esrc/impact-acceleration-accounts/#:~:text=Impact%20Acceleration%20Accounts%20(IAAs)%20provide,knowledge%20to%20challenges%20in%20society.">
                UKRI Impact Acceleration Account
              </AExternal>{" "}
              awarded to <AExternal href="https://www.imperial.ac.uk/">Imperial College London</AExternal> and will
              involve co-designing and creating a separate Web App tool which will enable schools and local authorities,
              amongst other stakeholders, to estimate the impact of installing high-performance air filter (HEPA) units
              on classroom air quality as well as evaluating potential impacts on energy consumption.
            </P>
          </Section>
          <ScrollTopButton />
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default AboutPage;
