import { useFormContext } from "react-hook-form";
import styled from "styled-components";

export interface IErrorMessageProps {
  name: string;
}

export const ErrorMessageStyle = styled.div`
  color: ${({ theme }) => theme.colors.warning};
  min-width: 10rem;
  flex-grow: 1;
  font-weight: 800;
`;

export const ErrorMessage = ({ name }: IErrorMessageProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  return (
    <ErrorMessageStyle className="form__errorMessage">
      {errorMessage ? `Input is invalid: ${errorMessage}` : ""}
    </ErrorMessageStyle>
  );
};
