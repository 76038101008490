import React from "react";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";
import { CookiePolicy } from "../components/Documents/CookiePolicy";

const CookiesPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE website cookie policy</PageTitle>
      <CookiePolicy />
    </ConstrainedPageStyle>
  );
};

export default CookiesPage;
