import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../Redux/hooks";
import UserDropDownMenu from "../../GenericComponents/UserDropDownBtn/UserDropDownBtn";
import {
  HeaderLink,
  WebAppLogo,
  LogoWrap,
  LinksList,
  AppHeaderStyle,
  AppHeaderMainSectionStyle,
  AppLinksListMenu,
  ReturnToSAMHELink,
  HeaderLabel,
  HeaderLabelValue,
  HeaderItem,
} from "../../styles/header";
import { ESessionStatus } from "../../Redux/User/userReducer";
import { HamburgerButton } from "./hamburgerBtn";
import { BETA_VERSION } from "../../config";
import { UserGroup } from "../../lib/User/UserGroup";
import { HeaderDeployment } from "./deploymentHeader";
import {
  LocationSelectionDropdown,
  MonitorSelectionDropdown,
} from "../MonitorLocationSelection/monitorLocationSelectionDropdowns";
import { UserGroupSelectionDropdown } from "./userGroupDropdown";
import { EMonitorChangeSource } from "../../lib/Monitor/IMonitor";

export interface IHeaderAppProps {
  setMenuOpen: (v: boolean) => void;
}

export const HeaderApp: React.FC<IHeaderAppProps> = ({ setMenuOpen }) => {
  const {
    user: { username },
  } = useAppSelector((state) => state.userState);

  return (
    <>
      <HeaderItem>
        <HeaderLink to="/app/data" className="header-button" onClick={() => setMenuOpen(false)}>
          Data
        </HeaderLink>
      </HeaderItem>
      <HeaderItem>
        <HeaderLink to="/app/activities" className="header-button" onClick={() => setMenuOpen(false)}>
          Activities
        </HeaderLink>
      </HeaderItem>
      <HeaderItem>
        <HeaderLink to="/app/achievements" className="header-button" onClick={() => setMenuOpen(false)}>
          Achievements
        </HeaderLink>
      </HeaderItem>
      <HeaderItem>
        <HeaderLink to="/app/library" className="header-button" onClick={() => setMenuOpen(false)}>
          Library
        </HeaderLink>
      </HeaderItem>
      <HeaderItem>
        <HeaderLabel>User ID</HeaderLabel>
        <HeaderLabelValue>
          <b>{username}</b>
        </HeaderLabelValue>
      </HeaderItem>
      <HeaderItem>
        <MonitorSelectionDropdown />
      </HeaderItem>
      <HeaderItem>
        <LocationSelectionDropdown updatedFrom={EMonitorChangeSource.HEADER} callApi />
      </HeaderItem>
      <HeaderItem>
        <UserGroupSelectionDropdown />
      </HeaderItem>
    </>
  );
};

const AppHeader: React.FC = () => {
  const {
    sessionState,
    user: { group },
  } = useAppSelector((state) => state.userState);

  /* menuOpen only affects mobile */
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <AppHeaderMainSectionStyle>
      <AppHeaderStyle className="header_wrap">
        <LogoWrap>
          <WebAppLogo>
            <Link to="/app" onClick={() => setMenuOpen(false)}>
              {BETA_VERSION ? (
                <img src="/images/logo/samhe_webapp_header_small.png" alt="SAMHE Web App" height="100%" />
              ) : (
                <img src="/images/logo/samhe_webapp_header_small.png" alt="SAMHE Web App" height="100%" />
              )}
            </Link>
          </WebAppLogo>
        </LogoWrap>
        <div style={{ flex: 1 }} /> {/* Spacer */}
        {sessionState !== ESessionStatus.LOGGED_IN && (
          <>
            <LinksList style={{alignItems: "end"}}>
              <ReturnToSAMHELink>
                <HeaderLink to="/">
                  <FontAwesomeIcon size="1x" icon={faCircleLeft} />{" "}
                  <span style={{ marginLeft: "4px" }}>Return to SAMHE.org.uk</span>
                </HeaderLink>
              </ReturnToSAMHELink>
            </LinksList>
          </>
        )}
        {sessionState === ESessionStatus.LOGGED_IN && <HamburgerButton onClick={() => setMenuOpen((prev) => !prev)} />}
        <AppLinksListMenu isOpen={menuOpen}>
          {[UserGroup.SCHOOLADMIN, UserGroup.STUDENT, UserGroup.SUPERADMIN, UserGroup.TEACHER].indexOf(group) !==
            -1 && (
            <LinksList>
              {sessionState === ESessionStatus.LOGGED_IN && <HeaderApp setMenuOpen={setMenuOpen} />}
            </LinksList>
          )}
          {[UserGroup.DEPLOYMENTADMIN].indexOf(group) !== -1 && (
            <LinksList>
              {sessionState === ESessionStatus.LOGGED_IN && <HeaderDeployment setMenuOpen={setMenuOpen} />}
            </LinksList>
          )}
        </AppLinksListMenu>
        {sessionState === ESessionStatus.LOGGED_IN && <UserDropDownMenu />}
      </AppHeaderStyle>
    </AppHeaderMainSectionStyle>
  );
};

export default AppHeader;
