import React from "react";
import { PageTitle, ConstrainedPageStyle } from "../styles/page";
import { RegistrationPrivacyPolicy } from "../components/Documents/RegistrationPrivacyPolicy";

const RegistrationPrivacyPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="subpage_wrap">
      <PageTitle>SAMHE School Registration Privacy Policy</PageTitle>
      <RegistrationPrivacyPolicy />
    </ConstrainedPageStyle>
  );
};

export default RegistrationPrivacyPage;
