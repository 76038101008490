import styled from "styled-components";
import { EEquipmentNeeded } from "../../../lib/Activities/EEquipmentNeeded";

export interface IEquipmentNeededIconProps {
  equipmentNeeded: EEquipmentNeeded;
}

export const EquipmentNeededIconStyle = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const EquipmentNeededIcon: React.FC<IEquipmentNeededIconProps> = ({ equipmentNeeded }) => {
  if (equipmentNeeded === EEquipmentNeeded.NONE) return <></>;
  if (equipmentNeeded === EEquipmentNeeded.ANY)
    return (
      <EquipmentNeededIconStyle width="2rem" src="/images/app/icons/Equipment_Needed.png" alt="Equipment needed" />
    );
  return <>INVALID EQUIPMENT NEEDED TYPE</>;
};

export interface IEquipmentNeededIconsProps {
  equipmentNeeded: EEquipmentNeeded[];
}

export const EquipmentNeededIcons: React.FC<IEquipmentNeededIconsProps> = ({ equipmentNeeded }) => {
  return (
    <>
      {equipmentNeeded.map((e) => (
        <EquipmentNeededIcon key={e} equipmentNeeded={e} />
      ))}
    </>
  );
};
