import React from "react";
import { useInterval } from "react-use";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import {
  CardInfoSectionWrapper,
  CardStyle,
  CardTitle,
  CardTitleBar,
  PlotDescriptionSection,
} from "../../../../GenericComponents/CardBoard/styles";
import { DataMetricsMetaData } from "../../../../lib/DataViews/EDataMetrics";
import { Tags } from "../../../Tags/Tags";
import { ExpandedPanelContainer, ExpandedPanelHeaderStyle, ExpandedPanelInnerStyle } from "../../../../styles/popups";
import { ISimpleBarCardProps, ISimpleBarCollapsedCardProps, ISimpleBarExpandedCardProps } from "./lib";
import { useDataLoader } from "../../dataLoader";
import { DataViewCsvDownload } from "../../DataViewComponents";
import { ExpandedCardLoader } from "../../CardLoader";
import { TextFormatter } from "../../../TextFormatter/TextFormatter";
import { keywordMap } from "../../../../dummyData/keywords";
import { defaultDateRangeState, refreshTimes } from "../../../DateRangePicker/utils";
import { SimpleBarOptions } from "./SimpleBarOptions";
import { Br, FlexBox } from "../../../../styles/singlePageStyles";
import { CardExpandedHr, DataViewButton, ErrorMessage } from "../../../../styles/app";
import { EDayRange, ETimeRange, IDateRangeState } from "../../../DateRangePicker/lib";
import { CollapsableSectionInner } from "../../../../styles/collapsable";
import { GraphCaptionBox } from "../../DataViewComponents/GraphCaptionBox";
import { ButtonStyle } from "../../../../styles/button";
import { GraphGuidanceBox } from "../../DataViewComponents/GraphGuidanceBox";
import { PatternInfoBox } from "../../DataViewComponents/PatternInfoBox";
import { SimpleBar } from "../../../DataViz/SimpleBar/SimpleBar";
import { EAggregationInterval } from "../../../../lib/MonitorData/AggregationIntervals";

const defaultBarPlotDateRange = (): IDateRangeState =>
  refreshTimes({
    ...defaultDateRangeState(EDayRange.TODAY),
    aggregation: EAggregationInterval.HOUR,
    timeRange: ETimeRange.SCHOOL_HOURS,
    minAggregation: EAggregationInterval.TEN_MINUTE,
    maxAggregation: EAggregationInterval.HOUR,
  });

export const SimpleBarCardInner = ({
  id,
  monitorId,
  label,
  additionalProps: { metric, liveUpdate = true, bucketRanges },
}: ISimpleBarCollapsedCardProps) => {
  const { label: metricLabel, unit } = DataMetricsMetaData[metric] || {};
  if (!metricLabel) throw Error(`Invalid Metric: ${metric}`);
  const [dateRange, setDateRange] = React.useState(defaultBarPlotDateRange());
  const xLabel = `${metricLabel} concentration range (${unit})`;
  const yLabel = "School hours in this range";

  const { loadedData, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric: metric,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
  });

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <div className="inner">
      <CardTitleBar>
        <CardTitle>
          <DataViewButton to={`/app/data/${id}`}>{String(label)}</DataViewButton>
        </CardTitle>
      </CardTitleBar>
      <p>Bar plot of: {metricLabel}</p>
      {readyToView && !dataLoadError ? (
        <SimpleBar
          data={loadedData?.data}
          xLabel={xLabel}
          yLabel={yLabel}
          bucketRanges={bucketRanges}
          aggregationInterval={dateRange.aggregation}
          compact
        />
      ) : (
        ""
      )}
      {dataLoadError ? <ErrorMessage>Error loading data</ErrorMessage> : ""}
    </div>
  );
};

export const SimpleBarExpanded = ({
  label,
  monitorId,
  description,
  longDescription,
  tags,
  additionalProps: { metric, bucketRanges, graphCaption, patternText, graphGuidance },
  onLoadedData,
  dateRange,
  setDateRange,
}: ISimpleBarExpandedCardProps) => {
  if (!metric) throw Error("Missing metric!");
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const [showLongDesc, setShowLongDesc] = React.useState(true);
  const { loadedData, loading, dataLoadError, readyToView } = useDataLoader({
    monitorId,
    currentMetric: metric,
    dateRange,
    blocked: dateRange.invalid && true,
    aggregationInterval: dateRange.aggregation,
    onLoadedData,
  });

  const { label: metricLabel, unit } = DataMetricsMetaData[metric] || {};
  const xLabel = `${metricLabel} concentration range (${unit})`;
  const yLabel = "School hours in this range";

  useInterval(
    () => (liveUpdate && !dateRange.invalid ? setDateRange(refreshTimes(dateRange)) : null),
    MONITOR_REFRESH_RATE
  );

  return (
    <div className="simplePlotExpanded">
      <CardTitle>{String(label)}</CardTitle>
      <CardExpandedHr />
      <PlotDescriptionSection>
        <TextFormatter text={String(description)} keywordMap={keywordMap} />
        {longDescription ? (
          <>
            <CardInfoSectionWrapper
              open={showLongDesc}
              maxHeight="70rem"
              style={{ width: "100%" }}
              transitionTime={0.2}
            >
              <CollapsableSectionInner open={showLongDesc}>
                <FlexBox horiz flexwrap style={{ gap: "2rem" }}>
                  <div data-testid="long-description" style={{ flex: "1 1 30rem" }}>
                    <TextFormatter text={longDescription} keywordMap={keywordMap} />
                  </div>
                  {graphCaption ? <GraphCaptionBox text={graphCaption} /> : ""}
                </FlexBox>
              </CollapsableSectionInner>
            </CardInfoSectionWrapper>
            <ButtonStyle style={{ width: "100%", marginTop: "1rem" }} onClick={() => setShowLongDesc((prev) => !prev)}>
              {showLongDesc ? "Hide Description" : "Show Description"}
            </ButtonStyle>
          </>
        ) : (
          ""
        )}
      </PlotDescriptionSection>
      <Br />
      {tags ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tags tags={tags} />
        </div>
      ) : (
        ""
      )}
      {loading ? "Loading Data..." : ""}
      <Br />
      {dataLoadError ? (
        <FlexBox center horiz>
          <ErrorMessage>{dataLoadError && dataLoadError.message}</ErrorMessage>
        </FlexBox>
      ) : (
        ""
      )}

      {readyToView ? (
        <div style={{ width: "100%" }}>
          <SimpleBar
            data={loadedData?.data}
            xLabel={xLabel}
            yLabel={yLabel}
            bucketRanges={bucketRanges}
            aggregationInterval={dateRange.aggregation}
          />
        </div>
      ) : (
        ""
      )}
      <Br />
      <Br />
      <SimpleBarOptions
        reloadData={() => setDateRange(refreshTimes(dateRange))}
        loading={loading}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setLiveUpdate={setLiveUpdate}
        liveUpdate={liveUpdate}
      />
      <Br />
      {graphGuidance ? <GraphGuidanceBox text={graphGuidance} /> : ""}
      {patternText ? <PatternInfoBox type={patternText} /> : ""}
    </div>
  );
};

export const SimpleBarCard = (props: ISimpleBarCardProps) => (
  <CardStyle>
    <SimpleBarCardInner {...props} />
  </CardStyle>
);

export const SimpleBarCardExpanded = (props: ISimpleBarCardProps) => {
  const { uniqueCardId } = props;

  const [loadedData, setLoadedData] = React.useState<any>(null);
  // Callback function to receive data from the child component
  const handleLoadedData = (data: any) => {
    setLoadedData(data);
  };
  const [dateRange, setDateRange] = React.useState<IDateRangeState>(defaultBarPlotDateRange());

  return (
    <>
      <ExpandedPanelContainer data-testid={`${uniqueCardId}_expanded-panel`}>
        <ExpandedPanelHeaderStyle style={{ backgroundColor: "#303b71", justifyContent: "flex-end" }}>
          {loadedData && <DataViewCsvDownload data={loadedData} />}
        </ExpandedPanelHeaderStyle>
        <ExpandedPanelInnerStyle>
          <ExpandedCardLoader childProps={props}>
            {(propsFull: ISimpleBarExpandedCardProps) => (
              <SimpleBarExpanded
                {...propsFull}
                onLoadedData={handleLoadedData}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}
          </ExpandedCardLoader>
        </ExpandedPanelInnerStyle>
      </ExpandedPanelContainer>
    </>
  );
};
