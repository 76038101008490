import React from "react";
import { keywordMap } from "../../dummyData/keywords";
import { ITag } from "../../lib/Tags/ITag";
import { TextFormatter } from "../TextFormatter";
import { TagTooltipStyle } from "./style";

export interface ITagTooltipProps {
  data: ITag;
  style: React.CSSProperties;
  attributes: any;
}

export const TagTooltip = React.forwardRef<HTMLDivElement, ITagTooltipProps>(
  ({ data: { label, description }, style, attributes }: ITagTooltipProps, ref) => {
    const descriptionAsShown =
      typeof description === "string" ? <TextFormatter text={description} keywordMap={keywordMap} /> : description;
    return (
      <TagTooltipStyle data-testid="tagTooltip" ref={ref} style={style} {...attributes}>
        <h2>{label}</h2>
        {descriptionAsShown}
        <p>Click the tag for more info...</p>
      </TagTooltipStyle>
    );
  }
);
