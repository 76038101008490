export const VOCGuidanceDataDetectives = `
The levels of !total volatile organic compounds! (TVOCs) detected by your SAMHE monitor might be affected by the presence of many different gases being mixed within the air.
TVOCs are not necessarily harmful – take a look at our !guidance on interpreting monitor readings! with your teacher for more detail.
Remember that the things in your classroom, and how you use your classroom, will have a big effect on TVOC levels – every classroom is different!

Common things which can affect the level of TVOCs detected by your monitor include:

 - Cleaning products - most products that are used to keep your school clean will contain chemicals that give off VOCs.
 - Art and design technology resources - some of the paints, glues, varnishes, etc. that you use in school will give off some VOCs.
 - Personal care products - products such as spray deodorants and other cosmetics, will produce VOCs.
 - Furniture, wall and floors – can all give off VOCs particularly when they are new or freshly renovated.
 - Human odours – the smells of body odour, farts and unwashed clothing are largely a result of VOCs.
 - Food – smells that come from food or cooking will give off VOCs
 - VOCs coming in from outdoors – whatever is happening outside of your school could be affecting classroom VOC levels too.  For example, car exhausts and the burning of fossil fuels emit VOCs, and the smell of manure spread on fields is largely due to VOCs. Many plants naturally emit VOCs too.

This list is not designed to be exhaustive (so it won’t cover everything that could possibly affect classroom TVOC levels) but it does include the things we think are most likely. If none of the things in our list apply, remember that anything with a strong smell can be a sign that a VOC is being emitted.
`;

export const VOCSourcesList = `
Common things which can affect the level of TVOCs detected by your monitor include:

 - Cleaning products
 - Paints, glues and varnishes
 - Body sprays and deodorants
 - Human odours
 - Food
 - VOCs coming in from outdoors

`;
