import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import styled from "styled-components";
import { AgeGroups, EAgeGroup } from "../../lib/User/AgeGroup";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { MultiCheckboxB, selectionToBooleanList } from "../FormComponents/MultiCheckbox";
import { reduxSetUserAgeGroup } from "../../Redux/User/userReducer";
import { UserGroup } from "../../lib/User/UserGroup";

export interface IUserAgePromptProps {}

export const PanelStyle = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  background: white;
  justify-content: flex-start;
  z-index: 1000;
  padding: ${({ theme }) => theme.shape.defaultPadding};
  background: white;
  && {
    pointer-events: none;
  }
  & > * {
    pointer-events: initial;
  }
  &:before {
    content: "";
    position: absolute;
    pointer-events: all;
    top: 0rem;
    left: 0rem;
    z-index: 998;
    right: 0rem;
    bottom: 0rem;
    background: ${({ theme }) => theme.colors.appBackground};
    background: white;
    opacity: 0.9;
  }
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
    padding: ${({ theme }) => theme.shape.defaultPaddingWide};
  }
`;

export const UserAgePrompt: React.FC<IUserAgePromptProps> = () => {
  const { user } = useAppSelector((state) => state.userState);
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const submitSelection = (v: EAgeGroup) => {
    dispatch(reduxSetUserAgeGroup(token, v));
  };
  if (!token) return <>MISSING TOKEN</>;
  if (user.group !== UserGroup.STUDENT || (user.ageGroup !== undefined && user.ageGroup !== null)) return <></>; // User has already completed the form!
  return (
    <PanelStyle data-testid="userAgePrompt">
      <div style={{ zIndex: 999 }}>
        <p>
          <b>Hi there!</b>
        </p>
        <br />
        <p>Please select your age from the following options:</p>
        <br />
        <p>
          <i>
            Completing this question helps us to understand your experience of using the Web App and how our content is
            being used so that we can continue to make it better.
          </i>
        </p>
        <br />
        <MultiCheckboxB
          options={Object.values(AgeGroups)}
          value={selectionToBooleanList(user.ageGroup, Object.values(AgeGroups))}
          onChange={(_, v) => submitSelection(v as EAgeGroup)}
        />
      </div>
    </PanelStyle>
  );
};
