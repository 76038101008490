import React from "react";
import { createRoot } from "react-dom/client";
import { getMockServer } from "./dummyApi/worker";
import * as database from "./dummyApi/database";
import { USE_DUMMY_API } from "./config";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Providers from "./Providers";
import "./index.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (USE_DUMMY_API) {
  console.info("Using dummy Api!");
  database.initAll();
  getMockServer(USE_DUMMY_API !== "ALL").start();
}

root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
