import { EComparisons, ILabelled, Uid } from "@react_db_client/constants.client-types";
import {
  IDataRangeSnapshotExtraProps,
  IDataRangeSnapshotState,
} from "../../components/ActivitiesDashboard/StepTypes/DataRangeSnapshotStep/DataRangeSnapshotStep";
import {
  IDataSnapshotExtraProps,
  IDataSnapshotState,
} from "../../components/ActivitiesDashboard/StepTypes/DataSnapshotStep";
import {
  IFeedbackStepExtraProps,
  IFeedbackStepState,
} from "../../components/ActivitiesDashboard/StepTypes/FeedbackStep/FeedbackStep";
import { IInfoExtraProps } from "../../components/ActivitiesDashboard/StepTypes/InfoStep/InfoStep";
import { IMultipleChoiceExtraProps } from "../../components/ActivitiesDashboard/StepTypes/MultiChoiceStep/MultipleChoiceStep";
import {
  IRoomSelectionStepExtraProps,
  IRoomSelectionStepState,
} from "../../components/ActivitiesDashboard/StepTypes/RoomSelection/RoomSelectionStep";
import { ITextInputExtraProps } from "../../components/ActivitiesDashboard/StepTypes/TextInputStep/TextInputStep";
import { IVideoStepExtraProps } from "../../components/ActivitiesDashboard/StepTypes/VideoStep/VideoStep";
import { IMultipleChoiceState } from "../../components/ActivitiesDashboard/StepTypes/MultiChoiceStep/MultipleChoiceStep";
import { MonitorId } from "../Monitor/IMonitor";
import { ImageUrl } from "../Primatives/Paths";
import { TActivityId } from "./IActivity";
import {
  IQuizMultiChoiceExtraProps,
  IQuizMultiChoiceState,
} from "../../components/ActivitiesDashboard/StepTypes/QuizMultiChoice";
import { UserGroup } from "../User/UserGroup";
import { RoomId } from "../School/IRoom";

export type TStepRef = string;
export type TStepId = number;
export type TStepStatePropertyId = string | number;
export type TStepStatePropertyData = any;

export type TStepStateDefault = { [id: TStepStatePropertyId]: TStepStatePropertyData };

export enum EStepTypes {
  INFO = 1,
  TEXT_INPUT = 2,
  VIDEO = 3,
  MULTIPLE_CHOICE = 4,
  DATA_SNAPSHOT = 5,
  TIMED = 6,
  FEEDBACK = 7,
  DATA_RANGE_SNAPSHOT = 8,
  QUIZ_MULTI_CHOICE = 9,
  ROOM_SELECTION = 10,
  FEEDBACK_FREETEXT = 11,
}

export const StepTypeData: Record<EStepTypes, ILabelled> = {
  [EStepTypes.INFO]: {
    uid: EStepTypes.INFO,
    label: "INFO",
  },
  [EStepTypes.TEXT_INPUT]: {
    uid: EStepTypes.TEXT_INPUT,
    label: "TEXT_INPUT",
  },
  [EStepTypes.VIDEO]: {
    uid: EStepTypes.VIDEO,
    label: "VIDEO",
  },
  [EStepTypes.MULTIPLE_CHOICE]: {
    uid: EStepTypes.MULTIPLE_CHOICE,
    label: "MULTIPLE_CHOICE",
  },
  [EStepTypes.DATA_SNAPSHOT]: {
    uid: EStepTypes.DATA_SNAPSHOT,
    label: "DATA_SNAPSHOT",
  },
  [EStepTypes.TIMED]: {
    uid: EStepTypes.TIMED,
    label: "TIMED",
  },
  [EStepTypes.FEEDBACK]: {
    uid: EStepTypes.FEEDBACK,
    label: "FEEDBACK",
  },
  [EStepTypes.DATA_RANGE_SNAPSHOT]: {
    uid: EStepTypes.DATA_RANGE_SNAPSHOT,
    label: "DATA_RANGE_SNAPSHOT",
  },
  [EStepTypes.QUIZ_MULTI_CHOICE]: {
    uid: EStepTypes.QUIZ_MULTI_CHOICE,
    label: "QUIZ_MULTI_CHOICE",
  },
  [EStepTypes.ROOM_SELECTION]: {
    uid: EStepTypes.ROOM_SELECTION,
    label: "ROOM_SELECTION",
  },
  [EStepTypes.FEEDBACK_FREETEXT]: {
    uid: EStepTypes.FEEDBACK_FREETEXT,
    label: "FEEDBACK_FREETEXT",
  },
};

export type TStepAdditionalProps =
  | ITextInputExtraProps
  | IVideoStepExtraProps
  | IMultipleChoiceExtraProps
  | IInfoExtraProps
  | IDataSnapshotExtraProps
  | IDataRangeSnapshotExtraProps
  | IQuizMultiChoiceExtraProps
  | IFeedbackStepExtraProps
  | IRoomSelectionStepExtraProps;

export type TStepState =
  | IDataSnapshotState
  | IDataRangeSnapshotState
  | IFeedbackStepState
  | TStepStateDefault
  | IMultipleChoiceState
  | IQuizMultiChoiceState
  | IRoomSelectionStepState;

export interface INextStepDependency {
  id: Uid;
  targetField: Uid;
  targetValue: any;
  comparison: EComparisons;
}

export type TNextStepId = Uid;
export interface INextStep {
  id: TNextStepId;
  stepRef: TStepRef;
  dependencies: INextStepDependency[];
}
export interface INextStepSimple {
  stepRef: TStepRef;
}

export interface IStep<AdditionalProps extends TStepAdditionalProps> {
  id: TStepId;
  stepRef: TStepRef;
  label: string;
  description: string;
  logo?: ImageUrl;
  type: EStepTypes;
  additionalProps?: AdditionalProps;
  optional?: boolean;
  group?: number;
  preSteps?: TStepRef[];
  nextSteps?: (INextStepSimple | INextStep)[];
  isLastStep?: boolean;
  userRoleLimit?: UserGroup[];
}

export enum EStepStatus {
  UNKNOWN = 0,
  BLOCKED = 1,
  NOT_STARTED = 2,
  IN_PROGRESS = 3,
  FAILED = 4,
  COMPLETED = 5,
}
export interface IStepUserData<State extends TStepState> {
  stepId: TStepId;
  stepRef: TStepRef;
  status?: EStepStatus;
  previousSteps?: TStepRef[];
  dateCreated: Date;
  state?: State;
}

export interface IStepSubmission<State extends TStepState> {
  stepId: TStepId;
  stepRef: TStepRef;
  state?: State;
  status: EStepStatus;
  monitorReferenceId?: MonitorId;
  roomId?: RoomId;
}

export interface IStepProps<AdditionalProps extends TStepAdditionalProps, State extends TStepState>
  extends IStep<AdditionalProps>,
    IStepUserData<State> {
  activityId: TActivityId;
  monitorId: MonitorId;
  previousStepsState: IStepUserData<TStepState>[]; // TODO: Get submission data from other steps
  activitySteps: IStep<TStepAdditionalProps>[];
  onChange: (id: TStepStatePropertyId, newValue: TStepStatePropertyData) => void;
  onSubmit: (args: {
    newValue: State,
    roomIdOverride?: RoomId,
    monitorIdOverride?: MonitorId,
    nextStep?: TStepRef | null
  }) => void;
}
