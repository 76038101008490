import { ILabeled } from "../common";

export enum ESchoolStatus {
  /* Monitor Request only */
  REGISTERED = 1,
  VALIDATING = 2,
  /* New school entry created from here */
  VALIDATED = 3,
  /* We search schools from here */
  LINKED = 4,
  DEPLOYED = 5,
  RECEIVED = 6,
  ACTIVATED = 7,
  INVALID = 8,
  ERROR = 9,
  WITHDRAWN = 10,
}

export interface SchoolStatusLabel extends ILabeled<ESchoolStatus> {
  toLabel?: string;
}

export const SchoolStatus: Record<ESchoolStatus, SchoolStatusLabel> = {
  [ESchoolStatus.REGISTERED]: {
    uid: ESchoolStatus.REGISTERED,
    label: "Registered",
  },
  [ESchoolStatus.VALIDATING]: {
    uid: ESchoolStatus.VALIDATING,
    label: "Validating",
    toLabel: "Set as Validating",
  },
  [ESchoolStatus.VALIDATED]: {
    uid: ESchoolStatus.VALIDATED,
    label: "Validated",
    toLabel: "Set as validated",
  },
  [ESchoolStatus.LINKED]: {
    uid: ESchoolStatus.LINKED,
    label: "Linked to Monitor",
  },
  [ESchoolStatus.DEPLOYED]: {
    uid: ESchoolStatus.DEPLOYED,
    label: "Monitor has been sent to school",
    toLabel: "Set as monitor sent",
  },
  [ESchoolStatus.RECEIVED]: {
    uid: ESchoolStatus.RECEIVED,
    label: "School has received monitor",
    toLabel: "Set as monitor received",
  },
  [ESchoolStatus.ACTIVATED]: {
    uid: ESchoolStatus.ACTIVATED,
    label: "School has activated their monitor",
  },
  [ESchoolStatus.INVALID]: {
    uid: ESchoolStatus.INVALID,
    label: "School is invalid",
    toLabel: "Mark selection as Invalid",
  },
  [ESchoolStatus.ERROR]: {
    uid: ESchoolStatus.ERROR,
    label: "There was an error processing this school",
    toLabel: "Mark an issue with this school",
  },
  [ESchoolStatus.WITHDRAWN]: {
    uid: ESchoolStatus.WITHDRAWN,
    label: "School has withdrawn",
    toLabel: "Mark selection as Withdrawn",
  },
};
