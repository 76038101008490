import { initialState as userState } from "./User/userReducer";
import { initialState as activitiesState } from "./Activities/activitiesReducer";
import { initialState as achievementsState } from "./Achievements/achievementsReducer";
import { initialState as notificationState } from "../GenericComponents/NotificationBar";

const initialState = {
  userState,
  activitiesState,
  achievementsState,
  notificationState,
};

export default initialState;
