import React from "react";
import PageStyle from "../styles/page";

const NotificationsPage: React.FC = () => {
  return (
    <PageStyle className="subpage_wrap">
      <h2>Your Notifications!</h2>
      <span>Please see your notifications below...</span>
      <ul className="list-reset">
        <li>Notification 1</li>
        <li>Notification 2</li>
      </ul>
    </PageStyle>
  );
};

export default NotificationsPage;
