import { rest } from "msw";
import { SERVER_URL } from "../config";
import { IApiResponseError } from "./apiHelpers";
import { ESchoolStatus } from "../lib/School/ESchoolStatus";
import {
  IApiDeployMonitorArgs,
  IApiInitiateValidationArgs,
  IApiMarkRequestsValidArgs,
  IApiLinkMonitorArgs,
  IApiUpdateDeploymentStatusArgs,
  IApiGetDeploymentStatsReturn,
  IApiSchoolsReturn,
  IApiMarkRequestsInvalidArgs,
} from "./apiMonitorDeployment";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";
import { ISchoolValidated } from "../lib/School/ISchoolDetails";
import { IMonitorServerState } from "../lib/Monitor/IMonitor";
import * as database from "../dummyApi/database";
import { parseJwt } from "../utils/testing/test-utils";

const Invalid = (req, res, ctx) => (message) =>
  res(
    ctx.status(403),
    ctx.json({
      errorMessage: message,
    })
  );
const Ok = (req, res, ctx) =>
  res(
    ctx.status(200),
    ctx.json({
      ok: true,
    })
  );

export const validateRequest = (req, res, ctx) => {
  if (!req?.body) {
    return Invalid(req, res, ctx)("Missing request body");
  }
  return;
};

const catchErrors = (fn) => {
  try {
    return fn();
  } catch (error) {
    console.info("NETWORK ERROR", error);
    throw error;
  }
};

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    // apiInitiateValidation
    useMockApi: true,
    endpoint: rest.post<IApiInitiateValidationArgs>(
      `${SERVER_URL}/MonitorDeployment/InitiateValidation`,
      (req, res, ctx) =>
        catchErrors(() => {
          const invalidRequest = validateRequest(req, res, ctx);
          if (invalidRequest) return invalidRequest;

          const accessToken = req.headers.get("Authorization");
          let tokenData;
          try {
            tokenData = parseJwt(accessToken);
          } catch (e) {
            console.info("Failed to parse user access token", accessToken);
            return res(
              ctx.status(401),
              ctx.json({
                errorMessage: "Not authorized",
              })
            );
          }
          const { userName } = tokenData;

          const { monitorRequestIds } = req.body;
          if (!monitorRequestIds) return Invalid(req, res, ctx)("Missing monitorRequestId");
          monitorRequestIds.forEach((monitorRequestId) => {
            const monitorRequestData = database.getDoc<IMonitorRequest>(monitorRequestId, "MonitorRequests");
            const newMonitorRequestData: IMonitorRequest = {
              ...monitorRequestData,
              dateStartedValidating: new Date(),
              lastEditedUser: userName,
            };
            database.putDoc(newMonitorRequestData.id, "MonitorRequests", newMonitorRequestData);
          });

          return Ok(req, res, ctx);
        })
    ),
  },
  {
    // apiMarkRequestsValid
    useMockApi: true,
    endpoint: rest.post<IApiMarkRequestsValidArgs>(
      `${SERVER_URL}/MonitorDeployment/MarkRequestsValid`,
      (req, res, ctx) =>
        catchErrors(() => {
          const accessToken = req.headers.get("Authorization");
          let tokenData;
          try {
            tokenData = parseJwt(accessToken);
          } catch (e) {
            console.info("Failed to parse user access token", accessToken);
            return res(
              ctx.status(401),
              ctx.json({
                errorMessage: "Not authorized",
              })
            );
          }
          const { userName } = tokenData;

          const invalidRequest = validateRequest(req, res, ctx);
          if (invalidRequest) return invalidRequest;

          const { monitorRequestIds } = req.body;
          if (!monitorRequestIds) return Invalid(req, res, ctx)("Missing monitorRequestId");
          for (let i = 0; i < monitorRequestIds.length; i++) {
            const monitorRequestId = monitorRequestIds[i];

            const monitorRequestData = database.getDoc<IMonitorRequest>(monitorRequestId, "MonitorRequests");
            const newMonitorRequestData: IMonitorRequest = {
              ...monitorRequestData,
              dateValidated: new Date(),
              lastEditedUser: userName,
            };
            database.putDoc(newMonitorRequestData.id, "MonitorRequests", newMonitorRequestData);

            const newSchoolValidated: ISchoolValidated = {
              ...monitorRequestData,
              monitorRequestId: monitorRequestData.id,
              status: ESchoolStatus.VALIDATED,
            };
            database.addDoc(newSchoolValidated.id, "SchoolsValidated", newSchoolValidated);
          }

          return Ok(req, res, ctx);
        })
    ),
  },

  {
    // apiMarkRequestsInvalid
    useMockApi: true,
    endpoint: rest.post<IApiMarkRequestsInvalidArgs>(
      `${SERVER_URL}/MonitorDeployment/MarkRequestsInvalid`,
      (req, res, ctx) =>
        catchErrors(() => {
          const accessToken = req.headers.get("Authorization");
          let tokenData;
          try {
            tokenData = parseJwt(accessToken);
          } catch (e) {
            console.info("Failed to parse user access token", accessToken);
            return res(
              ctx.status(401),
              ctx.json({
                errorMessage: "Not authorized",
              })
            );
          }
          const { userName } = tokenData;

          const invalidRequest = validateRequest(req, res, ctx);
          if (invalidRequest) return invalidRequest;

          const { invalidMonitorRequests } = req.body;
          if (!invalidMonitorRequests) return Invalid(req, res, ctx)("Missing monitorRequestId");
          for (let i = 0; i < invalidMonitorRequests.length; i++) {
            const { monitorRequestId, invalidReason } = invalidMonitorRequests[i];

            const monitorRequestData = database.getDoc<IMonitorRequest>(monitorRequestId, "MonitorRequests");
            const newMonitorRequestData: IMonitorRequest = {
              ...monitorRequestData,
              invalidReason,
              dateMarkedInvalid: new Date(),
              lastEditedUser: userName,
            };
            database.putDoc(newMonitorRequestData.id, "MonitorRequests", newMonitorRequestData);

            const newSchoolValidated: ISchoolValidated = {
              ...monitorRequestData,
              monitorRequestId: monitorRequestData.id,
              status: ESchoolStatus.VALIDATED,
            };
            database.addDoc(newSchoolValidated.id, "SchoolsValidated", newSchoolValidated);
          }
          return Ok(req, res, ctx);
        })
    ),
  },
  {
    // apiLinkMonitor
    useMockApi: true,
    endpoint: rest.post<IApiLinkMonitorArgs>(`${SERVER_URL}/MonitorDeployment/LinkMonitor`, (req, res, ctx) => {
      const invalidRequest = validateRequest(req, res, ctx);
      if (invalidRequest) return invalidRequest;
      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        console.info("Failed to parse user access token", accessToken);
        return res(
          ctx.status(401),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const { userName } = tokenData;

      const { monitorId, schoolId } = req.body;
      if (!monitorId) return Invalid(req, res, ctx)("Missing monitorId");
      if (!schoolId) return Invalid(req, res, ctx)("Missing schoolId");
      const schoolData = database.getDoc<ISchoolValidated>(schoolId, "SchoolsValidated");
      if (!schoolData) return Invalid(req, res, ctx)(`Could not find school with id: ${schoolId}`);
      const linkedMonitorData: IMonitorServerState = {
        Id: 99,
        MonitorReferenceId: monitorId,
        SchoolId: schoolData.schoolId,
        DateActivated: new Date(Date.now()),
      };
      database.addDoc(linkedMonitorData.Id, "Monitors", linkedMonitorData);
      const schoolDataLinked: ISchoolValidated = {
        ...schoolData,
        monitors: [monitorId],
        lastEditedUser: userName,
        status: ESchoolStatus.LINKED,
      };
      database.putDoc(schoolDataLinked.id, "SchoolsValidated", schoolDataLinked);

      return Ok(req, res, ctx);
    }),
  },
  {
    // apiDeployMonitor
    useMockApi: true,
    endpoint: rest.post<IApiDeployMonitorArgs>(`${SERVER_URL}/MonitorDeployment/DeployMonitor`, (req, res, ctx) =>
      catchErrors(() => {
        const invalidRequest = validateRequest(req, res, ctx);
        if (invalidRequest) return invalidRequest;

        const accessToken = req.headers.get("Authorization");
        let tokenData;
        try {
          tokenData = parseJwt(accessToken);
        } catch (e) {
          console.info("Failed to parse user access token", accessToken);
          return res(
            ctx.status(401),
            ctx.json({
              errorMessage: "Not authorized",
            })
          );
        }
        const { userName } = tokenData;

        const { schoolIds } = req.body;

        if (!schoolIds) return Invalid(req, res, ctx)("Missing school id");

        const schoolData = database.getDoc<ISchoolValidated>(schoolIds, "SchoolsValidated");
        const schoolDataUpdated: ISchoolValidated = {
          ...schoolData,
          lastEditedUser: userName,
          status: ESchoolStatus.DEPLOYED,
        };
        database.putDoc(schoolDataUpdated.id, "SchoolsValidated", schoolDataUpdated);

        return Ok(req, res, ctx);
      })
    ),
  },
  {
    // apiUpdateDeploymentStatus && apiMarkReceived
    useMockApi: true,
    endpoint: rest.post<IApiUpdateDeploymentStatusArgs>(
      `${SERVER_URL}/MonitorDeployment/UpdateDeploymentStatus`,
      (req, res, ctx) => {
        const invalidRequest = validateRequest(req, res, ctx);
        if (invalidRequest) return invalidRequest;

        const accessToken = req.headers.get("Authorization");
        let tokenData;
        try {
          tokenData = parseJwt(accessToken);
        } catch (e) {
          console.info("Failed to parse user access token", accessToken);
          return res(
            ctx.status(401),
            ctx.json({
              errorMessage: "Not authorized",
            })
          );
        }
        const { userName } = tokenData;

        const { schoolId, newStatus } = req.body;

        if (!schoolId) return Invalid(req, res, ctx)("Missing school id");

        const schoolData = database.getDoc<ISchoolValidated>(schoolId, "SchoolsValidated");
        const schoolDataUpdated: ISchoolValidated = {
          ...schoolData,
          lastEditedUser: userName,
          status: newStatus,
        };
        database.putDoc(schoolDataUpdated.id, "SchoolsValidated", schoolDataUpdated);

        return Ok(req, res, ctx);
      }
    ),
  },
  {
    /* apiGetDeploymentStats */
    useMockApi: false,
    endpoint: rest.get<{}, {}, IApiGetDeploymentStatsReturn | IApiResponseError>(
      `${SERVER_URL}/MonitorDeployment/Stats`,
      (req, res, ctx) => {
        // NOTE: This is not necessarily what is computed on the server.
        const stats: Omit<IApiGetDeploymentStatsReturn, "ok"> = {
          totalRequestCount: database.getDocs("MonitorRequests").length,
          totalValidatedRequestCount: database
            .getDocs<IMonitorRequest>("MonitorRequests")
            .filter((m) => m.dateValidated).length,
          totalSchoolsWithMonitorLinkedCount: database
            .getDocs<ISchoolValidated>("Schools")
            .filter((s) => s.status === ESchoolStatus.LINKED).length,
          totalSchoolsStatusDeployed: database
            .getDocs<ISchoolValidated>("Schools")
            .filter((s) => s.status === ESchoolStatus.DEPLOYED).length,
          totalSchoolsStatusActivated: database
            .getDocs<ISchoolValidated>("Schools")
            .filter((s) => s.status === ESchoolStatus.ACTIVATED).length,
          totalSchools: database.getDocs<ISchoolValidated>("Schools").length,
          totalSchoolsStatusDuplicate: database.getDocs<ISchoolValidated>("Schools").length,
          totalSchoolsStatusWithdrawn: database.getDocs<ISchoolValidated>("Schools").length,
        };
        return res(ctx.status(200), ctx.json({ ok: true, ...stats }));
      }
    ),
  },
  {
    /* searchSchoolDetails */
    useMockApi: true,
    endpoint: rest.get<{}, {}, IApiSchoolsReturn[]>(`${SERVER_URL}/MonitorDeployment/Search`, (req, res, ctx) => {
      const searchPhrase = req.url.searchParams.get("searchPhrase");
      const schoolStatus = req.url.searchParams.get("schoolStatus");
      const filteredSchools = database.getDocs<ISchoolValidated>("SchoolsValidated").filter((s) => {
        const searchableText = [s.id, s.schoolId, s.schoolName, s.schoolAddressPostCode].join(" ");
        return (
          (schoolStatus === "all" || s.status === (parseInt(schoolStatus) as ESchoolStatus)) &&
          (!searchPhrase || searchableText.includes(searchPhrase))
        );
      });
      return res(
        // Respond with a 200 status code
        ctx.status(200),
        ctx.json(
          filteredSchools.map((s) => ({
            id: s.id,
            schoolId: s.schoolId,
            monitorRequest: database.getDocs<IMonitorRequest>("MonitorRequests").find((ss) => {
              return s.schoolId === ss.schoolId;
            }),
            monitorReferenceIds: s?.monitors ? [String(s?.monitors[0])] : [],
            potentialDuplicateMonitorRequests: null,
            status: 10,
          }))
        )
      );
    }),
  },
  {
    /* getSchoolDetails */
    useMockApi: true,
    endpoint: rest.get<{}, { id: string }, IApiSchoolsReturn>(
      `${SERVER_URL}/MonitorDeployment/Schools/:id`,
      (req, res, ctx) => {
        const id = req.params.id;
        const school = database.getDocs<ISchoolValidated>("SchoolsValidated").find((s) => {
          return s.schoolId === id;
        });

        const monitorRequest = database.getDocs<IMonitorRequest>("MonitorRequests").find((s) => {
          return s.schoolId === id;
        });

        if (!monitorRequest) return Invalid(req, res, ctx)(`Could not find monitor request for school: ${id}`);
        return res(
          // Respond with a 200 status code
          ctx.status(200),
          ctx.json({
            ...school,
            monitorRequest,
            schoolId: school.schoolId,
            id: school.id,
            monitorReferenceIds: school.monitors?.map((m) => String(m)) || [],
            potentialDuplicateMonitorRequests: null,
          })
        );
      }
    ),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
