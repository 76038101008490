import React from "react";
import { CardStyle } from "../../../GenericComponents/CardBoard/styles";
import { FlexBox } from "../../../styles/singlePageStyles";
import { IResourceCardInnerProps } from "./AppResourcesDownloadCard";
import { TextFormatter } from "../../TextFormatter";

export const ResourcePlainTextCard: React.FC<IResourceCardInnerProps> = ({ label, description, thumbnailUrl }) => {
  return (
    <CardStyle>
      {thumbnailUrl && (
        <FlexBox center style={{ width: "100%" }}>
          <img src={thumbnailUrl} width="100%" alt={label} />
        </FlexBox>
      )}
      <h1>{label}</h1>
      <div style={{ marginBottom: "1rem" }}>
        <TextFormatter text={description} keywordMap={[]} />
      </div>
    </CardStyle>
  );
};
