import { DocumentStyle } from "../../styles/documents";
import { Section, SectionHeading, P, Ul } from "../../styles/page";

export const lastUpdated = "17/06/2022";
export const version = "1.4";

export const RegistrationPrivacyPolicy = () => {
  return (
    <DocumentStyle>
      <Section>
        <P>Version: {version}</P>
        <P> Last updated {lastUpdated} </P>
      </Section>
      <Section>
        <SectionHeading>How will your information be used?</SectionHeading>
        <P>Research Study Title: Schools' Air quality Monitoring for Health and Education P91387</P>
        <P>
          Imperial College London is the sponsor for this study and will act as the data controller for this study. This
          means that we are responsible for looking after your information and using it properly. Imperial College
          London will keep your personal data for:
        </P>
        <Ul>
          <li>10 years after the study has finished in relation to data subject consent forms.</li>
          <li>10 years after the study has completed in relation to primary research data.</li>
        </Ul>
        <P>This study is stated to finish in August 2024, but additional funding may extend the project.</P>
        <P>We will need to use information from you for this registration form. </P>
        <P>This information will include:</P>
        <Ul>
          <li>Your name</li>
          <li>
            Your contact details (your email address - preferably a work one - and a postal address for you at your
            school)
          </li>
        </Ul>
        <P>
          <span>
            People within Imperial College London and study team (see section &lsquo;Sharing your information with
            others') will use this information to do the research or to check your records (see information to be
            collected) to make sure that the research is being done properly and the information held (such as contact
            details) is accurate.
          </span>
        </P>
        <P>
          <span>People who do not need to know who you are will not be able to see your name or contact details.</span>
        </P>
        <P>
          <span>We will keep all information about you safe and secure. </span>
        </P>
        <P>
          <span></span>
        </P>
        <P>
          <span>
            Once we have finished the study, we will keep some of the data so we can check the results. We will write
            our reports in a way that no-one can work out that you took part in the study.
          </span>
        </P>
        <P>
          <span></span>
        </P>
      </Section>
      <Section>
        <SectionHeading>
          <span>Legal Basis</span>
        </SectionHeading>
        <P>
          <span>
            As a university we use personal information to conduct research that will either be in the public interest,
            or the &lsquo;legitimate interests' of Imperial College whose interests are to conduct world leading
            research and innovation aiming to deliver transformative impact for society.
          </span>
        </P>
        <P>
          <span>
            Our legal basis for using your information under the General Data Protection Regulation (GDPR) and the Data
            Protection Act 2018, is as follows:
          </span>
        </P>
        <Ul>
          <li>Imperial College London - "performance of a task carried out in the public interest"</li>
        </Ul>
        <P>
          <span>
            Where special category personal information is involved (most commonly health data, biometric data and
            genetic data, racial and ethnic data etc.), Imperial College London relies on "scientific or historical
            research purposes or statistical purposes".
          </span>
        </P>
        <P>
          <span>
            We will conduct scientific research in compliance with the law and the recommendations and guidance
            published by the UK Information Commissioners Office (ICO). This will follow similar rules to health and
            social care research but may not meet the definition of the College's public interest task or requirement.
            In such cases we will be holding and using your data for what are called our &ldquo;legitimate
            interests&rdquo; where, as previously stated, the College aims to conduct world leading research and
            innovation aiming to deliver transformative impact for society. Scientific research should serve the public
            interest, which means that we have to demonstrate that our research has societal impact serving the
            interests of society as a whole.
          </span>
        </P>
      </Section>
      <Section>
        <SectionHeading>International Transfers</SectionHeading>
        <P>
          <span>
            There may be a requirement to transfer information to countries outside the European Economic Area (for
            example, to a research partner). Where this information contains your personal data, Imperial College London
            will ensure that it is transferred in accordance with data protection legislation. If the data is
            transferred to a country which is not subject to a European Commission{" "}
          </span>
          <b>(EC)</b>{" "}
          <span>
            adequacy decision in respect of its data protection standards, Imperial College London will enter into a
            data sharing agreement with the recipient organisation that incorporates EC approved standard contractual
            clauses that safeguard how your personal data is processed.
          </span>
        </P>
      </Section>
      <Section>
        <SectionHeading>Sharing your information with others</SectionHeading>
        <P>
          <span>
            For the purposes referred to in this privacy notice and relying on the bases for processing as set out
            above, we will share your personal data with certain third parties.
          </span>
        </P>
        <Ul>
          <li>
            <span>
              Other College employees, agents, contractors and service providers (for example, suppliers of printing and
              mailing services, email communication services or web services, or suppliers who help us carry out any of
              the activities described above). Our third party service providers are required to enter into data
              processing agreements with us. We only permit them to process your personal data for specified purposes
              and in accordance with our policies.
            </span>
          </li>
          <li>
            <span>The following Research Collaborators in the study will have access to your data:</span>
          </li>
          <Ul>
            <li>
              <span>
                The University of Cambridge &ndash; will have access to your personal data, including your contact
                details because they will be in charge of arranging delivery of a monitor to you
              </span>
            </li>
            <li>
              <span>
                Selected contractors of the University of Cambridge &ndash; the University of Cambridge may seek to
                engage contractors to help them with the process of dispatching monitors. These contractors would
                receive access to your contact details because they will be assisting with the arranging delivery of a
                monitor to you
              </span>
            </li>
            <li>
              <span>
                The Stockholm Environment Institute (SEI) at the University of York &ndash; will have access to your
                personal data including your contact details because they will be leading communications with schools
              </span>
            </li>
            <li>
              <span>
                The University of Surrey, the University of Leeds, and the UK HSA &ndash; will have access to all
                non-personal data given in response to this form, including the generic email address you specify and
                all school level data, so that they can help us to make decisions about which schools should receive a
                monitor in the event we are oversubscribed, and to that they can help us to track and evaluate
                participation at the school level in relation to our goal to build an air quality monitor network which
                is representative of the UK's schools.
              </span>
            </li>
          </Ul>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Potential use of study data for future research</SectionHeading>
        <P>
          <span>
            When you agree to take part in a research study, the information collected either as part of the study or in
            preparation (such as contact details) may, with your consent, be provided to researchers running other
            research studies in this organisation and in other organisations. These organisations may be universities or
            organisations involved in research in this country or abroad. Your information will only be used by
            organisations and researchers to conduct research in accordance with all relevant legislation including the
            GDPR (UK GDPR and EU GDPR 2016/679), the Data Protection Act 2018.
          </span>
        </P>
        <P>
          <span>
            This information will not identify you and will not be combined with other information in a way that could
            identify you, used against you or used to make decisions about you.
          </span>
        </P>
      </Section>
      <Section>
        <SectionHeading>Commercialisation</SectionHeading>
        <P>
          <span>
            Samples / data from the study may also be provided to organisations not named in this participant
            information sheet, e.g. commercial organisations or non-commercial organisations for the purposes of
            undertaking the current study, future research studies or commercial purposes such as development by a
            company of a new test, product, service or treatment. We will ensure your name and any identifying details
            will NOT be given to these third parties, instead you will be identified by a unique study number with any
            sample analysis having the potential to generate &lsquo;personal data'.
          </span>
        </P>
        <P>
          <span>
            Aggregated (combined) or anonymised data sets (all identifying information is removed) may also be created
            using your data (in a way which does not identify you individually) and be used for such research or
            commercial purposes where the purposes align to relevant legislation (including the GDPR) and wider aims of
            the study. Your data will not be shared with a commercial organisation for marketing purposes.
          </span>
        </P>
      </Section>
      <Section>
        <SectionHeading>What are your choices about how your information is used?</SectionHeading>
        <P>
          <span>
            You can stop this registration form at any time before submitting and you can withdraw your personal data
            after submitting it by contacting the SAMHE project team using {" "}
          </span>
          <span>
            <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>
          </span>
          <span>
            . You will not be required to provide a reason if you wish to withdraw and there will be no repercussions if
            you decide to do so. If you withdraw we will keep information you provided in relation to your school, so
            that we can continue project work.
          </span>
        </P>
      </Section>
      <Section>
        <SectionHeading>Where can you find out more about how your information is used?</SectionHeading>
        <P>You can find out more about how we use your information </P>
        <Ul>
          <li>
            <span>by asking one of the research team</span>
          </li>
          <li>
            <span>by sending an email to</span>
            <span> </span>
            <span>
              <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>
            </span>
          </li>
        </Ul>
      </Section>
      <Section>
        <SectionHeading>Complaint</SectionHeading>
        <P>
          <span>
            If you wish to raise a complaint on how we have handled your personal data, please contact Imperial College
            London's Data Protection Officer via email at <a href="mailto:dpo@imperial.ac.uk">dpo@imperial.ac.uk</a>,
            via telephone on 020 7594 3502 and/or via post at Imperial College London, Data Protection Officer, Faculty
            Building Level 4, London SW7 2AZ.
          </span>
        </P>
        <P>
          <span>
            If you are not satisfied with our response or believe we are processing your personal data in a way that is
            not lawful you can complain to the Information Commissioner's Office (ICO). The ICO does recommend that you
            seek to resolve matters with the data controller (us) first before involving the regulator.
          </span>
        </P>
      </Section>
    </DocumentStyle>
  );
};
