import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { LoadingCardStyle } from "./styles";

export const LoadingCard = () => (
  <LoadingCardStyle>
    <p>
      <FontAwesomeIcon className="" style={{ color: "grey" }} icon={faSpinner} spin />{" "}
      Loading card data
    </p>
  </LoadingCardStyle>
);
