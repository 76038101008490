export const PmGuidanceDataDetectives = `
The levels of !particulate matter!, PM₂.₅, detected by your SAMHE monitor might be affected by many different types of things floating in your air - take a look at our [guidance on interpreting monitor readings](/resources/monitor-leds) with your teacher for more detail.  Below is a list of particulate matter that could be affecting the PM₂.₅ levels on your SAMHE monitor. Remember that every classroom is different, and how you use your classroom will also have a big effect, so not everything on the list will be relevant to your space.

 - **Fine dust** - dust floats on the air in many indoor spaces. Levels of dust may be higher when people have been dusting or sweeping, if people bang or rub dusty cushions/furniture, and when the classroom is very active. 
 - !Respiratory aerosols!  - every time we breathe out, we exhale a mix of small droplets/!particles!. When these are carried on the air indoors they are called respiratory aerosols.
 - !Combustion!  - anything that burns can produce small particulate matter, this might not happen often in your classrooms but the PM levels might increase if anything like a Bunsen burner, candle, gas cooker, or even incense is being used.
 - **Cooking food** - particularly, frying, stir-frying, and food like overdone toast will all create particulate matter in the air.
 - **Outdoor air** - air that that enters your school and classrooms via ventilation ducts due to weather-related events (e.g. desert dust). If you are near a busy road, small particles from combustion in vehicle engines or from their tyres and brakes could also be transported indoors.

This list is not designed to be exhaustive (so it won't cover everything that could possibly affect classroom PM₂.₅ levels) but it does include the things we think are most likely.
`;
