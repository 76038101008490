import React from "react";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";
import { HeroBackground, BackgroundHeroStyle } from "../styles/hero";
import {
  ConstrainedPageStyle,
  PageTitle,
  Section,
  SectionHeading,
  P,
  TableOfContentsWrapper,
  TableOfContents,
  Ul,
  FloatImageMax,
  SectionBackground
} from "../styles/page";
import ScrollTopButton from "../GenericComponents/Buttons/ScrollTopButton";
import { CTALinkButtonStyle } from "../styles/links";

const VocabSheetPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <SectionBackground>
        <PageTitle>Key Definitions</PageTitle>
        <BackgroundHeroStyle>
          <HeroBackground src="/images/key-definitions/blue-sky-with-clouds.jpg" />
        </BackgroundHeroStyle>
      </SectionBackground>
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>Contents:</h4>
            <Ul>
              <li>
                <Link to="#air-pollution">Air Pollution</Link>
              </li>
              <li>
                <Link to="#air-quality">Air quality</Link>
              </li>
              <li>
                <Link to="#carbon-dioxide">Carbon dioxide</Link>
              </li>
              <li>
                <Link to="#particulate-matter">Particulate Matter</Link>
              </li>
              <li>
                <Link to="#total-volatile-organic-compounds">TVOCs</Link>
              </li>
              <li>
                <Link to="#relative-humidity">Relative humidity</Link>
              </li>
              <li>
                <Link to="#temperature">Temperature</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <Section>
            <SectionHeading id="air-pollution">Air Pollution</SectionHeading>
            <P>
              Air pollution is the combination of tiny airborne particles (solid or liquid) and certain gases that are
              mixed into the air around us, making it less healthy to breathe. These air pollutants mostly come from
              human activities such as car and lorry exhausts, factory smoke, and even from the furniture in our
              buildings and paint on our walls. However, some of the particles, such as desert dust (which can travel
              from the Sahara!) and mould spores, come from natural sources.
            </P>
            <P>
              We can measure the 'particulate matter' (PM) (or particles) in the air. Some of the gases found in our air
              are produced by commonly used solids and liquids naturally evaporating, these gases are typically volatile
              organic compounds (or VOCs - almost everything you smell is a VOC!). Other gases that are common air
              pollutants include nitrogen dioxide, sulphur dioxide, ozone or carbon monoxide.
            </P><P> The SAMHE monitors measure
              particulate matter, total volatile organic compounds but also carbon dioxide, along with temperature and
              relative humidity. We're measuring carbon dioxide (CO<sub>2</sub>) because, although it is not actually a
              pollutant, it is a reliable indicator of some aspects of indoor air quality; higher indoor concentrations
              of CO<sub>2</sub> indoors correspond with high levels of rebreathed air and a lack of fresh air supplied
              to the room. For more information on what air pollution is, visit the{" "}
              <AExternal href="https://www.cleanairhub.org.uk/clean-air-information/the-basic-information/what-is-air-pollution">
                Clean Air Hub
              </AExternal>
              .
            </P>
          </Section>
          <Section>
            <SectionHeading id="air-quality">Air quality</SectionHeading>
            <P>
              Air quality describes how polluted the air we breathe is. It is important because the presence of air
              pollution can have a direct impact on our health, both in the long and short term.{" "}
            </P>
          </Section>
          <Section>
            <SectionHeading id="carbon-dioxide">Carbon dioxide</SectionHeading>
            <P>
              Carbon dioxide (CO<sub>2</sub>) is a gas found in the air around us. The most common source of CO
              <sub>2</sub> indoors in schools is people who breathe it out. If a room is well ventilated, CO<sub>2</sub>{" "}
              levels are usually much lower. Because of this, CO<sub>2</sub> is a good indication of the ventilation
              each person might be experiencing: with higher CO<sub>2</sub> levels showing an increase in stale air and
              a lack of fresh air supplied to the room.
            </P>
            <P>
              Outside, the typical concentration of CO<sub>2</sub> is around 415 ppm (parts per million) although it can
              be higher in cities. For more information about background CO<sub>2</sub> levels and how they have changed
              over time, see{" "}
              <AExternal href="https://climate.nasa.gov/vital-signs/carbon-dioxide/">NASA's climate pages</AExternal>.
            </P>
            <FloatImageMax
              src="/images/key-definitions/buildings.jpg"
              alt="buildings"
            />
            <P>
              A well-ventilated classroom should have CO<sub>2</sub> concentrations below 800 ppm (shown by the green
              LEDs on your SAMHE monitor and explained in <Link to="/resources/monitor-leds">our monitor guidance</Link>
              ). If the reading is between 800 and 1,500 ppm (orange LEDs), the room is potentially stuffy and steps
              could be taken to improve the ventilation to the room (for example, by opening windows).
            </P> <P> Consistent
              readings above 1,500 ppm (red LEDs on your SAMHE monitor) could indicate that the classroom ventilation
              needs improving, if the levels keep rising above 1,500 ppm then please seek support from your Senior
              Leadership Team. Even at this level, CO<sub>2</sub> is not itself dangerous to our health, it is very
              different to poisonous gases, such as carbon monoxide. However, lower CO<sub>2</sub> levels have been
              shown to help with our concentration and improving the ventilation could also help with the student's
              learning.
            </P>
            <FloatImageMax
              src="/images/monitor-leds/monitor_readings_page_GIF.gif"
              alt="monitor readings GIF"
            />
            <P>
              For more information on the LEDs see our guide to monitor LEDs during normal operation available on the{" "}
              <Link to="/resources">Resources Page</Link>. General information on CO<sub>2</sub> and the use of monitors
              to manage ventilation can be found on the{" "}
              <AExternal href="https://coschools.org.uk/">CoSchools website</AExternal>.
            </P>
          </Section>
          <Section>
            <SectionHeading id="particulate-matter">Particulate Matter</SectionHeading>
            <FloatImageMax
              src="/images/key-definitions/hand-with-dust.jpg"
              alt="hand with dust"
            />
            <P>
              Particulate matter is small bits of matter that we can breathe in. The monitors measure the fine
              particles, PM<sub>2.5</sub>, which include most of the particles smaller than 2.5 microns and are
              invisible to the naked eye. They can come from different sources, some of which are outdoors, for example
              traffic, whilst others come from indoors, for example, cooking or dust from carpets.
            </P>
          </Section>
          <Section>
            <SectionHeading id="total-volatile-organic-compounds">Total volatile organic compounds </SectionHeading>
            <FloatImageMax
              src="/images/key-definitions/cleaning-materials.jpg"
              alt="cleaning materials"
            />
            <P>
              The monitors also detect total volatile organic compounds (TVOCs). Volatile organic compounds, or VOCs,
              are chemicals that evaporate into the air from liquids or solids commonly found indoors. VOCs come from a
              range of sources like cleaning products, air fresheners, paint or cooking.
            </P>
          </Section>
          <Section>
            <SectionHeading id="relative-humidity">Relative humidity</SectionHeading>
            <P>
              Relative humidity is a measure of the moisture in the air around us. It is useful to measure because it
              tells us how comfortable people might be in the room.
            </P>
          </Section>
          <Section>
            <SectionHeading id="temperature">Temperature</SectionHeading>
            <P>
              The monitors also measure temperature. This is another useful factor to measure, because it can show how
              comfortable the room might feel to students and staff, and whether the heating systems, and the
              ventilation, are working well. If the ventilation is really good (with a CO<sub>2</sub> reading well below
              800 ppm) and the room is too cold, then you could consider having your windows slightly less wide open.
              Keep referring back to your SAMHE monitor as you make adjustments.
            </P>
          </Section>
          <Section>
            <CTALinkButtonStyle to="/resources">Find more info on the Resources Hub</CTALinkButtonStyle>
          </Section>
          <ScrollTopButton />
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default VocabSheetPage;
