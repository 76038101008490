import React from "react";
import { EChartsOption, VisualMapComponentOption } from "echarts";
import styled, { useTheme } from "styled-components";
import ReactECharts from "echarts-for-react";
import { useWindowSize } from "@uidotdev/usehooks";
import { IMonitorDataItem, IMonitorDataItemProcessed } from "../../../lib/MonitorData/IMonitorData";
import { SeriesOptionsType, aggregateDays } from "../dataProcessing/aggregateDays";
import { IPreProcessDataOptions, preProcessData } from "../dataProcessing/dataProcessing";
import { IVisualMapProps } from "../../DataViewDashboard/DataViewTypes/BubblePlotView/lib";

export interface IBubbleArrayPlotProps {
  data: IMonitorDataItem<number>[];
  preProcessDataOptions?: IPreProcessDataOptions;
  scale?: number;
  visualMapProps?: IVisualMapProps;
  unit: string; // TODO: This may be better coming from metric choice
}

// Values to adjust positioning and scaling of chart elements
const YOFFSET_POINTS = 4;
const MIN_SIZE = 10;
const MAX_SIZE = 60;
const ROW_SPACING = MAX_SIZE * 1.4;


export const LegendWrap = styled.div`
  flex-grow: 0.3;
  min-width: 11rem;
  position: relative;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    position: absolute;
    right: 1rem;
  }
`;

export const PlotWrap = styled.div`
  flex-grow: 1;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    position: relative;
    flex-grow: 0.85;
  }
  .reactECharts-container {
    height: 16rem;
    @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      height: 24rem;
      min-height: 24rem;
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.large}) {
    flex-direction: row;
  }
`;

export const optionsExpandedView =
  (scale) =>
  (day, hours, idx): ReturnType<SeriesOptionsType> => ({
    title: {
      textBaseline: "middle",
      top: (idx * ROW_SPACING)+ ROW_SPACING,
      left: 0,
      text: day,
      textStyle: {
        fontSize: "1rem",
      },
    },
    singleAxis: {
      left: 150,
      right: 30,
      type: "category",
      boundaryGap: false,
      data: hours,
      top: idx * ROW_SPACING,
      height: ROW_SPACING,
      axisLabel: {
        interval: 1,
      },
    },
    series: {
      name: day,
      singleAxisIndex: idx,
      coordinateSystem: "singleAxis",
      type: "scatter",
      data: [],
      symbolSize: function (dataItem) {
        if (dataItem[1] === 0) return [20, 2];
        return Math.min(MAX_SIZE, Math.max(MIN_SIZE, MIN_SIZE + dataItem[1] * scale));
      },
      symbolOffset: function (dataItem) {
        const s = Math.min(MAX_SIZE, Math.max(MIN_SIZE, MIN_SIZE + dataItem[1] * scale))
        return [0, ROW_SPACING / 2 - YOFFSET_POINTS - s / 2];
      },
      symbol: function (dataItem) {
        return dataItem[1] === 0 ? "rect" : "circle";
      },
    },
  });

export const optionsExpanedViewMobile = (scale) => (day, hours, idx) => {
  const out = {
    ...(optionsExpandedView(scale)(day, hours, idx) as any),
  };
  const mobileScale = scale * 0.5;
  out.singleAxis.axisLabel.interval = 4;
  out.singleAxis.left = 110;
  out.series.symbolSize = function (dataItem) {
    if (dataItem[1] === 0) return [20, 2];
    return Math.min(MAX_SIZE, Math.max(MIN_SIZE, MIN_SIZE + dataItem[1] * mobileScale));
  };
  out.series.symbolOffset = function (dataItem) {
    const s = Math.min(MAX_SIZE, Math.max(MIN_SIZE, MIN_SIZE + dataItem[1] * mobileScale))
    return [0, ROW_SPACING / 2 - YOFFSET_POINTS - s / 2];
  };
  return out;
};

/**
 * Bubble plot created by https://github.com/Cfaccetti
 * @returns
 */
export const BubbleArrayPlot = ({
  data,
  preProcessDataOptions,
  scale,
  visualMapProps = {},
  unit,
}: IBubbleArrayPlotProps) => {
  const dataProcessed = preProcessData(data, preProcessDataOptions);
  const dataProcessedCropped = (dataProcessed as IMonitorDataItemProcessed<number>[]).filter((d) => d.highlighted);
  const theme = useTheme();
  const size = useWindowSize();
  const isMobileView = React.useMemo(
    () => size.width < parseInt(theme.mediaBoundaries.medium.replace("px", "")),
    [size.width, theme.mediaBoundaries.medium]
  );
  const isTabletView = React.useMemo(
    () => !isMobileView && size.width < parseInt(theme.mediaBoundaries.large.replace("px", "")),
    [isMobileView, size.width, theme.mediaBoundaries.large]
  );
  const baseOptionsFn = React.useMemo(
    () => (isMobileView || isTabletView ? optionsExpanedViewMobile(scale) : optionsExpandedView(scale)),
    [isMobileView, isTabletView, scale]
  );
  const { series, title, singleAxis, hoursNoGap } = aggregateDays(baseOptionsFn)(dataProcessedCropped);

  const chartOptions: EChartsOption = {
    responsive: true,
    grid: {
      width: "100%",
      height: "100%",
    },
    tooltip: {
      position: "top",
      formatter: function (params) {
        if (params.value.length > 1) {
          return params.seriesName + " <br/>" + hoursNoGap[params.value[0]] + ":00 <br/>" + params.value[1] + unit;
        } else {
          return params.seriesName + " <br/>" + params.name + " : " + params.value + unit;
        }
      },
    },
    title: title,
    singleAxis: singleAxis,
    series: series,
    // defines the legend properties
    // This is included just to ensure the colors are correct
    visualMap: {
      show: false,
      calculable: true,
      realtime: false,
      textStyle: {
        fontSize: 0,
      },
      ...visualMapProps,
    },
  };

  const visualMap: VisualMapComponentOption = {
    show: true,
    left: "center",
    top: "middle",
    inverse: visualMapProps.type !== "continuous", // Required as order is flipped in vertical view
    calculable: true,
    realtime: true,
    textStyle: {
      fontSize: 12,
    },
    align: "left",
    hoverLink: true,
    selectedMode: false,
    indicatorIcon: "square",
    indicatorSize: "80%",
    ...(visualMapProps as VisualMapComponentOption),
  } as VisualMapComponentOption;

  const visualMapTablet: EChartsOption["visualMap"] = {
    ...visualMap,
    top: "middle",
    inverse: false, // Required as order is flipped in vertical view
    orient: "horizontal",
    left: "center",
    padding: 0,
  };

  const visualMapMobile: EChartsOption["visualMap"] = {
    ...visualMap,
    top: "middle",
    orient: "vertical",
    inverse: visualMap.type !== "continuous", // Required as order is flipped in vertical view
    left: "center",
    padding: 0,
  };

  const chartOptionsLegend: EChartsOption = {
    responsive: true,
    grid: {
      left: "100%",
    },
    visualMap: isMobileView ? visualMapMobile : isTabletView ? visualMapTablet : visualMap,
  };
  // Key is set to date now to force update see https://github.com/CoTrace-Samhe/FrontEnd.Wrap/issues/378
  return (
    <Wrap>
      <PlotWrap>
        <ReactECharts
          className="reactEcharts-container"
          key={Date.now()}
          option={chartOptions}
          style={{ width: "100%", height: `${(series.length + 0.6) * ROW_SPACING}px` }}
        />
      </PlotWrap>
      <LegendWrap>
        <ReactECharts
          key={Date.now()}
          option={chartOptionsLegend}
          style={{ height: isTabletView ? "6rem" : isMobileView ? "10rem" : `${(series.length + 0.6) * ROW_SPACING}px` }}
        />
      </LegendWrap>
    </Wrap>
  );
};
