import React from "react";
import {
  ConstrainedPageStyle,
  P,
  PageTitle,
  Section,
  SectionHeading,
  TableOfContentsWrapper,
  TableOfContents,
  Ul,
} from "../styles/page";
import { TeamIconsManager } from "../components/TeamIcons/TeamIcons";
import { execBoardMembers, samheTeamMembers, steeringCommitteeMembers } from "../lib/Team/TeamData";
import { Link } from "react-router-dom";
import { CTALinkButtonStyle } from "../styles/links";

const TeamPage: React.FC = () => {
  return (
    <ConstrainedPageStyle className="page__wrap">
      <TableOfContentsWrapper>
        <div>
          <TableOfContents>
            <h4>
              <b>
                <u>Contents:</u>
              </b>
            </h4>
            <Ul>
              <li>
                <Link to="#team">Team</Link>
              </li>
              <li>
                <Link to="#executive-board">Executive Board</Link>
              </li>
              <li>
                <Link to="#steering-committee">Steering Committee</Link>
              </li>
            </Ul>
          </TableOfContents>
        </div>
        <div>
          <Section>
            <PageTitle>Meet the Team</PageTitle>
            <P>
              The core work of the project was carried out by the SAMHE team, led by Dr Henry Burridge and Professor
              Sarah West. It was supported by Steering Committee and Executive Board members, and an Engagement Panel of
              key stakeholders, who helped to shape the project's engagement and dissemination activities.
            </P>
            <br></br>
            <SectionHeading id="team">SAMHE Team</SectionHeading>
            <TeamIconsManager iconsData={samheTeamMembers} />
            <br></br>
            <br></br>
            <SectionHeading id="executive-board">Steering Committee and Executive Board members</SectionHeading>
            <TeamIconsManager iconsData={[...execBoardMembers, ...steeringCommitteeMembers]} />
            <br></br>
            <br></br>
            <Section>
              <CTALinkButtonStyle to="/about">Find more info on the About page</CTALinkButtonStyle>
            </Section>
          </Section>
        </div>
      </TableOfContentsWrapper>
    </ConstrainedPageStyle>
  );
};

export default TeamPage;
