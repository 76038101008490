/*  ASYNC Calls to the API on Node server
 *
 */
import { FilterObjectClass } from "@react_db_client/constants.client-types";

export class ApiError extends Error {
  statusCode: number;
  constructor(message = "", statusCode = 500) {
    super(message);
    this.name = "ApiError";
    this.statusCode = statusCode;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export enum EReturnTypes {
  JSON = "json",
  HTML = "html",
}

export interface IApiResponse {
  ok: boolean;
}

export interface IApiResponseError {
  ok: false;
  message: string;
  error: ApiError;
}

export interface IApiGetResponse<ResponseData> extends IApiResponse {
  ok: true;
  json: ResponseData;
}

export interface IApiPostResponse<ResponseData> extends IApiResponse {
  ok: true;
  json: ResponseData;
}

export interface ApiReturnType<RequestData, Response> {
  request: {
    url: string;
    options: RequestInit;
    data?: RequestData;
  };
  response: Response;
}

// const DEV_MODE = process.env.NODE_ENV === "development";

/* API HELPERS */

const manageResponse =
  <ResponseData>(url, returnType: "json" | "html" = "json") =>
  async (response: Response) => {
    if (response.ok) {
      if (returnType === "json") return response.json() as Promise<ResponseData>;
      if (returnType === "html") return response.text() as Promise<ResponseData extends string ? ResponseData : never>;
    }
    let apiError = "Unkonwn error";
    try {
      apiError = await response.json().then((r) => r.error);
    } catch (error) {
      apiError = response.statusText || (await response.text());
    }
    const errorMessage = apiError || `Something went wrong getting data from ${url}`;
    const error = new ApiError(errorMessage, response.status);
    throw error;
  };

export const throwErrors = async <RequestData, ResponseData>(
  // returned
  returned: ApiReturnType<RequestData, IApiGetResponse<ResponseData> | IApiResponseError>
): Promise<ApiReturnType<RequestData, IApiGetResponse<ResponseData>>> => {
  if ((returned.response as IApiResponseError).error) throw (returned.response as IApiResponseError).error;
  if (!returned.response.ok) throw new ApiError((returned.response as IApiResponseError).message);
  if (!returned.response.json) throw new ApiError("Missing return data!");
  return returned as ApiReturnType<RequestData, IApiGetResponse<ResponseData>>;
};

/**
 * Get Data from api
 *
 * @param {string} url
 * @returns
 */
export const getDataFetch = async <ResponseData>(
  url,
  returnType: "json" | "html" = "json",
  headers: RequestInit["headers"] = {}
): Promise<ApiReturnType<null, IApiGetResponse<ResponseData> | IApiResponseError>> => {
  const options: RequestInit = {
    method: "GET",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };
  try {
    const response = await fetch(url, options);
    const responseData = await manageResponse<ResponseData>(url, returnType)(response);
    const out: IApiGetResponse<ResponseData> = { ok: true, json: responseData };
    return { response: out, request: { url, options } };
  } catch (error) {
    if (error.name === "ApiError") {
      return { response: { ok: false, message: error.message, error }, request: { url, options } };
    }
    throw error;
  }
};

/**
 * Update a doc via the api
 *
 * @param {string} url
 * @param {object} data
 * @returns
 */
export const putDataFetch = async <Data, ResponseData = IApiResponse>(
  url: string,
  data: Data,
  headers: RequestInit["headers"] = {}
): Promise<ApiReturnType<Data, ResponseData>> => {
  const options: RequestInit = {
    method: "PUT",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      ...headers,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const responseData = await manageResponse<ResponseData>(url)(response);
    return { response: responseData, request: { url, options, data } };
  } catch (error) {
    console.warn(`Failed to get data from ${url}`);
    throw error;
  }
};

export const okEmptyResponse = {
  response: {
    ok: true as true,
    json: {
      ok: true as true,
    },
  },
  request: { url: "", options: {}, data: {} },
};

/**
 * Upload a new doc to the api
 *
 * @param {string} url
 * @param {object} data
 * @returns
 */
export const postDataFetch = async <RequestData, ResponseData = IApiResponse>(
  url: string,
  data: RequestData,
  headers: RequestInit["headers"] = {},
  options: {
    returnType?: EReturnTypes;
  } = {
    returnType: EReturnTypes.JSON,
  }
): Promise<ApiReturnType<RequestData, IApiPostResponse<ResponseData>>> => {
  const requestOptions: RequestInit = {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      ...headers,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, requestOptions);
    const responseData = await manageResponse<ResponseData>(url, options.returnType)(response);

    if (response.ok) {
      const out: IApiPostResponse<ResponseData> = { ok: true, json: responseData };
      return { response: out, request: { url, options: requestOptions, data } };
    }
    const apiError = await response
      .json()
      .then((r) => r)
      .catch((e) => e);
    const errorMessage = apiError.error || response.statusText || `Something went wrong getting data from ${url}`;
    const error = new ApiError(errorMessage);
    throw error;
  } catch (error) {
    console.warn(`Failed to get data from ${url}`);
    throw error;
  }
};

/**
 * Get Data from api
 *
 * @param {string} url
 * @returns
 */
export const deleteDataFetch = async <Data, ResponseData>(
  url: string,
  data: Data
): Promise<ApiReturnType<Data, ResponseData>> => {
  const options: RequestInit = {
    method: "DELETE",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data }),
  };

  const responseData = await fetch(url, options).then(manageResponse<ResponseData>(url));

  return { response: responseData, request: { url, options, data } };
};

/* queryObjectToString
 * Converts an array of filters into a query string
 * I.e. [{ uid: 'a', value: 'foo'}, { uid: 'b', value: 'bar' }] => '?a=foo&b=bar'
 */
export const queryDetailedObjectToStringOld = (filters: FilterObjectClass[]): string => {
  const queryArray = filters.map((queryItem) => {
    const { uid, field, value, operator, type } = queryItem;
    // Encode for api
    const f = field || uid;
    let str = `${f}[field]=${f}`;
    // if value is array we add multiple
    if (Array.isArray(value)) {
      value.forEach((v) => {
        str += `&${f}[value]=${v}`;
      });
    } else {
      str += `&${f}[value]=${value}`;
    }
    str += `&${f}[type]=${type || "string"}`;
    if (operator) str += `&${f}[operator]=${operator}`;
    return str;
  });
  return queryArray.length > 0 ? `${queryArray.join("&")}` : "";
};

/* queryObjectToString
 * Converts an array of filters into a query string
 * I.e. [{ uid: 'a', value: 'foo'}, { uid: 'b', value: 'bar' }] => '?a=foo&b=bar'
 */
export const queryDetailedObjectToString = (filters: FilterObjectClass[]): string =>
  filters.length > 0 ? `filters=${JSON.stringify(Object.values(filters))}` : "";

/* queryObjectToString
 * Converts a object to a query string
 * I.e. { a: 'foo', b: 'bar } => '?a=foo&b=bar'
 */
export const queryObjectToString = (queryObject) => {
  const queryArray = [];
  Object.keys(queryObject).forEach((key) => {
    queryArray.push(`${key}=${queryObject[key]}`);
  });
  if (queryArray.length > 0) return `${queryArray.join("&")}`;
  return "";
};

/**
 * Converts schema request array into a string
 *
 * @param {Array} schemaRequest
 * @returns
 */
export const schemaRequestArrayToQueryString = (schemaRequest) => {
  if (Array.isArray(schemaRequest)) return schemaRequest.join(",");
  if (typeof schemaRequest === "string") return schemaRequest;
  throw Error("Schema Request is not an array or string!");
};

export const validateQueryObject = (queryObject) => {
  if (typeof queryObject !== "object" || !Array.isArray(queryObject)) {
    // if (DEV_MODE) console.log(queryObject);
    throw TypeError("Invalid query Object Type. Must be array");
  }
  try {
    /* Try to coerce the query object into a filter object */
    return queryObject.map((q) => q && new FilterObjectClass({ ...q }));
  } catch (error) {
    throw TypeError("Invalid query Object item Type");
  }
};
