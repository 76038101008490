import styled from "styled-components";
import { OnOffButtonStyle } from "../../styles/button";

export const SelectButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  button {
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
  }
`;

export const DateRangeSelectionBtn = styled(OnOffButtonStyle)`
  flex-grow: 1;
`;

export const DateTimePicker = styled.input`
  border-radius: 5px;
  border: 1px solid #767676;
  padding-left: 5px;
  line-height: 1.5rem;
  padding-top: 3px;
  vertical-align: middle;
`;

export const HeaderLabel = styled.label`
  line-height: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeader};
  font-size: ${({ theme }) => theme.typography.fontSize4};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.shape.defaultPadding};
`;
