import { IRoomListItem, IRoomListItemServer } from "../School/IRoom";
import { SchoolId } from "../School/ISchoolDetails";

export type MonitorId = string | number;

/* Server monitor state */
export interface IMonitorServerState {
  Id: number;
  MonitorReferenceId: MonitorId;
  DateCreated?: Date;
  DateActivated?: Date | null; //TODO: Return this from the API
  SchoolId: SchoolId; // This is not actually returned by the api but is needed for tests
  Room?: IRoomListItemServer;
}

export interface IMonitorCurrentState {
  id: MonitorId;
  activated: boolean;
  room: IRoomListItem | null;
}

export interface IMonitorState {
  id: MonitorId;
  activated: boolean;
  room?: IRoomListItem;
}

export enum EMonitorChangeSource {
  HEADER = "HEADER",
  LOGIN = "LOGIN",
  ACTIVITY = "ACTIVITY",
}
