import React from "react";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";

import { ConstrainedPageStyle, P, PageTitle, Section } from "../styles/page";

const SuccessMessage = () => (
  <div>
    <P>
      Thank you for registering to take part in the SAMHE project! Your request has been received and we will email you
      with further information. Please consider adding our email address{" "}
      <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a> to your address book to prevent our emails ending up in
      your spam or junk folders.
    </P>
    <P>
      <b>Due to the volume of registration requests we receive, processing typically takes a few weeks.</b>
    </P>
    <P>
      If we need to confirm any of the details entered in this form we will contact you using the email address you have
      provided.
    </P>
    <P>
      Meanwhile <Link to="/news">read the latest issue of the SAMHE Newsletter</Link> on our News page! If you didn't
      sign up to receive the newsletter as part of registering, you can do so at any time using our{" "}
      <Link to="/contact">contact form</Link>.
    </P>
    <P>
      You can also follow SAMHE on <AExternal href="https://bsky.app/profile/samhe.bsky.social">BlueSky</AExternal> or{" "}
      <AExternal href="https://www.linkedin.com/groups/12898847/">Linkedin</AExternal> and learn more about the project
      through our short{" "}
      <AExternal href="https://www.youtube.com/watch?v=fL33sLJDdFQ">Introducing SAMHE video</AExternal> and other videos
      on <AExternal href="https://www.youtube.com/@SAMHEProject">YouTube</AExternal>.
    </P>
    <P>
      <i>
        Please note completing this form does not guarantee your school a monitor. In the event that we are
        oversubscribed we reserve the right to use the school level information entered in this form to make decisions
        about prioritising monitor dispatch.
      </i>
    </P>
  </div>
);

const SchoolRegistrationSuccessPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Registration request received - thank you!</PageTitle>
      <Section>
        <SuccessMessage />
      </Section>
    </ConstrainedPageStyle>
  );
};

export default SchoolRegistrationSuccessPage;
