import styled, { css } from "styled-components";

export const ButtonCSS = css`
  font-family: ${({ theme }) => theme.typography.fontFamilyButton};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.shape.defaultRadius};
  padding: ${({ theme }) => theme.shape.defaultPadding};
  font-weight: 400;
  text-decoration: none;
  background: ${({ theme }) => theme.background};
  border: 1px grey solid;
  text-align: centre;
  font-size: ${({ theme }) => theme.typography.fontSize3};

  &:hover {
    background: ${({ theme }) => theme.colors.grey1};
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: ${({ theme }) => theme.colors.mainHover} solid 3px;
    outline-offset: -4px;
    border-color: ${({ theme }) => theme.colors.grey1};
  }

  &:disabled,
  &[disabled] {
    background: ${({ theme }) => theme.colors.grey1};
    cursor: not-allowed;
  }
`;

export const ButtonStyle = styled.button`
  ${ButtonCSS}
`;

export const CTAButtonCSS = css`
  ${ButtonCSS}

  background: ${({ theme }) => theme.colors.cta};
  color: ${({ theme }) => theme.colors.ctaText};
  border: none;

  &:hover {
    background: ${({ theme }) => theme.colors.ctaHover};
    color: ${({ theme }) => theme.colors.ctaText};
  }

  &:focus,
  &:focus-within {
    outline-offset: 2px;
  }

  &:disabled,
  &[disabled] {
    background: ${({ theme }) => theme.colors.grey2};
  }
`;

export const CTAButtonStyle = styled(ButtonStyle)`
  ${CTAButtonCSS}
`;

export const DisabledButton = styled(ButtonStyle)`
  background: ${({ theme }) => theme.colors.grey2};
  cursor: disabled;
`;

export interface OnOffButtonStyleProps {
  on?: boolean | string;
}

export const OnOffButtonStyle = styled(ButtonStyle)<OnOffButtonStyleProps>`
  background: ${(props) => (props.on ? props.theme.colors.cta : props.theme.colors.off)};
  color: ${({ theme, on }) => (on ? theme.colors.ctaText : theme.colors.text)};
  border: none;

  &:hover {
    background: ${(props) => (props.on ? props.theme.colors.ctaHover : props.theme.colors.offHover)};
    box-shadow: none;
  }
`;

export const ButtonNoFormatCss = css`
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  border: none;
  text-align: left;

  padding: 0;
  margin: 0;

  &:focus,
  &:focus-visible {
    outline: none;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.mainHover};
    text-decoration-thickness: 3px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.grey9};
    background-color: transparent;
  }
`;
export const ButtonNoFormat = styled.button`
  ${ButtonNoFormatCss}
`;

export const ButtonUnderline = styled.button`
  cursor: pointer;
  text-decoration: underline;
  background: transparent;
  border: none;
  text-align: left;

  padding: 0;
  margin: 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -1rem;

  & > * {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
`;

// TODO: Replace this with LinkNoFormat in links.tsx
export const LinkNoFormat = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
