import React from "react";

import { ConstrainedPageStyle, PageTitle, Section, P } from "../styles/page";
import { Link } from "react-router-dom";
import { AExternal } from "../components/Links";

const SuccessMessage = () => (
  <div>
    <P>Thank you for completing our contact form!</P>
    <P>
      If you included an enquiry, we will respond as soon as possible. Do keep an eye out for an email from{" "}
      <a href="mailto:hello@samhe.org.uk">hello@samhe.org.uk</a>. Please consider adding this email address to your
      address book to prevent our emails ending up in your spam or junk folders.
    </P>
    <P>
      If you signed up for our newsletter, you'll receive the next issue as soon as it's published. Meanwhile{" "}
      <Link to="/news">read the latest issue of the SAMHE Newsletter</Link> on our News page!
    </P>
    <P>
      You can also follow SAMHE on <AExternal href="https://bsky.app/profile/samhe.bsky.social">BlueSky</AExternal> or{" "}
      <AExternal href="https://www.linkedin.com/groups/12898847/">Linkedin</AExternal> and learn more about the project
      through our short{" "}
      <AExternal href="https://www.youtube.com/watch?v=fL33sLJDdFQ">Introducing SAMHE video</AExternal> and other videos
      on <AExternal href="https://www.youtube.com/@SAMHEProject">YouTube</AExternal>.
    </P>
    <P>
      If we need to confirm any of the details entered in this form we will contact you using the email address you have
      provided.
    </P>
  </div>
);

const ContactSuccessPage: React.FC = () => {
  return (
    <ConstrainedPageStyle>
      <PageTitle>Contact form submitted - thank you!</PageTitle>
      <Section>
        <SuccessMessage />
      </Section>
    </ConstrainedPageStyle>
  );
};

export default ContactSuccessPage;
