import { rest } from "msw";
import { AUTH_TOKEN_URL, SERVER_URL } from "../config";
import { mockServerMonitors } from "../dummyData";
import { IUser, IUserIdentity } from "../lib/User/IUser";
import * as database from "../dummyApi/database";
import { parseJwt } from "../utils/testing/test-utils";
import { ISchoolAppDetails } from "../lib/School/ISchoolDetails";

export interface IGetUserResponse {
  ok: boolean;
  data: IUserIdentity;
}

export const availableEndpoints: { endpoint: ReturnType<typeof rest.get>; useMockApi?: boolean }[] = [
  {
    // useMockApi: true,
    endpoint: rest.get("/_clearstorage", (req, res, ctx) => {
      // sessionStorage.clear();
      return res(ctx.status(200));
    }),
  },
  {
    // useMockApi: true,
    endpoint: rest.post(AUTH_TOKEN_URL, (req, res, ctx) => {
      const body = req.body as any;
      // This is a bit of a hack to get the form encoded value
      const code = decodeURI(body.split("code=")[1].split("&")[0]).replace("%3A", ":");
      const response = {
        access_token: code,
        scope: "someScope",
        token_type: "jwt",
        expires_in: 99999999999,
        refresh_token: "NOTFORPRODUCTION_REFRESH_TOKEN",
        refresh_token_expires_in: 99999999999999,
        id_token: "NOTFORPRODUCTION_ID_TOKEN",
      };
      return res(ctx.status(200), ctx.json(response));
    }),
  },
  {
    // useMockApi: false,
    endpoint: rest.get<IGetUserResponse>(`${SERVER_URL}/identity/GetUser`, (req, res, ctx) => {
      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        console.info("Failed to parse user access token", accessToken);
        return res(
          ctx.status(401),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const { userName } = tokenData;
      const userData = database.getDocs<IUser>("SchoolUsers").find((u) => u.username === userName);
      const schoolData = database.getDocs<ISchoolAppDetails>("Schools").find((u) => u.schoolId === userData.schoolId);

      if (!userData) {
        // If not authenticated, respond with a 403 error
        return res(
          ctx.status(403),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const userReturnData: IUserIdentity = {
        Id: userData.username,
        Roles: [userData.group],
        superUser: false,
        ABProfile: userData.abProfile,
        AgeGroup: userData.ageGroup,
        School: {
          Id: schoolData.id,
          SchoolId: schoolData.schoolId,
          Monitors: userData.monitors.map((pid) => mockServerMonitors.find((m) => m.MonitorReferenceId === pid)),
          SchoolStatus: schoolData.status,
          Rooms: schoolData?.roomList?.map((r) => ({ Id: Number(r.id), Name: r.label })),
        },
      };
      // If authenticated, return a mocked user details
      return res(ctx.status(200), ctx.json(userReturnData));
    }),
  },
  {
    // apiPutUserAgeGroup
    endpoint: rest.post<{}>(`${SERVER_URL}/user/UpdateAgeGroup`, (req, res, ctx) => {
      const accessToken = req.headers.get("Authorization");
      let tokenData;
      try {
        tokenData = parseJwt(accessToken);
      } catch (e) {
        return res(
          ctx.status(401),
          ctx.json({
            errorMessage: "Not authorized",
          })
        );
      }
      const requestedGroup = req.url.searchParams.get("ageGroup");

      const { userName } = tokenData;
      try {
        const userData = database.getDocs<IUser>("SchoolUsers").find((u) => u.username === userName);
        const modUserData = { ...userData, ageGroup: requestedGroup };
        database.putDoc(userData.username, "SchoolUsers", modUserData, "username");
        // If authenticated, return a mocked user details
        return res(ctx.status(200), ctx.json({ ok: true }));
      } catch (error) {
        console.info(error);
        return res(ctx.status(500), ctx.json({ error: "Failed to put user data" }));
      }
    }),
  },
];

export const useMockApiEndpoints = availableEndpoints.filter((e) => e.useMockApi).map((e) => e.endpoint);
export const endpoints = availableEndpoints.map((e) => e.endpoint);
