import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { useCookie } from "react-use";
import { ESessionStatus, reduxSwitchRoomAsync, switchMonitor, switchRoom } from "../../Redux/User/userReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { AppHeaderDropdown } from "../Header/appHeaderDropdown";
import { ROOM_SELECTION_COOKIE_TIMEOUT_HOURS } from "../../config";
import { EMonitorChangeSource } from "../../lib/Monitor/IMonitor";
import { IRoomListItem } from "../../lib/School/IRoom";

export const useSyncMonitorLocationSelection = () => {
  const sessionState = useAppSelector((state) => state.userState.sessionState);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const monitors = useAppSelector((state) => state.userState.state.school?.data?.monitors);
  const dispatch = useAppDispatch();
  const [storedMonitorSelection] = useCookie("SAMHE_MONITOR_SELECTION");
  const firstLoad = React.useRef(true);

  React.useEffect(() => {
    if (
      // Wait for session logged in so we don't risk overriding the selection
      sessionState === ESessionStatus.LOGGED_IN &&
      firstLoad.current &&
      monitors
    ) {
      firstLoad.current = false;

      if (storedMonitorSelection !== monitorId) {
        // Cookie selection is different. Either we have just refreshed the page or the user has changed the monitor
        const selectedMonitor = monitors?.find((m) => m.id === storedMonitorSelection);
        if (selectedMonitor) {
          /* This stops a loop issue */
          dispatch(
            switchMonitor({
              id: storedMonitorSelection,
              activated: selectedMonitor?.activated,
              room: selectedMonitor?.room,
            })
          );
        }
      }
    }
  }, [storedMonitorSelection, monitorId, dispatch, monitors, sessionState]);
};

export const MonitorSelectionDropdown = () => {
  const {
    state: {
      school: {
        data: { monitors, disallowMoveYourMonitor, roomList },
      },
    },
  } = useAppSelector((state) => state.userState);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const dispatch = useAppDispatch();
  const [, updateStoredMonitorSelection] = useCookie("SAMHE_MONITOR_SELECTION");

  const singleMobile = !disallowMoveYourMonitor && roomList;
  const multipleStatic = monitors && monitors.length > 1;

  if (multipleStatic && !singleMobile)
    return (
      <AppHeaderDropdown
        id="monitor-select"
        label="Monitor"
        selectOptions={monitors.map((m) => ({
          ...m,
          id: String(m.id),
          label: m.room?.label || String(m.id),
        }))}
        value={String(monitorId)}
        onChange={(newMonitorSelection) => {
          const selectedMonitor = monitors.find((m) => m.id === newMonitorSelection);
          if (selectedMonitor) {
            // We change the cookie then the redux state is updated in the use effect above
            const cookieTimeout = new Date(Date.now() + 1000 * 60 * 60 * ROOM_SELECTION_COOKIE_TIMEOUT_HOURS);
            updateStoredMonitorSelection(String(newMonitorSelection), { expires: cookieTimeout, sameSite: "strict" });
            dispatch(
              switchMonitor({
                id: selectedMonitor.id,
                activated: selectedMonitor.activated,
                room: selectedMonitor.room,
              })
            );
          }
        }}
      />
    );
  return <></>;
};

export const LocationSelectionDropdown = ({
  updatedFrom,
  callApi,
  onSelected,
}: {
  updatedFrom: EMonitorChangeSource;
  callApi?: boolean;
  onSelected?: (room: IRoomListItem) => void;
}) => {
  const {
    state: {
      monitor: { id: monitorId } = { id: null },
      school: {
        data: { monitors, disallowMoveYourMonitor, roomList },
      },
    },
  } = useAppSelector((state) => state.userState);
  const currentRoomId = useAppSelector((state) => state.userState.state.room?.id);
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();

  const singleMobile = !disallowMoveYourMonitor && roomList;
  const multipleStatic = monitors && monitors.length > 1;

  if (singleMobile && !multipleStatic)
    return (
      <AppHeaderDropdown
        id="location-select"
        label="Location"
        selectOptions={roomList}
        value={String(currentRoomId)}
        onChange={(newRoomSelection) => {
          const selectedRoom = roomList.find((r) => r.id === newRoomSelection);
          // TODO: Handle errors here
          if (selectedRoom && callApi) dispatch(reduxSwitchRoomAsync(token, monitorId, selectedRoom, updatedFrom));
          if (selectedRoom && !callApi) dispatch(switchRoom(selectedRoom));
          if (onSelected) onSelected(selectedRoom);
        }}
      />
    );

  return <></>;
};

/** This location selection will only call onChangeRoom and will not modify global state */
export const LocalLocationSelectionDropdown = ({
  roomSelection,
  onChangeRoom,
}: {
  roomSelection: IRoomListItem;
  onChangeRoom: (newRoomSelection: IRoomListItem) => void;
}) => {
  const {
    state: {
      room: { id: currentRoomId } = { id: null },
      school: {
        data: { monitors, disallowMoveYourMonitor, roomList },
      },
    },
  } = useAppSelector((state) => state.userState);

  const singleMobile = !disallowMoveYourMonitor && roomList;
  const multipleStatic = monitors && monitors.length > 1;

  if (singleMobile && !multipleStatic)
    return (
      <AppHeaderDropdown
        id="location-select"
        label="Location"
        selectOptions={roomList}
        value={roomSelection?.id != null ? String(roomSelection.id) : String(currentRoomId)}
        onChange={(newRoomSelection) => {
          const selectedRoom = roomList.find((r) => r.id === newRoomSelection);
          if (selectedRoom) onChangeRoom(selectedRoom);
        }}
      />
    );
};
