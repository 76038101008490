import React from "react";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { IMultiCheckboxOption } from "../../../../lib/Forms/IMultiCheckboxOption";
import { StepDescription } from "../../../../styles/app";
import { FormStyle } from "../../../../styles/form";
import { MultiCheckboxB } from "../../../FormComponents/MultiCheckbox";
import { TextFormatter } from "../../../TextFormatter";

export interface IFeedbackStepExtraProps {
  options: IMultiCheckboxOption[];
}

export interface IFeedbackStepState {
  value: IMultiCheckboxOption;
}

export const FeedbackStep = ({
  id,
  state,
  description,
  additionalProps,
  onSubmit,
}: Partial<IStepProps<IFeedbackStepExtraProps, IFeedbackStepState>>) => {
  const { options } = additionalProps;
  const [selected, setSelected] = React.useState(state?.value);
  const handleChange = (v: boolean[]) => {
    const value = options.find((o, i) => v[i]);
    setSelected(value);
    onSubmit({ newValue: { value } });
  };

  return (
    <FormStyle
      aria-label={`multiChoiceStep-${id}`}
      name={`multiChoiceStep-${id}`}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <MultiCheckboxB
        name="selection"
        options={options}
        value={options.map((o) => o.uid === selected?.uid)}
        onChange={handleChange}
      />
    </FormStyle>
  );
};
