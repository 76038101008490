import React from "react";
import { PopupPanelContext } from "@react_db_client/components.popup-panel-v2";
import { ITag } from "../../lib/Tags/ITag";
import { TagPopupStyle } from "./style";
import { CloseBtn } from "../../styles/popups";
import { TextFormatter } from "../TextFormatter";
import { keywordMap } from "../../dummyData/keywords";

export interface ITagPopupProps {
  data: ITag;
  id: string;
}

export const TagPopup = ({ data: { label, description, longDescription }, id }: ITagPopupProps) => {
  const descriptionAsShown =
    typeof description === "string" ? <TextFormatter text={description} keywordMap={keywordMap} /> : description;
  const longDescriptionAsShown = typeof longDescription === "string" ? <p>{longDescription}</p> : longDescription;
  const { closePopup } = React.useContext(PopupPanelContext);
  return (
    <TagPopupStyle data-testid="tagPopup">
      <h2 className="popupTitle">{label}</h2>
      <CloseBtn onClick={() => closePopup(id)}>x</CloseBtn>
      {descriptionAsShown}
      {longDescriptionAsShown}
    </TagPopupStyle>
  );
};
