import { postDataFetch, IApiResponse, EReturnTypes } from "./apiHelpers";
import { SERVER_URL } from "../config";
import { IRoomListFormSubmission } from "../lib/formSubmissions/IRoomListFormSubmission";

export interface IApiGetSchoolAdminDataReturn extends IApiResponse {
  data: {
    handle: string;
    secretWordTeacher: string;
    secretWordPupil: string;
  };
}

export const postUpdateRoomList = (accessToken: string) => async (data: IRoomListFormSubmission) => {
  const url = `${SERVER_URL}/Rooms/Update`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IRoomListFormSubmission, IApiResponse>(url, data, headers, { returnType: EReturnTypes.HTML });
};


export const apiRoomList = {
  update: postUpdateRoomList,
};
