import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { DataMetricsMetaData, MetricStrIdMap } from "../../../lib/DataViews/EDataMetrics";
import { IMonitorData, IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";
import { DownloadCsvLink, DownloadCsvLinkBig } from "../../../styles/links";
import { aggregateTimestamps, dataToCsv } from "../../DataViz/dataProcessing/aggregateTimestamps";
import { IDateRangeState } from "../../DateRangePicker/lib";

export interface IDataViewCsvDownloadProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  data: IMonitorData<T>;
  includeTime?: boolean;
  multipleMonitors?: boolean;
  dateRange?: IDateRangeState;
}

export const processDataRow = <T,>(row: IMonitorDataItem<T>): [string, string, T, string, string] => {
  const date = new Date(row.dateStamp).toLocaleDateString();
  const time = new Date(row.dateStamp).toLocaleTimeString();
  const fieldId = MetricStrIdMap[row.field];
  const field = DataMetricsMetaData[fieldId];
  if (!field) {
    throw new Error(`Invalid field: ${row.field}`);
  }
  const { label, unit } = field;
  return [date, time, row.value, label, unit];
};

export const DataViewCsvDownload = <T,>({
  data: dataRaw,
  dateRange,
  ...additionalProps
}: IDataViewCsvDownloadProps<T>) => {
  const { data, metricHeadings } = aggregateTimestamps(dataRaw.data);
  if (!metricHeadings || metricHeadings.length === 0) {
    // Couldn't find any metric headings as we have no dataProcessing
    return <p>No data to download!</p>;
  }
  // if dataRange is provided, we should filter the data to only include data within the range
  let filteredData = data;
  if (dateRange) {
    const startTimeString = dateRange.startTimeLimit.toTimeString().split(" ")[0];
    const endTimeString = dateRange.endTimeLimit.toTimeString().split(" ")[0];
    filteredData = data.filter((row) => {
      const timeString = new Date(row.dateStamp).toTimeString().split(" ")[0];
      return timeString >= startTimeString && timeString <= endTimeString;
    });
  }

  const csvData = dataToCsv(metricHeadings, filteredData);
  if (!csvData || csvData.length === 0) {
    return <p>No data to download!</p>;
  }
  return (
    <DownloadCsvLink
      role="button"
      data-testdata={JSON.stringify(csvData)}
      data={csvData}
      filename="downloaded_school_data.csv"
      {...additionalProps}
    >
      Download data as a CSV file <FontAwesomeIcon style={{ marginRight: "0.3rem" }} icon={faDownload} />
    </DownloadCsvLink>
  );
};

export const DataViewCsvDownloadBig = <T,>({
  data: dataRaw,
  multipleMonitors,
  includeTime,
  ...additionalProps
}: IDataViewCsvDownloadProps<T>) => {
  const { data, metricHeadings } = aggregateTimestamps(dataRaw.data, multipleMonitors);
  const csvData = dataToCsv(metricHeadings, data, includeTime, multipleMonitors);
  return (
    <DownloadCsvLinkBig
      role="button"
      data-testdata={JSON.stringify(csvData)}
      data={csvData}
      filename="downloaded_school_data.csv"
      {...additionalProps}
    >
      Download data as a CSV file
    </DownloadCsvLinkBig>
  );
};
