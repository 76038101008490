import React from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../Redux/hooks";
import { UserGroup } from "../lib/User/UserGroup";
import { ESessionStatus } from "../Redux/User/userReducer";
import { AuthPage } from "./AuthPage";

export type AuthWrapperProps = {
  allowedUserGroups?: UserGroup[];
  children: React.ReactNode;
  blockedComponent?: React.ReactElement;
};

export const DefaultBlockedComponent = () => (
  <div>
    <h1
      style={{
        textAlign: "center",
      }}
    >
      You are not authorized to view this content.
    </h1>
  </div>
);

export const LoggingInPanel = () => {
  return (
    <div>
      <p>Logging in...</p>
    </div>
  );
};

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, allowedUserGroups, blockedComponent }) => {
  const {
    sessionState,
    user: { group },
  } = useAppSelector((state) => state.userState);
  const isAuthorized = allowedUserGroups === undefined || allowedUserGroups.indexOf(group) !== -1;

  const passesChecks = sessionState === ESessionStatus.LOGGED_IN;

  if (!isAuthorized && passesChecks) return blockedComponent;
  if (isAuthorized && passesChecks) return <>{children}</>;
  return <AuthPage />;
};

AuthWrapper.propTypes = {
  // @ts-ignore
  allowedUserGroups: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserGroup) as string[])),
  blockedComponent: PropTypes.element,
};

AuthWrapper.defaultProps = {
  allowedUserGroups: undefined,
  blockedComponent: <DefaultBlockedComponent />,
};

export default AuthWrapper;
