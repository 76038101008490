import React from "react";
import { MAX_DATE_RANGE, MIN_DATE_RANGE } from "../../config";
import { AggregationIntervals, EAggregationInterval } from "../../lib/MonitorData/AggregationIntervals";
import { H2 } from "../../styles/app";
import { FlexBox } from "../../styles/singlePageStyles";
import { EDayRange, ETimeRange, IDateRangePickerProps, timeRangeOptions } from "./lib";
import {
  allowAllDayRanges,
  getMaxAggregation,
  getMinAggregation,
  parseDateIn,
  parseDateToHumanRange,
  selectDayRange,
  selectTimeRange,
} from "./utils";
import { SelectDropdown } from "../../GenericComponents/FormElements/Select/Select";
import { DateTimePicker, HeaderLabel } from "./styles";

const getAggregationValues = (
  aggregationIn: EAggregationInterval,
  startDate: Date,
  endDate: Date,
  allowedMaxAggregation?: EAggregationInterval,
  allowedMinAggregation?: EAggregationInterval
) => {
  let maxAggregation = getMaxAggregation(endDate.valueOf() - startDate.valueOf());
  let minAggregation = getMinAggregation(endDate.valueOf() - startDate.valueOf());
  maxAggregation =
    !allowedMaxAggregation || maxAggregation <= allowedMaxAggregation ? maxAggregation : allowedMaxAggregation;

  minAggregation =
    !allowedMinAggregation || minAggregation >= allowedMinAggregation ? minAggregation : allowedMinAggregation;
  let aggregation = aggregationIn;
  aggregation = aggregation >= minAggregation ? aggregation : minAggregation;
  aggregation = aggregation <= maxAggregation ? aggregation : maxAggregation;
  return {
    aggregation,
    minAggregation,
    maxAggregation,
  };
};

export const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  onChange,
  value,
  useAggregation,
  allowTimeLimiting,
  adaptiveAggregation,
  allowedDayRanges=allowAllDayRanges,
  allowedMinDateRange,
  allowedMaxDateRange,
  allowedMaxAggregation,
  allowedMinAggregation,
}) => {
  const {
    startDate,
    endDate,
    startTimeLimit,
    endTimeLimit,
    dayRange,
    timeRange,
    aggregation,
    minAggregation,
    maxAggregation,
  } = value;

  const changeDayRange = (newDayRange: EDayRange) => {
    const newState = { ...value };
    newState.dayRange = newDayRange;
    if (newDayRange === EDayRange.LAST_HOUR) {
      if (timeRange !== ETimeRange.CUSTOM) {
        const [startTimeLimit, endTimeLimit] = selectTimeRange(ETimeRange.NO_LIMIT);
        newState.startTimeLimit = startTimeLimit;
        newState.endTimeLimit = endTimeLimit;
      }
      const [startDateNew, endDateNew] = selectDayRange(newDayRange);
      newState.startDate = startDateNew;
      newState.endDate = endDateNew;
    } else if (newDayRange !== EDayRange.CUSTOM) {
      const [startDateNew, endDateNew] = selectDayRange(newDayRange);
      newState.startDate = startDateNew;
      newState.endDate = endDateNew;
    } else {
      // Using custom date range.
      // In this case start and end date will be set to whatever the previous selection was.
      // I.e if the user was on "today" and then selects "custom" the start and end
      // date will be set to today.
    }
    if (useAggregation && adaptiveAggregation) {
      const {
        aggregation: newAggregation,
        minAggregation: newMinAggregation,
        maxAggregation: newMaxAggregation,
      } = getAggregationValues(
        aggregation,
        newState.startDate,
        newState.endDate,
        allowedMaxAggregation,
        allowedMinAggregation
      );
      newState.aggregation = newAggregation;
      newState.minAggregation = newMinAggregation;
      newState.maxAggregation = newMaxAggregation;
    }

    onChange(newState);
  };

  const changeTimeRange = (newTimeRange: ETimeRange) => {
    const newState = { ...value };
    newState.timeRange = newTimeRange;
    if (newTimeRange !== ETimeRange.CUSTOM) {
      const [startTimeLimit, endTimeLimit] = selectTimeRange(newTimeRange);
      newState.startTimeLimit = startTimeLimit;
      newState.endTimeLimit = endTimeLimit;
    }
    onChange(newState);
  };

  const changeTimeRangeCustom = (newStartTimeIn: string, newEndTimeIn: string) => {
    const newStartTime = newStartTimeIn === "Invalid Date" ? "00:00" : newStartTimeIn;
    const newEndTime = newEndTimeIn === "Invalid Date" ? "23:59" : newEndTimeIn;

    const startDateNew = startDate ? new Date(startDate) : new Date();
    startDateNew.setHours(parseInt(newStartTime.split(":")[0]));
    startDateNew.setMinutes(parseInt(newStartTime.split(":")[1]));
    const endDateNew = endDate ? new Date(endDate) : new Date();
    endDateNew.setHours(parseInt(newEndTime.split(":")[0]));
    endDateNew.setMinutes(parseInt(newEndTime.split(":")[1]));

    if (
      startDateNew instanceof Date &&
      !isNaN(startDateNew.valueOf()) &&
      endDateNew instanceof Date &&
      !isNaN(endDateNew.valueOf())
    ) {
      onChange({ ...value, startTimeLimit: startDateNew, endTimeLimit: endDateNew });
    } else {
      console.info("invalid dates", startDateNew, endDateNew);
      onChange({ ...value, startTimeLimit: startDateNew, endTimeLimit: endDateNew, invalid: "Invalid date" });
    }
  };

  const changeDateRangeCustom = (newStartDate: string, newEndDate: string) => {
    const newState = { ...value };
    newState.startDate = new Date(newStartDate);
    newState.endDate = new Date(newEndDate);
    newState.invalid = false;

    if (
      newStartDate &&
      newState.startDate instanceof Date &&
      !isNaN(newState.startDate.valueOf()) &&
      newEndDate &&
      newState.endDate instanceof Date &&
      !isNaN(newState.endDate.valueOf())
    ) {
      // Update Aggregation limits
      if (useAggregation && adaptiveAggregation) {
        // Aggregation options are set by the date range. I.e. cannot aggregate to a day if only viewing an hour of data
        const {
          aggregation: newAggregation,
          minAggregation: newMinAggregation,
          maxAggregation: newMaxAggregation,
        } = getAggregationValues(
          aggregation,
          newState.startDate,
          newState.endDate,
          allowedMaxAggregation,
          allowedMinAggregation
        );
        newState.aggregation = newAggregation;
        newState.minAggregation = newMinAggregation;
        newState.maxAggregation = newMaxAggregation;
      }
      const dateRange = newState.endDate.valueOf() - newState.startDate.valueOf();
      // Check not requesting too much data
      if (dateRange > MAX_DATE_RANGE * AggregationIntervals[newState.aggregation].ratio) {
        newState.invalid = `Currently you must select less than ${parseDateToHumanRange(
          MAX_DATE_RANGE * AggregationIntervals[newState.aggregation].ratio
        )} of data. Please change the start or end date.`;
      }
      // Check not requesting too much data
      if (dateRange < MIN_DATE_RANGE * AggregationIntervals[newState.aggregation].ratio) {
        newState.invalid = `You must select at least ${parseDateToHumanRange(
          AggregationIntervals[newState.aggregation].ratio
        )} of data. Please change the start or end date.`;
      }
      if (allowedMaxDateRange && dateRange > allowedMaxDateRange) {
        newState.invalid = `You must select less than ${parseDateToHumanRange(allowedMaxDateRange)} of data.`;
      }
      if (allowedMinDateRange && dateRange < allowedMinDateRange) {
        newState.invalid = `You must select more than ${parseDateToHumanRange(allowedMinDateRange)} of data.`;
      }
      if (newState.startDate.valueOf() > newState.endDate.valueOf()) {
        newState.invalid = "Start date must be before end date. Please change the start or end date.";
      }

      onChange(newState);
    } else {
      // is invalid
      newState.invalid = "Invalid Date";
      onChange(newState);
    }
  };

  const changeAggregation = (newAggregation: EAggregationInterval) => {
    onChange({ ...value, aggregation: Number(newAggregation) });
  };

  const availableAggregationOptions = Object.values(AggregationIntervals)
    .filter((a) => a.uid >= minAggregation && a.uid <= maxAggregation)
    .map((item) => {
      return {
        id: item.uid,
        label: item.label,
      };
    });

  const allowedDayRangesOptions = allowedDayRanges
    .filter((item) =>
      [
        // Implemented date ranges
        EDayRange.LAST_HOUR,
        EDayRange.TODAY,
        EDayRange.YESTERDAY,
        EDayRange.THIS_WEEK,
        EDayRange.LAST_SCHOOL_WEEK,
        EDayRange.THIS_YEAR,
        EDayRange.THIS_SCHOOL_YEAR,
        EDayRange.CUSTOM,
      ].includes(item)
    )
    .map((item) => {
      // Converts enum values into human readable strings
      const words = item.toLowerCase().split("_");
      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

      return {
        id: item,
        label: capitalizedWords.join(" "),
      };
    });

  return (
    <FlexBox horiz flexwrap style={{ gap: "1rem" }}>
      <div style={{ flex: "1 1 10rem" }}>
        <H2>
          {/* Select your date range */}
          <HeaderLabel htmlFor="dateRangePicker_dayRangeSelect">Select your date range</HeaderLabel>
        </H2>
        <p>This is the range of data that will be shown in the graph below.</p>
        <SelectDropdown
          selectOptions={allowedDayRangesOptions}
          value={dayRange}
          onChange={(v) => changeDayRange(v)}
          id="dateRangePicker_dayRangeSelect"
        />
        {dayRange === EDayRange.CUSTOM && (
          <FlexBox style={{ marginTop: "0.5rem" }}>
            <FlexBox>
              <FlexBox>
                <span>
                  <label htmlFor="dateRangePicker_startDateSelect">Start Date</label>:
                </span>
                <DateTimePicker
                  id="dateRangePicker_startDateSelect"
                  type="datetime-local"
                  // Requires local time string for datetime-local
                  value={parseDateIn(startDate || new Date())}
                  onChange={(e) => changeDateRangeCustom(e.target.value, endDate.toString())}
                />
              </FlexBox>
              <FlexBox style={{ marginTop: "0.5rem" }}>
                <span>
                  <label htmlFor="dateRangePicker_endDateSelect">End Date</label>:
                </span>
                <DateTimePicker
                  id="dateRangePicker_endDateSelect"
                  type="datetime-local"
                  value={parseDateIn(endDate || new Date())}
                  onChange={(e) => changeDateRangeCustom(startDate.toString(), e.target.value)}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </div>
      {allowTimeLimiting && dayRange !== EDayRange.LAST_HOUR && (
        <div style={{ flex: "1 1 10rem" }}>
          <H2>
            <HeaderLabel htmlFor="dateRangePicker_timeLimitingSelect">Select your time range</HeaderLabel>
          </H2>
          <p>Use the options below to select the time range you would like to investigate:</p>
          <SelectDropdown
            selectOptions={timeRangeOptions}
            value={timeRange}
            onChange={(v) => changeTimeRange(v)}
            id="dateRangePicker_timeLimitingSelect"
          />
          {timeRange === ETimeRange.CUSTOM && (
            <FlexBox style={{ marginTop: "0.5rem" }}>
              <FlexBox>
                <span>
                  <label htmlFor="dateRangePicker_startTimeSelect">Start Time</label>:
                </span>
                <DateTimePicker
                  id="dateRangePicker_startTimeSelect"
                  type="time"
                  value={startTimeLimit?.toTimeString().slice(0, 5)}
                  onChange={(e) => changeTimeRangeCustom(e.target.value, endTimeLimit.toTimeString())}
                />
              </FlexBox>
              <FlexBox style={{ marginTop: "0.5rem" }}>
                <span>
                  <label htmlFor="dateRangePicker_endTimeSelect">End Time</label>:
                </span>
                <DateTimePicker
                  id="dateRangePicker_endTimeSelect"
                  type="time"
                  value={endTimeLimit?.toTimeString().slice(0, 5)}
                  onChange={(e) => changeTimeRangeCustom(startTimeLimit.toTimeString(), e.target.value)}
                />
              </FlexBox>
            </FlexBox>
          )}
        </div>
      )}
      {useAggregation && (
        <div data-testid="selectIntervalOptionsSection" style={{ flex: "1 1 10rem" }}>
          <H2>Select the interval of your data.</H2>
          <p>
            {" "}
            For example select <b>5 minutes</b> to show the average value every 5 minutes.
          </p>
          <SelectDropdown
            selectOptions={availableAggregationOptions}
            value={aggregation}
            onChange={(v) => changeAggregation(v)}
            id="dateRangePicker_aggregationSelect"
          />
        </div>
      )}
    </FlexBox>
  );
};
