import React from "react";
import {
  FaqGroupStyle,
  FaqGroupWrapStyle,
  FaqItemContentStyle,
  FaqItemHeadingStyle,
  FaqItemStyle,
  FaqItemWrapStyle,
  IFaqItemContentStyle,
} from "../../styles/faq";
import { StyledSASDropdown } from "../../styles/form";
import { getNodeText } from "../../utils/textUtils";
import { ITechSupportGroup, ITechSupportItem, TechSupportGroups } from "./lib";
import techSupportEntries from "./techSupportEntries";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { BoxoutContactStyle } from "../../styles/hero";
import { useLocation } from "react-router-dom";
import { PLong, Section } from "../../styles/page";

const TechSupportItemContentStyle = styled(FaqItemContentStyle)<IFaqItemContentStyle>`
  max-height: ${({ isOpen }) => (isOpen ? "min-content" : "0")};
`;

const allGroup = { uid: "all", label: "All" };

const getGroupOptions: () => Promise<(ITechSupportGroup | { uid: string; label: string })[]> = async () => [
  allGroup,
  ...Object.values(TechSupportGroups),
];

const filterBySearchVal = (searchVal: string) => (item: ITechSupportItem) => {
  const labelAsString = typeof item.label === "string" ? item.label : getNodeText(item.label);
  if (labelAsString.toLowerCase().includes(searchVal.toLowerCase())) return true;
  if (getNodeText(item.content).toLowerCase().includes(searchVal.toLowerCase())) return true;
  return false;
};

export interface ITechSupportItemProps {
  data: ITechSupportItem;
  open?: boolean;
}

export const TechSupportItem = ({ data: { uid, label, content }, open = false }: ITechSupportItemProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const [icon, setIcon] = React.useState(faCaretDown);
  return (
    <FaqItemStyle>
      <FaqItemHeadingStyle
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIcon(icon === faCaretDown ? faCaretUp : faCaretDown);
        }}
      >
        <h3 id={uid}>
          {label}
          <FontAwesomeIcon size="xl" style={{ float: "right", marginLeft: "0.5rem" }} icon={icon} />
        </h3>
      </FaqItemHeadingStyle>
      <TechSupportItemContentStyle isOpen={isOpen}>
        <div>{content}</div>
      </TechSupportItemContentStyle>
    </FaqItemStyle>
  );
};

interface ITechSupportGroupProps {
  label: string | React.ReactNode;
  entries: ITechSupportItem[];
  uidToShow?: string;
}
export const TechSupportGroup: React.FC<ITechSupportGroupProps> = ({ label, entries, uidToShow }) => {
  return (
    <FaqGroupStyle>
      <h2>{label}</h2>
      <FaqItemWrapStyle>
        {entries.map((techSupportItem) => (
          <TechSupportItem
            key={techSupportItem.uid}
            data={techSupportItem}
            open={uidToShow ? techSupportItem.uid === uidToShow : false}
          />
        ))}
      </FaqItemWrapStyle>
    </FaqGroupStyle>
  );
};

const TechSupport: React.FC = () => {
  const [currentGroup, setCurrentGroup] = React.useState(allGroup);
  const [searchVal, setSearchVal] = React.useState("");

  const onChangeMiddleware = React.useCallback((selectedData) => {
    selectedData && setCurrentGroup(selectedData);
  }, []);

  const { hash } = useLocation();
  const uid = hash?.replace("#", "");

  return (
    <>
      <BoxoutContactStyle style={{ marginBottom: "1rem", padding: "1rem", overflow: "visible" }}>
        <Section>
          <PLong>
            <b>Update July 2024</b>: Please note that although the original research funding for SAMHE has now come to
            an end, funds have been preserved to enable the storage of Web App and monitor data until at least 2030. If
            your school has a SAMHE monitor and you haven't got started with SAMHE yet, it's not too late to be part of
            this important research project.
          </PLong>
        </Section>
        <Section style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
          <label style={{ minWidth: "12rem", marginBottom: "0.3rem" }} htmlFor="groupSelect">
            <b>Filter help by topic:</b>
          </label>
          <StyledSASDropdown
            id="groupSelect"
            searchFunction={getGroupOptions as any} // TODO: Fix types
            initialValue={currentGroup}
            handleSelect={onChangeMiddleware}
            allowEmptySearch
            // allowInputOnly={false}
            onChange={(e) => onChangeMiddleware((e.target as HTMLInputElement).value)}
            searchFieldTargetField="label"
            searchDelay={0}
            labelField="label"
          />
        </Section>
        <Section style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ minWidth: "12rem", marginBottom: "0.3rem" }} htmlFor="searchInput">
            <b>Search FAQ:</b>
          </label>
          <input
            style={{ flexGrow: 1 }}
            id="searchInput"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Section>
      </BoxoutContactStyle>
      <FaqGroupWrapStyle>
        {Object.values(TechSupportGroups)
          .map((f) => ({
            ...f,
            entries: techSupportEntries.filter((fe) => fe.group === f.uid).filter(filterBySearchVal(searchVal)),
          }))
          .filter((f) => f.entries.length > 0 && (currentGroup?.uid === "all" || currentGroup?.uid === f.uid))
          .map((f) => (
            <TechSupportGroup label={f.label} key={f.uid} entries={f.entries} uidToShow={uid} />
          ))}
      </FaqGroupWrapStyle>
    </>
  );
};

export default TechSupport;
