import { IMonitorDataItem } from "../../../lib/MonitorData/IMonitorData";

export type Hour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export interface IBucketRange {
  min: number;
  max: number;
  label: string;
  color: string;
}

export const processDataForPieChart = (data: IMonitorDataItem<number>[], bucketRanges: IBucketRange[]) => {
  const bucketGroups = bucketRanges.reduce((acc, bucketRange) => {
    return {
      ...acc,
      [bucketRange.label]: {
        count: 0,
        bucketLabel: bucketRange.label,
      },
    };
  }, {});

  const totalDataCount = data.length;

  const groupedByRangeData: { [key: string]: { count: number } } = data.reduce((groups, item) => {
    const { value } = item;
    const bucketRange = bucketRanges.find((bucketRange) => {
      const { min, max } = bucketRange;
      return value >= min && value <= max;
    }) || {
      label: "Out of range!",
      color: "black",
    };
    if (bucketRange.label === "Out of range!") console.log("Out of range!", value);
    const key = bucketRange.label;
    const groupsOut = { ...groups };
    const oldGroupData = groups[key] || { count: 0 };
    groupsOut[key] = {
      ...oldGroupData,
      count: oldGroupData.count + 1,
      bucketLabel: bucketRange.label,
    };
    return groupsOut;
  }, bucketGroups);

  const groupedByRangeDataPercentages: [string, number][] = Object.entries(groupedByRangeData).map(([key, value]) => [
    key,
    parseFloat(((value.count * 100) / totalDataCount).toFixed(1)) as number,
  ]);
  return groupedByRangeDataPercentages;
};

export const usePreprocessPieData = (data: IMonitorDataItem<number>[], bucketRanges: IBucketRange[]) =>
  processDataForPieChart(data, bucketRanges);

export const weekdaylabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const processDataForPieChartDaily = (data: IMonitorDataItem<number>[], bucketRanges: IBucketRange[]) => {
  const bucketGroups = weekdaylabels.map(() =>
    bucketRanges.reduce((acc, bucketRange) => {
      return {
        ...acc,
        [bucketRange.label]: {
          count: 0,
          bucketLabel: bucketRange.label,
        },
      };
    }, {})
  );

  const dayPointCounts = data.reduce(
    (dayCounts, item) => {
      const { dateStamp } = item;
      const currentDateStamp = new Date(dateStamp);
      const weekday = (currentDateStamp.getDay() + 6) % 7;
      const newDayCounts = [...dayCounts];
      newDayCounts[weekday] = newDayCounts[weekday] + 1;
      return newDayCounts;
    },
    weekdaylabels.map(() => 0)
  );

  const groupedByRangeData: { [key: string]: { count: number } }[] = data.reduce((groups, item) => {
    const { dateStamp, value } = item;
    const currentDateStamp = new Date(dateStamp);
    const weekday = (currentDateStamp.getDay() + 6) % 7;

    const bucketRange = bucketRanges.find((bucketRange) => {
      const { min, max } = bucketRange;
      return value >= min && value <= max;
    }) || {
      label: "Out of range!",
      color: "black",
    };
    if (bucketRange.label === "Out of range!") console.log("Out of range!", value);
    const key = bucketRange.label;
    const groupsOut = [...groups];
    const oldGroupData = groups[weekday][key] || { count: 0 };
    groupsOut[weekday][key] = {
      ...(oldGroupData || { count: 0 }),
      count: oldGroupData.count + 1,
      bucketLabel: bucketRange.label,
    };
    return groupsOut;
  }, bucketGroups);

  const groupedByRangeDataPercentages: [string, number][][] = groupedByRangeData.map((d, i) => {
    const dayPointCount = dayPointCounts[i];
    return Object.entries(d).map(([key, value]) => [
      key,
      parseFloat(((value.count * 100) / dayPointCount).toFixed(1)) as number,
    ]);
  });

  const filteredGroupedData = groupedByRangeDataPercentages
    .map((d, i) => ({ dayLabel: weekdaylabels[i], value: d }))
    .filter((d, i) => dayPointCounts[i] > 0);
  return filteredGroupedData;
};

export const usePreprocessPieDataDaily = (data: IMonitorDataItem<number>[], bucketRanges: IBucketRange[]) =>
  processDataForPieChartDaily(data, bucketRanges);
