import styled from "styled-components";
import { KeyIconStyle, KeyItemLabel, KeySectionStyle } from "../../../styles/app";
import { FlexBox } from "../../../styles/singlePageStyles";

export interface ICompleteCountIconProps {
  count: number;
}

export const CountStyle = styled.p`
  margin-top: 0.1rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 100%;
  margin-bottom: ${({ theme }) => theme.shape.defaultPaddingSmall};
`;

export const CompleteCountIconImgStyle = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const CompleteCountIcon: React.FC<ICompleteCountIconProps> = ({ count }) => {
  return (
    <>
      <CountStyle style={{ marginTop: "0.1rem" }}>{count}</CountStyle>
      <CompleteCountIconImgStyle src="/images/app/icons/No_Of_Attempts.png" alt="Activity complete count" />
    </>
  );
};

export const CompleteCountKeyItems = () => (
  <KeySectionStyle>
    <FlexBox horiz center>
    <KeyIconStyle
        width="2rem"
        height="2rem"
        src="/images/app/icons/No_Of_Attempts.png"
        alt="Activity complete count"
      />
      <KeyItemLabel>Count</KeyItemLabel>
    </FlexBox>
  </KeySectionStyle>
);
