import React from "react";
import styled from "styled-components";
import { useInterval } from "react-use";
import { MONITOR_REFRESH_RATE } from "../../../../config";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { DataMetricsMetaData, EDataMetrics, IMetricData } from "../../../../lib/DataViews/EDataMetrics";
import { MonitorId } from "../../../../lib/Monitor/IMonitor";
import { IMonitorData, IMonitorDataItem } from "../../../../lib/MonitorData/IMonitorData";
import { ErrorMessage, StepDescription } from "../../../../styles/app";
import { CTAButtonStyle, OnOffButtonStyle } from "../../../../styles/button";
import { TextFormatter } from "../../../TextFormatter";
import { SimplePlot } from "../../../DataViz/SimplePlot/SimplePlot";
import { useDataLoader } from "../../../DataViewDashboard/dataLoader";
import { ETimeFormat } from "../../../../lib/DataViews/ETimeFormats";
import { StepPlotContainer } from "./styles";
import { useAppSelector } from "../../../../Redux/hooks";
import { EDayRange } from "../../../DateRangePicker/lib";
import { defaultDateRangeState } from "../../../DateRangePicker/utils";

export interface IUseDataLoaderArgs {
  monitorId: MonitorId;
  metric: EDataMetrics;
}

export enum EDataSnapshotChallengeType {
  QUICK = 1,
  SELECT = 2,
  MANUAL = 3,
}
export interface IDataSnapshotExtraProps {
  metric: EDataMetrics;
  challengeType: EDataSnapshotChallengeType;
}

export interface IDataSnapshotState {
  snapshot: IMonitorDataItem<number>;
}

export interface ISnapshotProps {
  latestValue: number;
  metric: IMetricData;
  challengeType: EDataSnapshotChallengeType;
  loadedData: IMonitorData<number>;
}

const QuickSnapshot = ({ latestValue, metric }: ISnapshotProps) => {
  const { unit, label } = metric;
  return (
    <>
      {latestValue != null && (
        <p>
          Current {label}: {latestValue} {unit}
        </p>
      )}
      <CTAButtonStyle disabled={latestValue == null} type="submit">
        Take snapshot
      </CTAButtonStyle>
    </>
  );
};

const SelectButtonsWrap = styled.div`
  display: flex;
  button {
    margin-right: 0.3rem;
  }
`;

const SelectSnapshot = ({ latestValue, metric, loadedData }: ISnapshotProps) => {
  const currentRoom = useAppSelector((state) => state.userState.state.room?.data?.currentRoom);
  const currentRoomMonitor = useAppSelector((state) => state.userState.state.monitor?.data?.room);

  const { label, unit, defaultYRange } = metric;
  const [selectedValue, setSelectedValue] = React.useState(null);
  const yLabel = `${label} (${unit})`;

  const upperVal = (latestValue * 1.4).toFixed(2);
  const lowerVal = (latestValue * 0.6).toFixed(2);
  const latestVal = (latestValue * 1.0).toFixed(2);

  const message =
    (selectedValue === null &&
      `Hover over the graph to find the latest  ${label} reading (which will be on the far right of the graph) and select the value below.`) ||
    (selectedValue > latestValue && "That's too high! Try again.") ||
    (selectedValue < latestValue && "That's too low! Try again.") ||
    (selectedValue === latestValue && `That's right! The ${label} value is currently ${latestValue}${unit}.`) ||
    "ERROR";

  return (
    <>
      <p>Viewing data for monitor in {currentRoom?.label || currentRoomMonitor?.label}.</p>
      {loadedData?.data ? (
        <StepPlotContainer>
          <SimplePlot timeFormat={ETimeFormat.TIME} data={loadedData?.data} yLabel={yLabel} yRange={defaultYRange} />
        </StepPlotContainer>
      ) : (
        ""
      )}
      <p>
        <b>{message}</b>
      </p>
      <SelectButtonsWrap>
        {lowerVal !== latestVal && (
          <CTAButtonStyle disabled={latestValue == null} onClick={() => setSelectedValue(lowerVal)} type="button">
            {lowerVal} {unit}
          </CTAButtonStyle>
        )}
        <CTAButtonStyle disabled={latestValue == null} onClick={() => setSelectedValue(latestValue)} type="submit">
          {latestValue} {unit}
        </CTAButtonStyle>
        {upperVal !== latestVal && (
          <CTAButtonStyle disabled={latestValue == null} onClick={() => setSelectedValue(upperVal)} type="button">
            {upperVal} {unit}
          </CTAButtonStyle>
        )}
      </SelectButtonsWrap>
    </>
  );
};

const SnapshotComponent = (props: ISnapshotProps) => {
  switch (props.challengeType) {
    case EDataSnapshotChallengeType.QUICK:
      return <QuickSnapshot {...props} />;
    case EDataSnapshotChallengeType.SELECT:
      return <SelectSnapshot {...props} />;
    case EDataSnapshotChallengeType.MANUAL:
      return <>NOT IMPLEMENTED</>;
  }
};

export const DataSnapshotStep = ({
  description,
  state,
  onSubmit,
  onChange,
  additionalProps,
  monitorId,
}: Partial<IStepProps<IDataSnapshotExtraProps, IDataSnapshotState>>) => {
  const { metric, challengeType } = additionalProps;
  const [liveUpdate, setLiveUpdate] = React.useState(false);
  const [dateRange] = React.useState(defaultDateRangeState(EDayRange.LAST_HOUR));
  const { loadedData, reloadData, dataLoadError, readyToView, loading } = useDataLoader({
    monitorId,
    currentMetric: additionalProps.metric,
    dateRange: dateRange,
  });
  useInterval(() => (liveUpdate ? reloadData : null), MONITOR_REFRESH_RATE);

  const latestValue = loadedData?.data[loadedData?.data.length - 1]?.value;
  const metricData = DataMetricsMetaData[metric];
  if (!metricData) throw Error(`Missing metric data for ${metric}`);

  const { unit } = metricData;
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        if (loadedData !== null) {
          onChange("snapshot", loadedData?.data[loadedData?.data.length - 1]);
          onSubmit({ newValue: { snapshot: loadedData?.data[loadedData?.data.length - 1] } });
        }
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>

      <CTAButtonStyle onClick={() => reloadData()} type="button" disabled={loading && !dataLoadError}>
        {(loading && !dataLoadError && "Loading data") || "Refresh data"}
      </CTAButtonStyle>
      <OnOffButtonStyle
        onClick={() => setLiveUpdate((prev) => !prev)}
        on={liveUpdate ? "true" : undefined}
        type="button"
      >
        Live Update
      </OnOffButtonStyle>
      {state?.snapshot?.value != null && (
        <p>
          Snapshot value: {state.snapshot.value} {unit}
        </p>
      )}
      {dataLoadError && <ErrorMessage>Error loading data</ErrorMessage>}
      {readyToView && !dataLoadError && (
        <SnapshotComponent
          latestValue={latestValue}
          challengeType={challengeType}
          metric={metricData}
          loadedData={loadedData}
        />
      )}
    </form>
  );
};
